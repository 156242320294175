import { actionTypes } from "./employeeWfhAction";
const {
  APPLY_WFH_PENDING,
  APPLY_WFH_SUCCESS,
  APPLY_WFH_ERROR,

  USERWFHLIST_PENDING,
  USERWFHLIST_SUCCESS,
  USERWFHLIST_ERROR,

  GETUSERWFH_PENDING,
  GETUSERWFH_SUCCESS,
  GETUSERWFH_ERROR,

  USERWFHLISTCALENDER_PENDING,
  USERWFHLISTCALENDER_SUCCESS,
  USERWFHLISTCALENDER_ERROR,

  UPDATE_WFH_DETAIL_PENDING,
  UPDATE_WFH_DETAIL_SUCCESS,
  UPDATE_WFH_DETAIL_ERROR,

  GETUSERWFHANDLEAVE_PENDING,
  GETUSERWFHANDLEAVE_SUCCESS,
  GETUSERWFHANDLEAVE_ERROR,

  UPDATE_WFH_GETDATA_PENDING,

  CANCELGETUSERWFHBYID_PENDING,
  CANCELGETUSERWFHBYID_SUCCESS,
  CANCELGETUSERWFHBYID_ERROR,
} = actionTypes;
const initialState = {
  applyForWfhPending: false,
  applyForWfhSuccess: false,
  applyForWfhError: null,

  userWfhListPending: false,
  userWfhListSuccess: false,
  userWfhListError: null,

  getUserWfhPending: false,
  getUserWfhSuccess: false,
  getUserWfhError: null,

  userWfhListCalenderPending: false,
  userWfhListCalenderSuccess: false,
  userWfhListCalenderError: null,

  userWfhList: [],
  getUserWfhData: null,
  userCalender: [],
  getUserWfhAndLeaveData: [],

  updateWfhDetailPending: false,
  updateWfhDetailSuccess: false,
  updateWfhDetailError: null,

  getUserWfhAndLeavePending: false,
  getUserWfhAndLeaveSuccess: false,
  getUserWfhAndLeaveError: null,

  cancelGetUserWfhByIdPending: false,
  cancelGetUserWfhByIdSuccess: false,
  cancelGetUserWfhByIdError: null,

  hasNextPage: false,
  hasPrevPage: false,
  limit: null,
  nextPage: null,
  pagingCounter: null,
  prevPage: null,
  totalDocs: null,
  totalPages: null,
};
export default function employeeWfhReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case APPLY_WFH_PENDING:
    case APPLY_WFH_ERROR:
    case APPLY_WFH_SUCCESS:
      return Object.assign({}, state, payload);

    case USERWFHLIST_PENDING:
    case USERWFHLIST_ERROR:
    case USERWFHLIST_SUCCESS:
      return Object.assign({}, state, payload);

    case USERWFHLISTCALENDER_PENDING:
    case USERWFHLISTCALENDER_SUCCESS:
    case USERWFHLISTCALENDER_ERROR:
      return Object.assign({}, state, payload);

    case GETUSERWFH_PENDING:
    case GETUSERWFH_ERROR:
    case GETUSERWFH_SUCCESS:
      return Object.assign({}, state, payload);

    case UPDATE_WFH_DETAIL_PENDING:
    case UPDATE_WFH_DETAIL_ERROR:
    case UPDATE_WFH_DETAIL_SUCCESS:
      return Object.assign({}, state, payload);

    case GETUSERWFHANDLEAVE_PENDING:
    case GETUSERWFHANDLEAVE_SUCCESS:
    case GETUSERWFHANDLEAVE_ERROR:
      return Object.assign({}, state, payload);

    case CANCELGETUSERWFHBYID_PENDING:
    case CANCELGETUSERWFHBYID_SUCCESS:
    case CANCELGETUSERWFHBYID_ERROR:
      return Object.assign({}, state, payload);

    case UPDATE_WFH_GETDATA_PENDING:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
