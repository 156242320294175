import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import parse from "html-react-parser";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, CKEditorView } from "../../../controls";

const data = {
  name: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  state: "",
  country: "",
  pinCode: "",
};

const LocationMngt = ({
  addLocation,
  editLocation,
  deleteLocation,

  locationList,
}) => {
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [locationData, setLocationData] = useState(data);
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [locationId, setLocationId] = useState("");
  // console.log(locationList);
  const columns = [
    {
      name: "City",
      selector: "name",
      sortable: true,
      grow: 8,
      // width: "150px",
    },
    {
      name: "State",
      selector: "state",
      sortable: true,
      grow: 8,
    },
    {
      name: "Address",
      selector: "addressLine1",
      sortable: true,
      grow: 8,
      width: "160px",
      // wrap: true,
      cell: (row) => {
        return (
          <span className="d-flex align-items-center location__column">
            {row.addressLine1 ? parse(row.addressLine1.slice(0, 22)) : ""}...
          </span>
        );
      },
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setLocationData(row);
                setShowAddLocation(true);
                setLocationId(row._id);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete permission?")) {
                // console.log(row._id)
                deleteLocation(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleAddRole = () => {
    var rules = { required: ["name", "state", "country", "addressLine1"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(locationData),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        editLocation({ ...locationData, city: locationData.name }, locationId);
        // edit
      } else {
        addLocation({ ...locationData, city: locationData.name });
        // add
      }
      console.log({ ...locationData, city: locationData.name });
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddLocation(false);
    setLocationData(data);
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Location</div>
              <div className="d-none d-md-block">Location Management</div>
            </Card.Title>
            <div className="add_permission">
              <ButtonView
                onClick={() => {
                  setLocationData(data);
                  setShowAddLocation(true);
                }}
                title={"Add Location"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={locationList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddLocation}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Location" : "Add Location"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="d-flex justify-content-end mb-2">
              <Col xs={6}>
                <Input
                  name="name"
                  label="City"
                  value={locationData.name}
                  type="text"
                  placeholder="City Name"
                  onChange={(e) =>
                    setLocationData({ ...locationData, name: e.target.value })
                  }
                  size="fluid"
                  error={errors["name"]}
                  isrequired={true}
                  maxLength={50}
                />
              </Col>
              <Col xs={6}>
                <Input
                  name="state"
                  label="State"
                  value={locationData.state}
                  type="text"
                  placeholder="State Name"
                  onChange={(e) =>
                    setLocationData({ ...locationData, state: e.target.value })
                  }
                  size="fluid"
                  error={errors["state"]}
                  isrequired={true}
                  maxLength={50}
                />
              </Col>
              <Col xs={6} className="mt-3">
                <Input
                  name="country"
                  label="Country"
                  value={locationData.country}
                  type="text"
                  placeholder="Country Name"
                  onChange={(e) =>
                    setLocationData({
                      ...locationData,
                      country: e.target.value,
                    })
                  }
                  size="fluid"
                  error={errors["country"]}
                  isrequired={true}
                  maxLength={25}
                />
              </Col>
              <Col xs={6} className="mt-3">
                <Input
                  name="pinCode"
                  label="PinCode"
                  value={locationData.pinCode}
                  // type="number"
                  type="tel"
                  placeholder="Add PinCode"
                  onChange={(e) =>
                    setLocationData({
                      ...locationData,
                      pinCode: e.target.value,
                    })
                  }
                  size="fluid"
                  maxLength={10}
                />
              </Col>
              {/* <Col xs={12} className="mt-3">
                <TextArea
                  name="addressLine1"
                  label="Address Line 1"
                  type="text"
                  placeholder="Address Line 1"
                  size="fluid"
                  value={locationData.addressLine1}
                  onChange={(e) =>
                    setLocationData({
                      ...locationData,
                      addressLine1: e.target.value,
                    })
                  }
                  error={errors["addressLine1"]}
                  isrequired={true}
                />
              </Col> */}
              {/* <Col xs={12} className="mt-3">
                <TextArea
                  name="addressLine2"
                  label="Address Line 2"
                  type="text"
                  placeholder="Address Line 2"
                  size="fluid"
                  value={locationData.addressLine2}
                  onChange={(e) =>
                    setLocationData({
                      ...locationData,
                      addressLine2: e.target.value,
                    })
                  }
                />
              </Col> */}
              <Col xs={12} className="mt-3">
                <CKEditorView
                  label="Address Line"
                  placeholder="Address Line"
                  value={locationData.addressLine1}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setLocationData({
                      ...locationData,
                      addressLine1: data,
                    });
                  }}
                  isrequired={true}
                  error={errors["addressLine1"]}
                  maxLength={300}
                />
              </Col>
              {/* <Col xs={12} className="mt-3">
                <CKEditorView
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  value={locationData.addressLine2}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setLocationData({
                      ...locationData,
                      addressLine2: data,
                    });
                  }}
                />
              </Col> */}
              <Col xs={6} className="mt-3">
                <ButtonView
                  onClick={handleAddRole}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LocationMngt;
