import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiBriefcase } from "react-icons/fi";

import { Header } from "../../common";
import JobApplicationDetail from "./components/JobApplicationDetail";
import JobApplications from "./components/JobApplications";
import JobFilter from "./components/JobFilter";

const JobApplicationsView = ({
  techDomainList,
  designationList,
  aplicationDomainList,
  user,
  createJobApplication,
  jobApplicationList,

  profileDetail,
  setProfileDetail,
  downloadJobApplicationDocument,
  qualificationList,
  getJobApplicationList,
  userList,
  userManager,

  interviewScheduleDetail,
  setInterviewScheduleDetail,
  totalInterviewScheduleList,
  createNewInterviewSchedule,
  updateJobApplicationStatus,
  rowId,
  setRowId,
  updateInterviewSchedule,
  closeJobApplication,
  srollToSummary,
  updateJobApplication,
  jobApplicationInactiveList,

  defaultTabActiveKey,
  onsetDefaultTabActiveKey,

  locationList,
  updateInterviewScheduleMeetingLink,

  date,
  desData,
  combineCalenderData,

  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,
  holidaysList,
  addTechDomain,
}) => {
  // console.log(totalInterviewScheduleList);
  useEffect(() => {
    if (rowId) {
      const profileDetailInterviewScheduleList =
        totalInterviewScheduleList.filter((list) => {
          return list.applicationId._id === rowId;
        });
      setInterviewScheduleDetail(profileDetailInterviewScheduleList);
    }
  }, [totalInterviewScheduleList, rowId, setInterviewScheduleDetail]);

  useEffect(() => {
    if (rowId) {
      const combinedata = [
        ...jobApplicationList,
        ...jobApplicationInactiveList,
      ];
      const profileDetailOnUpdate = combinedata.find(
        (list) => rowId === list._id
      );
      setProfileDetail(profileDetailOnUpdate);
    }
  }, [jobApplicationList, jobApplicationInactiveList, rowId, setProfileDetail]);

  return (
    <>
      <Header label={"Job Applications"} Icon={FiBriefcase} />
      <div className="main__body padding">
        <Container>
          <Row>
            <Col lg={12} xl={8} className="col-xxl-8">
              <div className="column_divider mt-3">
                <div className="job_filter_overview">
                  <JobFilter
                    techDomainList={techDomainList}
                    designationList={designationList}
                    qualificationList={qualificationList}
                    getJobApplicationList={getJobApplicationList}
                    setInterviewScheduleDetail={setInterviewScheduleDetail}
                    setProfileDetail={setProfileDetail}
                    setRowId={setRowId}
                    onsetDefaultTabActiveKey={onsetDefaultTabActiveKey}
                  />
                </div>

                <div className="job_list_overview mt-3">
                  <JobApplications
                    techDomainList={techDomainList}
                    designationList={designationList}
                    aplicationDomainList={aplicationDomainList}
                    user={user}
                    createJobApplication={createJobApplication}
                    jobApplicationList={jobApplicationList}
                    setProfileDetail={setProfileDetail}
                    userList={userList}
                    setInterviewScheduleDetail={setInterviewScheduleDetail}
                    rowId={rowId}
                    setRowId={setRowId}
                    srollToSummary={srollToSummary}
                    jobApplicationInactiveList={jobApplicationInactiveList}
                    defaultTabActiveKey={defaultTabActiveKey}
                    onsetDefaultTabActiveKey={onsetDefaultTabActiveKey}
                    addTechDomain={addTechDomain}
                  />
                  <div ref={srollToSummary} className="d-block d-md-none"></div>
                </div>
              </div>
            </Col>
            <Col lg={12} xl={4} className="col-xxl-4">
              <div className="job_detail_overview mt-3">
                <JobApplicationDetail
                  profileDetail={profileDetail}
                  interviewScheduleDetail={interviewScheduleDetail}
                  userManager={userManager}
                  downloadJobApplicationDocument={
                    downloadJobApplicationDocument
                  }
                  createNewInterviewSchedule={createNewInterviewSchedule}
                  updateJobApplicationStatus={updateJobApplicationStatus}
                  user={user}
                  jobApplicationList={jobApplicationList}
                  setProfileDetail={setProfileDetail}
                  updateInterviewSchedule={updateInterviewSchedule}
                  setInterviewScheduleDetail={setInterviewScheduleDetail}
                  closeJobApplication={closeJobApplication}
                  techDomainList={techDomainList}
                  designationList={designationList}
                  aplicationDomainList={aplicationDomainList}
                  updateJobApplication={updateJobApplication}
                  locationList={locationList}
                  updateInterviewScheduleMeetingLink={
                    updateInterviewScheduleMeetingLink
                  }
                  date={date}
                  desData={desData}
                  combineCalenderData={combineCalenderData}
                  onsetCombineCalenderList={onsetCombineCalenderList}
                  onsetDate={onsetDate}
                  onsetDescription={onsetDescription}
                  totalInterviewScheduleList={totalInterviewScheduleList}
                  holidaysList={holidaysList}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default JobApplicationsView;
