import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { ButtonView, Input } from "../../../controls";

const UserFilter = ({
  filterShow,
  handleClose,
  userListFilter,
  getUserList,
  onsetFilterData,
  onresetFilterPagination,
  handleIsFilterActive,
  handleClearFilter,
  getUserWfhAndLeaveData,
  getUserWfhAndLeave,
  userList,
}) => {
  const viewData = {
    employeeId: "",
    startDate: "",
    endDate: "",
  };

  const [data, setData] = useState(viewData);
  const [type, setType] = useState("");

  const [errors, setErrors] = useState({});

  const modifliedUserList = userList.map((list) => {
    return {
      label: `${list.firstName} ${list.lastName}`,
      value: list._id,
    };
  });

  const handleSubmit = () => {
    var filterData = {};

    if (data.employeeId) {
      filterData.employeeId = data.employeeId;
    }
    if (data.startDate) {
      filterData.startDate = data.startDate;
    }
    if (data.endDate) {
      filterData.endDate = data.endDate;
    }

    const rules = {
      required: [],
    };

    if (type.value) {
      rules.required.push("startDate", "endDate");
    }

    const { errors } = validator.validate(rules, {
      ...trimFieldsData(data),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      var startdate = new Date(data.startDate);
      var enddate = new Date(data.endDate);
      if (enddate < startdate) {
        alert("endDate should greater than startDate ");
      } else {
        getUserWfhAndLeave(1, filterData, false);
        onsetFilterData(filterData);
        if (data.employeeId || data.startDate || data.endDate)
          handleIsFilterActive(true);
        handleClose();
        setData("");
      }
    }
  };
  const handleReset = () => {
    handleClearFilter();
    setData("");
    handleClose();
  };

  return (
    <div className="filter_modal">
      <Modal
        show={filterShow}
        onHide={handleClose}
        aria-labelledby="filter_modal"
      >
        <Modal.Header>
          <Modal.Title id="filter_modal">Filter</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex align-items-end mt-2">
            <Col sm={12} lg={12} className="mb-3 mb-lg-0">
              <span className="label text-muted">Select Employee Name</span>
              <Select
                name="type"
                label="type"
                // label="Select Employee Name"
                defaultValue={
                  viewData.FullName
                    ? modifliedUserList.find(
                        (list) => list.label === data.FullName
                      )
                    : ""
                }
                isSearchable={true}
                placeholder="Search"
                options={modifliedUserList}
                // value={data.FullName}
                onChange={(val) => {
                  // console.log("val", val);
                  setType(val);
                  setData({
                    ...data,
                    employeeId: val.value,
                    startDate: "",
                    endDate: "",
                  });
                  //  setEmployeeData({ ...employeeData, employeeId: val.value });
                }}
                className="select"
                classNamePrefix="select"
                // error={errors["employeeId"]}
              />
            </Col>

            <Col md={12} lg={12} className="mb-3 mt-2">
              <Input
                name="startDate"
                label="Start Date"
                value={data.startDate}
                // value={moment().max(date.startDate)}
                type="date"
                onChange={(e) =>
                  setData({
                    ...data,
                    startDate: e.target.value,
                  })
                }
                size="fluid"
                // min={moment(new Date()).format("YYYY-MM-DD")}
                error={errors["startDate"]}
                // isrequired={true}
              />
            </Col>

            <Col md={12} lg={12} className="mb-3">
              <Input
                name="endDate"
                label="End Date"
                value={data.endDate}
                // value={moment().max(date.endDate, "DD/MM/YY")}
                type="date"
                onChange={(e) =>
                  setData({
                    ...data,
                    endDate: e.target.value,
                  })
                }
                size="fluid"
                // min={date.startDate}
                max={new Date().toISOString().split("T")[0]}
                error={errors["endDate"]}
                // isrequired={true}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-2">
            <Col xs={12} md={6} lg={4} className="mb-2">
              <ButtonView
                // onClick={handleClearFilter}
                onClick={handleReset}
                title={"Reset"}
                styles="primal"
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <ButtonView
                onClick={handleSubmit}
                title={"Apply Filter"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserFilter;
