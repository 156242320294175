import React from "react";

import { Card, Row, Col } from "react-bootstrap";

const LeaveSummary = ({
  currentYear,
  employeeLeavesSummary,
  otherLeavesSummary,
  optionalHolidaySummary,
}) => {
  return (
    <Card className="mt-3">
      <Card.Body>
        <div className="user__detail__container">
          {/* <p style={{ color: "#d87d2e", fontSize: "20px" }} className="mb-1 ">
            Leave summary of year {currentYear}
          </p> */}
          <Row>
            <Col sm={12} lg={6}>
              {employeeLeavesSummary && (
                <>
                  <p
                    style={{ color: "#d87d2e", fontSize: "18px" }}
                    className="mb-1 "
                  >
                    Leave summary of year {currentYear}
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Total Paid Leaves</span>
                    <span>
                      :{" "}
                      {employeeLeavesSummary.totalPaidLeaves.toString().length >
                      1
                        ? `${employeeLeavesSummary.totalPaidLeaves}`
                        : `0${employeeLeavesSummary.totalPaidLeaves}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Approved Leaves</span>
                    <span>
                      :{" "}
                      {employeeLeavesSummary.approved.toString().length > 1
                        ? `${employeeLeavesSummary.approved}`
                        : `0${employeeLeavesSummary.approved}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Pending Approval</span>
                    <span>
                      :{" "}
                      {employeeLeavesSummary.pendingApproval.toString().length >
                      1
                        ? `${employeeLeavesSummary.pendingApproval}`
                        : `0${employeeLeavesSummary.pendingApproval}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Rejected</span>
                    <span>
                      :{" "}
                      {employeeLeavesSummary.rejected.toString().length > 1
                        ? `${employeeLeavesSummary.rejected}`
                        : `0${employeeLeavesSummary.rejected}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Availed</span>
                    <span>
                      :{" "}
                      {employeeLeavesSummary.availed.toString().length > 1
                        ? `${employeeLeavesSummary.availed}`
                        : `0${employeeLeavesSummary.availed}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Cancelled</span>
                    <span>
                      :{" "}
                      {employeeLeavesSummary.cancelled.toString().length > 1
                        ? `${employeeLeavesSummary.cancelled}`
                        : `0${employeeLeavesSummary.cancelled}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Total Leaves Available</span>
                    <span>
                      :{" "}
                      {employeeLeavesSummary.availableLeaves.toString().length >
                      1
                        ? `${employeeLeavesSummary.availableLeaves}`
                        : `0${employeeLeavesSummary.availableLeaves}`}
                    </span>
                  </p>
                </>
              )}
            </Col>
            <div className="d-block d-lg-none">
              <hr />
            </div>
            <Col sm={12} lg={6}>
              {((otherLeavesSummary[0].leaveCount > 0 &&
                otherLeavesSummary[0].status !== -2) ||
                (otherLeavesSummary[1].leaveCount > 0 &&
                  otherLeavesSummary[1].status !== -2)) && (
                <>
                  <div style={{ color: "#d87d2e" }} className="extra_info">
                    Extra Information
                  </div>
                </>
              )}
              {(otherLeavesSummary[0].leaveCount &&
                otherLeavesSummary[0].status !== -2) > 0 && (
                <p className="mb-1 d-flex justify-content-between">
                  <span>Maternity Leaves (No. of Leaves)</span>
                  <span>: {otherLeavesSummary[0].leaveCount}</span>
                </p>
              )}
              {(otherLeavesSummary[1].leaveCount &&
                otherLeavesSummary[1].status !== -2) > 0 && (
                <p className="mb-1 d-flex justify-content-between mb-2">
                  <span>Paternity Leaves (No. of Leaves)</span>
                  <span>: {otherLeavesSummary[1].leaveCount}</span>
                </p>
              )}
              <p
                style={{ color: "#d87d2e", fontSize: "18px" }}
                className="mb-1"
              >
                Optional Festival Leave Summary of year {currentYear}
              </p>
              {optionalHolidaySummary && (
                <>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Total Optional Festival Leaves</span>
                    {/* <span>Optional Festival Leave Summary</span> */}
                    <span>
                      :{" "}
                      {optionalHolidaySummary.totalOptionalHolidayLeavesAllowed.toString()
                        .length > 1
                        ? `${optionalHolidaySummary.totalOptionalHolidayLeavesAllowed}`
                        : `0${optionalHolidaySummary.totalOptionalHolidayLeavesAllowed}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Approved Leaves</span>
                    <span>
                      :{" "}
                      {optionalHolidaySummary.approved.toString().length > 1
                        ? `${optionalHolidaySummary.approved}`
                        : `0${optionalHolidaySummary.approved}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Pending Approval</span>
                    <span>
                      :{" "}
                      {optionalHolidaySummary.pendingApproval.toString()
                        .length > 1
                        ? `${optionalHolidaySummary.pendingApproval}`
                        : `0${optionalHolidaySummary.pendingApproval}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Rejected</span>
                    <span>
                      :{" "}
                      {optionalHolidaySummary.rejected.toString().length > 1
                        ? `${optionalHolidaySummary.rejected}`
                        : `0${optionalHolidaySummary.rejected}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Availed</span>
                    <span>
                      :{" "}
                      {optionalHolidaySummary.availed.toString().length > 1
                        ? `${optionalHolidaySummary.availed}`
                        : `0${optionalHolidaySummary.availed}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Cancelled</span>
                    <span>
                      :{" "}
                      {optionalHolidaySummary.cancelled.toString().length > 1
                        ? `${optionalHolidaySummary.cancelled}`
                        : `0${optionalHolidaySummary.cancelled}`}
                    </span>
                  </p>
                  <p className="mb-1 d-flex justify-content-between">
                    <span>Optional Festival Leaves Available</span>
                    <span>
                      :{" "}
                      {optionalHolidaySummary.availableLeaves.toString()
                        .length > 1
                        ? `${optionalHolidaySummary.availableLeaves}`
                        : `0${optionalHolidaySummary.availableLeaves}`}
                    </span>
                  </p>
                </>
              )}
              {((otherLeavesSummary[0].leaveCount > 0 &&
                otherLeavesSummary[0].status !== -2) ||
                (otherLeavesSummary[1].leaveCount > 0 &&
                  otherLeavesSummary[1].status !== -2)) && (
                <>
                  <hr />
                  <div style={{ color: "#d87d2e" }} className="extra_info">
                    Extra Information
                  </div>
                </>
              )}
              {(otherLeavesSummary[0].leaveCount &&
                otherLeavesSummary[0].status !== -2) > 0 && (
                <p className="mb-1 d-flex justify-content-between">
                  <span>Maternity Leaves (No. of Leaves)</span>
                  <span>: {otherLeavesSummary[0].leaveCount}</span>
                </p>
              )}
              {(otherLeavesSummary[1].leaveCount &&
                otherLeavesSummary[1].status !== -2) > 0 && (
                <p className="mb-1 d-flex justify-content-between">
                  <span>Paternity Leaves (No. of Leaves)</span>
                  <span>: {otherLeavesSummary[1].leaveCount}</span>
                </p>
              )}
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LeaveSummary;
