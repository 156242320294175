import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  PERMISSION_PENDING: "PERMISSION_PENDING",
  PERMISSION_SUCCESS: "PERMISSION_SUCCESS",
  PERMISSION_ERROR: "PERMISSION_ERROR",

  PERMISSION_ADD_PENDING: "PERMISSION_ADD_PENDING", 
  PERMISSION_ADD_ERROR: "PERMISSION_ADD_ERROR", 
  PERMISSION_ADD_SUCCESS: "PERMISSION_ADD_SUCCESS",

  PERMISSION_EDIT_PENDING: "PERMISSION_EDIT_PENDING", 
  PERMISSION_EDIT_ERROR: "PERMISSION_EDIT_ERROR", 
  PERMISSION_EDIT_SUCCESS: "PERMISSION_EDIT_SUCCESS",

  PERMISSION_DELETE_PENDING: "PERMISSION_DELETE_PENDING", 
  PERMISSION_DELETE_ERROR: "PERMISSION_DELETE_ERROR", 
  PERMISSION_DELETE_SUCCESS: "PERMISSION_DELETE_SUCCESS",
};

export function addPermission(value, displayName) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PERMISSION_ADD_PENDING,
      payload: {
        permissionAddPending: true,
        permissionAddSuccess: false,
        permissionAddError: null,
      },
    });

    const data = {
      name: value,
      displayName: displayName
    }
    // console.log(data)

    Api.post("permission/create", data)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully added new permission") {
          dispatch({
            type: actionTypes.PERMISSION_ADD_SUCCESS,
            payload: {
              permissionAddPending: false,
              permissionAddSuccess: true,
              permissionAddError: null,
  
              permission: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new permission.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.PERMISSION_ADD_ERROR,
          payload: {
            permissionAddPending: false,
            permissionAddSuccess: true,
            permissionAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add permission.",
          })
        );
      })
  }
}

export function editPermission(value, displayName, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PERMISSION_EDIT_PENDING,
      payload: {
        permissionEditPending: true,
        permissionEditSuccess: false,
        permissionEditError: null,
      },
    });

    const data = {
      name: value,
      displayName: displayName
    }
    console.log(data, id)

    Api.put(`permission/update/${id}`, data)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully updated permission") {
          dispatch({
            type: actionTypes.PERMISSION_EDIT_SUCCESS,
            payload: {
              permissionEditPending: false,
              permissionEditSuccess: true,
              permissionEditError: null,
  
              permission: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated permission.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.PERMISSION_EDIT_ERROR,
          payload: {
            permissionEditPending: false,
            permissionEditSuccess: true,
            permissionEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit permission.",
          })
        );
      })
  }
}

export function deletePermission(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PERMISSION_DELETE_PENDING,
      payload: {
        permissionDeletePending: true,
        permissionDeleteSuccess: false,
        permissionDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`permission/delete/${id}`)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully deleted permission") {
          dispatch({
            type: actionTypes.PERMISSION_DELETE_SUCCESS,
            payload: {
              permissionDeletePending: false,
              permissionDeleteSuccess: true,
              permissionDeleteError: null,
  
              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted permission.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.PERMISSION_DELETE_ERROR,
          payload: {
            permissionDeletePending: false,
            permissionDeleteSuccess: true,
            permissionDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add permission.",
          })
        );
      })
  }
}

export function assignPermission(data, mutiple) {
  return (dispatch) => {
    if(mutiple) {
      console.log(data)
    } else {
      console.log(data)
    }
    // console.log(data)
  }
}

export function list() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PERMISSION_PENDING,
      payload: {
        permissionPending: true,
        permissionSuccess: false,
        permissionError: null,
      },
    });

    Api.post("permission/list")
      .then(response => {
        if(response.message === "Successfully fetched permission list") {
          dispatch({
            type: actionTypes.PERMISSION_SUCCESS,
            payload: {
              permissionPending: false,
              permissionSuccess: true,
              permissionError: null,
  
              permissionList: response.data,
            },
          });
        }
      })
      .catch(err => {
        dispatch({
          type: actionTypes.PERMISSION_ERROR,
          payload: {
            permissionPending: false,
            permissionSuccess: true,
            permissionError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get permission list.",
          })
        );
      })
  }
}