import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  DESIGNATION_PENDING: "DESIGNATION_PENDING",
  DESIGNATION_SUCCESS: "DESIGNATION_SUCCESS",
  DESIGNATION_ERROR: "DESIGNATION_ERROR",

  DESIGNATION_ADD_PENDING: "DESIGNATION_ADD_PENDING",
  DESIGNATION_ADD_ERROR: "DESIGNATION_ADD_ERROR",
  DESIGNATION_ADD_SUCCESS: "DESIGNATION_ADD_SUCCESS",

  DESIGNATION_EDIT_PENDING: "DESIGNATION_EDIT_PENDING",
  DESIGNATION_EDIT_ERROR: "DESIGNATION_EDIT_ERROR",
  DESIGNATION_EDIT_SUCCESS: "DESIGNATION_EDIT_SUCCESS",

  DESIGNATION_DELETE_PENDING: "DESIGNATION_DELETE_PENDING",
  DESIGNATION_DELETE_ERROR: "DESIGNATION_DELETE_ERROR",
  DESIGNATION_DELETE_SUCCESS: "DESIGNATION_DELETE_SUCCESS",
};

export function addDesignation(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DESIGNATION_ADD_PENDING,
      payload: {
        designationAddPending: true,
        designationAddSuccess: false,
        designationAddError: null,
      },
    });
    // console.log(data)

    Api.post("recruitmentManagement/hradmin/addDesignation", data)
      .then((response) => {
        if (response.message === "Successfully added new designation") {
          dispatch({
            type: actionTypes.DESIGNATION_ADD_SUCCESS,
            payload: {
              designationAddPending: false,
              designationAddSuccess: true,
              designationAddError: null,

              designation: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new designation.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DESIGNATION_ADD_ERROR,
          payload: {
            designationAddPending: false,
            designationAddSuccess: true,
            designationAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add holiday.",
          })
        );
      });
  };
}

export function editDesignation(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DESIGNATION_EDIT_PENDING,
      payload: {
        designationEditPending: true,
        designationEditSuccess: false,
        designationEditError: null,
      },
    });

    Api.put(`recruitmentManagement/hradmin/updateDesignation/${id}`, data)
      .then((response) => {
        if (response.message === "Successfully updated designation") {
          dispatch({
            type: actionTypes.DESIGNATION_EDIT_SUCCESS,
            payload: {
              designationEditPending: false,
              designationEditSuccess: true,
              designationEditError: null,

              designation: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated designation.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.DESIGNATION_EDIT_ERROR,
          payload: {
            designationEditPending: false,
            designationEditSuccess: true,
            designationEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit holiday.",
          })
        );
      });
  };
}

export function deleteDesignation(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DESIGNATION_DELETE_PENDING,
      payload: {
        designationDeletePending: true,
        designationDeleteSuccess: false,
        designationDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`recruitmentManagement/hradmin/deleteDesignation/${id}`)
      .then((response) => {
        if (response.message === "Successfully deleted designation") {
          dispatch({
            type: actionTypes.DESIGNATION_DELETE_SUCCESS,
            payload: {
              designationDeletePending: false,
              designationDeleteSuccess: true,
              designationDeleteError: null,

              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted designation.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DESIGNATION_DELETE_ERROR,
          payload: {
            designationDeletePending: false,
            designationDeleteSuccess: true,
            designationDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add holiday.",
          })
        );
      });
  };
}

export function list() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DESIGNATION_PENDING,
      payload: {
        designationPending: true,
        designationSuccess: false,
        designationError: null,
      },
    });

    Api.post("recruitmentManagement/hradmin/getDesignationsList")
      .then((response) => {
        if (response.message === "Successfully fetched designations list") {
          dispatch({
            type: actionTypes.DESIGNATION_SUCCESS,
            payload: {
              designationPending: false,
              designationSuccess: true,
              designationError: null,

              designationList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.DESIGNATION_SUCCESS,
          payload: {
            designationPending: false,
            designationSuccess: true,
            designationError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DESIGNATION_ERROR,
          payload: {
            designationPending: false,
            designationSuccess: true,
            designationError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get designation list.",
          })
        );
      });
  };
}
