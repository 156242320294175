import Api from "../../service/api";
import { globals } from "../globals";
import { LUNCH, LOGIN } from "../../navigation/routes";
import { showAlert } from "../alert/alertActions";
import { COOKIE_DOMAIN } from "../../constants/env";
import Cookies from "universal-cookie";

export const actionTypes = {
  AUTH_PENDING: "AUTH_PENDING",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",
};

export function logout() {
  return dispatch => {
    localStorage.clear();
    dispatch({
      type: actionTypes.AUTH_PENDING,
      payload: {
        authPending: false,
        authSuccess: false,
        authError: null,
        token: null,
        profileurl: null,
        loginUser: {
          user_role: "",
          user_id: "",
          userPermissions: [],
          level: 0,
        },
      },
    });
    globals.history.push(LOGIN);
  };
}

export function login(googleData, history, url, type, mode = "cookie") {
  // console.log("googleData", typeof url);
  return dispatch => {
    dispatch({
      type: actionTypes.AUTH_PENDING,
      payload: {
        authPending: true,
        authSuccess: false,
        authError: null,
        token: null,
        profileurl: null,
        loginUser: null,
      },
    });

    const data = {
      provider: "GOOGLE",
      social_data: googleData,
    };

    // document.cookie =
    //   "sso_local=" +
    //   JSON.stringify(data) +
    //   ";  max-age=" +
    //   60 * 1 +
    //   `;domain=${LOCALDOMAIN}`;

    // window.open(url, "_self");
    // alert(document.cookie);

    Api.post("auth/socialSignin", data)

      .then(response => {
        // console.log("response", response);
        if (response.status === "true") {
          localStorage.setItem("token", JSON.stringify(response.token));
          localStorage.setItem("tokenExpiresOn", JSON.stringify(response.tokenExpiresOn));
          // localStorage.setItem(
          //   "profileurl",
          //   JSON.stringify(googleData.profileObj.imageUrl)
          // );
          // console.log("JSON.stringify(response)", JSON.stringify(response));
          localStorage.setItem("loginUser", JSON.stringify(response));

          dispatch({
            type: actionTypes.AUTH_SUCCESS,
            payload: {
              authPending: false,
              authSuccess: true,
              authError: null,
              token: response.token,
              profileurl: null,
              loginUser: response,
            },
          });
          //cookies

          // console.log("url", url);
          // if (type) {
          if (url) {
            var final_data = JSON.parse(JSON.stringify(response));
            delete final_data.userPermissions;
            if (mode === "queryString") {
              const query = new URLSearchParams(final_data).toString();
              window.open(url + "?" + query, "_self");
            } else {
              var cookies = new Cookies();
              cookies.set("usercredentials", JSON.stringify(final_data), {
                maxAge: 60 * 1,
                domain: COOKIE_DOMAIN,
                sameSite: "none",
                secure: COOKIE_DOMAIN === "localhost" ? false : true,
              });
              setTimeout(() => {
                window.open(url, "_self");
              }, 1000);
            }

            return;
          }
          // }
          // console.log("globals", globals);
          if (globals.history.location.state) {
            globals.history.push(globals.history.location.state.from.pathname + globals.history.location.state.from.search);
          } else {
            globals.history.push(LUNCH);
          }
        } else {
          dispatch({
            type: actionTypes.AUTH_ERROR,
            payload: {
              authPending: false,
              authSuccess: true,
              authError: false,
              token: null,
              profileurl: null,
              loginUser: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Invalid email.",
            })
          );
        }
      })
      .catch(e => {
        console.log(e);
        dispatch({
          type: actionTypes.AUTH_ERROR,
          payload: {
            authPending: false,
            authSuccess: true,
            authError: false,
            token: null,
            profileurl: null,
            loginUser: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Something went wrong.",
          })
        );
      });
  };
}
