import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Select } from "../../../controls";

const data = {
  location: "",
  bookingCloseStartTime: "",
  bookingCloseEndTime: "",
};

const LunchPreferenceOpenPeriod = ({
  locationList,
  addLunchBlockingTime,
  lunchBlockingTimeList,
  editLunchBlockingTime,
}) => {
  const locationOptions = locationList.map((location) => {
    return { value: location._id, label: location.name };
  });
  const timeList = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];
  const periodTime = [
    { label: "am", value: "am" },
    { label: "pm", value: "pm" },
  ];

  const [showAddTime, setShowAddTime] = useState(false);
  const [lunchData, setLunchData] = useState(data);
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [lunchId, setLunchId] = useState("");
  const [startPeriod, setStartPeriod] = useState("am");
  const [endPeriod, setEndPeriod] = useState("am");
  // console.log(locationList);
  const columns = [
    {
      name: "Location",
      // selector: "location",
      sortable: true,
      grow: 8,
      cell: (row) => {
        return locationOptions
          .filter((list) => list.value === row.location)
          .map((list) => list.label);
      },
    },
    {
      name: "Start Time",
      // selector: "bookingCloseStartTime",
      sortable: true,
      grow: 8,
      cell: (row) => (
        <span>
          {row.bookingCloseStartTime.split(":")[0] > 12
            ? `${row.bookingCloseStartTime.split(":")[0] - 12} pm`
            : `${row.bookingCloseStartTime.split(":")[0]} am`}
        </span>
      ),
    },
    {
      name: "End Time",
      // selector: "bookingCloseEndTime",
      sortable: true,
      grow: 8,
      cell: (row) => (
        <span>
          {row.bookingCloseEndTime.split(":")[0] > 12
            ? `${row.bookingCloseEndTime.split(":")[0] - 12} pm`
            : `${row.bookingCloseEndTime.split(":")[0]} am`}
        </span>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                const startTime = row.bookingCloseStartTime.split(":");
                const endTime = row.bookingCloseEndTime.split(":");
                const editData = {
                  location: row.location,
                  bookingCloseStartTime:
                    startTime[0] > 12
                      ? (startTime[0] - 12).toString()
                      : startTime[0],
                  bookingCloseEndTime:
                    endTime[0] > 12 ? (endTime[0] - 12).toString() : endTime[0],
                };
                setLunchData(editData);
                setStartPeriod(startTime[0] > 12 ? "pm" : "am");
                setEndPeriod(endTime[0] > 12 ? "pm" : "am");
                setShowEdit(true);
                setShowAddTime(true);
                setLunchId(row._id);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
        </div>
      ),
    },
  ];

  const handleAddRole = () => {
    var rules = {
      required: ["location", "bookingCloseStartTime", "bookingCloseEndTime"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(lunchData),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const newData = {
          bookingCloseStartTime: `${
            startPeriod === "am"
              ? lunchData.bookingCloseStartTime
              : parseInt(lunchData.bookingCloseStartTime) + 12
          }:00`,
          bookingCloseEndTime: `${
            endPeriod === "am"
              ? lunchData.bookingCloseEndTime
              : parseInt(lunchData.bookingCloseEndTime) + 12
          }:00`,
        };
        if (
          parseInt(newData.bookingCloseStartTime.split(":")[0]) >
          parseInt(newData.bookingCloseEndTime.split(":")[0])
        ) {
          alert("Invalid Start Time.");
        } else {
          editLunchBlockingTime(newData, lunchId);
        }
      } else {
        // console.log(lunchData);
        // console.log(startPeriod);
        // console.log(endPeriod);

        const newData = {
          bookingCloseStartTime: `${
            startPeriod === "am"
              ? lunchData.bookingCloseStartTime
              : parseInt(lunchData.bookingCloseStartTime) + 12
          }:00`,
          bookingCloseEndTime: `${
            endPeriod === "am"
              ? lunchData.bookingCloseEndTime
              : parseInt(lunchData.bookingCloseEndTime) + 12
          }:00`,
          location: lunchData.location,
        };
        if (
          parseInt(newData.bookingCloseStartTime.split(":")[0]) >
          parseInt(newData.bookingCloseEndTime.split(":")[0])
        ) {
          alert("Invalid Start Time.");
        } else {
          addLunchBlockingTime(newData);
        }
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddTime(false);
    setLunchData(data);
    setShowEdit(false);
    setErrors({});
    setStartPeriod("am");
    setEndPeriod("am");
    setLunchId("");
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Lunch Blocking Time</div>
              <div className="d-none d-md-block">Lunch Blocking Time</div>
            </Card.Title>
            <div className="add_permission">
              <ButtonView
                onClick={() => setShowAddTime(true)}
                title={"Add Time"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={lunchBlockingTimeList}
            // data={[]}
            columns={columns}
            pagination={true}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddTime}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Blocking Time" : "Add Blocking Time"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="d-flex justify-content-end mb-2">
              {!showEdit && (
                <Col xs={12} className="mb-3">
                  <Select
                    isSearchable={false}
                    onChange={(val) => {
                      setLunchData({ ...lunchData, location: val.value });
                    }}
                    defaultValue={
                      lunchData.location
                        ? locationOptions.find(
                            (option) => option.value === lunchData.location
                          )
                        : ""
                    }
                    placeholder="Select Location"
                    label="Select Location"
                    options={locationOptions}
                    className="select"
                    classNamePrefix="select"
                    error={errors["location"]}
                    isrequired={true}
                  />
                </Col>
              )}

              <Col xs={6}>
                <Row className="g-0 d-flex align-items-end">
                  <Col xs={8}>
                    <Select
                      isSearchable={false}
                      onChange={(val) => {
                        setLunchData({
                          ...lunchData,
                          bookingCloseStartTime: val.value,
                        });
                      }}
                      defaultValue={
                        lunchData.bookingCloseStartTime
                          ? timeList.find(
                              (option) =>
                                option.value === lunchData.bookingCloseStartTime
                            )
                          : ""
                      }
                      placeholder="Start Time"
                      label="Start Time"
                      options={timeList}
                      className="select"
                      classNamePrefix="select"
                      error={errors["bookingCloseStartTime"]}
                      isrequired={true}
                    />
                  </Col>
                  <Col xs={4}>
                    <Select
                      isSearchable={false}
                      onChange={(val) => {
                        setStartPeriod(val.value);
                      }}
                      defaultValue={
                        startPeriod
                          ? periodTime.find(
                              (option) => option.value === startPeriod
                            )
                          : ""
                      }
                      placeholder=""
                      label=""
                      options={periodTime}
                      className="select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row className="g-0 d-flex align-items-end">
                  <Col xs={8}>
                    <Select
                      isSearchable={false}
                      onChange={(val) => {
                        setLunchData({
                          ...lunchData,
                          bookingCloseEndTime: val.value,
                        });
                      }}
                      defaultValue={
                        lunchData.bookingCloseEndTime
                          ? timeList.find(
                              (option) =>
                                option.value === lunchData.bookingCloseEndTime
                            )
                          : ""
                      }
                      placeholder="End Time"
                      label="End Time"
                      options={timeList}
                      className="select"
                      classNamePrefix="select"
                      error={errors["bookingCloseEndTime"]}
                      isrequired={true}
                    />
                  </Col>
                  <Col xs={4}>
                    <Select
                      isSearchable={false}
                      onChange={(val) => {
                        setEndPeriod(val.value);
                      }}
                      defaultValue={
                        endPeriod
                          ? periodTime.find(
                              (option) => option.value === endPeriod
                            )
                          : ""
                      }
                      placeholder=""
                      label=""
                      options={periodTime}
                      className="select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={6} className="mt-3">
                <ButtonView
                  onClick={handleAddRole}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LunchPreferenceOpenPeriod;
