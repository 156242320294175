import React from "react";
import { Spinner } from "react-bootstrap";

import "./LoaderStyles.scss";

const LoaderView = ({ type = "" }) => {
  return (
    <div
      className={` loader ${type}  `}
      // style={{ display: "block", width: 700, padding: 180, marginLeft: 130 }}
    >
      <div className="">
        <Spinner
          // className="loader"
          animation="border"
          role="status"
          style={{ textAlign: "center" }}
        >
          <span className="visually-hidden ">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

LoaderView.propTypes = {};

export default LoaderView;
