import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RiPencilRulerFill } from "react-icons/ri";
// import { RiListSettingsFill } from "react-icons/ri";
import { Header } from "../../common";

import CategoryMgt from "./components/CategoryMgt";
import TechnologyMgt from "./components/TechnologyMgt";
import ProficiencyMgt from "./components/ProficiencyMgt";
import MappingMgt from "./components/MappingMgt";

const SkillManagementView = ({
  categoryList,
  addSkillsCategory,
  updateSkillsCategories,
  deleteSkillCategory,

  ProficiencyList,
  addProficiency,
  updateProficiency,
  deleteProficiency,

  TechnologyList,
  addSkillsTechnology,
  saveCategoryTechnolgy,
  updateSkillsTechnology,
  deleteSkillsTechnology,
  DeleteMappedCategoryTechnolgy,

  getTechnologiesForCategory,
  technologies,
}) => {
  return (
    <div>
      <Header label={"Skill Management"} Icon={RiPencilRulerFill} />
      <div className="main__body padding">
        <Container>
          <Row>
            <Col lg={6} xl={6}>
              <CategoryMgt
                categoryList={categoryList}
                addSkillsCategory={addSkillsCategory}
                updateSkillsCategories={updateSkillsCategories}
                deleteSkillCategory={deleteSkillCategory}
              />
            </Col>
            <Col lg={6} xl={6}>
              <TechnologyMgt
                categoryList={categoryList}
                TechnologyList={TechnologyList}
                addSkillsTechnology={addSkillsTechnology}
                updateSkillsTechnology={updateSkillsTechnology}
                deleteSkillsTechnology={deleteSkillsTechnology}
                getTechnologiesForCategory={getTechnologiesForCategory}
                technologies={technologies}
              />
            </Col>
            <Col lg={6} xl={6}>
              <ProficiencyMgt
                ProficiencyList={ProficiencyList}
                addProficiency={addProficiency}
                updateProficiency={updateProficiency}
                deleteProficiency={deleteProficiency}
              />
            </Col>
            <Col lg={6} xl={6}>
              <MappingMgt
                categoryList={categoryList}
                TechnologyList={TechnologyList}
                saveCategoryTechnolgy={saveCategoryTechnolgy}
                getTechnologiesForCategory={getTechnologiesForCategory}
                technologies={technologies}
                DeleteMappedCategoryTechnolgy={DeleteMappedCategoryTechnolgy}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SkillManagementView;
