import { actionTypes } from "./joinUsActions";

const { SHOW_NOTIFICATION, HIDE_NOTIFICATION } = actionTypes;

const initialState = {
  msg: "",
  type: "",
};

export default function joinUs(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_NOTIFICATION: {
      return Object.assign({}, state, payload);
    }
    case HIDE_NOTIFICATION: {
      return Object.assign({}, state, payload);
    }
    default:
      return state;
  }
}
