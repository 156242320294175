import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import queryString from "query-string";

import "./JobApplicationsStyles.scss";
import JobApplicationsView from "./JobApplicationsView";
import {
  list as getTechDomains,
  addTechDomain,
} from "../../../store/technicalDomain/technicalDomainActions";
import { list as getDesignations } from "../../../store/Designation/DesignationActions";
import { list as getApplicationDomains } from "../../../store/aplicationDomain/aplicationDomainActions";
import { user as getUser } from "../../../store/user/userActions";
import {
  createJobApplication,
  jobApplication as getJobApplicationList,
  downloadJobApplicationDocument,
  qualificationList,
  userList as getUserList,
  interviewScheduleList as getInterviewScheduleList,
  createNewInterviewSchedule,
  updateJobApplicationStatus,
  updateInterviewSchedule,
  closeJobApplication,
  updateJobApplication,
  jobApplicationInactive as getJobApplicationInactive,
  updateInterviewScheduleMeetingLink,
} from "../../../store/jobApplication/jobApplicationActions";
import { reportingMangerUsersList } from "../../../store/user/userActions";
import { BigLoader, Loader } from "../../common";
import { list as getlocation } from "../../../store/location/locationActions";
import { list as getHolidays } from "../../../store/holidays/holidaysActions";

export class JobApplicationsContainer extends Component {
  state = {
    profileDetail: null,
    interviewScheduleDetail: [],
    rowId: "",
    defaultTabActiveKey: "active",

    date: new Date().toISOString().split("T")[0],
    combineCalenderData: [],
    desData: [],
  };
  constructor(props) {
    super(props);
    if (window.location.search !== "") {
      const parsed = queryString.parse(window.location.search);
      this.state.rowId = parsed.id;
    }

    this.props.getUser();
    this.props.getJobApplicationList({});
    this.props.getTechDomains();
    this.props.getDesignations();
    this.props.getApplicationDomains();
    this.props.getQualificationList();
    this.props.getInterviewScheduleList();
    this.props.getUserList(1, { isActive: "1" });
    this.props.reportingMangerUsersList();
    this.props.getJobApplicationInactive({});
    this.props.getlocation();

    this.props.getHolidays();

    this.srollToSummary = React.createRef();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  setProfileDetail = (data) => {
    this.setState({
      profileDetail: data,
    });
  };
  setInterviewScheduleDetail = (data) => {
    this.setState({
      interviewScheduleDetail: data,
    });
  };
  setRowId = (data) => {
    this.setState({
      rowId: data,
    });
  };
  onsetDefaultTabActiveKey = (data) => {
    this.setState({
      defaultTabActiveKey: data,
    });
  };

  onsetCombineCalenderList = (data) => {
    this.setState({
      combineCalenderData: data,
    });
  };
  onsetDate = (data) => {
    this.setState({
      date: data,
    });
  };
  onsetDescription = (data) => {
    this.setState({
      desData: data,
    });
  };

  render() {
    const {
      user,
      userSuccess,

      techDomainSuccess,
      techDomainList,

      designationSuccess,
      designationList,

      aplicationDomainSuccess,
      aplicationDomainList,

      createJobApplication,
      jobApplicationList,
      jobApplicationSuccess,
      downloadJobApplicationDocument,

      qualificationListSuccess,
      qualificationList,
      getJobApplicationList,

      userList,
      userListSuccess,

      userManager,

      interviewScheduleListSuccess,
      interviewScheduleList,
      createNewInterviewSchedule,
      updateJobApplicationStatus,
      updateInterviewSchedule,
      closeJobApplication,

      updateJobApplication,
      jobApplicationInactiveList,
      locationList,
      updateInterviewScheduleMeetingLink,
      totalInterviewScheduleList,
      holidaysList,
      addTechDomain,
    } = this.props;
    if (
      userSuccess === false ||
      jobApplicationSuccess === false ||
      techDomainSuccess === false ||
      designationSuccess === false ||
      aplicationDomainSuccess === false ||
      qualificationListSuccess === false ||
      userListSuccess === false ||
      interviewScheduleListSuccess === false
    ) {
      return <Loader />;
    } else {
      return (
        <>
          <JobApplicationsView
            techDomainList={techDomainList}
            designationList={designationList}
            aplicationDomainList={aplicationDomainList}
            user={user}
            createJobApplication={createJobApplication}
            jobApplicationList={jobApplicationList}
            profileDetail={this.state.profileDetail}
            setProfileDetail={this.setProfileDetail}
            downloadJobApplicationDocument={downloadJobApplicationDocument}
            qualificationList={qualificationList}
            getJobApplicationList={getJobApplicationList}
            userList={userList}
            userManager={userManager}
            interviewScheduleList={interviewScheduleList}
            interviewScheduleDetail={this.state.interviewScheduleDetail}
            setInterviewScheduleDetail={this.setInterviewScheduleDetail}
            createNewInterviewSchedule={createNewInterviewSchedule}
            updateJobApplicationStatus={updateJobApplicationStatus}
            rowId={this.state.rowId}
            setRowId={this.setRowId}
            updateInterviewSchedule={updateInterviewSchedule}
            closeJobApplication={closeJobApplication}
            srollToSummary={this.srollToSummary}
            updateJobApplication={updateJobApplication}
            jobApplicationInactiveList={jobApplicationInactiveList}
            defaultTabActiveKey={this.state.defaultTabActiveKey}
            onsetDefaultTabActiveKey={this.onsetDefaultTabActiveKey}
            locationList={locationList}
            updateInterviewScheduleMeetingLink={
              updateInterviewScheduleMeetingLink
            }
            onsetCombineCalenderList={this.onsetCombineCalenderList}
            onsetDate={this.onsetDate}
            onsetDescription={this.onsetDescription}
            date={this.state.date}
            desData={this.state.desData}
            combineCalenderData={this.state.combineCalenderData}
            totalInterviewScheduleList={totalInterviewScheduleList}
            holidaysList={holidaysList}
            addTechDomain={addTechDomain}
          />
          <BigLoader />
        </>
      );
    }
  }
}

JobApplicationsContainer.propTypes = {};

const mapStateToProps = (state) => ({
  techDomainSuccess: state.techDomain.techDomainSuccess,
  techDomainList: state.techDomain.techDomainList,

  designationSuccess: state.designation.designationSuccess,
  designationList: state.designation.designationList,

  aplicationDomainSuccess: state.aplicationDomain.aplicationDomainSuccess,
  aplicationDomainList: state.aplicationDomain.aplicationDomainList,

  user: state.user.user,
  userSuccess: state.user.userSuccess,

  jobApplicationList: state.jobApplication.jobApplicationList,
  jobApplicationInactiveList: state.jobApplication.jobApplicationInactiveList,
  jobApplicationSuccess: state.jobApplication.jobApplicationSuccess,

  qualificationList: state.jobApplication.qualificationList,
  qualificationListSuccess: state.jobApplication.qualificationListSuccess,

  interviewScheduleList: state.jobApplication.interviewScheduleList,
  totalInterviewScheduleList: state.jobApplication.totalInterviewScheduleList,
  interviewScheduleListSuccess:
    state.jobApplication.interviewScheduleListSuccess,

  userList: state.jobApplication.userList,
  userListSuccess: state.jobApplication.userListSuccess,

  userManager: state.user.userManager,

  locationList: state.location.locationList,

  holidaysList: state.holidays.holidaysList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTechDomains: getTechDomains,
      getDesignations: getDesignations,
      getApplicationDomains: getApplicationDomains,
      getUser: getUser,
      createJobApplication: createJobApplication,
      getJobApplicationList: getJobApplicationList,
      downloadJobApplicationDocument: downloadJobApplicationDocument,
      getQualificationList: qualificationList,
      getUserList: getUserList,
      reportingMangerUsersList: reportingMangerUsersList,
      getInterviewScheduleList: getInterviewScheduleList,
      createNewInterviewSchedule: createNewInterviewSchedule,
      updateJobApplicationStatus: updateJobApplicationStatus,
      updateInterviewSchedule: updateInterviewSchedule,
      closeJobApplication: closeJobApplication,
      updateJobApplication: updateJobApplication,
      getJobApplicationInactive: getJobApplicationInactive,
      getlocation: getlocation,
      updateInterviewScheduleMeetingLink: updateInterviewScheduleMeetingLink,
      getHolidays: getHolidays,
      addTechDomain: addTechDomain,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobApplicationsContainer);
