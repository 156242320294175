import { actionTypes } from "./levelActions";

const { 
  LEVEL_PENDING, 
  LEVEL_ERROR, 
  LEVEL_SUCCESS,

  LEVEL_ADD_PENDING,
  LEVEL_ADD_ERROR,
  LEVEL_ADD_SUCCESS,

  LEVEL_EDIT_PENDING,
  LEVEL_EDIT_ERROR,
  LEVEL_EDIT_SUCCESS,

  LEVEL_DELETE_PENDING,
  LEVEL_DELETE_ERROR,
  LEVEL_DELETE_SUCCESS,
      } = actionTypes;

const initialState = {
  levelPending: false,
  levelSuccess: false,
  levelError: null,

  levelAddPending: false,
  levelAddSuccess: false,
  levelAddError: null,

  levelEditPending: false,
  levelEditSuccess: false,
  levelEditError: null,

  levelDeletePending: false,
  levelDeleteSuccess: false,
  levelDeleteError: null,

  levelList: [],
};

export default function lunch(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LEVEL_PENDING:
    case LEVEL_ERROR:
    case LEVEL_SUCCESS:
      return Object.assign({}, state, payload);
    case LEVEL_ADD_PENDING:
    case LEVEL_ADD_ERROR:
      return Object.assign({}, state, payload);
    case LEVEL_ADD_SUCCESS: {
      const newRole = payload.level;
      const newLevelList = [...state.levelList, newRole];

      const newState = {
        levelList: newLevelList,
        levelAddPending: payload.levelAddPending,
        levelAddSuccess: payload.levelAddSuccess,
        levelAddError: payload.levelAddError,
      }
      return Object.assign({}, state, newState);
    }
    case LEVEL_EDIT_PENDING:
    case LEVEL_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case LEVEL_EDIT_SUCCESS: {
      const newLevelList = state.levelList.map(list => {
        if(list._id === payload.level._id) {
          return payload.level
        } else {
          return list
        }
      })
      return Object.assign({}, state, {levelList: newLevelList}, payload);
    }
    case LEVEL_DELETE_PENDING:
    case LEVEL_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case LEVEL_DELETE_SUCCESS: {
      const newLevelList = state.levelList.filter(list => {
        return list._id !== payload.id
      })
      return Object.assign({}, state, {levelList: newLevelList}, payload);
    }
    default:
      return state;
  }
}
