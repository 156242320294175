import React, { useState } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { ButtonView, TextArea, Pagination } from "../../../controls";
import { DataTable } from "../../../common";
import { AiFillEye } from "react-icons/ai";
import {
  LEAVE_STATUS_PENDING_APPROVAL,
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
} from "../../../../constants/env";
import { FiEdit3 } from "react-icons/fi";
import EditWFHFormModal from "./EditWFHFormModal";
import ModalView from "./ModalView";
import LoaderView from "./LoaderView";
const Unapproved = ({
  data,
  user,
  //   initDoc,
  getDocument,
  userDocument,
  updateLeave,
  downloadFile,
  onsetLeaveId,
  leaveId,
  getNextYearLeaveDependOnId,
  currentYear,
  leaveMasterTypeList,
  leaveTypesList,
  updateLeaveDetail,
  userWfhList,
  rowsPerPage,
  rowCount,
  currentPage,
  getUserWfhList,
  getUserWfh,
  updateWfh,
  updateWFHDetail,
  userWfhListSuccess,
  Id,
  changingYear,
}) => {
  // console.log("changingyr", changingYear);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [editWfhDetailsShow, setEditWfhDetailsShow] = useState(false);
  const [rejectionVal, setRejectionVal] = useState("");
  const [rejectionId, setRejectionId] = useState("");
  const [errors, setErrors] = useState({});
  const [editWfhData, setEditWfhData] = useState({
    leaveSubType: "",
    lastUpdatedBy: "",
    startDate: "",
    endDate: "",
    toalDays: "",
    file: null,
  });

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="badge bg-warning-transparent">Pending Approval</span>
      ),
    },

    {
      name: "FD/1H/2H",
      selector: "leaveSubType",
      center: true,
      grow: 4,
    },
    {
      name: "Employee",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {row.userId[0].firstName} {row.userId[0].lastName.slice(0, 1)}
        </span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "70px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <AiFillEye
            className="view"
            size={18}
            onClick={() => {
              getUserWfh(row._id);

              if (row.attachementDocumentId) {
                getDocument(
                  row.employeeId[0],
                  true,
                  false,
                  row.attachementDocumentId
                );
              }
            }}
          />
          <FiEdit3
            size={17}
            className="edit"
            onClick={() => {
              handleOpen(row);
              if (row.attachementDocumentId) {
                getDocument(
                  row.employeeId[0],
                  true,
                  false,
                  row.attachementDocumentId
                );
              }
            }}
          />
        </div>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "200px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => handleApproveLeave(row)}
              title={"Approve"}
              size="small"
            />
          </div>
          <ButtonView
            onClick={() => handleRejectOpen(row)}
            title={"Reject"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleRejectOpen = (row) => {
    setRejectionId(row._id);
    setRejectOpen(true);
  };

  const handleApproveLeave = (row) => {
    // console.log("row", row);
    var setKey = 0;
    if (window.confirm("Approve WFH?")) {
      updateWfh(
        row._id,
        user,
        "",
        LEAVE_STATUS_APPROVED,
        "Successfully approved WFH.",
        changingYear,
        setKey
      );
      handleClose();
    }
  };

  const handleRejectLeave = () => {
    var rules = {
      required: ["rejectionVal"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ rejectionVal }),
    });

    setErrors(errors);
    var setKey = 0;
    if (!Object.keys(errors).length) {
      // console.log(
      //   "rowreject",
      //   rejectionId,
      //   user,
      //   rejectionVal,
      //   LEAVE_STATUS_REJECTED,
      //   "Successfully approved WFH"
      // );
      updateWfh(
        rejectionId,
        user,
        rejectionVal,
        LEAVE_STATUS_REJECTED,
        "Successfully rejected leave.",
        changingYear,
        setKey
      );
      handleClose();
    }
  };

  const handleOpen = (data) => {
    var setKey = 0;
    const WfhDetails = {
      id: data._id,
      leaveSubType: data.leaveSubType,
      startDate: data.startDate,
      endDate: data.endDate,
      approver: data.reportingManagerName,
      comments: data.comments,
      totalDays: data.totalDays,
      setKey: setKey,
    };
    setEditWfhData(WfhDetails);
    setEditWfhDetailsShow(true);
  };

  const handleClose = () => {
    setEditWfhDetailsShow(false);
    setRejectOpen(false);
    setRejectionId("");
    setErrors({});
    setRejectionVal("");
  };
  const onChangePage = (currentPage) => {
    getUserWfhList(
      currentPage,
      {
        status: LEAVE_STATUS_PENDING_APPROVAL,
        year: changingYear,
      },
      false
    );
  };

  if (userWfhListSuccess === false) {
    return <LoaderView />;
  } else {
    return (
      <>
        <div className="table_content px-2 attendance_table pb-3">
          <DataTable
            data={userWfhList}
            columns={columns}
            pagination={false}
            paginationPerPage={5}
            paginationServer={false}
          />
        </div>

        <div className="reject_modal">
          <Modal
            show={rejectOpen}
            onHide={handleClose}
            aria-labelledby="reject_modal"
          >
            <Modal.Header>
              <Modal.Title id="reject_modal">Rejection Reason</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <TextArea
                  name="comments"
                  placeholder="Reason..."
                  label="Kindly provide the reason for rejecting the WFH."
                  value={rejectionVal}
                  type="textarea"
                  setFocus={1}
                  onChange={(e) => setRejectionVal(e.target.value)}
                  size="fluid"
                  error={errors["rejectionVal"]}
                  isrequired={true}
                />
              </div>
              <Row>
                <Col md={12} lg={6} className="mb-2">
                  <ButtonView
                    onClick={handleClose}
                    title={"Cancel"}
                    styles="danger-outline"
                  />
                </Col>
                <Col md={12} lg={6}>
                  <ButtonView
                    onClick={handleRejectLeave}
                    title={"Submit Rejection"}
                    styles="secondary"
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>

        {editWfhDetailsShow && (
          <div className="edit_modal">
            <EditWFHFormModal
              editWfhDetailsShow={editWfhDetailsShow}
              handleClose={handleClose}
              editWfhData={editWfhData}
              setEditWfhData={setEditWfhData}
              userDocument={userDocument}
              downloadFile={downloadFile}
              updateWFHDetail={updateWFHDetail}
              changingYear={changingYear}
            />
          </div>
        )}

        {userWfhList && <ModalView handleRejectOpen={handleRejectOpen} />}

        {userWfhList.length > 0 ? (
          <div className="margin_x mt-2 me-3 mb-3 d-flex justify-content-end">
            <Pagination
              rowsPerPage={rowsPerPage}
              rowCount={rowCount}
              onChangePage={onChangePage}
              currentPage={currentPage}
            />
          </div>
        ) : null}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    userWfhList: state.employeeWfhReducer.userWfhList,
    page: state.employeeWfhReducer.page,
    userWfhListSuccess: state.employeeWfhReducer.userWfhListSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getUser: getUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Unapproved);
