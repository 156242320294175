import React, { useState } from "react";
import { Container } from "react-bootstrap";
import All from "./All";
import { ButtonView } from "../../../controls";
import AddMySkill from "./AddMySkill";

const MySkills = ({
  mySkillList,
  proficiencies,
  categories,
  technologies,
  getTechnologies,
  updateSkills,
  saveSkills,
  saveMappingData,
  fetchSkillCertificate,
  mySkillCertificates,
  downloadSkillCertificates,
  uploadSkillCertificates,
  deleteSkillCertificate,
  deleteSkill,
  newSkillId,
  DuplicateSkillMessage,
  ErrorMessage,
}) => {
  const [AddMySkillShow, setAddMySkillShow] = useState(false);

  const handleOpenAddUser = () => {
    setAddMySkillShow(true);
  };

  const handleClose = () => {
    setAddMySkillShow(false);
  };

  return (
    <Container>
      <div classname="my_skills_card">
        <div className="card">
          <div className="card-header border-0 mb-3">
            <div className="d-flex justify-content-between align-item-center mb-3">
              <h5
                className="card-title d-flex justify-content-center align-items-center"
                style={{ color: "#d87d2e" }}
              >
                My Skills List
              </h5>
              <div style={{ width: "150px" }}>
                <ButtonView
                  title="Add Skill"
                  onClick={handleOpenAddUser}
                ></ButtonView>
              </div>
            </div>

            {/* <Tabs> */}
            {/* <Tab eventKey="My-Skills" title="All"> */}
            <All
              mySkillList={mySkillList}
              proficiencies={proficiencies}
              categories={categories}
              technologies={technologies}
              getTechnologies={getTechnologies}
              updateSkills={updateSkills}
              fetchSkillCertificate={fetchSkillCertificate}
              mySkillCertificates={mySkillCertificates}
              downloadSkillCertificates={downloadSkillCertificates}
              uploadSkillCertificates={uploadSkillCertificates}
              deleteSkillCertificate={deleteSkillCertificate}
              deleteSkill={deleteSkill}
            />
            {/* </Tab> */}
            {/* </Tabs> */}
          </div>

          <AddMySkill
            AddMySkillShow={AddMySkillShow}
            categories={categories}
            technologies={technologies}
            getTechnologies={getTechnologies}
            proficiencies={proficiencies}
            saveSkills={saveSkills}
            saveMappingData={saveMappingData}
            uploadSkillCertificates={uploadSkillCertificates}
            handleClose={handleClose}
            newSkillId={newSkillId}
            DuplicateSkillMessage={DuplicateSkillMessage}
            mySkillList={mySkillList}
            ErrorMessage={ErrorMessage}
          />
        </div>
      </div>
    </Container>
  );
};

export default MySkills;
