/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import moment from "moment";
import { FaFilter } from "react-icons/fa";
// import * as validator from "../../../helpers/validator";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderView from "./LoaderView";
import { CircularButtonView } from "../../../controls";
// import { trimFieldsData } from "../../../utils";

const QueryList = ({
  getUserQueryList,
  userQueryList,
  totalDocsData,
  nextPagee,
  getUserQuery,
  userQueryListSuccess,
  getUserQueryData,
  ModalNullGetQueryData,
  queryStatusOptions,
  onchangeStatus,
  addStatusToMessages,
  userId,
  handleOpenFilter,
  fields,
  totalPagess,
}) => {
  // console.log("fields", fields);
  const [filterStatus, setFilterStatus] = useState(
    fields.status?.value.toLowerCase()
  );

  const queryId = getUserQueryData?.[0].queryId._id;
  const statusIds = [];

  if (getUserQueryData != null) {
    getUserQueryData.map((item) => {
      if (item.status === "unread") {
        statusIds.push(`${item._id}`);
      }
    });
  }
  var messageStatus = {
    messageIds: statusIds,
    status: "read",
  };
  useEffect(() => {
    if (statusIds.length > 0) {
      var setKey = 4;

      ModalNullGetQueryData();

      addStatusToMessages(messageStatus, queryId, setKey);
    }
  }, [statusIds]);

  const [scrollData, setScrollData] = useState([]);
  const [css, setCss] = useState("");
  const rowId = scrollData.map((item) => {
    return item._id;
  });

  const totalCount = totalDocsData;

  useEffect(() => {
    setScrollData((prevState) => {
      return [...prevState, ...userQueryList];
    });
  }, [userQueryList]);

  const visited = (id) => {
    rowId.forEach((item) => {
      if (item === id) {
        setCss(id);
      }
    });
  };

  const scrollDataFunction = () => {
    if (scrollData.length < totalCount) {
      if (filterStatus) {
        getUserQueryList(
          {
            pageNumber: nextPagee,
            // status: fields.status?.value ? fields.status?.value : ""
            status: filterStatus,
            limit: 12,
          },
          false
        );
      } else {
        getUserQueryList(
          {
            pageNumber: nextPagee,
            // status: fields.status?.value ? fields.status?.value : ""
            status: "",
            limit: 12,
          },
          false
        );
      }
    }
  };

  const btnData = [
    {
      onClick: handleOpenFilter,
      icon: FaFilter,
      tooltip: "Filter",
    },
  ];

  if (userQueryListSuccess === false) {
    return <LoaderView />;
  } else {
    return (
      <>
        <Col lg={12} xl={4} md={12} className="col-xxl-8 top_height">
          <div className="chatbox ">
            <div className="filter">
              <div className="filter_heading"> Queries</div>
              <div className="filter_icon">
                {btnData.map((btn, index) => {
                  var Icon = btn.icon;
                  return (
                    <div key={index} className="ms-2">
                      <CircularButtonView
                        Icon={Icon}
                        onClick={btn.onClick}
                        size={12}
                        tooltip={btn.tooltip}
                        id={`btn-${index}`}
                        place="right"
                        styles="circular_small_btn"
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {scrollData.length > 0 ? (
              <div id="scrollableDiv" className="chatbox_scrollbar">
                <InfiniteScroll
                  dataLength={scrollData.length}
                  next={scrollDataFunction}
                  hasMore={scrollData.length < totalCount}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {scrollData.map((item, index) => {
                    var isRead = "read";

                    //this content is for query status
                    var formattedStatus = "";
                    if (item.status === "new") formattedStatus = `New`;
                    if (item.status === "pending")
                      formattedStatus = `In-Progress`;
                    if (item.status === "reopened")
                      formattedStatus = `Reopened`;
                    if (item.status === "resolved")
                      formattedStatus = `Resolved`;

                    //This content is for message status
                    item?.messages.forEach((ele) => {
                      if (ele?.createdBy.id === userId) {
                        if (ele.status === "unread") isRead = "read";
                      } else {
                        if (ele.status === "unread") isRead = "unread";
                      }
                    });

                    //this part is for message time
                    var time = moment(item.createdAt).toISOString(true);
                    const myArray = time.split("T");
                    var validTime = moment(myArray, ["h:mm:ss a"]);
                    var actualTime = moment(validTime._i?.[1], [
                      "HH:mm",
                    ]).format("hh:mm a");

                    return (
                      <div className="card_hover " key={index}>
                        <div
                          className={`chat_container ${
                            item._id === css ? "visited" : ""
                          } `}
                          onClick={() => {
                            ModalNullGetQueryData();
                            getUserQuery(item._id);

                            visited(item._id);
                            onchangeStatus(
                              queryStatusOptions.find(
                                (element) =>
                                  element.value === item.status.toUpperCase()
                              )
                            );
                          }}
                        >
                          <div className="chat-inner">
                            <strong> {item.createdBy}</strong>
                            <span className={`${formattedStatus}`}>
                              <i>{formattedStatus.toUpperCase()}</i>
                            </span>
                          </div>
                          <div className="containerr">
                            <div className="chatbox_dot ">
                              <span className={isRead}></span>
                              <span className="ms-1">{item.subject}</span>
                            </div>

                            <i className="date mt-1">{`${moment(
                              new Date(item.createdAt.slice(0, 10))
                            ).format("DD/MM/YYYY")}${","} ${actualTime} `}</i>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
            ) : (
              <h4 className="mt-3 text-center pb-3">No data is available</h4>
            )}
          </div>
        </Col>
      </>
    );
  }
};

export default QueryList;
