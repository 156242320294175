import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { NavLink, Link } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { IoFastFoodOutline, IoPeopleOutline } from "react-icons/io5";
// import {MdOutlineQuestionAnswer} from "react-icons/md"
import { IoPeople } from "react-icons/io5";
import { IoAppsOutline } from "react-icons/io5";
import { RiAdminLine } from "react-icons/ri";
import { AiOutlineSetting, AiOutlineFileText } from "react-icons/ai";
import { BiHomeSmile } from "react-icons/bi";
import { BsTable, BsNewspaper } from "react-icons/bs";
import { FiBriefcase } from "react-icons/fi";
import { RiPencilRuler2Line, RiPencilRulerLine,  } from "react-icons/ri";
import {
  MdSchedule,
  MdLocalDining,
  MdOutlineQuestionAnswer,
  MdOutlinePersonSearch,
} from "react-icons/md";
import { BiBook } from "react-icons/bi";

// BsClipboard

import "./SidebarStyles.scss";
// import { Role } from '../../../helpers/role';
import { globals } from "../../../store/globals";
import { logout } from "../../../store/auth/authActions";
import logo from "../../../assets/images/logo-ssi-new.png";
import {
  LUNCH,
  LUNCHPREFERENCE,
  USER,
  APPLYFORWFH,
  WFHAPPLICATION,
  VIEWWFHANDLEAVE,
  PROFILE,
  SYSTEMMANAGEMENT,
  MYLEAVES,
  LEAVEAPPLICATIONS,
  // LEAVEANAGEMENT,
  JOBAPPLICATIONS,
  // RECRUITMENTMANAGEMENT,
  INTERVIEWSCHEDULE,
  LEAVEREPORTING,
  HRADMINISTRATION,
  IMPDOCUMENTS,
  MYQUERIES,
  VIEWQUERIES,
  APPLICATIONMANAGER,
  MYSKILLS,
  SKILLMANAGEMENT,
  EMPLOYEESKILLS,
} from "../../../navigation/routes";

const Sidebar = ({ profileURL, logout }) => {
  var permission = globals.store.getState().auth.loginUser.userPermissions;
  var permissionNameList = permission.map((x) => x.name);
  // console.log(permissionNameList);
  var level = globals.store.getState().auth.loginUser.level;
  // const heightRef = useRef();

  const [height, setHeight] = useState(0);
  // console.log("globals", globals);

  useEffect(() => {
    const newHeight = window.innerHeight;
    let navheight = 97;
    let bottomHeight = 44;
    var actualHeight = newHeight - (navheight + bottomHeight);
    setHeight(actualHeight);

    function handleResize() {
      const newHeight = window.innerHeight;
      var actualHeight = newHeight - (navheight + bottomHeight);
      setHeight(actualHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="navbar_body">
      <div className="sidenav_wrapper d-none d-md-block">
        <Navbar className="sidenav_wrapper_navbar">
          <Container>
            <div className="flex-column">
              <Navbar.Brand className="brand">
                <img src={logo} className="img" alt="React Bootstrap logo" />
              </Navbar.Brand>
              <hr />
              <Nav
                className="flex-column flex_column_scroll"
                style={{ height: `${height}px` }}
              >
                {permissionNameList.indexOf("AppDashboard") !== -1 && (
                  <NavLink className="nav-link mt-1" to={APPLICATIONMANAGER}>
                    <IoAppsOutline size={20} />
                    <span className="side_nav_content">
                      {/* {permission
                      .filter((list) => list.name === "ApplicationManager")
                      .map((list) => {
                        return list.displayName;
                      })} */}
                      App Dashboard
                    </span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("LunchPreference") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={LUNCH}>
                    <IoFastFoodOutline size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "LunchPreference")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("LunchManagement") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={LUNCHPREFERENCE}>
                    <MdLocalDining size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "LunchManagement")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("ImportantDocuments") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={IMPDOCUMENTS}>
                    <BiBook size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "ImportantDocuments")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("MyLeaves") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={MYLEAVES}>
                    <BsTable size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "MyLeaves")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {(level >= 4 ||
                  permissionNameList.indexOf("LeaveApplications") !== -1) && (
                  <NavLink
                    className="nav-link mt-1"
                    exact
                    to={LEAVEAPPLICATIONS}
                  >
                    <BsNewspaper size={20} />{" "}
                    <span className="side_nav_content">Leave Applications</span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("LeaveReporting") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={LEAVEREPORTING}>
                    <AiOutlineFileText size={20} />{" "}
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "LeaveReporting")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("JobApplications") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={JOBAPPLICATIONS}>
                    <FiBriefcase size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "JobApplications")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {(level >= 4 ||
                  permissionNameList.indexOf("InterviewSchedule") !== -1) && (
                  <NavLink
                    className="nav-link mt-1"
                    exact
                    to={INTERVIEWSCHEDULE}
                  >
                    <MdSchedule size={20} />
                    <span className="side_nav_content">Interview Schedule</span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("UserManagement") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={USER}>
                    <IoPeopleOutline size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "UserManagement")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}

                {permissionNameList.indexOf("MySkills") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={MYSKILLS}>
                    <RiPencilRuler2Line size={20} />
                    <span className="side_nav_content">
                      {/* {permission
                        .filter((list) => list.name === "MySkills")
                        .map((list) => {
                          return list.displayName;
                        })} */}
                      My Skills
                    </span>
                  </NavLink>
                )}

                {permissionNameList.indexOf("SkillsManagement") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={SKILLMANAGEMENT}>
                    <RiPencilRulerLine size={20} />
                    <span className="side_nav_content">
                      {/* {permission
                        .filter((list) => list.name === "MySkills")
                        .map((list) => {
                          return list.displayName;
                        })} */}
                      Skill Management
                    </span>
                  </NavLink>
                )}

                {permissionNameList.indexOf("EmployeeSkills") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={EMPLOYEESKILLS}>
                    <MdOutlinePersonSearch size={20} />
                    <span className="side_nav_content">
                      {/* {permission
                        .filter((list) => list.name === "MySkills")
                        .map((list) => {
                          return list.displayName;
                        })} */}
                      Employee Skills
                    </span>
                  </NavLink>
                )}

                {/* 
                {permissionNameList.indexOf("LeaveManagement") !== -1 && (
                  <NavLink className="nav-link mt-1" exact to={LEAVEANAGEMENT}>
                    <BsClipboard size={20} />{" "}
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "LeaveManagement")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )} */}
                {/* {permissionNameList.indexOf("JobManagement") !== -1 && (
                  <NavLink
                    className="nav-link mt-1"
                    exact
                    to={RECRUITMENTMANAGEMENT}
                  >
                    <FiBriefcase size={20} />{" "}
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "JobManagement")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )} */}
                {permissionNameList.indexOf("HRMSAdministration") !== -1 && (
                  <NavLink
                    className="nav-link mt-1"
                    exact
                    to={HRADMINISTRATION}
                  >
                    <RiAdminLine size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "HRMSAdministration")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("SystemManagement") !== -1 && (
                  <NavLink
                    className="nav-link mt-1"
                    exact
                    to={SYSTEMMANAGEMENT}
                  >
                    <AiOutlineSetting size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "SystemManagement")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("MyWFH") !== -1 && (
                  <NavLink className="nav-link mt-1" to={APPLYFORWFH}>
                    <BiHomeSmile size={20} />
                    <span className="side_nav_content">
                      {/* My WFH */}
                      {permission
                        .filter((list) => list.name === "MyWFH")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {(level >= 4 ||
                  permissionNameList.indexOf("WFHApplication") !== -1) && (
                  <NavLink className="nav-link mt-1" to={WFHAPPLICATION}>
                    <BsNewspaper size={20} />
                    {/* <span className="side_nav_content"> */}
                    {/* WFH Application */}
                    {/* {permission
                        .filter((list) => list.name === "WFHApplication")
                        .map((list) => {
                          return list.displayName;
                        })} */}
                    {/* </span> */}
                    <span className="side_nav_content">WFH Applications</span>
                  </NavLink>
                )}
                {permissionNameList.indexOf("ViewWFH/Leaves") !== -1 && (
                  <NavLink className="nav-link mt-1" to={VIEWWFHANDLEAVE}>
                    <IoPeople size={20} />
                    <span className="side_nav_content">
                      {/* View WFH/Leaves */}
                      {permission
                        .filter((list) => list.name === "ViewWFH/Leaves")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {/* )} */}
                {/* <NavLink className="nav-link mt-1" exact to={"leavereporting"}>
                  <AiOutlineFileText size={20} />{" "}
                  <span className="side_nav_content">Leave Reporting</span>
                </NavLink> */}

                {permissionNameList.indexOf("MyQueries") !== -1 && (
                  <NavLink className="nav-link mt-1" to={MYQUERIES}>
                    <MdOutlineQuestionAnswer size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "MyQueries")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}

                {permissionNameList.indexOf("ViewQueries") !== -1 && (
                  <NavLink className="nav-link mt-1" to={VIEWQUERIES}>
                    <BsTable size={20} />
                    <span className="side_nav_content">
                      {permission
                        .filter((list) => list.name === "ViewQueries")
                        .map((list) => {
                          return list.displayName;
                        })}
                    </span>
                  </NavLink>
                )}
                {/* {permissionNameList.indexOf("ApplicationManager") !== -1 && ( */}

                {/* )} */}
              </Nav>
            </div>
          </Container>
          <div className="settings setting_scroll d-flex align-items-center">
            {/* <Image height={30} width={30} src={profileURL} roundedCircle /> */}
            <NavDropdown id="nav-dropdown-settings" title="Settings">
              {
                // (true) && (
                permissionNameList.indexOf("Profile") !== -1 && (
                  <NavDropdown.Item as={Link} to={PROFILE}>
                    {permission
                      .filter((list) => list.name === "Profile")
                      .map((list) => {
                        // return "My Profile"
                        return list.displayName;
                      })}
                  </NavDropdown.Item>
                )
              }
              <NavDropdown.Item onClick={() => logout()}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Navbar>
      </div>

      <div className="bottomnav_wrapper d-block d-md-none">
        <nav className="navbar fixed-bottom">
          <Container>
            <Nav className="w-100">
              <div className="d-flex flex-row justify-content-between w-100">
                {permissionNameList.indexOf("LunchPreference") !== -1 && (
                  <NavLink className="nav-link" exact to={LUNCH}>
                    <IoFastFoodOutline size={30} />
                  </NavLink>
                )}
                {permissionNameList.indexOf("MyLeaves") !== -1 && (
                  <NavLink className="nav-link" exact to={MYLEAVES}>
                    <BsTable size={30} />
                  </NavLink>
                )}
                {permissionNameList.indexOf("ImportantDocuments") !== -1 && (
                  <NavLink className="nav-link" exact to={IMPDOCUMENTS}>
                    <BiBook size={30} />
                  </NavLink>
                )}

                <NavDropdown
                  id="nav-dropdown-settings"
                  title={<AiOutlineSetting size={30} />}
                >
                  <NavDropdown.Item as={Link} to={PROFILE}>
                    My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => logout()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>
          </Container>
        </nav>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profileURL: state.auth.profileurl,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
