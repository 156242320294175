import React from "react";
import { Spinner } from "react-bootstrap";

import "../WfhApplicationStyle.scss";

const LoaderView = ({ type = "" }) => {
  return (
    <div className=" tabLoader">
      <div className="">
        <Spinner
          animation="border"
          role="status"
          style={{ textAlign: "center" }}
        >
          <span className="visually-hidden ">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

LoaderView.propTypes = {};

export default LoaderView;
