import React, { useState, useEffect } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { ButtonView, Input } from "../../../controls";
import { DataTable } from "../../../common";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { AiOutlineClose } from "react-icons/ai";

const CategoryMgt = ({
  categoryList,
  addSkillsCategory,
  updateSkillsCategories,
  deleteSkillCategory,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [data, setData] = useState([]);

  // const data = categoryList;
  // console.log(data);

  useEffect(() => {
    const sortedCategoryList = categoryList.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    setData(sortedCategoryList);
    // eslint-disable-next-line
  }, [categoryList]);

  const columns = [
    {
      name: "Category Name",
      selector: "categoryName",
      sortable: false,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowAddModal(true);
                setShowEditModal(true);
                setCategoryID(row._id);
                setCategory(row.categoryName);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              setShowDelete(true);
              setCategoryID(row._id);
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const addUpdateData = {
    categoryName: capitalizeFirstLetter(category),
  };

  const handleaddCategory = () => {
    var rules = {
      required: ["category"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ category }),
    });

    const hasParentheses = /\(|\)/.test(category);

    if (hasParentheses) {
      errors = {
        ...errors,
        category: "Category should not contain parentheses.",
      };
    }

    if (!category) {
      errors = {
        ...errors,
        category: "Please fill category field",
      };
    }

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEditModal) {
        // const foundData = data.find(
        //   (item) =>
        //     item.categoryName.toLowerCase().split(" ").join("") ===
        //     category.toLowerCase().split(" ").join("")
        // );
        // // console.log("foundData:", foundData);

        // if (foundData === undefined) {
        //   updateSkillsCategories(categoryID, addUpdateData);
        //   handleClose();
        // } else {
        //   updateSkillsCategories(categoryID, { duplicateFound: true });
        //   handleClose();
        // }
        // edit
        updateSkillsCategories(categoryID, addUpdateData);
        handleClose();
      } else {
        addSkillsCategory(addUpdateData);
        handleClose();
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowDelete(false);
    setCategory("");
    setCategoryID("");
    setErrors({});
  };

  return (
    <div className="category_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Category</div>
              <div className="d-none d-md-block">Category Management</div>
            </Card.Title>

            <ButtonView
              onClick={() => {
                setShowAddModal(true);
              }}
              title={"Add New"}
              size={"medium"}
            // styles="secondary"
            />
          </Card.Header>
          <DataTable
            columns={columns}
            data={data}
            pagination={true}
            paginationPerPage={5}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal show={showDelete} onHide={handleClose} size="sm" centered={true}>
        <Modal.Header className="d-flex justify-content-center align-items-center">
          <Modal.Title> Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Are you sure you want to delete this Category?
          </p>
          <Row>
            <Col>
              <ButtonView
                size={"small"}
                title="Confirm"
                styles="danger-outline"
                onClick={() => {
                  deleteSkillCategory(categoryID);
                  handleClose();
                }}
              ></ButtonView>
            </Col>
            <Col>
              <ButtonView
                size={"small"}
                title="Cancel"
                onClick={() => {
                  handleClose();
                }}
              ></ButtonView>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showAddModal} onHide={handleClose} centered={true}>
        <Modal.Header>
          <Modal.Title>
            {showEditModal ? "Edit Category" : "Add Category"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12}>
                <Input
                  name="Category"
                  label="Category"
                  maxLength={50}
                  type="text"
                  value={showEditModal ? category : category}
                  placeholder={
                    showEditModal
                      ? "Enter Category Name"
                      : "Enter Category Name"
                  }
                  onChange={(e) => setCategory(e.target.value)}
                  size="fluid"
                  error={errors["category"]}
                  isrequired={true}
                />
              </Col>

              <Col xs={12} className="mt-3">
                <ButtonView
                  // disabled={
                  //   showEditModal &&
                  //   category ===
                  //     data.find((item) => item._id === categoryID)?.categoryName
                  // }
                  onClick={handleaddCategory}
                  title={showEditModal ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CategoryMgt;
