import React, { useState, useEffect } from "react";
import moment from "moment";
import { Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, TextArea } from "../../../controls";
import {
  INTERVIEW_STATUS_REJECTED,
  INTERVIEW_STATUS_ACCEPTED,
  INTERVIEW_OUTCOME_REJECTED,
} from "../../../../constants/env";

const Unaccepted = ({
  data,
  updateInterviewScheduleStatusByInterviewer,
  user,
  updateJobApplicationStatus,
  downloadJobApplicationDocument,
  interviewId,
  onsetInterviewId,
  onsetDate,
}) => {
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);
  const [rejectJobApplication, setRejectJobApplication] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [rejectionVal, setRejectionVal] = useState("");
  const [rejectionId, setRejectionId] = useState("");
  const [jobAppId, setjobAppId] = useState("");
  const [errors, setErrors] = useState({});
  // console.log("data", data);

  useEffect(() => {
    if (interviewId) {
      const interviewDependOnId = data.find((list) => {
        return list._id === interviewId;
      });
      if (interviewDependOnId) {
        setViewDetails(interviewDependOnId);
        setViewDetailsShow(true);
      }
      onsetInterviewId(0);
    }
  }, [interviewId, data, onsetInterviewId]);

  const columns = [
    {
      name: "Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.interviewDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Start",
      center: true,
      // minWidth: "70px",
      grow: 4,
      cell: (row) => (
        <span>
          {((parseInt(row.startTime) + 11) % 12) + 1}:
          {row.startTime.split(":")[1]}{" "}
          {parseInt(row.startTime) >= 12 ? "PM" : "AM"}
        </span>
      ),
    },
    {
      name: "End",
      center: true,
      // minWidth: "70px",
      grow: 4,
      cell: (row) => (
        <span>
          {((parseInt(row.endTime) + 11) % 12) + 1}:{row.endTime.split(":")[1]}{" "}
          {parseInt(row.endTime) >= 12 ? "PM" : "AM"}
        </span>
      ),
    },
    // {
    //   name: "Candidate",
    //   center: true,
    //   grow: 4,
    //   cell: (row) => {
    //     return (
    //       <span>
    //         {row.applicationId.firstName && row.applicationId.firstName}{" "}
    //         {row.applicationId.lastName &&
    //           row.applicationId.lastName.slice(0, 1)}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   name: "Prime Domain",
    //   center: true,
    //   minWidth: "120px",
    //   cell: (row) => {
    //     return (
    //       <span>
    //         {row.applicationId.primaryTechnicalDomains &&
    //           row.applicationId.primaryTechnicalDomains[0]}
    //       </span>
    //     );
    //   },
    // },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => <span className="badge bg-primary">Unaccepted</span>,
    },
    {
      name: "Detail",
      center: true,
      minWidth: "90px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              handleViewOpen(row);
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "180px",
      // grow: 4,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div className="me-1">
              <ButtonView
                onClick={() => handleApproveInterview(row)}
                title={"Accept"}
                size="small"
              />
            </div>
            <ButtonView
              onClick={() => handleRejectOpen(row)}
              title={"Decline"}
              styles="danger-outline"
              size="small"
            />
          </div>
        );
      },
    },
    {
      name: "Reject Application",
      center: true,
      minWidth: "160px",
      // grow: 4,
      cell: (row) => {
        return (
          <ButtonView
            onClick={() => {
              setRejectJobApplication(true);
              handleRejectOpen(row);
              setjobAppId(row.applicationId._id);
            }}
            title={"Reject Application"}
            size="small"
            styles="danger-outline"
          />
        );
      },
    },
  ];

  const handleViewOpen = (row) => {
    setViewDetails(row);
    setViewDetailsShow(true);
  };

  const handleApproveInterview = (row) => {
    if (window.confirm("Approve Interview?")) {
      const data = {
        status: INTERVIEW_STATUS_ACCEPTED,
        lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
      };
      updateInterviewScheduleStatusByInterviewer(data, row._id);
      // onsetDate(new Date().toISOString().split("T")[0]);
      handleClose();
    }
  };

  const handleRejectInterview = () => {
    var rules = {
      required: ["rejectionVal"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ rejectionVal }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (rejectJobApplication) {
        const data = {
          status: INTERVIEW_STATUS_REJECTED,
          statusComments: `${rejectionVal} - (Job Application Rejected)`,
          lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        };
        updateInterviewScheduleStatusByInterviewer(data, rejectionId);
        const jobdata = {
          lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
          status: INTERVIEW_OUTCOME_REJECTED,
        };
        updateJobApplicationStatus(jobdata, jobAppId, false);
      } else {
        const data = {
          status: INTERVIEW_STATUS_REJECTED,
          statusComments: rejectionVal,
          lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        };
        updateInterviewScheduleStatusByInterviewer(data, rejectionId);
      }
      // onsetDate(new Date().toISOString().split("T")[0]);
      handleClose();
    }
  };

  const handleRejectOpen = (row) => {
    setRejectionId(row._id);
    setRejectOpen(true);
  };

  const handleClose = () => {
    setRejectOpen(false);
    setRejectionId("");
    setErrors({});
    setRejectionVal("");
    setRejectJobApplication(false);
    setjobAppId("");
    setViewDetailsShow(false);
  };

  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <DataTable
          data={data}
          columns={columns}
          pagination={true}
          paginationPerPage={5}
          paginationServer={false}
        />
      </div>
      <div className="reject_modal">
        <Modal
          show={rejectOpen}
          onHide={handleClose}
          aria-labelledby="interview_schedule_view"
        >
          <Modal.Header>
            <Modal.Title id="reject_modal">Rejection Reason</Modal.Title>
            <div onClick={handleClose} style={{ cursor: "pointer" }}>
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              Kindly provide the reason for rejecting the{" "}
              {rejectJobApplication ? "job application" : "interview"}.{" "}
              <span className="text-danger">*</span>
              <TextArea
                name="comments"
                placeholder="Reason..."
                value={rejectionVal}
                type="textarea"
                setFocus={1}
                onChange={(e) => setRejectionVal(e.target.value)}
                size="fluid"
                error={errors["rejectionVal"]}
              />
            </div>
            <Row>
              <Col md={12} lg={6} className="mb-2">
                <ButtonView
                  onClick={handleClose}
                  title={"Cancel"}
                  styles="danger-outline"
                />
              </Col>
              <Col md={12} lg={6}>
                <ButtonView
                  onClick={handleRejectInterview}
                  title={"Submit Rejection"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>

      {viewDetails && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Interview Detail</Modal.Title>

              <div className="d-flex align-items-center">
                <div className="me-2 d-none d-md-block">
                  <ButtonView
                    onClick={() =>
                      downloadJobApplicationDocument(
                        viewDetails.applicationId._id
                      )
                    }
                    title={"Download Resume"}
                    styles="secondary"
                  />
                </div>

                <div onClick={handleClose} style={{ cursor: "pointer" }}>
                  <AiOutlineClose size={20} />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="interview_schedule_detail ms-1">
                <div className="mb-3 d-block d-md-none">
                  <ButtonView
                    onClick={() =>
                      downloadJobApplicationDocument(
                        viewDetails.applicationId._id
                      )
                    }
                    title={"Download Resume"}
                    styles="secondary"
                  />
                </div>
                <h5>Round: {viewDetails.roundNumber}</h5>
                <h5>
                  Interviewer(s):{" "}
                  {viewDetails.interviewers.map((list, id, array) => {
                    if (id === array.length - 1) {
                      return (
                        <span style={{ color: "#d87d2e" }} key={list._id}>
                          {list.userId[0].firstName} {list.userId[0].lastName}{" "}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{ color: "#d87d2e" }} key={list._id}>
                          {list.userId[0].firstName} {list.userId[0].lastName},{" "}
                        </span>
                      );
                    }
                  })}
                </h5>
                <div className="d-flex flex-wrap">
                  <h5 className="me-3">
                    Date:{" "}
                    {moment(new Date(viewDetails.interviewDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </h5>
                  <h5>
                    Time: {((parseInt(viewDetails.startTime) + 11) % 12) + 1}:
                    {viewDetails.startTime.split(":")[1]}{" "}
                    {parseInt(viewDetails.startTime) >= 12 ? "PM" : "AM"} to{" "}
                    {((parseInt(viewDetails.endTime) + 11) % 12) + 1}:
                    {viewDetails.endTime.split(":")[1]}{" "}
                    {parseInt(viewDetails.endTime) >= 12 ? "PM" : "AM"}
                  </h5>
                  <h5 className="me-3">Mode: {viewDetails.mode}</h5>
                </div>
              </div>
              <ul className="list-group interview_schedule_detail_list">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Name:
                  <span>
                    {viewDetails.applicationId.firstName}{" "}
                    {viewDetails.applicationId.middleName &&
                      viewDetails.applicationId.middleName}{" "}
                    {viewDetails.applicationId.lastName &&
                      viewDetails.applicationId.lastName}
                  </span>
                </li>
                {viewDetails.applicationId.primaryTechnicalDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Prime Domain:
                    <span>
                      {viewDetails.applicationId.primaryTechnicalDomains[0]}
                    </span>
                  </li>
                ) : null}
                {viewDetails.applicationId.secondaryTechnicalDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    Secondary Domain:
                    <span style={{ textAlign: "right" }}>
                      {viewDetails.applicationId.secondaryTechnicalDomains.map(
                        (list, id, array) => {
                          if (
                            viewDetails.applicationId.secondaryTechnicalDomains
                              .length > 1
                          ) {
                            if (id === array.length - 1) {
                              return list;
                            } else {
                              return (
                                <>
                                  {list}, {(id + 1) % 3 === 0 ? <br /> : null}
                                </>
                              );
                            }
                          } else {
                            return list;
                          }
                        }
                      )}
                    </span>
                  </li>
                ) : null}
                {/* {viewDetails.applicationId.skills && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Skills:
                    <span>{viewDetails.applicationId.skills}</span>
                  </li>
                )} */}
                {viewDetails.applicationId.applicationDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    Application Domain:
                    <span style={{ textAlign: "right" }}>
                      {viewDetails.applicationId.applicationDomains.map(
                        (list, id, array) => {
                          if (
                            viewDetails.applicationId.secondaryTechnicalDomains
                              .length > 1
                          ) {
                            if (id === array.length - 1) {
                              return list;
                            } else {
                              return (
                                <>
                                  {list}, {(id + 1) % 3 === 0 ? <br /> : null}
                                </>
                              );
                            }
                          } else {
                            return list;
                          }
                        }
                      )}
                    </span>
                  </li>
                ) : null}
                {viewDetails.applicationId.designationAppliedFor && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Applied Designation:
                    <span>
                      {viewDetails.applicationId.designationAppliedFor}
                    </span>
                  </li>
                )}

                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Experience:
                  <span>
                    {" "}
                    {(viewDetails.applicationId.experienceInMonths / 12) | 0}.
                    {viewDetails.applicationId.experienceInMonths % 12} yrs
                  </span>
                </li>
                {viewDetails.applicationId.qualification && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Qualification:
                    <span>{viewDetails.applicationId.qualification}</span>
                  </li>
                )}
                {viewDetails.applicationId.passingYear && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Passing Year:
                    <span>{viewDetails.applicationId.passingYear}</span>
                  </li>
                )}
              </ul>
              <Row className="mt-3 d-flex justify-content-end gx-2">
                <Col xs={6} md={6}>
                  <ButtonView
                    onClick={() => handleApproveInterview(viewDetails)}
                    title={"Accept Invite"}
                    styles="secondary"
                  />
                </Col>
                <Col xs={6} md={6}>
                  <ButtonView
                    onClick={() => {
                      setViewDetailsShow(false);
                      handleRejectOpen(viewDetails);
                    }}
                    title={"Decline Invite"}
                    styles="danger-outline"
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Unaccepted;
