import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  HOLIDAYS_PENDING: "HOLIDAYS_PENDING",
  HOLIDAYS_SUCCESS: "HOLIDAYS_SUCCESS",
  HOLIDAYS_ERROR: "HOLIDAYS_ERROR",

  HOLIDAYS_ADD_PENDING: "HOLIDAYS_ADD_PENDING",
  HOLIDAYS_ADD_ERROR: "HOLIDAYS_ADD_ERROR",
  HOLIDAYS_ADD_SUCCESS: "HOLIDAYS_ADD_SUCCESS",

  HOLIDAYS_EDIT_PENDING: "HOLIDAYS_EDIT_PENDING",
  HOLIDAYS_EDIT_ERROR: "HOLIDAYS_EDIT_ERROR",
  HOLIDAYS_EDIT_SUCCESS: "HOLIDAYS_EDIT_SUCCESS",

  HOLIDAYS_DELETE_PENDING: "HOLIDAYS_DELETE_PENDING",
  HOLIDAYS_DELETE_ERROR: "HOLIDAYS_DELETE_ERROR",
  HOLIDAYS_DELETE_SUCCESS: "HOLIDAYS_DELETE_SUCCESS",
};

export function addHoliday(data, currentYear) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.HOLIDAYS_ADD_PENDING,
      payload: {
        holidaysAddPending: true,
        holidaysAddSuccess: false,
        holidaysAddError: null,
      },
    });
    // console.log(data)

    Api.post("leaveManagement/hradmin/addHoliday", data)
      .then((response) => {
        // console.log(response);
        if (response.message === "Successfully added new holiday") {
          if (response.data.year === currentYear) {
            dispatch({
              type: actionTypes.HOLIDAYS_ADD_SUCCESS,
              payload: {
                holidaysAddPending: false,
                holidaysAddSuccess: true,
                holidaysAddError: null,

                holidays: response.data,
              },
            });
          }
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new holiday.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.HOLIDAYS_ADD_ERROR,
          payload: {
            holidaysAddPending: false,
            holidaysAddSuccess: true,
            holidaysAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add holiday.",
          })
        );
      });
  };
}

export function editHoliday(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.HOLIDAYS_EDIT_PENDING,
      payload: {
        holidaysEditPending: true,
        holidaysEditSuccess: false,
        holidaysEditError: null,
      },
    });
    // console.log(data, id);

    Api.put(`leaveManagement/hradmin/updateHoliday/${id}`, data)
      .then((response) => {
        // console.log(response)
        if (response.message === "Successfully updated holiday") {
          dispatch({
            type: actionTypes.HOLIDAYS_EDIT_SUCCESS,
            payload: {
              holidaysEditPending: false,
              holidaysEditSuccess: true,
              holidaysEditError: null,

              holidays: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated holiday.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.HOLIDAYS_EDIT_ERROR,
          payload: {
            holidaysEditPending: false,
            holidaysEditSuccess: true,
            holidaysEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit holiday.",
          })
        );
      });
  };
}

export function deleteHoliday(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.HOLIDAYS_DELETE_PENDING,
      payload: {
        holidaysDeletePending: true,
        holidaysDeleteSuccess: false,
        holidaysDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`leaveManagement/hradmin/deleteHoliday/${id}`)
      .then((response) => {
        // console.log(response)
        if (response.message === "Successfully deleted holiday") {
          dispatch({
            type: actionTypes.HOLIDAYS_DELETE_SUCCESS,
            payload: {
              holidaysDeletePending: false,
              holidaysDeleteSuccess: true,
              holidaysDeleteError: null,

              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted holiday.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.HOLIDAYS_DELETE_ERROR,
          payload: {
            holidaysDeletePending: false,
            holidaysDeleteSuccess: true,
            holidaysDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add holiday.",
          })
        );
      });
  };
}

export function list(year, showLoader = true) {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.HOLIDAYS_PENDING,
        payload: {
          holidaysPending: true,
          holidaysSuccess: false,
          holidaysError: null,
        },
      });
    }

    const data = {
      year: year,
    };

    Api.post("leaveManagement/hradmin/getHolidaysList", data)
      .then((response) => {
        // console.log(response)
        if (response.message === "Successfully fetched holidays list") {
          dispatch({
            type: actionTypes.HOLIDAYS_SUCCESS,
            payload: {
              holidaysPending: false,
              holidaysSuccess: true,
              holidaysError: null,

              holidaysList: response.data,
              currentYear: year,
            },
          });
        }
        dispatch({
          type: actionTypes.HOLIDAYS_SUCCESS,
          payload: {
            holidaysPending: false,
            holidaysSuccess: true,
            holidaysError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.HOLIDAYS_ERROR,
          payload: {
            holidaysPending: false,
            holidaysSuccess: true,
            holidaysError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get holidays list.",
          })
        );
      });
  };
}
