import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";

export const actionTypes = {
  GET_MY_SKILLS_PENDING: "GET_MY_SKILLS_PENDING",
  GET_MY_SKILLS_SUCCESS: "GET_MY_SKILLS_SUCCESS",
  GET_MY_SKILLS_ERROR: "GET_MY_SKILLS_ERROR",

  GETPROFICIENCY_PENDING: "GETPROFICIENCY_PENDING",
  GETPROFICIENCY_SUCCESS: "GETPROFICIENCY_SUCCESS",
  GETPROFICIENCY_ERROR: "GETPROFICIENCY_ERROR",

  GETCATEGORY_PENDING: "GETCATEGORY_PENDING",
  GETCATEGORY_SUCCESS: "GETCATEGORY_SUCCESS",
  GETCATEGORY_ERROR: "GETCATEGORY_ERROR",

  GETTECHNOLOGY_PENDING: "GETTECHNOLOGY_PENDING",
  GETTECHNOLOGY_SUCCESS: "GETTECHNOLOGY_SUCCESS",
  GETTECHNOLOGY_ERROR: "GETTECHNOLOGY_ERROR",

  FETCH_SKILL_CERTIFICATE_PENDING: "FETCH_SKILL_CERTIFICATE_PENDING",
  FETCH_SKILL_CERTIFICATE_SUCCESS: "FETCH_SKILL_CERTIFICATE_SUCCESS",
  FETCH_SKILL_CERTIFICATE_ERROR: "FETCH_SKILL_CERTIFICATE_ERROR",

  SAVE_SKILLS_PENDING: "SAVESKILLS_PENDING",
  SAVE_SKILLS_SUCCESS: "SAVESKILLS_SUCCESS",
  SAVE_SKILLS_ERROR: "SAVESKILLS_ERROR",

  SAVE_MAPPING_DATA_PENDING: "SAVE_MAPPING_DATA_PENDING",
  SAVE_MAPPING_DATA_SUCCESS: "SAVE_MAPPING_DATA_SUCCESS",
  SAVE_MAPPING_DATA_ERROR: "SAVE_MAPPING_DATA_ERROR",

  UPLOAD_CERTIFICATE_PENDING: "UPLOAD_CERTIFICATE_PENDING",
  UPLOAD_CERTIFICATE_SUCCESS: "UPLOAD_CERTIFICATE_SUCCESS",
  UPLOAD_CERTIFICATE_ERROR: "UPLOAD_CERTIFICATE_ERROR",

  DELETE_CERTIFICATE_PENDING: "DELETE_CERTIFICATE_PENDING",
  DELETE_CERTIFICATE_SUCCESS: "DELETE_CERTIFICATE_SUCCESS",
  DELETE_CERTIFICATE_ERROR: "DELETE_CERTIFICATE_ERROR",
};

export function getMySkills() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_MY_SKILLS_PENDING,
      payload: {
        getMySkillsPending: true,
        getMySkillsSuccess: false,
        getMySkillsError: null,
      },
    });
    dispatch(showLoader());
    Api.get("userSkills/fetchSkillsList")
      .then((response) => {
        // console.log(response.data);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_MY_SKILLS_SUCCESS,
          payload: {
            getMySkillsPending: false,
            getMySkillsSuccess: true,
            getMySkillsError: null,

            mySkillList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_MY_SKILLS_ERROR,
          payload: {
            getMySkillsPending: true,
            getMySkillsSuccess: true,
            getMySkillsError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}

export function getProficiencies() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_MY_SKILLS_PENDING,
      payload: {
        GETPROFICIENCY_PENDING: true,
        GETPROFICIENCY_SUCCESS: false,
        GETPROFICIENCY_ERROR: null,
      },
    });
    dispatch(showLoader());
    Api.get("skills/getProficiencies")
      .then((response) => {
        // console.log(response.data);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GETPROFICIENCY_SUCCESS,
          payload: {
            proficiencies: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GETPROFICIENCY_ERROR,
          payload: {
            GETPROFICIENCY_PENDING: true,
            GETPROFICIENCY_SUCCESS: true,
            GETPROFICIENCY_ERROR: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}

export function getCategories() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GETCATEGORY_PENDING,
      payload: {
        GETCATEGORY_PENDING: true,
        GETCATEGORY_SUCCESS: false,
        GETCATEGORY_ERROR: null,
      },
    });
    dispatch(showLoader());
    Api.get("skills/getCategories")
      .then((response) => {
        // console.log(response.data);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GETCATEGORY_SUCCESS,
          payload: {
            categories: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GETCATEGORY_ERROR,
          payload: {
            GETCATEGORY_PENDING: false,
            GETCATEGORY_SUCCESS: true,
            GETCATEGORY_ERROR: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}

export function getTechnologiesForCategory(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GETTECHNOLOGY_PENDING,
      payload: {
        GETTECHNOLOGY_PENDING: true,
        GETTECHNOLOGY_SUCCESS: false,
        GETTECHNOLOGY_ERROR: null,
      },
    });
    dispatch(showLoader());
    Api.post(`skills/getMappingData/`, data)
      .then((response) => {
        // console.log(response.data, "technologies Response");
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GETTECHNOLOGY_SUCCESS,
          payload: {
            technologies: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GETTECHNOLOGY_ERROR,
          payload: {
            GETTECHNOLOGY_PENDING: false,
            GETTECHNOLOGY_SUCCESS: true,
            GETTECHNOLOGY_ERROR: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}

// export function getTechnologiesForCategory() {
//   return (dispatch) => {
//     Api.get("skills/getTechnologies").then((response) => {
//       console.log(response.data, "technologies Response");
//       dispatch({
//         type: actionTypes.GETTECHNOLOGY_SUCCESS,
//         payload: {
//           technologies: response.data,
//         },
//       });
//     });
//   };
// }

export function updateSkills(data, id) {
  return (dispatch) => {
    if (data === "invalid Data") {
      dispatch(
        showAlert({
          isOpen: true,
          title: "Warning",
          type: "warning",
          msg: "Certificate Input-Fields are empty",
        })
      );
    } else {
      Api.put(`userSkills/updateSkills/${id}`, data).then((response) => {
        console.log(response);
        dispatch(getMySkills());
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: "Successfully Updated Data.",
          })
        );
      });
    }
  };
}

export function saveSkills(data) {
  return (dispatch) => {
    if (data.existingSkill === "Skill Exist") {
      dispatch(
        showAlert({
          isOpen: true,
          title: "Warning",
          type: "warning",
          msg: "Skill already exist.",
        })
      );
    } else {
      dispatch({
        type: actionTypes.SAVE_SKILLS_PENDING,
        payload: {
          SAVE_SKILLS_PENDING: true,
          SAVE_SKILLS_SUCCESS: false,
          SAVE_SKILLS_ERROR: null,
        },
      });
      dispatch(showLoader());
      Api.post("userSkills/saveSkills", data)
        .then((response) => {
          if (response.message === "Failed to add new skills") {
            dispatch({
              type: actionTypes.SAVE_SKILLS_SUCCESS,
              payload: {
                ErrorMessage: response.message,
              },
            });
          }
          console.log(response);
          if (response.message === "Duplicate skills found") {
            dispatch({
              type: actionTypes.SAVE_SKILLS_SUCCESS,
              payload: {
                SAVE_SKILLS_PENDING: false,
                SAVE_SKILLS_SUCCESS: true,
                SAVE_SKILLS_ERROR: null,

                DuplicateSkillMessage: response.message,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Warning",
                type: "warning",
                msg: "Skill already exist.",
              })
            );
          } else {
            dispatch({
              type: actionTypes.SAVE_SKILLS_SUCCESS,
              payload: {
                SAVE_SKILLS_PENDING: false,
                SAVE_SKILLS_SUCCESS: true,
                SAVE_SKILLS_ERROR: null,

                newSkillId: response.data._id,
                technologies: [],
                DuplicateSkillMessage: "",
              },
            });
            console.log(response.message);
            dispatch(getMySkills());
            dispatch(
              showAlert({
                isOpen: true,
                title: "Success",
                type: "success",
                msg: "Successfully Added Skill.",
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(hideLoader());
          dispatch({
            type: actionTypes.SAVE_SKILLS_ERROR,
            payload: {
              SAVE_SKILLS_PENDING: false,
              SAVE_SKILLS_SUCCESS: true,
              SAVE_SKILLS_ERROR: null,

              ErrorMessage: "Failed to add new skills",
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to save Data",
            })
          );
        });
    }
  };
}

export function saveMappingData(data) {
  return (dispatch) => {
    Api.post("skills/saveMappingData", data).then((response) => {
      console.log(response.data);
      dispatch({
        type: actionTypes.SAVE_MAPPING_DATA_SUCCESS,
        payload: {
          newSkillId: response.data._id,
        },
      });
    });
  };
}

export function fetchSkillCertificate(data) {
  return (dispatch) => {
    Api.post("userSkills/fetchSkillsCertificate", data).then((response) => {
      // console.log(response);
      dispatch({
        type: actionTypes.FETCH_SKILL_CERTIFICATE_SUCCESS,
        payload: {
          mySkillCertificates: response.data,
        },
      });
    });
  };
}

export function downloadSkillCertificates(data) {
  return (dispatch) => {
    Api.post("userSkills/downloadSkillsCertificate", data).then((response) => {
      console.log(response);
      if (response.data) {
        const url = response.data[0].imageURL;
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      }
    });
  };
}

export function uploadSkillCertificates(data, fecthCertificateData) {
  return (dispatch) => {
    if (data.certificateName === "") {
      dispatch(
        showAlert({
          isOpen: true,
          title: "Warning",
          type: "warning",
          msg: "Certificate Name is required",
        })
      );
    }
    if (data.file === null) {
      dispatch(
        showAlert({
          isOpen: true,
          title: "Warning",
          type: "warning",
          msg: "Please Choose file to upload",
        })
      );
    }
    if (data.certificateName !== "" && data.file !== null) {
      dispatch({
        type: actionTypes.UPLOAD_CERTIFICATE_PENDING,
        payload: {
          UPLOAD_CERTIFICATE_PENDING: true,
          UPLOAD_CERTIFICATE_SUCCESS: false,
          UPLOAD_CERTIFICATE_ERROR: null,
        },
      });
      dispatch(showLoader());
      const formData = new FormData();
      formData.append("skillsId", data.skillsId);
      formData.append("certificateName", data.certificateName);
      formData.append("validCertificationDate", data.validCertificationDate);
      formData.append("file", data.file);
      Api.post("userSkills/uploadSkillsCertificate", formData)
        .then((response) => {
          dispatch(hideLoader());
          console.log(response);
          dispatch({
            type: actionTypes.UPLOAD_CERTIFICATE_SUCCESS,
            payload: {
              UPLOAD_CERTIFICATE_PENDING: false,
              UPLOAD_CERTIFICATE_SUCCESS: true,
              UPLOAD_CERTIFICATE_ERROR: null,
            },
          });
          dispatch(fetchSkillCertificate(fecthCertificateData));
          dispatch(getMySkills());
          // dispatch(
          //   showAlert({
          //     isOpen: true,
          //     title: "Success",
          //     type: "success",
          //     msg: "Uploaded Successfully.",
          //   })
          // );
        })
        .catch((err) => {
          console.log(err);
          dispatch(hideLoader());
          dispatch({
            type: actionTypes.UPLOAD_CERTIFICATE_ERROR,
            payload: {
              UPLOAD_CERTIFICATE_PENDING: false,
              UPLOAD_CERTIFICATE_SUCCESS: true,
              UPLOAD_CERTIFICATE_ERROR: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to Upload Certificate",
            })
          );
        });
    }
  };
}

export function deleteSkillCertificate(certificateId, fecthCertificateData) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_CERTIFICATE_PENDING,
      payload: {
        DELETE_CERTIFICATE_PENDING: true,
        DELETE_CERTIFICATE_SUCCESS: false,
        DELETE_CERTIFICATE_ERROR: null,
      },
    });
    dispatch(showLoader());
    const data = {
      certification_id: certificateId,
    };
    Api.post("userSkills/deleteSkillsCertificate", data)
      .then((response) => {
        console.log(response);
        dispatch({
          type: actionTypes.DELETE_CERTIFICATE_SUCCESS,
          payload: {
            DELETE_CERTIFICATE_PENDING: false,
            DELETE_CERTIFICATE_SUCCESS: true,
            DELETE_CERTIFICATE_ERROR: null,
          },
        });
        dispatch(fetchSkillCertificate(fecthCertificateData));
        dispatch(hideLoader());
        dispatch(getMySkills());
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.DELETE_CERTIFICATE_ERROR,
          payload: {
            DELETE_CERTIFICATE_PENDING: false,
            DELETE_CERTIFICATE_SUCCESS: true,
            DELETE_CERTIFICATE_ERROR: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Delete Certificate",
          })
        );
      });
  };
}

export function deleteSkill(id) {
  return (dispatch) => {
    Api.delete(`userSkills/deleteSkillsForUser/${id}`).then((response) => {
      console.log(response);
      dispatch(getMySkills());
      dispatch(
        showAlert({
          isOpen: true,
          title: "Success",
          type: "success",
          msg: "Successfully Deleted Data.",
        })
      );
    });
  };
}
