import { actionTypes } from "./skillManagementAction";

const {
  GET_CATEGORY_PENDING,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,

  ADD_CATEGORY_PENDING,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,

  UPDATE_CATEGORY_PENDING,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,

  DELETE_CATEGORY_PENDING,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,

  GET_PROFICIENCY_PENDING,
  GET_PROFICIENCY_SUCCESS,
  GET_PROFICIENCY_ERROR,

  ADD_PROFICIENCY_PENDING,
  ADD_PROFICIENCY_SUCCESS,
  ADD_PROFICIENCY_ERROR,

  UPDATE_PROFICIENCY_PENDING,
  UPDATE_PROFICIENCY_SUCCESS,
  UPDATE_PROFICIENCY_ERROR,

  DELETE_PROFICIENCY_PENDING,
  DELETE_PROFICIENCY_SUCCESS,
  DELETE_PROFICIENCY_ERROR,

  GET_TECHNOLOGY_PENDING,
  GET_TECHNOLOGY_SUCCESS,
  GET_TECHNOLOGY_ERROR,

  ADD_TECHNOLOGY_PENDING,
  ADD_TECHNOLOGY_SUCCESS,
  ADD_TECHNOLOGY_ERROR,

  SAVE_TECHNOLOGY_CATEGORY_PENDING,
  SAVE_TECHNOLOGY_CATEGORY_SUCCESS,
  SAVE_TECHNOLOGY_CATEGORY_ERROR,

  DELETE_MAPPED_TECHNOLOGY_CATEGORY_PENDING,
  DELETE_MAPPED_TECHNOLOGY_CATEGORY_SUCCESS,
  DELETE_MAPPED_TECHNOLOGY_CATEGORY_ERROR,

  UPDATE_TECHNOLOGY_PENDING,
  UPDATE_TECHNOLOGY_SUCCESS,
  UPDATE_TECHNOLOGY_ERROR,

  UPDATE_TECHNOLOGY_CATEGORY_PENDING,
  UPDATE_TECHNOLOGY_CATEGORY_SUCCESS,
  UPDATE_TECHNOLOGY_CATEGORY_ERROR,

  DELETE_TECHNOLOGY_PENDING,
  DELETE_TECHNOLOGY_SUCCESS,
  DELETE_TECHNOLOGY_ERROR,

  DOWNLOAD_TECHNOLOGY_REPORT_PENDING,
  DOWNLOAD_TECHNOLOGY_REPORT_SUCCESS,
  DOWNLOAD_TECHNOLOGY_REPORT_ERROR,

  DOWNLOAD_USER_REPORT_PENDING,
  DOWNLOAD_USER_REPORT_SUCCESS,
  DOWNLOAD_USER_REPORT_ERROR,
} = actionTypes;

const initialState = {
  getCategoryPending: false,
  getCategorySuccess: false,
  getCategoryError: null,

  addCategoryPending: false,
  addCategorySuccess: false,
  addCategoryError: null,

  updateCategoryPending: false,
  updateCategorySuccess: false,
  updateCategoryError: null,

  deleteCategoryPending: false,
  deleteCategorySuccess: false,
  deleteCategoryError: null,

  getProficiencyPending: false,
  getProficiencySuccess: false,
  getProficiencyError: null,

  addProficiencyPending: false,
  addProficiencySuccess: false,
  addProficiencyError: null,

  updateProficiencyPending: false,
  updateProficiencySuccess: false,
  updateProficiencyError: null,

  deleteProficiencyPending: false,
  deleteProficiencySuccess: false,
  deleteProficiencyError: null,

  getTechnologyPending: false,
  getTechnologySuccess: false,
  getTechnologyError: null,

  addTechnologyPending: false,
  addTechnologySuccess: false,
  addTechnologyError: null,

  saveTechnologyCategoryPending: false,
  saveTechnologyCategorySuccess: false,
  saveTechnologyCategoryError: null,

  deleteMappedTechnologyCategoryPending: false,
  deleteMappedTechnologyCategorySuccess: false,
  deleteMappedTechnologyCategoryError: null,

  updateTechnologyPending: false,
  updateTechnologySuccess: false,
  updateTechnologyError: null,

  updateTechnologyCategoryPending: false,
  updateTechnologyCategorySuccess: false,
  updateTechnologyCategoryError: null,

  deleteTechnologyPending: false,
  deleteTechnologySuccess: false,
  deleteTechnologyError: null,

  downloadTechonlogyReportPending: false,
  downloadTechonlogyReportSuccess: false,
  downloadTechonlogyReportError: null,

  downloadUserReportPending: false,
  downloadUserReportSuccess: false,
  downloadUserReportError: null,

  categoryList: [],
  ProficiencyList: [],
  TechnologyList: [],
  newTechnologyID: "",
};

export default function skillManagement(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORY_PENDING:
    case GET_CATEGORY_SUCCESS:
    case GET_CATEGORY_ERROR:
      return Object.assign({}, state, payload);

    case ADD_CATEGORY_PENDING:
    case ADD_CATEGORY_SUCCESS:
    case ADD_CATEGORY_ERROR:
      return Object.assign({}, state, payload);

    case UPDATE_CATEGORY_PENDING:
    case UPDATE_CATEGORY_SUCCESS:
    case UPDATE_CATEGORY_ERROR:
      return Object.assign({}, state, payload);

    case DELETE_CATEGORY_PENDING:
    case DELETE_CATEGORY_SUCCESS:
    case DELETE_CATEGORY_ERROR:
      return Object.assign({}, state, payload);

    case GET_PROFICIENCY_PENDING:
    case GET_PROFICIENCY_SUCCESS:
    case GET_PROFICIENCY_ERROR:
      return Object.assign({}, state, payload);

    case ADD_PROFICIENCY_PENDING:
    case ADD_PROFICIENCY_SUCCESS:
    case ADD_PROFICIENCY_ERROR:
      return Object.assign({}, state, payload);

    case UPDATE_PROFICIENCY_PENDING:
    case UPDATE_PROFICIENCY_SUCCESS:
    case UPDATE_PROFICIENCY_ERROR:
      return Object.assign({}, state, payload);

    case DELETE_PROFICIENCY_PENDING:
    case DELETE_PROFICIENCY_SUCCESS:
    case DELETE_PROFICIENCY_ERROR:
      return Object.assign({}, state, payload);

    case GET_TECHNOLOGY_PENDING:
    case GET_TECHNOLOGY_SUCCESS:
    case GET_TECHNOLOGY_ERROR:
      return Object.assign({}, state, payload);

    case ADD_TECHNOLOGY_PENDING:
    case ADD_TECHNOLOGY_SUCCESS:
    case ADD_TECHNOLOGY_ERROR:
      return Object.assign({}, state, payload);

    case SAVE_TECHNOLOGY_CATEGORY_PENDING:
    case SAVE_TECHNOLOGY_CATEGORY_SUCCESS:
    case SAVE_TECHNOLOGY_CATEGORY_ERROR:
      return Object.assign({}, state, payload);

    case DELETE_MAPPED_TECHNOLOGY_CATEGORY_PENDING:
    case DELETE_MAPPED_TECHNOLOGY_CATEGORY_SUCCESS:
    case DELETE_MAPPED_TECHNOLOGY_CATEGORY_ERROR:
      return Object.assign({}, state, payload);

    case UPDATE_TECHNOLOGY_PENDING:
    case UPDATE_TECHNOLOGY_SUCCESS:
    case UPDATE_TECHNOLOGY_ERROR:
      return Object.assign({}, state, payload);

    case UPDATE_TECHNOLOGY_CATEGORY_PENDING:
    case UPDATE_TECHNOLOGY_CATEGORY_SUCCESS:
    case UPDATE_TECHNOLOGY_CATEGORY_ERROR:
      return Object.assign({}, state, payload);

    case DELETE_TECHNOLOGY_PENDING:
    case DELETE_TECHNOLOGY_SUCCESS:
    case DELETE_TECHNOLOGY_ERROR:
      return Object.assign({}, state, payload);

    case DOWNLOAD_TECHNOLOGY_REPORT_PENDING:
    case DOWNLOAD_TECHNOLOGY_REPORT_SUCCESS:
    case DOWNLOAD_TECHNOLOGY_REPORT_ERROR:
      return Object.assign({}, state, payload);

    case DOWNLOAD_USER_REPORT_PENDING:
    case DOWNLOAD_USER_REPORT_SUCCESS:
    case DOWNLOAD_USER_REPORT_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
