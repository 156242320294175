import { createStore } from "redux";
import { globals } from "../globals";
import { rootReducer } from "./rootReducer";
import { applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";

globals.history = createBrowserHistory();

export const store = createStore(
  rootReducer(globals.history),
  {},
  applyMiddleware(thunk)
);

globals.store = store;
