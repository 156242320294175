import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import {
  ButtonView,
  Input,
  Select,
  TextArea,
  CircularButtonView,
} from "../../../controls";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import moment from "moment";
const leaveSubTypesListOptions = [
  { value: "Full Day", label: "Full Day" },
  { value: "Half Day - 1st Half", label: "Half Day - 1st Half" },
  { value: "Half Day - 2nd Half", label: "Half Day - 2nd Half" },
];

const EditLeaveFormModal = ({
  editLeaveDetailsShow,
  handleClose,
  data,
  editLeaveData,
  setEditLeaveData,
  masterLeaveOptions,
  LeaveSubTypeOptions,
  userDocument,
  downloadFile,
  updateLeaveDetail,
}) => {
  // console.log("edit", editLeaveData);
  const [errors, setErrors] = useState({});

  const onLoadFile = (e) => {
    // console.log("e", e);
    if (e.target.files[0]) {
      setEditLeaveData({ ...editLeaveData, file: e.target.files[0] });
    }
  };

  const handleSubmit = () => {
    var rules = {
      required: [
        "leaveSubType",
        "masterLeaveType",
        "leaveType",
        "startDate",
        "endDate",
        "totalDays",
      ],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(editLeaveData),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      const data = {
        _id: editLeaveData.id,
        leaveSubType: editLeaveData.leaveSubType,
        masterLeaveType: editLeaveData.masterLeaveType,
        leaveType: editLeaveData.leaveType,
        startDate: editLeaveData.startDate,
        endDate: editLeaveData.endDate,
        totalDays: editLeaveData.totalDays,
        comments: editLeaveData.comments,
      };
      console.log("edit", data);
      updateLeaveDetail(data);
      handleClose();
    }
  };

  return (
    <Modal
      show={editLeaveDetailsShow}
      onHide={handleClose}
      aria-labelledby="edit_modal"
      size="lg"
    >
      <Modal.Header>
        <Modal.Title id="edit_modal">Edit Leave Details</Modal.Title>
        <div onClick={handleClose} style={{ cursor: "pointer" }}>
          <AiOutlineClose size={20} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} lg={6} className="mb-3">
            <Select
              name="leaveSubType"
              label="FD/1H/2H"
              isSearchable={false}
              placeholder="Select"
              options={leaveSubTypesListOptions}
              defaultValue={leaveSubTypesListOptions.find(
                (option) => option.value === editLeaveData.leaveSubType
              )}
              onChange={(val) =>
                setEditLeaveData({ ...editLeaveData, leaveSubType: val.value })
              }
              className="select"
              classNamePrefix="select"
              isrequired={true}
              error={errors["leaveSubType"]}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="approver"
              label="Approver"
              value={editLeaveData.approver}
              type="text"
              placeholder="Approver"
              readOnly
              size="fluid"
              disabled={true}
              // error={errors["lastName"]}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Select
              name="masterLeaveType"
              label="Leave Type"
              isSearchable={false}
              placeholder="Select Leave Type"
              defaultValue={masterLeaveOptions.find(
                (option) => option.value === editLeaveData.masterLeaveType
              )}
              options={masterLeaveOptions}
              onChange={(val) =>
                setEditLeaveData({
                  ...editLeaveData,
                  masterLeaveType: val.value,
                })
              }
              className="select"
              classNamePrefix="select"
              error={errors["masterLeaveType"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Select
              name="leaveType"
              label="Leave Sub Type"
              isSearchable={false}
              placeholder="Select Sub Leave Type"
              options={LeaveSubTypeOptions}
              defaultValue={LeaveSubTypeOptions.find(
                (option) => option.value === editLeaveData.leaveType
              )}
              onChange={(val) =>
                setEditLeaveData({ ...editLeaveData, leaveType: val.value })
              }
              className="select"
              classNamePrefix="select"
              error={errors["leaveType"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="startDate"
              label="Start Date"
              value={moment(editLeaveData.startDate).format("YYYY-MM-DD")}
              type="date"
              onChange={(e) =>
                setEditLeaveData({
                  ...editLeaveData,
                  startDate: e.target.value,
                })
              }
              size="fluid"
              //  min={moment(new Date()).format("YYYY-MM-DD")}
              error={errors["startDate"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="endDate"
              label="End Date"
              value={moment(editLeaveData.endDate).format("YYYY-MM-DD")}
              type="date"
              onChange={(e) =>
                setEditLeaveData({
                  ...editLeaveData,
                  endDate: e.target.value,
                })
              }
              size="fluid"
              min={editLeaveData.startDate}
              error={errors["endDate"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            {userDocument ? (
              <div className="d-flex justify-content-center mt-3">
                <p className="mt-2">Download file</p>
                <CircularButtonView
                  Icon={AiOutlineArrowDown}
                  onClick={() => {
                    downloadFile(userDocument.employeeId, userDocument._id);
                  }}
                  size={20}
                />
              </div>
            ) : (
              <Input
                name="file"
                label="Upload File"
                type="file"
                placeholder="Upload File"
                onChange={onLoadFile}
                size="fluid"
                accept=".pdf,.png,.jpg,.doc,.docx"
              />
            )}
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="totalDays"
              label="Total No of Days"
              type="text"
              value={editLeaveData.totalDays}
              placeholder="Total No of Days"
              onChange={(e) =>
                setEditLeaveData({
                  ...editLeaveData,
                  totalDays: e.target.value,
                })
              }
              size="fluid"
              error={errors["totalDays"]}
              isrequired={true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <TextArea
              name="comments"
              label="Leave Reason"
              value={editLeaveData.comments}
              type="textarea"
              placeholder="Type something..."
              onChange={(e) =>
                setEditLeaveData({ ...editLeaveData, comments: e.target.value })
              }
              size="fluid"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Col xs={6} md={6} lg={3}>
            <ButtonView
              onClick={handleClose}
              title={"Cancel"}
              styles="danger-outline"
            />
          </Col>
          <Col xs={6} md={6} lg={3}>
            <ButtonView
              onClick={handleSubmit}
              title={"Save"}
              styles="secondary"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EditLeaveFormModal;
