import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import "./CircularButtonstyles.scss";

const CircularButtonView = ({
  Icon,
  onClick,
  disabled,
  size,
  tooltip,
  place,
  id = "",
  styles,
}) => {
  // console.log("tooltip", place);
  return (
    <button
      onClick={onClick}
      data-tip={tooltip}
      data-for={id ? id : "main"}
      disabled={disabled}
      className={`${styles}? ${styles} : circular_btn`}
    >
      <Icon size={size} />
      <ReactTooltip
        className="circukar_tooltip"
        id={id ? id : "main"}
        place={place || "top"}
        type="dark"
        effect="solid"
      />
    </button>
  );
};

CircularButtonView.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  tooltip: PropTypes.string,
};

CircularButtonView.defaultProps = {
  disabled: false,
  size: 20,
  tooltip: "",
};

export default CircularButtonView;
