import React, { useState, useEffect } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
// import {IoDownload,IoRemove} from "react-icons/io5";
import {RiDeleteBin5Line,RiFileDownloadLine} from "react-icons/ri";
import { DataTable, Loader } from "../../../../common";
import { ButtonView, TextArea, Select, Input } from "../../../../controls";
import * as validator from "../../../../../helpers/validator";
import { trimFieldsData } from "../../../../../utils";

const initState = {
  category: "",
  description: "",
  file: null,
  location: "",
};

const DocumentsMgnt = ({
  userDocumentsList,
  addDocumentCategory,
  editDocumentCategory,
  getCategoryDocumentList,
  categoryDocumentsList,

  categoryDocumentsListSuccess,
  downloadDocument,
  locationList,
  uploadDocumentForCategory,
  deleteDocument
}) => {
  // console.log(locationList);
  const locationOptions = locationList.map((location) => {
    return { value: location._id, label: location.name };
  });
  locationOptions.push({ value: "all", label: "All" });
  const [showAddDocCatgeroy, setShowAddDocCatgeroy] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [categoryId, setCategoryId] = useState("");

  const [uploadDocState, setUploadDocState] = useState(initState);

  const [viewCategoryList, setViewCategoryList] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState(false);
  const [showUploadDocumentView, setShowUploadDocumentView] = useState(false);

  const onLoadFile = (e) => {
    if (e.target.files[0]) {
      setUploadDocState({
        ...uploadDocState,
        file: e.target.files[0],
      });
    }
  };

  const handleUploadDocChange = (field) => (e) => {
    let value;

    const isDate = e instanceof Date;
    const isTextField = e.target;
    if (isDate) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }
    setUploadDocState({
      ...uploadDocState,
      [field]: value,
    });
  };

  const handleSelectChange = (field) => (e) => {
    let value;
    value = e;

    setUploadDocState({
      ...uploadDocState,
      [field]: value,
    });
  };

  useEffect(() => {
    if (categoryDetail) {
      if (viewCategoryList) {
        getCategoryDocumentList(categoryDetail._id);
      }
      // console.log("Hi");
    }
  }, [categoryDetail, getCategoryDocumentList, viewCategoryList]);

  const categoryColumns = [
    {
      name: "Name",
      selector: "description",
      sortable: true,
      grow: 6,
      width: "200px",
    },
    {
      name: "Location",
      grow: 4,
      cell: (row) => {
        const getLocation = locationList.find(
          (item) => item._id === row.location
        );
        return <div>{getLocation ? getLocation.name : "All"}</div>;
      },
    },
    {
      name: "Option",
      center: true,
      minWidth: "70px",
      grow: 3,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div className="me-1">
              {/* <ButtonView
                onClick={() => {
                  downloadDocument(row._id);
                }}
                title={"Download"}
                size="small"
              /> */}
              <RiFileDownloadLine size={23} 
              style={{cursor:'pointer',color:"d87d2e"}}
                onClick={() => {
                  downloadDocument(row._id);
                }}        
              />
              <RiDeleteBin5Line size={23} 
              style={{cursor:'pointer',color:'#db3700'}}
                onClick={() => {                                                    
                  if (window.confirm(`Are you sure you want to delete User Document Domain? Category : ${row.category.name}
Name : ${row.description}`)){
                      // console.log(categoryDocumentsList,"downloadDocument");
                    deleteDocument(row._id);
                  }
                }}  
              />
            </div>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      name: "Category",
      selector: "name",
      sortable: true,
      grow: 4,
    },
    {
      name: "Description",
      selector: "description",
      // sortable: true,
      // center: true,
      grow: 4,
    },
    {
      name: "Option",
      center: true,
      minWidth: "70px",
      grow: 2,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div className="me-1">
              <ButtonView
                onClick={() => {
                  setShowEdit(true);
                  setCategoryName(row.name);
                  setDescription(row.description);
                  setShowAddDocCatgeroy(true);
                  setCategoryId(row._id);
                }}
                title={"Edit"}
                size="small"
                styles="primal"
              />
            </div>
          </div>
        );
      },
    },
    {
      name: "Details",
      center: true,
      minWidth: "90px",
      grow: 4,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div className="me-1">
              <ButtonView
                onClick={() => {
                  setCategoryDetail(row);
                  setViewCategoryList(true);
                }}
                title={"View List"}
                size="small"
                styles="primal"
              />
            </div>
          </div>
        );
      },
    },
  ];

  const handleAddDocCategory = () => {
    var rules = { required: ["categoryName"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ categoryName, description }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const data = { name: categoryName, description: description };
        editDocumentCategory(data, categoryId);
        // edit
      } else {
        const data = { name: categoryName, description: description };
        addDocumentCategory(data);
        // add
      }
      handleClose();
    }
  };

  const handleUploadDocForCategory = () => {
    var rules = { required: ["category", "file", "location", "description"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(uploadDocState),
    });

    setErrors(errors);
    if (!Object.keys(errors).length) {
      uploadDocumentForCategory(uploadDocState);
      handleUploadCatgeoryModalClose();
    }
  };

  const handleCategoryModalClose = () => {
    setViewCategoryList(false);
  };

  const handleUploadCatgeoryModalClose = () => {
    setShowUploadDocumentView(false);
    setErrors({});
    setUploadDocState(initState);
  };

  const handleClose = () => {
    setShowAddDocCatgeroy(false);
    setCategoryName("");
    setDescription("");
    setShowEdit(false);
    setCategoryId("");
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Documents</div>
              <div className="d-none d-md-block">Documents Management</div>
            </Card.Title>
            <div className="add_permission d-flex flex-wrap align-items-center">
              <div className="d-none d-md-flex">
                <div className="me-2">
                  <ButtonView
                    onClick={() => setShowAddDocCatgeroy(true)}
                    title={"Add Category"}
                    // styles="secondary"
                  />
                </div>
                <ButtonView
                  onClick={() => setShowUploadDocumentView(true)}
                  title={"Upload Document"}
                  styles="secondary"
                />
              </div>
            </div>
          </Card.Header>
          <div className="d-block d-md-none">
            <div className="mb-2">
              <ButtonView
                onClick={() => {}}
                title={"Add Category"}
                // styles="secondary"
              />
            </div>
            <ButtonView
              onClick={() => {}}
              title={"Upload Document"}
              styles="secondary"
            />
          </div>
          <DataTable
            data={userDocumentsList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddDocCatgeroy}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Category" : "Add Category"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12} className="mb-3">
                <Input
                  name="name"
                  label="Category type"
                  value={categoryName}
                  type="text"
                  placeholder="Add Category"
                  onChange={(e) => setCategoryName(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["categoryName"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12}>
                <TextArea
                  name="description"
                  label="Description"
                  value={description}
                  type="number"
                  placeholder="Some text here..."
                  onChange={(e) => setDescription(e.target.value)}
                  size="fluid"
                />
              </Col>

              <Col xs={4} className="mt-3">
                <ButtonView
                  onClick={handleAddDocCategory}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <div className="view_modal">
        <Modal
          show={viewCategoryList}
          onHide={() => {
            handleCategoryModalClose();
          }}
          aria-labelledby="view_modal"
        >
          <Modal.Header>
            <Modal.Title id="view_modal">Category Detail</Modal.Title>
            <div
              onClick={() => {
                handleCategoryModalClose();
              }}
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="comments ms-1">
              <h5>Category Name: {categoryDetail.name}</h5>
              <h6>{categoryDetail.description}</h6>
            </div>
            {categoryDocumentsListSuccess === false ? (
              <Loader type={"smallLoader"} />
            ) : (
              <DataTable
                data={categoryDocumentsList}
                columns={categoryColumns}
                pagination={true}
                // defaultSortField={"name"}
                paginationPerPage={5}
                paginationServer={false}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>

      <div className="view_modal">
        <Modal
          show={showUploadDocumentView}
          onHide={() => {
            handleUploadCatgeoryModalClose();
          }}
          aria-labelledby="view_modal"
        >
          <Modal.Header>
            <Modal.Title id="view_modal">Upload Document</Modal.Title>
            <div
              onClick={() => {
                handleUploadCatgeoryModalClose();
              }}
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="add_role">
              <Row className="mb-2 d-flex justify-content-end">
                <Col xs={12} className="mb-3">
                  <Select
                    name="uploadCategory"
                    label="Select Category"
                    isSearchable={false}
                    placeholder="Select"
                    options={userDocumentsList.map((item) => {
                      return { value: item._id, label: item.name };
                    })}
                    onChange={handleSelectChange("category")}
                    className="select"
                    classNamePrefix="select"
                    isrequired={true}
                    error={errors["category"]}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Select
                    isSearchable={false}
                    onChange={handleSelectChange("location")}
                    placeholder="Select Location"
                    label="Select Location"
                    options={locationOptions}
                    className="select"
                    classNamePrefix="select"
                    error={errors["location"]}
                    isrequired={true}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Input
                    name="file"
                    label="Document"
                    type="file"
                    placeholder="Document"
                    onChange={onLoadFile}
                    size="fluid"
                    accept=".pdf,.png,.jpg,.doc,.docx"
                    error={errors["file"]}
                    isrequired={true}
                  />
                </Col>
                <Col xs={12}>
                  <TextArea
                    name="description"
                    label="Description"
                    value={uploadDocState.description}
                    type="number"
                    placeholder="Some text here..."
                    onChange={handleUploadDocChange("description")}
                    size="fluid"
                    error={errors["description"]}
                    isrequired={true}
                    maxLength={30}
                  />
                </Col>

                <Col xs={4} className="mt-3">
                  <ButtonView
                    onClick={handleUploadDocForCategory}
                    title={"Upload"}
                    styles="secondary"
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default DocumentsMgnt;
