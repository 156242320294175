import { actionTypes } from "./jobApplicationActions";

const {
  JOB_APPLICATION_LIST_PENDING,
  JOB_APPLICATION_LIST_SUCCESS,
  JOB_APPLICATION_LIST_ERROR,

  JOB_APPLICATION_LIST_INACTIVE_PENDING,
  JOB_APPLICATION_LIST_INACTIVE_SUCCESS,
  JOB_APPLICATION_LIST_INACTIVE_ERROR,

  JOB_APPLICATION_BY_ID_PENDING,
  JOB_APPLICATION_BY_ID_SUCCESS,
  JOB_APPLICATION_BY_ID_ERROR,

  QUALIFICATION_LIST_PENDING,
  QUALIFICATION_LIST_SUCCESS,
  QUALIFICATION_LIST_ERROR,

  USERLIST_PENDING,
  USERLIST_SUCCESS,
  USERLIST_ERROR,

  INTERVIEW_SCHEDULE_LIST_PENDING,
  INTERVIEW_SCHEDULE_LIST_SUCCESS,
  INTERVIEW_SCHEDULE_LIST_ERROR,

  CREATE_JOB_PENDING,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,

  CREATE_INTERVIEW_PENDING,
  CREATE_INTERVIEW_SUCCESS,
  CREATE_INTERVIEW_ERROR,

  UPDATE_JOB_PENDING,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,

  CLOSE_JOB_PENDING,
  CLOSE_JOB_SUCCESS,
  CLOSE_JOB_ERROR,

  UPDATE_INTERVIEW_PENDING,
  UPDATE_INTERVIEW_SUCCESS,
  UPDATE_INTERVIEW_ERROR,

  FILTER_INTERVIEW_SCHEDULE,
  VIEW_ALL_INTERVIEW_SCHEDULE,
} = actionTypes;

const initialState = {
  jobApplicationListPending: false,
  jobApplicationListSuccess: false,
  jobApplicationListError: null,

  jobApplicationInactivePending: true,
  jobApplicationInactiveSuccess: true,
  jobApplicationInactiveError: null,

  jobApplicationByIdPending: false,
  jobApplicationByIdSuccess: false,
  jobApplicationByIdError: null,

  qualificationListPending: false,
  qualificationListSuccess: false,
  qualificationListError: null,

  interviewScheduleListPending: false,
  interviewScheduleListSuccess: false,
  interviewScheduleListError: null,

  userListPending: false,
  userListSuccess: false,
  userListError: null,

  createJobPending: false,
  createJobSuccess: false,
  createJobError: null,

  createInterviewPending: false,
  createInterviewSuccess: false,
  createInterviewError: null,

  updateJobPending: false,
  updateJobSuccess: false,
  updateJobError: null,

  closeJobPending: false,
  closeJobSuccess: false,
  closeJobError: null,

  updateInterviewPending: false,
  updateInterviewSuccess: false,
  updateInterviewError: null,

  jobApplicationList: [],
  jobApplicationInactiveList: [],
  qualificationList: [],
  interviewScheduleList: [],
  totalInterviewScheduleList: [],
  userList: [],
  jobApplicationById: null,
};

export default function jobApplication(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case JOB_APPLICATION_LIST_PENDING:
    case JOB_APPLICATION_LIST_ERROR:
    case JOB_APPLICATION_LIST_SUCCESS:
      return Object.assign({}, state, payload);
    case JOB_APPLICATION_LIST_INACTIVE_PENDING:
    case JOB_APPLICATION_LIST_INACTIVE_ERROR:
    case JOB_APPLICATION_LIST_INACTIVE_SUCCESS:
      return Object.assign({}, state, payload);
    case JOB_APPLICATION_BY_ID_PENDING:
    case JOB_APPLICATION_BY_ID_ERROR:
    case JOB_APPLICATION_BY_ID_SUCCESS:
      return Object.assign({}, state, payload);
    case QUALIFICATION_LIST_PENDING:
    case QUALIFICATION_LIST_ERROR:
    case QUALIFICATION_LIST_SUCCESS:
      return Object.assign({}, state, payload);
    case USERLIST_PENDING:
    case USERLIST_ERROR:
    case USERLIST_SUCCESS:
      return Object.assign({}, state, payload);
    case INTERVIEW_SCHEDULE_LIST_PENDING:
    case INTERVIEW_SCHEDULE_LIST_ERROR:
    case INTERVIEW_SCHEDULE_LIST_SUCCESS:
      return Object.assign({}, state, payload);
    case CREATE_JOB_PENDING:
    case CREATE_JOB_ERROR:
      return Object.assign({}, state, payload);
    case CREATE_JOB_SUCCESS: {
      const newjobApplication = payload.appliedJobdata;
      const jobApplicationList = [
        newjobApplication,
        ...state.jobApplicationList,
      ];

      const newState = {
        jobApplicationList: jobApplicationList,
        createJobPending: payload.createJobPending,
        createJobSuccess: payload.createJobSuccess,
        createJobError: payload.createJobError,
      };
      return Object.assign({}, state, newState);
    }
    case CREATE_INTERVIEW_PENDING:
    case CREATE_INTERVIEW_ERROR:
      return Object.assign({}, state, payload);
    case CREATE_INTERVIEW_SUCCESS: {
      const appliedInterviewdata = payload.appliedInterviewdata;
      const totalInterviewScheduleList = [
        ...state.totalInterviewScheduleList,
        appliedInterviewdata,
      ];

      const newState = {
        totalInterviewScheduleList: totalInterviewScheduleList,
        createJobPending: payload.createJobPending,
        createJobSuccess: payload.createJobSuccess,
        createJobError: payload.createJobError,
      };
      return Object.assign({}, state, newState);
    }
    case UPDATE_JOB_PENDING:
    case UPDATE_JOB_ERROR:
      return Object.assign({}, state, payload);
    case UPDATE_JOB_SUCCESS: {
      const newjobApplication = state.jobApplicationList.map((list) => {
        if (list._id === payload.updateJob._id) {
          return payload.updateJob;
        } else {
          return list;
        }
      });
      const newState = {
        jobApplicationList: newjobApplication,
        updateJobPending: payload.updateJobPending,
        updateJobSuccess: payload.updateJobSuccess,
        updateJobError: payload.updateJobError,
      };
      return Object.assign({}, state, newState);
      // return Object.assign({}, state, newState);
    }
    case CLOSE_JOB_PENDING:
    case CLOSE_JOB_ERROR:
      return Object.assign({}, state, payload);
    case CLOSE_JOB_SUCCESS: {
      const newjobApplication = state.jobApplicationList.filter((list) => {
        return list._id !== payload.closeJob;
      });
      const newInactivejobApplication = state.jobApplicationList.find(
        (list) => {
          return list._id === payload.closeJob;
        }
      );
      const newState = {
        jobApplicationList: newjobApplication,
        closeJobPending: payload.closeJobPending,
        closeJobSuccess: payload.closeJobSuccess,
        closeJobError: payload.closeJobError,
        jobApplicationInactiveList: [
          { ...newInactivejobApplication, isActive: false },
          ...state.jobApplicationInactiveList,
        ],
      };
      return Object.assign({}, state, newState);
      // return Object.assign({}, state, newState);
    }
    case UPDATE_INTERVIEW_PENDING:
    case UPDATE_INTERVIEW_ERROR:
      return Object.assign({}, state, payload);
    case UPDATE_INTERVIEW_SUCCESS: {
      const newTotalinterviewScheduleList =
        state.totalInterviewScheduleList.map((list) => {
          if (list._id === payload.updateInterview._id) {
            return payload.updateInterview;
          } else {
            return list;
          }
        });
      const newinterviewScheduleList = state.interviewScheduleList.map(
        (list) => {
          if (list._id === payload.updateInterview._id) {
            return payload.updateInterview;
          } else {
            return list;
          }
        }
      );
      const newState = {
        totalInterviewScheduleList: newTotalinterviewScheduleList,
        interviewScheduleList: newinterviewScheduleList,
        updateInterviewPending: payload.updateInterviewPending,
        updateInterviewSuccess: payload.updateInterviewSuccess,
        updateInterviewError: payload.updateInterviewError,
      };
      return Object.assign({}, state, newState);
      // return Object.assign({}, state, newState);
    }
    case FILTER_INTERVIEW_SCHEDULE: {
      // console.log(payload.date);
      // console.log(state.totalInterviewScheduleList);
      const newinterviewScheduleList = state.totalInterviewScheduleList.filter(
        (list) => {
          return payload.date === list.interviewDate.slice(0, 10);
        }
      );
      const newState = {
        interviewScheduleList: newinterviewScheduleList,
      };
      return Object.assign({}, state, newState);
    }
    case VIEW_ALL_INTERVIEW_SCHEDULE: {
      const newState = {
        interviewScheduleList: state.totalInterviewScheduleList,
      };
      return Object.assign({}, state, newState);
    }
    default:
      return state;
  }
}
