import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { globals } from "../globals";

export const actionTypes = {
  EMPLOYEE_LEAVE_LIST_PENDING: "EMPLOYEE_LEAVE_LIST_PENDING",
  EMPLOYEE_LEAVE_LIST_SUCCESS: "EMPLOYEE_LEAVE_LIST_SUCCESS",
  EMPLOYEE_LEAVE_LIST_ERROR: "EMPLOYEE_LEAVE_LIST_ERROR",

  TOTAL_EMPLOYEE_LEAVE_LIST_PENDING: "TOTAL_EMPLOYEE_LEAVE_LIST_PENDING",
  TOTAL_EMPLOYEE_LEAVE_LIST_SUCCESS: "TOTAL_EMPLOYEE_LEAVE_LIST_SUCCESS",
  TOTAL_EMPLOYEE_LEAVE_LIST_ERROR: "TOTAL_EMPLOYEE_LEAVE_LIST_ERROR",

  MY_TEAM_EMPLOYEE_LEAVE_LIST_PENDING: "MY_TEAM_EMPLOYEE_LEAVE_LIST_PENDING",
  MY_TEAM_EMPLOYEE_LEAVE_LIST_SUCCESS: "MY_TEAM_EMPLOYEE_LEAVE_LIST_SUCCESS",
  MY_TEAM_EMPLOYEE_LEAVE_LIST_ERROR: "MY_TEAM_EMPLOYEE_LEAVE_LIST_ERROR",

  LEAVE_MASTER_TYPE_LIST_PENDING: "LEAVE_MASTER_TYPE_LIST_PENDING",
  LEAVE_MASTER_TYPE_LIST_SUCCESS: "LEAVE_MASTER_TYPE_LIST_SUCCESS",
  LEAVE_MASTER_TYPE_LIST_ERROR: "LEAVE_MASTER_TYPE_LIST_ERROR",

  EMPLOYEE_SUMMARY_PENDING: "EMPLOYEE_SUMMARY_PENDING",
  EMPLOYEE_SUMMARY_SUCCESS: "EMPLOYEE_SUMMARY_SUCCESS",
  EMPLOYEE_SUMMARY_ERROR: "EMPLOYEE_SUMMARY_ERROR",

  OTHER_SUMMARY_PENDING: "OTHER_SUMMARY_PENDING",
  OTHER_SUMMARY_SUCCESS: "OTHER_SUMMARY_SUCCESS",
  OTHER_SUMMARY_ERROR: "OTHER_SUMMARY_ERROR",

  APPLY_LEAVE_PENDING: "APPLY_LEAVE_PENDING",
  APPLY_LEAVE_SUCCESS: "APPLY_LEAVE_SUCCESS",
  APPLY_LEAVE_ERROR: "APPLY_LEAVE_ERROR",

  EMPLOYEE_LEAVE_BY_ID_PENDING: "EMPLOYEE_LEAVE_BY_ID_PENDING",
  EMPLOYEE_LEAVE_BY_ID_SUCCESS: "EMPLOYEE_LEAVE_BY_ID_SUCCESS",
  EMPLOYEE_LEAVE_BY_ID_ERROR: "EMPLOYEE_LEAVE_BY_ID_ERROR",

  CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_PENDING:
    "CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_PENDING",
  CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_SUCCESS:
    "CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_SUCCESS",
  CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_ERROR:
    "CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_ERROR",

  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING:
    "OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING",
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS:
    "OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS",
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR: "OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR",

  UPDATE_LEAVE_DETAIL_PENDING: "UPDATE_LEAVE_DETAIL_PENDING",
  UPDATE_LEAVE_DETAIL_SUCCESS: "UPDATE_LEAVE_DETAIL_SUCCESS",
  UPDATE_LEAVE_DETAIL_ERROR: "UPDATE_LEAVE_DETAIL_ERROR",
};

export function applyForLeave(
  leaveData,
  file,
  user,
  approverName,
  currentYear
) {
  // console.log("leavedata", leaveData);
  // console.log("user", user);
  // console.log("approverName", approverName);
  // console.log("currentYear", currentYear);
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPLY_LEAVE_PENDING,
      payload: {
        applyForLeavePending: true,
        applyForLeaveSuccess: false,
        applyForLeaveError: null,
      },
    });
    dispatch(showLoader());
    var date1 = new Date(leaveData.startDate);
    var date2 = new Date(leaveData.endDate);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const formdata = new FormData();
    formdata.append("employeeId", user._id);
    formdata.append("leaveType", leaveData.leaveType);
    formdata.append("leaveSubType", leaveData.leaveSubType);
    formdata.append("reportingManagerId", user.reportingManagerEmployeeId);
    formdata.append("startDate", leaveData.startDate);
    formdata.append("endDate", leaveData.endDate);
    formdata.append("totalDays", `${Difference_In_Days + 1}`);
    formdata.append("status", "0");
    formdata.append("masterLeaveType", leaveData.masterLeaveType);
    // formdata.append("reportingManagerEmailId", approverName.email);
    formdata.append(
      "createdBy",
      `${user.userId[0].firstName} ${user.userId[0].lastName}`
    );
    formdata.append(
      "lastUpdatedBy",
      `${user.userId[0].firstName} ${user.userId[0].lastName}`
    );
    formdata.append("reportingManagerName", approverName.name);

    if (leaveData.comments) {
      formdata.append("comments", leaveData.comments);
    }
    if (file) {
      formdata.append("file", file);
    }
    // console.log("formdata", ...formdata);

    Api.post("employeeLeaveManagement/applyForLeave", formdata)
      .then((response) => {
        // console.log(response);
        dispatch(hideLoader());
        if (response.data) {
          dispatch(getEmployeeLeavesSummary(false, currentYear));
          dispatch(getOptionalHolidayLeaveSummary(false, currentYear));
          dispatch(getOtherLeavesSummary(false, currentYear));
          dispatch({
            type: actionTypes.APPLY_LEAVE_SUCCESS,
            payload: {
              applyForLeavePending: false,
              applyForLeaveSuccess: true,
              applyForLeaveError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully uploaded leave.",
            })
          );
          dispatch(employeeLeaveList(false, currentYear));
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: response.message,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.APPLY_LEAVE_ERROR,
          payload: {
            applyForLeavePending: false,
            applyForLeaveSuccess: true,
            applyForLeaveError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to upload leave.",
          })
        );
      });
  };
}

export function updateLeave(id, user, val, status, message, year) {
  // console.log("id", id);

  return (dispatch) => {
    var data = {};
    if (val) {
      // console.log("val", val);
      data = {
        lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        rejectionOrCancellationComments: val,
        status: status,
      };
    } else {
      data = {
        lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        status: status,
      };
    }
    // console.log(message)
    Api.post(`employeeLeaveManagement/updateLeaveStatus/${id}`, data)
      .then((response) => {
        // console.log(response);
        dispatch(myTeamEmployeeLeaveList(false, year));
        dispatch(employeeLeaveList(false, year));
        dispatch(getEmployeeLeavesSummary(false, year));
        dispatch(getOptionalHolidayLeaveSummary(false, year));
        dispatch(getOtherLeavesSummary(false, year));
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            // msg: "Successfully cancelled leave.",
            msg: message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update leave.",
          })
        );
      });
  };
}

export function employeeLeaveList(showLoader = true, year) {
  // console.log("year", year);
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.EMPLOYEE_LEAVE_LIST_PENDING,
        payload: {
          employeeLeaveListPending: true,
          employeeLeaveListSuccess: false,
          employeeLeaveListError: null,
        },
      });
    }
    const userId = globals.store.getState().auth.loginUser.userId;
    Api.get(`user/${userId}`).then((response) => {
      dispatch(totalemployeeLeaveList(response.data[0]._id));
      Api.post(`employeeLeaveManagement/getEmployeeLeaves?limit=${1000}`, {
        // year: getNextYearLeave
        //   ? new Date().getFullYear() + 1
        //   : new Date().getFullYear(),
        year: year,
        employeeId: response.data[0]._id,
      })
        .then((response) => {
          // console.log("response", response);
          const sortedArray = response.data.docs
            .sort((a, b) => {
              return new Date(b.startDate) - new Date(a.startDate);
            })
            .reverse();
          if (response.data) {
            dispatch({
              type: actionTypes.EMPLOYEE_LEAVE_LIST_SUCCESS,
              payload: {
                employeeLeaveListPending: false,
                employeeLeaveListSuccess: true,
                employeeLeaveListError: null,

                employeeLeaveList: sortedArray,
                currentYear: year,
                // showNextYearLeave: getNextYearLeave,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: actionTypes.EMPLOYEE_LEAVE_LIST_ERROR,
            payload: {
              employeeLeaveListPending: false,
              employeeLeaveListSuccess: true,
              employeeLeaveListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get list.",
            })
          );
        });
    });
  };
}

export function myTeamEmployeeLeaveList(showLoader = true, year) {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.MY_TEAM_EMPLOYEE_LEAVE_LIST_PENDING,
        payload: {
          myTeamEmployeeLeaveListPending: true,
          myTeamEmployeeLeaveListSuccess: false,
          myTeamEmployeeLeaveListError: null,
        },
      });
    }

    const userId = globals.store.getState().auth.loginUser.userId;
    const userRole = globals.store.getState().auth.loginUser.userRole;
    Api.get(`user/${userId}`).then((response) => {
      var data = {};
      if (userRole === "Admin" || userRole === "Super Admin") {
        data = {
          year: year,
        };
      } else {
        data = {
          year: year,
          reportingManagerEmployeeId: response.data[0]._id,
        };
      }
      Api.post(`employeeLeaveManagement/getEmployeeLeaves?limit=${4000}`, data)
        .then((response) => {
          // console.log(response);
          if (response.data) {
            const sortedArray = response.data.docs
              .sort((a, b) => {
                return new Date(b.startDate) - new Date(a.startDate);
              })
              .reverse();
            dispatch({
              type: actionTypes.MY_TEAM_EMPLOYEE_LEAVE_LIST_SUCCESS,
              payload: {
                myTeamEmployeeLeaveListPending: false,
                myTeamEmployeeLeaveListSuccess: true,
                myTeamEmployeeLeaveListError: null,

                myTeamEmployeeLeaveList: sortedArray,
                currentYear: year,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: actionTypes.MY_TEAM_EMPLOYEE_LEAVE_LIST_ERROR,
            payload: {
              myTeamEmployeeLeaveListPending: false,
              myTeamEmployeeLeaveListSuccess: true,
              myTeamEmployeeLeaveListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get list.",
            })
          );
        });
    });
  };
}

export function getLeaveMasterTypesList() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEAVE_MASTER_TYPE_LIST_PENDING,
      payload: {
        leaveMasterTypeListPending: true,
        leaveMasterTypeListSuccess: false,
        leaveMasterTypeListError: null,
      },
    });

    const userId = globals.store.getState().auth.loginUser.userId;
    Api.get(`user/${userId}`)
      .then((response) => {
        Api.get("employeeLeaveManagement/getLeaveMasterTypesList")
          .then((response) => {
            // console.log(response);
            if (response.data) {
              dispatch({
                type: actionTypes.LEAVE_MASTER_TYPE_LIST_SUCCESS,
                payload: {
                  leaveMasterTypeListPending: false,
                  leaveMasterTypeListSuccess: true,
                  leaveMasterTypeListError: null,

                  leaveMasterTypeList: response.data,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: actionTypes.LEAVE_MASTER_TYPE_LIST_ERROR,
              payload: {
                leaveMasterTypeListPending: false,
                leaveMasterTypeListSuccess: true,
                leaveMasterTypeListError: null,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Failed to get list.",
              })
            );
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LEAVE_MASTER_TYPE_LIST_ERROR,
          payload: {
            leaveMasterTypeListPending: false,
            leaveMasterTypeListSuccess: true,
            leaveMasterTypeListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function getEmployeeLeavesSummary(showLoader = true, year) {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.EMPLOYEE_SUMMARY_PENDING,
        payload: {
          employeeLeavesSummaryPending: true,
          employeeLeavesSummarySuccess: false,
          employeeLeavesSummaryError: null,
        },
      });
    }

    const userId = globals.store.getState().auth.loginUser.userId;
    Api.get(`user/${userId}`)
      .then((response) => {
        Api.post("employeeLeaveManagement/getEmployeeLeavesSummary", {
          year: year,
          employeeId: response.data[0]._id,
        })
          .then((response) => {
            // console.log(response);
            if (response.data) {
              dispatch({
                type: actionTypes.EMPLOYEE_SUMMARY_SUCCESS,
                payload: {
                  employeeLeavesSummaryPending: false,
                  employeeLeavesSummarySuccess: true,
                  employeeLeavesSummaryError: null,

                  employeeLeavesSummary: response.data,
                },
              });
            } else {
              dispatch({
                type: actionTypes.EMPLOYEE_SUMMARY_SUCCESS,
                payload: {
                  employeeLeavesSummaryPending: false,
                  employeeLeavesSummarySuccess: true,
                  employeeLeavesSummaryError: null,

                  employeeLeavesSummary: null,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: actionTypes.EMPLOYEE_SUMMARY_ERROR,
              payload: {
                employeeLeavesSummaryPending: false,
                employeeLeavesSummarySuccess: true,
                employeeLeavesSummaryError: null,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Failed to get list.",
              })
            );
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.EMPLOYEE_SUMMARY_ERROR,
          payload: {
            employeeLeavesSummaryPending: false,
            employeeLeavesSummarySuccess: true,
            employeeLeavesSummaryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function getOtherLeavesSummary(showLoader = true, year) {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.OTHER_SUMMARY_PENDING,
        payload: {
          otherLeavesSummaryPending: true,
          otherLeavesSummarySuccess: false,
          otherLeavesSummaryError: null,
        },
      });
    }

    const userId = globals.store.getState().auth.loginUser.userId;
    Api.get(`user/${userId}`)
      .then((response) => {
        Api.post("employeeLeaveManagement/getOtherLeaveSummary", {
          year: year,
          employeeId: response.data[0]._id,
        })
          .then((response) => {
            // console.log(response);
            if (response.data) {
              dispatch({
                type: actionTypes.OTHER_SUMMARY_SUCCESS,
                payload: {
                  otherLeavesSummaryPending: false,
                  otherLeavesSummarySuccess: true,
                  otherLeavesSummaryError: null,

                  otherLeavesSummary: response.data,
                },
              });
            } else {
              dispatch({
                type: actionTypes.OTHER_SUMMARY_SUCCESS,
                payload: {
                  otherLeavesSummaryPending: false,
                  otherLeavesSummarySuccess: true,
                  otherLeavesSummaryError: null,

                  otherLeavesSummary: null,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: actionTypes.OTHER_SUMMARY_ERROR,
              payload: {
                otherLeavesSummaryPending: false,
                otherLeavesSummarySuccess: true,
                otherLeavesSummaryError: null,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Failed to get list.",
              })
            );
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.OTHER_SUMMARY_ERROR,
          payload: {
            otherLeavesSummaryPending: false,
            otherLeavesSummarySuccess: true,
            otherLeavesSummaryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function getNextYearLeaveDependOnId(id) {
  return (dispatch) => {
    Api.post(`employeeLeaveManagement/getEmployeeLeaves?limit=${4000}`, {
      year: new Date().getFullYear() + 1,
    })
      .then((response) => {
        // console.log(response);
        const leaveById = response.data.docs.find((list) => list._id === id);
        // console.log(leaveById);
        if (leaveById) {
          dispatch({
            type: actionTypes.EMPLOYEE_LEAVE_BY_ID_SUCCESS,
            payload: {
              showLeaveById: true,
              leaveById: leaveById,
            },
          });
        } else {
          dispatch({
            type: actionTypes.EMPLOYEE_LEAVE_BY_ID_ERROR,
            payload: {
              showLeaveById: false,
              leaveByIdData: null,
            },
          });
          // dispatch(
          //   showAlert({
          //     isOpen: true,
          //     title: "Error",
          //     type: "danger",
          //     msg: "Failed to get leave detail.",
          //   })
          // );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.EMPLOYEE_LEAVE_BY_ID_ERROR,
          payload: {
            showLeaveById: false,
            leaveByIdData: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get leave detail.",
          })
        );
      });
  };
}
export function closeLeaveByIdData(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMPLOYEE_LEAVE_BY_ID_ERROR,
      payload: {
        showLeaveById: false,
        leaveByIdData: null,
      },
    });
  };
}

export function totalemployeeLeaveList(empId) {
  return (dispatch) => {
    Api.post(`employeeLeaveManagement/getEmployeeLeaves?limit=${5000}`, {
      employeeId: empId,
      year: new Date().getFullYear(),
    })
      .then((response) => {
        var entireList = [];
        if (response.data) {
          entireList = [...entireList, ...response.data.docs];
          Api.post(`employeeLeaveManagement/getEmployeeLeaves?limit=${5000}`, {
            employeeId: empId,
            year: new Date().getFullYear() + 1,
          }).then((res) => {
            if (res.data) {
              entireList = [...entireList, ...res.data.docs];
              const sortedArray = entireList
                .sort((a, b) => {
                  return new Date(b.startDate) - new Date(a.startDate);
                })
                .reverse();
              // console.log(entireList);
              dispatch({
                type: actionTypes.TOTAL_EMPLOYEE_LEAVE_LIST_SUCCESS,
                payload: {
                  totalEmployeeLeaveListPending: false,
                  totalEmployeeLeaveListSuccess: true,
                  totalEmployeeLeaveListError: null,

                  totalEmployeeLeaveList: sortedArray,
                },
              });
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.TOTAL_EMPLOYEE_LEAVE_LIST_ERROR,
          payload: {
            totalEmployeeLeaveListPending: false,
            totalEmployeeLeaveListSuccess: true,
            totalEmployeeLeaveListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function canApplyOptionalHolidayLeave(year) {
  return (dispatch) => {
    const userId = globals.store.getState().auth.loginUser.userId;
    Api.get(`user/${userId}`)
      .then((response) => {
        Api.post("employeeLeaveManagement/canApplyOptionalHolidayLeave", {
          year: year,
          employeeId: response.data[0]._id,
        })
          .then((response) => {
            if (response.data) {
              dispatch({
                type: actionTypes.CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_SUCCESS,
                payload: {
                  canApply: response.data.canApply,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: actionTypes.CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_ERROR,
              payload: {
                canApply: null,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Something went wrong.",
              })
            );
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getOptionalHolidayLeaveSummary(showLoader = true, year) {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING,
        payload: {
          optionalHolidaySummaryPending: true,
          optionalHolidaySummarySuccess: false,
          optionalHolidaySummaryError: null,
        },
      });
    }
    const userId = globals.store.getState().auth.loginUser.userId;
    Api.get(`user/${userId}`)
      .then((response) => {
        Api.post("employeeLeaveManagement/getOptionalHolidayLeavesSummary", {
          year: year,
          employeeId: response.data[0]._id,
        })
          .then((response) => {
            // console.log(response);
            if (response.data) {
              dispatch({
                type: actionTypes.OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS,
                payload: {
                  optionalHolidaySummaryPending: false,
                  optionalHolidaySummarySuccess: true,
                  optionalHolidaySummaryError: null,
                  optionalHolidaySummary: response.data,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: actionTypes.OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR,
              payload: {
                optionalHolidaySummaryPending: false,
                optionalHolidaySummarySuccess: true,
                optionalHolidaySummaryError: null,
                optionalHolidaySummary: null,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Something went wrong.",
              })
            );
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updateLeaveDetail(data) {
  console.log("data", data);
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_LEAVE_DETAIL_PENDING,
      payload: {
        updateLeaveDetailPending: true,
        updateLeaveDetailSuccess: false,
        updateLeaveDetailError: null,
      },
    });

    Api.post("employeeLeaveManagement/updateLeaveDetail", data)
      .then((response) => {
        console.log("dataresponse", response);
        if (response.message === "Successfully update leave details") {
          dispatch(myTeamEmployeeLeaveList(false, new Date().getFullYear()));
          dispatch({
            type: actionTypes.UPDATE_LEAVE_DETAIL_SUCCESS,
            payload: {
              updateLeaveDetailPending: false,
              updateLeaveDetailSuccess: true,
              updateLeaveDetailError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "leave detail updated successfully.",
            })
          );
        } else {
          dispatch({
            type: actionTypes.UPDATE_LEAVE_DETAIL_ERROR,
            payload: {
              updateLeaveDetailPending: false,
              updateLeaveDetailSuccess: true,
              updateLeaveDetailError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to update leave details.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_LEAVE_DETAIL_ERROR,
          payload: {
            updateLeaveDetailPending: false,
            updateLeaveDetailSuccess: true,
            updateLeaveDetailError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update leave details.",
          })
        );
      });
  };
}
