import React from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { ButtonView, Pagination } from "../../../controls";
import { DataTable } from "../../../common";
import LoaderView from "./LoaderView";

const All = ({
  rowsPerPage,
  rowCount,
  userWfhList,
  currentPage,
  getUserWfhList,
  employeeId,
  userWfhListSuccess,
  getUserWfh,
  changingYear,
}) => {
  // console.log("user", userWfhList);

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => {
        if (row.status === 0) {
          return (
            <span className="badge bg-warning-transparent">
              Pending Approval
            </span>
          );
        }
        if (row.status === 1) {
          return <span className="badge bg-success-transparent">Approved</span>;
        }
        if (row.status === -1) {
          return <span className="badge bg-danger-transparent">Rejected</span>;
        }
        if (row.status === -2) {
          return <span className="badge bg-cancel-transparent">Cancelled</span>;
        }
      },
    },
    // {
    //   name: "Type",
    //   selector: "masterLeaveType",
    //   center: true,
    //   grow: 4,
    // },

    {
      name: "Approver",
      selector: "",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{`${
          row.reportingManagerDetailsUserId[0].firstName
        } ${row.reportingManagerDetailsUserId[0].lastName.charAt(0)}`}</span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "70px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              getUserWfh(row._id);
              // handleViewOpen()
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
  ];

  const onChangePage = (currentPage) => {
    // console.log("onchange", page);
    getUserWfhList(
      currentPage,
      {
        // status: LEAVE_STATUS_PENDING_APPROVAL,
        year: changingYear,
        employeeId: employeeId,
      },
      false
    );
  };

  if (userWfhListSuccess === false) {
    return <LoaderView />;
  } else {
    return (
      <>
        <div className="table_content px-2 attendance_table pb-3">
          <DataTable
            data={userWfhList}
            columns={columns}
            pagination={false}
            paginationPerPage={5}
            paginationServer={false}
          />
        </div>

        {userWfhList.length > 0 ? (
          <div className="margin_x mt-2 me-3 mb-3 d-flex justify-content-end">
            <Pagination
              rowsPerPage={rowsPerPage}
              rowCount={rowCount}
              onChangePage={onChangePage}
              currentPage={currentPage}
            />
          </div>
        ) : null}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    userWfhList: state.employeeWfhReducer.userWfhList,
    page: state.employeeWfhReducer.page,
    userWfhListSuccess: state.employeeWfhReducer.userWfhListSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getUser: getUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(All);
