import React from "react";
import { Header } from "../../common";
import MySkills from "./components/MySkills";
import { RiPencilRuler2Fill } from "react-icons/ri";

const MySkillsView = ({
  mySkillList,
  proficiencies,
  categories,
  technologies,
  getTechnologies,
  updateSkills,
  saveSkills,
  saveMappingData,
  fetchSkillCertificate,
  mySkillCertificates,
  downloadSkillCertificates,
  uploadSkillCertificates,
  deleteSkillCertificate,
  deleteSkill,
  newSkillId,
  DuplicateSkillMessage,
  ErrorMessage,
}) => {
  
  return (
    <>
      <div className="my_skills_overview">
        <Header label={"My Skills"} Icon={RiPencilRuler2Fill} />

        <div className="main__body padding">
          <MySkills
            mySkillList={mySkillList}
            proficiencies={proficiencies}
            categories={categories}
            technologies={technologies}
            getTechnologies={getTechnologies}
            updateSkills={updateSkills}
            saveSkills={saveSkills}
            saveMappingData={saveMappingData}
            fetchSkillCertificate={fetchSkillCertificate}
            mySkillCertificates={mySkillCertificates}
            downloadSkillCertificates={downloadSkillCertificates}
            uploadSkillCertificates={uploadSkillCertificates}
            deleteSkillCertificate={deleteSkillCertificate}
            deleteSkill={deleteSkill}
            newSkillId={newSkillId}
            DuplicateSkillMessage={DuplicateSkillMessage}
            ErrorMessage={ErrorMessage}
          />
        </div>
      </div>
    </>
  );
};

export default MySkillsView;
