import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";

import {
  ButtonView,
  Input,
  Select,
  Radio,
  // AutoComplete,
  TextArea,
  DatePicker,
} from "../../../controls";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";

const currentYear = new Date().getFullYear();
var passingYearOptions = [];
for (var i = 1950; i <= currentYear; i++) {
  passingYearOptions.push({ value: i, label: i });
}
passingYearOptions.reverse();

var expInYears = [];
for (var j = 0; j <= 60; j++) {
  expInYears.push({ value: j, label: j });
}

var expInMonths = [];
for (var k = 0; k <= 12; k++) {
  expInMonths.push({ value: k, label: k });
}

const data = {
  firstName: "",
  middleName: "",
  lastName: "",
  mobileNumber: "",
  whatsAppNumber: "",
  emailAddress: "",
  city: "",
  applicationDomains: [],
  secondaryTechnicalDomains: [],
  primaryTechnicalDomains: [],
  createdBy: "",
  lastUpdatedBy: "",
  designationAppliedFor: "",
  experienceInMonths: {
    expInYears: "",
    expInMonths: "",
  },
  qualification: "",
  passingYear: "",
  skills: "",
  currentEmployer: "",
  previousCTC: "",
  expectedCTC: "",
  noticePeriodEndDate: new Date(),
};

const EditJobApplication = ({
  techDomainList,
  designationList,
  aplicationDomainList,
  user,

  editJobAppData,
  showEdit,
  setShowEdit,
  setEditJobAppData,
  updateJobApplication,
}) => {
  const designationListOptions = designationList.map((list) => {
    return { value: list.name, label: list.name };
  });
  const techDomainListOptions = techDomainList.map((list) => {
    return { value: list.name, label: list.name };
  });
  const aplicationDomainListOptions = aplicationDomainList.map((list) => {
    return { value: list.name, label: list.name };
  });

  const [jobData, setJobData] = useState(data);
  const [employemntStatus, setEmployemntStatus] = useState(true);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [jobId, setJobId] = useState("");

  useEffect(() => {
    if (editJobAppData) {
      setJobData({
        firstName: editJobAppData.firstName,
        middleName: editJobAppData.middleName,
        lastName: editJobAppData.lastName,
        mobileNumber: editJobAppData.mobileNumber,
        whatsAppNumber: editJobAppData.whatsAppNumber,
        emailAddress: editJobAppData.emailAddress,
        city: editJobAppData.city,
        applicationDomains: editJobAppData.applicationDomains.map((list) => {
          return { label: list, value: list };
        }),
        secondaryTechnicalDomains: editJobAppData.secondaryTechnicalDomains.map(
          (list) => {
            return { label: list, value: list };
          }
        ),
        primaryTechnicalDomains: editJobAppData.primaryTechnicalDomains.map(
          (list) => {
            return { label: list, value: list };
          }
        ),
        designationAppliedFor: editJobAppData.designationAppliedFor,
        experienceInMonths: {
          expInYears: (editJobAppData.experienceInMonths / 12) | 0,
          expInMonths: editJobAppData.experienceInMonths % 12,
        },
        qualification: editJobAppData.qualification,
        passingYear: editJobAppData.passingYear,
        skills: editJobAppData.skills,
        currentEmployer: editJobAppData.currentEmployer
          ? editJobAppData.currentEmployer
          : "",
        previousCTC: editJobAppData.previousCTC
          ? editJobAppData.previousCTC
          : "",
        expectedCTC: editJobAppData.expectedCTC
          ? editJobAppData.expectedCTC
          : "",
        noticePeriodEndDate: editJobAppData.noticePeriodEndDate
          ? new Date(editJobAppData.noticePeriodEndDate)
          : "",
      });
      if (editJobAppData.previousCTC) {
        setEmployemntStatus(true);
      } else {
        setEmployemntStatus(false);
      }
      setJobId(editJobAppData._id);
      setShowModal(true);
      // console.log(new Date(editJobAppData.noticePeriodEndDate));
      // console.log(new Date());
    }
  }, [setJobData, editJobAppData, showEdit]);

  const handleSubmit = () => {
    var rules = {
      required: [
        "firstName",
        // "whatsAppNumber",
        "emailAddress",
        "mobileNumber",
        "lastName",
      ],
      custom: [validator.email(["emailAddress"])],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(jobData),
    });
    setErrors({
      ...errors,
    });
    if (!Object.keys(errors).length) {
      const newJobApplicationData = {
        ...jobData,
        applicationDomains: jobData.applicationDomains.map((i) => i.label),
        secondaryTechnicalDomains: jobData.secondaryTechnicalDomains.map(
          (i) => i.label
        ),
        primaryTechnicalDomains: jobData.primaryTechnicalDomains.map(
          (i) => i.label
        ),
        experienceInMonths:
          jobData.experienceInMonths.expInYears * 12 +
          jobData.experienceInMonths.expInMonths,
        lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        noticePeriodEndDate: jobData.noticePeriodEndDate
          ? moment(jobData.noticePeriodEndDate).format("YYYY-MM-DD")
          : "",
      };
      // console.log(newJobApplicationData);
      updateJobApplication(newJobApplicationData, jobId);
      handleClose();
    }
  };

  const handleClose = () => {
    setShowEdit(false);
    setErrors({});
    setEmployemntStatus(true);
    setShowModal(false);
    setEditJobAppData(null);
    setJobId("");
  };
  return (
    <>
      {editJobAppData && showModal && (
        <Modal
          show={showEdit}
          onHide={handleClose}
          aria-labelledby="filter_modal"
          size="lg"
        >
          <Modal.Header>
            <Modal.Title style={{ color: "#d87d2e" }} id="filter_modal">
              Edit Job Application
            </Modal.Title>
            <div onClick={handleClose} style={{ cursor: "pointer" }}>
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="add_job_application">
              <h5>Personal Information:</h5>
              <Row>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="firstName"
                    label="First Name"
                    type="text"
                    placeholder="First Name"
                    size="fluid"
                    value={jobData.firstName}
                    onChange={(e) =>
                      setJobData({ ...jobData, firstName: e.target.value })
                    }
                    error={errors["firstName"]}
                    isrequired={true}
                    maxLength={25}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="middleName"
                    label="Middle Name"
                    type="text"
                    placeholder="Middle Name"
                    size="fluid"
                    value={jobData.middleName}
                    onChange={(e) =>
                      setJobData({ ...jobData, middleName: e.target.value })
                    }
                    error={errors["middleName"]}
                    maxLength={25}
                    // isrequired={true}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="lastName"
                    label="Last Name"
                    type="text"
                    placeholder="Last Name"
                    size="fluid"
                    value={jobData.lastName}
                    onChange={(e) =>
                      setJobData({ ...jobData, lastName: e.target.value })
                    }
                    error={errors["lastName"]}
                    isrequired={true}
                    maxLength={25}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="emailAddress"
                    label="Email Address"
                    type="text"
                    placeholder="Email Address"
                    size="fluid"
                    value={jobData.emailAddress}
                    onChange={(e) =>
                      setJobData({ ...jobData, emailAddress: e.target.value })
                    }
                    error={errors["emailAddress"]}
                    isrequired={true}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="mobileNumber"
                    label="Mobile Number"
                    type="tel"
                    isNum={true}
                    placeholder="Mobile Number"
                    size="fluid"
                    value={jobData.mobileNumber}
                    onChange={(e) =>
                      setJobData({ ...jobData, mobileNumber: e.target.value })
                    }
                    error={errors["mobileNumber"]}
                    isrequired={true}
                    maxLength={10}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="whatsAppNumber"
                    label="Whatsapp Number"
                    type="tel"
                    isNum={true}
                    placeholder="Whatsapp Number"
                    size="fluid"
                    value={jobData.whatsAppNumber}
                    onChange={(e) =>
                      setJobData({ ...jobData, whatsAppNumber: e.target.value })
                    }
                    error={errors["whatsAppNumber"]}
                    // isrequired={true}
                    maxLength={10}
                  />
                </Col>

                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="city"
                    label="City"
                    type="text"
                    placeholder="City"
                    size="fluid"
                    value={jobData.city}
                    onChange={(e) =>
                      setJobData({ ...jobData, city: e.target.value })
                    }
                    error={errors["city"]}
                    // isrequired={true}
                    maxLength={25}
                  />
                </Col>
              </Row>
              <hr />
              <h5 className="mt-3">Educational Information:</h5>
              <Row>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="qualification"
                    label="Qualification"
                    type="text"
                    placeholder="Qualification"
                    size="fluid"
                    value={jobData.qualification}
                    onChange={(e) =>
                      setJobData({ ...jobData, qualification: e.target.value })
                    }
                    error={errors["qualification"]}
                    // isrequired={true}
                    maxLength={50}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Select
                    name="passingYear"
                    label="Passing Year"
                    isSearchable={false}
                    placeholder="Select"
                    defaultValue={passingYearOptions.find(
                      (option) => option.value === jobData.passingYear
                    )}
                    options={passingYearOptions}
                    onChange={(val) =>
                      setJobData({ ...jobData, passingYear: val.value })
                    }
                    className="select"
                    classNamePrefix="select"
                    error={errors["passingYear"]}
                    // isrequired={true}
                  />
                </Col>
              </Row>
              <hr />
              <h5 className="mt-3">Job Skills:</h5>
              <Row>
                <Col md={12} lg={6} className="mb-3">
                  <Select
                    name="designationAppliedFor"
                    label="Applied Designation"
                    isSearchable={false}
                    placeholder="Select"
                    defaultValue={designationListOptions.find(
                      (option) => option.value === jobData.designationAppliedFor
                    )}
                    options={designationListOptions}
                    onChange={(val) =>
                      setJobData({
                        ...jobData,
                        designationAppliedFor: val.value,
                      })
                    }
                    className="select"
                    classNamePrefix="select"
                    error={errors["designationAppliedFor"]}
                    // isrequired={true}
                  />
                </Col>
                <Col md={12} lg={6} className="mb-3">
                  <Select
                    name="primaryTechnicalDomains"
                    label="Primary Domain"
                    isSearchable={true}
                    placeholder="Select"
                    isMulti={true}
                    defaultValue={jobData.primaryTechnicalDomains}
                    options={techDomainListOptions}
                    onChange={(val) =>
                      setJobData({
                        ...jobData,
                        primaryTechnicalDomains: val,
                      })
                    }
                    className="select"
                    classNamePrefix="select"
                    error={errors["primaryTechnicalDomains"]}
                    // isrequired={true}
                  />
                </Col>
                <Col md={12} lg={6} className="mb-3">
                  <Select
                    name="primaryTechnicalDomains"
                    label="Secondary Domain"
                    isSearchable={true}
                    isMulti={true}
                    placeholder="Select"
                    defaultValue={jobData.secondaryTechnicalDomains}
                    options={techDomainListOptions}
                    onChange={(val) => {
                      setJobData({
                        ...jobData,
                        secondaryTechnicalDomains: val,
                      });
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    // isClearable={true}
                    error={errors["secondaryTechnicalDomains"]}
                    // isrequired={true}
                  />
                </Col>
                <Col md={12} lg={6} className="mb-3">
                  <Select
                    name="applicationDomains"
                    label="Application Domain"
                    isSearchable={true}
                    isMulti={true}
                    placeholder="Select"
                    defaultValue={jobData.applicationDomains}
                    options={aplicationDomainListOptions}
                    onChange={(val) => {
                      setJobData({
                        ...jobData,
                        applicationDomains: val,
                      });
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    error={errors["applicationDomains"]}
                    // isrequired={true}
                  />
                </Col>
                <Col md={12} lg={12} className="mb-3">
                  <TextArea
                    name="skills"
                    label="Skills"
                    type="text"
                    placeholder="Skills"
                    size="fluid"
                    value={jobData.skills}
                    onChange={(e) =>
                      setJobData({ ...jobData, skills: e.target.value })
                    }
                    error={errors["skills"]}
                    // isrequired={true}
                    maxLength={300}
                  />
                </Col>
              </Row>
              <hr />
              <h5 className="mt-3">Employment:</h5>
              <Row>
                <Col md={12} lg={4} className="mb-3">
                  <span className={`label text-muted`}>
                    Currently Employment Status
                  </span>
                  <div className="currently_employment_status d-flex mt-2">
                    <Radio
                      name={"currently_employment_status"}
                      text={"Employed"}
                      onChange={() => setEmployemntStatus(true)}
                      checked={employemntStatus}
                    />
                    <Radio
                      name={"currently_employment_status"}
                      text={"Unemployed"}
                      onChange={() => {
                        setEmployemntStatus(false);
                        setJobData({
                          ...jobData,
                          previousCTC: "",
                          currentEmployer: "",
                          noticePeriodEndDate: "",
                        });
                      }}
                      checked={!employemntStatus}
                    />
                  </div>
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="previousCTC"
                    label="Previous CTC"
                    type="tel"
                    isNum={true}
                    placeholder="Previous CTC"
                    size="fluid"
                    value={jobData.previousCTC}
                    onChange={(e) =>
                      setJobData({ ...jobData, previousCTC: e.target.value })
                    }
                    disabled={!employemntStatus}
                    error={errors["previousCTC"]}
                    // isrequired={true}
                    maxLength={10}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="expectedCTC"
                    label="Expected CTC"
                    type="tel"
                    isNum={true}
                    placeholder="Expected CTC"
                    size="fluid"
                    value={jobData.expectedCTC}
                    onChange={(e) =>
                      setJobData({ ...jobData, expectedCTC: e.target.value })
                    }
                    error={errors["expectedCTC"]}
                    // isrequired={true}
                    maxLength={10}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="currentEmployer"
                    label="Current Employment"
                    type="text"
                    placeholder="Current Employment"
                    size="fluid"
                    value={jobData.currentEmployer}
                    onChange={(e) =>
                      setJobData({
                        ...jobData,
                        currentEmployer: e.target.value,
                      })
                    }
                    disabled={!employemntStatus}
                    error={errors["currentEmployer"]}
                    // isrequired={true}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  {/* <Input
                    name="noticePeriodEndDate"
                    label="Notice Period Ends on"
                    type="date"
                    placeholder="Notice Period Ends on"
                    size="fluid"
                    value={jobData.noticePeriodEndDate}
                    onChange={(e) =>
                      setJobData({
                        ...jobData,
                        noticePeriodEndDate: e.target.value,
                      })
                    }
                    disabled={!employemntStatus}
                    error={errors["noticePeriodEndDate"]}
                  /> */}
                  <DatePicker
                    name="noticePeriodEndDate"
                    label="Notice Period Ends on"
                    value={jobData.noticePeriodEndDate}
                    onChange={(e) =>
                      setJobData({
                        ...jobData,
                        noticePeriodEndDate: e,
                      })
                    }
                    popupStyle={{
                      position: "relative",
                    }}
                    disabled={!employemntStatus}
                    // isrequired={true}
                    error={errors["noticePeriodEndDate"]}
                    // isrequired={true}
                  />
                </Col>
                <Col md={12} lg={4} className="mb-3">
                  <Row className="g-0">
                    <span className={`select__label text-muted m-0`}>
                      Experience
                      {/* <span className="text-danger">*</span> */}
                    </span>
                    <Col sm={4} xs={4} md={6}>
                      <Select
                        name="expInYears"
                        isSearchable={false}
                        placeholder="In Year"
                        defaultValue={expInYears.find(
                          (option) =>
                            option.value ===
                            jobData.experienceInMonths.expInYears
                        )}
                        options={expInYears}
                        onChange={(val) =>
                          setJobData({
                            ...jobData,
                            experienceInMonths: {
                              ...jobData.experienceInMonths,
                              expInYears: val.value,
                            },
                          })
                        }
                        className="select"
                        classNamePrefix="select"
                        error={errors["expInYears"]}
                      />
                    </Col>
                    <Col sm={4} xs={4} md={6}>
                      <Select
                        name="expInMonths"
                        isSearchable={false}
                        placeholder="In Month"
                        defaultValue={expInYears.find(
                          (option) =>
                            option.value ===
                            jobData.experienceInMonths.expInMonths
                        )}
                        options={expInMonths}
                        onChange={(val) =>
                          setJobData({
                            ...jobData,
                            experienceInMonths: {
                              ...jobData.experienceInMonths,
                              expInMonths: val.value,
                            },
                          })
                        }
                        className="select"
                        classNamePrefix="select"
                        error={errors["expInMonths"]}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="d-flex justify-content-end">
                <Col xs={6} lg={3}>
                  <ButtonView
                    onClick={() => {
                      handleClose();
                    }}
                    title={"Cancel"}
                    styles="danger-outline"
                  />
                </Col>
                <Col xs={6} lg={3}>
                  <ButtonView
                    onClick={handleSubmit}
                    title={"Save"}
                    styles="secondary"
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EditJobApplication;
// import React, { useState, useEffect } from "react";
// import { Row, Col, Modal } from "react-bootstrap";
// import { AiOutlineClose } from "react-icons/ai";
// import moment from "moment";

// import {
//   ButtonView,
//   Input,
//   Select,
//   Radio,
//   // AutoComplete,
//   TextArea,
//   DatePicker,
// } from "../../../controls";
// import * as validator from "../../../../helpers/validator";
// import { trimFieldsData } from "../../../../utils";

// const currentYear = new Date().getFullYear();
// var passingYearOptions = [];
// for (var i = 1950; i <= currentYear; i++) {
//   passingYearOptions.push({ value: i, label: i });
// }
// passingYearOptions.reverse();

// var expInYears = [];
// for (var j = 0; j <= 60; j++) {
//   expInYears.push({ value: j, label: j });
// }

// var expInMonths = [];
// for (var k = 0; k <= 12; k++) {
//   expInMonths.push({ value: k, label: k });
// }

// const data = {
//   firstName: "",
//   middleName: "",
//   lastName: "",
//   mobileNumber: "",
//   emailAddress: "",
//   city: "",
//   applicationDomains: [],
//   secondaryTechnicalDomains: [],
//   primaryTechnicalDomains: [],
//   createdBy: "",
//   lastUpdatedBy: "",
//   designationAppliedFor: "",
//   experienceInMonths: {
//     expInYears: "",
//     expInMonths: "",
//   },
//   qualification: "",
//   passingYear: "",
//   skills: "",
//   currentEmployer: "",
//   previousCTC: "",
//   expectedCTC: "",
//   noticePeriodEndDate: new Date(),
// };

// const EditJobApplication = ({
//   techDomainList,
//   designationList,
//   aplicationDomainList,
//   user,

//   editJobAppData,
//   showEdit,
//   setShowEdit,
//   setEditJobAppData,
//   updateJobApplication,
// }) => {
//   const designationListOptions = designationList.map((list) => {
//     return { value: list.name, label: list.name };
//   });
//   const techDomainListOptions = techDomainList.map((list) => {
//     return { value: list.name, label: list.name };
//   });
//   const aplicationDomainListOptions = aplicationDomainList.map((list) => {
//     return { value: list.name, label: list.name };
//   });

//   const [jobData, setJobData] = useState(data);
//   const [employemntStatus, setEmployemntStatus] = useState(true);
//   const [errors, setErrors] = useState({});
//   const [showModal, setShowModal] = useState(false);
//   const [jobId, setJobId] = useState("");
//   useEffect(() => {
//     if (editJobAppData) {
//       setJobData({
//         firstName: editJobAppData.firstName,
//         middleName: editJobAppData.middleName,
//         lastName: editJobAppData.lastName,
//         mobileNumber: editJobAppData.mobileNumber,
//         emailAddress: editJobAppData.emailAddress,
//         city: editJobAppData.city,
//         applicationDomains: editJobAppData.applicationDomains.map((list) => {
//           return { label: list, value: list };
//         }),
//         secondaryTechnicalDomains: editJobAppData.secondaryTechnicalDomains.map(
//           (list) => {
//             return { label: list, value: list };
//           }
//         ),
//         primaryTechnicalDomains: editJobAppData.primaryTechnicalDomains.map(
//           (list) => {
//             return { label: list, value: list };
//           }
//         ),
//         designationAppliedFor: editJobAppData.designationAppliedFor,
//         experienceInMonths: {
//           expInYears: (editJobAppData.experienceInMonths / 12) | 0,
//           expInMonths: editJobAppData.experienceInMonths % 12,
//         },
//         qualification: editJobAppData.qualification,
//         passingYear: editJobAppData.passingYear,
//         skills: editJobAppData.skills,
//         currentEmployer: editJobAppData.currentEmployer
//           ? editJobAppData.currentEmployer
//           : "",
//         previousCTC: editJobAppData.previousCTC
//           ? editJobAppData.previousCTC
//           : "",
//         expectedCTC: editJobAppData.expectedCTC
//           ? editJobAppData.expectedCTC
//           : "",
//         noticePeriodEndDate: editJobAppData.noticePeriodEndDate
//           ? new Date(editJobAppData.noticePeriodEndDate)
//           : "",
//       });
//       if (editJobAppData.previousCTC) {
//         setEmployemntStatus(true);
//       } else {
//         setEmployemntStatus(false);
//       }
//       setJobId(editJobAppData._id);
//       setShowModal(true);
//       // console.log(new Date(editJobAppData.noticePeriodEndDate));
//       // console.log(new Date());
//     }
//   }, [setJobData, editJobAppData, showEdit]);

//   const handleSubmit = () => {
//     var rules = {};
//     if (employemntStatus) {
//       rules = {
//         required: [
//           "firstName",
//           "middleName",
//           "lastName",
//           "mobileNumber",
//           "emailAddress",
//           "city",
//           "designationAppliedFor",
//           "experienceInMonths",
//           "qualification",
//           "passingYear",
//           "skills",
//           "currentEmployer",
//           "previousCTC",
//           "expectedCTC",
//           "noticePeriodEndDate",
//         ],
//         custom: [validator.email(["emailAddress"])],
//       };
//     } else {
//       rules = {
//         required: [
//           "firstName",
//           "middleName",
//           "lastName",
//           "mobileNumber",
//           "emailAddress",
//           "city",
//           "designationAppliedFor",
//           "experienceInMonths",
//           "qualification",
//           "passingYear",
//           "skills",
//           "expectedCTC",
//         ],
//         custom: [validator.email(["emailAddress"])],
//       };
//     }

//     var { errors } = validator.validate(rules, {
//       ...trimFieldsData(jobData),
//     });
//     if (!jobData.primaryTechnicalDomains.length) {
//       errors = {
//         ...errors,
//         primaryTechnicalDomains: "Please fill required field",
//       };
//     }
//     if (!jobData.secondaryTechnicalDomains.length) {
//       errors = {
//         ...errors,
//         secondaryTechnicalDomains: "Select atleast one domain",
//       };
//     }
//     if (!jobData.applicationDomains.length) {
//       errors = {
//         ...errors,
//         applicationDomains: "Select atleast one domain",
//       };
//     }
//     if (jobData.experienceInMonths.expInYears === "") {
//       errors = {
//         ...errors,
//         expInYears: "Please fill required field",
//       };
//     }
//     if (jobData.experienceInMonths.expInMonths === "") {
//       errors = {
//         ...errors,
//         expInMonths: "Please fill required field",
//       };
//     }
//     setErrors({
//       ...errors,
//     });
//     if (!Object.keys(errors).length) {
//       const newJobApplicationData = {
//         ...jobData,
//         applicationDomains: jobData.applicationDomains.map((i) => i.label),
//         secondaryTechnicalDomains: jobData.secondaryTechnicalDomains.map(
//           (i) => i.label
//         ),
//         primaryTechnicalDomains: jobData.primaryTechnicalDomains.map(
//           (i) => i.label
//         ),
//         experienceInMonths:
//           jobData.experienceInMonths.expInYears * 12 +
//           jobData.experienceInMonths.expInMonths,
//         lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
//         noticePeriodEndDate: moment(jobData.noticePeriodEndDate).format(
//           "YYYY-MM-DD"
//         ),
//       };
//       // console.log(jobId)
//       updateJobApplication(newJobApplicationData, jobId);
//       handleClose();
//     }
//   };

//   const handleClose = () => {
//     setShowEdit(false);
//     setErrors({});
//     setEmployemntStatus(true);
//     setShowModal(false);
//     setEditJobAppData(null);
//     setJobId("");
//   };
//   return (
//     <>
//       {editJobAppData && showModal && (
//         <Modal
//           show={showEdit}
//           onHide={handleClose}
//           aria-labelledby="filter_modal"
//           size="lg"
//         >
//           <Modal.Header>
//             <Modal.Title style={{ color: "#d87d2e" }} id="filter_modal">
//               Edit Job Application
//             </Modal.Title>
//             <div onClick={handleClose} style={{ cursor: "pointer" }}>
//               <AiOutlineClose size={20} />
//             </div>
//           </Modal.Header>
//           <Modal.Body>
//             <div className="add_job_application">
//               <h5>Personal Information:</h5>
//               <Row>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="firstName"
//                     label="First Name"
//                     type="text"
//                     placeholder="First Name"
//                     size="fluid"
//                     value={jobData.firstName}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, firstName: e.target.value })
//                     }
//                     error={errors["firstName"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="middleName"
//                     label="Middle Name"
//                     type="text"
//                     placeholder="Middle Name"
//                     size="fluid"
//                     value={jobData.middleName}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, middleName: e.target.value })
//                     }
//                     error={errors["middleName"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="lastName"
//                     label="Last Name"
//                     type="text"
//                     placeholder="Last Name"
//                     size="fluid"
//                     value={jobData.lastName}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, lastName: e.target.value })
//                     }
//                     error={errors["lastName"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="mobileNumber"
//                     label="Mobile Number"
//                     type="number"
//                     placeholder="Mobile Number"
//                     size="fluid"
//                     value={jobData.mobileNumber}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, mobileNumber: e.target.value })
//                     }
//                     error={errors["mobileNumber"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="emailAddress"
//                     label="Email Address"
//                     type="text"
//                     placeholder="Email Address"
//                     size="fluid"
//                     value={jobData.emailAddress}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, emailAddress: e.target.value })
//                     }
//                     error={errors["emailAddress"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="city"
//                     label="City"
//                     type="text"
//                     placeholder="City"
//                     size="fluid"
//                     value={jobData.city}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, city: e.target.value })
//                     }
//                     error={errors["city"]}
//                     isrequired={true}
//                   />
//                 </Col>
//               </Row>
//               <hr />
//               <h5 className="mt-3">Educational Information:</h5>
//               <Row>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="qualification"
//                     label="Qualification"
//                     type="text"
//                     placeholder="Qualification"
//                     size="fluid"
//                     value={jobData.qualification}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, qualification: e.target.value })
//                     }
//                     error={errors["qualification"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Select
//                     name="passingYear"
//                     label="Passing Year"
//                     isSearchable={false}
//                     placeholder="Select"
//                     defaultValue={passingYearOptions.find(
//                       (option) => option.value === jobData.passingYear
//                     )}
//                     options={passingYearOptions}
//                     onChange={(val) =>
//                       setJobData({ ...jobData, passingYear: val.value })
//                     }
//                     className="select"
//                     classNamePrefix="select"
//                     error={errors["passingYear"]}
//                     isrequired={true}
//                   />
//                 </Col>
//               </Row>
//               <hr />
//               <h5 className="mt-3">Job Skills:</h5>
//               <Row>
//                 <Col md={12} lg={6} className="mb-3">
//                   <Select
//                     name="designationAppliedFor"
//                     label="Applied Designation"
//                     isSearchable={false}
//                     placeholder="Select"
//                     defaultValue={designationListOptions.find(
//                       (option) => option.value === jobData.designationAppliedFor
//                     )}
//                     options={designationListOptions}
//                     onChange={(val) =>
//                       setJobData({
//                         ...jobData,
//                         designationAppliedFor: val.value,
//                       })
//                     }
//                     className="select"
//                     classNamePrefix="select"
//                     error={errors["designationAppliedFor"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={6} className="mb-3">
//                   <Select
//                     name="primaryTechnicalDomains"
//                     label="Primary Domain"
//                     isSearchable={true}
//                     placeholder="Select"
//                     isMulti={true}
//                     defaultValue={jobData.primaryTechnicalDomains}
//                     options={techDomainListOptions}
//                     onChange={(val) =>
//                       setJobData({
//                         ...jobData,
//                         primaryTechnicalDomains: val,
//                       })
//                     }
//                     className="select"
//                     classNamePrefix="select"
//                     error={errors["primaryTechnicalDomains"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={6} className="mb-3">
//                   <Select
//                     name="primaryTechnicalDomains"
//                     label="Secondary Domain"
//                     isSearchable={true}
//                     isMulti={true}
//                     placeholder="Select"
//                     defaultValue={jobData.secondaryTechnicalDomains}
//                     options={techDomainListOptions}
//                     onChange={(val) => {
//                       setJobData({
//                         ...jobData,
//                         secondaryTechnicalDomains: val,
//                       });
//                     }}
//                     className="basic-multi-select"
//                     classNamePrefix="select"
//                     // isClearable={true}
//                     error={errors["secondaryTechnicalDomains"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={6} className="mb-3">
//                   <Select
//                     name="applicationDomains"
//                     label="Application Domain"
//                     isSearchable={true}
//                     isMulti={true}
//                     placeholder="Select"
//                     defaultValue={jobData.applicationDomains}
//                     options={aplicationDomainListOptions}
//                     onChange={(val) => {
//                       setJobData({
//                         ...jobData,
//                         applicationDomains: val,
//                       });
//                     }}
//                     className="basic-multi-select"
//                     classNamePrefix="select"
//                     error={errors["applicationDomains"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={12} className="mb-3">
//                   <TextArea
//                     name="skills"
//                     label="Skills"
//                     type="text"
//                     placeholder="Skills"
//                     size="fluid"
//                     value={jobData.skills}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, skills: e.target.value })
//                     }
//                     error={errors["skills"]}
//                     isrequired={true}
//                   />
//                 </Col>
//               </Row>
//               <hr />
//               <h5 className="mt-3">Employment:</h5>
//               <Row>
//                 <Col md={12} lg={4} className="mb-3">
//                   <span className={`label text-muted`}>
//                     Currently Employment Status
//                   </span>
//                   <div className="currently_employment_status d-flex mt-2">
//                     <Radio
//                       name={"currently_employment_status"}
//                       text={"Employed"}
//                       onChange={() => setEmployemntStatus(true)}
//                       checked={employemntStatus}
//                     />
//                     <Radio
//                       name={"currently_employment_status"}
//                       text={"Unemployed"}
//                       onChange={() => {
//                         setEmployemntStatus(false);
//                         setJobData({
//                           ...jobData,
//                           previousCTC: "",
//                           currentEmployer: "",
//                           noticePeriodEndDate: "",
//                         });
//                       }}
//                       checked={!employemntStatus}
//                     />
//                   </div>
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="previousCTC"
//                     label="Previous CTC"
//                     type="number"
//                     placeholder="Previous CTC"
//                     size="fluid"
//                     value={jobData.previousCTC}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, previousCTC: e.target.value })
//                     }
//                     disabled={!employemntStatus}
//                     error={errors["previousCTC"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="expectedCTC"
//                     label="Expected CTC"
//                     type="number"
//                     placeholder="Expected CTC"
//                     size="fluid"
//                     value={jobData.expectedCTC}
//                     onChange={(e) =>
//                       setJobData({ ...jobData, expectedCTC: e.target.value })
//                     }
//                     error={errors["expectedCTC"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Input
//                     name="currentEmployer"
//                     label="Current Employment"
//                     type="text"
//                     placeholder="Current Employment"
//                     size="fluid"
//                     value={jobData.currentEmployer}
//                     onChange={(e) =>
//                       setJobData({
//                         ...jobData,
//                         currentEmployer: e.target.value,
//                       })
//                     }
//                     disabled={!employemntStatus}
//                     error={errors["currentEmployer"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   {/* <Input
//                     name="noticePeriodEndDate"
//                     label="Notice Period Ends on"
//                     type="date"
//                     placeholder="Notice Period Ends on"
//                     size="fluid"
//                     value={jobData.noticePeriodEndDate}
//                     onChange={(e) =>
//                       setJobData({
//                         ...jobData,
//                         noticePeriodEndDate: e.target.value,
//                       })
//                     }
//                     disabled={!employemntStatus}
//                     error={errors["noticePeriodEndDate"]}
//                   /> */}
//                   <DatePicker
//                     name="noticePeriodEndDate"
//                     label="Notice Period Ends on"
//                     value={jobData.noticePeriodEndDate}
//                     onChange={(e) =>
//                       setJobData({
//                         ...jobData,
//                         noticePeriodEndDate: e,
//                       })
//                     }
//                     popupStyle={{
//                       position: "relative",
//                     }}
//                     disabled={!employemntStatus}
//                     // isrequired={true}
//                     error={errors["noticePeriodEndDate"]}
//                     isrequired={true}
//                   />
//                 </Col>
//                 <Col md={12} lg={4} className="mb-3">
//                   <Row className="g-0">
//                     <span className={`select__label text-muted m-0`}>
//                       Experience <span className="text-danger">*</span>
//                     </span>
//                     <Col sm={4} xs={4} md={6}>
//                       <Select
//                         name="expInYears"
//                         isSearchable={false}
//                         placeholder="In Year"
//                         defaultValue={expInYears.find(
//                           (option) =>
//                             option.value ===
//                             jobData.experienceInMonths.expInYears
//                         )}
//                         options={expInYears}
//                         onChange={(val) =>
//                           setJobData({
//                             ...jobData,
//                             experienceInMonths: {
//                               ...jobData.experienceInMonths,
//                               expInYears: val.value,
//                             },
//                           })
//                         }
//                         className="select"
//                         classNamePrefix="select"
//                         error={errors["expInYears"]}
//                       />
//                     </Col>
//                     <Col sm={4} xs={4} md={6}>
//                       <Select
//                         name="expInMonths"
//                         isSearchable={false}
//                         placeholder="In Month"
//                         defaultValue={expInYears.find(
//                           (option) =>
//                             option.value ===
//                             jobData.experienceInMonths.expInMonths
//                         )}
//                         options={expInMonths}
//                         onChange={(val) =>
//                           setJobData({
//                             ...jobData,
//                             experienceInMonths: {
//                               ...jobData.experienceInMonths,
//                               expInMonths: val.value,
//                             },
//                           })
//                         }
//                         className="select"
//                         classNamePrefix="select"
//                         error={errors["expInMonths"]}
//                       />
//                     </Col>
//                   </Row>
//                 </Col>
//               </Row>

//               <Row className="d-flex justify-content-end">
//                 <Col xs={6} lg={3}>
//                   <ButtonView
//                     onClick={handleSubmit}
//                     title={"Save"}
//                     styles="secondary"
//                   />
//                 </Col>
//                 <Col xs={6} lg={3}>
//                   <ButtonView
//                     onClick={() => {
//                       handleClose();
//                     }}
//                     title={"Cancel"}
//                     styles="danger-outline"
//                   />
//                 </Col>
//               </Row>
//             </div>
//           </Modal.Body>
//         </Modal>
//       )}
//     </>
//   );
// };

// export default EditJobApplication;
