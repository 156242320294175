import React from "react";

import { MdDescription, MdLocalDining } from "react-icons/md";
import { FaFilter } from "react-icons/fa";

import { Header } from "../../common";
import LunchManagementCard from "./components/LunchManagementCard";
import TiffinCount from "./components/TiffinCount";
import Filter from "./components/Filter";

const LunchManagementView = ({
  lunchList,
  onlunchPreference,
  allLunchTypeList,
  lunchCountData,

  tiffinShow,
  filterShow,
  handleClose,
  handleOpenTiffin,
  handleOpenFilter,

  locationList,

  location,
  lunchType,
  handleSelectChange,
  onAddFilter,
  onResetFilter,

  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,

  showPagination,
  deleteLunchPreference,
  isFilterActive,
}) => {
  const headerBtnData = [
    {
      onClick: handleOpenTiffin,
      icon: MdDescription,
      tooltip: "Tiffin Count",
    },
    {
      onClick: handleOpenFilter,
      icon: FaFilter,
      tooltip: "Filter",
    },
  ];

  return (
    <>
      <Header
        label={"Lunch Management"}
        Icon={MdLocalDining}
        btnData={headerBtnData}
        isFilterActive={isFilterActive}
        handleClearFilter={onResetFilter}
      />

      {lunchList.length > 0 ? (
        <LunchManagementCard
          lunchList={lunchList}
          onlunchPreference={onlunchPreference}
          allLunchTypeList={allLunchTypeList}
          rowsPerPage={rowsPerPage}
          rowCount={rowCount}
          onChangePage={onChangePage}
          currentPage={currentPage}
          showPagination={showPagination}
          deleteLunchPreference={deleteLunchPreference}
        />
      ) : (
        <div className="text-center padding mt-3">
          <h4>No data to display</h4>
        </div>
      )}

      <TiffinCount
        tiffinShow={tiffinShow}
        handleClose={handleClose}
        lunchCountData={lunchCountData}
      />

      <Filter
        filterShow={filterShow}
        handleClose={handleClose}
        locationList={locationList}
        allLunchTypeList={allLunchTypeList}
        location={location}
        lunchType={lunchType}
        handleSelectChange={handleSelectChange}
        onAddFilter={onAddFilter}
        onResetFilter={onResetFilter}
      />
    </>
  );
};

export default LunchManagementView;
