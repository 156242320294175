import { actionTypes } from "./employeeLeaveActions";

const {
  EMPLOYEE_LEAVE_LIST_PENDING,
  EMPLOYEE_LEAVE_LIST_SUCCESS,
  EMPLOYEE_LEAVE_LIST_ERROR,

  MY_TEAM_EMPLOYEE_LEAVE_LIST_PENDING,
  MY_TEAM_EMPLOYEE_LEAVE_LIST_SUCCESS,
  MY_TEAM_EMPLOYEE_LEAVE_LIST_ERROR,

  LEAVE_MASTER_TYPE_LIST_PENDING,
  LEAVE_MASTER_TYPE_LIST_SUCCESS,
  LEAVE_MASTER_TYPE_LIST_ERROR,

  EMPLOYEE_SUMMARY_PENDING,
  EMPLOYEE_SUMMARY_SUCCESS,
  EMPLOYEE_SUMMARY_ERROR,

  OTHER_SUMMARY_PENDING,
  OTHER_SUMMARY_SUCCESS,
  OTHER_SUMMARY_ERROR,

  APPLY_LEAVE_PENDING,
  APPLY_LEAVE_SUCCESS,
  APPLY_LEAVE_ERROR,

  EMPLOYEE_LEAVE_BY_ID_SUCCESS,
  EMPLOYEE_LEAVE_BY_ID_ERROR,

  TOTAL_EMPLOYEE_LEAVE_LIST_PENDING,
  TOTAL_EMPLOYEE_LEAVE_LIST_SUCCESS,
  TOTAL_EMPLOYEE_LEAVE_LIST_ERROR,

  CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_SUCCESS,
  CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_ERROR,

  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING,
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS,
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR,

  UPDATE_LEAVE_DETAIL_PENDING,
  UPDATE_LEAVE_DETAIL_SUCCESS,
  UPDATE_LEAVE_DETAIL_ERROR,
} = actionTypes;

const initialState = {
  employeeLeaveListPending: false,
  employeeLeaveListSuccess: false,
  employeeLeaveListError: null,

  totalEmployeeLeaveListPending: false,
  totalEmployeeLeaveListSuccess: false,
  totalEmployeeLeaveListError: null,

  myTeamEmployeeLeaveListPending: false,
  myTeamEmployeeLeaveListSuccess: false,
  myTeamEmployeeLeaveListError: null,

  leaveMasterTypeListPending: false,
  leaveMasterTypeListSuccess: false,
  leaveMasterTypeListError: null,

  applyForLeavePending: false,
  applyForLeaveSuccess: false,
  applyForLeaveError: null,

  employeeLeavesSummaryPending: false,
  employeeLeavesSummarySuccess: false,
  employeeLeavesSummaryError: null,

  otherLeavesSummaryPending: false,
  otherLeavesSummarySuccess: false,
  otherLeavesSummaryError: null,

  optionalHolidaySummaryPending: false,
  optionalHolidaySummarySuccess: false,
  optionalHolidaySummaryError: null,

  employeeLeaveList: [],
  myTeamEmployeeLeaveList: [],
  leaveMasterTypeList: [],
  otherLeavesSummary: null,
  optionalHolidaySummary: null,
  employeeLeavesSummary: null,

  showNextYearLeave: false,

  showLeaveById: false,
  leaveByIdData: null,
  currentYear: new Date().getFullYear(),

  totalEmployeeLeaveList: [],

  canApply: null,

  updateLeaveDetailPending: false,
  updateLeaveDetailSuccess: false,
  updateLeaveDetailError: null,
};

export default function employeeLeave(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEE_LEAVE_LIST_PENDING:
    case EMPLOYEE_LEAVE_LIST_ERROR:
    case EMPLOYEE_LEAVE_LIST_SUCCESS:
      return Object.assign({}, state, payload);
    case MY_TEAM_EMPLOYEE_LEAVE_LIST_PENDING:
    case MY_TEAM_EMPLOYEE_LEAVE_LIST_ERROR:
    case MY_TEAM_EMPLOYEE_LEAVE_LIST_SUCCESS:
      return Object.assign({}, state, payload);
    case LEAVE_MASTER_TYPE_LIST_PENDING:
    case LEAVE_MASTER_TYPE_LIST_ERROR:
    case LEAVE_MASTER_TYPE_LIST_SUCCESS:
      return Object.assign({}, state, payload);
    case EMPLOYEE_SUMMARY_PENDING:
    case EMPLOYEE_SUMMARY_ERROR:
    case EMPLOYEE_SUMMARY_SUCCESS:
      return Object.assign({}, state, payload);
    case OTHER_SUMMARY_PENDING:
    case OTHER_SUMMARY_ERROR:
    case OTHER_SUMMARY_SUCCESS:
      return Object.assign({}, state, payload);
    case APPLY_LEAVE_PENDING:
    case APPLY_LEAVE_ERROR:
    case APPLY_LEAVE_SUCCESS:
      return Object.assign({}, state, payload);
    case TOTAL_EMPLOYEE_LEAVE_LIST_PENDING:
    case TOTAL_EMPLOYEE_LEAVE_LIST_SUCCESS:
    case TOTAL_EMPLOYEE_LEAVE_LIST_ERROR:
      return Object.assign({}, state, payload);
    case OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING:
    case OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS:
    case OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR:
      return Object.assign({}, state, payload);
    case EMPLOYEE_LEAVE_BY_ID_SUCCESS:
    case EMPLOYEE_LEAVE_BY_ID_ERROR:
      return Object.assign({}, state, payload);
    case CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_SUCCESS:
    case CAN_APPLY_OPTIONAL_HOLIDAY_LEAVE_ERROR:
      return Object.assign({}, state, payload);
    case UPDATE_LEAVE_DETAIL_PENDING:
    case UPDATE_LEAVE_DETAIL_ERROR:
    case UPDATE_LEAVE_DETAIL_SUCCESS:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
