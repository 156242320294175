import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  LUNCH_BLOCKING_TIME_PENDING: "LUNCH_BLOCKING_TIME_PENDING",
  LUNCH_BLOCKING_TIME_ERROR: "LUNCH_BLOCKING_TIME_ERROR",
  LUNCH_BLOCKING_TIME_SUCCESS: "LUNCH_BLOCKING_TIME_SUCCESS",

  LUNCH_BLOCKING_TIME_ADD_PENDING: "LUNCH_BLOCKING_TIME_ADD_PENDING",
  LUNCH_BLOCKING_TIME_ADD_ERROR: "LUNCH_BLOCKING_TIME_ADD_ERROR",
  LUNCH_BLOCKING_TIME_ADD_SUCCESS: "LUNCH_BLOCKING_TIME_ADD_SUCCESS",

  LUNCH_BLOCKING_TIME_EDIT_PENDING: "LUNCH_BLOCKING_TIME_EDIT_PENDING",
  LUNCH_BLOCKING_TIME_EDIT_ERROR: "LUNCH_BLOCKING_TIME_EDIT_ERROR",
  LUNCH_BLOCKING_TIME_EDIT_SUCCESS: "LUNCH_BLOCKING_TIME_EDIT_SUCCESS",
};

export function addLunchBlockingTime(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCH_BLOCKING_TIME_ADD_PENDING,
      payload: {
        lunchBlockingTimeAddPending: true,
        lunchBlockingTimeAddSuccess: false,
        lunchBlockingTimeAddError: null,
      },
    });

    Api.post("lunchPreferenceSettings/add", data)
      .then((response) => {
        // console.log(response);
        if (
          response.message === "SuccessFully added lunch preference settings"
        ) {
          dispatch({
            type: actionTypes.LUNCH_BLOCKING_TIME_ADD_SUCCESS,
            payload: {
              lunchBlockingTimeAddPending: false,
              lunchBlockingTimeAddSuccess: true,
              lunchBlockingTimeAddError: null,

              lunchBlockingTime: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new preference setting.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCH_BLOCKING_TIME_ADD_ERROR,
          payload: {
            lunchBlockingTimeAddPending: false,
            lunchBlockingTimeAddSuccess: true,
            lunchBlockingTimeAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add preference setting.",
          })
        );
      });
  };
}

export function editLunchBlockingTime(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCH_BLOCKING_TIME_EDIT_PENDING,
      payload: {
        lunchBlockingTimeEditPending: true,
        lunchBlockingTimeEditSuccess: false,
        lunchBlockingTimeEditError: null,
      },
    });
    // console.log(data, id);

    Api.put(`lunchPreferenceSettings/update/${id}`, data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.LUNCH_BLOCKING_TIME_EDIT_SUCCESS,
            payload: {
              lunchBlockingTimeEditPending: false,
              lunchBlockingTimeEditSuccess: true,
              lunchBlockingTimeEditError: null,

              lunchBlockingTime: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated preference setting.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.LUNCH_BLOCKING_TIME_EDIT_ERROR,
          payload: {
            lunchBlockingTimeEditPending: false,
            lunchBlockingTimeEditSuccess: true,
            lunchBlockingTimeEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit preference setting.",
          })
        );
      });
  };
}

export function list() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCH_BLOCKING_TIME_PENDING,
      payload: {
        lunchBlockingTimeListPending: true,
        lunchBlockingTimeListSuccess: false,
        lunchBlockingTimeListError: null,
      },
    });

    Api.get("lunchPreferenceSettings/list")
      .then((response) => {
        // console.log(response);
        if (
          response.message === "Successfully fetched lunch preference settings"
        ) {
          dispatch({
            type: actionTypes.LUNCH_BLOCKING_TIME_SUCCESS,
            payload: {
              lunchBlockingTimeListPending: false,
              lunchBlockingTimeListSuccess: true,
              lunchBlockingTimeListError: null,

              lunchBlockingTimeList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.LUNCH_BLOCKING_TIME_SUCCESS,
          payload: {
            lunchBlockingTimeListPending: false,
            lunchBlockingTimeListSuccess: true,
            lunchBlockingTimeListError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCH_BLOCKING_TIME_ERROR,
          payload: {
            lunchBlockingTimeListPending: false,
            lunchBlockingTimeListSuccess: true,
            lunchBlockingTimeListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get lunch Blocking Time list.",
          })
        );
      });
  };
}
