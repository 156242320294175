import React, { useState } from "react";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";

import {
  ButtonView,
  Input,
  Select,
  Radio,
  // AutoComplete,
  TextArea,
  DatePicker,
} from "../../../controls";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import AddTechDomain from "./AddTechDomain";

const currentYear = new Date().getFullYear();
var passingYearOptions = [];
for (var i = 1950; i <= currentYear; i++) {
  passingYearOptions.push({ value: i, label: i });
}
passingYearOptions.reverse();

var expInYears = [];
for (var j = 0; j <= 60; j++) {
  expInYears.push({ value: j, label: j });
}

var expInMonths = [];
for (var k = 0; k <= 11; k++) {
  expInMonths.push({ value: k, label: k });
}

const data = {
  firstName: "",
  middleName: "",
  lastName: "",
  mobileNumber: "",
  whatsAppNumber: "",
  emailAddress: "",
  city: "",
  applicationDomains: [],
  secondaryTechnicalDomains: [],
  primaryTechnicalDomains: [],
  createdBy: "",
  lastUpdatedBy: "",
  designationAppliedFor: "",
  experienceInMonths: {
    expInYears: "",
    expInMonths: "",
  },
  qualification: "",
  passingYear: "",
  skills: "",
  currentEmployer: "",
  previousCTC: "",
  expectedCTC: "",
  noticePeriodEndDate: "",
  referredByName: "",
  referredById: "",
};

const AddJobApplication = ({
  techDomainList,
  designationList,
  aplicationDomainList,
  user,
  createJobApplication,
  userList,
  addTechDomain,
}) => {
  const designationListOptions = designationList.map((list) => {
    return { value: list.name, label: list.name };
  });
  const techDomainListOptions = techDomainList.map((list) => {
    return { value: list.name, label: list.name };
  });
  const aplicationDomainListOptions = aplicationDomainList.map((list) => {
    return { value: list.name, label: list.name };
  });
  const userListOptions = userList.map((list) => {
    return {
      value: {
        name: `${list.firstName} ${list.lastName}`,
        id: list._id,
      },
      label: `${list.firstName} ${list.lastName}`,
    };
  });

  const [jobData, setJobData] = useState(data);
  const [addJobApplication, setAddJobApplication] = useState(false);
  const [employemntStatus, setEmployemntStatus] = useState(true);
  const [errors, setErrors] = useState({});
  const [showReferenceInput, setShowReferenceInput] = useState(false);
  const [referenceType, setReferenceType] = useState("");
  const [isExperienced, setIsExperience] = useState(true);
  // const [numberCount, setNumberCount] = useState("");

  const [addTechDomainShow, setAddTechDomainShow] = useState(false);

  const [file, setFile] = useState(null);
  const onLoadFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  // console.log("numberCount", numberCount);
  // useEffect(() => {
  //   if (numberCount > 10) {

  //     alert("");
  //   }
  // }, [numberCount]);
  const handleSubmit = () => {
    var rules = { required: [] };
    if (isExperienced) {
      if (employemntStatus) {
        rules = {
          required: [
            "firstName",
            // "middleName",
            // "whatsAppNumber",
            "lastName",
            "mobileNumber",
            "emailAddress",
            "city",
            "designationAppliedFor",
            "experienceInMonths",
            "qualification",
            "passingYear",
            "skills",
            "currentEmployer",
            "previousCTC",
            "expectedCTC",
            "noticePeriodEndDate",
            "referredByName",
          ],
          custom: [validator.email(["emailAddress"])],
        };
      } else {
        rules = {
          required: [
            "firstName",
            // "middleName",
            // "whatsAppNumber",
            "lastName",
            "mobileNumber",
            "emailAddress",
            "city",
            "designationAppliedFor",
            "experienceInMonths",
            "qualification",
            "passingYear",
            "skills",
            "expectedCTC",
            "referredByName",
          ],
          custom: [validator.email(["emailAddress"])],
        };
      }
    } else {
      rules = {
        required: [
          "firstName",
          // "whatsAppNumber",
          "emailAddress",
          "mobileNumber",
          "lastName",
        ],
        custom: [validator.email(["emailAddress"])],
      };
    }

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(jobData),
    });
    if (isExperienced) {
      if (!jobData.primaryTechnicalDomains.length) {
        errors = {
          ...errors,
          primaryTechnicalDomains: "Please fill required field",
        };
      }
      if (!jobData.secondaryTechnicalDomains.length) {
        errors = {
          ...errors,
          secondaryTechnicalDomains: "Select atleast one domain",
        };
      }
      if (!jobData.applicationDomains.length) {
        errors = {
          ...errors,
          applicationDomains: "Select atleast one domain",
        };
      }

      if (jobData.experienceInMonths.expInYears === "") {
        errors = {
          ...errors,
          expInYears: "Please fill required field",
        };
      }
      if (jobData.experienceInMonths.expInMonths === "") {
        errors = {
          ...errors,
          expInMonths: "Please fill required field",
        };
      }
    }

    if (!file) {
      errors = {
        ...errors,
        file: "Please select a file",
      };
    }

    if (!referenceType) {
      errors = {
        ...errors,
        referenceType: "Please select referred by type",
      };
    }
    setErrors({
      ...errors,
    });
    if (!Object.keys(errors).length) {
      const newJobApplicationData = {
        ...jobData,
        applicationDomains: jobData.applicationDomains.map((i) => i.label),
        secondaryTechnicalDomains: jobData.secondaryTechnicalDomains.map(
          (i) => i.label
        ),
        primaryTechnicalDomains: jobData.primaryTechnicalDomains.map(
          (i) => i.label
        ),
        experienceInMonths:
          jobData.experienceInMonths.expInYears * 12 +
          jobData.experienceInMonths.expInMonths,
        createdBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        referredByDetails: referenceType,
        noticePeriodEndDate: jobData.noticePeriodEndDate
          ? moment(jobData.noticePeriodEndDate).format("YYYY-MM-DD")
          : "",
      };
      createJobApplication(newJobApplicationData, file);
      // console.log(newJobApplicationData);
      handleClose();
    }
    // console.log(jobData);
  };

  const handleClose = () => {
    setAddJobApplication(false);
    setJobData(data);
    setErrors({});
    setEmployemntStatus(true);
    setFile(null);
    setShowReferenceInput(false);
    setReferenceType("");
    setIsExperience(true);
    setAddTechDomainShow(false);
  };
  return (
    <>
      <Card.Title>
        <div className="job_app_title">Job Application(s)</div>
        <ButtonView
          onClick={() => setAddJobApplication(true)}
          title={"Add Application"}
          styles="secondary"
        />
      </Card.Title>

      <Modal
        show={addJobApplication}
        onHide={handleClose}
        aria-labelledby="filter_modal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title style={{ color: "#d87d2e" }} id="filter_modal">
            Job Application
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body className="add_job_app_modal">
          {addTechDomainShow && (
            <AddTechDomain
              addTechDomainShow={addTechDomainShow}
              setAddTechDomainShow={setAddTechDomainShow}
              addTechDomain={addTechDomain}
            />
          )}

          <div className="add_job_application">
            <h5>Candidate Type:</h5>
            <Row>
              <Col md={12} lg={4} className="mb-3">
                <div className="currently_employment_status d-flex mt-2">
                  <Radio
                    name={"candidate_type"}
                    text={"Experienced"}
                    onChange={() => {
                      setIsExperience(true);
                      setJobData(data);
                      setErrors({});
                    }}
                    checked={isExperienced}
                  />
                  <Radio
                    name={"candidate_type"}
                    text={"Fresher"}
                    onChange={() => {
                      setIsExperience(false);
                      setJobData(data);
                      setErrors({});
                    }}
                    checked={!isExperienced}
                  />
                </div>
              </Col>
            </Row>
            <hr />
            <h5 className="mt-3">Personal Information:</h5>
            <Row>
              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="firstName"
                  label="First Name"
                  type="text"
                  placeholder="First Name"
                  size="fluid"
                  value={jobData.firstName}
                  onChange={(e) =>
                    setJobData({ ...jobData, firstName: e.target.value })
                  }
                  error={errors["firstName"]}
                  isrequired={true}
                />
              </Col>
              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="middleName"
                  label="Middle Name"
                  type="text"
                  placeholder="Middle Name"
                  size="fluid"
                  value={jobData.middleName}
                  onChange={(e) =>
                    setJobData({ ...jobData, middleName: e.target.value })
                  }
                  error={errors["middleName"]}
                />
              </Col>
              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="lastName"
                  label="Last Name"
                  type="text"
                  placeholder="Last Name"
                  size="fluid"
                  value={jobData.lastName}
                  onChange={(e) =>
                    setJobData({ ...jobData, lastName: e.target.value })
                  }
                  error={errors["lastName"]}
                  isrequired={true}
                />
              </Col>
              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="emailAddress"
                  label="Email Address"
                  type="text"
                  placeholder="Email Address"
                  size="fluid"
                  value={jobData.emailAddress}
                  onChange={(e) =>
                    setJobData({ ...jobData, emailAddress: e.target.value })
                  }
                  error={errors["emailAddress"]}
                  isrequired={true}
                />
              </Col>
              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="mobileNumber"
                  label="Mobile Number"
                  type="tel"
                  isNum={true}
                  placeholder="Mobile Number"
                  size="fluid"
                  value={jobData.mobileNumber}
                  onChange={(e) => {
                    setJobData({ ...jobData, mobileNumber: e.target.value });
                    // setNumberCount(e.target.value.length);
                  }}
                  error={errors["mobileNumber"]}
                  isrequired={true}
                  maxLength="10"
                />
              </Col>
              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="whatsAppNumber"
                  label="Whatsapp Number"
                  type="tel"
                  isNum={true}
                  placeholder="Whatsapp Number"
                  size="fluid"
                  value={jobData.whatsAppNumber}
                  onChange={(e) =>
                    setJobData({ ...jobData, whatsAppNumber: e.target.value })
                  }
                  error={errors["whatsAppNumber"]}
                  // isrequired={true}
                  maxLength="10"
                />
              </Col>

              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="city"
                  label="City"
                  type="text"
                  placeholder="City"
                  size="fluid"
                  value={jobData.city}
                  onChange={(e) =>
                    setJobData({ ...jobData, city: e.target.value })
                  }
                  error={errors["city"]}
                  isrequired={isExperienced}
                />
              </Col>
            </Row>
            <hr />
            <h5 className="mt-3">Educational Information:</h5>
            <Row>
              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="qualification"
                  label="Qualification"
                  type="text"
                  placeholder="Qualification"
                  size="fluid"
                  value={jobData.qualification}
                  onChange={(e) =>
                    setJobData({ ...jobData, qualification: e.target.value })
                  }
                  error={errors["qualification"]}
                  isrequired={isExperienced}
                />
              </Col>
              <Col md={12} lg={4} className="mb-3">
                <Select
                  name="passingYear"
                  label="Passing Year"
                  isSearchable={false}
                  placeholder="Select"
                  options={passingYearOptions}
                  onChange={(val) =>
                    setJobData({ ...jobData, passingYear: val.value })
                  }
                  className="select"
                  classNamePrefix="select"
                  error={errors["passingYear"]}
                  isrequired={isExperienced}
                />
              </Col>
            </Row>
            <hr />
            <h5 className="mt-3">Job Skills:</h5>
            <Row>
              <Col md={12} lg={6} className="mb-3">
                <Select
                  name="designationAppliedFor"
                  label="Applied Designation"
                  isSearchable={false}
                  placeholder="Select"
                  options={designationListOptions}
                  onChange={(val) =>
                    setJobData({ ...jobData, designationAppliedFor: val.value })
                  }
                  className="select"
                  classNamePrefix="select"
                  error={errors["designationAppliedFor"]}
                  isrequired={isExperienced}
                />
              </Col>
              <Col md={12} lg={6} className="mb-3 d-flex align-items-center">
                <Select
                  name="primaryTechnicalDomains"
                  label="Primary Domain"
                  isSearchable={false}
                  placeholder="Select"
                  isMulti={true}
                  options={techDomainListOptions}
                  onChange={(val) =>
                    setJobData({
                      ...jobData,
                      primaryTechnicalDomains: val,
                    })
                  }
                  className="select"
                  classNamePrefix="select"
                  error={errors["primaryTechnicalDomains"]}
                  isrequired={isExperienced}
                />
                <div style={{ marginTop: "18px" }} className="ms-3">
                  <ButtonView
                    onClick={() => {
                      setAddTechDomainShow(true);
                    }}
                    title={"+"}
                    styles="secondary"
                    tooltip="Add new Technical Domain"
                  />
                </div>
              </Col>
              <Col md={12} lg={6} className="mb-3">
                <Select
                  name="primaryTechnicalDomains"
                  label="Secondary Domain"
                  isSearchable={false}
                  isMulti={true}
                  placeholder="Select"
                  options={techDomainListOptions}
                  onChange={(val) => {
                    setJobData({
                      ...jobData,
                      secondaryTechnicalDomains: val,
                    });
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  // isClearable={true}
                  error={errors["secondaryTechnicalDomains"]}
                  isrequired={isExperienced}
                />
              </Col>
              <Col md={12} lg={6} className="mb-3">
                <Select
                  name="applicationDomains"
                  label="Application Domain"
                  isSearchable={true}
                  isMulti={true}
                  placeholder="Select"
                  options={aplicationDomainListOptions}
                  onChange={(val) => {
                    setJobData({
                      ...jobData,
                      applicationDomains: val,
                    });
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  error={errors["applicationDomains"]}
                  isrequired={isExperienced}
                />
              </Col>
              <Col md={12} lg={12} className="mb-3">
                <TextArea
                  name="skills"
                  label="Skills"
                  type="text"
                  placeholder="Skills"
                  size="fluid"
                  value={jobData.skills}
                  onChange={(e) =>
                    setJobData({ ...jobData, skills: e.target.value })
                  }
                  error={errors["skills"]}
                  isrequired={isExperienced}
                />
              </Col>
            </Row>
            {isExperienced && (
              <>
                <hr />
                <h5 className="mt-3">Employment:</h5>
              </>
            )}

            <Row>
              {isExperienced && (
                <>
                  <Col md={12} lg={4} className="mb-3">
                    <span className={`label text-muted`}>
                      Currently Employment Status
                    </span>
                    <div className="currently_employment_status d-flex mt-2">
                      <Radio
                        name={"currently_employment_status"}
                        text={"Employed"}
                        onChange={() => setEmployemntStatus(true)}
                        checked={employemntStatus}
                      />
                      <Radio
                        name={"currently_employment_status"}
                        text={"Unemployed"}
                        onChange={() => {
                          var errorList = errors;
                          delete errorList.noticePeriodEndDate;
                          setEmployemntStatus(false);
                          setJobData({
                            ...jobData,
                            previousCTC: "",
                            currentEmployer: "",
                            noticePeriodEndDate: "",
                          });
                          setErrors(errorList);
                        }}
                        checked={!employemntStatus}
                      />
                    </div>
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <Input
                      name="previousCTC"
                      label="Previous CTC"
                      type="tel"
                      isNum={true}
                      placeholder="Previous CTC"
                      size="fluid"
                      value={jobData.previousCTC}
                      onChange={(e) =>
                        setJobData({ ...jobData, previousCTC: e.target.value })
                      }
                      disabled={!employemntStatus}
                      error={errors["previousCTC"]}
                      isrequired={isExperienced}
                    />
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <Input
                      name="expectedCTC"
                      label="Expected CTC"
                      type="tel"
                      isNum={true}
                      placeholder="Expected CTC"
                      size="fluid"
                      value={jobData.expectedCTC}
                      onChange={(e) =>
                        setJobData({ ...jobData, expectedCTC: e.target.value })
                      }
                      error={errors["expectedCTC"]}
                      isrequired={isExperienced}
                    />
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <Input
                      name="currentEmployer"
                      label="Current Employment"
                      type="text"
                      placeholder="Current Employment"
                      size="fluid"
                      value={jobData.currentEmployer}
                      onChange={(e) =>
                        setJobData({
                          ...jobData,
                          currentEmployer: e.target.value,
                        })
                      }
                      disabled={!employemntStatus}
                      isrequired={isExperienced}
                      error={errors["currentEmployer"]}
                    />
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <DatePicker
                      name="noticePeriodEndDate"
                      label="Notice Period Ends on"
                      value={jobData.noticePeriodEndDate}
                      onChange={(e) =>
                        setJobData({
                          ...jobData,
                          noticePeriodEndDate: e,
                        })
                      }
                      disabled={!employemntStatus}
                      isrequired={isExperienced}
                      error={errors["noticePeriodEndDate"]}
                    />
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <Row className="g-0">
                      <span className={`select__label text-muted m-0 ms-1`}>
                        Experience{" "}
                        {isExperienced && (
                          <span className="text-danger">*</span>
                        )}
                      </span>
                      <Col sm={4} xs={4} md={6}>
                        <Select
                          name="expInYears"
                          isSearchable={false}
                          placeholder="In Year"
                          options={expInYears}
                          onChange={(val) =>
                            setJobData({
                              ...jobData,
                              experienceInMonths: {
                                ...jobData.experienceInMonths,
                                expInYears: val.value,
                              },
                            })
                          }
                          className="select"
                          classNamePrefix="select"
                          error={errors["expInYears"]}
                        />
                      </Col>
                      <Col sm={4} xs={4} md={6}>
                        <Select
                          name="expInMonths"
                          isSearchable={false}
                          placeholder="In Month"
                          options={expInMonths}
                          onChange={(val) =>
                            setJobData({
                              ...jobData,
                              experienceInMonths: {
                                ...jobData.experienceInMonths,
                                expInMonths: val.value,
                              },
                            })
                          }
                          className="select"
                          classNamePrefix="select"
                          error={errors["expInMonths"]}
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              <Col md={12} lg={4} className="mb-3">
                <Input
                  name="file"
                  label="Resume"
                  type="file"
                  placeholder="Resume"
                  onChange={onLoadFile}
                  size="fluid"
                  accept=".pdf,.png,.jpg,.doc,.docx"
                  error={errors["file"]}
                  isrequired={true}
                />
              </Col>
            </Row>
            <hr />
            <h5 className="mt-3 mb-0">
              Reference: <span className="text-danger">*</span>
            </h5>
            <Row className="g-0">
              <Col md={12} lg={6} className="mb-3">
                {/* <span className={`label text-muted`}>Referred By</span> */}
                <div className="referred_by d-flex mt-2 flex-wrap">
                  <Radio
                    name={"referred_by"}
                    text={"Self"}
                    onChange={(e) => {
                      setJobData({
                        ...jobData,
                        referredByName: "Self",
                        referredById: "",
                      });
                      setReferenceType("Self");
                      setShowReferenceInput(false);
                    }}
                  />
                  <Radio
                    name={"referred_by"}
                    text={"Consultancy"}
                    onChange={(e) => {
                      setJobData({
                        ...jobData,
                        referredByName: "",
                        referredById: "",
                      });
                      setReferenceType("Consultancy");
                      setShowReferenceInput(true);
                    }}
                  />
                  <Radio
                    name={"referred_by"}
                    text={"HR Team"}
                    onChange={(e) => {
                      setJobData({
                        ...jobData,
                        referredByName: "HR Team",
                        referredById: "",
                      });
                      setReferenceType("HR Team");
                      setShowReferenceInput(false);
                    }}
                  />
                  <Radio
                    name={"referred_by"}
                    text={"Employee"}
                    onChange={(e) => {
                      setJobData({
                        ...jobData,
                        referredByName: "",
                        referredById: "",
                      });
                      setReferenceType("Employee");
                      setShowReferenceInput(true);
                    }}
                  />
                  {errors["referenceType"] && (
                    <span className="referred_by_error">
                      {errors["referenceType"]}
                    </span>
                  )}
                </div>
              </Col>
              {showReferenceInput && referenceType === "Consultancy" && (
                <Col md={12} lg={4} className="mb-3">
                  <Input
                    name="referredByName"
                    label="Consultancy Name"
                    type="text"
                    placeholder="Consultancy Name"
                    size="fluid"
                    value={jobData.referredByName}
                    onChange={(e) =>
                      setJobData({
                        ...jobData,
                        referredByName: e.target.value,
                        referredById: "",
                      })
                    }
                    error={errors["referredByName"]}
                    isrequired={isExperienced}
                  />
                </Col>
              )}
              {showReferenceInput && referenceType === "Employee" && (
                <Col md={12} lg={4} className="mb-3">
                  <Select
                    name="employeeList"
                    isSearchable={true}
                    label="Employee Name"
                    placeholder="Search Employee"
                    options={userListOptions}
                    onChange={(val) =>
                      setJobData({
                        ...jobData,
                        referredByName: val.value.name,
                        referredById: val.value.id,
                      })
                    }
                    className="select"
                    classNamePrefix="select"
                    maxMenuHeight="80px"
                    error={errors["referredByName"]}
                    isrequired={isExperienced}
                  />
                </Col>
              )}
            </Row>

            <Row className="d-flex justify-content-end">
              <Col xs={6} md={6} lg={3}>
                <ButtonView
                  onClick={() => {
                    handleClose();
                  }}
                  title={"Cancel"}
                  styles="danger-outline"
                />
              </Col>
              <Col xs={6} md={6} lg={3}>
                <ButtonView
                  onClick={handleSubmit}
                  title={"Save"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddJobApplication;
