import { actionTypes } from "./mySkillsActions";
const {
  GET_MY_SKILLS_PENDING,
  GET_MY_SKILLS_SUCCESS,
  GET_MY_SKILLS_ERROR,

  GETPROFICIENCY_PENDING,
  GETPROFICIENCY_SUCCESS,
  GETPROFICIENCY_ERROR,

  GETCATEGORY_PENDING,
  GETCATEGORY_SUCCESS,
  GETCATEGORY_ERROR,

  GETTECHNOLOGY_PENDING,
  GETTECHNOLOGY_SUCCESS,
  GETTECHNOLOGY_ERROR,

  FETCH_SKILL_CERTIFICATE_PENDING,
  FETCH_SKILL_CERTIFICATE_SUCCESS,
  FETCH_SKILL_CERTIFICATE_ERROR,

  SAVE_SKILLS_PENDING,
  SAVE_SKILLS_SUCCESS,
  SAVE_SKILLS_ERROR,

  SAVE_MAPPING_DATA_PENDING,
  SAVE_MAPPING_DATA_SUCCESS,
  SAVE_MAPPING_DATA_ERROR,

  UPLOAD_CERTIFICATE_PENDING,
  UPLOAD_CERTIFICATE_SUCCESS,
  UPLOAD_CERTIFICATE_ERROR,

  DELETE_CERTIFICATE_PENDING,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_ERROR,
} = actionTypes;

const initialState = {
  getMySkillsPending: false,
  getMySkillsSuccess: false,
  getMySkillsError: null,

  GETPROFICIENCY_PENDING: false,
  GETPROFICIENCY_SUCCESS: false,
  GETPROFICIENCY_ERROR: null,

  GETCATEGORY_PENDING: false,
  GETCATEGORY_SUCCESS: false,
  GETCATEGORY_ERROR: null,

  GETTECHNOLOGY_PENDING: false,
  GETTECHNOLOGY_SUCCESS: false,
  GETTECHNOLOGY_ERROR: null,

  FETCH_SKILL_CERTIFICATE_PENDING: false,
  FETCH_SKILL_CERTIFICATE_SUCCESS: false,
  FETCH_SKILL_CERTIFICATE_ERROR: null,

  SAVE_SKILLS_PENDING: false,
  SAVE_SKILLS_SUCCESS: false,
  SAVE_SKILLS_ERROR: null,

  SAVE_MAPPING_DATA_PENDING: false,
  SAVE_MAPPING_DATA_SUCCESS: false,
  SAVE_MAPPING_DATA_ERROR: null,

  UPLOAD_CERTIFICATE_PENDING: false,
  UPLOAD_CERTIFICATE_SUCCESS: false,
  UPLOAD_CERTIFICATE_ERROR: null,

  DELETE_CERTIFICATE_PENDING: false,
  DELETE_CERTIFICATE_SUCCESS: false,
  DELETE_CERTIFICATE_ERROR: null,

  mySkillList: [],
  proficiencies: [],
  categories: [],
  technologies: [],
  mySkillCertificates: [],
  newSkillId: "",
  DuplicateSkillMessage: "",
  ErrorMessage : ""
};

export default function mySkill(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MY_SKILLS_PENDING:
    case GET_MY_SKILLS_SUCCESS:
    case GET_MY_SKILLS_ERROR:
      return Object.assign({}, state, payload);

    case GETPROFICIENCY_PENDING:
    case GETPROFICIENCY_SUCCESS:
    case GETPROFICIENCY_ERROR:
      return Object.assign({}, state, payload);

    case GETCATEGORY_PENDING:
    case GETCATEGORY_SUCCESS:
    case GETCATEGORY_ERROR:
      return Object.assign({}, state, payload);

    case GETTECHNOLOGY_PENDING:
    case GETTECHNOLOGY_SUCCESS:
    case GETTECHNOLOGY_ERROR:
      return Object.assign({}, state, payload);

    case FETCH_SKILL_CERTIFICATE_PENDING:
    case FETCH_SKILL_CERTIFICATE_SUCCESS:
    case FETCH_SKILL_CERTIFICATE_ERROR:
      return Object.assign({}, state, payload);

    case SAVE_SKILLS_PENDING:
    case SAVE_SKILLS_SUCCESS:
    case SAVE_SKILLS_ERROR:
      return Object.assign({}, state, payload);

    case SAVE_MAPPING_DATA_PENDING:
    case SAVE_MAPPING_DATA_SUCCESS:
    case SAVE_MAPPING_DATA_ERROR:
      return Object.assign({}, state, payload);

    case UPLOAD_CERTIFICATE_PENDING:
    case UPLOAD_CERTIFICATE_SUCCESS:
    case UPLOAD_CERTIFICATE_ERROR:
      return Object.assign({}, state, payload);

    case DELETE_CERTIFICATE_PENDING:
    case DELETE_CERTIFICATE_SUCCESS:
    case DELETE_CERTIFICATE_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
