import React from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import ActiveList from "./ActiveList";

import AddJobApplication from "./AddJobApplication";
import Inactive from "./Inactive";
import { MONTH_LIMIT } from "../../../../service/apiConstants";

const JobApplications = ({
  techDomainList,
  designationList,
  aplicationDomainList,
  user,
  createJobApplication,
  jobApplicationList,
  setProfileDetail,
  userList,
  rowId,
  setRowId,
  srollToSummary,
  jobApplicationInactiveList,
  defaultTabActiveKey,
  onsetDefaultTabActiveKey,
  addTechDomain,
}) => {
  return (
    <Card className="job_list">
      <Card.Body>
        <AddJobApplication
          techDomainList={techDomainList}
          designationList={designationList}
          aplicationDomainList={aplicationDomainList}
          user={user}
          createJobApplication={createJobApplication}
          userList={userList}
          addTechDomain={addTechDomain}
        />
      </Card.Body>
      <div className="job_table_content mb-3 px-1">
        <div className="show_months_limit">
          <h6>Showing last {MONTH_LIMIT} months data.</h6>
        </div>
        <Tabs
          defaultActiveKey="active"
          activeKey={defaultTabActiveKey}
          id="leave_application"
          className="table_tabs"
          onSelect={(key) => {
            onsetDefaultTabActiveKey(key);
          }}
        >
          <Tab eventKey="active" title="ACTIVE">
            <ActiveList
              jobApplicationList={jobApplicationList}
              setProfileDetail={setProfileDetail}
              rowId={rowId}
              setRowId={setRowId}
              srollToSummary={srollToSummary}
            />
          </Tab>
          <Tab eventKey="inactive" title="INACTIVE">
            <Inactive
              setProfileDetail={setProfileDetail}
              rowId={rowId}
              setRowId={setRowId}
              srollToSummary={srollToSummary}
              jobApplicationInactiveList={jobApplicationInactiveList}
            />
          </Tab>
        </Tabs>
      </div>
    </Card>
  );
};

export default JobApplications;
