import React, { useState } from "react";
import { Card, Table, Dropdown } from "react-bootstrap";
import moment from "moment";
import { FiEdit3 } from "react-icons/fi";
import { BsThreeDots } from "react-icons/bs";

import { ButtonView } from "../../../controls";
import ScheduleInterview from "./ScheduleInterview";
import {
  JOB_APPLICAITON_STATUS_ONHOLD,
  JOB_APPLICAITON_STATUS_CANCELLED,
  INTERVIEW_OUTCOME_SELECTED,
  INTERVIEW_OUTCOME_REJECTED,
  JOB_APPLICAITON_STATUS_APPLIED,
} from "../../../../constants/env";
import EditJobApplication from "./EditJobApplication";

const JobApplicationDetail = ({
  profileDetail,
  downloadJobApplicationDocument,
  userManager,
  interviewScheduleDetail,
  createNewInterviewSchedule,
  updateJobApplicationStatus,
  user,
  jobApplicationList,
  setProfileDetail,
  updateInterviewSchedule,
  setInterviewScheduleDetail,
  closeJobApplication,
  techDomainList,
  designationList,
  aplicationDomainList,
  updateJobApplication,
  locationList,
  updateInterviewScheduleMeetingLink,

  date,
  desData,
  combineCalenderData,

  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,
  totalInterviewScheduleList,
  holidaysList,
}) => {
  // console.log("profileDetail", profileDetail);

  const [editJobAppData, setEditJobAppData] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  // console.log(interviewScheduleDetail);
  interviewScheduleDetail.filter((list) => {
    return !isNaN(list.roundNumber);
  });
  var showCancelButton = true;

  interviewScheduleDetail.forEach((list) => {
    if (
      list.status === "Accepted" ||
      list.status === "Completed" ||
      list.status === "Incomplete" ||
      list.status === "Scheduled" ||
      list.status === "Rejected"
    ) {
      showCancelButton = false;
    }
  });

  const interviewSchedule = (data, id, newList) => {
    updateInterviewSchedule(data, id, newList);
  };

  return (
    <>
      <Card className="job_detail">
        <Card.Body>
          <Card.Title>
            {/* <div>Profile Detail</div> */}
            <div className="d-flex job_app_title mt-1">
              Profile Detail{" "}
              {profileDetail && profileDetail.isActive && (
                <div
                  className="ms-1"
                  style={{ cursor: "pointer", color: "#D87D2E" }}
                  onClick={() => {
                    setEditJobAppData(profileDetail);
                    setShowEdit(true);
                  }}
                >
                  <FiEdit3 />
                </div>
              )}
            </div>
            {profileDetail && (
              <ButtonView
                onClick={() =>
                  downloadJobApplicationDocument(profileDetail._id)
                }
                title={"Download Resume"}
                styles="secondary"
              />
            )}
          </Card.Title>
        </Card.Body>

        {profileDetail ? (
          <div className="job_detail_content">
            {profileDetail.isActive && (
              <div className="job_detail_actions mb-1 d-flex align-items-center justify-content-between">
                {profileDetail.status !== JOB_APPLICAITON_STATUS_CANCELLED &&
                  profileDetail.status === JOB_APPLICAITON_STATUS_ONHOLD && (
                    <span
                      style={{ fontSize: "15px" }}
                      className="badge badge-hold me-3"
                    >
                      On Hold
                    </span>
                  )}
                {showCancelButton &&
                  profileDetail.status === JOB_APPLICAITON_STATUS_CANCELLED && (
                    <span
                      style={{ fontSize: "15px" }}
                      className="badge badge-danger me-3"
                    >
                      Application Cancelled
                    </span>
                  )}
                <div></div>
                <Dropdown>
                  <Dropdown.Toggle as="div" id="dropdown-basic">
                    <BsThreeDots size={30} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {profileDetail.status !==
                      JOB_APPLICAITON_STATUS_CANCELLED &&
                      (profileDetail.status ===
                      JOB_APPLICAITON_STATUS_ONHOLD ? (
                        ""
                      ) : (
                        <Dropdown.Item
                          onClick={() => {
                            const data = {
                              lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
                              status: JOB_APPLICAITON_STATUS_ONHOLD,
                            };
                            if (window.confirm("Hold Job Application?")) {
                              updateJobApplicationStatus(
                                data,
                                profileDetail._id
                              );
                              setProfileDetail({
                                ...profileDetail,
                                status: JOB_APPLICAITON_STATUS_ONHOLD,
                              });
                            }
                          }}
                        >
                          {" "}
                          Hold Application
                        </Dropdown.Item>
                      ))}

                    {profileDetail.status !== INTERVIEW_OUTCOME_REJECTED &&
                      profileDetail.status !==
                        JOB_APPLICAITON_STATUS_CANCELLED &&
                      profileDetail.status !== JOB_APPLICAITON_STATUS_APPLIED &&
                      profileDetail.status !==
                        JOB_APPLICAITON_STATUS_ONHOLD && (
                        <Dropdown.Item
                          onClick={() => {
                            const data = {
                              lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
                              status: INTERVIEW_OUTCOME_REJECTED,
                            };
                            if (window.confirm("Reject Job Application?")) {
                              updateJobApplicationStatus(
                                data,
                                profileDetail._id
                              );
                              setProfileDetail({
                                ...profileDetail,
                                status: INTERVIEW_OUTCOME_REJECTED,
                              });
                            }
                          }}
                        >
                          {" "}
                          Reject Application
                        </Dropdown.Item>
                      )}

                    {profileDetail.status === JOB_APPLICAITON_STATUS_ONHOLD && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            const data = {
                              lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
                              status: INTERVIEW_OUTCOME_SELECTED,
                            };
                            if (window.confirm("Reselect Job Application?")) {
                              updateJobApplicationStatus(
                                data,
                                profileDetail._id
                              );
                              setProfileDetail({
                                ...profileDetail,
                                status: INTERVIEW_OUTCOME_SELECTED,
                              });
                            }
                          }}
                        >
                          {" "}
                          Reselect Application
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            const data = {
                              lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
                              status: INTERVIEW_OUTCOME_REJECTED,
                            };
                            if (window.confirm("Reject Job Application?")) {
                              updateJobApplicationStatus(
                                data,
                                profileDetail._id
                              );
                              setProfileDetail({
                                ...profileDetail,
                                status: INTERVIEW_OUTCOME_REJECTED,
                              });
                            }
                          }}
                        >
                          {" "}
                          Reject Application
                        </Dropdown.Item>
                      </>
                    )}
                    {profileDetail.status === INTERVIEW_OUTCOME_REJECTED && (
                      <Dropdown.Item
                        onClick={() => {
                          const data = {
                            lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
                            status: INTERVIEW_OUTCOME_SELECTED,
                          };
                          if (window.confirm("Reselect Job Application?")) {
                            updateJobApplicationStatus(data, profileDetail._id);
                            setProfileDetail({
                              ...profileDetail,
                              status: INTERVIEW_OUTCOME_SELECTED,
                            });
                          }
                        }}
                      >
                        Reselect Application
                      </Dropdown.Item>
                    )}
                    {showCancelButton &&
                      (profileDetail.status ===
                      JOB_APPLICAITON_STATUS_CANCELLED ? (
                        ""
                      ) : (
                        <Dropdown.Item
                          onClick={() => {
                            const data = {
                              lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
                              status: JOB_APPLICAITON_STATUS_CANCELLED,
                            };
                            if (window.confirm("Cancel Job Application?")) {
                              updateJobApplicationStatus(
                                data,
                                profileDetail._id
                              );
                              setProfileDetail({
                                ...profileDetail,
                                status: JOB_APPLICAITON_STATUS_CANCELLED,
                              });
                            }
                          }}
                          className="text-danger"
                        >
                          Cancel Application
                        </Dropdown.Item>
                      ))}
                    {profileDetail.status ===
                      JOB_APPLICAITON_STATUS_CANCELLED && (
                      <Dropdown.Item
                        onClick={() => {
                          const data = {
                            lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
                            status: JOB_APPLICAITON_STATUS_APPLIED,
                          };
                          if (window.confirm("Reapply Job Application?")) {
                            updateJobApplicationStatus(data, profileDetail._id);
                            setProfileDetail({
                              ...profileDetail,
                              status: JOB_APPLICAITON_STATUS_APPLIED,
                            });
                          }
                        }}
                      >
                        Reapply Application
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() => {
                        console.log(profileDetail.status);
                        const data = {
                          lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
                          isActive: false,
                          status: profileDetail.status,
                        };
                        if (window.confirm("Inactivate Job Application?")) {
                          closeJobApplication(profileDetail._id, data);
                          setProfileDetail(null);
                        }
                      }}
                      className="text-danger"
                    >
                      Archive Application
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            <Table bordered className="profile_detail_table">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {profileDetail.firstName}{" "}
                    {profileDetail.middleName && profileDetail.middleName}{" "}
                    {profileDetail.lastName && profileDetail.lastName}
                  </td>
                </tr>
                {profileDetail.emailAddress && (
                  <tr>
                    <td>Email Address</td>
                    <td>{profileDetail.emailAddress}</td>
                  </tr>
                )}
                {profileDetail.mobileNumber && (
                  <tr>
                    <td>Mobile Number</td>
                    <td>{profileDetail.mobileNumber}</td>
                  </tr>
                )}
                {profileDetail.whatsAppNumber && (
                  <tr>
                    <td>Whatsapp Number</td>
                    <td>{profileDetail.whatsAppNumber}</td>
                  </tr>
                )}
                {profileDetail.city && (
                  <tr>
                    <td>City</td>
                    <td>{profileDetail.city}</td>
                  </tr>
                )}
                {profileDetail.primaryTechnicalDomains.length ? (
                  <tr>
                    <td>Primary Domain</td>
                    <td>
                      {profileDetail.primaryTechnicalDomains.map(
                        (list, id, array) => {
                          if (
                            profileDetail.primaryTechnicalDomains.length > 1
                          ) {
                            if (id === array.length - 1) {
                              return <span key={id}>{list} </span>;
                            } else {
                              return <span key={id}>{list}, </span>;
                            }
                          } else {
                            return <span key={id}>{list}</span>;
                          }
                        }
                      )}
                    </td>
                  </tr>
                ) : null}
                {profileDetail.secondaryTechnicalDomains.length ? (
                  <tr>
                    <td>Secondary Domain</td>
                    <td>
                      {profileDetail.secondaryTechnicalDomains.map(
                        (list, id, array) => {
                          if (
                            profileDetail.secondaryTechnicalDomains.length > 1
                          ) {
                            if (id === array.length - 1) {
                              return <span key={id}>{list} </span>;
                            } else {
                              return <span key={id}>{list}, </span>;
                            }
                          } else {
                            return <span key={id}>{list}</span>;
                          }
                        }
                      )}
                    </td>
                  </tr>
                ) : null}
                {profileDetail.skills && (
                  <tr>
                    <td>Skills</td>
                    <td>{profileDetail.skills}</td>
                  </tr>
                )}
                {profileDetail.applicationDomains.length ? (
                  <tr>
                    <td>Application Domain</td>
                    <td>
                      {profileDetail.applicationDomains.map(
                        (list, id, array) => {
                          if (profileDetail.applicationDomains.length > 1) {
                            if (id === array.length - 1) {
                              return <span key={id}>{list} </span>;
                            } else {
                              return <span key={id}>{list}, </span>;
                            }
                          } else {
                            return <span key={id}>{list}</span>;
                          }
                        }
                      )}
                    </td>
                  </tr>
                ) : null}
                {profileDetail.designationAppliedFor && (
                  <tr>
                    <td>Applied Designation</td>
                    <td>{profileDetail.designationAppliedFor}</td>
                  </tr>
                )}
                <tr>
                  <td>Experience</td>
                  <td>
                    {(profileDetail.experienceInMonths / 12) | 0}.
                    {profileDetail.experienceInMonths % 12} yrs
                  </td>
                </tr>
                {profileDetail.qualification && (
                  <tr>
                    <td>Qualification</td>
                    <td>{profileDetail.qualification}</td>
                  </tr>
                )}
                {profileDetail.passingYear && (
                  <tr>
                    <td>Passing Year</td>
                    <td>{profileDetail.passingYear}</td>
                  </tr>
                )}
                <tr>
                  <td>Applied Date</td>
                  <td>
                    {moment(new Date(profileDetail.createdAt)).format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                </tr>
                {profileDetail.previousCTC && (
                  <tr>
                    <td>Previous CTC</td>
                    <td>{profileDetail.previousCTC}</td>
                  </tr>
                )}
                {profileDetail.expectedCTC && (
                  <tr>
                    <td>Expected CTC</td>
                    <td>{profileDetail.expectedCTC}</td>
                  </tr>
                )}

                {profileDetail.currentEmployer && (
                  <tr>
                    <td>Current Employment</td>
                    <td>{profileDetail.currentEmployer}</td>
                  </tr>
                )}
                {profileDetail.noticePeriodEndDate && (
                  <tr>
                    <td>Notice Period Ends on</td>
                    <td>
                      {moment(
                        new Date(profileDetail.noticePeriodEndDate)
                      ).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                )}
                {profileDetail.referredByDetails && (
                  <tr>
                    <td>Reference Detail</td>
                    <td>{profileDetail.referredByDetails}</td>
                  </tr>
                )}
                {profileDetail.referredByName && (
                  <tr>
                    <td>Referred By</td>
                    <td>{profileDetail.referredByName}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {profileDetail.status ===
            JOB_APPLICAITON_STATUS_CANCELLED ? null : (
              <>
                <hr />

                <ScheduleInterview
                  profileDetail={profileDetail}
                  userManager={userManager}
                  interviewScheduleDetail={interviewScheduleDetail}
                  createNewInterviewSchedule={createNewInterviewSchedule}
                  updateJobApplicationStatus={updateJobApplicationStatus}
                  user={user}
                  interviewSchedule={interviewSchedule}
                  locationList={locationList}
                  updateInterviewScheduleMeetingLink={
                    updateInterviewScheduleMeetingLink
                  }
                  date={date}
                  desData={desData}
                  combineCalenderData={combineCalenderData}
                  onsetCombineCalenderList={onsetCombineCalenderList}
                  onsetDate={onsetDate}
                  onsetDescription={onsetDescription}
                  totalInterviewScheduleList={totalInterviewScheduleList}
                  holidaysList={holidaysList}
                />
              </>
            )}
          </div>
        ) : (
          <h5 className="text-center m-3">
            {jobApplicationList.length
              ? `Click on "View" Button to see detail.`
              : "There are no records to display"}
          </h5>
        )}
      </Card>
      <div className="edit_job_application">
        <EditJobApplication
          editJobAppData={editJobAppData}
          setEditJobAppData={setEditJobAppData}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          techDomainList={techDomainList}
          designationList={designationList}
          aplicationDomainList={aplicationDomainList}
          user={user}
          updateJobApplication={updateJobApplication}
        />
      </div>
    </>
  );
};

export default JobApplicationDetail;
