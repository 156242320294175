import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { ButtonView, Input, Select, TextArea } from "../../../controls";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import moment from "moment";
const leaveSubTypesListOptions = [
  { value: "Full Day", label: "Full Day" },
  { value: "Half Day - 1st Half", label: "Half Day - 1st Half" },
  { value: "Half Day - 2nd Half", label: "Half Day - 2nd Half" },
];

const EditWFHFormModal = ({
  editWfhDetailsShow,
  handleClose,
  data,
  editWfhData,
  setEditWfhData,
  userDocument,
  updateWFHDetail,
  changingYear,
}) => {
  // console.log("edit", editWfhData);
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    var rules = {
      required: ["leaveSubType", "startDate", "endDate", "totalDays"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(editWfhData),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      const data = {
        leaveSubType: editWfhData.leaveSubType,
        startDate: editWfhData.startDate,
        endDate: editWfhData.endDate,
        totalDays: editWfhData.totalDays,
        comments: editWfhData.comments,
        setKey: editWfhData.setKey,
        currentYear: changingYear,
        _id: editWfhData.id,
      };
      // console.log("data", data);
      updateWFHDetail(data);
      handleClose();
    }
  };

  return (
    <Modal
      show={editWfhDetailsShow}
      onHide={handleClose}
      aria-labelledby="edit_modal"
      size="lg"
    >
      <Modal.Header>
        <Modal.Title id="edit_modal">Edit Leave Details</Modal.Title>
        <div onClick={handleClose} style={{ cursor: "pointer" }}>
          <AiOutlineClose size={20} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} lg={6} className="mb-3">
            <Select
              name="leaveSubType"
              label="FD/1H/2H"
              isSearchable={false}
              placeholder="Select"
              options={leaveSubTypesListOptions}
              defaultValue={leaveSubTypesListOptions.find(
                (option) => option.value === editWfhData.leaveSubType
              )}
              onChange={(val) =>
                setEditWfhData({ ...editWfhData, leaveSubType: val.value })
              }
              className="select"
              classNamePrefix="select"
              isrequired={true}
              error={errors["leaveSubType"]}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="approver"
              label="Approver"
              value={editWfhData.approver}
              type="text"
              placeholder="Approver"
              readOnly
              size="fluid"
              disabled={true}
              // error={errors["lastName"]}
            />
          </Col>

          <Col md={12} lg={6} className="mb-3">
            <Input
              name="startDate"
              label="Start Date"
              value={moment(editWfhData.startDate).format("YYYY-MM-DD")}
              type="date"
              onChange={(e) =>
                setEditWfhData({
                  ...editWfhData,
                  startDate: e.target.value,
                })
              }
              size="fluid"
              //  min={moment(new Date()).format("YYYY-MM-DD")}
              error={errors["startDate"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="endDate"
              label="End Date"
              value={moment(editWfhData.endDate).format("YYYY-MM-DD")}
              type="date"
              onChange={(e) =>
                setEditWfhData({
                  ...editWfhData,
                  endDate: e.target.value,
                })
              }
              size="fluid"
              min={editWfhData.startDate}
              error={errors["endDate"]}
              isrequired={true}
            />
          </Col>

          <Col md={12} lg={6} className="mb-3">
            <Input
              name="totalDays"
              label="Total No of Days"
              type="text"
              value={editWfhData.totalDays}
              placeholder="Total No of Days"
              onChange={(e) =>
                setEditWfhData({
                  ...editWfhData,
                  totalDays: e.target.value,
                })
              }
              size="fluid"
              error={errors["totalDays"]}
              isrequired={true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <TextArea
              label="WFH Reason"
              type="textarea"
              placeholder="Type something..."
              value={editWfhData.comments}
              onChange={(e) =>
                setEditWfhData({ ...editWfhData, comments: e.target.value })
              }
              size="fluid"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Col xs={6} md={6} lg={3}>
            <ButtonView
              onClick={handleClose}
              title={"Cancel"}
              styles="danger-outline"
            />
          </Col>
          <Col xs={6} md={6} lg={3}>
            <ButtonView
              onClick={handleSubmit}
              title={"Save"}
              styles="secondary"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EditWFHFormModal;
