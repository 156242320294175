import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, TextArea } from "../../../controls";

const TechDomainMngt = ({
  techDomainList,
  addTechDomain,
  editTechDomain,
  deleteTechDomain,
}) => {
  const [showAddTechDomain, setShowAddTechDomain] = useState(false);
  const [techDomain, setTechDomain] = useState("");
  const [description, setDescription] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [techDomainId, setTechDomainId] = useState("");

  const columns = [
    {
      name: "Technical Domain",
      selector: "name",
      sortable: true,
      grow: 4,
    },
    {
      name: "Descripton",
      selector: "extraInfo",
      sortable: true,
      grow: 4,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setTechDomain(row.name);
                setDescription(row.extraInfo);
                setShowAddTechDomain(true);
                setTechDomainId(row._id);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete Technical Domain?")) {
                // console.log(row._id);
                deleteTechDomain(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleAddHoliday = () => {
    var rules = { required: ["techDomain"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ techDomain }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const data = { name: techDomain, extraInfo: description };
        // console.log(data, techDomainId);
        editTechDomain(data, techDomainId);
        // edit
      } else {
        const data = { name: techDomain, extraInfo: description };
        // console.log(data);
        // console.log(techDomain, description);
        addTechDomain(data);
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddTechDomain(false);
    setTechDomain("");
    setDescription("");
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Technical Domain</div>
              <div className="d-none d-md-block">
                Technical Domain Management
              </div>
            </Card.Title>
            <div className="add_tech_domain_one">
              <ButtonView
                onClick={() => setShowAddTechDomain(true)}
                title={"Add"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={techDomainList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddTechDomain}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Technical Domain" : "Add Technical Domain"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12} className="mb-3">
                <Input
                  name="techDomain"
                  label="Technical Domain"
                  value={techDomain}
                  type="text"
                  placeholder="Add Technical Domain"
                  onChange={(e) => setTechDomain(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["techDomain"]}
                  isrequired={true}
                  maxLength={30}
                />
              </Col>
              <Col xs={12}>
                <TextArea
                  name="description"
                  label="Description"
                  value={description}
                  type="number"
                  placeholder="Some text here..."
                  onChange={(e) => setDescription(e.target.value)}
                  size="fluid"
                  maxLength={300}
                />
              </Col>
              <Col xs={4} className="mt-3">
                <ButtonView
                  onClick={handleAddHoliday}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TechDomainMngt;
