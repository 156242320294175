import React, { useState, useEffect } from "react";
import { DataTable } from "../../../common";
import { Modal, Row, Col } from "react-bootstrap";
import EditMySkillModal from "./EditMySkillModal";
import { AiFillEye, AiOutlineClose } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { ButtonView } from "../../../controls";
import moment from "moment";

const All = ({
  mySkillList,
  proficiencies,
  categories,
  technologies,
  getTechnologies,
  updateSkills,
  fetchSkillCertificate,
  mySkillCertificates,
  downloadSkillCertificates,
  uploadSkillCertificates,
  deleteSkillCertificate,
  deleteSkill,
}) => {
  const [ViewShowModal, setViewShowModal] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);
  const [editMySkillShow, setEditMySkillShow] = useState(false);
  const [editMySkillData, setEditMySkillData] = useState(null);
  const [editData, setEditData] = useState({});
  const [skillId, setSkillId] = useState("");
  const [deleteSkillModal, setDeleteSkillModal] = useState(false);
  const [deleteSkillID, setDeleteSkillID] = useState("");

  const handleClose = () => {
    setEditMySkillShow(false);
    setEditMySkillData(null);
  };

  const yesterDay = new Date();
  yesterDay.setDate(yesterDay.getDate() - 1);

  const columns = [
    {
      name: "Technology",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="Technology_Title">
          {/* {row.categoryTechnologyId.technologyName} */}
          {row.technologyName.substring(0, 50)}
        </span>
      ),
    },
    {
      name: "Category",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {/* {row.categoryTechnologyId.categoryName} */}
          {row.categoryName.substring(0, 50)}
        </span>
      ),
    },
    {
      name: "Proficiency",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{row.proficiencyId.proficiencyName.substring(0, 50)}</span>
      ),
    },
    {
      name: "Certified",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="badge bg-success-transparent">
          {row.certificate_count === 0 ? "No" : "Yes"}
        </span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "250px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <AiFillEye
              className="view"
              size={18}
              onClick={() => {
                setViewShowModal(true);
                setViewDetails(row);
                setSkillId(row._id);
                console.log(row._id);
              }}
            />
          </div>
          <FiEdit3
            size={17}
            className="edit"
            onClick={() => {
              setEditMySkillShow(true);
              setEditMySkillData(true);
              setEditData(row);
              setSkillId(row._id);
            }}
          />
          <MdDelete
            style={{ color: "#d87d2e", marginLeft: "15px", cursor: "pointer"}}
            size={18}
            className="delete"
            onClick={() => {
              setDeleteSkillModal(true);
              setDeleteSkillID(row._id);
            }}
          />
        </div>
      ),
    },
  ];

  console.log(mySkillList[0]);

  const data = mySkillList;

  const fecthCertificateData = {
    skillsId: skillId,
  };

  useEffect(() => {
    fetchSkillCertificate(fecthCertificateData);
    // eslint-disable-next-line
  }, [skillId]);

  console.log(mySkillCertificates);

  useEffect(() => {
    fetchSkillCertificate(fecthCertificateData);
    // eslint-disable-next-line
  }, [ViewShowModal, editMySkillShow]);

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        pagination={true}
        paginationPerPage={20}
        paginationServer={false}
      />

      {deleteSkillModal ? (
        <Modal show={deleteSkillModal} centered={true} size="sm">
          <Modal.Header className="d-flex justify-content-center align-items-center">
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>Are you sure you want to delete this skill?</p>
            <Row>
              <Col lg={6} md={6} sm={6} xs={6}>
                <ButtonView
                  size={"small"}
                  title="Confirm"
                  styles="danger-outline"
                  onClick={() => {
                    deleteSkill(deleteSkillID);
                    setDeleteSkillModal(false);
                  }}
                ></ButtonView>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <ButtonView
                  size={"small"}
                  title="Cancel"
                  onClick={() => {
                    setDeleteSkillModal(false);
                  }}
                ></ButtonView>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      ) : null}

      {viewDetails && (
        <Modal show={ViewShowModal} size="lg">
          <Modal.Header>
            <Modal.Title>View Skill</Modal.Title>
            <div
              onClick={() => {
                setViewShowModal(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h5>
                Technology:{" "}
                <span style={{ color: "#d87d2e" }}>
                  {viewDetails.technologyName.substring(0, 50)}
                </span>
              </h5>
            </div>
            <div>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Category
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.categoryName.substring(0, 50)}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Proficiency
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.proficiencyId.proficiencyName.substring(0, 50)}
                  </span>
                </li>
                {viewDetails.certificate_count !== 0 ? (
                  <>
                    {mySkillCertificates.map((certificate, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center overflow-auto"
                      >
                        {certificate.skill.certificateName.substring(0, 50)}
                        <div className="d-flex justify-content-between align-items-center">
                          {certificate.skill.validCertificationDate !== null ? (
                            <span className="m-2">
                              Valid Till:{" "}
                              {moment(
                                certificate.skill.validCertificationDate
                              ).format("DD/MM/YYYY")}
                            </span>
                          ) : null}
                          <span>
                            <ButtonView
                              title="Download"
                              onClick={() => {
                                downloadSkillCertificates({
                                  skillsId: skillId,
                                  certificateId: certificate.skill._id,
                                });
                              }}
                            ></ButtonView>
                          </span>
                        </div>
                      </li>
                    ))}
                  </>
                ) : null}
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {editMySkillData && (
        <EditMySkillModal
          editMySkillShow={editMySkillShow}
          handleClose={handleClose}
          proficiencies={proficiencies}
          categories={categories}
          technologies={technologies}
          editData={editData}
          getTechnologies={getTechnologies}
          updateSkills={updateSkills}
          skillId={skillId}
          fetchSkillCertificate={fetchSkillCertificate}
          mySkillCertificates={mySkillCertificates}
          uploadSkillCertificates={uploadSkillCertificates}
          deleteSkillCertificate={deleteSkillCertificate}
        />
      )}
    </>
  );
};

export default All;
