import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";

export const actionTypes = {
  GET_USERLIST_PENDING: "GET_USERLIST_PENDING",
  GET_USERLIST_SUCCESS: "GET_USERLIST_SUCCESS",
  GET_USERLIST_ERROR: "GET_USERLIST_ERROR",

  GET_USER_SKILL_WITH_FILTER_PENDING: "GET_USER_SKILL_WITH_FILTER_PENDING",
  GET_USER_SKILL_WITH_FILTER_SUCCESS: "GET_USER_SKILL_WITH_FILTER_SUCCESS",
  GET_USER_SKILL_WITH_FILTER_ERROR: "GET_USER_SKILL_WITH_FILTER_ERROR",
};

export function getUserList(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_USERLIST_PENDING,
      payload: {
        getUserListPending: true,
        getUserListSuccess: false,
        getUserListError: null,
      },
    });
    // dispatch(showLoader());
    Api.post("user/sortedlist", data)
      .then((response) => {
        console.log(response);
        // dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_USERLIST_SUCCESS,
          payload: {
            getUserListPending: false,
            getUserListSuccess: true,
            getUserListError: null,

            userList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.GET_USERLIST_ERROR,
          payload: {
            getUserListPending: false,
            getUserListSuccess: true,
            getUserListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}

export function getUserSkillWithFilter(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_USER_SKILL_WITH_FILTER_PENDING,
      payload: {
        getUserSkillWithFilterPending: true,
        getUserSkillWIthFilterSuccess: false,
        getUserSkillWithFilterError: null,
      },
    });
    dispatch(showLoader());
    if (data.clearData === true) {
      dispatch(hideLoader());
      dispatch({
        type: actionTypes.GET_USER_SKILL_WITH_FILTER_SUCCESS,
        payload: {
          UserSkillFilteredList: [],
        },
      });
    } else {
      if (data.Empty === true) {
        dispatch(hideLoader());
        dispatch(
          showAlert({
            isOpen: true,
            title: "Warning",
            type: "warning",
            msg: "Records not found !",
          })
        );
      } else {
        Api.post(
          "userSkills/getUserSkillsWithFilter/list?page=1&limit=100",
          data
        )
          .then((response) => {
            console.log(response);
            dispatch(hideLoader());
            if (response.message === "Records not found !!") {
              dispatch(
                showAlert({
                  isOpen: true,
                  title: "Warning",
                  type: "warning",
                  msg: "Records not found !",
                })
              );
              dispatch({
                type: actionTypes.GET_USER_SKILL_WITH_FILTER_SUCCESS,
                payload: {
                  getUserSkillWithFilterPending: false,
                  getUserSkillWIthFilterSuccess: true,
                  getUserSkillWithFilterError: null,

                  UserSkillFilteredList: response.data.data,
                },
              });
            }
            if (
              response.message ===
              "Successfully fetched user skills with filter"
            ) {
              dispatch({
                type: actionTypes.GET_USER_SKILL_WITH_FILTER_SUCCESS,
                payload: {
                  getUserSkillWithFilterPending: false,
                  getUserSkillWIthFilterSuccess: true,
                  getUserSkillWithFilterError: null,

                  UserSkillFilteredList: response.data.results,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: actionTypes.GET_USER_SKILL_WITH_FILTER_ERROR,
              payload: {
                getUserSkillWithFilterPending: false,
                getUserSkillWIthFilterSuccess: true,
                getUserSkillWithFilterError: null,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Failed to fetch Data.",
              })
            );
          });
      }
    }
  };
}
