import React from "react";
import { IoFastFood } from "react-icons/io5";

import { Header } from "../../common";
import LunchPreferenceCard from "./components/LunchPreferenceCard";

const LunchPreferenceView = ({
  user,
  lunchTypeList,
  selectedLunchType,
  onlunchPreference,
  showAlert,
  deleteLunchPreference,
}) => {
  return (
    <>
      <Header label={"Lunch Preference"} Icon={IoFastFood} />
      <LunchPreferenceCard
        user={user}
        lunchTypeList={lunchTypeList}
        selectedLunchType={selectedLunchType}
        onlunchPreference={onlunchPreference}
        showAlert={showAlert}
        deleteLunchPreference={deleteLunchPreference}
      />
    </>
  );
};

export default LunchPreferenceView;
