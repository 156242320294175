import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, TextArea } from "../../../controls";

const DesignationsMngt = ({
  designationList,
  addDesignation,
  editDesignation,
  deleteDesignation,
}) => {
  const [showAddDesignation, setShowAddDesignation] = useState(false);
  const [designation, setDesignation] = useState("");
  const [description, setDescription] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [designationId, setDesignationId] = useState("");

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      grow: 4,
    },
    {
      name: "Descripton",
      selector: "extraInfo",
      sortable: true,
      grow: 4,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setDesignation(row.name);
                setDescription(row.extraInfo);
                setShowAddDesignation(true);
                setDesignationId(row._id);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete Designation?")) {
                // console.log(row._id);
                deleteDesignation(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleAddHoliday = () => {
    var rules = { required: ["designation"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ designation }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const data = { name: designation, extraInfo: description };
        // console.log(data, designationId);
        editDesignation(data, designationId);
        // edit
      } else {
        const data = { name: designation, extraInfo: description };
        // console.log(data);
        // console.log(designation, description);
        addDesignation(data);
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddDesignation(false);
    setDesignation("");
    setDescription("");
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Designation</div>
              <div className="d-none d-md-block">Designation Management</div>
            </Card.Title>
            <div className="add_designation">
              <ButtonView
                onClick={() => setShowAddDesignation(true)}
                title={"Add"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={designationList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddDesignation}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Designation" : "Add Designation"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12} className="mb-3">
                <Input
                  name="designation"
                  label="Designation"
                  value={designation}
                  type="text"
                  placeholder="Add Designation"
                  onChange={(e) => setDesignation(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["designation"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12}>
                <TextArea
                  name="description"
                  label="Description"
                  value={description}
                  type="number"
                  placeholder="Some text here..."
                  onChange={(e) => setDescription(e.target.value)}
                  size="fluid"
                />
              </Col>
              <Col xs={4} className="mt-3">
                <ButtonView
                  onClick={handleAddHoliday}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DesignationsMngt;
