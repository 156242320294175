import React, { useEffect, useState } from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import { ButtonView, TextArea } from "../../../controls";
// import { AiOutlineArrowDown } from "react-icons/ai";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import {
  LEAVE_STATUS_REJECTED,
  LEAVE_STATUS_APPROVED,
} from "../../../../constants/env";
// import Unapproved from "./Unapproved";

const ModalView = ({
  getUserWfhData,
  downloadFile,
  userDocument,
  updateWfh,
  user,
  currentYear,
  changingYear,
  updateGetUserWfhData,
  // handleRejectOpen,
  // props
  // props,
}) => {
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [rejectionVal, setRejectionVal] = useState("");
  const [rejectionId, setRejectionId] = useState("");
  const [errors, setErrors] = useState({});
  const [rejectOpen, setRejectOpen] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);

  // const Id = rejectionId;
  const handleClose = () => {
    // console.log()
    setViewDetailsShow(false);
    setRejectionId("");
    updateGetUserWfhData();
    // setCancelOpen(false);
    // setCancelId("");
    // setErrors({});
    // setCancelVal("");
    // setViewDetails(null);
    setRejectOpen(false);
  };

  useEffect(() => {
    if (getUserWfhData) {
      setViewDetails(getUserWfhData);
      // console.log("hi");

      setViewDetailsShow(true);
    } else {
      return "";
    }
  }, [getUserWfhData]);

  const handleApproveLeave = (row) => {
    // console.log("row", row);
    var setKey = 0;
    if (window.confirm("Approve WFH?")) {
      updateWfh(
        row._id,
        user,
        "",
        LEAVE_STATUS_APPROVED,
        "Successfully approved WFH.",
        changingYear,

        setKey
      );
      handleClose();
    }
  };
  const handleRejectOpen = (row) => {
    // console.log("row", row);
    setRejectionId(row._id);
    setRejectOpen(true);
  };

  const handleRejectLeave = () => {
    var rules = {
      required: ["rejectionVal"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ rejectionVal }),
    });

    setErrors(errors);
    var setKey = 0;
    if (!Object.keys(errors).length) {
      console.log(
        "rowreject",
        rejectionId,
        user,
        rejectionVal,
        LEAVE_STATUS_REJECTED,
        "Successfully approved WFH"
      );
      updateWfh(
        rejectionId,
        user,
        rejectionVal,
        LEAVE_STATUS_REJECTED,
        "Successfully rejected leave.",
        changingYear,
        setKey
      );
      handleClose();
    }
  };

  //   console.log("viewshow", viewDetailsShow);
  //   console.log("viewdata", viewDetails);
  return (
    <>
      {rejectionId && (
        <div className="reject_modal">
          <Modal
            show={rejectOpen}
            onHide={handleClose}
            aria-labelledby="reject_modal"
          >
            <Modal.Header>
              <Modal.Title id="reject_modal">Rejection Reason</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <TextArea
                  name="comments"
                  placeholder="Reason..."
                  label="Kindly provide the reason for rejecting the leave."
                  value={rejectionVal}
                  type="textarea"
                  setFocus={1}
                  onChange={(e) => setRejectionVal(e.target.value)}
                  size="fluid"
                  error={errors["rejectionVal"]}
                  isrequired={true}
                />
              </div>
              <Row>
                <Col md={12} lg={6} className="mb-2">
                  <ButtonView
                    onClick={handleClose}
                    title={"Cancel"}
                    styles="danger-outline"
                  />
                </Col>
                <Col md={12} lg={6}>
                  <ButtonView
                    onClick={handleRejectLeave}
                    title={"Submit Rejection"}
                    styles="secondary"
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      )}

      <div>
        {viewDetails && (
          <div className="view_modal">
            <Modal
              show={viewDetailsShow}
              onHide={handleClose}
              aria-labelledby="view_modal"
            >
              <Modal.Header>
                <Modal.Title id="view_modal">Application Detail</Modal.Title>
                <div onClick={handleClose} style={{ cursor: "pointer" }}>
                  <AiOutlineClose size={20} />
                </div>
              </Modal.Header>
              <Modal.Body>
                {viewDetails.status === -1 && (
                  <div>
                    <div className="comments ms-1 mb-3 d-flex justify-content-between align-items-center flex-wrap">
                      <h5>
                        Rejected by{" "}
                        <span style={{ color: "#d87d2e" }}>
                          {viewDetails.lastUpdatedBy}
                        </span>
                      </h5>
                      {/* {viewDetails.masterLeaveType ===
                      "Optional Holiday Leave" && (
                      <h5>
                        <span
                          style={{ fontSize: "15px" }}
                          className="badge badge-success"
                        >
                          Optional Holiday
                        </span>
                      </h5>
                    )} */}
                    </div>
                    {/* {viewDetails.rejectionOrCancellationComments && ( */}
                    <div>
                      <div className="comments ms-1">
                        <h6>Rejection Reason:</h6>
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          // disabled
                          readOnly
                          value={viewDetails.rejectionOrCancellationComments}
                          as="textarea"
                          rows={3}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  // )}
                )}

                {viewDetails.status === -2 && (
                  <div>
                    <div className="comments">
                      <h5>Cancellation Reason:</h5>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={viewDetails.rejectionOrCancellationComments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}

                <div className="comments ms-1 d-flex justify-content-between align-items-center flex-wrap">
                  <h5>
                    Applier:{" "}
                    <span style={{ color: "#d87d2e" }}>
                      {viewDetails.userId[0].firstName}{" "}
                      {viewDetails.userId[0].lastName}
                    </span>
                  </h5>
                  {/* {viewDetails.masterLeaveType === "Optional Holiday Leave" && (
                  <h5>
                    <span
                      style={{ fontSize: "15px" }}
                      className="badge badge-success"
                    >
                      Optional Holiday
                    </span>
                  </h5>
                )} */}
                </div>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    FD/1H/2H
                    <span>{viewDetails.leaveSubType}</span>
                  </li>
                  {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                  Leave Type:
                  <span>{viewDetails.masterLeaveType}</span>
                </li> */}
                  {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sub Leave Type:
                  <span>{viewDetails.leaveType.description}</span>
                </li> */}
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Start Dates:
                    <span>
                      {moment(new Date(viewDetails.startDate)).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    End Dates:
                    <span>
                      {moment(new Date(viewDetails.endDate)).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </li>
                  {viewDetails.comments && (
                    <div className="mt-3">
                      <div className="comments">
                        <h6>Comments:</h6>
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          // disabled
                          readOnly
                          value={viewDetails.comments}
                          as="textarea"
                          rows={3}
                        />
                      </Form.Group>
                    </div>
                  )}
                  {/* {userDocument ? (
                    <div className="d-flex justify-content-end mt-3">
                      <p className="mt-2">Download file</p>
                      <CircularButtonView
                        Icon={AiOutlineArrowDown}
                        onClick={() => {
                          downloadFile(
                            userDocument.employeeId,
                            userDocument._id
                          );
                        }}
                        size={20}
                      />
                    </div>
                  ) : (
                    ""
                  )} */}
                  {viewDetails.status === 0 && (
                    <Row className="mt-3">
                      <Col md={12} lg={6} className="mb-2">
                        <ButtonView
                          onClick={() => handleApproveLeave(viewDetails)}
                          title={"Approve"}
                          styles="secondary"
                        />
                      </Col>
                      <Col md={12} lg={6}>
                        <ButtonView
                          onClick={() => {
                            setViewDetailsShow(false);
                            handleRejectOpen(viewDetails);
                          }}
                          title={"Reject"}
                          styles="danger-outline"
                        />
                      </Col>
                    </Row>
                  )}
                </ul>
              </Modal.Body>
            </Modal>
          </div>
        )}
        {/* {rejectOpen && <Unapproved handleRejectOpen={handleRejectOpen} />} */}
      </div>
    </>
  );
};

export default ModalView;
