import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { RiAdminLine } from "react-icons/ri";

import { Header } from "../../common";
import HolidaysMngt from "./components/HolidaysMngt";
import LeaveTypesMngt from "./components/LeaveTypesMngt";
import LeavesPerYearMngt from "./components/LeavesPerYearMngt";
// import OptionalHolidaysMngt from "./components/OptionalHolidaysMngt";

import TechDomainMngt from "./components/TechDomainMngt";
import DesignationsMngt from "./components/DesignationsMngt";
import ApplicationDomainMngt from "./components/ApplicationDomainMngt";

import LocationMngt from "./components/LocationMngt";
import LunchTypeMngt from "./components/LunchTypeMngt";
import LunchPreferenceOpenPeriod from "./components/LunchPreferenceOpenPeriod";
import DocumentsMgnt from "./components/DocumentsManagement/DocumentsMgnt";

const HRAdministrationView = ({
  addHoliday,
  editHoliday,
  deleteHoliday,
  holidaysList,

  addLeaveType,
  editLeaveType,
  deleteLeaveType,
  leaveTypesList,

  addLeavesPerYear,
  editLeavesPerYear,
  deleteLeavesPerYear,
  leavesPerYearList,

  leaveMasterTypeList,
  currentYear,
  getHolidays,

  techDomainList,
  addTechDomain,
  editTechDomain,
  deleteTechDomain,

  designationList,
  addDesignation,
  editDesignation,
  deleteDesignation,

  aplicationDomainList,
  addApplicationDomain,
  editApplicationDomain,
  deleteApplicationDomain,

  locationList,
  addLocation,
  editLocation,
  deleteLocation,

  lunchTypeList,
  addLunchType,
  editLunchType,
  deleteLunchType,

  addLunchBlockingTime,
  lunchBlockingTimeList,
  editLunchBlockingTime,

  userDocumentsList,
  addDocumentCategory,
  editDocumentCategory,
  getCategoryDocumentList,
  categoryDocumentsList,
  categoryDocumentsListSuccess,
  downloadDocument,
  uploadDocumentForCategory,
  deleteDocument,
}) => {
  return (
    <>
      <Header label={"HRMS Administration"} Icon={RiAdminLine} />
      <div className="main__body padding">
        <Container>
          <div className="admin_heading mt-4 ms-1">
            <h4>Location & Lunch Management:</h4>
          </div>
          <Row className="mt-0">
            <Col lg={6} xl={6}>
              <LocationMngt
                locationList={locationList}
                addLocation={addLocation}
                editLocation={editLocation}
                deleteLocation={deleteLocation}
              />
            </Col>
            <Col lg={6} xl={6}>
              <LunchTypeMngt
                lunchTypeList={lunchTypeList}
                addLunchType={addLunchType}
                editLunchType={editLunchType}
                deleteLunchType={deleteLunchType}
                locationList={locationList}
              />
            </Col>
            <Col lg={6} xl={6}>
              <LunchPreferenceOpenPeriod
                locationList={locationList}
                addLunchBlockingTime={addLunchBlockingTime}
                lunchBlockingTimeList={lunchBlockingTimeList}
                editLunchBlockingTime={editLunchBlockingTime}
              />
            </Col>
          </Row>
          <div className="admin_heading mt-4 ms-1">
            <h4>Leave Management:</h4>
          </div>
          <Row>
            <Col lg={6} xl={6}>
              <HolidaysMngt
                addHoliday={addHoliday}
                editHoliday={editHoliday}
                deleteHoliday={deleteHoliday}
                holidaysList={holidaysList}
                currentYear={currentYear}
                getHolidays={getHolidays}
              />
            </Col>
            <Col lg={6} xl={6}>
              <LeaveTypesMngt
                addLeaveType={addLeaveType}
                editLeaveType={editLeaveType}
                deleteLeaveType={deleteLeaveType}
                leaveTypesList={leaveTypesList}
                leaveMasterTypeList={leaveMasterTypeList}
              />
            </Col>
            <Col lg={6} xl={6}>
              <LeavesPerYearMngt
                addLeavesPerYear={addLeavesPerYear}
                editLeavesPerYear={editLeavesPerYear}
                deleteLeavesPerYear={deleteLeavesPerYear}
                leavesPerYearList={leavesPerYearList}
                leaveMasterTypeList={leaveMasterTypeList}
              />
            </Col>
          </Row>

          <div className="admin_heading mt-4 ms-1">
            <h4>Job Management:</h4>
          </div>
          <Row>
            <Col lg={6} xl={6}>
              <TechDomainMngt
                techDomainList={techDomainList}
                addTechDomain={addTechDomain}
                editTechDomain={editTechDomain}
                deleteTechDomain={deleteTechDomain}
              />
            </Col>
            <Col lg={6} xl={6}>
              <DesignationsMngt
                designationList={designationList}
                addDesignation={addDesignation}
                editDesignation={editDesignation}
                deleteDesignation={deleteDesignation}
              />
            </Col>
            <Col lg={6} xl={6}>
              <ApplicationDomainMngt
                aplicationDomainList={aplicationDomainList}
                addApplicationDomain={addApplicationDomain}
                editApplicationDomain={editApplicationDomain}
                deleteApplicationDomain={deleteApplicationDomain}
              />
            </Col>
          </Row>
          <div className="admin_heading mt-4 ms-1">
            <h4>User Documents:</h4>
          </div>
          <Row>
            <Col lg={6} xl={6}>
              <DocumentsMgnt
                userDocumentsList={userDocumentsList}
                addDocumentCategory={addDocumentCategory}
                editDocumentCategory={editDocumentCategory}
                getCategoryDocumentList={getCategoryDocumentList}
                categoryDocumentsList={categoryDocumentsList}
                categoryDocumentsListSuccess={categoryDocumentsListSuccess}
                downloadDocument={downloadDocument}
                locationList={locationList}
                uploadDocumentForCategory={uploadDocumentForCategory}
                deleteDocument={deleteDocument}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HRAdministrationView;
