// auth
export const LOGIN = "/login";

// app
export const LUNCH = "/lunchpreference";
export const LUNCHPREFERENCE = "/lunchmanagement";
export const USER = "/user";
export const PROFILE = "/profile";
export const SYSTEMMANAGEMENT = "/systemmanagement";
export const MYLEAVES = "/myleaves";
export const LEAVEAPPLICATIONS = "/leaveapplications";
export const LEAVEANAGEMENT = "/leavemanagement";
export const JOBAPPLICATIONS = "/jobapplications";
export const RECRUITMENTMANAGEMENT = "/recruitmentmanagement";
export const INTERVIEWSCHEDULE = "/interviewschedule";
export const LEAVEREPORTING = "/leavereporting";
export const HRADMINISTRATION = "/hradministration";
export const IMPDOCUMENTS = "/important-documents";
export const APPLYFORWFH = "/apply-for-wfh";
export const WFHAPPLICATION = "/WFHApplication";
export const VIEWWFHANDLEAVE = "/View-WFH-Leaves";
export const MYQUERIES = "/my-queries";
export const VIEWQUERIES = "/view-queries";
export const APPLICATIONMANAGER = "/application-manager";
export const MYSKILLS = "/my-skills";
export const SKILLMANAGEMENT = "/skill-management";
export const EMPLOYEESKILLS = "/employee-skills";
// export const JOBMANAGEMENT = "/jobmanagement";
