import { actionTypes } from "./leaveTypesActions";

const { 
  LEAVE_TYPES_PENDING, 
  LEAVE_TYPES_ERROR, 
  LEAVE_TYPES_SUCCESS,

  LEAVE_TYPES_ADD_PENDING,
  LEAVE_TYPES_ADD_ERROR,
  LEAVE_TYPES_ADD_SUCCESS,

  LEAVE_TYPES_EDIT_PENDING,
  LEAVE_TYPES_EDIT_ERROR,
  LEAVE_TYPES_EDIT_SUCCESS,

  LEAVE_TYPES_DELETE_PENDING,
  LEAVE_TYPES_DELETE_ERROR,
  LEAVE_TYPES_DELETE_SUCCESS,
      } = actionTypes;

const initialState = {
  leaveTypesPending: false,
  leaveTypesSuccess: false,
  leaveTypesError: null,

  leaveTypesAddPending: false,
  leaveTypesAddSuccess: false,
  leaveTypesAddError: null,

  leaveTypesEditPending: false,
  leaveTypesEditSuccess: false,
  leaveTypesEditError: null,

  leaveTypesDeletePending: false,
  leaveTypesDeleteSuccess: false,
  leaveTypesDeleteError: null,

  leaveTypesList: [],
};

export default function leaveTypes(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LEAVE_TYPES_PENDING:
    case LEAVE_TYPES_ERROR:
    case LEAVE_TYPES_SUCCESS:
      return Object.assign({}, state, payload);
    case LEAVE_TYPES_ADD_PENDING:
    case LEAVE_TYPES_ADD_ERROR:
      return Object.assign({}, state, payload);
    case LEAVE_TYPES_ADD_SUCCESS: {
      // const newLeaveType = payload.leaveTypes;
      // const newLeaveTypesList = [...state.leaveTypesList, newLeaveType];

      // const newState = {
      //   leaveTypesList: newLeaveTypesList,
      //   leaveTypesAddPending: payload.leaveTypesAddPending,
      //   leaveTypesAddSuccess: payload.leaveTypesAddSuccess,
      //   leaveTypesAddError: payload.leaveTypesAddError,
      // }
      // return Object.assign({}, state, newState);
      return Object.assign({}, state, payload);
    }
    case LEAVE_TYPES_EDIT_PENDING:
    case LEAVE_TYPES_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case LEAVE_TYPES_EDIT_SUCCESS: {
      // const newLeaveTypesList = state.leaveTypesList.map(list => {
      //   if(list._id === payload.leaveTypes._id) {
      //     return payload.leaveTypes
      //   } else {
      //     return list
      //   }
      // })
      // return Object.assign({}, state, {leaveTypesList: newLeaveTypesList}, payload);
      return Object.assign({}, state, payload);
    }
    case LEAVE_TYPES_DELETE_PENDING:
    case LEAVE_TYPES_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case LEAVE_TYPES_DELETE_SUCCESS: {
      const newLeaveTypesList = state.leaveTypesList.filter(list => {
        return list._id !== payload.id
      })
      return Object.assign({}, state, {leaveTypesList: newLeaveTypesList}, payload);
    }
    default:
      return state;
  }
}
