import React from "react";
import { Tabs, Tab } from "react-bootstrap";

// import All from "./All";

import Unapproved from "./Unapproved";

import ApplyNewQuery from "./ApplyNewQuery";
import Resolve from "./Resolve";

const MyQueriesCard = ({
  applyForQuery,
  userQueryList,
  user,
  getUserWfhList,
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
  userWfhListSuccess,
  handleSelect,
  employeeId,
  getUserWfh,
  updateWfh,
  currentYear,

  // onChangePage,
  defaultTabActiveKey,
  cancelGetUserWfhBYId,
  getUserQuery,

  getUserQueryList,
  userQueryListSuccess,
}) => {
  return (
    <div className="card">
      <ApplyNewQuery applyForQuery={applyForQuery} user={user} />
      {/* <ApplyForWfhFormModal
        applyForWfh={applyForWfh}
        userManagerList={userManagerList}
        user={user}
        currentYear={currentYear}
        getUserWfhList={getUserWfhList}
        employeeId={employeeId}
        userWfhListCalender={userWfhListCalender}
        onsetDate={onsetDate}
        onsetYear={onsetYear}
        empData={userCalender.filter(
          (list) => list.status === 0 || list.status === 1
        )}
        updateGetUserWfhData={updateGetUserWfhData}
        cancelGetUserWfhBYId={cancelGetUserWfhBYId}
        changingYear={changingYear}
      /> */}
      <Tabs
        defaultActiveKey={defaultTabActiveKey}
        id="leave_application"
        className="table_tabs"
        // value={key}
        onSelect={handleSelect}
      >
        <Tab
          eventKey="pending"
          title="PENDING"
          // value={0}
        >
          <Unapproved
            getUserWfhList={getUserWfhList}
            userQueryList={userQueryList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            getUserWfh={getUserWfh}
            updateWfh={updateWfh}
            user={user}
            currentYear={currentYear}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
            getUserQuery={getUserQuery}
            getUserQueryList={getUserQueryList}
            userQueryListSuccess={userQueryListSuccess}
          />
        </Tab>

        <Tab eventKey="resolved" title="RESOLVED">
          <Resolve
            userQueryList={userQueryList}
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            // userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            getUserWfh={getUserWfh}
            updateWfh={updateWfh}
            user={user}
            currentYear={currentYear}
            getUserQuery={getUserQuery}
            getUserQueryList={getUserQueryList}
            userQueryListSuccess={userQueryListSuccess}
          />
        </Tab>

        {/* <Tab eventKey={LEAVE_STATUS_REJECTED} title="REJECTED">
          <Rejected
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
            getUserWfh={getUserWfh}
          />
        </Tab> */}
        {/* <Tab eventKey={LEAVE_STATUS_CANCELLED} title="CANCELLED">
          <Cancelled
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            getUserWfh={getUserWfh}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
          />
        </Tab> */}
        {/* <Tab eventKey="" title="ALL">
          <All
            //  data={employeeLeaveList} updateLeave={updateLeave} user={user}
            userQueryList={userQueryList}
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            getUserWfh={getUserWfh}
            getUserQuery={getUserQuery}
            getUserQueryList={getUserQueryList}
            userQueryListSuccess={userQueryListSuccess}
          />
        </Tab> */}
      </Tabs>
    </div>
  );
};

export default MyQueriesCard;
