import React, { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import { ButtonView } from "../../../controls";
import {
  HOLIDAY_TYPE_REGULAR,
  HOLIDAY_TYPE_OPTIONAL,
} from "../../../../constants/env";
import { DataTable } from "../../../common";
import moment from "moment";

const HolidayList = ({ holidaysList }) => {
  // console.log(holidaysList);
  const newHolidayList = holidaysList
    .filter((item) => item.year === new Date().getFullYear())
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.date) - new Date(b.date);
    });

  const regularList = newHolidayList.filter(
    (item) => item.type === HOLIDAY_TYPE_REGULAR
  );
  const optionalList = newHolidayList.filter(
    (item) => item.type === HOLIDAY_TYPE_OPTIONAL
  );

  const [showOptionalList, setShowOptionallist] = useState(false);
  const [showRegularList, setShowRegularlist] = useState(false);

  const columns = [
    {
      name: "Description",
      selector: "description",
      grow: 4,
    },
    {
      name: "Date",
      sortable: true,
      grow: 2,
      cell: (row) => <div>{moment(row.date).format("MMMM Do YYYY")}</div>,
      // cell: (row) => <div>{`${row.day}/${row.month}/${row.year}`}</div>,
    },
  ];

  const handleClose = () => {
    setShowOptionallist(false);
    setShowRegularlist(false);
  };

  return (
    <div className="mt-3">
      <Card>
        <Card.Body>
          <div className="holiday__list">
            <div className="holiday__list--regular mb-3">
              <div>Regular holiday list</div>
              <ButtonView
                onClick={() => {
                  setShowRegularlist(true);
                }}
                title={"View"}
                // styles="secondary"
                size={"small"}
              />
            </div>
            <div className="holiday__list--optional">
              <div>Optional festival holiday list</div>
              <ButtonView
                onClick={() => {
                  setShowOptionallist(true);
                }}
                title={"View"}
                // styles="secondary"
                size={"small"}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={showOptionalList}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            Optional festival holiday list
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            data={optionalList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={10}
            paginationServer={false}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRegularList}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">Regular holiday list</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            data={regularList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={10}
            paginationServer={false}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HolidayList;
