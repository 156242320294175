import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, TextArea } from "../../../controls";

const ApplicationDomainMngt = ({
  aplicationDomainList,
  addApplicationDomain,
  editApplicationDomain,
  deleteApplicationDomain,
}) => {
  const [showAddApplicationDomain, setShowAddApplicationDomain] =
    useState(false);
  const [applicationDomain, setApplicationDomain] = useState("");
  const [description, setDescription] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [applicationDomainId, setApplicationDomainId] = useState("");

  const columns = [
    {
      name: "Application Domain",
      selector: "name",
      sortable: true,
      grow: 4,
    },
    {
      name: "Descripton",
      selector: "extraInfo",
      sortable: true,
      grow: 4,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setApplicationDomain(row.name);
                setDescription(row.extraInfo);
                setShowAddApplicationDomain(true);
                setApplicationDomainId(row._id);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete Application Domain?")) {
                // console.log(row._id);
                deleteApplicationDomain(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleAddHoliday = () => {
    var rules = { required: ["applicationDomain"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ applicationDomain }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const data = { name: applicationDomain, extraInfo: description };
        // console.log(data, applicationDomainId);
        editApplicationDomain(data, applicationDomainId);
        // edit
      } else {
        const data = { name: applicationDomain, extraInfo: description };
        // console.log(data);
        // console.log(applicationDomain, description);
        addApplicationDomain(data);
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddApplicationDomain(false);
    setApplicationDomain("");
    setDescription("");
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Application Domain</div>
              <div className="d-none d-md-block">
                Application Domain Management
              </div>
            </Card.Title>
            <div className="add_ApplicationDomain">
              <ButtonView
                onClick={() => setShowAddApplicationDomain(true)}
                title={"Add"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={aplicationDomainList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddApplicationDomain}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit ApplicationDomain" : "Add ApplicationDomain"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12} className="mb-3">
                <Input
                  name="applicationDomain"
                  label="Application Domain"
                  value={applicationDomain}
                  type="text"
                  placeholder="Add Application Domain"
                  onChange={(e) => setApplicationDomain(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["applicationDomain"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12}>
                <TextArea
                  name="description"
                  label="Description"
                  value={description}
                  type="number"
                  placeholder="Some text here..."
                  onChange={(e) => setDescription(e.target.value)}
                  size="fluid"
                />
              </Col>
              <Col xs={4} className="mt-3">
                <ButtonView
                  onClick={handleAddHoliday}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApplicationDomainMngt;
