import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ButtonView, Select } from "../../../controls";
import { FaFilter } from "react-icons/fa";

const SkillFilter = ({
  userList,
  categoryList,
  TechnologyList,
  ProficiencyList,
  getUserSkillWithFilter,
}) => {
  const userOptions = userList.map((item) => {
    return { value: item._id, label: item.fullName };
  });

  const categoryOptions = categoryList.map((item) => {
    return { value: item._id, label: item.categoryName };
  });

  const technologyOptions = TechnologyList.map((item) => {
    return { value: item._id, label: item.technologyName };
  });

  const proficiencyOptions = ProficiencyList.map((item) => {
    return { value: item._id, label: item.proficiencyName };
  });

  const isCertifiedOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const [clearFilter, setClearFilter] = useState(false);
  const [fields, setfields] = useState({
    userID: "",
    categoryID: "",
    technologyID: "",
    proficiencyID: "",
    isCertified: "",
  });

  const areAllFieldsEmpty = () => {
    for (const key in fields) {
      if (fields[key] !== "") {
        return false;
      }
    }
    return true;
  };

  const data = {
    userId: fields.userID,
    categoryId: fields.categoryID,
    technologyId: fields.technologyID,
    proficiencyId: fields.proficiencyID,
    isCertified: fields.technologyID ? fields.isCertified : undefined,
  };

  useEffect(() => {
    if (clearFilter === true) {
      setfields({
        userID: "",
        categoryID: "",
        technologyID: "",
        proficiencyID: "",
        isCertified: "",
      });
      setClearFilter(false);
    }
    getUserSkillWithFilter({ clearData: true });
    // eslint-disable-next-line
  }, [clearFilter]);

  // console.log(clearFilter);
  // console.log(fields);

  return (
    <Card className="skill_filter">
      <Card.Body>
        <h4 className="filter_heading mt-0 d-block d-md-none">
          FILTER <FaFilter size={20} />
        </h4>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="User"
                  label="User"
                  isSearchable={
                    Object.keys(userOptions).length > 0 ? true : false
                  }
                  placeholder="Select"
                  value={fields.userID ? userOptions.label : ""}
                  options={userOptions}
                  onChange={(val) => {
                    console.log(val);
                    setfields({ ...fields, userID: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                  // error={errors["User"]}
                  // isrequired={true}
                />
              </Col>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="Category"
                  label="Category"
                  isSearchable={
                    Object.keys(categoryOptions).length > 0 ? true : false
                  }
                  placeholder="Select"
                  value={fields.categoryID ? categoryOptions.label : ""}
                  options={categoryOptions}
                  onChange={(val) => {
                    console.log(val);
                    setfields({ ...fields, categoryID: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                  // error={errors["Category"]}
                  // isrequired={true}
                />
              </Col>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="Technology"
                  label="Technology"
                  isSearchable={
                    Object.keys(technologyOptions).length > 0 ? true : false
                  }
                  placeholder="Select"
                  value={fields.technologyID ? technologyOptions.label : ""}
                  options={technologyOptions}
                  onChange={(val) => {
                    console.log(val);
                    setfields({ ...fields, technologyID: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                  // error={errors["Technology"]}
                  // isrequired={true}
                />
              </Col>

              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="Proficiency"
                  label="Proficiency"
                  isSearchable={
                    Object.keys(proficiencyOptions).length > 0 ? true : false
                  }
                  isDisabled={fields.technologyID ? false : true}
                  placeholder="Select"
                  value={fields.proficiencyID ? proficiencyOptions.label : ""}
                  options={proficiencyOptions}
                  onChange={(val) => {
                    setfields({ ...fields, proficiencyID: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                  // error={errors["Proficiency"]}
                  // isrequired={true}
                />
              </Col>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="Certified"
                  label="Certified"
                  isSearchable={false}
                  isDisabled={fields.technologyID ? false : true}
                  value={
                    fields.technologyID
                      ? fields.isCertified
                        ? isCertifiedOptions.find(
                            (option) => option.label === "Yes"
                          )
                        : isCertifiedOptions.find(
                            (option) => option.label === "No"
                          )
                      : null
                  }
                  placeholder={fields.technologyID ? null : "Select"}
                  options={isCertifiedOptions}
                  onChange={(val) => {
                    setfields({ ...fields, isCertified: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                />
              </Col>
            </Row>
          </Col>
          <Col md={12} lg={3} className="mb-3">
            <h4 className="filter_heading text-center d-none d-md-block">
              FILTER <FaFilter size={20} />
            </h4>
            <div className="d-block d-md-none mb-3"></div>
            <Row>
              <Col md={12} className="mb-2">
                <ButtonView
                  onClick={() => {
                    if (areAllFieldsEmpty()) {
                      // console.log("fields are empty");
                      getUserSkillWithFilter({ Empty: true });
                    } else {
                      getUserSkillWithFilter(data);
                    }
                  }}
                  // title={
                  //   Object.values(fields).every((value) => value === "")
                  //     ? "Get Details Without Filters"
                  //     : "Apply Filter"
                  // }
                  title="Apply Filter"
                  styles="secondary"
                />
              </Col>
              <Col md={12}>
                <ButtonView
                  onClick={() => {
                    setClearFilter(true);
                    getUserSkillWithFilter({ clearData: true });
                  }}
                  title={"Clear Filter"}
                  styles="danger-outline"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SkillFilter;
