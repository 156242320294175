import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import connect from "react-redux/es/connect/connect";
import { ButtonView } from "../../../controls";
import { AiFillEye, AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import { FiEdit3 } from "react-icons/fi";
// import FamilyInfo from "./FamilyInfo";
import FamilyInfoView from "./FamilyInfoView";
import { getFamilyMembersInsurance } from "../../../../store/familyInsurance/familyInsuranceActions";
import { DataTable } from "../../../common";

const ViewUser = ({
  getFamilyMembersInsurance,
  familyInsuranceSuccess,
  familyMemberInsuranceList,
  empId,
  data,
  userViewData,
  handleClose,
  userViewShow,
  userManagerOptions,
  roleOptions,
  levelOptions,
  locationOptions,
  handleOpen,
  setUserViewShow,
  handledownloadFile,
  userDocument,

  userSkillList,
}) => {
  // console.log("userDocument", userDocument);

  // console.log(userViewData);

  useEffect(() => {
    if (userViewShow) {
      getFamilyMembersInsurance(empId);
    }
  }, [getFamilyMembersInsurance, userViewShow, empId]);
  // console.log("userViewData", userViewData);

  const [showUserCertificatedModal, setShowUserCertificateModal] =
    useState(false);

  // console.log(userSkillList);
  const dataSet = [
    {
      TechnologyName: "PHP",
      categoryName: "backend",
      proficiencyName: "Beginner",
    },
    {
      TechnologyName: "PHP",
      categoryName: "backend",
      proficiencyName: "Beginner",
    },
    {
      TechnologyName: "PHP",
      categoryName: "backend",
      proficiencyName: "Beginner",
    },
    {
      TechnologyName: "PHP",
      categoryName: "backend",
      proficiencyName: "Beginner",
    },
    {
      TechnologyName: "PHP",
      categoryName: "backend",
      proficiencyName: "Beginner",
    },
    {
      TechnologyName: "node",
      categoryName: "backend",
      proficiencyName: "Beginner",
    },
  ];

  const columns = [
    {
      name: "Technology",
      center: true,
      grow: 4,
      cell: (row) => (
        <span style={{ color: "#d87d2e" }} className="Technology_Title">
          {row.TechnologyName}
        </span>
      ),
    },
    {
      name: "Category",
      center: true,
      grow: 4,
      cell: (row) => <span>{row.categoryName}</span>,
    },
    {
      name: "Proficiency",
      center: true,
      grow: 4,
      cell: (row) => <span>{row.proficiencyName}</span>,
    },
    {
      name: "Certificates",
      center: true,
      grow: 4,
      cell: (row) => (
        <div>
          <AiFillEye
            className="view"
            size={18}
            onClick={() => {
              // console.log(row.certificateId);
              setShowUserCertificateModal(true);
              setUserSkills(false);
            }}
          />
        </div>
      ),
    },
  ];

  const [showUserSkills, setUserSkills] = useState(false);

  const handleCloseForUserSkills = () => {
    setUserSkills(false);
  };

  const handleCloseForUserCertificateModal = () => {
    setShowUserCertificateModal(false);
    setUserSkills(true);
  };

  useEffect(() => {
    if (showUserSkills === true) {
      setUserViewShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseForUserSkills]);

  const CertificateDataSet = [
    {
      certificateName: "php",
      validDate: Date.now().toString(),
      file: "https://s3.ap-south-1.amazonaws.com/ssi-hrms-dev//certificates/64508e7a3d88f5001dd96b50/649abf0dbfb980002033d50a/761f2a35-174e-40ef-a2b9-7cd6fbf28dfe.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5PYU37NM6HG5VL55%2F20230629%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230629T035546Z&X-Amz-Expires=900&X-Amz-Signature=5a2fbd3401be1ad765aadb3220a902b589920ffdcde0fbb5fb141df09b881c6f&X-Amz-SignedHeaders=host",
    },
    {
      certificateName: "nodejs",
      validDate: Date.now(),
      file: "https://s3.ap-south-1.amazonaws.com/ssi-hrms-dev//certificates/64508e7a3d88f5001dd96b50/649abf0dbfb980002033d50a/761f2a35-174e-40ef-a2b9-7cd6fbf28dfe.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5PYU37NM6HG5VL55%2F20230629%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230629T035546Z&X-Amz-Expires=900&X-Amz-Signature=5a2fbd3401be1ad765aadb3220a902b589920ffdcde0fbb5fb141df09b881c6f&X-Amz-SignedHeaders=host",
    },
    {
      certificateName: "React",
      validDate: Date.now(),
      file: "https://s3.ap-south-1.amazonaws.com/ssi-hrms-dev//certificates/64508e7a3d88f5001dd96b50/649abf0dbfb980002033d50a/761f2a35-174e-40ef-a2b9-7cd6fbf28dfe.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5PYU37NM6HG5VL55%2F20230629%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230629T035546Z&X-Amz-Expires=900&X-Amz-Signature=5a2fbd3401be1ad765aadb3220a902b589920ffdcde0fbb5fb141df09b881c6f&X-Amz-SignedHeaders=host",
    },
  ];

  const CertificateColumns = [
    {
      name: "Certificate Name",
      center: true,
      grow: 4,
      cell: (row) => (
        <span style={{ color: "#d87d2e" }}>{row.certificateName}</span>
      ),
    },
    {
      name: "Validity",
      center: true,
      grow: 4,
      cell: (row) => <span>{row.validaDate}</span>,
    },
    {
      name: "Option",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          <ButtonView
            title="Download"
            onClick={
              row.file
            }
          ></ButtonView>
        </span>
      ),
    },
  ];

  return (
    <>
      <Modal
        size="lg"
        show={userViewShow}
        onHide={handleClose}
        aria-labelledby="edit_user_modal"
      >
        <Modal.Header>
          <Modal.Title id="edit_user_modal">User Profile</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="user__profile">
            <div className="user__heading d-flex">
              <div className="profile__img me-3">
                {userViewData?.firstName?.slice(0, 1)}
              </div>
              <div className="profile__detail mt-1">
                <h4 className="mb-1">
                  {userViewData?.firstName} {userViewData?.lastName}{" "}
                  <FiEdit3
                    style={{ cursor: "pointer", color: "#D87D2E" }}
                    onClick={() => {
                      handleOpen(userViewData);
                      setUserViewShow(false);
                    }}
                  />
                </h4>
                <p className="text-muted mb-1">{userViewData?.email}</p>
                <p>Employee No. : {userViewData?.employeeNumber}</p>
              </div>
            </div>
            <div className="employee__information mt-2 ms-2">
              <h5>Employee Information:</h5>
              <Row className="mt-2">
                <Col col={6} sm={6} md={4} lg={4}>
                  <p>Location:</p>
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    {userViewData?.location &&
                      locationOptions
                        .filter(
                          (option) => option.value === userViewData.location[0]
                        )
                        .map((list) => list.label)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  <p>Role:</p>
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    {userViewData?.role &&
                      roleOptions
                        .filter((option) => option.value === userViewData.role)
                        .map((list) => list.label)}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  <p>Level:</p>
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    {userViewData?.level &&
                      levelOptions
                        .filter((option) => option.value === userViewData.level)
                        .map((list) => list.label)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  <p>Manager:</p>
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    {userViewData?.reportingManagerEmployeeId &&
                      userManagerOptions
                        .filter(
                          (option) =>
                            option.value ===
                            userViewData.reportingManagerEmployeeId
                        )
                        .map((list) => list.label)}
                  </p>
                </Col>
              </Row>
            </div>
            <div className="personal__info mt-2 ms-2">
              <h5>Personal Informations:</h5>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  <p>Name:</p>
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    {userViewData?.firstName} {userViewData?.lastName}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  Email:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    <a
                      href={`mailto:${userViewData?.email}`}
                      className="let-talk"
                    >
                      {userViewData?.email}
                    </a>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  Birthday:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    {userViewData?.dob
                      ? moment(userViewData?.dob).format("MMMM Do YYYY")
                      : ""}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  Phone:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    <a href={`tel: ${userViewData?.phoneNumber}`}>
                      {userViewData?.phoneNumber}
                    </a>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  Emergency Contact:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    <a href={`tel: ${userViewData?.emergencyContactNumber}`}>
                      {userViewData?.emergencyContactNumber}
                    </a>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  Joining Date:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    {userViewData?.joiningDate
                      ? moment(userViewData?.joiningDate).format("MMMM Do YYYY")
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  Confirmation Date:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">
                    {userViewData?.confirmationDate
                      ? moment(userViewData?.confirmationDate).format("MMMM Do YYYY")
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row className="mt-2 ">
                <Col col={6} sm={6} md={4} lg={4}>
                  Address 1:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">{userViewData?.addressLine1}</p>
                </Col>
              </Row>
              <Row>
                <Col col={6} sm={6} md={4} lg={4}>
                  Address 2:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">{userViewData?.addressLine2}</p>
                </Col>
              </Row>
              <Row className={`${userViewData?.city} ? "" : "bottom_row"`}>
                <Col col={6} sm={6} md={4} lg={4}>
                  City:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">{userViewData?.city}</p>
                </Col>
              </Row>
              <Row className={`${userViewData?.state} ? "" : "bottom_row"`}>
                <Col col={6} sm={6} md={4} lg={4}>
                  State:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">{userViewData?.state}</p>
                </Col>
              </Row>
              <Row className={`${userViewData?.pinCode} ? "" : "bottom_row"`}>
                <Col col={6} sm={6} md={4} lg={4}>
                  Zip Code:
                </Col>
                <Col col={6} sm={6} md={8} lg={8}>
                  <p className="text-muted">{userViewData?.pinCode}</p>
                </Col>
              </Row>
              {userDocument && (
                <Row>
                  <Col col={6} sm={6} md={4} lg={4}>
                    Insurance Card:
                  </Col>
                  <Col col={6} sm={6} md={4} lg={4}>
                    <p className="text-muted">
                      <ButtonView
                        onClick={() =>
                          handledownloadFile(userDocument.employeeId)
                        }
                        title={"Download Card"}
                        styles="secondary"
                      />
                    </p>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          {/* <hr /> */}
          {familyInsuranceSuccess === true && (
            <>
              <hr />
              <div className="family__info">
                <Row>
                  <h4 className="family__info--title">
                    Family Member Information
                  </h4>
                  {familyMemberInsuranceList.length > 0 ? (
                    <div className="family__info--container mt-3">
                      {familyMemberInsuranceList.map((item, num) => {
                        return (
                          <FamilyInfoView
                            key={item._id}
                            data={item}
                            num={num}
                            empId={empId}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <p>No data available</p>
                  )}
                </Row>
              </div>
            </>
          )}

          {/* {showUserSkills ? (
            <>
              <p>User Skills</p>

              <DataTable
                columns={columns}
                data={dataSet}
                pagination={true}
                paginationPerPage={3}
                paginationServer={false}
              />
            </>
          ) : (
            <div>
              <hr />
              <Row>
                <Col col={12} sm={12} md={12} lg={12}>
                  <p className="text-muted">
                    <ButtonView
                      title={"View User Skills"}
                      onClick={() => {
                        setUserSkills(true);
                      }}
                    />
                  </p>
                </Col>
              </Row>
            </div>
          )} */}

          {showUserSkills ? null : (
            <div>
              <hr />
              <Row>
                <Col col={12} sm={12} md={12} lg={12}>
                  <p className="text-muted">
                    <ButtonView
                      title={"View User Skills"}
                      onClick={() => {
                        setUserSkills(true);
                      }}
                    />
                  </p>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showUserSkills}
        onHide={handleCloseForUserSkills}
        size="lg"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title>User Skill</Modal.Title>
          <div onClick={handleCloseForUserSkills} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={columns}
            data={dataSet}
            pagination={true}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showUserCertificatedModal}
        onHide={handleCloseForUserCertificateModal}
        size="lg"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title>User Skill Certificates</Modal.Title>
          <div
            onClick={handleCloseForUserCertificateModal}
            style={{ cursor: "pointer" }}
          >
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={CertificateColumns}
            data={CertificateDataSet}
            pagination={true}
            paginationPerPage={10}
            paginationServer={false}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

// export default ViewUser;
const mapStateToProps = (state) => ({
  // familyMemberList: state.user.familyMemberList,
  // familyMemberListSuccess: state.user.familyMemberListSuccess,
  familyMemberInsuranceList:
    state.familyInsuranceReducer.familyMemberInsuranceList,
  familyInsuranceSuccess: state.familyInsuranceReducer.familyInsuranceSuccess,
});

export default connect(mapStateToProps, {
  // getFamilyMemberList,
  getFamilyMembersInsurance,
})(ViewUser);
