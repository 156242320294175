import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import "./MyQueriesStyle.scss";

import MyQueriesView from "./MyQueriesView";
import {
  user as getUser,
  // reportingMangerUsersList,
} from "../../../store/user/userActions";

import {
  applyForQuery,
  userQueryList as getUserQueryList,
  getUserQuery,
  ModalNullGetQueryData,
  addMessageToQuery,
  addStatusToQuery,
} from "../../../store/queries/queryAction";
import { Loader } from "../../common";

export class MyQueriesContainer extends Component {
  state = {
    defaultTabActiveKey: "pending",
  };
  constructor(props) {
    super(props);

    this.props.getUser();

    this.props.getUserQueryList({
      pageNumber: 1,
      setKey: 1,
      status: "pending",
      limit: 7,
    });

    if (this.props.getUserQueryData) {
      this.props.ModalNullGetQueryData();
    }
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  handleSelect = (eventKey) => {
    this.setState({
      defaultTabActiveKey: eventKey,
    });
    // console.log("eventKey", eventKey);
    if (eventKey === "pending") {
      this.props.getUserQueryList({
        pageNumber: 1,
        status: "pending",
        limit: 7,
      });
    } else if (eventKey === "resolved") {
      this.props.getUserQueryList({
        pageNumber: 1,
        status: "resolved",
        limit: 7,
      });
    } else if (eventKey === "") {
      this.props.getUserQueryList({ pageNumber: 1, limit: 7 });
    }
  };

  onsetDefaultTabActiveKey = (data) => {
    console.log("data", data);
    this.setState({
      defaultTabActiveKey: data,
    });
  };

  render() {
    const {
      user,

      ModalNullGetQueryData,

      getUserQueryData,
      addMessageToQuery,
      getUserQueryList,
      userQueryList,
      applyForQuery,
      getUserQuery,
      limit,
      totalDocsData,
      addStatusToQuery,
      page,
      userQueryListSuccess,
    } = this.props;
    // console.log("defaultTabActiveKey", defaultTabActiveKey);
    if (userQueryListSuccess === false) {
      return <Loader />;
    } else {
      return (
        <MyQueriesView
          applyForQuery={applyForQuery}
          user={user}
          userQueryList={userQueryList}
          getUserQueryList={getUserQueryList}
          getUserQuery={getUserQuery}
          getUserQueryData={getUserQueryData}
          ModalNullGetQueryData={ModalNullGetQueryData}
          onChangePage={this.handlePageChange}
          defaultTabActiveKey={this.state.defaultTabActiveKey}
          onsetDefaultTabActiveKey={this.onsetDefaultTabActiveKey}
          currentPage={page}
          rowsPerPage={limit}
          rowCount={totalDocsData}
          handleSelect={this.handleSelect}
          addMessageToQuery={addMessageToQuery}
          userQueryListSuccess={userQueryListSuccess}
          addStatusToQuery={addStatusToQuery}
          // deleteLunchPreference={deleteLunchPreference}
        />
        //   <MyQueriesView
      );
    }
  }
}

MyQueriesContainer.propTypes = {
  user: PropTypes.object,
  //   lunchTypeList: PropTypes.array,
  //   selectedLunchType: PropTypes.string,
};

const mapStateToProps = (state) => {
  // console.log("state", state.employeeQueryReducer.userQueryListSuccess);
  return {
    user: state.user.user,
    userQueryList: state.employeeQueryReducer.userQueryList,
    userQueryListSuccess: state.employeeQueryReducer.userQueryListSuccess,
    getUserQueryData: state.employeeQueryReducer.getUserQueryData,
    limit: state.employeeQueryReducer.limitt,
    totalDocsData: state.employeeQueryReducer.totalDocsData,
    page: state.employeeQueryReducer.page,
    //   lunchTypeList: state.lunchType.lunchTypeList,
    //   selectedLunchType: state.lunchType.selectedLunchType,
    //   lunchTypeSuccess: state.lunchType.lunchTypeSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      applyForQuery: applyForQuery,
      getUserQuery: getUserQuery,
      //   showAlert: showAlert,
      ModalNullGetQueryData: ModalNullGetQueryData,
      getUserQueryList: getUserQueryList,
      getUser: getUser,
      addMessageToQuery: addMessageToQuery,
      addStatusToQuery: addStatusToQuery,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MyQueriesContainer);
