import React, { useEffect, useState } from "react";
import { Container, Row, Accordion } from "react-bootstrap";
import { BiBook } from "react-icons/bi";

import { Header } from "../../common";
import CategoryTypes from "./components/CategoryTypes";

const ImpDocumentsView = ({
  userDocumentsList,
  loginUserData,
  getCategoryDocumentList,
  categoryDocumentsListSuccess,
  categoryDocumentsList,
  downloadDocument,
}) => {
  useEffect(() => {
    getCategoryDocumentList("", loginUserData.location[0]._id);
  }, [loginUserData, getCategoryDocumentList]);

  const [activeId, setActiveId] = useState("");

  function toggleActive(id) {
    // console.log("id", id);
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <>
      <Header label={"Important Documents"} Icon={BiBook} />
      <div className="user_profile main__body padding imp__document">
        <Container>
          <Row>
            <Accordion defaultActiveKey={activeId}>
              {userDocumentsList.map((item, index) => {
                // console.log("index", index);
                return (
                  <CategoryTypes
                    key={item._id}
                    data={item}
                    index={index + 1}
                    activeId={activeId}
                    toggleActive={toggleActive}
                    categoryDocumentsListSuccess={categoryDocumentsListSuccess}
                    categoryDocumentsList={categoryDocumentsList}
                    downloadDocument={downloadDocument}
                  />
                );
              })}
              {userDocumentsList.length === 0 && (
                <div className="text-center mt-3">
                  <h4>No data to display</h4>
                </div>
              )}
            </Accordion>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ImpDocumentsView;
