import React, { useState } from "react";
import { IoPerson } from "react-icons/io5";
import { MdLocalDining } from "react-icons/md";
import { Col, Card } from "react-bootstrap";

import { ButtonView, Select } from "../../../controls";

const CardContent = ({
  lunch,
  onlunchPreference,
  allLunchTypeList,
  deleteLunchPreference,
}) => {
  // console.log("lunch", lunch);
  const [edit, setEdit] = useState(false);
  const [lunchTypeId, setLunchTypeId] = useState(lunch.lunchPreference._id);
  const [lunchTypeName, setLunchTypeName] = useState(
    lunch.lunchPreference.lunchType
  );
  // console.log("edit", edit);
  var lunchTypeOptions = [{ value: "No Tiffin", label: "No Tiffin" }];
  // console.log(allLunchTypeList)
  allLunchTypeList.forEach((lunchType) => {
    if (lunchType.location.indexOf(lunch.employeeId.location[0]) !== -1) {
      lunchTypeOptions.push({
        value: lunchType._id,
        label: lunchType.lunchType,
      });
    }
  });
  // console.log(lunchTypeOptions)

  const selectLunchType = () => {
    const lunchPreference = allLunchTypeList.find((lunchType) => {
      return lunchTypeId === lunchType._id;
    });
    if (lunchPreference) {
      onlunchPreference(lunchPreference, lunch.employeeId._id);
    } else {
      deleteLunchPreference(lunch.employeeId._id, false);
    }
    setEdit(false);
  };

  return (
    <Col md={6} lg={6} xl={3} className="col-xxl-3">
      <div className="lunch_managament_card">
        <Card className="mt-3">
          <Card.Body>
            <Card.Title className="text-center">
              <span className="card_icon fw-bold">
                <IoPerson /> {lunch.employeeId.userId[0].firstName}{" "}
                {lunch.employeeId.userId[0].lastName}
              </span>
            </Card.Title>
            {!edit ? (
              <Card.Text className="text-muted text-center">
                <MdLocalDining /> {lunchTypeName}
              </Card.Text>
            ) : (
              <div className="select_lunch d-flex align-items-center">
                <Select
                  isSearchable={false}
                  defaultValue={lunchTypeOptions.find(
                    (option) => option.label === lunch.lunchPreference.lunchType
                  )}
                  placeholder="Select Lunch Type"
                  options={lunchTypeOptions}
                  className="select"
                  classNamePrefix="select"
                  onChange={(selectedOption) => {
                    setLunchTypeId(selectedOption.value);
                    setLunchTypeName(selectedOption.label);
                  }}
                />
                <div
                  style={{ marginTop: "4px" }}
                  className="ms-2"
                  onClick={() => {
                    setEdit(false);
                    setLunchTypeName(lunch.lunchPreference.lunchType);
                  }}
                >
                  {/* <AiOutlineClose size={20} /> */}
                  <ButtonView
                    onClick={() => {}}
                    title="Cancel"
                    styles="danger-outline"
                  />
                </div>
              </div>
            )}
            <hr />
            <div className="edit_lunch">
              {!edit ? (
                <ButtonView onClick={() => setEdit(true)} title="Edit" />
              ) : (
                <ButtonView
                  onClick={selectLunchType}
                  title="Save"
                  styles="secondary"
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};

export default CardContent;
