import React from "react";
import { IoApps } from "react-icons/io5";
import { Header } from "../../common";
import ApplicationManagerCard from "./components/ApplicationMangerCard";

const ApplicationManagerView = ({
  user,
  applicationTypeList,
  onOpenApplicationClicked,
  onApplicationManager,
  showAlert,
  applications,
  // deleteApplicationManager,
}) => {
  console.log("inside application manager view");
  return (
    <>
      <Header label={"Application Manager"} Icon={IoApps} />
      <ApplicationManagerCard
        user={user}
        applicationTypeList={applicationTypeList}
        onOpenApplicationClicked={onOpenApplicationClicked}
        onApplicationManager={onApplicationManager}
        showAlert={showAlert}
        applications={applications}
        // deleteApplicationManager={deleteApplicationManager}
      />
    </>
  );
};

export default ApplicationManagerView;
