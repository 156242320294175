import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input } from "../../../controls";

const PermissionMngt = ({
  addPermission,
  editPermission,
  deletePermission,
  permissionList,
}) => {
  // console.log(permissionList);
  const [showAddPermission, setShowAddPermission] = useState(false);
  const [permission, setPermission] = useState("");
  const [permissionDisplay, setPermissionDisplay] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  // const [permissionId, setPermissionId] = useState("");

  const columns = [
    {
      name: "Permission Name",
      selector: "displayName",
      sortable: true,
      grow: 8,
    },
    // {
    //   name: "Display name",
    //   selector: "name",
    //   sortable: true,
    //   grow: 8,
    // },
    // {
    //   name: "Edit",
    //   center: true,
    //   minWidth: "150px",
    //   grow: 2,
    //   cell: (row) => (
    //     <div className="d-flex">
    //       {/* <div className="me-1">
    //         <ButtonView
    //           onClick={() => {
    //             setShowEdit(true)
    //             setPermission(row.name)
    //             setPermissionDisplay(row.displayName)
    //             setShowAddPermission(true)
    //             setPermissionId(row._id)
    //           }}
    //           title={"Edit"}
    //           size="small"
    //           styles="secondary"
    //         />
    //       </div> */}
    //       <ButtonView
    //         onClick={() => {
    //           if (window.confirm("Delete permission?")) {
    //             // console.log(row._id)
    //             deletePermission(row._id);
    //           }
    //         }}
    //         title={"Delete"}
    //         size="small"
    //         styles="danger-outline"
    //       />
    //     </div>
    //   ),
    // },
  ];

  const handleAddRole = () => {
    var rules = { required: ["permission"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ permission, permissionDisplay }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        // editPermission(permission, permissionDisplay, permissionId)
        // edit
      } else {
        let text1 = permission;

        let text2 = text1.replace(/ /g, "");
        // console.log(text2);
        // console.log(permission);
        addPermission(text2, permission);
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddPermission(false);
    setPermission("");
    setPermissionDisplay("");
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Permission</div>
              <div className="d-none d-md-block">Permission Management</div>
            </Card.Title>
            <div className="add_permission">
              <ButtonView
                onClick={() => setShowAddPermission(true)}
                title={"Add Permission"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={permissionList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={2}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddPermission}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Permission" : "Add Permission"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="d-flex mb-2 justify-content-end">
              <Col xs={12} className="mb-3">
                <Input
                  name="permission"
                  label="Permission Name"
                  value={permission}
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setPermission(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["permission"]}
                  isrequired={true}
                />
              </Col>
              {/* <Col xs={12}>
                <Input
                  name="permissionDisplay"
                  label="Write full name with space:"
                  value={permissionDisplay}
                  type="text"
                  placeholder="Display Name"
                  onChange={(e) => setPermissionDisplay(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["permissionDisplay"]}
                  isrequired={true}
                />
              </Col> */}
              <Col xs={4}>
                <ButtonView
                  onClick={handleAddRole}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PermissionMngt;
