import React from "react";
import { MdOutlineQuestionAnswer } from "react-icons/md";
// import { BsTable } from "react-icons/bs";
import { Header } from "../../common";
import MyQueriesCard from "./components/MyQueriesCard";
import { Col, Container, Row } from "react-bootstrap";
import ModalView from "./components/ModalView";
const MyQueriesView = ({
  applyForQuery,
  user,
  userQueryList,
  getUserQuery,
  ModalNullGetQueryData,
  handleSelect,
  getUserQueryData,
  onChangePage,
  rowsPerPage,
  currentPage,
  rowCount,
  getUserQueryList,
  addMessageToQuery,
  userQueryListSuccess,
  defaultTabActiveKey,
  onsetDefaultTabActiveKey,
  addStatusToQuery,
}) => {
  // console.log("defaultTabActiveKey", defaultTabActiveKey);

  return (
    <>
      <Header label={"My Queries"} Icon={MdOutlineQuestionAnswer} />

      <>
        <div className="main__body padding">
          <Container>
            <Row>
              <Col lg={12} xl={12} className="col-xxl-12">
                <div className="column_divider mt-3">
                  <div className="my_leave_applications">
                    {/* {defaultTabActiveKey && ( */}
                    <MyQueriesCard
                      user={user}
                      getUserQuery={getUserQuery}
                      userQueryList={userQueryList}
                      applyForQuery={applyForQuery}
                      handleSelect={handleSelect}
                      onChangePage={onChangePage}
                      rowsPerPage={rowsPerPage}
                      rowCount={rowCount}
                      currentPage={currentPage}
                      getUserQueryList={getUserQueryList}
                      addMessageToQuery={addMessageToQuery}
                      userQueryListSuccess={userQueryListSuccess}
                      defaultTabActiveKey={defaultTabActiveKey}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <ModalView
            getUserQueryData={getUserQueryData}
            ModalNullGetQueryData={ModalNullGetQueryData}
            addMessageToQuery={addMessageToQuery}
            defaultTabActiveKey={defaultTabActiveKey}
            addStatusToQuery={addStatusToQuery}
          />
        </div>
      </>
    </>
  );
};

export default MyQueriesView;
