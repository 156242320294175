import React from "react";
import ReactExport from "react-export-excel";
import { ButtonView } from "../../../controls";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const EmployeeDownload = ({ filteredData }) => {
  const dataSet = filteredData;
  console.log("filteredData", filteredData);
  const groupedData = dataSet.reduce((accumulator, current) => {
    const userName = current.userId.firstName;
    if (!accumulator[userName]) {
      accumulator[userName] = [];
    }
    accumulator[userName].push(current);
    return accumulator;
  }, {});

  // console.log("GroupedData:", groupedData);

  return (
    <ExcelFile
      element={
        <div style={{ width: "150px" }} className="me-3">
          <ButtonView onClick={() => {}} title={"Export To Excel"} />
        </div>
      }
      filename={`Employee skills list`}
      bold={true}
    >
      {Object.keys(groupedData).map((item) => {
        return (
          <ExcelSheet data={Object.values(groupedData[item])} name={item}>
            <ExcelColumn
              label="First Name"
              value={(col) => col?.userId?.firstName}
            />
            <ExcelColumn
              label="Last Name"
              value={(col) =>
                col?.userId?.lastName ? col.userId?.lastName : "--"
              }
            />
            <ExcelColumn label="Category" value={(col) => col?.categoryName} />
            <ExcelColumn
              label="Technology"
              value={(col) => col?.technologyName}
            />
            <ExcelColumn
              label="Proficiency"
              value={(col) => col?.proficiencyId?.proficiencyName || "-"}
            />
            <ExcelColumn
              label="Certified"
              value={(col) => (col?.certificateId?.length > 0 ? "Yes" : "No")}
            />
          </ExcelSheet>
        );
      })}
      {/* <ExcelSheet data={dataSet} name="Employees skills List">
        <ExcelColumn label="Fitst Name" value={(col) => col.userId.firstName} />
        <ExcelColumn
          label="Last Name"
          value={(col) => (col.userId.lastName ? col.userId.lastName : "--")}
        />
        <ExcelColumn label="Category" value={(col) => col.categoryName} />
        <ExcelColumn label="Technology" value={(col) => col.technologyName} />
        <ExcelColumn
          label="Proficienct"
          value={(col) => col.proficiencyId.proficiencyName}
        />
      </ExcelSheet> */}
    </ExcelFile>
  );
};

export default EmployeeDownload;
