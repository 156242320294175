import React from "react";
import ReactExport from "react-export-excel";
import { ButtonView } from "../../../controls";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const LeaveListDownload = (props) => {
  const dataSet1 = props.dateRangeLeaveList;

  // console.log("props", props);
  return (
    <ExcelFile
      element={
        <div style={{ width: "150px" }} className="me-3">
          <ButtonView onClick={() => {}} title={"Export To Excel"} />
        </div>
      }
      filename={`Employee leave list`}
      bold={true}
    >
      <ExcelSheet data={dataSet1} name="Employee">
        <ExcelColumn label="Employee Id" value="employeeNumber" />
        <ExcelColumn
          label="Employee Name"
          value={(col) => `${col.employeeFirstName} ${col.employeeLastName}`}
        />

        {/* <ExcelColumn label="No of Leave Taken" value="totalApproveLeaveDays" /> */}
        <ExcelColumn
          label="approveLeaveDetail"
          value={(col) => {
            // console.log("item", typeof col.employeeNumber);
            const approveLeave = col.approveLeaveDetail.map((item) => {
              const { totalDays } = item;
              var formattedTotalDays = "";
              if (totalDays && totalDays < 1) formattedTotalDays = `Half Day`;
              if (totalDays > 1) formattedTotalDays = `${totalDays} Days`;
              if (totalDays === 1) formattedTotalDays = `${totalDays} Day`;
              var startDate = moment(new Date(item.startDate)).format(
                "DD/MM/YYYY"
              );

              var endDate = moment(new Date(item.endDate)).format("DD/MM/YYYY");

              return `${startDate} - ${endDate} (${formattedTotalDays})`;
            });
            return approveLeave.join(",");
          }}
        />

        <ExcelColumn
          label="Optional Holiday Leave"
          value={(col) => {
            // console.log("item", typeof col.employeeNumber);
            const optionalLeave = col.optionalLeaveDetail.map((item) => {
              var startDate = moment(new Date(item.startDate)).format(
                "DD/MM/YYYY"
              );
              // console.log("xyz", typeof startDate);
              var endDate = moment(new Date(item.endDate)).format("DD/MM/YYYY");
              // return moment(new Date(item.startDate))
              //   .format("DD/MM/YYYY")
              //   .toString();
              return `${startDate} - ${endDate}`;
            });
            return optionalLeave.join(",");
          }}
        />
        <ExcelColumn
          label="Approval Pending"
          value={(col) => {
            // console.log("item", typeof col.employeeNumber);
            const pendingLeave = col.pendingApprovalDetail.map((item) => {
              var startDate = moment(new Date(item.startDate)).format(
                "DD/MM/YYYY"
              );
              // console.log("xyz", typeof startDate);
              var endDate = moment(new Date(item.endDate)).format("DD/MM/YYYY");
              // return moment(new Date(item.startDate))
              //   .format("DD/MM/YYYY")
              //   .toString();
              return `${startDate} - ${endDate}`;
            });
            return pendingLeave.join(",");
          }}
        />
        <ExcelColumn label="" value="" />
      </ExcelSheet>
    </ExcelFile>
  );
};

// import React, { Component } from "react";
// import ReactExport from "react-data-export";
// // import ReactExport from "react-export-excel";
// import moment from "moment";
// import { ButtonView } from "../../../controls";
// // import './App.css';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

// class LeaveListDownload extends Component {
//   render() {
//     const dataSet1 = this.props.dateRangeLeaveList;

//     var approveLeave = dataSet1.map((item) => {
//       console.log("item", item);
//       var approveDetailList = "";
//       item.approveLeaveDetail.forEach((element) => {
//         var startDate = moment(new Date(element.startDate)).format(
//           "DD/MM/YYYY"
//         );
//         var endDate = moment(new Date(element.endDate)).format("DD/MM/YYYY");

//         approveDetailList = `${approveDetailList} ${startDate}-${endDate},`;
//       });
//       console.log("approveDetailList", approveDetailList);
//       return {
//         ...item,
//         approveLeaveDetail: approveDetailList,
//       };
//     });

//     var optionalLeave = approveLeave.map((item) => {
//       console.log("item", item);
//       var optionalLeaveDetail = "";
//       item.optionalLeaveDetail.forEach((element) => {
//         var startDate = moment(new Date(element.startDate)).format(
//           "DD/MM/YYYY"
//         );
//         var endDate = moment(new Date(element.endDate)).format("DD/MM/YYYY");

//         optionalLeaveDetail = `${optionalLeaveDetail} ${startDate}-${endDate},`;
//       });
//       return {
//         ...item,
//         // ...approveLeave,
//         optionalLeaveDetail: optionalLeaveDetail,
//       };
//     });

//     var pendingLeave = optionalLeave.map((item) => {
//       console.log("item", item);
//       var pendingApprovalDetail = "";
//       item.pendingApprovalDetail.forEach((element) => {
//         var startDate = moment(new Date(element.startDate)).format(
//           "DD/MM/YYYY"
//         );
//         var endDate = moment(new Date(element.endDate)).format("DD/MM/YYYY");

//         pendingApprovalDetail = `${pendingApprovalDetail} ${startDate}-${endDate},`;
//       });
//       return {
//         ...item,
//         // ...approveLeave,
//         pendingApprovalDetail: pendingApprovalDetail,
//       };
//     });

//     const DataSet = [
//       {
//         columns: [
//           { title: "Employee Id", width: { wpx: 90 } }, //pixels width
//           { title: "Employee Name", width: { wch: 25 } }, //char width
//           { title: "approveLeaveDetail", width: { wch: 25 } },
//           { title: "optionalLeaveDetail", width: { wpx: 180 } },
//           { title: "Approval Pending", width: { wpx: 180 } },
//           { title: "", width: { wpx: 45 } },
//         ],

//         data: pendingLeave.map((item) => [
//           { value: item.employeeNumber },
//           {
//             value: `${item.employeeFirstName} ${item.employeeLastName}`,
//           },
//           { value: item.approveLeaveDetail },
//           {
//             value: item.optionalLeaveDetail,
//           },
//           { value: item.pendingApprovalDetail },
//           { value: "" },
//         ]),
//       },
//     ];

//     return (
//       <div>
//         <ExcelFile
//           element={
//             <div style={{ width: "150px" }} className="me-3">
//               <ButtonView onClick={() => {}} title={"Export To Excel"} />
//             </div>
//           }
//           filename={`Employee leave list`}
//           bold={true}
//         >
//           <ExcelSheet dataSet={DataSet} name="Employee" />
//         </ExcelFile>
//       </div>
//     );
//   }
// }

export default LeaveListDownload;
