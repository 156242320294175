import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Card } from "react-bootstrap";

import {
  HOLIDAY_TYPE_REGULAR,
  LEAVE_STATUS_PENDING_APPROVAL,
  LEAVE_STATUS_APPROVED,
} from "../../../../constants/env";

const Calender = ({
  holidaysList,

  userCalender,
  onsetDate,
  onsetDescription,
  combineCalenderData,
  srollToSummary,
}) => {
  var calenderDataWfh = [];

  holidaysList.filter((list) => list.type === HOLIDAY_TYPE_REGULAR);
  // .forEach((i) => {
  //   calenderDataWfh.push({
  //     title: i.description,
  //     date: i.date,
  //     backgroundColor: "#CC0066",
  //     borderColor: "#CC0066",
  //   });
  //   calenderDataWfh.push({
  //     title: i.description,
  //     date: i.date,
  //     backgroundColor: "#CC0066",
  //     borderColor: "#CC0066",
  //   });
  // });

  const pending = userCalender.filter(
    (list) => list.status === LEAVE_STATUS_PENDING_APPROVAL
  );
  const approved = userCalender.filter(
    (list) => list.status === LEAVE_STATUS_APPROVED
  );

  approved
    .filter(
      (list, index, self) =>
        index === self.findIndex((i) => i.startDate === list.startDate)
    )
    .forEach((i) => {
      calenderDataWfh.push({
        title: "",
        date: i.startDate.slice(0, 10),
        backgroundColor: "#009900",
        borderColor: "#009900",
      });
    });

  pending
    .filter(
      (list, index, self) =>
        index === self.findIndex((i) => i.startDate === list.startDate)
    )
    .forEach((i) => {
      calenderDataWfh.push({
        title: "",
        date: i.startDate.slice(0, 10),
        backgroundColor: "#004C99",
        borderColor: "#004C99",
      });
    });
  const onDayClick = (e) => {
    const data = combineCalenderData.filter((list) => {
      return list.startDate.slice(0, 10) === e.dateStr;
    });
    onsetDate(e.dateStr);
    if (data) {
      onsetDescription(data);
    } else {
      onsetDescription([]);
    }
    if (srollToSummary.current) {
      srollToSummary.current.scrollIntoView({
        //  behavior: "smooth",
      });
    }
  };

  return (
    <Card className="event_calender">
      <Card.Body>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            start: "prev",
            center: "title",
            end: "next",
          }}
          dateClick={onDayClick}
          initialView="dayGridMonth"
          events={
            calenderDataWfh
            // [
            // { title: '', date: '2021-09-16', backgroundColor: '#CC0066', borderColor: "#CC0066" },
            // { title: '', date: '2021-09-12', backgroundColor: '#004C99', borderColor: "#004C99" },
            // // { title: '', date: '2021-09-05', backgroundColor: '#009900', borderColor: "#009900" },
            // // { title: '', date: '2021-09-29', backgroundColor: '#FF3333', borderColor: "#FF3333" },
            // ]
          }
          //   editable={true}
          selectable={true}
          contentHeight={"auto"}
          // aspectRatio={0.7}
        />
      </Card.Body>
    </Card>
  );
};

export default Calender;
