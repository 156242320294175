import { actionTypes } from "./leavesPerYearActions";

const { 
  LEAVES_PER_YEAR_PENDING, 
  LEAVES_PER_YEAR_ERROR, 
  LEAVES_PER_YEAR_SUCCESS,

  LEAVES_PER_YEAR_ADD_PENDING,
  LEAVES_PER_YEAR_ADD_ERROR,
  LEAVES_PER_YEAR_ADD_SUCCESS,

  LEAVES_PER_YEAR_EDIT_PENDING,
  LEAVES_PER_YEAR_EDIT_ERROR,
  LEAVES_PER_YEAR_EDIT_SUCCESS,

  LEAVES_PER_YEAR_DELETE_PENDING,
  LEAVES_PER_YEAR_DELETE_ERROR,
  LEAVES_PER_YEAR_DELETE_SUCCESS,
      } = actionTypes;

const initialState = {
  leavesPerYearPending: false,
  leavesPerYearSuccess: false,
  leavesPerYearError: null,

  leavesPerYearAddPending: false,
  leavesPerYearAddSuccess: false,
  leavesPerYearAddError: null,

  leavesPerYearEditPending: false,
  leavesPerYearEditSuccess: false,
  leavesPerYearEditError: null,

  leavesPerYearDeletePending: false,
  leavesPerYearDeleteSuccess: false,
  leavesPerYearDeleteError: null,

  leavesPerYearList: [],
};

export default function leavesPerYear(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LEAVES_PER_YEAR_PENDING:
    case LEAVES_PER_YEAR_ERROR:
    case LEAVES_PER_YEAR_SUCCESS:
      return Object.assign({}, state, payload);
    case LEAVES_PER_YEAR_ADD_PENDING:
    case LEAVES_PER_YEAR_ADD_ERROR:
      return Object.assign({}, state, payload);
    case LEAVES_PER_YEAR_ADD_SUCCESS: {
      // const newLeavesPerYear = payload.leavesPerYear;
      // const newLeavesPerYearList = [...state.leavesPerYearList, newLeavesPerYear];

      // const newState = {
      //   leavesPerYearList: newLeavesPerYearList,
      //   leavesPerYearAddPending: payload.leavesPerYearAddPending,
      //   leavesPerYearAddSuccess: payload.leavesPerYearAddSuccess,
      //   leavesPerYearAddError: payload.leavesPerYearAddError,
      // }
      // return Object.assign({}, state, newState);
      return Object.assign({}, state, payload);
    }
    case LEAVES_PER_YEAR_EDIT_PENDING:
    case LEAVES_PER_YEAR_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case LEAVES_PER_YEAR_EDIT_SUCCESS: {
      // const newLeavesPerYearList = state.leavesPerYearList.map(list => {
      //   if(list._id === payload.leavesPerYear._id) {
      //     return payload.leavesPerYear
      //   } else {
      //     return list
      //   }
      // })
      // return Object.assign({}, state, {leavesPerYearList: newLeavesPerYearList}, payload);
      return Object.assign({}, state, payload);
    }
    case LEAVES_PER_YEAR_DELETE_PENDING:
    case LEAVES_PER_YEAR_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case LEAVES_PER_YEAR_DELETE_SUCCESS: {
      const newLeavesPerYearList = state.leavesPerYearList.filter(list => {
        return list._id !== payload.id
      })
      return Object.assign({}, state, {leavesPerYearList: newLeavesPerYearList}, payload);
    }
    default:
      return state;
  }
}
