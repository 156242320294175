import { globals } from "../../store/globals";
import decode from "jwt-decode";

class Auth {
  constructor() {
    this.authenticated = true;
  }

  isAuthenticated() {
    if (!globals.store.getState().auth.token) {
      this.authenticated = false;
    } else {
      let decodedData = decode(
        JSON.parse(window.localStorage.getItem("token"))
      );
      let current_time = Date.now() / 1000;
      if (decodedData.exp - 3600 < current_time) {
        this.authenticated = false;
      } else {
        this.authenticated = true;
      }
    }
    return this.authenticated;
  }
}

// eslint-disable-next-line
export default new Auth();
// if (

//   list.status === 1
// ) {
//   // console.log("leaveFormatted", list);
//   if ( list.leaveType.description ===
//     "Optional Holiday Leave") {
//     leaveFormatted = `on ${list.leaveType.description}`;
//   }
//   // leaveFormatted = `on ${list.leaveType.description}`;
// } else if (
//   list.leaveType.description !==
//   "Optional Holiday Leave"
// ) {
//   leaveFormatted = `on ${list.leaveSubType} leave`;
// }
