import Api from "../../service/api";
import { globals } from "../globals";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";

export const actionTypes = {
  FAMILY_INSURANCE_PENDING: " FAMILY_INSURANCE_PENDING",
  FAMILY_INSURANCE_SUCCESS: " FAMILY_INSURANCE_SUCCESS",
  FAMILY_INSURANCE_ERROR: " FAMILY_INSURANCE_ERROR",

  ADD_FAMILY_INSURANCE_PENDING: "ADD_FAMILY_INSURANCE_PENDING",
  ADD_FAMILY_INSURANCE_SUCCESS: "  ADD_FAMILY_INSURANCE_SUCCESS",
  ADD_FAMILY_INSURANCE_ERROR: "  ADD_FAMILY_INSURANCE_ERROR",

  UPDATE_FAMILY_INSURANCE_PENDING: "UPDATE_FAMILY_INSURANCE_PENDING",
  UPDATE_FAMILY_INSURANCE_SUCCESS: "UPDATE_FAMILY_INSURANCE_SUCCESS",
  UPDATE_FAMILY_INSURANCE_ERROR: "UPDATE_FAMILY_INSURANCE_ERROR",

  DELETE_FAMILY_INSURANCE_PENDING: "DELETE_FAMILY_INSURANCE_PENDING",
  DELETE_FAMILY_INSURANCE_SUCCESS: "DELETE_FAMILY_INSURANCE_SUCCESS",
  DELETE_FAMILY_INSURANCE_ERROR: "DELETE_FAMILY_INSURANCE_ERROR",

  GET_FAMILY_INSURANCEDOC_PENDING: "GET_FAMILY_INSURANCEDOC_PENDING",
  GET_FAMILY_INSURANCEDOC_SUCCESS: "GET_FAMILY_INSURANCEDOC_SUCCESS",
  GET_FAMILY_INSURANCEDOC_ERROR: "GET_FAMILY_INSURANCEDOC_ERROR",

  FAMILY_INSURANCEDOC_PENDING: "FAMILY_INSURANCEDOC_PENDING",
  FAMILY_INSURANCEDOC_SUCCESS: "FAMILY_INSURANCEDOC_SUCCESS",
  FAMILY_INSURANCEDOC_ERROR: "FAMILY_INSURANCEDOC_ERROR",

  UPDATE_FAMILY_INSURANCEDOC_SUCCESS: "UPDATE_FAMILY_INSURANCEDOC_SUCCESS",
  UPDATE_FAMILY_INSURANCEDOC_PENDING: "  UPDATE_FAMILY_INSURANCEDOC_PENDING",
  UPDATE_FAMILY_INSURANCEDOC_ERROR: "  UPDATE_FAMILY_INSURANCEDOC_ERROR",
};

export function addFamilyInsurance(field) {
  return (dispatch) => {
    const employeeId = globals.store.getState().auth.loginUser.employeeId;

    const data = {
      employeeId: employeeId,
      firstName: field.firstName,
      lastName: field.lastName,
      relation: field.relation.value,
      insuranceNumber: "",
    };
    dispatch({
      type: actionTypes.ADD_FAMILY_INSURANCE_PENDING,
      payload: {
        familyInsurancePending: true,
        familyInsuranceSuccess: false,
        familyInsuranceError: null,
      },
    });

    Api.post("employeeFamilyMembers/add", data)
      .then((response) => {
        //console.log(response);

        if (response.message === "Successfully added employee family member") {
          dispatch({
            type: actionTypes.ADD_FAMILY_INSURANCE_SUCCESS,
            payload: {
              familyInsurancePending: false,
              familyInsuranceSuccess: true,
              familyInsuranceError: null,
              familyInsuranceData: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added family member insurance.",
            })
          );
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to add family member insurance.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: actionTypes.ADD_FAMILY_INSURANCE_ERROR,
          payload: {
            familyInsurancePending: false,
            familyInsuranceSuccess: true,
            familyInsuranceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add family member insurance.",
          })
        );
      });
  };
}

export function getFamilyMembersInsurance(hasEmployeeId = "") {
  var employeeId;
  if (hasEmployeeId) {
    employeeId = hasEmployeeId;
  } else {
    employeeId = globals.store.getState().auth.loginUser.employeeId;
  }
  return (dispatch) => {
    const data = {
      employeeId: employeeId,
    };

    dispatch({
      type: actionTypes.FAMILY_INSURANCE_PENDING,
      payload: {
        familyInsurancePending: true,
        familyInsuranceSuccess: false,
        familyInsuranceError: null,
      },
    });

    Api.post("employeeFamilyMembers/list", data)

      .then((response) => {
        if (response.data.length > 0) {
          dispatch({
            type: actionTypes.FAMILY_INSURANCE_PENDING,
            payload: {
              familyInsurancePending: false,
              familyInsuranceSuccess: true,
              familyInsuranceError: null,

              familyMemberInsuranceList: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.FAMILY_INSURANCE_SUCCESS,
            payload: {
              familyInsurancePending: false,
              familyInsuranceSuccess: true,
              familyInsuranceError: null,
              familyMemberInsuranceList: [],
            },
          });
        }
      })
      .catch((err) => {
        //console.log(err);
        dispatch({
          type: actionTypes.FAMILY_INSURANCE_ERROR,
          payload: {
            familyInsurancePending: false,
            familyInsuranceSuccess: true,
            familyInsuranceError: null,
            familyMemberInsuranceList: [],
          },
        });
      });
  };
}

export function editFamilyMemberInsurance(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_FAMILY_INSURANCE_PENDING,
      payload: {
        updateFamilyInsurancePending: true,
        updateFamilyInsuranceSuccess: false,
        updateFamilyInsuranceError: null,
      },
    });
    // console.log(data, id);
    Api.put(`employeeFamilyMembers/${id}`, data)
      .then((response) => {
        // console.log(response);
        if (
          response.message === "Successfully updated employee family member"
        ) {
          dispatch({
            type: actionTypes.UPDATE_FAMILY_INSURANCE_SUCCESS,
            payload: {
              updateFamilyInsurancePending: false,
              updateFamilyInsuranceSuccess: true,
              updateFamilyInsuranceError: null,
              familyInsuranceData: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated Family Member Insurance.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.UPDATE_FAMILY_INSURANCE_ERROR,
          payload: {
            leaveTypesEditPending: false,
            leaveTypesEditSuccess: true,
            leaveTypesEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit Family Member Insurance.",
          })
        );
      });
  };
}

export function deleteFamilyMemberInsurance(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_FAMILY_INSURANCE_PENDING,
      payload: {
        deleteFamilyInsurancePending: true,
        deleteFamilyInsuranceSuccess: false,
        deleteFamilyInsuranceError: null,
      },
    });

    // console.log(id)

    Api.delete(`employeeFamilyMembers/${id}`)
      .then((response) => {
        // console.log(response)
        if (
          response.message === "Successfully deleted employee family member"
        ) {
          dispatch({
            type: actionTypes.DELETE_FAMILY_INSURANCE_SUCCESS,
            payload: {
              deleteFamilyInsurancePending: false,
              deleteFamilyInsuranceSuccess: true,
              deleteFamilyInsuranceError: null,
              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted family member insurance.",
            })
          );
        }
      })
      .catch((err) => {
        //console.log(err);
        dispatch({
          type: actionTypes.DELETE_FAMILY_INSURANCE_ERROR,
          payload: {
            deleteFamilyInsurancePending: false,
            deleteFamilyInsuranceSuccess: true,
            deleteFamilyInsuranceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to delete family member insurance.",
          })
        );
      });
  };
}

export function getFamilyMemberInsuranceDoc(description, empId = "") {
  return (dispatch) => {
    const employeeId = globals.store.getState().auth.loginUser.employeeId;
    var data = {
      description: description,
    };
    if (empId) {
      data.employeeId = empId;
    } else {
      data.employeeId = employeeId;
    }

    dispatch({
      type: actionTypes.GET_FAMILY_INSURANCEDOC_PENDING,
      payload: {
        familyInsuranceDocPending: true,
        familyInsuranceDocSuccess: false,
        familyInsuranceDocError: null,
        familyInsuranceDocumentList: [],
      },
    });

    Api.post("userDocuments/getUserDocuments?limit=200", data)
      .then((response) => {
        if (response.message === "Successfully fetched dccuments list") {
          dispatch({
            type: actionTypes.GET_FAMILY_INSURANCEDOC_SUCCESS,
            payload: {
              familyInsuranceDocPending: false,
              familyInsuranceDocSuccess: true,
              familyInsuranceDocError: null,
              familyInsuranceDocumentList: response.data.docs,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_FAMILY_INSURANCEDOC_ERROR,
            payload: {
              familyInsuranceDocPending: false,
              familyInsuranceDocSuccess: true,
              familyInsuranceDocError: null,
              familyInsuranceDocumentList: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get family Insurance documents list.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.GET_FAMILY_INSURANCEDOC_ERROR,
          payload: {
            familyInsuranceDocPending: false,
            familyInsuranceDocSuccess: true,
            familyInsuranceDocError: null,
            familyInsuranceDocumentList: [],
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get family insurance document.",
          })
        );
      });
  };
}

export function downloadFamilyInsuranceCard(
  familyMemberId,
  description,
  empId = ""
) {
  return (dispatch) => {
    const employeeId = globals.store.getState().auth.loginUser.employeeId;

    var data = {
      familyMemberId: familyMemberId,
      description: description,
    };
    console.log("downloadInsurancecard", data);
    if (empId) {
      data.employeeId = empId;
    } else {
      data.employeeId = employeeId;
    }

    Api.post("userDocuments/getUserDocumentWithDownloadURL", data)

      .then((response) => {
        // console.log(response);
        if (response.data) {
          const url = response.data[0].downloadURL;
          const link = document.createElement("a");
          link.href = url;
          // link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to download document.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err);
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to download document.",
          })
        );
      });
  };
}

export function removefamilyMemberInsuranceCard(id) {
  return (dispatch) => {
    const data = {
      documentId: id,
    };

    Api.post("userDocuments/deleteUserDocument", data)
      .then((response) => {
        // console.log(response)
        if (response.message === "Successfully deleted user document") {
          dispatch({
            type: actionTypes.FAMILY_INSURANCEDOC_SUCCESS,
            payload: {
              familyInsuranceDocument: null,
              showInsuranceBox: true,
              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "User document deleted successfully.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
}

export function uploadMemberInsuranceCard(file, employeeId, familyMemberId) {
  return (dispatch) => {
    dispatch(showLoader());
    const formData = new FormData();
    formData.append("file", file);
    formData.append("employeeId", employeeId);
    formData.append("familyMemberId", familyMemberId);
    formData.append("description", "family_insurance_card");
    // console.log(id);

    Api.post("userDocuments/uploadUserDocument", formData)
      .then((response) => {
        if (response.message === "Successfully uploaded user document") {
          dispatch({
            type: actionTypes.UPDATE_FAMILY_INSURANCEDOC_SUCCESS,
            payload: {
              updateFamilyInsuranceDocPending: false,
              updateFamilyInsuranceDocSuccess: true,
              updateFamilyInsuranceDocError: null,
              familyInsuranceDocument: response.data,
            },
          });
          dispatch(hideLoader());
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully uploaded family insurance card.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err);
        dispatch(hideLoader());
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to upload insurance card.",
          })
        );
      });
  };
}
