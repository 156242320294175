import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ButtonView } from "../../../controls";
import { IoFastFood } from "react-icons/io5";

const LunchPreferenceCard = ({
  user,
  lunchTypeList,
  selectedLunchType,
  onlunchPreference,
  showAlert,
  deleteLunchPreference,
}) => {
  // console.log(user)
  const selectLunchType = (lunchType) => {
    if (lunchType._id !== selectedLunchType) {
      onlunchPreference(lunchType, user._id, user.location, false);
      // const today = new Date();
      // const time = today.getHours();
      // if (time < 7 || time > 9) {
      //   // if (time < 7 || time > 9) {
      //   // console.log(user._id)
      //   // console.log(lunchType)

      // } else {
      //   showAlert({
      //     isOpen: true,
      //     title: "error",
      //     type: "danger",
      //     msg: "You can't edit lunch settings 7am - 10am.",
      //   });
      // }
    }
  };

  const onDeleteLunchPreference = (id) => {
    deleteLunchPreference(user._id, user.location);
    // const today = new Date();
    // const time = today.getHours();
    // // if (time < 6 || time > 9) {
    // if (time < 7 || time > 9) {

    // } else {
    //   showAlert({
    //     isOpen: true,
    //     title: "error",
    //     type: "danger",
    //     msg: "You can't edit lunch settings 6am - 10am.",
    //   });
    // }
  };

  return (
    <div className="lunch_preference main__body padding">
      <Container>
        <Row>
          {lunchTypeList.map((lunchType) => {
            return (
              <Col
                key={lunchType._id}
                md={6}
                lg={6}
                xl={3}
                className="col-xxl-3"
              >
                <div className="lunch_preference_card">
                  <Card className="mt-3">
                    <Card.Body>
                      <Card.Title className="text-center">
                        <span className="card_icon fw-bold">
                          <IoFastFood /> {lunchType.lunchType}
                        </span>
                      </Card.Title>
                      <Card.Text className="text-muted text-center">
                        {lunchType.description}
                      </Card.Text>
                      <hr />
                      <div className="choose_lunch">
                        {selectedLunchType &&
                        selectedLunchType === lunchType._id ? (
                          <ButtonView
                            title={"Selected"}
                            styles="secondary"
                            onClick={() => selectLunchType(lunchType)}
                          />
                        ) : (
                          <ButtonView
                            title={"Choose"}
                            onClick={() => selectLunchType(lunchType)}
                          />
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            );
          })}

          <Col md={6} lg={6} xl={3} className="col-xxl-3">
            <div className="lunch_preference_card">
              <Card className="mt-3">
                <Card.Body>
                  <Card.Title className="text-center">
                    <span className="card_icon fw-bold">
                      <IoFastFood /> No Tiffin
                    </span>
                  </Card.Title>
                  <Card.Text className="text-muted text-center">-</Card.Text>
                  <hr />
                  <div className="choose_lunch">
                    {!selectedLunchType ? (
                      <ButtonView
                        title={"Selected"}
                        styles="secondary"
                        onClick={() => {}}
                      />
                    ) : (
                      <ButtonView
                        title={"Choose"}
                        onClick={() =>
                          onDeleteLunchPreference(selectedLunchType)
                        }
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LunchPreferenceCard;
