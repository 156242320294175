import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";

import { ButtonView, Input } from "../../../controls";

const UserFilter = ({
  filterShow,
  handleClose,
  locationList,
  userListFilter,
  getUserList,
  onsetFilterData,
  onresetFilterPagination,
  handleIsFilterActive,
  handleClearFilter,
}) => {
  const [location, setLocation] = useState("");
  const [firstName, setLFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const locationOptions = locationList.map((location) => {
    return { value: location._id, label: location.name };
  });

  const handleSubmit = () => {
    var filterData = { isActive: "1" };

    if (location) {
      filterData.location = location;
    }
    if (firstName) {
      filterData.firstName = firstName;
    }
    if (lastName) {
      filterData.lastName = lastName;
    }
    // console.log(filterData)
    getUserList(1, filterData, false);
    onsetFilterData(filterData);
    if (location || firstName || lastName) handleIsFilterActive(true);

    // userListFilter({
    //   firstName: firstName,
    //   lastName: lastName,
    //   location: location
    // })
    handleClose();
  };
  const handleReset = () => {
    handleClearFilter();
    handleClose();
  };

  return (
    <div className="filter_modal">
      <Modal
        show={filterShow}
        onHide={handleClose}
        aria-labelledby="filter_modal"
      >
        <Modal.Header>
          <Modal.Title id="filter_modal">Filter</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Input
              type="text"
              value={firstName}
              placeholder="First Name"
              onChange={(e) => setLFirstName(e.target.value)}
              size="fluid"
            />
          </div>
          <div className="mb-3">
            <Input
              type="text"
              value={lastName}
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              size="fluid"
            />
          </div>
          <div className="mb-3">
            <Select
              isSearchable={false}
              onChange={(val) => setLocation(val.value)}
              defaultValue={
                location
                  ? locationOptions.find((option) => option.value === location)
                  : ""
              }
              placeholder="Select Location"
              options={locationOptions}
              className="select"
              classNamePrefix="select"
            />
          </div>
          <div className="mb-3">
            <Select
              isSearchable={false}
              placeholder="Select Category"
              options={locationOptions}
              className="select"
              classNamePrefix="select"
            />
          </div>
          <div className="mb-3">
            <Select
              isSearchable={false}
              placeholder="Select Technology"
              options={locationOptions}
              className="select"
              classNamePrefix="select"
            />
          </div>
          <Row className="d-flex justify-content-end">
            <Col xs={12} md={6} lg={4} className="mb-2">
              <ButtonView
                // onClick={handleClearFilter}
                onClick={handleReset}
                title={"Reset"}
                styles="primal"
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <ButtonView
                onClick={handleSubmit}
                title={"Apply Filter"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserFilter;
