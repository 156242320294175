import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
// import { MdCall } from "react-icons/md";

import { Modal, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { ButtonView } from "../../../controls";
import { DataTable } from "../../../common";

const Cancelled = ({
  data,
  onsetLeaveId,
  leaveId,
  getNextYearLeaveDependOnId,
}) => {
  // console.log("cancelled", data);
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);

  const handleViewOpen = useCallback((row) => {
    setViewDetails(row);
    setViewDetailsShow(true);
    // console.log(row)
  }, []);

  useEffect(() => {
    if (leaveId) {
      const leaveDependOnId = data.find((list) => {
        return list._id === leaveId;
      });
      if (leaveDependOnId) {
        handleViewOpen(leaveDependOnId);
      } else {
        getNextYearLeaveDependOnId(leaveId);
      }
    }
    onsetLeaveId(0);
  }, [handleViewOpen, leaveId, data, onsetLeaveId, getNextYearLeaveDependOnId]);

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="badge bg-cancel-transparent">Cancelled</span>
      ),
    },
    {
      name: "Type",
      selector: "masterLeaveType",
      center: true,
      grow: 4,
    },
    {
      name: "FD/1H/2H",
      selector: "leaveSubType",
      center: true,
      grow: 4,
    },
    {
      name: "Employee",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {row.userId[0].firstName} {row.userId[0].lastName.slice(0, 1)}
        </span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "130px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              handleViewOpen(row);
              // handleViewOpen()
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
  ];

  const handleClose = () => {
    setViewDetailsShow(false);
    // setRejectOpen(false)
    // initDoc()
    // setRejectionId('')
    // setErrors({})
    // setRejectionVal("")
  };
  // console.log("data", data);
  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <DataTable
          data={data}
          columns={columns}
          pagination={true}
          paginationPerPage={5}
          paginationServer={false}
        />
      </div>

      {viewDetails && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Application Detail</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              {viewDetails.rejectionOrCancellationComments && (
                <div>
                  <div className="comments">
                    <h5>Cancellation Reason:</h5>
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      // disabled
                      readOnly
                      value={viewDetails.rejectionOrCancellationComments}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </div>
              )}
              <div className="comments ms-1 d-flex justify-content-between align-items-center flex-wrap">
                <h5>
                  Applier:{" "}
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.userId[0].firstName}{" "}
                    {viewDetails.userId[0].lastName}
                  </span>
                </h5>
                {viewDetails.masterLeaveType === "Optional Holiday Leave" && (
                  <h5>
                    <span
                      style={{ fontSize: "15px" }}
                      className="badge badge-success"
                    >
                      Optional Holiday
                    </span>
                  </h5>
                )}
              </div>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  FD/1H/2H
                  <span>{viewDetails.leaveSubType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Leave Type:
                  <span>{viewDetails.masterLeaveType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sub Leave Type:
                  <span>{viewDetails.leaveType.description}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Start Date:
                  <span>
                    {moment(new Date(viewDetails.startDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  End Date:
                  <span>
                    {moment(new Date(viewDetails.endDate)).format("DD/MM/YYYY")}
                  </span>
                </li>
                {viewDetails.comments && (
                  <div className="mt-3">
                    <div className="comments">
                      <h6>Comments:</h6>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={viewDetails.comments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
              </ul>
            </Modal.Body>
          </Modal>
        </div>
        // <div className="view_modal">
        //   <Modal
        //       show={viewDetailsShow}
        //       onHide={() => setViewDetailsShow(false)}
        //       aria-labelledby="view_modal"
        //     >
        //       <Modal.Header>
        //         <Modal.Title id="view_modal">Application Detail</Modal.Title>
        //         <div onClick={() => setViewDetailsShow(false)} style={{cursor: "pointer"}}>
        //           <AiOutlineClose size={20} />
        //         </div>
        //       </Modal.Header>
        //       <Modal.Body>
        //         <div className="comments">
        //           <h5>Cancellation reason</h5>
        //           <TextArea
        //             name="comments"
        //             value={viewDetails.rejectionOrCancellationComments}
        //             type="textarea"
        //             onChange={() => {}}
        //             size="fluid"
        //             readOnly
        //             // error={errors["email"]}
        //           />
        //         </div>
        //       </Modal.Body>
        //     </Modal>
        // </div>
      )}
    </>
  );
};

export default Cancelled;
