import React, { Component } from "react";
import { bindActionCreators } from "redux";
// import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import JoinUsView from "./JoinUsView";

import "./joinUseStyles.scss";
import * as validator from "../../../helpers/validator";
import { trimFieldsData } from "../../../utils";
import {
  showNotification,
  addJobApplication,
} from "../../../store/joinUs/joinUsActions";
import { BigLoader } from "../../common";

export class JoinUsContainer extends Component {
  state = {
    fileVal: null,
    errors: {},
    formData: {
      firstName: "",
      lastName: "",
      qualification: "",
      phone: "",
      email: "",
      experience: "0",
    },
    isVarified: false,
  };
  // constructor(props) {
  //   super(props);

  // }

  setFileVal = (e) => {
    // console.log(e.target.files[0]);
    this.setState({
      fileVal: e.target.files[0],
    });
  };

  setFormData = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleKeyPress = (e) => {
    let charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVarified: true,
      });
    }
    // console.log(response);
  };

  callback = function () {
    console.log("capcha successfully loaded");
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var rules = {
      required: [
        "firstName",
        "lastName",
        "qualification",
        "phone",
        "email",
        "experience",
      ],
      custom: [validator.email(["email"])],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(this.state.formData),
    });
    if (this.state.formData.phone && this.state.formData.phone.length < 10) {
      errors = {
        ...errors,
        phone: "This field is too short.",
      };
    }
    if (!this.state.fileVal) {
      errors = {
        ...errors,
        fileVal: "Please select a file",
      };
    }
    this.setState({
      errors: {
        ...errors,
      },
    });

    if (!Object.keys(errors).length) {
      if (this.state.isVarified) {
        this.props.addJobApplication(this.state.formData, this.state.fileVal);
        this.setState({
          fileVal: null,
          errors: {},
          formData: {
            firstName: "",
            lastName: "",
            qualification: "",
            phone: "",
            email: "",
            experience: "0",
          },
          isVarified: false,
        });
      } else {
        this.props.showNotification(
          "Please verify that you are a human!",
          "warning"
        );
      }
    }
  };

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { notificationMessage, notificationType } = this.props;
    return (
      <>
        <JoinUsView
          fileVal={this.state.fileVal}
          setFileVal={this.setFileVal}
          formData={this.state.formData}
          setFormData={this.setFormData}
          handleSubmit={this.handleSubmit}
          errors={this.state.errors}
          handleKeyPress={this.handleKeyPress}
          verifyCallback={this.verifyCallback}
          notificationMessage={notificationMessage}
          notificationType={notificationType}
          callback={this.callback}
        />
        <BigLoader />
      </>
    );
  }
}

JoinUsContainer.propTypes = {};

const mapStateToProps = (state) => ({
  notificationMessage: state.joinUs.msg,
  notificationType: state.joinUs.type,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showNotification: showNotification,
      addJobApplication: addJobApplication,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(JoinUsContainer);
