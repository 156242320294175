import React, { useState } from "react";
import moment from "moment";
import { Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, Select, Radio, TextArea } from "../../../controls";
import {
  INTERVIEW_STATUS_INCOMPLETED,
  INTERVIEW_STATUS_COMPLETED,
  INTERVIEW_OUTCOME_SELECTED,
  INTERVIEW_OUTCOME_REJECTED,
  INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND,
} from "../../../../constants/env";

const formData = {
  outcomeRemark: "",
  outcome: "",
  nonCompletionRemark: "",
};

const PendingReview = ({
  data,
  updateInterviewScheduleStatusByInterviewer,
  user,
  updateJobApplicationStatus,
  downloadJobApplicationDocument,
}) => {
  const [reviewData, setReviewData] = useState(formData);
  const [submitReviewShow, setSubmitReviewShow] = useState(false);
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const [interviewStatus, setInterviewStatus] = useState("");
  const [jobAppId, setjobAppId] = useState("");
  const [interviewId, setInterviewId] = useState("");

  const columns = [
    {
      name: "Date",
      center: true,
      minWidth: "90px",
      cell: (row) => (
        <span>{moment(new Date(row.interviewDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Start",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {((parseInt(row.startTime) + 11) % 12) + 1}:
          {row.startTime.split(":")[1]}{" "}
          {parseInt(row.startTime) >= 12 ? "PM" : "AM"}
        </span>
      ),
    },
    {
      name: "End",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {((parseInt(row.endTime) + 11) % 12) + 1}:{row.endTime.split(":")[1]}{" "}
          {parseInt(row.endTime) >= 12 ? "PM" : "AM"}
        </span>
      ),
    },
    {
      name: "Candidate",
      center: true,
      grow: 4,
      cell: (row) => {
        return (
          <span>
            {row.applicationId.firstName && row.applicationId.firstName}{" "}
            {row.applicationId.lastName &&
              row.applicationId.lastName.slice(0, 1)}
          </span>
        );
      },
    },
    // {
    //   name: "Prime Domain",
    //   center: true,
    //   grow: 4,
    //   cell: (row) => {
    //     return (
    //       <span>
    //         {row.applicationId.primaryTechnicalDomains &&
    //           row.applicationId.primaryTechnicalDomains[0]}
    //       </span>
    //     );
    //   },
    // },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => <span className="badge badge-hold">Pending Review</span>,
    },
    {
      name: "Detail",
      center: true,
      grow: 4,
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              handleViewOpen(row);
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      // grow: 4,
      cell: (row) => {
        return (
          <div className="d-flex">
            <ButtonView
              onClick={() => {
                setViewDetails(row);
                setSubmitReviewShow(true);
                setjobAppId(row.applicationId._id);
                setInterviewId(row._id);
              }}
              title={"Submit Review"}
              size="small"
            />
          </div>
        );
      },
    },
  ];

  const handleSubmit = () => {
    var rules = {};
    if (interviewStatus) {
      if (interviewStatus === INTERVIEW_STATUS_INCOMPLETED) {
        rules = {
          required: ["nonCompletionRemark"],
        };
      } else {
        rules = {
          required: ["outcomeRemark", "outcome"],
        };
      }
      var { errors } = validator.validate(rules, {
        ...trimFieldsData(reviewData),
      });
    } else {
      errors = {
        ...errors,
        interviewStatus: "Please select interview complition type",
      };
    }
    setErrors({
      ...errors,
    });
    if (!Object.keys(errors).length) {
      if (interviewStatus === INTERVIEW_STATUS_INCOMPLETED) {
        const nonCompletionData = {
          status: interviewStatus,
          statusComments: reviewData.nonCompletionRemark,
          lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        };
        updateInterviewScheduleStatusByInterviewer(
          nonCompletionData,
          interviewId
        );
      } else {
        const completionData = {
          status: interviewStatus,
          outcome: reviewData.outcome,
          outcomeComments: reviewData.outcomeRemark,
          lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        };
        const jobAppStatus = {
          status: reviewData.outcome,
          lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        };
        updateInterviewScheduleStatusByInterviewer(completionData, interviewId);
        updateJobApplicationStatus(jobAppStatus, jobAppId, false);
      }
      handleClose();
    }
  };

  const handleViewOpen = (row) => {
    setViewDetails(row);
    setViewDetailsShow(true);
  };

  const handleClose = () => {
    setViewDetailsShow(false);
    setSubmitReviewShow(false);
    setReviewData(formData);
    setErrors({});
    setInterviewStatus("");
    setjobAppId("");
    setInterviewId("");
  };

  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <DataTable
          data={data}
          columns={columns}
          pagination={true}
          paginationPerPage={5}
          paginationServer={false}
        />
      </div>

      {viewDetails && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Interview Detail</Modal.Title>

              <div className="d-flex align-items-center">
                <div className="me-2 d-none d-md-block">
                  <ButtonView
                    onClick={() =>
                      downloadJobApplicationDocument(
                        viewDetails.applicationId._id
                      )
                    }
                    title={"Download Resume"}
                    styles="secondary"
                  />
                </div>

                <div onClick={handleClose} style={{ cursor: "pointer" }}>
                  <AiOutlineClose size={20} />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="interview_schedule_detail ms-1">
                <div className="mb-3 d-block d-md-none">
                  <ButtonView
                    onClick={() =>
                      downloadJobApplicationDocument(
                        viewDetails.applicationId._id
                      )
                    }
                    title={"Download Resume"}
                    styles="secondary"
                  />
                </div>
                <h5>Round: {viewDetails.roundNumber}</h5>
                <h5>
                  Interviewer(s):{" "}
                  {viewDetails.interviewers.map((list, id, array) => {
                    if (id === array.length - 1) {
                      return (
                        <span key={list._id}>
                          {list.userId[0].firstName} {list.userId[0].lastName}{" "}
                        </span>
                      );
                    } else {
                      return (
                        <span key={list._id}>
                          {list.userId[0].firstName} {list.userId[0].lastName},{" "}
                        </span>
                      );
                    }
                  })}
                </h5>
                <div className="d-flex flex-wrap">
                  <h5 className="me-3">
                    Date:{" "}
                    {moment(new Date(viewDetails.interviewDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </h5>
                  <h5>
                    Time: {((parseInt(viewDetails.startTime) + 11) % 12) + 1}:
                    {viewDetails.startTime.split(":")[1]}{" "}
                    {parseInt(viewDetails.startTime) >= 12 ? "PM" : "AM"} to{" "}
                    {((parseInt(viewDetails.endTime) + 11) % 12) + 1}:
                    {viewDetails.endTime.split(":")[1]}{" "}
                    {parseInt(viewDetails.endTime) >= 12 ? "PM" : "AM"}
                  </h5>
                </div>
                <h5 className="me-3">Mode: {viewDetails.mode}</h5>
              </div>
              <ul className="list-group interview_schedule_detail_list">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Name:
                  <span>
                    {viewDetails.applicationId.firstName}{" "}
                    {viewDetails.applicationId.middleName &&
                      viewDetails.applicationId.middleName}{" "}
                    {viewDetails.applicationId.lastName &&
                      viewDetails.applicationId.lastName}
                  </span>
                </li>
                {viewDetails.applicationId.primaryTechnicalDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Prime Domain:
                    <span>
                      {viewDetails.applicationId.primaryTechnicalDomains[0]}
                    </span>
                  </li>
                ) : null}
                {viewDetails.applicationId.secondaryTechnicalDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    Secondary Domain:
                    <span style={{ textAlign: "right" }}>
                      {viewDetails.applicationId.secondaryTechnicalDomains.map(
                        (list, id, array) => {
                          if (
                            viewDetails.applicationId.secondaryTechnicalDomains
                              .length > 1
                          ) {
                            if (id === array.length - 1) {
                              return list;
                            } else {
                              return (
                                <>
                                  {list}, {(id + 1) % 3 === 0 ? <br /> : null}
                                </>
                              );
                            }
                          } else {
                            return list;
                          }
                        }
                      )}
                    </span>
                  </li>
                ) : null}
                {/* {viewDetails.applicationId.skills && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Skills:
                    <span>{viewDetails.applicationId.skills}</span>
                  </li>
                )} */}
                {viewDetails.applicationId.applicationDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    Application Domain:
                    <span style={{ textAlign: "right" }}>
                      {viewDetails.applicationId.applicationDomains.map(
                        (list, id, array) => {
                          if (
                            viewDetails.applicationId.secondaryTechnicalDomains
                              .length > 1
                          ) {
                            if (id === array.length - 1) {
                              return list;
                            } else {
                              return (
                                <>
                                  {list}, {(id + 1) % 3 === 0 ? <br /> : null}
                                </>
                              );
                            }
                          } else {
                            return list;
                          }
                        }
                      )}
                    </span>
                  </li>
                ) : null}
                {viewDetails.applicationId.designationAppliedFor && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Applied Designation:
                    <span>
                      {viewDetails.applicationId.designationAppliedFor}
                    </span>
                  </li>
                )}

                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Experience:
                  <span>
                    {" "}
                    {(viewDetails.applicationId.experienceInMonths / 12) | 0}.
                    {viewDetails.applicationId.experienceInMonths % 12} yrs
                  </span>
                </li>
                {viewDetails.applicationId.qualification && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Qualification:
                    <span>{viewDetails.applicationId.qualification}</span>
                  </li>
                )}
                {viewDetails.applicationId.passingYear && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Passing Year:
                    <span>{viewDetails.applicationId.passingYear}</span>
                  </li>
                )}
              </ul>
              <div className="interview_schedule_detail">
                <h5 className="m-0 mt-2">
                  Accepted By:{" "}
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.lastUpdatedBy}
                  </span>
                </h5>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

      {viewDetails && (
        <div className="review_modal">
          <Modal
            show={submitReviewShow}
            onHide={handleClose}
            aria-labelledby="filter_modal"
            size="lg"
          >
            <Modal.Header>
              <Modal.Title style={{ color: "#d87d2e" }} id="filter_modal">
                Submit Review
              </Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="interview_schedule">
                <h6 className="ms-1">Round# {viewDetails.roundNumber}</h6>
                <div className="interview_schedule_table mt-2 mb-3">
                  <ul className="list-group interview_schedule_detail_list">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Name:
                      <span>
                        {viewDetails.applicationId.firstName}{" "}
                        {viewDetails.applicationId.middleName &&
                          viewDetails.applicationId.middleName}{" "}
                        {viewDetails.applicationId.lastName &&
                          viewDetails.applicationId.lastName}
                      </span>
                    </li>
                    {viewDetails.applicationId.designationAppliedFor && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Applied Designation:
                        <span>
                          {viewDetails.applicationId.designationAppliedFor}
                        </span>
                      </li>
                    )}
                    {viewDetails.applicationId.primaryTechnicalDomains
                      .length ? (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Prime Domain:
                        <span>
                          {viewDetails.applicationId.primaryTechnicalDomains[0]}
                        </span>
                      </li>
                    ) : null}
                    {viewDetails.applicationId.secondaryTechnicalDomains
                      .length ? (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        Secondary Domain:
                        <span style={{ textAlign: "right" }}>
                          {viewDetails.applicationId.secondaryTechnicalDomains.map(
                            (list, id, array) => {
                              if (
                                viewDetails.applicationId
                                  .secondaryTechnicalDomains.length > 1
                              ) {
                                if (id === array.length - 1) {
                                  return list;
                                } else {
                                  return (
                                    <>
                                      {list},{" "}
                                      {(id + 1) % 3 === 0 ? <br /> : null}
                                    </>
                                  );
                                }
                              } else {
                                return list;
                              }
                            }
                          )}
                        </span>
                      </li>
                    ) : null}
                    {viewDetails.applicationId.applicationDomains.length ? (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        Application Domain:
                        <span style={{ textAlign: "right" }}>
                          {viewDetails.applicationId.applicationDomains.map(
                            (list, id, array) => {
                              if (
                                viewDetails.applicationId
                                  .secondaryTechnicalDomains.length > 1
                              ) {
                                if (id === array.length - 1) {
                                  return list;
                                } else {
                                  return (
                                    <>
                                      {list},{" "}
                                      {(id + 1) % 3 === 0 ? <br /> : null}
                                    </>
                                  );
                                }
                              } else {
                                return list;
                              }
                            }
                          )}
                        </span>
                      </li>
                    ) : null}

                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Experience:
                      <span>
                        {" "}
                        {(viewDetails.applicationId.experienceInMonths / 12) |
                          0}
                        .{viewDetails.applicationId.experienceInMonths % 12} yrs
                      </span>
                    </li>
                    {viewDetails.applicationId.qualification && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Qualification:
                        <span>{viewDetails.applicationId.qualification}</span>
                      </li>
                    )}
                    {viewDetails.applicationId.passingYear && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Passing Year:
                        <span>{viewDetails.applicationId.passingYear}</span>
                      </li>
                    )}
                  </ul>
                </div>
                <Row>
                  <Col md={12} lg={4} className="mb-3">
                    <Input
                      name="interviewDate"
                      label="Schedule Date"
                      type="date"
                      placeholder="Schedule Date"
                      size="fluid"
                      value={viewDetails.interviewDate.slice(0, 10)}
                      onChange={(e) => {}}
                      disabled
                    />
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <Input
                      name="startTime"
                      label="Start Time"
                      type="time"
                      placeholder="Start Time"
                      size="fluid"
                      value={viewDetails.startTime}
                      onChange={(e) => {}}
                      disabled
                    />
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <Input
                      name="endTime"
                      label="End Time"
                      type="time"
                      placeholder="End Time"
                      size="fluid"
                      value={viewDetails.endTime}
                      onChange={(e) => {}}
                      disabled
                    />
                  </Col>
                  {viewDetails.interviewers.map((list, i) => {
                    return (
                      <Col md={12} lg={4}>
                        <Input
                          name="interviewer"
                          type="text"
                          label={`Interviewer ${i + 1}`}
                          size="fluid"
                          value={`${list.userId[0].firstName} ${list.userId[0].lastName}`}
                          onChange={(e) => {}}
                          disabled
                        />
                      </Col>
                    );
                  })}
                </Row>
                <hr />
                <Row>
                  <Col md={12} className="mb-4">
                    <span className={`label text-muted`}>
                      Interview Completion{" "}
                      <span className="text-danger">*</span>
                    </span>
                    <div className="interview_status d-flex mt-2">
                      <Radio
                        name={"completed_review"}
                        text={"Completed"}
                        onChange={() => {
                          setReviewData(formData);
                          setInterviewStatus(INTERVIEW_STATUS_COMPLETED);
                          setErrors({});
                        }}
                      />
                      <Radio
                        name={"completed_review"}
                        text={"Not Completed"}
                        onChange={() => {
                          setReviewData(formData);
                          setInterviewStatus(INTERVIEW_STATUS_INCOMPLETED);
                          setErrors({});
                        }}
                      />
                      {errors["interviewStatus"] && (
                        <span className="interview_status_error">
                          {errors["interviewStatus"]}
                        </span>
                      )}
                    </div>
                  </Col>

                  <Col md={12} lg={12} className="mb-3">
                    <TextArea
                      name="nonCompletionRemark"
                      label="Non-completion remark"
                      type="text"
                      placeholder="Remark"
                      size="fluid"
                      value={reviewData.nonCompletionRemark}
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          nonCompletionRemark: e.target.value,
                        })
                      }
                      error={errors["nonCompletionRemark"]}
                      isrequired={true}
                      disabled={
                        interviewStatus === INTERVIEW_STATUS_COMPLETED
                          ? true
                          : false
                      }
                    />
                  </Col>

                  <Col md={12} lg={6} className="mb-3">
                    <Select
                      name="outcome"
                      label="Outcome"
                      placeholder="Select"
                      options={[
                        {
                          value: INTERVIEW_OUTCOME_SELECTED,
                          label: INTERVIEW_OUTCOME_SELECTED,
                        },
                        {
                          value: INTERVIEW_OUTCOME_REJECTED,
                          label: INTERVIEW_OUTCOME_REJECTED,
                        },
                        {
                          value: INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND,
                          label: INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND,
                        },
                      ]}
                      onChange={(val) => {
                        setReviewData({
                          ...reviewData,
                          outcome: val.value,
                        });
                      }}
                      className="select"
                      classNamePrefix="select"
                      // isClearable={true}
                      error={errors["outcome"]}
                      isDisabled={
                        interviewStatus === INTERVIEW_STATUS_INCOMPLETED
                          ? true
                          : false
                      }
                      isrequired={true}
                    />
                  </Col>

                  <Col md={12} lg={12} className="mb-3">
                    <TextArea
                      name="outcomeRemark"
                      label="Outcome remark"
                      type="text"
                      placeholder="Remark"
                      size="fluid"
                      value={reviewData.outcomeRemark}
                      onChange={(e) =>
                        setReviewData({
                          ...reviewData,
                          outcomeRemark: e.target.value,
                        })
                      }
                      error={errors["outcomeRemark"]}
                      disabled={
                        interviewStatus === INTERVIEW_STATUS_INCOMPLETED
                          ? true
                          : false
                      }
                      isrequired={true}
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-end">
                  <Col xs={6} md={6} lg={3}>
                    <ButtonView
                      onClick={() => {
                        handleClose();
                      }}
                      title={"Cancel"}
                      styles="danger-outline"
                    />
                  </Col>
                  <Col xs={6} md={6} lg={3}>
                    <ButtonView
                      onClick={handleSubmit}
                      title={"Submit Review"}
                      styles="secondary"
                    />
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default PendingReview;
