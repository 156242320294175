import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, TextArea } from "../../../controls";

const LunchTypeMngt = ({
  addLunchType,
  editLunchType,
  deleteLunchType,
  lunchTypeList,

  locationList,
}) => {
  // console.log(locationList)
  // console.log(lunchTypeList)
  const [showAddLunchType, setShowAddLunchType] = useState(false);
  const [lunchType, setLunchType] = useState("");
  const [lunchDes, setLunchDes] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [lunchTypeId, setLunchTypeId] = useState("");
  const [location, setLocation] = useState([]);

  const columns = [
    {
      name: "Lunch Type",
      selector: "lunchType",
      sortable: true,
      grow: 4,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setLunchType(row.lunchType);
                setLunchDes(row.description);
                setShowAddLunchType(true);
                setLunchTypeId(row._id);
                setLocation(row.location);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete lunchType?")) {
                // console.log(row._id)
                deleteLunchType(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleInputChange = (event) => {
    const target = event.target;
    var value = target.value;

    if (target.checked) {
      // console.log('hi')
      setLocation((prevData) => {
        const newData = prevData.filter((v) => v !== value);
        return [...newData, value];
      });
    } else {
      // console.log("hi2")
      setLocation((prevData) => {
        const newData = prevData.filter((v) => v !== value);
        return [...newData];
      });
    }
  };

  const handleAddRole = () => {
    var rules = { required: ["lunchType", "lunchDes"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ lunchType, lunchDes }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        editLunchType(lunchType, lunchDes, location, lunchTypeId);
        // edit
      } else {
        addLunchType(lunchType, lunchDes, location);
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddLunchType(false);
    setLunchType("");
    setLunchDes("");
    setShowEdit(false);
    setErrors({});
    setLocation([]);
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Lunch Type</div>
              <div className="d-none d-md-block">Lunch Type Management</div>
            </Card.Title>
            <div className="add_permission">
              <ButtonView
                onClick={() => setShowAddLunchType(true)}
                title={"Add Lunch Type"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={lunchTypeList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddLunchType}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Lunch Type" : "Add Lunch Type"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-center ">
              <Col xs={12} md={12}>
                <Input
                  name="lunchType"
                  label="Add Lunch Type"
                  value={lunchType}
                  type="text"
                  placeholder="Add Lunch Type"
                  onChange={(e) => setLunchType(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["lunchType"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12} md={12} className="mt-3">
                <TextArea
                  name="lunchDes"
                  label="Description"
                  value={lunchDes}
                  type="number"
                  placeholder="Add Lunch Description"
                  onChange={(e) => setLunchDes(e.target.value)}
                  size="fluid"
                  error={errors["lunchDes"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12} md={12} className="select_location mt-3 mb-3">
                <h6>Select Location</h6>
                <Row>
                  {locationList.map((list) => {
                    return (
                      <Col key={list._id} md={12}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            defaultChecked={
                              location.indexOf(list._id) !== -1 ? true : false
                            }
                            type="checkbox"
                            value={list._id}
                            onChange={handleInputChange}
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {list.name}
                          </label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={12} md={12} className="mt-3">
                <ButtonView
                  onClick={handleAddRole}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LunchTypeMngt;
