import { actionTypes } from "./lunchTypeActions";

const { 
  LUNCHTYPE_PENDING, 
  LUNCHTYPE_ERROR, 
  LUNCHTYPE_SUCCESS,  

  LUNCHTYPE_ADD_PENDING,
  LUNCHTYPE_ADD_ERROR,
  LUNCHTYPE_ADD_SUCCESS,

  LUNCHTYPE_EDIT_PENDING,
  LUNCHTYPE_EDIT_ERROR,
  LUNCHTYPE_EDIT_SUCCESS,

  LUNCHTYPE_DELETE_PENDING,
  LUNCHTYPE_DELETE_ERROR,
  LUNCHTYPE_DELETE_SUCCESS,
} = actionTypes;

const initialState = {
  lunchTypePending: false,
  lunchTypeSuccess: false,
  lunchTypeError: null,

  lunchTypeAddPending: false,
  lunchTypeAddSuccess: false,
  lunchTypeAddError: null,

  lunchTypeEditPending: false,
  lunchTypeEditSuccess: false,
  lunchTypeEditError: null,

  lunchTypeDeletePending: false,
  lunchTypeDeleteSuccess: false,
  lunchTypeDeleteError: null,

  lunchTypeList: [],
  allLunchTypeList: [],
  selectedLunchType: "",
};

export default function lunchType(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LUNCHTYPE_PENDING:
    case LUNCHTYPE_ERROR:
    case LUNCHTYPE_SUCCESS:
      return Object.assign({}, state, payload);
    case LUNCHTYPE_ADD_PENDING:
    case LUNCHTYPE_ADD_ERROR:
      return Object.assign({}, state, payload);
    case LUNCHTYPE_ADD_SUCCESS: {
      const newRole = payload.lunchType;
      const newLunchTypeList = [...state.allLunchTypeList, newRole];

      const newState = {
        allLunchTypeList: newLunchTypeList,
        lunchTypeAddPending: payload.lunchTypeAddPending,
        lunchTypeAddSuccess: payload.lunchTypeAddSuccess,
        lunchTypeAddError: payload.lunchTypeAddError,
      }
      return Object.assign({}, state, newState);
    }
    case LUNCHTYPE_EDIT_PENDING:
    case LUNCHTYPE_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case LUNCHTYPE_EDIT_SUCCESS: {
      const newLunchTypeList = state.allLunchTypeList.map(list => {
        if(list._id === payload.lunchType._id) {
          return payload.lunchType
        } else {
          return list
        }
      })
      return Object.assign({}, state, {allLunchTypeList: newLunchTypeList}, payload);
    }
    case LUNCHTYPE_DELETE_PENDING:
    case LUNCHTYPE_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case LUNCHTYPE_DELETE_SUCCESS: {
      const newLunchTypeList = state.allLunchTypeList.filter(list => {
        return list._id !== payload.id
      })
      return Object.assign({}, state, {allLunchTypeList: newLunchTypeList}, payload);
    }
    default:
      return state;
  }
}
