import React from "react";
// import GoogleLogin from "react-google-login";
import { Card } from "react-bootstrap";

//import { CLIENT_ID } from "../../../constants/env";
import logo from "../../../assets/images/download.png";
//import google from "../../../assets/images/google.png";
import { GoogleLogin } from "@react-oauth/google";
// import { disableCursor } from "@fullcalendar/core";

const LoginView = ({ onSuccessGoogle, onErrorGoogle, url, ref }) => {
  // console.log("xyz", xyz);
  return (
    <div className="google_login">
      <Card className="google_login_container shadow">
        <Card.Body className="text-center d-flex justify-content-center align-items-center flex-column">
          <img src={logo} alt="SSI Logo" />
          <Card.Title className="mt-2">Smart Sight Innovations</Card.Title>
          {/* <h4>Lunch</h4> */}
          {/* <GoogleLogin
            clientId={CLIENT_ID}
            render={(renderProps) => (
              <button
                className="shadow-sm"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                id="autoClickBtn"
              >
                <img src={google} alt="google logo" /> Sign in with Google
              </button>
            )}
            buttonText="Login with Google"
            onSuccess={onSuccessGoogle}
            onFailure={onErrorGoogle}
            cookiePolicy={"single_host_origin"}
            // isSignedIn={true}
          /> */}
          <div className="google-sign-in" id="google-sign-in">
            <GoogleLogin onSuccess={onSuccessGoogle} onError={onErrorGoogle} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LoginView;
