import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import connect from "react-redux/es/connect/connect";

import { ButtonView } from "../../../controls";
import {
  downloadFamilyInsuranceCard,
  getFamilyMemberInsuranceDoc,
} from "../../../../store/familyInsurance/familyInsuranceActions";

const FamilyInfoView = ({
  data,
  num,
  empId,
  getFamilyMemberInsuranceDoc,
  familyInsuranceDocumentList,
  downloadFamilyInsuranceCard,
}) => {
  useEffect(() => {
    getFamilyMemberInsuranceDoc("family_insurance_card", empId);
  }, [getFamilyMemberInsuranceDoc, empId]);
  // console.log("familyinfoview", data);

  const hasDoc = familyInsuranceDocumentList.find((item) => {
    if (data?._id === item.familyMemberId) {
      return true;
    } else {
      return false;
    }
  });
  return (
    <div className="family__info--content mb-3">
      <div className="family__content--header mb-1">
        <h5 className="family__content--title">
          {`${num + 1})`} {data?.firstName} {data?.lastName}{" "}
          {`(${data?.relation})`}
        </h5>
      </div>
      <Row>
        <Col md={12} lg={6}>
          <p>Insurance Number :{data.insuranceNumber}</p>
          {hasDoc ? (
            <div className="d-flex  align-items-start flex-column upload_card">
              <div className="d-flex align-items-center">
                <ButtonView
                  onClick={() => {
                    downloadFamilyInsuranceCard(
                      data?._id,
                      "family_insurance_card",
                      empId
                    );
                  }}
                  title={"Download Card"}
                  styles="secondary"
                />
              </div>
            </div>
          ) : null}
        </Col>
        {/* <Col md={12} lg={6} className="mb-3">
          {hasDoc ? (
            <div className="d-flex mt-1 align-items-start flex-column upload_card">
              <div className="label text-muted me-3">
                Insurance Card Uploaded
              </div>
              <div className="d-flex align-items-center">
                <ButtonView
                  onClick={() => {
                    downloadFamilyInsuranceCard(
                      data?._id,
                      "family_insurance_card",
                      empId
                    );
                  }}
                  title={"Download Card"}
                  styles="secondary"
                />
                <div className="ms-1">
                  <ButtonView
                    onClick={() => deleteCard(hasDoc)}
                    title={"Delete Card"}
                    styles="danger-outline"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <Input
                name="insuranceCard"
                label="Upload Insurance Card"
                type="file"
                placeholder="Upload File"
                onChange={onLoadFile}
                size="fluid"
                accept=".pdf,.png,.jpg,.doc,.docx"
              />
              <div className="mt-2">
                <ButtonView
                  onClick={handleUpload}
                  title={"Upload"}
                  size={"medium"}
                  styles="secondary"
                />
              </div>
            </>
          )}
        </Col> */}
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => ({
  familyInsuranceDocumentList:
    state.familyInsuranceReducer.familyInsuranceDocumentList,
  showInsuranceBox: state.familyInsuranceReducer.showInsuranceBox,
});

export default connect(mapStateToProps, {
  // editFamilyMemberInsurance,

  downloadFamilyInsuranceCard,
  getFamilyMemberInsuranceDoc,
  // removefamilyMemberInsuranceCard,
  // uploadMemberInsuranceCard,
})(FamilyInfoView);
