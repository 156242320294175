import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import "./WfhApplicationStyle.scss";
import { LEAVE_STATUS_PENDING_APPROVAL } from "../../../constants/env";
import WfhApplicationView from "./WfhApplicationView";
import {
  user as getUser,
  initDoc,
  getDocument,
  downloadFile,
  reportingMangerUsersList,
} from "../../../store/user/userActions";
import { list as getHolidays } from "../../../store/holidays/holidaysActions";
import {
  userWfhList as getUserWfhList,
  getUserWfh,
  userWfhListCalender,
  updateWfh,
  updateWFHDetail,
  cancelGetUserWfhBYId,
  updateGetUserWfhData,
} from "../../../store/employeeWfh/employeeWfhAction";
import queryString from "query-string";
import { globals } from "../../../store/globals";
import { Loader } from "../../common";

export class WfhApplicationContainer extends Component {
  employeeId = globals.store.getState().auth.loginUser.employeeId;
  wfhId = 0;
  state = {
    date: new Date().toISOString().split("T")[0],
    combineCalenderData: [],
    desData: [],

    status: 0,

    year: new Date().getFullYear(),
    changingYear: new Date().getFullYear(),

    defaultTabActiveKey: "",
    currentYear: new Date().getFullYear(),
  };

  constructor(props) {
    super(props);
    if (window.location.search !== "") {
      const parsed = queryString.parse(window.location.search);
      console.log("parsed", parsed);
      this.wfhId = parsed.id;
    }

    this.props.getUser();
    this.props.getHolidays();
    if (this.wfhId !== 0) {
      this.props.getUserWfh(this.wfhId);
    }

    this.props.initDoc();
    this.props.getUserWfhList(1, {
      status: LEAVE_STATUS_PENDING_APPROVAL,
      year: this.state.changingYear,
    });
    this.props.userWfhListCalender({
      year: this.state.changingYear,
    });
    this.props.reportingMangerUsersList();
    this.srollToSummary = React.createRef();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  handleSelect = (eventKey) => {
    if (parseInt(eventKey) === 5) {
      this.props.getUserWfhList(1, {
        year: this.state.changingYear,
      });
    } else {
      this.props.getUserWfhList(1, {
        status: parseInt(eventKey),
        year: this.state.changingYear,
      });
    }
  };

  onsetCombineCalenderList = (data) => {
    this.setState({
      combineCalenderData: data,
    });
  };
  onsetDate = (data) => {
    this.setState({
      date: data,
    });
  };
  onsetDescription = (data) => {
    this.setState({
      desData: data,
    });
  };

  onsetDefaultTabActiveKey = (data) => {
    this.setState({
      defaultTabActiveKey: data,
    });
  };
  onsetYear = (data) => {
    // console.log("onsetyera", data);
    this.setState({
      changingYear: data,
    });
  };

  onsetWfhId = (data) => {
    this.wfhId = data;
  };

  render() {
    const {
      userSuccess,
      // currentYear,
      page,
      userWfhList,
      limit,
      totalDocs,
      getUserWfhList,
      downloadFile,
      userDocument,
      getUserWfh,
      getUserWfhData,
      getDocument,
      getUser,
      user,
      initDoc,
      updateWfh,
      updateWFHDetail,
      userWfhListCalender,
      userWfhListSuccess,
      holidaysList,
      userCalender,
      updateGetUserWfhData,
      userWfhListCalenderSuccess,
    } = this.props;
    // console.log("userWfhListCalenderSuccess", userWfhListCalenderSuccess);
    if (userSuccess === false || userWfhListCalenderSuccess === false) {
      return <Loader />;
    } else {
      return (
        <WfhApplicationView
          currentYear={this.state.currentYear}
          userWfhList={userWfhList}
          rowCount={totalDocs}
          rowsPerPage={limit}
          currentPage={page}
          onChangePage={this.handlePageChange}
          getUserWfhList={getUserWfhList}
          cancelGetUserWfhBYId={cancelGetUserWfhBYId}
          handleSelect={this.handleSelect}
          wfhId={this.wfhId}
          onsetWfhId={this.onsetWfhId}
          onsetYear={this.onsetYear}
          onsetDefaultTabActiveKey={this.onsetDefaultTabActiveKey}
          defaultTabActiveKey={this.state.defaultTabActiveKey}
          downloadFile={downloadFile}
          userDocument={userDocument}
          getUserWfh={getUserWfh}
          getUserWfhData={getUserWfhData}
          getDocument={getDocument}
          user={user}
          userSuccess={userSuccess}
          getUser={getUser}
          initDoc={initDoc}
          updateWfh={updateWfh}
          updateWFHDetail={updateWFHDetail}
          employeeId={this.employeeId}
          userWfhListCalender={userWfhListCalender}
          userWfhListSuccess={userWfhListSuccess}
          onsetCombineCalenderList={this.onsetCombineCalenderList}
          onsetDate={this.onsetDate}
          onsetDescription={this.onsetDescription}
          holidaysList={holidaysList}
          combineCalenderData={this.state.combineCalenderData}
          srollToSummary={this.srollToSummary}
          userCalender={userCalender}
          date={this.state.date}
          desData={this.state.desData}
          changingYear={this.state.changingYear}
          updateGetUserWfhData={updateGetUserWfhData}
        />
      );
    }
  }
}

// LeaveApplicationsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    userManagerList: state.user.userManager,
    userWfhList: state.employeeWfhReducer.userWfhList,
    limit: state.employeeWfhReducer.limit,
    totalDocs: state.employeeWfhReducer.totalDocs,
    page: state.employeeWfhReducer.page,
    getUserWfhData: state.employeeWfhReducer.getUserWfhData,
    userCalender: state.employeeWfhReducer.userCalender,
    holidaysList: state.holidays.holidaysList,
    userSuccess: state.user.userSuccess,
    userDocument: state.user.userDocument,
    userWfhListSuccess: state.employeeWfhReducer.userWfhListSuccess,
    userWfhListCalenderSuccess:
      state.employeeWfhReducer.userWfhListCalenderSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: getUser,
      initDoc: initDoc,
      getDocument: getDocument,
      downloadFile: downloadFile,
      reportingMangerUsersList: reportingMangerUsersList,
      getUserWfhList: getUserWfhList,
      getUserWfh: getUserWfh,
      userWfhListCalender: userWfhListCalender,
      getHolidays: getHolidays,
      updateWfh: updateWfh,
      cancelGetUserWfhBYId: cancelGetUserWfhBYId,

      updateWFHDetail: updateWFHDetail,
      updateGetUserWfhData: updateGetUserWfhData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WfhApplicationContainer);
