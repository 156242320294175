import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  LUNCHTYPE_PENDING: "LUNCHTYPE_PENDING",
  LUNCHTYPE_SUCCESS: "LUNCHTYPE_SUCCESS",
  LUNCHTYPE_ERROR: "LUNCHTYPE_ERROR",

  LUNCHTYPE_ADD_PENDING: "LUNCHTYPE_ADD_PENDING",
  LUNCHTYPE_ADD_ERROR: "LUNCHTYPE_ADD_ERROR",
  LUNCHTYPE_ADD_SUCCESS: "LUNCHTYPE_ADD_SUCCESS",

  LUNCHTYPE_EDIT_PENDING: "LUNCHTYPE_EDIT_PENDING",
  LUNCHTYPE_EDIT_ERROR: "LUNCHTYPE_EDIT_ERROR",
  LUNCHTYPE_EDIT_SUCCESS: "LUNCHTYPE_EDIT_SUCCESS",

  LUNCHTYPE_DELETE_PENDING: "LUNCHTYPE_DELETE_PENDING",
  LUNCHTYPE_DELETE_ERROR: "LUNCHTYPE_DELETE_ERROR",
  LUNCHTYPE_DELETE_SUCCESS: "LUNCHTYPE_DELETE_SUCCESS",
};

export function setLunchPreference(lunchTypeId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCHTYPE_SUCCESS,
      payload: {
        selectedLunchType: lunchTypeId,
      },
    });
    dispatch(
      showAlert({
        isOpen: true,
        title: "Success",
        type: "success",
        msg: "Lunch data updated successfully.",
      })
    );
  };
}

function showLunchError(id) {
  return (dispatch) => {
    Api.get(`lunchPreferenceSettings/list/${id}`).then((response) => {
      // console.log(response);
      dispatch(
        showAlert({
          isOpen: true,
          title: "Error",
          type: "danger",
          msg: `You can't edit lunch setting ${response.data[0].bookingCloseStartTime} - ${response.data[0].bookingCloseEndTime}.`,
        })
      );
    });
  };
}

export function deleteLunchPreference(empId, userData) {
  return (dispatch) => {
    //  console.log(id)
    var today = new Date();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    // console.log(time);
    if (userData) {
      Api.post("lunchPreferenceSettings/canUpdateLunchPreference", {
        updateTime: time,
        location: userData[0]._id,
      })
        .then((response) => {
          if (response.data.canUpdateLunchPreference) {
            const data = {
              employeeId: empId,
            };
            Api.post("lunch/search", data).then((response) => {
              // console.log(response)
              if (response.data.length > 0) {
                Api.delete(`lunch/delete/${response.data[0]._id}`)
                  .then((response) => {
                    dispatch({
                      type: actionTypes.LUNCHTYPE_SUCCESS,
                      payload: {
                        selectedLunchType: "",
                      },
                    });
                    dispatch(
                      showAlert({
                        isOpen: true,
                        title: "Success",
                        type: "success",
                        msg: "Lunch data updated successfully.",
                      })
                    );
                  })
                  .catch((err) => console.log(err));
              }
            });
          } else {
            dispatch(showLunchError(userData[0]._id));
          }
        })
        .catch((err) => {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to set lunch.",
            })
          );
        });
    } else {
      const data = {
        employeeId: empId,
      };
      Api.post("lunch/search", data).then((response) => {
        // console.log(response)
        if (response.data.length > 0) {
          Api.delete(`lunch/delete/${response.data[0]._id}`)
            .then((response) => {
              dispatch({
                type: actionTypes.LUNCHTYPE_SUCCESS,
                payload: {
                  selectedLunchType: "",
                },
              });
              dispatch(
                showAlert({
                  isOpen: true,
                  title: "Success",
                  type: "success",
                  msg: "Lunch data updated successfully.",
                })
              );
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };
}

export function searchLunch(userData) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCHTYPE_PENDING,
      payload: {
        lunchTypePending: true,
        lunchTypeSuccess: false,
        lunchTypeError: null,
      },
    });
    // console.log(userData)
    const data = {
      employeeId: userData._id,
    };
    // console.log(JSON.stringify(data))
    Api.post("lunch/search", data).then((response) => {
      // console.log(response)
      if (response.data.length > 0) {
        dispatch(list(userData, response.data[0].lunchPreference._id));
      } else {
        dispatch(list(userData, ""));
      }
    });
  };
}

export function list(userData = false, userLunch = false) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCHTYPE_PENDING,
      payload: {
        lunchTypePending: true,
        lunchTypeSuccess: false,
        lunchTypeError: null,
      },
    });

    Api.get("lunchType/list")
      .then((response) => {
        // console.log(response)

        if (response.data) {
          if (userData) {
            var lunchTypeList = [];

            response.data.forEach((lunchType) => {
              if (lunchType.location.indexOf(userData.location[0]._id) !== -1) {
                lunchTypeList.push(lunchType);
              }
            });

            dispatch({
              type: actionTypes.LUNCHTYPE_SUCCESS,
              payload: {
                lunchTypePending: false,
                lunchTypeSuccess: true,
                lunchTypeError: null,

                lunchTypeList: lunchTypeList,
                allLunchTypeList: response.data,
                selectedLunchType: userLunch,
              },
            });
          } else {
            dispatch({
              type: actionTypes.LUNCHTYPE_SUCCESS,
              payload: {
                lunchTypePending: false,
                lunchTypeSuccess: true,
                lunchTypeError: null,
                allLunchTypeList: response.data,
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.LUNCHTYPE_ERROR,
            payload: {
              lunchTypePending: false,
              lunchTypeSuccess: true,
              lunchTypeError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get lunch list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCHTYPE_ERROR,
          payload: {
            lunchTypePending: false,
            lunchTypeSuccess: true,
            lunchTypeError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get lunch list.",
          })
        );
      });
  };
}

export function addLunchType(lunchType, description, location) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCHTYPE_ADD_PENDING,
      payload: {
        lunchTypeAddPending: true,
        lunchTypeAddSuccess: false,
        lunchTypeAddError: null,
      },
    });

    const data = {
      lunchType: lunchType,
      description: description,
      location: location,
    };
    // console.log(data)

    Api.post("lunchType/create", data)
      .then((response) => {
        // console.log(response)
        if (response.message === "SuccessFully added lunch type") {
          dispatch({
            type: actionTypes.LUNCHTYPE_ADD_SUCCESS,
            payload: {
              lunchTypeAddPending: false,
              lunchTypeAddSuccess: true,
              lunchTypeAddError: null,

              lunchType: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new lunchType.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCHTYPE_ADD_ERROR,
          payload: {
            lunchTypeAddPending: false,
            lunchTypeAddSuccess: true,
            lunchTypeAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add lunchType.",
          })
        );
      });
  };
}

export function editLunchType(lunchType, description, location, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCHTYPE_EDIT_PENDING,
      payload: {
        lunchTypeEditPending: true,
        lunchTypeEditSuccess: false,
        lunchTypeEditError: null,
      },
    });

    const data = {
      lunchType: lunchType,
      description: description,
      location: location,
    };
    // console.log(data, id)

    Api.put(`lunchType/update/${id}`, data)
      .then((response) => {
        // console.log(response)
        if (response.message === "SuccessFully updated lunch type") {
          dispatch({
            type: actionTypes.LUNCHTYPE_EDIT_SUCCESS,
            payload: {
              lunchTypeEditPending: false,
              lunchTypeEditSuccess: true,
              lunchTypeEditError: null,

              lunchType: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated lunchType.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCHTYPE_EDIT_ERROR,
          payload: {
            lunchTypeEditPending: false,
            lunchTypeEditSuccess: true,
            lunchTypeEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit lunchType.",
          })
        );
      });
  };
}

export function deleteLunchType(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCHTYPE_DELETE_PENDING,
      payload: {
        lunchTypeDeletePending: true,
        lunchTypeDeleteSuccess: false,
        lunchTypeDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`lunchType/delete/${id}`)
      .then((response) => {
        // console.log(response)
        if (response.message === "SuccessFully deleted lunch type") {
          dispatch({
            type: actionTypes.LUNCHTYPE_DELETE_SUCCESS,
            payload: {
              lunchTypeDeletePending: false,
              lunchTypeDeleteSuccess: true,
              lunchTypeDeleteError: null,

              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted lunchType.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCHTYPE_DELETE_ERROR,
          payload: {
            lunchTypeDeletePending: false,
            lunchTypeDeleteSuccess: true,
            lunchTypeDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add lunchType.",
          })
        );
      });
  };
}
