import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import Accepted from "./Accepted";
import All from "./All";
import Completed from "./Completed";
import PendingReview from "./PendingReview";
import Rejected from "./Rejected";
import Unaccepted from "./Unaccepted";
import {
  INTERVIEW_STATUS_SCHEDULED,
  INTERVIEW_STATUS_ACCEPTED,
  INTERVIEW_STATUS_COMPLETED,
  INTERVIEW_STATUS_REJECTED,
  INTERVIEW_STATUS_INCOMPLETED,
  INTERVIEW_STATUS_CANCELLED,
} from "../../../../constants/env";
import { ButtonView } from "../../../controls";

const MyLeaveApplications = ({
  interviewScheduleList,
  totalInterviewScheduleList,
  updateInterviewScheduleStatusByInterviewer,
  jobApplicationById,
  user,
  updateJobApplicationStatus,
  downloadJobApplicationDocument,
  interviewId,
  onsetInterviewId,
  viewAllInterviewSchedule,
  onsetDate,
}) => {
  // console.log(interviewScheduleList);
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  return (
    <div className="card">
      <div className="card-header border-0 mb-3">
        <h5 className="card-title">Scheduled Interview(s)</h5>
        {totalInterviewScheduleList.length !== interviewScheduleList.length && (
          <ButtonView
            onClick={() => {
              viewAllInterviewSchedule();
              onsetDate(new Date().toISOString().split("T")[0]);
            }}
            title={"View All"}
            styles="secondary"
          />
        )}
      </div>
      <Tabs
        defaultActiveKey="unaccepted"
        id="leave_application"
        className="table_tabs"
      >
        <Tab
          eventKey="unaccepted"
          title={
            <span className="notification_container">
              UNACCEPTED{" "}
              {interviewScheduleList.filter(
                (list) => list.status === INTERVIEW_STATUS_SCHEDULED
              ).length ? (
                <div className="notification_dots"></div>
              ) : null}
            </span>
          }
        >
          <Unaccepted
            data={interviewScheduleList.filter(
              (list) => list.status === INTERVIEW_STATUS_SCHEDULED
            )}
            updateInterviewScheduleStatusByInterviewer={
              updateInterviewScheduleStatusByInterviewer
            }
            jobApplicationById={jobApplicationById}
            user={user}
            updateJobApplicationStatus={updateJobApplicationStatus}
            downloadJobApplicationDocument={downloadJobApplicationDocument}
            interviewId={interviewId}
            onsetInterviewId={onsetInterviewId}
            onsetDate={onsetDate}
          />
        </Tab>
        <Tab
          eventKey="accepted"
          title={
            <span className="notification_container">
              ACCEPTED{" "}
              {interviewScheduleList.filter(
                (list) =>
                  list.status === INTERVIEW_STATUS_ACCEPTED &&
                  new Date(list.interviewDate) > now
              ).length ? (
                <div className="notification_dots"></div>
              ) : null}
            </span>
          }
        >
          <Accepted
            data={interviewScheduleList.filter(
              (list) =>
                list.status === INTERVIEW_STATUS_ACCEPTED &&
                new Date(list.interviewDate) > now
            )}
            updateInterviewScheduleStatusByInterviewer={
              updateInterviewScheduleStatusByInterviewer
            }
            jobApplicationById={jobApplicationById}
            user={user}
            updateJobApplicationStatus={updateJobApplicationStatus}
            downloadJobApplicationDocument={downloadJobApplicationDocument}
          />
        </Tab>
        <Tab
          eventKey="reviewpending"
          title={
            <span className="notification_container">
              REVIEW PENDING{" "}
              {interviewScheduleList.filter(
                (list) =>
                  list.status === INTERVIEW_STATUS_ACCEPTED &&
                  new Date(list.interviewDate) < now
              ).length ? (
                <div className="notification_dots"></div>
              ) : null}
            </span>
          }
        >
          <PendingReview
            data={interviewScheduleList.filter(
              (list) =>
                list.status === INTERVIEW_STATUS_ACCEPTED &&
                new Date(list.interviewDate) < now
            )}
            updateInterviewScheduleStatusByInterviewer={
              updateInterviewScheduleStatusByInterviewer
            }
            jobApplicationById={jobApplicationById}
            user={user}
            updateJobApplicationStatus={updateJobApplicationStatus}
            downloadJobApplicationDocument={downloadJobApplicationDocument}
          />
        </Tab>
        <Tab eventKey="completed" title="COMPLETED">
          <Completed
            data={interviewScheduleList.filter(
              (list) => list.status === INTERVIEW_STATUS_COMPLETED
            )}
          />
        </Tab>
        <Tab eventKey="rejected" title="REJECTED">
          <Rejected
            data={interviewScheduleList.filter(
              (list) => list.status === INTERVIEW_STATUS_REJECTED
            )}
            downloadJobApplicationDocument={downloadJobApplicationDocument}
          />
        </Tab>
        <Tab eventKey="all" title="ALL">
          <All
            data={interviewScheduleList.filter(
              (list) =>
                list.status !== INTERVIEW_STATUS_CANCELLED &&
                list.status !== INTERVIEW_STATUS_INCOMPLETED
            )}
            downloadJobApplicationDocument={downloadJobApplicationDocument}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default MyLeaveApplications;
