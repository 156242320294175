import React, { useState, useEffect } from "react";
import { Card, Row, Modal, Col } from "react-bootstrap";
import { DataTable } from "../../../common";
import { ButtonView } from "../../../controls";
import EmployeeDownload from "./EmployeeDownload";
import { AiOutlineClose } from "react-icons/ai";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import moment from "moment";

const EmployeeSkills = ({
  UserSkillFilteredList,
  downloadSkillCertificates,
  saveCategoryTechnolgy,
}) => {
  const [showSkillModal, setShowSkillModal] = useState("");
  const [showCertificates, setShowCertificates] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedUserSkills, setSelectedUserSkills] = useState([]);
  const [selectedUserSkillCertificate, setSelectedUserSkillCertificate] =
    useState([]);

  const [disabledButtons, setDisabledButtons] = useState([]);

  const handleAddToMasterClick = (row) => {
    if (!disabledButtons.includes(row._id)) {
      setDisabledButtons([...disabledButtons, row._id]);

      const data = {
        categoryId: row.categoryId,
        categoryName: row.categoryName,
        technologyId: row.technologyId,
        technologyName: row.technologyName,
      };
      saveCategoryTechnolgy(data);
    }
  };

  useEffect(() => {
    if (UserSkillFilteredList !== "") {
      const userSkills = Object.values(
        UserSkillFilteredList.reduce((acc, obj) => {
          const {
            _id,
            userId,
            categoryId,
            categoryName,
            technologyId,
            technologyName,
            proficiencyId,
            certificateId,
            categoryTechnologyId,
          } = obj;
          const key = userId._id;

          if (!acc[key]) {
            acc[key] = {
              _id: userId._id,
              userId: userId,
              skills: [],
            };
          }

          acc[key].skills.push({
            _id: _id,
            categoryId: categoryId,
            categoryName: categoryName,
            technologyId: technologyId,
            technologyName: technologyName,
            proficiencyId: proficiencyId?._id ? proficiencyId?._id : "-",
            proficiencyName: proficiencyId?.proficiencyName
              ? proficiencyId?.proficiencyName
              : "-",
            categoryTechnologyId: categoryTechnologyId,
            certificateId: certificateId,
          });

          return acc;
        }, {})
      );

      setFilteredData(userSkills);
    }
  }, [UserSkillFilteredList]);

  // console.log(filteredData);
  // console.log(UserSkillFilteredList);
  const data = filteredData;

  const columns = [
    {
      name: "Name",
      center: false,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          {row?.userId?.firstName}
          {row?.userId?.lastName && (
            <span className="d-block d-md-none ms-1">
              {" "}
              {row?.userId?.lastName.slice(0, 1)}
            </span>
          )}
          {row.userId.lastName && (
            <span className="d-none d-md-block ms-1">
              {" "}
              {row?.userId?.lastName ? row?.userId?.lastName : "-"}
            </span>
          )}
        </span>
      ),
    },
    {
      name: "Option",
      right: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          <ButtonView
            title={"View"}
            onClick={() => {
              setSelectedUserSkills(row.skills);
              handleShowModal();
            }}
            styles="secondary"
          />
        </span>
      ),
    },
  ];
  const columnsSkills = [
    {
      name: "Category",
      center: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          {row?.categoryName ? row?.categoryName : "-"}
        </span>
      ),
    },
    {
      name: "Technology",
      center: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          {row?.technologyName ? row?.technologyName : "-"}
        </span>
      ),
    },
    {
      name: "Proficiency",
      center: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          {row.proficiencyName ? row?.proficiencyName : "-"}
        </span>
      ),
    },
    {
      name: "Certificates",
      center: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          {" "}
          {row.certificateId.length > 0 ? (
            <ButtonView
              title={"View"}
              onClick={() => {
                setShowCertificates(true);
                setSelectedUserSkillCertificate(row.certificateId);
                console.log(row);
              }}
              size="small"
            />
          ) : (
            // <ButtonView title={"View"} disabled={true} size="small" />
            "-"
          )}
        </span>
      ),
    },
    {
      name: "Option",
      center: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          {row.categoryTechnologyId === "Other" ? (
            <ButtonView
              title={"Add To Master"}
              onClick={() => handleAddToMasterClick(row)}
              disabled={disabledButtons.includes(row._id)}
              styles="secondary"
              size="large"
            />
          ) : (
            "-"
          )}
        </span>
      ),
    },
  ];
  const columnsCertificates = [
    {
      name: "Name",
      center: true,
      grow: 2,
      cell: (row) => <span className="d-flex">{row.certificateName}</span>,
    },
    {
      name: "Expiry Date",
      center: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          {row.validCertificationDate
            ? moment(row.validCertificationDate).format("DD/MM/YYYY")
            : "N/A"}
        </span>
      ),
    },
    {
      name: "Option",
      center: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          <ButtonView
            title={"Download"}
            onClick={() => {
              console.log(row);
              const data = {
                skillsId: row.skillsId,
                certificateId: row._id,
              };
              downloadSkillCertificates(data);
            }}
            styles="secondary"
            size="large"
          />
        </span>
      ),
    },
  ];

  const handleShowModal = () => {
    setShowSkillModal(true);
  };

  const handleClose = () => {
    setShowSkillModal(false);
    setShowCertificates(false);
  };

  return (
    <div className="Employee_Skills_List mb-3">
      <Card className="skills_list">
        <Card.Header>
          <Card.Title className="mt-1 mt-lg-0 fs-sm-1">
            Employee Skill(s)
          </Card.Title>
          {UserSkillFilteredList.length > 0 && (
            <EmployeeDownload filteredData={UserSkillFilteredList} />
          )}
        </Card.Header>
        <Card.Body>
          <DataTable
            columns={columns}
            data={data}
            pagination={true}
            paginationPerPage={20}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal show={showSkillModal} onHide={handleClose} size="xl">
        <Modal.Header>
          <Modal.Title>
            {showCertificates ? (
              <div className="d-flex align-items-center">
                <div
                  className="me-1"
                  onClick={() => {
                    showCertificates
                      ? setShowCertificates(false)
                      : handleClose();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FaArrowAltCircleLeft size={40} />
                </div>{" "}
                <div>Certificates</div>
              </div>
            ) : (
              "Skills"
            )}
          </Modal.Title>
          <div
            onClick={() => {
              handleClose();
            }}
            style={{ cursor: "pointer" }}
          >
            {showCertificates ? null : <AiOutlineClose size={20} />}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <DataTable
                columns={showCertificates ? columnsCertificates : columnsSkills}
                data={
                  showCertificates
                    ? selectedUserSkillCertificate
                    : selectedUserSkills
                }
                pagination={showCertificates ? false : true}
                paginationPerPage={20}
                paginationServer={false}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EmployeeSkills;
