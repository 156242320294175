import { actionTypes } from "./familyInsuranceActions";

const {
  FAMILY_INSURANCE_PENDING,
  FAMILY_INSURANCE_SUCCESS,
  FAMILY_INSURANCE_ERROR,

  ADD_FAMILY_INSURANCE_PENDING,
  ADD_FAMILY_INSURANCE_SUCCESS,
  ADD_FAMILY_INSURANCE_ERROR,

  UPDATE_FAMILY_INSURANCE_PENDING,
  UPDATE_FAMILY_INSURANCE_SUCCESS,
  UPDATE_FAMILY_INSURANCE_ERROR,

  DELETE_FAMILY_INSURANCE_PENDING,
  DELETE_FAMILY_INSURANCE_SUCCESS,
  DELETE_FAMILY_INSURANCE_ERROR,

  GET_FAMILY_INSURANCEDOC_PENDING,
  GET_FAMILY_INSURANCEDOC_SUCCESS,
  GET_FAMILY_INSURANCEDOC_ERROR,

  FAMILY_INSURANCEDOC_SUCCESS,
  FAMILY_INSURANCEDOC_PENDING,
  FAMILY_INSURANCEDOC_ERROR,

  UPDATE_FAMILY_INSURANCEDOC_SUCCESS,
  UPDATE_FAMILY_INSURANCEDOC_PENDING,
  UPDATE_FAMILY_INSURANCEDOC_ERROR,
} = actionTypes;

const initialState = {
  familyInsurancePending: false,
  familyInsuranceSuccess: false,
  familyInsuranceError: null,

  addFamilyInsurancePending: false,
  addFamilyInsuranceSuccess: false,
  addFamilyInsuranceError: null,

  updateFamilyInsurancePending: false,
  updateFamilyInsuranceSuccess: false,
  updateFamilyInsuranceError: null,

  deleteFamilyInsurancePending: false,
  deleteFamilyInsuranceSuccess: false,
  deleteFamilyInsuranceError: null,

  familyInsuranceDocPending: false,
  familyInsuranceDocSuccess: false,
  familyInsuranceDocError: null,

  updateFamilyInsuranceDocPending: false,
  updateFamilyInsuranceDocSuccess: false,
  updateFamilyInsuranceDocError: null,

  familyMemberInsuranceList: [],
  familyInsuranceDocumentList: [],

  familyInsuranceDocument: null,
  showInsuranceBox: false,
};

export default function familyInsuranceReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FAMILY_INSURANCE_PENDING:
    case FAMILY_INSURANCE_ERROR:
    case FAMILY_INSURANCE_SUCCESS:
      return Object.assign({}, state, payload);

    case ADD_FAMILY_INSURANCE_PENDING:
    case ADD_FAMILY_INSURANCE_ERROR:
      return Object.assign({}, state, payload);

    case ADD_FAMILY_INSURANCE_SUCCESS: {
      const newFamilyInsuranceList = [
        ...state.familyMemberInsuranceList,
        payload.familyInsuranceData,
      ];
      return Object.assign(
        {},
        state,
        { familyMemberInsuranceList: newFamilyInsuranceList },
        payload
      );
    }

    case UPDATE_FAMILY_INSURANCE_PENDING:
    case UPDATE_FAMILY_INSURANCE_ERROR:
      return Object.assign({}, state, payload);
    case UPDATE_FAMILY_INSURANCE_SUCCESS: {
      const newFamilyInsuranceList = state.familyMemberInsuranceList.map(
        (list) => {
          if (list._id === payload.familyInsuranceData._id) {
            return payload.familyInsuranceData;
          } else {
            return list;
          }
        }
      );
      return Object.assign(
        {},
        state,
        { familyMemberInsuranceList: newFamilyInsuranceList },
        payload
      );
    }
    case DELETE_FAMILY_INSURANCE_PENDING:
    case DELETE_FAMILY_INSURANCE_ERROR:
      return Object.assign({}, state, payload);
    case DELETE_FAMILY_INSURANCE_SUCCESS: {
      const newFamilyInsuranceList = state.familyMemberInsuranceList.filter(
        (list) => {
          return list._id !== payload.id;
        }
      );
      return Object.assign(
        {},
        state,
        { familyMemberInsuranceList: newFamilyInsuranceList },
        payload
      );
    }

    case GET_FAMILY_INSURANCEDOC_PENDING:
    case GET_FAMILY_INSURANCEDOC_SUCCESS:
    case GET_FAMILY_INSURANCEDOC_ERROR:
      return Object.assign({}, state, payload);
    case FAMILY_INSURANCEDOC_PENDING:
    case FAMILY_INSURANCEDOC_SUCCESS: {
      const newfamilyInsuranceDocumentList =
        state.familyInsuranceDocumentList.filter((list) => {
          return list._id !== payload.id;
        });
      return Object.assign(
        {},
        state,
        { familyInsuranceDocumentList: newfamilyInsuranceDocumentList },
        payload
      );
    }
    case FAMILY_INSURANCEDOC_ERROR:
      return Object.assign({}, state, payload);

    case UPDATE_FAMILY_INSURANCEDOC_PENDING:
    case UPDATE_FAMILY_INSURANCEDOC_ERROR:
      return Object.assign({}, state, payload);
    case UPDATE_FAMILY_INSURANCEDOC_SUCCESS: {
      const newfamilyInsuranceDocumentList = [
        ...state.familyInsuranceDocumentList,
        payload.familyInsuranceDocument,
      ];

      return Object.assign(
        {},
        state,
        { familyInsuranceDocumentList: newfamilyInsuranceDocumentList },
        payload
      );
    }
    default:
      return state;
  }
}
