import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  LEAVES_PER_YEAR_PENDING: "LEAVES_PER_YEAR_PENDING",
  LEAVES_PER_YEAR_SUCCESS: "LEAVES_PER_YEAR_SUCCESS",
  LEAVES_PER_YEAR_ERROR: "LEAVES_PER_YEAR_ERROR",

  LEAVES_PER_YEAR_ADD_PENDING: "LEAVES_PER_YEAR_ADD_PENDING", 
  LEAVES_PER_YEAR_ADD_ERROR: "LEAVES_PER_YEAR_ADD_ERROR", 
  LEAVES_PER_YEAR_ADD_SUCCESS: "LEAVES_PER_YEAR_ADD_SUCCESS",

  LEAVES_PER_YEAR_EDIT_PENDING: "LEAVES_PER_YEAR_EDIT_PENDING", 
  LEAVES_PER_YEAR_EDIT_ERROR: "LEAVES_PER_YEAR_EDIT_ERROR", 
  LEAVES_PER_YEAR_EDIT_SUCCESS: "LEAVES_PER_YEAR_EDIT_SUCCESS",

  LEAVES_PER_YEAR_DELETE_PENDING: "LEAVES_PER_YEAR_DELETE_PENDING", 
  LEAVES_PER_YEAR_DELETE_ERROR: "LEAVES_PER_YEAR_DELETE_ERROR", 
  LEAVES_PER_YEAR_DELETE_SUCCESS: "LEAVES_PER_YEAR_DELETE_SUCCESS",
};

export function addLeavesPerYear(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEAVES_PER_YEAR_ADD_PENDING,
      payload: {
        leavesPerYearAddPending: true,
        leavesPerYearAddSuccess: false,
        leavesPerYearAddError: null,
      },
    });
    // console.log(data)

    Api.post("leaveManagement/hradmin/addLeavesPerYearRecord", data)
      .then(response => {
        console.log(response)
        if(response.message === "Successfully added new leave per year record") {
          dispatch(list(false))
          // const newLeavePerYear = {
          //   leaveType: leaveTypesList.find(i => i._id === response.data.leaveType),
          //   totalLeaveDays: response.data.totalLeaveDays,
          //   _id: response.data._id
          // }
          // console.log(newLeavePerYear)
          dispatch({
            type: actionTypes.LEAVES_PER_YEAR_ADD_SUCCESS,
            payload: {
              leavesPerYearAddPending: false,
              leavesPerYearAddSuccess: true,
              leavesPerYearAddError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new leave type.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.LEAVES_PER_YEAR_ADD_ERROR,
          payload: {
            leavesPerYearAddPending: false,
            leavesPerYearAddSuccess: true,
            leavesPerYearAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add leave type.",
          })
        );
      })
  }
}

export function editLeavesPerYear(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEAVES_PER_YEAR_EDIT_PENDING,
      payload: {
        leavesPerYearEditPending: true,
        leavesPerYearEditSuccess: false,
        leavesPerYearEditError: null,
      },
    });
    // console.log(JSON.stringify(data))
    // console.log(id)

    Api.put(`leaveManagement/hradmin/updateLeavesPerYearRecord/${id}`, data)
      .then(response => {
        console.log(response)
        if(response.message === "Successfully updated leave per year record") {
          dispatch(list(false))
          // const newLeavePerYear = {
          //   leaveType: leaveTypesList.find(i => i._id === response.data.leaveType),
          //   totalLeaveDays: response.data.totalLeaveDays,
          //   _id: response.data._id
          // }
          dispatch({
            type: actionTypes.LEAVES_PER_YEAR_EDIT_SUCCESS,
            payload: {
              leavesPerYearEditPending: false,
              leavesPerYearEditSuccess: true,
              leavesPerYearEditError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated leave type.",
            })
          );
        }
      })
      .catch(err => {
        // console.log(err)
        dispatch({
          type: actionTypes.LEAVES_PER_YEAR_EDIT_ERROR,
          payload: {
            leavesPerYearEditPending: false,
            leavesPerYearEditSuccess: true,
            leavesPerYearEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit leave type.",
          })
        );
      })
  }
}

export function deleteLeavesPerYear(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEAVES_PER_YEAR_DELETE_PENDING,
      payload: {
        leavesPerYearDeletePending: true,
        leavesPerYearDeleteSuccess: false,
        leavesPerYearDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`leaveManagement/hradmin/deleteLeavesPerYearRecord/${id}`)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully deleted leave per year record") {
          dispatch({
            type: actionTypes.LEAVES_PER_YEAR_DELETE_SUCCESS,
            payload: {
              leavesPerYearDeletePending: false,
              leavesPerYearDeleteSuccess: true,
              leavesPerYearDeleteError: null,
  
              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted leave type.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.LEAVES_PER_YEAR_DELETE_ERROR,
          payload: {
            leavesPerYearDeletePending: false,
            leavesPerYearDeleteSuccess: true,
            leavesPerYearDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add leave type.",
          })
        );
      })
  }
}

export function list(showLoader = true) {
  return (dispatch) => {
    if(showLoader) {
      dispatch({
        type: actionTypes.LEAVES_PER_YEAR_PENDING,
        payload: {
          leavesPerYearPending: true,
          leavesPerYearSuccess: false,
          leavesPerYearError: null,
        },
      });
    }

    Api.get("leaveManagement/hradmin/getLeavesPerYearList")
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully fetched leave per year record list") {
          dispatch({
            type: actionTypes.LEAVES_PER_YEAR_SUCCESS,
            payload: {
              leavesPerYearPending: false,
              leavesPerYearSuccess: true,
              leavesPerYearError: null,
  
              leavesPerYearList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.LEAVES_PER_YEAR_SUCCESS,
          payload: {
            leavesPerYearPending: false,
            leavesPerYearSuccess: true,
            leavesPerYearError: null,
          },
        });
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.LEAVES_PER_YEAR_ERROR,
          payload: {
            leavesPerYearPending: false,
            leavesPerYearSuccess: true,
            leavesPerYearError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get leavesPerYear list.",
          })
        );
      })
  }
}