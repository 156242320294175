import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { ButtonView, Input, TextArea } from "../../../controls";

const AddTechDomain = ({ addTechDomain, setAddTechDomainShow }) => {
  const [techDomain, setTechDomain] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const handleAddHoliday = () => {
    var rules = { required: ["techDomain"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ techDomain }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      const data = { name: techDomain, extraInfo: description };
      // console.log(data);
      // console.log(techDomain, description);
      addTechDomain(data);
      setAddTechDomainShow(false);
      setTechDomain("");
      setDescription("");
      setErrors({});
      // add
      // handleClose();
    }
  };
  return (
    <div className="add_tech_domain">
      <div className="add_role">
        <Card className="add_tech_domain_content">
          <Card.Title className="add_domain_title">
            Add Technical Domain
          </Card.Title>
          <Card.Body>
            <Row className="mb-2">
              <Col xs={12} className="mb-3">
                <Input
                  name="techDomain"
                  label="Technical Domain"
                  value={techDomain}
                  type="text"
                  placeholder="Add Technical Domain"
                  onChange={(e) => setTechDomain(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["techDomain"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12}>
                <TextArea
                  name="description"
                  label="Description"
                  value={description}
                  type="number"
                  placeholder="Some text here..."
                  onChange={(e) => setDescription(e.target.value)}
                  size="fluid"
                />
              </Col>
            </Row>
            <Row className="g-2 d-flex justify-content-end">
              <Col xs={6} className="mt-3">
                <ButtonView
                  onClick={handleAddHoliday}
                  title={"Add"}
                  styles="secondary"
                />
              </Col>
              <Col xs={6} className="mt-3">
                <ButtonView
                  onClick={() => {
                    setAddTechDomainShow(false);
                  }}
                  title={"Cancel"}
                  styles="danger-outline"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default AddTechDomain;
