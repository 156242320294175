import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { ButtonView, Input, TextArea } from "../../../controls";

const ApplyNewQuery = ({ user, applyForQuery }) => {
  const data = {
    subject: "",
    message: "",
  };

  const [queryData, setQueryData] = useState(data);
  const [applyForWfhShow, setApplyForWfhShow] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setApplyForWfhShow(true);
  };

  const handleSubmit = () => {
    var rules = {
      required: ["subject", "message"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(queryData),
    });
    setErrors(errors);
    // console.log("user", !Object.keys(errors).length);
    // var startDate = new Date(wfhData.startDate);

    if (!Object.keys(errors).length) {
      applyForQuery(queryData, user);
      handleClose();
    }
  };

  const handleClose = () => {
    // updateGetUserWfhData();
    setApplyForWfhShow(false);
    setQueryData(data);

    setErrors({});
  };

  return (
    <>
      <div className="card-header d-flex border-0 mb-3">
        <h5 className="card-title"> New Query Application(s)</h5>
        <div className="d-flex flex-wrap">
          <div style={{ marginTop: "6px" }} className="ms">
            <ButtonView
              onClick={handleOpen}
              title={"New Queries"}
              styles="secondary"
            />
          </div>
        </div>
      </div>
      <Modal
        show={applyForWfhShow}
        onHide={handleClose}
        aria-labelledby="filter_modal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title id="filter_modal">New Query Application</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="subject"
                label="Subject"
                type="text"
                // isNum={true}
                value={queryData.subject}
                placeholder="Subject"
                onChange={(e) =>
                  setQueryData({ ...queryData, subject: e.target.value })
                }
                size="fluid"
                error={errors["subject"]}
                isrequired={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <TextArea
                name="message"
                label="Reason"
                value={queryData.message}
                type="textarea"
                placeholder="Type something..."
                onChange={(e) =>
                  setQueryData({ ...queryData, message: e.target.value })
                }
                size="fluid"
                error={errors["message"]}
                isrequired={true}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                onClick={() => {
                  handleClose();
                }}
                title={"Cancel"}
                styles="danger-outline"
              />
            </Col>
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                onClick={handleSubmit}
                // onClick={() => {
                //   handleSubmit();
                // }}
                title={"Save"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplyNewQuery;
