import React, { useState } from "react";
import moment from "moment";
// import { MdCall } from "react-icons/md";
import { AiOutlineArrowDown } from "react-icons/ai";

import { Modal, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { ButtonView, CircularButtonView } from "../../../controls";
import { DataTable } from "../../../common";
import { LEAVE_STATUS_APPROVED } from "../../../../constants/env";
import { AiFillEye } from "react-icons/ai";
const Rejected = ({
  data,
  user,
  initDoc,
  getDocument,
  userDocument,
  updateLeave,
  downloadFile,
  showNextYearLeave,
  currentYear,
}) => {
  // console.log("rejected", data);
  const newData = data.filter((item) => {
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    if (new Date(item.startDate) < now) {
      return false;
    } else return true;
  });

  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="badge bg-danger-transparent">Rejected</span>
      ),
    },
    {
      name: "Type",
      selector: "masterLeaveType",
      center: true,
      grow: 4,
    },
    {
      name: "FD/1H/2H",
      selector: "leaveSubType",
      center: true,
      grow: 4,
    },
    {
      name: "Employee",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {row.userId[0].firstName} {row.userId[0].lastName.slice(0, 1)}
        </span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "70px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <AiFillEye
            className="view"
            size={18}
            onClick={() => {
              handleViewOpen(row);
              if (row.attachementDocumentId) {
                getDocument(
                  row.employeeId[0],
                  true,
                  false,
                  row.attachementDocumentId
                );
              }
              // handleViewOpen()
            }}
          />
        </div>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "120px",
      // grow: 4,
      cell: (row) => {
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        if (new Date(row.startDate) < now) {
          return "";
        } else {
          return (
            <div className="d-flex">
              <ButtonView
                onClick={() => handleApproveLeave(row)}
                title={"Approve"}
                size="small"
              />
            </div>
          );
        }
      },
    },
  ];

  const handleViewOpen = (row) => {
    setViewDetails(row);
    setViewDetailsShow(true);
    // console.log(row)
  };

  const handleApproveLeave = (row) => {
    if (window.confirm("Approve leave?")) {
      updateLeave(
        row._id,
        user,
        "",
        LEAVE_STATUS_APPROVED,
        "Successfully approved leave.",
        currentYear
      );
    }
  };

  const handleClose = () => {
    setViewDetailsShow(false);
    initDoc();
  };
  // console.log("data", newData);
  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <DataTable
          data={newData}
          columns={columns}
          pagination={true}
          paginationPerPage={5}
          paginationServer={false}
        />
      </div>

      {viewDetails && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Application Detail</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="comments ms-1 mb-3 d-flex justify-content-between align-items-center flex-wrap">
                <h5>
                  Rejected by{" "}
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.lastUpdatedBy}
                  </span>
                </h5>
                {viewDetails.masterLeaveType === "Optional Holiday Leave" && (
                  <h5>
                    <span
                      style={{ fontSize: "15px" }}
                      className="badge badge-success"
                    >
                      Optional Holiday
                    </span>
                  </h5>
                )}
              </div>
              {viewDetails.rejectionOrCancellationComments && (
                <div>
                  <div className="comments ms-1">
                    <h6>Rejection Reason:</h6>
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      // disabled
                      readOnly
                      value={viewDetails.rejectionOrCancellationComments}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </div>
              )}
              <div className="comments ms-1">
                <h5>
                  Applier:{" "}
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.userId[0].firstName}{" "}
                    {viewDetails.userId[0].lastName}
                  </span>
                </h5>
              </div>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  FD/1H/2H
                  <span>{viewDetails.leaveSubType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Leave Type:
                  <span>{viewDetails.masterLeaveType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sub Leave Type:
                  <span>{viewDetails.leaveType.description}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Start Date:
                  <span>
                    {moment(new Date(viewDetails.startDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  End Date:
                  <span>
                    {moment(new Date(viewDetails.endDate)).format("DD/MM/YYYY")}
                  </span>
                </li>
                {viewDetails.comments && (
                  <div className="mt-3">
                    <div className="comments">
                      <h5>Comments</h5>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={viewDetails.comments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
                {userDocument ? (
                  <div className="d-flex justify-content-end mt-3">
                    <p className="mt-2">Download file</p>
                    <CircularButtonView
                      Icon={AiOutlineArrowDown}
                      onClick={() => {
                        downloadFile(userDocument.employeeId, userDocument._id);
                      }}
                      size={20}
                    />
                  </div>
                ) : (
                  ""
                )}
              </ul>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Rejected;
