import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import moment from "moment";

import { Header } from "../../common";
import { BsNewspaper } from "react-icons/bs";
import MyLeaveApplications from "./components/MyLeaveApplications";
import Calender from "./components/Calender";
import {
  LEAVE_STATUS_PENDING_APPROVAL,
  LEAVE_STATUS_CANCELLED,
  HOLIDAY_TYPE_REGULAR,
  // HOLIDAY_TYPE_OPTIONAL,
} from "../../../constants/env";
// md={6} lg={6} xl={4} className="col-xxl-3"
const LeaveApplicationsView = ({
  myTeamEmployeeLeaveList,
  user,
  initDoc,
  getDocument,
  userDocument,

  holidaysList,

  date,
  desData,
  combineCalenderData,

  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,

  updateLeave,

  downloadFile,
  leaveId,
  defaultTabActiveKey,
  onsetDefaultTabActiveKey,
  onsetLeaveId,

  srollToSummary,
  getMyTeamEmployeeLeaveList,
  showNextYearLeave,
  getNextYearLeaveDependOnId,

  showLeaveById,
  leaveById,
  closeLeaveByIdData,
  currentYear,
  leaveMasterTypeList,
  leaveTypesList,
  updateLeaveDetail,
}) => {
  // console.log("desData", desData);
  // useEffect for creating calender Data

  useEffect(() => {
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    const LeaveData = myTeamEmployeeLeaveList.filter(
      (list) =>
        (list.status === 0 && new Date(list.startDate) > now) ||
        list.status === 1
      // list.status === -1
    );
    var combineDate = [];
    holidaysList
      .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
      .forEach((i) => {
        combineDate.push({
          des: `${i.description} (Holiday)`,
          startDate: i.date,
        });
      });
    // holidaysList
    //   .filter((list) => list.type === HOLIDAY_TYPE_OPTIONAL)
    //   .forEach((i) => {
    //     combineDate.push({
    //       des: `${i.description} (Optional Holiday)`,
    //       startDate: i.date,
    //     });
    //   });
    LeaveData.forEach((i) => {
      // console.log("i", i);
      if (
        i.status === 1 ||
        i.status === 0 ||
        i.leaveType.description === "Optional Holiday Leave"
      ) {
        // console.log("push", i);
        combineDate.push(i);
      }
    });
    // console.log("combine", combineDate);
    onsetCombineCalenderList(combineDate);
    const data = combineDate.filter((list) => {
      // console("list", list.startDate);
      return list.startDate.slice(0, 10) === date;
    });
    if (data) {
      onsetDescription(data);
    } else {
      onsetDescription([]);
    }
  }, [
    myTeamEmployeeLeaveList,
    holidaysList,
    date,
    onsetCombineCalenderList,
    onsetDescription,
  ]);

  // useEffect for getting the tab name from leaveId
  useEffect(() => {
    // console.log("hi");
    if (leaveId) {
      const leaveDependOnId = myTeamEmployeeLeaveList.find((list) => {
        return list._id === leaveId;
      });
      // console.log("log99", leaveDependOnId);
      if (leaveDependOnId) {
        if (leaveDependOnId.status === LEAVE_STATUS_PENDING_APPROVAL) {
          onsetDefaultTabActiveKey("pendingapproval");
        } else if (leaveDependOnId.status === LEAVE_STATUS_CANCELLED) {
          onsetDefaultTabActiveKey("cancelled");
        } else {
          onsetDefaultTabActiveKey("pendingapproval");
        }
      } else {
        onsetDefaultTabActiveKey("pendingapproval");
      }
    } else {
      onsetDefaultTabActiveKey("pendingapproval");
    }
  }, [myTeamEmployeeLeaveList, leaveId, onsetDefaultTabActiveKey]);

  return (
    <>
      <Header label={"Leave Applications"} Icon={BsNewspaper} />
      <div className="main__body padding">
        <Container>
          <Row>
            <Col lg={12} xl={4} className="col-xxl-4">
              <div className="mt-3">
                <Calender
                  holidaysList={holidaysList}
                  approved={myTeamEmployeeLeaveList.filter(
                    (list) => list.status === 1
                  )}
                  pending={myTeamEmployeeLeaveList.filter((list) => {
                    var now = new Date();
                    now.setHours(0, 0, 0, 0);
                    if (list.status === 0 && new Date(list.startDate) > now) {
                      return true;
                    } else {
                      return false;
                    }
                  })}
                  onsetDate={onsetDate}
                  onsetDescription={onsetDescription}
                  combineCalenderData={combineCalenderData}
                  srollToSummary={srollToSummary}
                  showNextYearLeave={showNextYearLeave}
                />
              </div>
            </Col>
            <Col lg={12} xl={8} className="col-xxl-8">
              <div className="column_divider mt-3">
                <div className="symbols">
                  <Card>
                    <Card.Body>
                      <Row className="calender_symbols">
                        <Col
                          md={6}
                          lg={3}
                          ref={srollToSummary}
                          className="d-block d-md-none"
                        ></Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--maroon me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Holiday/Optional Holiday
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--blue me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Pending Approval
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--green me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Approved Leave
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center"
                        ></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <div className="leave_overview">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          md={12}
                          lg={4}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <div>
                            <h5 className="text-center">
                              Total Leaves Pending Approval
                            </h5>
                            <h3 className="text-center">
                              {
                                myTeamEmployeeLeaveList
                                  .filter((list) => list.status === 0)
                                  .filter((item) => {
                                    var now = new Date();
                                    now.setHours(0, 0, 0, 0);
                                    if (new Date(item.startDate) < now) {
                                      return false;
                                    } else return true;
                                  }).length
                              }
                            </h3>
                          </div>
                        </Col>
                        <Col className="border_divider" md={12} lg={8}>
                          <Card
                            className="today_date"
                            style={{ background: "#242034", color: "#fff" }}
                          >
                            <Card.Body className="text-center py-2">
                              {moment(date).format("MMMM Do YYYY")},
                              {moment(date).format("dddd")}
                              {/* 24th October 2021, Wednesday */}
                            </Card.Body>
                          </Card>
                          {desData.length > 0 && desData ? (
                            <div
                              style={{ fontSize: "18px", color: "#242034" }}
                              className="mt-3 mb-3"
                            >
                              {desData.map((list, i) => {
                                // console.log("list", list);
                                var leaveFormatted = "";
                                if (list.status === 1) {
                                  // console.log("leaveFormatted", list);
                                  if (
                                    list.leaveType.description ===
                                    "Optional Holiday Leave"
                                  ) {
                                    leaveFormatted = `on ${list.leaveType.description}`;
                                  } else {
                                    leaveFormatted = `on ${list.leaveSubType} leave`;
                                  }
                                  // leaveFormatted = `on ${list.leaveType.description}`;
                                }
                                // console.log("leaveFormatted", leaveFormatted);
                                return (
                                  <div key={i}>
                                    {list.userId ? (
                                      <span>
                                        {i + 1}.{" "}
                                        <span style={{ color: "#d87d2e" }}>
                                          {list.userId[0].firstName}
                                          {list.userId[0].lastName.slice(0, 1)}
                                        </span>{" "}
                                        {leaveFormatted}{" "}
                                        {list.startDate === list.endDate
                                          ? `(${moment(list.startDate).format(
                                              "ll"
                                            )})`
                                          : `(${moment(list.startDate).format(
                                              "ll"
                                            )} - ${moment(list.endDate).format(
                                              "ll"
                                            )})`}
                                        {list.status === 0 &&
                                          " Pending Approval."}
                                        {list.status === 1 && " Approved."}
                                      </span>
                                    ) : (
                                      <div
                                        style={{
                                          fontSize: "18px",
                                          color: "#d87d2e",
                                        }}
                                        className="mt-3 mb-3 text-center"
                                      >
                                        {list.des}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className="mt-3 mb-3 text-center text-muted">
                              No data available on this day
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>

                <div className="my_leave_applications">
                  {defaultTabActiveKey && (
                    <MyLeaveApplications
                      myTeamEmployeeLeaveList={myTeamEmployeeLeaveList}
                      user={user}
                      initDoc={initDoc}
                      getDocument={getDocument}
                      userDocument={userDocument}
                      updateLeave={updateLeave}
                      downloadFile={downloadFile}
                      defaultTabActiveKey={defaultTabActiveKey}
                      onsetLeaveId={onsetLeaveId}
                      leaveId={leaveId}
                      getMyTeamEmployeeLeaveList={getMyTeamEmployeeLeaveList}
                      showNextYearLeave={showNextYearLeave}
                      onsetDate={onsetDate}
                      getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
                      leaveById={leaveById}
                      showLeaveById={showLeaveById}
                      closeLeaveByIdData={closeLeaveByIdData}
                      currentYear={currentYear}
                      leaveMasterTypeList={leaveMasterTypeList}
                      leaveTypesList={leaveTypesList}
                      updateLeaveDetail={updateLeaveDetail}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LeaveApplicationsView;
