import React, { useState, useEffect } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { ButtonView, Select } from "../../../controls";
import { DataTable } from "../../../common";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { AiOutlineClose } from "react-icons/ai";

const TechnologyMgt = ({
  TechnologyList,
  categoryList,
  technologies,
  saveCategoryTechnolgy,
  DeleteMappedCategoryTechnolgy,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [technology, setTechnology] = useState("");
  const [technologyID, setTechnologyID] = useState("");
  const [data, setData] = useState([]);

  // const data = technologies;
  // console.log("technologies:", technologies);

  useEffect(() => {
    const sortedMappingList = technologies.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setData(sortedMappingList);
    // eslint-disable-next-line 
  },[technologies]);

  const columns = [
    {
      name: "Category",
      selector: "categoryName",
      //   sortable: true,
      grow: 8,
    },
    {
      name: "Technology",
      selector: "technologyName",
      //   sortable: true,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              setCategoryID(row.categoryId);
              setTechnologyID(row.technologyId);
              setShowDelete(true);
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const catgoryOptions = categoryList.map((item) => {
    return { value: item._id, label: item.categoryName };
  });

  const technologyOptions = TechnologyList.map((item) => {
    return { value: item._id, label: item.technologyName };
  });

  const mappingData = {
    categoryId: categoryID,
    categoryName: category,
    technologyId: technologyID,
    technologyName: technology,
  };

  const handleaddTechnology = () => {
    var rules = {
      required: ["technology", "category"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ category, technology }),
    });

    if (!category) {
      errors = {
        ...errors,
        category: "Please fill category field",
      };
    }

    if (!technology) {
      errors = {
        ...errors,
        technology: "Please fill technology field",
      };
    }

    setErrors(errors);
    // console.log("errors:", errors);

    if (!Object.keys(errors).length) {
      saveCategoryTechnolgy(mappingData);
      handleClose();
    }
  };

  const deleteData = {
    categoryId: categoryID,
    technologyId: technologyID,
  };

  const handleClose = () => {
    setShowAddModal(false);
    setShowDelete(false);
    setErrors({});
    setTechnology("");
    setCategory("");
    setTechnologyID("");
    setCategoryID("");
    // console.log(technology, category, technologyID, categoryID);
  };

  return (
    <div className="technology_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Mapping</div>
              <div className="d-none d-md-block">Mapping Management</div>
            </Card.Title>

            <ButtonView
              onClick={() => {
                setShowAddModal(true);
              }}
              title={"Add New"}
              size={"medium"}
              // styles="secondary"
            />
          </Card.Header>
          <DataTable
            columns={columns}
            data={data}
            pagination={true}
            paginationPerPage={5}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showDelete}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
        size="sm"
        centered={true}
      >
        <Modal.Header className="d-flex justify-content-center align-items-center">
          <Modal.Title id="add_role_modal"> Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Are you sure you want to delete this mapped Technology?
          </p>
          <Row>
            <Col>
              <ButtonView
                size={"small"}
                title="Confirm"
                styles="danger-outline"
                onClick={() => {
                  DeleteMappedCategoryTechnolgy(deleteData);
                  handleClose();
                }}
              ></ButtonView>
            </Col>
            <Col>
              <ButtonView
                size={"small"}
                title="Cancel"
                onClick={() => {
                  handleClose();
                }}
              ></ButtonView>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddModal}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">Map Technology</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12} className="mb-3">
                <Select
                  label="Category"
                  placeholder="Select Category"
                  isSearchable={
                    Object.keys(catgoryOptions).length > 0 ? true : false
                  }
                  options={catgoryOptions}
                  onChange={(val) => {
                    setCategory(val.label);
                    setCategoryID(val.value);
                  }}
                  error={errors["category"]}
                  isrequired={true}
                />
              </Col>

              <Col xs={12}>
                <Select
                  label="Technology"
                  placeholder="Select Technology"
                  isSearchable={
                    Object.keys(technologyOptions).length > 0 ? true : false
                  }
                  options={technologyOptions}
                  onChange={(val) => {
                    setTechnology(val.label);
                    setTechnologyID(val.value);
                  }}
                  error={errors["technology"]}
                  isrequired={true}
                />
              </Col>

              <Col xs={12} className="mt-3">
                <ButtonView
                  onClick={handleaddTechnology}
                  title={"Map"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TechnologyMgt;
