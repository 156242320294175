import { actionTypes } from "./documentsActions";

const {
  GET_DOCUMENT_CATEGORIES_PENDING,
  GET_DOCUMENT_CATEGORIES_ERROR,
  GET_DOCUMENT_CATEGORIES_SUCCESS,

  GET_CATEGORY_DOCUMENT_LIST_PENDING,
  GET_CATEGORY_DOCUMENT_LIST_ERROR,
  GET_CATEGORY_DOCUMENT_LIST_SUCCESS,

  DOCUMENT_CATEGORY_ADD_PENDING,
  DOCUMENT_CATEGORY_ADD_ERROR,
  DOCUMENT_CATEGORY_ADD_SUCCESS,

  DOCUMENT_CATEGORY_EDIT_PENDING,
  DOCUMENT_CATEGORY_EDIT_ERROR,
  DOCUMENT_CATEGORY_EDIT_SUCCESS,

  DOCUMENT_CATEGORY_DELETE_PENDING,
  DOCUMENT_CATEGORY_DELETE_ERROR,
  DOCUMENT_CATEGORY_DELETE_SUCCESS,

  INIT_CATEGORY_DOCUMENT_LIST,
} = actionTypes;

const initialState = {
  userDocumentsListPending: false,
  userDocumentsListSuccess: false,
  userDocumentsListError: null,

  documentCategoryAddPending: false,
  documentCategoryAddSuccess: false,
  documentCategoryAddError: null,

  documentCategoryEditPending: false,
  documentCategoryEditSuccess: false,
  documentCategoryEditError: null,

  documentCategoryDeletePending:false,
  documentCategoryDeleteSuccess:false,
  documentCategoryDeleteError:null,

  categoryDocumentsListPending: false,
  categoryDocumentsListSuccess: false,
  categoryDocumentsListError: null,

  categoryDocumentsList: [],

  userDocumentsList: [],
};

export default function userDocuments(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DOCUMENT_CATEGORIES_PENDING:
    case GET_DOCUMENT_CATEGORIES_SUCCESS:
    case GET_DOCUMENT_CATEGORIES_ERROR:
      return Object.assign({}, state, payload);
    case INIT_CATEGORY_DOCUMENT_LIST:
      return Object.assign({}, state, payload);
    case GET_CATEGORY_DOCUMENT_LIST_PENDING:
    case GET_CATEGORY_DOCUMENT_LIST_SUCCESS:
    case GET_CATEGORY_DOCUMENT_LIST_ERROR:
      return Object.assign({}, state, payload);
    case DOCUMENT_CATEGORY_ADD_PENDING:
    case DOCUMENT_CATEGORY_ADD_ERROR:
      return Object.assign({}, state, payload);
    case DOCUMENT_CATEGORY_ADD_SUCCESS: {
      const newDocumentCategory = payload.documentCategory;

      const newUserDocumentsList = [
        ...state.userDocumentsList,
        newDocumentCategory,
      ];
      return Object.assign({}, state, payload, {
        userDocumentsList: newUserDocumentsList,
      });
    }
    case DOCUMENT_CATEGORY_EDIT_PENDING:
    case DOCUMENT_CATEGORY_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case DOCUMENT_CATEGORY_EDIT_SUCCESS: {
      const newDocumentCategoryList = state.userDocumentsList.map((list) => {
        if (list._id === payload.documentCategory._id) {
          return payload.documentCategory;
        } else {
          return list;
        }
      });
      return Object.assign(
        {},
        state,
        { userDocumentsList: newDocumentCategoryList },
        payload
      );
    }
    case DOCUMENT_CATEGORY_DELETE_PENDING:
    case DOCUMENT_CATEGORY_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case DOCUMENT_CATEGORY_DELETE_SUCCESS:{
      const newCategoryDocumentList = state.categoryDocumentsList.filter((list) => {
        return list._id !== payload.id;
      });
      return Object.assign(
        {},
        state,
        { categoryDocumentsList: newCategoryDocumentList },
        payload
      );
    }
    default:
      return state;
  }
}
