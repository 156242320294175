import React from "react";
import { DataTable } from "../../../common";
function LeaveListDatatable({
  label,
  data,
  columns,
  pagination,
  totalLeave,
  totalLeaveSubtitle,
  // pagination,
  paginationPerPage,
  paginationServer,
}) {
  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <h4>{label}</h4>
        <p>
          {totalLeaveSubtitle}
          {totalLeave}
        </p>
        <DataTable
          data={data}
          columns={columns}
          pagination={pagination}
          paginationServer={paginationServer}
          paginationPerPage={paginationPerPage}
        />
      </div>
    </>
  );
}
export default LeaveListDatatable;
