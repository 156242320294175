import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import "./ApplicationManagerStyle.scss";
import { user as getUser } from "../../../store/user/userActions";
import {
  // applicationmanager,
  getApplicationManagerList,
} from "../../../store/application/applicationAction";
// import { showAlert } from "../../../store/alert/alertActions";
import { Loader, BigLoader } from "../../common";
import ApplicationManagerView from "./ApplicationManagerView";
import Cookies from "universal-cookie";
import { COOKIE_DOMAIN } from "../../../constants/env";
import { globals } from "../../../store/globals";

export class ApplicationManagerContainer extends Component {
  constructor(props) {
    super(props);
    console.log("inside applicationmanager container");
    this.props.getUser(true);
    this.props.getApplicationManagerList();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onApplicationManager = (applicationType, userId, userLocation) => {
    this.props.applicationmanager(applicationType, userId, userLocation, false);
  };

  onOpenApplicationClicked(application) {
    var cookieData = JSON.parse(JSON.stringify(globals.store.getState().auth.loginUser));
    delete cookieData.userPermissions;

    if (application.mode === "queryString") {
      // const u = new URLSearchParams(myParams).toString();
      const query = new URLSearchParams(cookieData).toString();
      window.open(application.link + "?" + query);
    } else {
      var cookies = new Cookies();
      cookies.set("usercredentials", JSON.stringify(cookieData), {
        maxAge: 60 * 1,
        domain: COOKIE_DOMAIN,
        sameSite: "none",
        secure: COOKIE_DOMAIN === "localhost" ? false : true,
      });
      setTimeout(() => {
        window.open(application.link);
      }, 1000);
    }
  }

  render() {
    console.log("inside reander applicationmanager container");
    const {
      user,
      userSuccess,
      //userListSuccess,
      applicationTypeList,
      //getApplicationManagerListSuccess,
      applicationTypeListSuccess,
      applicationTypeSuccess,
      applicationManagerSuccess,
      // selectedApplicationsType,
      // applicationTypeSuccess,

      //getUserApplicationManagerListSuccess,
      showAlert,
      getUserApplicationManagerList,
      // deleteApplicationManager,
    } = this.props;
    console.log("getUserApplicationManagerList", getUserApplicationManagerList);

    if (
      userSuccess === false ||
      applicationTypeListSuccess === false ||
      // getApplicationManagerListSuccess === false
      //getUserApplicationManagerListSuccess === false
      applicationManagerSuccess === false ||
      applicationTypeSuccess === false
    ) {
      console.log("inside application if");
      return <Loader />;
    } else {
      console.log("inside application else");
      return (
        <>
          <ApplicationManagerView
            user={user}
            applicationTypeList={applicationTypeList}
            onOpenApplicationClicked={this.onOpenApplicationClicked}
            onApplicationManager={this.onApplicationManager}
            showAlert={showAlert}
            applications={getUserApplicationManagerList}
          />
          <BigLoader />
        </>
      );
      // }
    }

    // if (
    //   userSuccess === true ||
    //   applicationSuccess === true ||
    //   applicationTypeSuccess === true
    //   // ||
    //   // getUserApplicationManagerListSuccess === false
    // ) {
    //   console.log("inside if");
    //   return (
    //     <ApplicationManagerView
    //       user={user}
    //       applicationTypeList={applicationTypeList}
    //       // selectedapplicationsType={selectedapplicationsType}
    //       onApplicationManager={this.onApplicationManager}
    //       showAlert={showAlert}
    //       getUserApplicationManagerList={getUserApplicationManagerList}
    //       // deleteApplicationManager={deleteApplicationManager}
    //     />
    //   );
    // } else {
    //   console.log("inside else");
    //   return <Loader />;
    //   // }
    // }
  }
}
ApplicationManagerContainer.propTypes = {
  user: PropTypes.object,
  applicationTypeList: PropTypes.array,
  // selectedapplicationsType: PropTypes.string,
};

const mapStateToProps = state => ({
  user: state.user.user,
  // userListSuccess: state.user.userListSuccess,
  userSuccess: state.user.userSuccess,

  applicationTypeListSuccess: state.applicationManagerReducer.applicationTypeListSuccess,

  applicationManagerSuccess: state.applicationManagerReducer.applicationManagerSuccess,

  applicationTypeSuccess: state.applicationManagerReducer.applicationTypeSuccess,

  // selectedapplicationsType: state.applicationType.selectedapplicationsType,
  // getUserApplicationManagerListSuccess:
  //   state.applicationManagerReducer.getUserApplicationManagerListSuccess,

  getUserApplicationManagerList: state.applicationManagerReducer.getUserApplicationManagerList,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: getUser,
      // applicationmanager: applicationmanager,
      // showAlert: showAlert,
      getApplicationManagerList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationManagerContainer);

// export default ApplicationManagerContainer;
