import React, { useState } from "react";
import { Card, Modal, Form } from "react-bootstrap";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";

import { ButtonView, Select } from "../../../controls";
import { DataTable } from "../../../common";
import {
  LEAVE_STATUS_PENDING_APPROVAL,
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
  LEAVE_STATUS_CANCELLED,
} from "../../../../constants/env";

import Download from "./Download";
const statusList = [
  { label: "Pending Approval", value: LEAVE_STATUS_PENDING_APPROVAL },
  { label: "Approved", value: LEAVE_STATUS_APPROVED },
  { label: "Rejected", value: LEAVE_STATUS_REJECTED },
  { label: "Cancelled", value: LEAVE_STATUS_CANCELLED },
];
const monthList = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

const data = {
  status: "",
  month: "",
};

const LeaveOverview = ({
  employeeLeaveList,
  currentYear,
  setFilter,
  resetFilter,
}) => {
  // console.log(employeeLeaveList);
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);

  const [filterData, setFilterData] = useState(data);
  const [statusValue, setStatusValue] = useState(null);
  const [monthValue, setMonthValue] = useState(null);

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => {
        if (row.status === 0) {
          return (
            <span className="badge bg-warning-transparent">
              Pending Approval
            </span>
          );
        }
        if (row.status === 1) {
          return <span className="badge bg-success-transparent">Approved</span>;
        }
        if (row.status === -1) {
          return <span className="badge bg-danger-transparent">Rejected</span>;
        }
        if (row.status === -2) {
          return <span className="badge bg-cancel-transparent">Cancelled</span>;
        }
      },
    },
    {
      name: "Type",
      selector: "masterLeaveType",
      center: true,
      grow: 4,
    },
    {
      name: "FD/1H/2H",
      selector: "leaveSubType",
      center: true,
      grow: 4,
    },
    {
      name: "Approver",
      selector: "",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{`${
          row.reportingManagerDetailsUserId[0].firstName
        } ${row.reportingManagerDetailsUserId[0].lastName.charAt(0)}`}</span>
      ),
    },
    {
      name: "Detail",
      center: true,
      // minWidth: "70px",
      grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              handleViewOpen(row);
              // handleViewOpen()
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
  ];

  const handleApply = () => {
    if (filterData.month || filterData.status || filterData.status === 0)
      setFilter(filterData);
  };

  const handleViewOpen = (row) => {
    setViewDetails(row);
    setViewDetailsShow(true);
    // console.log(row)
  };

  const handleClose = () => {
    setViewDetailsShow(false);
  };

  return (
    <div className="leave__overview__container">
      <Card className="mt-3">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <Card.Title className="mt-2 mt-lg-0">
              Leave Application(s) of year {currentYear}
            </Card.Title>
            <div className="d-flex flex-wrap align-items-center mt-3 mt-lg-0">
              <div style={{ width: "200px" }} className="me-2">
                <Select
                  name="status"
                  // label="Select Month"
                  isSearchable={true}
                  placeholder="Select Status"
                  options={statusList}
                  value={statusValue}
                  onChange={(val) => {
                    setStatusValue(val);
                    setFilterData({ ...filterData, status: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                />
              </div>
              <div style={{ width: "200px" }} className="me-2">
                <Select
                  name="month"
                  // label="Select Month"
                  isSearchable={true}
                  placeholder="Select Month"
                  options={monthList}
                  value={monthValue}
                  onChange={(val) => {
                    setMonthValue(val);
                    setFilterData({ ...filterData, month: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                />
              </div>
              <div style={{ width: "100px" }} className="me-2">
                <ButtonView
                  onClick={handleApply}
                  title={"Apply"}
                  styles="secondary"
                />
              </div>
              {(filterData.month ||
                filterData.status ||
                filterData.status === 0) && (
                <div style={{ width: "100px" }} className="me-3">
                  <ButtonView
                    onClick={() => {
                      setStatusValue(null);
                      setMonthValue(null);
                      setFilterData(data);
                      resetFilter();
                    }}
                    title={"Clear"}
                  />
                </div>
              )}
              <Download
                employeeLeaveList={employeeLeaveList}
                currentYear={currentYear}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="table_content px-2 attendance_table pb-3">
            <DataTable
              data={employeeLeaveList}
              columns={columns}
              pagination={true}
              paginationPerPage={5}
              paginationServer={false}
            />
            {viewDetails && (
              <div className="view_modal">
                <Modal
                  show={viewDetailsShow}
                  onHide={handleClose}
                  aria-labelledby="view_modal"
                >
                  <Modal.Header>
                    <Modal.Title id="view_modal">
                      Application Detail
                    </Modal.Title>
                    <div onClick={handleClose} style={{ cursor: "pointer" }}>
                      <AiOutlineClose size={20} />
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="comments ms-1 d-flex justify-content-between align-items-center flex-wrap">
                      <h5>
                        Applier:{" "}
                        <span style={{ color: "#d87d2e" }}>
                          {viewDetails.userId[0].firstName}{" "}
                          {viewDetails.userId[0].lastName}
                        </span>
                      </h5>
                      {viewDetails.masterLeaveType ===
                        "Optional Holiday Leave" && (
                        <h5>
                          <span
                            style={{ fontSize: "15px" }}
                            className="badge badge-success"
                          >
                            Optional Holiday
                          </span>
                        </h5>
                      )}
                    </div>
                    <ul className="list-group">
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        FD/1H/2H
                        <span>{viewDetails.leaveSubType}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Leave Type:
                        <span>{viewDetails.masterLeaveType}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Sub Leave Type:
                        <span>{viewDetails.leaveType.description}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Start Date:
                        <span>
                          {moment(new Date(viewDetails.startDate)).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        End Date:
                        <span>
                          {moment(new Date(viewDetails.endDate)).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </li>
                      {viewDetails.comments && (
                        <div className="mt-3">
                          <div className="comments">
                            <h6>Comments:</h6>
                          </div>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Control
                              // disabled
                              readOnly
                              value={viewDetails.comments}
                              as="textarea"
                              rows={3}
                            />
                          </Form.Group>
                        </div>
                      )}
                    </ul>
                  </Modal.Body>
                </Modal>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LeaveOverview;
