import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
// import { showLoader, hideLoader } from "../loader/loaderActions";

export const actionTypes = {
  GET_DOCUMENT_CATEGORIES_PENDING: "GET_DOCUMENT_CATEGORIES_PENDING",
  GET_DOCUMENT_CATEGORIES_SUCCESS: "GET_DOCUMENT_CATEGORIES_SUCCESS",
  GET_DOCUMENT_CATEGORIES_ERROR: "GET_DOCUMENT_CATEGORIES_ERROR",

  GET_CATEGORY_DOCUMENT_LIST_PENDING: "GET_CATEGORY_DOCUMENT_LIST_PENDING",
  GET_CATEGORY_DOCUMENT_LIST_SUCCESS: "GET_CATEGORY_DOCUMENT_LIST_SUCCESS",
  GET_CATEGORY_DOCUMENT_LIST_ERROR: "GET_CATEGORY_DOCUMENT_LIST_ERROR",

  DOCUMENT_CATEGORY_ADD_PENDING: "DOCUMENT_CATEGORY_ADD_PENDING",
  DOCUMENT_CATEGORY_ADD_SUCCESS: "DOCUMENT_CATEGORY_ADD_SUCCESS",
  DOCUMENT_CATEGORY_ADD_ERROR: "DOCUMENT_CATEGORY_ADD_ERROR",

  DOCUMENT_CATEGORY_EDIT_PENDING: "DOCUMENT_CATEGORY_EDIT_PENDING",
  DOCUMENT_CATEGORY_EDIT_SUCCESS: "DOCUMENT_CATEGORY_EDIT_SUCCESS",
  DOCUMENT_CATEGORY_EDIT_ERROR: "DOCUMENT_CATEGORY_EDIT_ERROR",

  DOCUMENT_CATEGORY_DELETE_PENDING:"DOCUMENT_CATEGORY_DELETE_PENDING",
  DOCUMENT_CATEGORY_DELETE_ERROR:"DOCUMENT_CATEGORY_DELETE_ERROR",
  DOCUMENT_CATEGORY_DELETE_SUCCESS:"DOCUMENT_CATEGORY_DELETE_SUCCESS",

  INIT_CATEGORY_DOCUMENT_LIST: "INIT_CATEGORY_DOCUMENT_LIST",
};

export function getDocumentCategoryList() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DOCUMENT_CATEGORIES_PENDING,
      payload: {
        userDocumentsListPending: true,
        userDocumentsListSuccess: false,
        userDocumentsListError: null,
      },
    });

    Api.get("userDocuments/staticContent/categories")
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.GET_DOCUMENT_CATEGORIES_SUCCESS,
            payload: {
              userDocumentsListPending: false,
              userDocumentsListSuccess: true,
              userDocumentsListError: null,

              userDocumentsList: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_DOCUMENT_CATEGORIES_ERROR,
            payload: {
              userDocumentsListPending: false,
              userDocumentsListSuccess: true,
              userDocumentsListError: null,

              userDocumentsList: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user documents list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.GET_DOCUMENT_CATEGORIES_ERROR,
          payload: {
            userDocumentsListPending: false,
            userDocumentsListSuccess: true,
            userDocumentsListError: null,

            userDocumentsList: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user documents list.",
          })
        );
      });
  };
}

export function addDocumentCategory(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOCUMENT_CATEGORY_ADD_PENDING,
      payload: {
        documentCategoryAddPending: true,
        documentCategoryAddSuccess: false,
        documentCategoryAddError: null,
      },
    });
    // console.log(data)

    Api.post("userDocuments/staticContentCategory", data)
      .then((response) => {
        // console.log(response);
        if (
          response.message === "Successfully created static content category"
        ) {
          dispatch({
            type: actionTypes.DOCUMENT_CATEGORY_ADD_SUCCESS,
            payload: {
              documentCategoryAddPending: false,
              documentCategoryAddSuccess: true,
              documentCategoryAddError: null,

              documentCategory: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new document category.",
            })
          );
        } else {
          console.log(response.message);
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to add document category.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DOCUMENT_CATEGORY_ADD_ERROR,
          payload: {
            documentCategoryAddPending: false,
            documentCategoryAddSuccess: true,
            documentCategoryAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add document category.",
          })
        );
      });
  };
}

export function editDocumentCategory(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOCUMENT_CATEGORY_EDIT_PENDING,
      payload: {
        documentCategoryEditPending: true,
        documentCategoryEditSuccess: false,
        documentCategoryEditError: null,
      },
    });

    Api.post(`userDocuments/staticContentCategory/${id}`, data)
      .then((response) => {
        if (
          response.message === "Successfully updated static content category"
        ) {
          dispatch({
            type: actionTypes.DOCUMENT_CATEGORY_EDIT_SUCCESS,
            payload: {
              documentCategoryEditPending: false,
              documentCategoryEditSuccess: true,
              documentCategoryEditError: null,

              documentCategory: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated document category.",
            })
          );
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to edit document category.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.DOCUMENT_CATEGORY_EDIT_ERROR,
          payload: {
            documentCategoryEditPending: false,
            documentCategoryEditSuccess: true,
            documentCategoryEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit document category.",
          })
        );
      });
  };
}

export function getCategoryDocumentList(id, location = "") {
  return (dispatch) => {
    var data = {};
    if (id) data.category = id;
    if (location) data.location = location;

    dispatch({
      type: actionTypes.GET_CATEGORY_DOCUMENT_LIST_PENDING,
      payload: {
        categoryDocumentsListPending: true,
        categoryDocumentsListSuccess: false,
        categoryDocumentsListError: null,

        categoryDocumentsList: [],
      },
    });

    Api.post("userDocuments/staticContentList", data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.GET_CATEGORY_DOCUMENT_LIST_SUCCESS,
            payload: {
              categoryDocumentsListPending: false,
              categoryDocumentsListSuccess: true,
              categoryDocumentsListError: null,

              categoryDocumentsList: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_CATEGORY_DOCUMENT_LIST_ERROR,
            payload: {
              categoryDocumentsListPending: false,
              categoryDocumentsListSuccess: true,
              categoryDocumentsListError: null,

              categoryDocumentsList: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get category documents list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.GET_CATEGORY_DOCUMENT_LIST_ERROR,
          payload: {
            categoryDocumentsListPending: false,
            categoryDocumentsListSuccess: true,
            categoryDocumentsListError: null,

            categoryDocumentsList: [],
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get category documents list.",
          })
        );
      });
  };
}

export function initCategoryDocumentList() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.INIT_CATEGORY_DOCUMENT_LIST,
      payload: {
        categoryDocumentsListPending: false,
        categoryDocumentsListSuccess: false,
        categoryDocumentsListError: null,

        categoryDocumentsList: [],
      },
    });
  };
}

export function downloadDocument(id) {
  return (dispatch) => {
    const data = {
      documentId: id,
    };
    Api.post("userDocuments/getStaticContentDocumentWithDownloadURL", data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          const url = response.data[0].downloadURL;
          const link = document.createElement("a");
          link.href = url;
          // link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to download document.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to download document.",
          })
        );
      });
  };
}

export function uploadDocumentForCategory(fields) {
  return (dispatch) => {
    dispatch(showLoader());
    const formData = new FormData();
    formData.append("file", fields.file);
    formData.append("category", fields.category.value);
    formData.append("description", fields.description);
    formData.append("location", fields.location.value);

    Api.post("userDocuments/uploadStaticContent", formData)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          // dispatch({
          //   type: actionTypes.GET_CATEGORY_DOCUMENT_LIST_PENDING,
          //   payload: {
          //     categoryDocumentsListPending: true,
          //     categoryDocumentsListSuccess: false,
          //     categoryDocumentsListError: null,

          //     categoryDocumentsList: [],
          //   },
          // });
          dispatch(hideLoader());
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: response.message,
            })
          );
        } else {
          dispatch(hideLoader());
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to add document.",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add document.",
          })
        );
      });
  };
}
//                       delete document action
export function deleteDocument(id)
{
  return (dispatch) => {
  const data = {
    "documentId": id,
  };
    dispatch({
      type: actionTypes.DOCUMENT_CATEGORY_DELETE_PENDING,
      payload: {
        documentCategoryDeletePending:true,
        documentCategoryDeleteSuccess:false,
        documentCategoryDeleteError:null,
      },
    });
     Api.delete("userDocuments/deleteStaticDocument", data)
      .then((response) => {
        console.log("response of delete",response);
        if (response.message === "Successfully deleted user document") {
          dispatch({
            type: actionTypes.DOCUMENT_CATEGORY_DELETE_SUCCESS,
            payload: {
              documentCategoryDeletePending:false,
              documentCategoryDeleteSuccess:true,
              documentCategoryDeleteError:null,

              id:id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Document deleted successfully user domain.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DOCUMENT_CATEGORY_DELETE_ERROR,
          payload: {
            documentCategoryDeletePending:false,
            documentCategoryDeleteSuccess:true,
            documentCategoryDeleteError:null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to delete.",
          })
        );
      });
  };
}