import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
// import ReactHtmlParser from "react-html-parser";

export const actionTypes = {
  LOCATION_PENDING: "LOCATION_PENDING",
  LOCATION_SUCCESS: "LOCATION_SUCCESS",
  LOCATION_ERROR: "LOCATION_ERROR",

  LOCATION_ADD_PENDING: "LOCATION_ADD_PENDING",
  LOCATION_ADD_ERROR: "LOCATION_ADD_ERROR",
  LOCATION_ADD_SUCCESS: "LOCATION_ADD_SUCCESS",

  LOCATION_EDIT_PENDING: "LOCATION_EDIT_PENDING",
  LOCATION_EDIT_ERROR: "LOCATION_EDIT_ERROR",
  LOCATION_EDIT_SUCCESS: "LOCATION_EDIT_SUCCESS",

  LOCATION_DELETE_PENDING: "LOCATION_DELETE_PENDING",
  LOCATION_DELETE_ERROR: "LOCATION_DELETE_ERROR",
  LOCATION_DELETE_SUCCESS: "LOCATION_DELETE_SUCCESS",
};

export function addLocation(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOCATION_ADD_PENDING,
      payload: {
        locationAddPending: true,
        locationAddSuccess: false,
        locationAddError: null,
      },
    });

    Api.post("location/create", data)
      .then((response) => {
        console.log(response);
        if (response.message === "Successfully added new location") {
          dispatch({
            type: actionTypes.LOCATION_ADD_SUCCESS,
            payload: {
              locationAddPending: false,
              locationAddSuccess: true,
              locationAddError: null,

              location: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new location.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LOCATION_ADD_ERROR,
          payload: {
            locationAddPending: false,
            locationAddSuccess: true,
            locationAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add location.",
          })
        );
      });
  };
}

export function editLocation(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOCATION_EDIT_PENDING,
      payload: {
        locationEditPending: true,
        locationEditSuccess: false,
        locationEditError: null,
      },
    });
    // console.log(data, id)

    Api.put(`location/update/${id}`, data)
      .then((response) => {
        console.log(response);
        if (response.message === "SuccessFully updated location") {
          dispatch({
            type: actionTypes.LOCATION_EDIT_SUCCESS,
            payload: {
              locationEditPending: false,
              locationEditSuccess: true,
              locationEditError: null,

              location: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated location.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LOCATION_EDIT_ERROR,
          payload: {
            locationEditPending: false,
            locationEditSuccess: true,
            locationEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit location.",
          })
        );
      });
  };
}

export function deleteLocation(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOCATION_DELETE_PENDING,
      payload: {
        locationDeletePending: true,
        locationDeleteSuccess: false,
        locationDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`location/delete/${id}`)
      .then((response) => {
        // console.log(response)
        if (response.message === "Successfully deleted location") {
          dispatch({
            type: actionTypes.LOCATION_DELETE_SUCCESS,
            payload: {
              locationDeletePending: false,
              locationDeleteSuccess: true,
              locationDeleteError: null,

              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted location.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LOCATION_DELETE_ERROR,
          payload: {
            locationDeletePending: false,
            locationDeleteSuccess: true,
            locationDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add location.",
          })
        );
      });
  };
}

export function list() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOCATION_PENDING,
      payload: {
        locationPending: true,
        locationSuccess: false,
        locationError: null,
      },
    });

    Api.get("location/list")
      .then((response) => {
        // console.log(response.data)
        if (response.data.length > 0) {
          // console.log("Inside Location List Data")
          // var approveLocation = response.data.map((item) => {
          //   // console.log("item", item);
          //   var html = item.addressLine1;

          //   var addrLoc = ReactHtmlParser(html);

          //   let addr = addrLoc[0].props.children.filter((data) => {
          //     return typeof data === "string";
          //   });

          //   // console.log("lol", addr.join(" "));

          //   return {
          //     ...item,
          //     addressLine1: addr.join(" "),
          //   };
          // });

          // console.log(approveLocation)

          dispatch({
            type: actionTypes.LOCATION_SUCCESS,
            payload: {
              locationPending: false,
              locationSuccess: true,
              locationError: null,

              locationList: response.data,
            },
          });
        } else {

          dispatch({
            type: actionTypes.LOCATION_SUCCESS,
            payload: {
              locationPending: false,
              locationSuccess: true,
              locationError: null,
              locationList: [],
            },
          });
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: actionTypes.LOCATION_ERROR,
          payload: {
            locationPending: false,
            locationSuccess: true,
            locationError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get location list.",
          })
        );
      });
  };
}
