import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Card } from "react-bootstrap";

import { HOLIDAY_TYPE_REGULAR } from "../../../../constants/env";

const Calender = ({
  holidaysList,
  accepted,
  onsetDate,
  onsetDescription,
  combineCalenderData,
  srollToSummary,
  filterInterviewSchedule,
}) => {
  // console.log(accepted);
  var calenderData = [];
  holidaysList
    .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
    .forEach((i) => {
      calenderData.push({
        title: i.description,
        date: i.date,
        backgroundColor: "#CC0066",
        borderColor: "#CC0066",
      });
    });
  accepted
    .filter(
      (list, index, self) =>
        index === self.findIndex((i) => i.interviewDate === list.interviewDate)
    )
    .forEach((i) => {
      calenderData.push({
        title: "",
        date: i.interviewDate.slice(0, 10),
        backgroundColor: "#004C99",
        borderColor: "#004C99",
      });
    });

  const onDayClick = (e) => {
    onsetDate(e.dateStr);
    filterInterviewSchedule(e.dateStr);
    if (srollToSummary.current) {
      srollToSummary.current.scrollIntoView({});
    }
  };

  return (
    <Card className="event_calender">
      <Card.Body>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            start: "prev",
            center: "title",
            end: "next",
          }}
          dateClick={onDayClick}
          initialView="dayGridMonth"
          events={calenderData}
          // editable={true}
          selectable={true}
          contentHeight={"auto"}
          eventClick={() => {}}
          // aspectRatio={0.7}
        />
      </Card.Body>
    </Card>
  );
};

export default Calender;
