import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import moment from "moment";

import { Header } from "../../common";
import { BsTable } from "react-icons/bs";
import MyLeaveApplications from "./components/MyLeaveApplications";
import Calender from "./components/Calender";
import {
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
  HOLIDAY_TYPE_REGULAR,
  HOLIDAY_TYPE_OPTIONAL,
} from "../../../constants/env";
import HolidayList from "./components/HolidayList";
// md={6} lg={6} xl={4} className="col-xxl-3"
const MyLeavesView = ({
  user,
  leaveTypesList,
  userManagerList,
  applyForLeave,

  employeeLeaveList,
  updateLeave,

  holidaysList,
  leaveMasterTypeList,

  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,

  date,
  desData,
  combineCalenderData,
  employeeLeavesSummary,
  otherLeavesSummary,

  leaveId,
  defaultTabActiveKey,
  onsetDefaultTabActiveKey,
  onsetLeaveId,

  srollToSummary,
  getemployeeLeaveList,
  showNextYearLeave,
  getOtherLeavesSummary,
  getEmployeeLeavesSummary,
  showLeaveById,
  leaveById,
  closeLeaveByIdData,
  getNextYearLeaveDependOnId,
  currentYear,
  totalEmployeeLeaveList,
  getHolidays,

  canApplyOptionalHolidayLeave,
  canApply,
  optionalHolidaySummary,
  getOptionalHolidayLeaveSummary,
}) => {
  // console.log("desdata", desData);
  useEffect(() => {
    const LeaveData = employeeLeaveList.filter(
      (list) => list.status === 0 || list.status === 1
    );
    const LeaveRejected = employeeLeaveList.filter(
      (list) => list.status === -1
    );
    // console.log("LeaveData", LeaveData);
    var combineDate = [];

    holidaysList
      .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
      .forEach((i) => {
        combineDate.push({ des: `${i.description} (Holiday)`, date: i.date });
      });
    LeaveData.forEach((i) => {
      // console.log("i", i);
      if (i.status === 1) {
        let startDate = new Date(i.startDate.slice(0, 10)); //YYYY-MM-DD
        let endDate = new Date(i.endDate.slice(0, 10)); //YYYY-MM-DD
        let arr = [];
        let dt = new Date(startDate);
        while (dt <= endDate) {
          // console.log("dt", dt);
          arr.push(moment(dt).format("YYYY-MM-DD"));
          dt.setDate(dt.getDate() + 1);
          // console.log("dt22", dt);
        }
        // console.log("arr", arr);
        const dateRange = `(${moment(i.startDate).format("ll")} - ${moment(
          i.endDate
        ).format("ll")})`;
        // console.log("arr", arr);
        arr.forEach((list) => {
          // console.log("list", list);

          if (i.leaveType.description === "Optional Holiday Leave") {
            // optionalHoliday.map((ele) => {
            //   if (ele.date === i.startDate.slice(0, 10)) {
            //     // console.log("ele", ele.date);
            //     // console.log("i", i.startDate.slice(0, 10));
            //     console.log("hi", ele.date !== i.startDate);
            //   }
            // });

            combineDate.push({
              des: `${i.leaveType.description}, ${
                i.startDate === i.endDate
                  ? moment(i.startDate).format("ll")
                  : dateRange
              } (Approved)`,
              date: list,
            });
          } else {
            combineDate.push({
              des: `${i.leaveSubType} leave, ${
                i.startDate === i.endDate
                  ? moment(i.startDate).format("ll")
                  : dateRange
              } (Approved)`,
              date: list,
            });
          }
        });
      }
      if (i.status === 0) {
        let startDate = new Date(i.startDate.slice(0, 10)); //YYYY-MM-DD
        let endDate = new Date(i.endDate.slice(0, 10)); //YYYY-MM-DD
        let arr = [];
        let dt = new Date(startDate);
        while (dt <= endDate) {
          arr.push(moment(dt).format("YYYY-MM-DD"));
          dt.setDate(dt.getDate() + 1);
        }
        const dateRange = `(${moment(i.startDate).format("ll")} - ${moment(
          i.endDate
        ).format("ll")})`;
        arr.forEach((list) => {
          if (i.leaveType.description === "Optional Holiday Leave") {
            combineDate.push({
              des: `${i.leaveType.description}, ${
                i.startDate === i.endDate
                  ? moment(i.startDate).format("ll")
                  : dateRange
              } (Pending Approval)`,
              date: list,
            });
          } else {
            combineDate.push({
              des: `${i.leaveSubType} leave, ${
                i.startDate === i.endDate
                  ? moment(i.startDate).format("ll")
                  : dateRange
              } (Pending Approval)`,
              date: list,
            });
          }
        });
      }
    });

    LeaveRejected.forEach((i) => {
      if (i.status === -1) {
        const dateRange = `(${moment(i.startDate).format("ll")} - ${moment(
          i.endDate
        ).format("ll")})`;

        if (i.leaveType.description === "Optional Holiday Leave") {
          combineDate.push({
            des: `${i.leaveType.description}, ${
              i.startDate === i.endDate
                ? moment(i.startDate).format("ll")
                : dateRange
            } (Rejected)`,
            date: i.startDate.slice(0, 10),
          });
        } else {
          combineDate.push({
            des: `${i.leaveSubType} leave, ${
              i.startDate === i.endDate
                ? moment(i.startDate).format("ll")
                : dateRange
            } (Rejected)`,
            date: i.startDate.slice(0, 10),
          });
        }
      }
    });

    holidaysList
      .filter((list) => list.type === HOLIDAY_TYPE_OPTIONAL)
      .forEach((i) => {
        // console.log("i", i);
        combineDate.push({
          des: `${i.description} (Optional Holiday)`,
          date: i.date,
        });
      });

    // LeaveRejected.forEach((i) => {
    //   if (i.status === -1) {
    //     let startDate = new Date(i.startDate.slice(0, 10)); //YYYY-MM-DD
    //     let endDate = new Date(i.endDate.slice(0, 10)); //YYYY-MM-DD
    //     let arr = [];
    //     let dt = new Date(startDate);
    //     while (dt <= endDate) {
    //       arr.push(moment(dt).format("YYYY-MM-DD"));
    //       dt.setDate(dt.getDate() + 1);
    //     }
    //     const dateRange = `(${moment(i.startDate).format("ll")} - ${moment(
    //       i.endDate
    //     ).format("ll")})`;
    //     arr.forEach((list) => {
    //       combineDate.push({
    //         des: `${i.leaveSubType} leave, ${
    //           i.startDate === i.endDate
    //             ? moment(i.startDate).format("ll")
    //             : dateRange
    //         } (Rejected)`,
    //         date: list,
    //       });
    //     });
    //   }
    // });
    // console.log("optionalHoliday", optionalHoliday);
    onsetCombineCalenderList(combineDate);
    // console.log("date", date);
    const data = combineDate.find((list) => {
      return list.date === date;
    });
    if (data && data.des) {
      // console.log("data", data);
      onsetDescription(data.des);
    } else {
      onsetDescription("");
    }
  }, [
    employeeLeaveList,
    holidaysList,
    date,
    onsetCombineCalenderList,
    onsetDescription,
  ]);

  // useEffect for getting the tab name from leaveId
  useEffect(() => {
    if (leaveId) {
      const leaveDependOnId = employeeLeaveList.find((list) => {
        return list._id === leaveId;
      });
      if (leaveDependOnId) {
        if (leaveDependOnId.status === LEAVE_STATUS_APPROVED) {
          onsetDefaultTabActiveKey("approved");
        } else if (leaveDependOnId.status === LEAVE_STATUS_REJECTED) {
          onsetDefaultTabActiveKey("rejected");
        } else {
          onsetDefaultTabActiveKey("pendingapproval");
        }
      } else {
        onsetDefaultTabActiveKey("pendingapproval");
      }
    } else {
      onsetDefaultTabActiveKey("pendingapproval");
    }
  }, [employeeLeaveList, leaveId, onsetDefaultTabActiveKey]);
  // console.log("srollToSummary", srollToSummary);
  return (
    <>
      <Header label={"My Leaves"} Icon={BsTable} />
      <div className="main__body padding">
        <Container>
          <Row>
            <Col lg={12} xl={4} className="col-xxl-4">
              <div className="mt-3">
                <Calender
                  holidaysList={holidaysList}
                  unapproved={employeeLeaveList.filter(
                    (list) => list.status === 0
                  )}
                  approved={employeeLeaveList.filter(
                    (list) => list.status === 1
                  )}
                  rejected={employeeLeaveList.filter(
                    (list) => list.status === -1
                  )}
                  onsetDate={onsetDate}
                  onsetDescription={onsetDescription}
                  combineCalenderData={combineCalenderData}
                  srollToSummary={srollToSummary}
                />
              </div>
              <HolidayList holidaysList={holidaysList} />
            </Col>
            <Col lg={12} xl={8} className="col-xxl-8">
              <div className="column_divider mt-3">
                <div className="symbols">
                  <Card>
                    <Card.Body>
                      <Row className="calender_symbols g-0">
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--maroon me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Holiday
                            {/* Holiday/Optional Holiday */}
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--blue me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Pending Approval
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          ref={srollToSummary}
                          className="d-block d-md-none"
                        ></Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--green me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Approved Leave
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--red me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Rejected Leave
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--lightgreen me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Optional Holiday
                          </span>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <div className="leave_overview">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={12} lg={5}>
                          <div className="leave_overview_content">
                            <p
                              style={{ color: "#d87d2e", fontSize: "18px" }}
                              className="mb-1 text-center"
                            >
                              Leave summary of year {currentYear}
                            </p>
                            {employeeLeavesSummary && (
                              <>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Total Paid Leaves</span>
                                  <span>
                                    :{" "}
                                    {employeeLeavesSummary.totalPaidLeaves.toString()
                                      .length > 1
                                      ? `${employeeLeavesSummary.totalPaidLeaves}`
                                      : `0${employeeLeavesSummary.totalPaidLeaves}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Approved Leaves</span>
                                  <span>
                                    :{" "}
                                    {employeeLeavesSummary.approved.toString()
                                      .length > 1
                                      ? `${employeeLeavesSummary.approved}`
                                      : `0${employeeLeavesSummary.approved}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Pending Approval</span>
                                  <span>
                                    :{" "}
                                    {employeeLeavesSummary.pendingApproval.toString()
                                      .length > 1
                                      ? `${employeeLeavesSummary.pendingApproval}`
                                      : `0${employeeLeavesSummary.pendingApproval}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Rejected</span>
                                  <span>
                                    :{" "}
                                    {employeeLeavesSummary.rejected.toString()
                                      .length > 1
                                      ? `${employeeLeavesSummary.rejected}`
                                      : `0${employeeLeavesSummary.rejected}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Availed</span>
                                  <span>
                                    :{" "}
                                    {employeeLeavesSummary.availed.toString()
                                      .length > 1
                                      ? `${employeeLeavesSummary.availed}`
                                      : `0${employeeLeavesSummary.availed}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Cancelled</span>
                                  <span>
                                    :{" "}
                                    {employeeLeavesSummary.cancelled.toString()
                                      .length > 1
                                      ? `${employeeLeavesSummary.cancelled}`
                                      : `0${employeeLeavesSummary.cancelled}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <p
                                    className="mb-1 d-flex justify-content-between"
                                    style={{ width: "100%" }}
                                  >
                                    <span>Total Leaves Available</span>
                                    <span>
                                      :{" "}
                                      {employeeLeavesSummary.availableLeaves.toString()
                                        .length > 1
                                        ? `${employeeLeavesSummary.availableLeaves}`
                                        : `0${employeeLeavesSummary.availableLeaves}`}
                                    </span>
                                  </p>
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "-10px",
                                      marginTop: "-2px",
                                      color: "red",
                                    }}
                                  >
                                    *
                                  </span>
                                </p>
                              </>
                            )}
                            <hr />
                            <p
                              style={{ color: "#d87d2e", fontSize: "16px" }}
                              className="mb-1"
                            >
                              Optional Festival Leave Summary
                            </p>
                            {optionalHolidaySummary && (
                              <>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Total Optional Festival Leaves</span>
                                  {/* <span>Optional Holiday Leave Summary</span> */}
                                  <span>
                                    :{" "}
                                    {optionalHolidaySummary.totalOptionalHolidayLeavesAllowed.toString()
                                      .length > 1
                                      ? `${optionalHolidaySummary.totalOptionalHolidayLeavesAllowed}`
                                      : `0${optionalHolidaySummary.totalOptionalHolidayLeavesAllowed}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Approved Leaves</span>
                                  <span>
                                    :{" "}
                                    {optionalHolidaySummary.approved.toString()
                                      .length > 1
                                      ? `${optionalHolidaySummary.approved}`
                                      : `0${optionalHolidaySummary.approved}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Pending Approval</span>
                                  <span>
                                    :{" "}
                                    {optionalHolidaySummary.pendingApproval.toString()
                                      .length > 1
                                      ? `${optionalHolidaySummary.pendingApproval}`
                                      : `0${optionalHolidaySummary.pendingApproval}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Rejected</span>
                                  <span>
                                    :{" "}
                                    {optionalHolidaySummary.rejected.toString()
                                      .length > 1
                                      ? `${optionalHolidaySummary.rejected}`
                                      : `0${optionalHolidaySummary.rejected}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Availed</span>
                                  <span>
                                    :{" "}
                                    {optionalHolidaySummary.availed.toString()
                                      .length > 1
                                      ? `${optionalHolidaySummary.availed}`
                                      : `0${optionalHolidaySummary.availed}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>Cancelled</span>
                                  <span>
                                    :{" "}
                                    {optionalHolidaySummary.cancelled.toString()
                                      .length > 1
                                      ? `${optionalHolidaySummary.cancelled}`
                                      : `0${optionalHolidaySummary.cancelled}`}
                                  </span>
                                </p>
                                <p className="mb-1 d-flex justify-content-between">
                                  <span>
                                    Optional Festival Leaves Available
                                  </span>
                                  <span>
                                    :{" "}
                                    {optionalHolidaySummary.availableLeaves.toString()
                                      .length > 1
                                      ? `${optionalHolidaySummary.availableLeaves}`
                                      : `0${optionalHolidaySummary.availableLeaves}`}
                                  </span>
                                </p>
                              </>
                            )}
                            {((otherLeavesSummary[0].leaveCount > 0 &&
                              otherLeavesSummary[0].status !== -2) ||
                              (otherLeavesSummary[1].leaveCount > 0 &&
                                otherLeavesSummary[1].status !== -2)) && (
                              <>
                                <hr />
                                <div
                                  style={{ color: "#d87d2e" }}
                                  className="extra_info"
                                >
                                  Extra Information
                                </div>
                              </>
                            )}
                            {(otherLeavesSummary[0].leaveCount &&
                              otherLeavesSummary[0].status !== -2) > 0 && (
                              <p className="mb-1 d-flex justify-content-between">
                                <span>Maternity Leaves (No. of Leaves)</span>
                                <span>
                                  : {otherLeavesSummary[0].leaveCount}
                                </span>
                              </p>
                            )}
                            {(otherLeavesSummary[1].leaveCount &&
                              otherLeavesSummary[1].status !== -2) > 0 && (
                              <p className="mb-1 d-flex justify-content-between">
                                <span>Paternity Leaves (No. of Leaves)</span>
                                <span>
                                  : {otherLeavesSummary[1].leaveCount}
                                </span>
                              </p>
                            )}
                          </div>
                          <div
                            className="mt-2 leaveNote"
                            // style={{ textAlign: "justify" }}
                          >
                            <span className="leaveNoteSpan">* </span>
                            The leave credits you see in this screen is not up
                            to date and is ONLY indicative, your leave
                            eligibility may vary, hence confirm your leave
                            balance with the HR/Payroll before you apply.
                          </div>
                        </Col>
                        <Col className="border_divider" md={12} lg={7}>
                          <Card
                            className="today_date"
                            style={{ background: "#242034", color: "#fff" }}
                          >
                            <Card.Body className="text-center py-2">
                              {moment(date).format("MMMM Do YYYY")},{" "}
                              {moment(date).format("dddd")}
                              {/* 24th October 2021, Wednesday */}
                            </Card.Body>
                          </Card>
                          {desData ? (
                            <div
                              style={{ fontSize: "18px", color: "#d87d2e" }}
                              className="mt-3 mb-3 text-center"
                            >
                              {desData}
                            </div>
                          ) : (
                            <div className="mt-3 mb-3 text-center text-muted">
                              No data available on this day
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <div className="my_leave_applications">
                  {defaultTabActiveKey && (
                    <MyLeaveApplications
                      user={user}
                      leaveTypesList={leaveTypesList}
                      userManagerList={userManagerList}
                      applyForLeave={applyForLeave}
                      employeeLeaveList={employeeLeaveList}
                      updateLeave={updateLeave}
                      leaveMasterTypeList={leaveMasterTypeList}
                      defaultTabActiveKey={defaultTabActiveKey}
                      onsetLeaveId={onsetLeaveId}
                      leaveId={leaveId}
                      getemployeeLeaveList={getemployeeLeaveList}
                      showNextYearLeave={showNextYearLeave}
                      getOtherLeavesSummary={getOtherLeavesSummary}
                      getEmployeeLeavesSummary={getEmployeeLeavesSummary}
                      getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
                      leaveById={leaveById}
                      showLeaveById={showLeaveById}
                      closeLeaveByIdData={closeLeaveByIdData}
                      currentYear={currentYear}
                      totalEmployeeLeaveList={totalEmployeeLeaveList}
                      onsetDate={onsetDate}
                      getHolidays={getHolidays}
                      holidaysList={holidaysList.filter(
                        (list) => list.type === HOLIDAY_TYPE_OPTIONAL
                      )}
                      canApplyOptionalHolidayLeave={
                        canApplyOptionalHolidayLeave
                      }
                      canApply={canApply}
                      getOptionalHolidayLeaveSummary={
                        getOptionalHolidayLeaveSummary
                      }
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MyLeavesView;
