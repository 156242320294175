import React from "react";
import { IoPerson } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
// import { MdAssignmentInd } from "react-icons/md";
import { Col, Card, Row } from "react-bootstrap";
// import ViewUser from "./ViewUser";

const WFHAndLeaveManagementCard = ({
  getUserWfhAndLeaveData,

  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
}) => {
  return (
    <>
      {getUserWfhAndLeaveData.length > 0 ? (
        <Row>
          {getUserWfhAndLeaveData.map((user) => {
            return (
              <Col md={6} lg={6} xl={3} className="col-xxl-3" key={user._id}>
                <Card className="mt-3">
                  <Card.Body>
                    <Card.Title className="text-center">
                      <span className="card_icon fw-bold">
                        <IoPerson className="icon" />
                        {/* {user.FirstName}
                        {user.lastName} */}
                        {`${" "}${user.FirstName}${" "}${user.lastName}`}
                      </span>
                    </Card.Title>
                    <p className="text-muted mb-1">
                      <AiOutlineMail className="icon" />
                      {user.email}
                    </p>
                    <p className="text-muted mb-1">
                      <BiPhone className="icon" />
                      {user.phoneNumber ? user.phoneNumber : "-"}
                    </p>

                    {/* 
                    <p className="text-muted mb-1">
                      <MdPlace />{" "}
                      {getUserWfhAndLeaveData.employeeLocation.name
                        ? getUserWfhAndLeaveData.employeeLocation.name
                        : "-"}
                    </p> */}
                    {/* <p className="text-muted mb-1">
                      <MdAssignmentInd />{" "}
                      {user.employeeNumber ? user.employeeNumber : " -"}
                    </p> */}
                    {/* <hr /> */}
                    {/* {user.wFHDetails > 0 ? (
                      <div className="user_edit ">
                        <div className="view__btn d-flex  justify-content-between">
                          <div>
                            <ButtonView
                              // onClick={() => handleView(user)}
                              title={"On Wfh"}
                              styles="success"
                            />
                          </div>
                          <div>
                            <ButtonView
                              // onClick={() => handleView(user)}
                              title={"On Leave"}
                              styles="danger"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="view__btn d-flex  justify-content-between">
                        <div>
                          <ButtonView
                            // onClick={() => handleView(user)}
                            title={"On Leave"}
                            styles="success"
                          />
                        </div>
                        <div>
                          <ButtonView
                            // onClick={() => handleView(user)}
                            title={"On Wfh"}
                            styles="danger"
                          />
                        </div>
                      </div>
                    )} */}
                    {user.wFHDetails && user.wFHDetails !== "none" && (
                      <div className="wFHDetails">
                        <i>
                          On WFH{" "}
                          {user.wFHDetails === "F"
                            ? ""
                            : user.wFHDetails === "1"
                            ? "- First Half"
                            : user.wFHDetails === "2"
                            ? "- Second Half"
                            : user.wFHDetails === "none"
                            ? " "
                            : ""}
                        </i>

                        {/* <div className="view__btn ">
                          <ButtonView
                            // onClick={() => handleView(user)}
                            title={"OnLeave"}
                            styles="danger"
                          />
                        </div> */}
                      </div>
                    )}

                    {user.leaveDetails && user.leaveDetails !== "none" && (
                      <div className="leaveDetails">
                        <i>
                          On Leave{" "}
                          {user.leaveDetails === "F"
                            ? ""
                            : user.leaveDetails === "1"
                            ? "- First Half"
                            : user.leaveDetails === "2"
                            ? "- Second Half"
                            : user.leaveDetails === "none"
                            ? " "
                            : ""}
                        </i>
                        {/* <div className="view__btn ">
                          <ButtonView
                            // onClick={() => handleView(user)}
                            title={"OnLeave"}
                            styles="danger"
                          />
                        </div> */}
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : (
        // <div className="margin_x mt-2 d-flex justify-content-end">
        //   <Pagination
        //     rowsPerPage={rowsPerPage}
        //     rowCount={rowCount}
        //     onChangePage={onChangePage}
        //     currentPage={currentPage}
        //   />
        // </div>
        getUserWfhAndLeaveData.length === 0 && (
          <div className="text-center mt-3">
            <h4>No data to display</h4>
          </div>
        )
      )}
    </>
  );
};

export default WFHAndLeaveManagementCard;















