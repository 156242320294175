import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { BiHomeSmile } from "react-icons/bi";

import { Header } from "../../common";
import ApplyForWfhApplication from "./components/ApplyForWfhApplication";
import Calender from "./components/calender";
import ModalView from "./components/ModalView";
import {
  HOLIDAY_TYPE_REGULAR,
  // HOLIDAY_TYPE_OPTIONAL,
  LEAVE_STATUS_CANCELLED,
  LEAVE_STATUS_PENDING_APPROVAL,
} from "../../../constants/env";

const ApplyForWFHView = ({
  applyForWfh,
  userManagerList,
  user,
  getUserWfhList,
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
  userWfhListSuccess,
  handleSelect,
  employeeId,
  getUserWfhData,
  getUserWfh,
  userWfhListCalender,
  userCalender,
  userWfhList,
  holidaysList,
  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,
  onsetDefaultTabActiveKey,
  srollToSummary,
  combineCalenderData,
  date,
  desData,
  updateWfh,
  currentYear,
  onsetYear,
  updateGetUserWfhData,
  setKey,
  defaultTabActiveKey,
  cancelGetUserWfhBYId,
  wfhId,
  changingYear,
  // key,
}) => {
  // console.log("changingYear", changingYear);

  useEffect(() => {
    var combineDate = [];
    holidaysList
      .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
      .forEach((i) => {
        combineDate.push({ des: `${i.description} (Holiday)`, date: i.date });
      });
    userCalender
      .filter((list) => list.status === 0)
      .forEach((i) => {
        let startDate = new Date(i.startDate.slice(0, 10)); //YYYY-MM-DD
        let endDate = new Date(i.endDate.slice(0, 10)); //YYYY-MM-DD
        let arr = [];
        let dt = new Date(startDate);
        while (dt <= endDate) {
          arr.push(moment(dt).format("YYYY-MM-DD"));
          dt.setDate(dt.getDate() + 1);
        }
        const dateRange = `(${moment(i.startDate).format("ll")} - ${moment(
          i.endDate
        ).format("ll")})`;
        // console.log("i", i);
        arr.forEach((list) => {
          combineDate.push({
            des: `${i.leaveSubType} WFH, ${
              i.startDate === i.endDate
                ? moment(i.startDate).format("ll")
                : dateRange
            } (Pending Approval)`,
            date: list,
          });
        });
      });

    userCalender
      .filter((list) => list.status === 1)
      .forEach((i) => {
        let startDate = new Date(i.startDate.slice(0, 10)); //YYYY-MM-DD
        let endDate = new Date(i.endDate.slice(0, 10)); //YYYY-MM-DD
        let arr = [];
        let dt = new Date(startDate);
        while (dt <= endDate) {
          arr.push(moment(dt).format("YYYY-MM-DD"));
          dt.setDate(dt.getDate() + 1);
        }
        const dateRange = `(${moment(i.startDate).format("ll")} - ${moment(
          i.endDate
        ).format("ll")})`;
        arr.forEach((list) => {
          combineDate.push({
            des: `${i.leaveSubType} WFH, ${
              i.startDate === i.endDate
                ? moment(i.startDate).format("ll")
                : dateRange
            } (Approved)`,
            date: list,
          });
        });
      });

    userCalender
      .filter((list) => list.status === -1)
      .forEach((i) => {
        let startDate = new Date(i.startDate.slice(0, 10)); //YYYY-MM-DD
        let endDate = new Date(i.endDate.slice(0, 10)); //YYYY-MM-DD
        let arr = [];
        let dt = new Date(startDate);
        while (dt <= endDate) {
          arr.push(moment(dt).format("YYYY-MM-DD"));
          dt.setDate(dt.getDate() + 1);
        }
        const dateRange = `(${moment(i.startDate).format("ll")} - ${moment(
          i.endDate
        ).format("ll")})`;
        arr.forEach((list) => {
          combineDate.push({
            des: `${i.leaveSubType} WFH, ${
              i.startDate === i.endDate
                ? moment(i.startDate).format("ll")
                : dateRange
            } (Rejected)`,
            date: list,
          });
        });
      });
    onsetCombineCalenderList(combineDate);
    const data = combineDate.find((list) => {
      return list.date === date;
    });
    if (data && data.des) {
      // console.log('datada'data.des)
      onsetDescription(data.des);
    } else {
      onsetDescription("");
    }
  }, [
    userCalender,
    holidaysList,
    date,
    onsetCombineCalenderList,
    onsetDescription,
  ]);

  useEffect(() => {
    if (userWfhList) {
      const leaveDependOnId = userWfhList.forEach((list) => {
        return list.status === wfhId;
      });

      if (leaveDependOnId) {
        if (leaveDependOnId.status === LEAVE_STATUS_PENDING_APPROVAL) {
          onsetDefaultTabActiveKey(LEAVE_STATUS_PENDING_APPROVAL);
        } else if (leaveDependOnId.status === LEAVE_STATUS_CANCELLED) {
          onsetDefaultTabActiveKey(LEAVE_STATUS_CANCELLED);
        } else {
          onsetDefaultTabActiveKey(LEAVE_STATUS_PENDING_APPROVAL);
        }
      } else {
        onsetDefaultTabActiveKey(LEAVE_STATUS_PENDING_APPROVAL);
      }
    } else {
      onsetDefaultTabActiveKey(LEAVE_STATUS_PENDING_APPROVAL);
    }
  }, [userWfhList, wfhId, onsetDefaultTabActiveKey]);
  return (
    <>
      <Header label={"My WFH"} Icon={BiHomeSmile} />
      <div className="main__body padding">
        <Container>
          <Row>
            <Col lg={12} xl={4} className="col-xxl-4">
              <div className="mt-3">
                <Calender
                  userWfhListCalender={userWfhListCalender}
                  userCalender={userCalender}
                  userWfhList={userWfhList}
                  holidaysList={holidaysList}
                  onsetDate={onsetDate}
                  onsetDescription={onsetDescription}
                  combineCalenderData={combineCalenderData}
                  srollToSummary={srollToSummary}
                />
              </div>
              {/* <HolidayList holidaysList={holidaysList} /> */}
            </Col>
            <Col lg={12} xl={8} className="col-xxl-8">
              <div className="column_divider mt-3">
                <div className="symbols">
                  <Card>
                    <Card.Body>
                      <Row className="calender_symbols g-0">
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--maroon me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>Holiday</span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--blue me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Pending Approval
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          ref={srollToSummary}
                          className="d-block d-md-none"
                        ></Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--green me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Approved Leave
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--red me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Rejected Leave
                          </span>
                        </Col>
                        {/* <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--orange me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>Opt. Holiday</span>
                        </Col> */}
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <div className="leave_overview">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col className="border_dividerr" md={12} lg={12}>
                          <Card
                            className="today_date"
                            style={{ background: "#242034", color: "#fff" }}
                          >
                            <Card.Body className="text-center py-2">
                              {moment(date).format("MMMM Do YYYY")},{" "}
                              {moment(date).format("dddd")}
                              {/* 24th October 2021, Wednesday */}
                            </Card.Body>
                          </Card>

                          {desData ? (
                            <div
                              style={{ fontSize: "18px", color: "#d87d2e" }}
                              className="mt-3 mb-3 text-center"
                            >
                              {desData}
                            </div>
                          ) : (
                            <div className="mt-3 mb-3 text-center text-muted">
                              No data available on this day
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <div className="my_leave_applications">
                  {/* {defaultTabActiveKey && ( */}
                  <ApplyForWfhApplication
                    applyForWfh={applyForWfh}
                    userWfhListSuccess={userWfhListSuccess}
                    userManagerList={userManagerList}
                    user={user}
                    getUserWfhList={getUserWfhList}
                    rowsPerPage={rowsPerPage}
                    rowCount={rowCount}
                    onChangePage={onChangePage}
                    currentPage={currentPage}
                    handleSelect={handleSelect}
                    employeeId={employeeId}
                    getUserWfh={getUserWfh}
                    updateWfh={updateWfh}
                    currentYear={currentYear}
                    userWfhListCalender={userWfhListCalender}
                    onsetDate={onsetDate}
                    onsetYear={onsetYear}
                    userCalender={userCalender}
                    // key={key}
                    setKey={setKey}
                    defaultTabActiveKey={defaultTabActiveKey}
                    cancelGetUserWfhBYId={cancelGetUserWfhBYId}
                    changingYear={changingYear}
                    updateGetUserWfhData={updateGetUserWfhData}
                  />
                  {/* )} */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <ModalView
          getUserWfhData={getUserWfhData}
          userWfhList={userWfhList}
          cancelGetUserWfhBYId={cancelGetUserWfhBYId}
          updateGetUserWfhData={updateGetUserWfhData}
        />
      </div>
    </>
  );
};

export default ApplyForWFHView;
