import React from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { ButtonView } from "../../../controls";
import { DataTable } from "../../../common";

const CategoryTypes = ({
  data,
  categoryDocumentsListSuccess,
  categoryDocumentsList,
  downloadDocument,
  index,
  activeId,
  toggleActive,
}) => {
  // console.log("activeId", activeId);
  const filteredCategoryDocumentsList = categoryDocumentsList.filter(
    (item) => item.category._id === data._id
  );

  const categoryColumns = [
    {
      name: "Name",
      selector: "description",
      sortable: true,
      grow: 6,
      
    },
    {
      name: "Option",
      center: true,
      minWidth: "100px",
      // grow: 2,
      cell: (row) => {
        return (
          <div className="d-flex">
            <div className="me-1">
              <ButtonView
                onClick={() => {
                  downloadDocument(row._id);
                }}
                title={"Download"}
                size="small"
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Col sm={12} md={12} lg={12}>
        {categoryDocumentsListSuccess === false ? (
          <div className="imp__document--body mb-2">
            <Row>
              <Col sm={12}>
                <Skeleton height={"50px"} />
              </Col>
            </Row>
          </div>
        ) : (
          <div
            className={
              activeId === index ? "panel-wrap active-panel" : "panel-wrap"
            }
          >
            <Accordion.Toggle
              //className="panel-toggle"
              //variant="link"
              eventKey={index}
              onClick={() => toggleActive(index)}
            >
              <div className="panel-header">
                <p>{data.name}</p>
                {activeId === index ? (
                  <h2 className="mb-3">-</h2>
                ) : (
                  <h2 className="mb-3">+</h2>
                )}
              </div>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={index}>
              <div className="panel-body">
                <DataTable
                  data={filteredCategoryDocumentsList}
                  columns={categoryColumns}
                  pagination={false}
                  // defaultSortField={"name"}
                  // paginationPerPage={2}
                  // paginationServer={false}
                />
              </div>
            </Accordion.Collapse>
          </div>
        )}
      </Col>
    </>
  );
};

export default CategoryTypes;
