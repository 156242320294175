import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  LEAVE_TYPES_PENDING: "LEAVE_TYPES_PENDING",
  LEAVE_TYPES_SUCCESS: "LEAVE_TYPES_SUCCESS",
  LEAVE_TYPES_ERROR: "LEAVE_TYPES_ERROR",

  LEAVE_TYPES_ADD_PENDING: "LEAVE_TYPES_ADD_PENDING", 
  LEAVE_TYPES_ADD_ERROR: "LEAVE_TYPES_ADD_ERROR", 
  LEAVE_TYPES_ADD_SUCCESS: "LEAVE_TYPES_ADD_SUCCESS",

  LEAVE_TYPES_EDIT_PENDING: "LEAVE_TYPES_EDIT_PENDING", 
  LEAVE_TYPES_EDIT_ERROR: "LEAVE_TYPES_EDIT_ERROR", 
  LEAVE_TYPES_EDIT_SUCCESS: "LEAVE_TYPES_EDIT_SUCCESS",

  LEAVE_TYPES_DELETE_PENDING: "LEAVE_TYPES_DELETE_PENDING", 
  LEAVE_TYPES_DELETE_ERROR: "LEAVE_TYPES_DELETE_ERROR", 
  LEAVE_TYPES_DELETE_SUCCESS: "LEAVE_TYPES_DELETE_SUCCESS",
};

export function addLeaveType(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEAVE_TYPES_ADD_PENDING,
      payload: {
        leaveTypesAddPending: true,
        leaveTypesAddSuccess: false,
        leaveTypesAddError: null,
      },
    });
    // console.log(data)

    Api.post("leaveManagement/hradmin/addLeaveType", data)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully added new leave type") {
          dispatch(list(false))
          dispatch({
            type: actionTypes.LEAVE_TYPES_ADD_SUCCESS,
            payload: {
              leaveTypesAddPending: false,
              leaveTypesAddSuccess: true,
              leaveTypesAddError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new leave type.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.LEAVE_TYPES_ADD_ERROR,
          payload: {
            leaveTypesAddPending: false,
            leaveTypesAddSuccess: true,
            leaveTypesAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add leave type.",
          })
        );
      })
  }
}

export function editLeaveType(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEAVE_TYPES_EDIT_PENDING,
      payload: {
        leaveTypesEditPending: true,
        leaveTypesEditSuccess: false,
        leaveTypesEditError: null,
      },
    });
    // console.log(data, id)

    Api.put(`leaveManagement/hradmin/updateLeaveType/${id}`, data)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully updated leave type") {
          dispatch(list(false))
          dispatch({
            type: actionTypes.LEAVE_TYPES_EDIT_SUCCESS,
            payload: {
              leaveTypesEditPending: false,
              leaveTypesEditSuccess: true,
              leaveTypesEditError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated leave type.",
            })
          );
        }
      })
      .catch(err => {
        // console.log(err)
        dispatch({
          type: actionTypes.LEAVE_TYPES_EDIT_ERROR,
          payload: {
            leaveTypesEditPending: false,
            leaveTypesEditSuccess: true,
            leaveTypesEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit leave type.",
          })
        );
      })
  }
}

export function deleteLeaveType(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEAVE_TYPES_DELETE_PENDING,
      payload: {
        leaveTypesDeletePending: true,
        leaveTypesDeleteSuccess: false,
        leaveTypesDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`leaveManagement/hradmin/deleteLeaveType/${id}`)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully deleted leave type") {
          dispatch({
            type: actionTypes.LEAVE_TYPES_DELETE_SUCCESS,
            payload: {
              leaveTypesDeletePending: false,
              leaveTypesDeleteSuccess: true,
              leaveTypesDeleteError: null,
  
              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted leave type.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.LEAVE_TYPES_DELETE_ERROR,
          payload: {
            leaveTypesDeletePending: false,
            leaveTypesDeleteSuccess: true,
            leaveTypesDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add leave type.",
          })
        );
      })
  }
}

export function list(showLoader = true) {
  return (dispatch) => {
    if(showLoader) {
      dispatch({
        type: actionTypes.LEAVE_TYPES_PENDING,
        payload: {
          leaveTypesPending: true,
          leaveTypesSuccess: false,
          leaveTypesError: null,
        },
      });
    }

    Api.get("leaveManagement/hradmin/getLeaveTypesList")
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully fetched leave type list") {
          dispatch({
            type: actionTypes.LEAVE_TYPES_SUCCESS,
            payload: {
              leaveTypesPending: false,
              leaveTypesSuccess: true,
              leaveTypesError: null,
  
              leaveTypesList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.LEAVE_TYPES_SUCCESS,
          payload: {
            leaveTypesPending: false,
            leaveTypesSuccess: true,
            leaveTypesError: null,
          },
        });
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.LEAVE_TYPES_ERROR,
          payload: {
            leaveTypesPending: false,
            leaveTypesSuccess: true,
            leaveTypesError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get leaveTypes list.",
          })
        );
      })
  }
}