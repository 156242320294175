import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";

import { ButtonView, Select, Input } from "../../../controls";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";

const data = {
  employeeId: "",
  year: "",
};

const dateData = {
  startDate: "",
  endDate: "",
};

const TypeOptions = [
  { value: "By Employee", label: "By Employee" },
  { value: "By Date-Range", label: "By Date-Range" },
];

const SearchUsers = ({
  userList,
  getEmployeeLeaveList,
  getEmployeeLeavesSummary,
  getOtherLeavesSummary,
  getOptionalHolidayLeaveSummary,
  reset,
  showLeaveSummary,
  typeSelection,
  dateRangeEmployeeLeaveList,
}) => {
  // console.log("userList", userList);

  const modifliedUserList = userList.map((list) => {
    return {
      label: `${list.firstName} ${list.lastName}`,
      value: list._id,
    };
  });

  var sorted = modifliedUserList.sort((a, b) =>
    a.label.localeCompare(b.label, undefined, { caseFirst: "upper" })
  );

  const [employeeData, setEmployeeData] = useState(data);
  // const [durationData, setDurationData] = useState(null);
  const [nameValue, setNameValue] = useState(null);
  const [yearValue, setYearValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [type, setType] = useState("");
  const [date, setDate] = useState(dateData);

  const handleSubmit = () => {
    // setDurationData(date);
    // console.log("row", date);
    // console.log("type", type);
    const rules = {
      required: ["type"],
    };
    const { errors } = validator.validate(rules, {
      ...trimFieldsData(type),
    });

    setErrors(errors);

    if (type.value === "By Employee") {
      const rules = {
        required: ["employeeId", "year"],
      };

      const { errors } = validator.validate(rules, {
        ...trimFieldsData(employeeData),
      });

      setErrors(errors);
      if (!Object.keys(errors).length) {
        getEmployeeLeavesSummary(employeeData);
        getOtherLeavesSummary(employeeData);
        getOptionalHolidayLeaveSummary(employeeData);
        getEmployeeLeaveList(employeeData);
        showLeaveSummary(true);
      }
    } else if (type.value === "By Date-Range") {
      const rules = {
        required: ["startDate", "endDate"],
      };

      const { errors } = validator.validate(rules, {
        ...trimFieldsData(date),
      });

      setErrors(errors);
      if (!Object.keys(errors).length) {
        if (date.startDate > date.endDate) {
          alert("Invalid Date Range");
        } else {
          dateRangeEmployeeLeaveList(date);
          showLeaveSummary(true);
        }
      }
    }
  };

  return (
    <Card className="mt-3">
      <Card.Body>
        <div className="leave__reporting__container ">
          <Row className="d-flex align-items-end">
            <Col sm={12} lg={5} className="mb-3 mb-lg-1">
              <Select
                name="type"
                label="Type"
                isSearchable={true}
                placeholder="Select Type"
                options={TypeOptions}
                value={type}
                onChange={(val) => {
                  // console.log("val", val);
                  setType(val);
                  typeSelection(val);
                  setDate(dateData);
                  setNameValue(null);
                  setYearValue(null);
                  setEmployeeData(data);
                }}
                className="select"
                classNamePrefix="select"
                error={errors["type"]}
                isrequired={true}
              />
            </Col>
          </Row>
          {type.value === "By Employee" ? (
            <Row className="d-flex align-items-end mt-2">
              <Col sm={12} lg={3} className="mb-3 mb-lg-0">
                <Select
                  name="name"
                  label="Select Employee Name"
                  isSearchable={true}
                  placeholder="Search"
                  options={sorted}
                  value={nameValue}
                  onChange={(val) => {
                    setNameValue(val);
                    setEmployeeData({ ...employeeData, employeeId: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                  error={errors["employeeId"]}
                  isrequired={true}
                />
              </Col>
              <Col sm={12} lg={2} className="mb-3 mb-lg-0">
                <Select
                  name="year"
                  label="Select Year"
                  isSearchable={false}
                  placeholder="Select"
                  options={[
                    {
                      value: new Date().getFullYear() - 3,
                      label: `${new Date().getFullYear() - 3}`,
                    },
                    {
                      value: new Date().getFullYear() - 2,
                      label: `${new Date().getFullYear() - 2}`,
                    },
                    {
                      value: new Date().getFullYear() - 1,
                      label: `${new Date().getFullYear() - 1}`,
                    },
                    {
                      value: new Date().getFullYear(),
                      label: `${new Date().getFullYear()}`,
                    },
                    {
                      value: new Date().getFullYear() + 1,
                      label: `${new Date().getFullYear() + 1}`,
                    },
                  ]}
                  value={yearValue}
                  onChange={(val) => {
                    setYearValue(val);
                    setEmployeeData({ ...employeeData, year: val.value });
                  }}
                  className="select"
                  classNamePrefix="select"
                  error={errors["year"]}
                  isrequired={true}
                />
              </Col>
            </Row>
          ) : type.value === "By Date-Range" ? (
            <>
              <Row>
                <Col md={12} lg={5} className="mb-3 mt-2">
                  <Input
                    name="startDate"
                    label="Start Date"
                    value={date.startDate}
                    // value={moment().max(date.startDate)}
                    type="date"
                    onChange={(e) =>
                      setDate({
                        ...date,
                        startDate: e.target.value,
                      })
                    }
                    size="fluid"
                    // min={moment(new Date()).format("YYYY-MM-DD")}
                    error={errors["startDate"]}
                    isrequired={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={5} className="mb-3">
                  <Input
                    name="endDate"
                    label="End Date"
                    value={date.endDate}
                    // value={moment().max(date.endDate, "DD/MM/YY")}
                    type="date"
                    onChange={(e) =>
                      setDate({
                        ...date,
                        endDate: e.target.value,
                      })
                    }
                    size="fluid"
                    // min={date.startDate}
                    max={new Date().toISOString().split("T")[0]}
                    error={errors["endDate"]}
                    isrequired={true}
                  />
                </Col>
              </Row>
            </>
          ) : null}
          <Row className="d-flex mt-3 align-items-end">
            <Col sm={12} lg={2} className="mb-2 mb-lg-1">
              <ButtonView
                // onClick={handleSubmit}
                onClick={() => {
                  handleSubmit();
                }}
                title={"View"}
                styles="secondary"
                // tooltip="Copy to clipboard"
              />
            </Col>
            <Col sm={12} lg={2} className="mb-3 mb-lg-1">
              <ButtonView
                onClick={() => {
                  reset();
                  setType("");
                  setNameValue(null);
                  setYearValue(null);
                  setEmployeeData(data);
                  setDate(dateData);
                }}
                title={"Clear"}
                // tooltip="Copy to clipboard"
              />
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SearchUsers;
