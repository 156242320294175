import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, TextArea } from "../../../controls";

const ApplicationMngt = ({
  addApplicationManager,
  editApplicationManager,
  deleteApplicationManager,
  getApplicationManagerList,
  getUserApplicationManagerList,
  //applicationList,
}) => {
  console.log("getApplicationlist", getApplicationManagerList);
  console.log("inside application manager card");
  console.log(getUserApplicationManagerList, "this.getUserApplicationManagerList");
  const [showAddApplication, setShowAddApplication] = useState(false);
  const [application, setApplication] = useState("");
  const [applicationDes, setApplicationDes] = useState("");
  const [applicationLink, setApplicationLink] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [applicationId, setApplicationId] = useState("");
  // const [location, setLocation] = useState([]);

  // const data = [
  //   {
  //     name: "checkpoint",
  //     description: "description 1",
  //     link: "hrms.in",
  //   },
  //   {
  //     name: "hrms",
  //     description: "description 1",
  //     link: "checkpoint.in",
  //   },
  //   { name: "timesheet", description: "description 2", link: "timesheet.in" },
  // ];

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      grow: 8,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      grow: 8,
    },
    {
      name: "Link",
      selector: "link",
      sortable: true,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: row => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setApplication(row.name);
                setApplicationDes(row.description);
                setApplicationLink(row.link);
                setShowAddApplication(true);
                setApplicationId(row._id);
                // setLocation(row.location);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete application ?")) {
                // console.log(row._id)
                deleteApplicationManager(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  // const handleInputChange = (event) => {
  //   const target = event.target;
  //   var value = target.value;

  //   if (target.checked) {
  //     // console.log('hi')
  //     setLocation((prevData) => {
  //       const newData = prevData.filter((v) => v !== value);
  //       return [...newData, value];
  //     });
  //   } else {
  //     // console.log("hi2")
  //     setLocation((prevData) => {
  //       const newData = prevData.filter((v) => v !== value);
  //       return [...newData];
  //     });
  //   }
  // };

  const linkValidation = () => {
    const linkregrex = /^(?:http(s)?:\/\/)?[\w.-]+(?:[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/;
    // const linkregrex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (linkregrex.test(applicationLink)) {
    } else {
      alert("Kindly enter valid link/URL.");
    }
  };

  const handleAddApplicationManager = () => {
    var rules = {
      required: ["application", "applicationDes", "applicationLink"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ application, applicationDes, applicationLink }),
    });

    setErrors(errors);
    linkValidation();

    if (!Object.keys(errors).length) {
      if (showEdit) {
        editApplicationManager(application, applicationDes, applicationLink, applicationId);
        // edit
      } else {
        addApplicationManager(application, applicationDes, applicationLink);
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddApplication(false);
    setApplication("");
    setApplicationDes("");
    setApplicationLink("");
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Application Manager</div>
              <div className="d-none d-md-block">Application Management</div>
            </Card.Title>
            <div className="add_permission">
              <ButtonView
                onClick={() => setShowAddApplication(true)}
                title={"Add Application"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={getUserApplicationManagerList}
            //data={data}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={2}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal show={showAddApplication} onHide={handleClose} aria-labelledby="add_role_modal">
        <Modal.Header>
          <Modal.Title id="add_role_modal">{showEdit ? "Edit Application" : "Add Application"}</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12}>
                <Input
                  name="application"
                  label="Name"
                  value={application}
                  type="text"
                  placeholder="Name"
                  onChange={e => setApplication(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["application"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12} className="mt-3">
                <TextArea
                  name="applicationDes"
                  label="Description"
                  value={applicationDes}
                  type="number"
                  placeholder="Description"
                  onChange={e => setApplicationDes(e.target.value)}
                  size="fluid"
                  error={errors["applicationDes"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12} className="mt-3">
                <TextArea
                  name="applicationLink"
                  label="Link"
                  value={applicationLink}
                  type="text"
                  placeholder="Link"
                  onChange={e => setApplicationLink(e.target.value)}
                  size="fluid"
                  error={errors["applicationLink"]}
                  isrequired={true}
                />
              </Col>

              <Col xs={12} className="mt-3">
                <ButtonView onClick={handleAddApplicationManager} title={showEdit ? "Save" : "Add"} styles="secondary" />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ApplicationMngt;
