import { actionTypes } from "./roleActions";

const {
  ROLE_PENDING,
  ROLE_ERROR,
  ROLE_SUCCESS,

  ROLE_PERMISSION_PENDING,
  ROLE_PERMISSION_ERROR,
  ROLE_PERMISSION_SUCCESS,
  
  ROLEADD_PENDING,
  ROLEADD_ERROR,
  ROLEADD_SUCCESS,

  ROLEEDIT_PENDING,
  ROLEEDIT_ERROR,
  ROLEEDIT_SUCCESS,

  ROLEDELETE_PENDING,
  ROLEDELETE_ERROR,
  ROLEDELETE_SUCCESS,
} = actionTypes;

const initialState = {
  rolePending: false,
  roleSuccess: false,
  roleError: null,

  rolepermissionPending: false,
  rolepermissionSuccess: false,
  rolepermissionError: null,

  roleAddPending: false,
  roleAddSuccess: false,
  roleAddError: null,

  roleEditPending: false,
  roleEditSuccess: false,
  roleEditError: null,

  roleDeletePending: false,
  roleDeleteSuccess: false,
  roleDeleteError: null,

  roleList: [],
  rolepermissionList: [],
};

export default function lunch(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ROLE_PENDING:
    case ROLE_ERROR:
    case ROLE_SUCCESS:
      return Object.assign({}, state, payload);
    case ROLE_PERMISSION_PENDING:
    case ROLE_PERMISSION_ERROR:
    case ROLE_PERMISSION_SUCCESS:
      return Object.assign({}, state, payload);
    case ROLEADD_PENDING:
    case ROLEADD_ERROR:
      return Object.assign({}, state, payload);
    case ROLEADD_SUCCESS: {
      const newRole = payload.role;
      const newRoleList = [...state.roleList, newRole];

      const newState = {
        roleList: newRoleList,
        roleAddPending: payload.roleAddPending,
        roleAddSuccess: payload.roleAddSuccess,
        roleAddError: payload.roleAddError,
      }
      return Object.assign({}, state, newState);
    }
    case ROLEEDIT_PENDING:
    case ROLEEDIT_ERROR:
      return Object.assign({}, state, payload);
    case ROLEEDIT_SUCCESS: {
      const newRoleList = state.roleList.map(list => {
        if(list._id === payload.role._id) {
          return payload.role
        } else {
          return list
        }
      })
      return Object.assign({}, state, {roleList: newRoleList}, payload);
    }
    case ROLEDELETE_PENDING:
    case ROLEDELETE_ERROR:
      return Object.assign({}, state, payload);
    case ROLEDELETE_SUCCESS: {
      const newRoleList = state.roleList.filter(list => {
        return list._id !== payload.id
      })
      return Object.assign({}, state, {roleList: newRoleList}, payload);
    }
    default:
      return state;
  }
}
