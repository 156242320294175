import React from "react";

import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import moment from "moment";

import { ButtonView, Pagination } from "../../../controls";
import { DataTable } from "../../../common";

import LoaderView from "./LoadView";

const Unapproved = ({
  userQueryList,

  getUserQuery,

  rowsPerPage,
  rowCount,

  currentPage,

  getUserQueryList,
  userQueryListSuccess,
}) => {
  // console.log(
  //   "userQueryList",
  //   moment(userQueryList?.[0].createdAt).toISOString(true)
  // );
  const columns = [
    {
      name: " Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {moment(new Date(row.createdAt.slice(0, 10))).format("DD/MM/YYYY")}
        </span>
      ),
    },

    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => {
        // var stat = row[0].queryId.status;
        // console.log("row", row);
        if (row.status === "pending") {
          return (
            <span className="badge bg-warning-transparent">In-progress</span>
          );
        }
        if (row.status === "new") {
          return <span className="badge bg-danger-transparent">New</span>;
        }
        if (row.status === "reopened") {
          return <span className="badge bg-danger-transparent">Reopened</span>;
        }
        // if (row.status === -2) {
        //   return <span className="badge bg-cancel-transparent">Cancelled</span>;
        // }
      },
    },

    {
      name: "Subject",
      selector: "",
      center: true,
      grow: 4,
      cell: (row) => <span>{row.subject}</span>,
    },
    {
      name: "Option",
      center: true,
      minWidth: "160px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                const setKey = "pending";
                getUserQuery(row._id, setKey);
                // handleViewOpen(row);
              }}
              title={"View"}
              size="small"
              styles="secondary"
            />
          </div>
          {/* <ButtonView
            onClick={() => handleCancelOpen(row)}
            title={"Cancel"}
            size="small"
            styles="danger-outline"
          /> */}
        </div>
      ),
    },
  ];

  const pageChange = (currentPage) => {
    getUserQueryList({
      pageNumber: currentPage,
      status: "pending",
      limit: 7,
    });
  };
  if (userQueryListSuccess === false) {
    return <LoaderView />;
  } else {
    return (
      <>
        <div className="table_content px-2 attendance_table pb-3">
          <DataTable
            data={userQueryList}
            columns={columns}
            pagination={false}
            paginationPerPage={5}
            paginationServer={false}
          />
        </div>

        {userQueryList.length > 0 ? (
          <div className="margin_x mt-2 me-3 mb-3 d-flex justify-content-end">
            <Pagination
              rowsPerPage={rowsPerPage}
              rowCount={rowCount}
              onChangePage={pageChange}
              currentPage={currentPage}
            />
          </div>
        ) : null}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    userWfhList: state.employeeWfhReducer.userWfhList,
    page: state.employeeWfhReducer.page,
    userWfhListSuccess: state.employeeWfhReducer.userWfhListSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getUser: getUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Unapproved);
