import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import moment from "moment";

import { ButtonView, Select, DatePicker } from "../../../controls";
import {
  JOB_APPLICAITON_STATUS_IN_PROGRESS,
  INTERVIEW_OUTCOME_SELECTED,
  JOB_APPLICAITON_STATUS_APPLIED,
  INTERVIEW_OUTCOME_REJECTED,
  JOB_APPLICAITON_STATUS_ONHOLD,
  INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND,
  // JOB_APPLICAITON_STATUS_CLOSED,
} from "../../../../constants/env";

const data = {
  primaryTechnicalDomains: "",
  designationAppliedFor: "",
  experienceInMonths: "",
  qualification: "",
  noticePeriodEndDate: "",
  status: "",
};

const JobFilter = ({
  qualificationList,
  techDomainList,
  designationList,
  getJobApplicationList,
  setInterviewScheduleDetail,
  setProfileDetail,
  setRowId,
  onsetDefaultTabActiveKey,
}) => {
  const designationListOptions = designationList.map((list) => {
    return { value: list.name, label: list.name };
  });
  const techDomainListOptions = techDomainList.map((list) => {
    return { value: list.name, label: list.name };
  });
  const qualificationListOptions = qualificationList.map((list) => {
    return { value: list, label: list };
  });
  var expInYears = [];
  for (var j = 0; j <= 60; j++) {
    expInYears.push({ value: j, label: `${j} years` });
  }
  var status = [
    {
      value: JOB_APPLICAITON_STATUS_APPLIED,
      label: JOB_APPLICAITON_STATUS_APPLIED,
    },
    {
      value: JOB_APPLICAITON_STATUS_IN_PROGRESS,
      label: JOB_APPLICAITON_STATUS_IN_PROGRESS,
    },
    {
      value: INTERVIEW_OUTCOME_SELECTED,
      label: INTERVIEW_OUTCOME_SELECTED,
    },
    {
      value: INTERVIEW_OUTCOME_REJECTED,
      label: INTERVIEW_OUTCOME_REJECTED,
    },
    {
      value: JOB_APPLICAITON_STATUS_ONHOLD,
      label: JOB_APPLICAITON_STATUS_ONHOLD,
    },
    {
      value: INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND,
      label: INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND,
    },
  ];

  const [filterData, setFilterData] = useState(data);

  const handleSubmit = () => {
    var data = {};
    if (filterData.primaryTechnicalDomains) {
      data.primaryTechnicalDomains = filterData.primaryTechnicalDomains.value;
    }
    if (filterData.designationAppliedFor) {
      data.designationAppliedFor = filterData.designationAppliedFor.value;
    }
    if (filterData.experienceInMonths) {
      data.minExperienceInMonths = filterData.experienceInMonths.value * 12;
    }
    if (filterData.qualification) {
      data.qualification = filterData.qualification.value;
    }
    if (filterData.status) {
      data.status = filterData.status.value;
    }
    if (filterData.noticePeriodEndDate) {
      const formattedDate = moment(filterData.noticePeriodEndDate).format(
        "YYYY-MM-DD"
      );
      data.noticePeriodEndDate = formattedDate;
    }

    getJobApplicationList(data, false);
    onsetDefaultTabActiveKey("active");
    setInterviewScheduleDetail([]);
    setProfileDetail(null);
    setRowId("");
    // console.log(data);
  };

  return (
    <Card className="job_filter">
      <Card.Body>
        <h4 className="filter_heading mt-0 d-block d-md-none">
          FILTER <FaFilter size={20} />
        </h4>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="primaryTechnicalDomains"
                  label="Prime Domain"
                  isSearchable={false}
                  placeholder="Select"
                  value={filterData.primaryTechnicalDomains}
                  options={techDomainListOptions}
                  onChange={(val) =>
                    setFilterData({
                      ...filterData,
                      primaryTechnicalDomains: val,
                    })
                  }
                  className="select"
                  classNamePrefix="select"
                  //  error={errors["primaryTechnicalDomains"]}
                />
              </Col>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="expInYears"
                  isSearchable={false}
                  label="Minimum Experience"
                  placeholder="In Years"
                  value={filterData.experienceInMonths}
                  options={expInYears}
                  onChange={(val) =>
                    setFilterData({
                      ...filterData,
                      experienceInMonths: val,
                    })
                  }
                  className="select"
                  classNamePrefix="select"
                  // error={errors["expInYears"]}
                />
              </Col>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="status"
                  label="Status"
                  isSearchable={false}
                  placeholder="Select"
                  value={filterData.status}
                  options={status}
                  onChange={(val) => {
                    setFilterData({
                      ...filterData,
                      status: val,
                    });
                  }}
                  className="select"
                  classNamePrefix="select"
                />
              </Col>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="designationAppliedFor"
                  label="Applied Designation"
                  isSearchable={false}
                  placeholder="Select"
                  value={filterData.designationAppliedFor}
                  options={designationListOptions}
                  onChange={(val) =>
                    setFilterData({
                      ...filterData,
                      designationAppliedFor: val,
                    })
                  }
                  className="select"
                  classNamePrefix="select"
                  // error={errors["designationAppliedFor"]}
                />
              </Col>
              <Col md={12} lg={4} className="mb-2">
                <Select
                  name="qualification"
                  label="Qualification"
                  isSearchable={false}
                  value={filterData.qualification}
                  // defaultValue={roleOptions.find(option => option.value === userData.role)}
                  placeholder="Select"
                  options={qualificationListOptions}
                  onChange={(val) => {
                    setFilterData({ ...filterData, qualification: val });
                  }}
                  className="select"
                  classNamePrefix="select"
                />
              </Col>
              {/* <Col md={12} lg={4} className="mb-2">
                <Input
                  name="noticePeriodEndDate"
                  label="Maximum Notice Period"
                  type="date"
                  placeholder="Maximum Notice Period"
                  value={filterData.noticePeriodEndDate}
                  size="fluid"
                  // value={filterData.noticePeriodEndDate}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      noticePeriodEndDate: e.target.value,
                    })
                  }
                  // disabled={!employemntStatus}
                  // error={errors["noticePeriodEndDate"]}
                />
              </Col> */}
              <Col md={12} lg={4} className="mb-2">
                <DatePicker
                  name="noticePeriodEndDate"
                  label="Maximum Notice Period"
                  value={filterData.noticePeriodEndDate}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      noticePeriodEndDate: e,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={12} lg={3} className="mb-3">
            <h4 className="filter_heading text-center d-none d-md-block">
              FILTER <FaFilter size={20} />
            </h4>
            <div className="d-block d-md-none mb-3"></div>
            <Row>
              <Col md={12} className="mb-2">
                <ButtonView
                  onClick={handleSubmit}
                  title={"Apply Filter"}
                  styles="secondary"
                />
              </Col>
              <Col md={12}>
                <ButtonView
                  onClick={() => {
                    getJobApplicationList({}, false);
                    setFilterData(data);
                    setInterviewScheduleDetail([]);
                    setProfileDetail(null);
                    setRowId("");
                  }}
                  title={"Clear Filter"}
                  styles="danger-outline"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default JobFilter;
