import React from "react";
import { Container, Row } from "react-bootstrap";

import CardContent from "./CardContent";
import { Pagination } from "../../../controls";

const LunchManagementCard = ({
  lunchList,
  onlunchPreference,
  allLunchTypeList,

  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,

  showPagination,
  deleteLunchPreference,
}) => {
  // console.log(lunchList)
  return (
    <div className="lunch_management main__body padding">
      <Container>
        <Row>
          {lunchList.map((lunch) => {
            return (
              <CardContent
                key={lunch._id}
                lunch={lunch}
                onlunchPreference={onlunchPreference}
                allLunchTypeList={allLunchTypeList}
                deleteLunchPreference={deleteLunchPreference}
              />
            );
          })}
        </Row>
        {showPagination && (
          <div className="margin_x mt-2 d-flex justify-content-end">
            <Pagination
              rowsPerPage={rowsPerPage}
              rowCount={rowCount}
              onChangePage={onChangePage}
              currentPage={currentPage}
            />
          </div>
        )}
      </Container>
    </div>
  );
};

export default LunchManagementCard;
