import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  ROLE_PENDING: "ROLE_PENDING",
  ROLE_SUCCESS: "ROLE_SUCCESS",
  ROLE_ERROR: "ROLE_ERROR",

  ROLE_PERMISSION_PENDING: "ROLE_PERMISSION_PENDING",
  ROLE_PERMISSION_SUCCESS: "ROLE_PERMISSION_SUCCESS",
  ROLE_PERMISSION_ERROR: "ROLE_PERMISSION_ERROR",

  ROLEADD_PENDING: "ROLEADD_PENDING", 
  ROLEADD_ERROR: "ROLEADD_ERROR", 
  ROLEADD_SUCCESS: "ROLEADD_SUCCESS",

  ROLEEDIT_PENDING: "ROLEEDIT_PENDING", 
  ROLEEDIT_ERROR: "ROLEEDIT_ERROR", 
  ROLEEDIT_SUCCESS: "ROLEEDIT_SUCCESS",

  ROLEDELETE_PENDING: "ROLEDELETE_PENDING", 
  ROLEDELETE_ERROR: "ROLEDELETE_ERROR", 
  ROLEDELETE_SUCCESS: "ROLEDELETE_SUCCESS",
};

export function removeAssignPermission(roleId, permission) {
  return (dispatch) => {
    const data = {
      roleId: roleId
    }
    console.log(data)
    Api.delete("permission/removeAllPermissionsForRole", data)
    .then(response => {
      console.log(response)
      dispatch(rolePermissionlist())
      if(permission.length === 1) {
        dispatch(assignOnePermission(roleId, permission))
      } else {
        dispatch(assignPermission(roleId, permission))
      }
    })
    .catch(err => {
      console.log(err)
    })
  }
}
export function assignPermission(roleId, permission) {
  return (dispatch) => {
    const data = {
      roleId: roleId,
      permissionIds: permission
    }
    Api.post("permission/assignMany", data)
      .then(response => {
       console.log(response)
       console.log("many")
       dispatch(rolePermissionlist())
       dispatch(
        showAlert({
          isOpen: true,
          title: "Success",
          type: "success",
          msg: "Successfully assigned new role.",
        })
      );
      })
      .catch(err => {
        console.log(err)
      })
  }
}
export function assignOnePermission(roleId, permission) {
  return (dispatch) => {
    const data = {
      roleId: roleId,
      permissionId: permission[0]
    }
    Api.post("permission/assignOne", data)
      .then(response => {
       console.log(response)
       console.log("1")
       dispatch(rolePermissionlist())
       dispatch(
        showAlert({
          isOpen: true,
          title: "Success",
          type: "success",
          msg: "Successfully assigned new role.",
        })
      );
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export function addRole(value, permission) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROLEADD_PENDING,
      payload: {
        roleAddPending: true,
        roleAddSuccess: false,
        roleAddError: null,
      },
    });

    const data = {
      name: value
    }

    Api.post("role/create", data)
      .then(response => {
        if(response.message === "SuccessFully added new role") {
          dispatch({
            type: actionTypes.ROLEADD_SUCCESS,
            payload: {
              roleAddPending: false,
              roleAddSuccess: true,
              roleAddError: null,
  
              role: response.data,
            },
          });
          if(permission.length === 1) {
            dispatch(assignOnePermission(response.data._id, permission))
          } else {
            dispatch(assignPermission(response.data._id, permission))
          }
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new role.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.ROLEADD_ERROR,
          payload: {
            roleAddPending: false,
            roleAddSuccess: true,
            roleAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add role.",
          })
        );
      })
  }
}

export function editRole(value, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROLEEDIT_PENDING,
      payload: {
        roleEditPending: true,
        roleEditSuccess: false,
        roleEditError: null,
      },
    });

    const data = {
      name: value
    }
    // console.log(data)

    Api.put(`role/update/${id}`, data)
      .then(response => {
        if(response.message === "SuccessFully updated role") {
          dispatch({
            type: actionTypes.ROLEEDIT_SUCCESS,
            payload: {
              roleEditPending: false,
              roleEditSuccess: true,
              roleEditError: null,
  
              role: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated role.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.ROLEEDIT_ERROR,
          payload: {
            roleEditPending: false,
            roleEditSuccess: true,
            roleEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update role.",
          })
        );
      })
  }
}

export function deleteRole(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROLEDELETE_PENDING,
      payload: {
        roleDeletePending: true,
        roleDeleteSuccess: false,
        roleDeleteError: null,
      },
    });
    const data = {
      roleId: id
    }
    Api.delete("permission/removeAllPermissionsForRole", data)
    .then(response => {
      console.log(response)
      dispatch(rolePermissionlist())
      Api.delete(`role/delete/${id}`)
      .then(response => {
        if(response.message === "SuccessFully deleted role") {
          dispatch({
            type: actionTypes.ROLEDELETE_SUCCESS,
            payload: {
              roleDeletePending: false,
              roleDeleteSuccess: true,
              roleDeleteError: null,
  
              id: response.data._id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted role.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.ROLEDELETE_ERROR,
          payload: {
            roleDeletePending: false,
            roleDeleteSuccess: true,
            roleDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to delete role.",
          })
        );
      })
    })
    .catch(err => {
      console.log(err)
    })
    
  }
}

export function list() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROLE_PENDING,
      payload: {
        rolePending: true,
        roleSuccess: false,
        roleError: null,
      },
    });

    Api.get("role/list")
      .then(response => {
        if(response.message === "Successfully fetched roles list") {
          dispatch({
            type: actionTypes.ROLE_SUCCESS,
            payload: {
              rolePending: false,
              roleSuccess: true,
              roleError: null,
  
              roleList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.ROLE_SUCCESS,
          payload: {
            rolePending: false,
            roleSuccess: true,
            roleError: null,
          },
        });
      })
      .catch(err => {
        dispatch({
          type: actionTypes.ROLE_ERROR,
          payload: {
            rolePending: false,
            roleSuccess: true,
            roleError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get role list.",
          })
        );
      })
  }
}

export function rolePermissionlist() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ROLE_PERMISSION_PENDING,
      payload: {
        rolepermissionPending: true,
        rolepermissionSuccess: false,
        rolepermissionError: null,
      },
    });

    Api.post("permission/getRolesAndPermissionsList")
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully fetched Roles and Permissions List") {
          dispatch({
            type: actionTypes.ROLE_PERMISSION_SUCCESS,
            payload: {
              rolepermissionPending: false,
              rolepermissionSuccess: true,
              rolepermissionError: null,
  
              rolepermissionList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.ROLE_PERMISSION_SUCCESS,
          payload: {
            rolepermissionPending: false,
            rolepermissionSuccess: true,
            rolepermissionError: null,
          },
        });
      })
      .catch(err => {
        dispatch({
          type: actionTypes.ROLE_PERMISSION_ERROR,
          payload: {
            rolepermissionPending: false,
            rolepermissionSuccess: true,
            rolepermissionError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get rolepermission list.",
          })
        );
      })
  }
}