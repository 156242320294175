import React from "react";

import { DataTable } from "../../../common";
import { ButtonView } from "../../../controls";
import moment from "moment";
import {
  JOB_APPLICAITON_STATUS_IN_PROGRESS,
  INTERVIEW_OUTCOME_SELECTED,
  JOB_APPLICAITON_STATUS_APPLIED,
  INTERVIEW_OUTCOME_REJECTED,
  JOB_APPLICAITON_STATUS_ONHOLD,
  INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND,
  // JOB_APPLICAITON_STATUS_CLOSED,
  JOB_APPLICAITON_STATUS_CANCELLED,
} from "../../../../constants/env";

const ActiveList = ({
  jobApplicationList,
  setProfileDetail,
  rowId,
  setRowId,
  srollToSummary,
}) => {
  // console.log("rowId", rowId);
  const columns = [
    {
      name: "Name",
      center: true,
      grow: 2,
      cell: (row) => (
        <span className="d-flex">
          {row.firstName}
          {row.lastName && (
            <span className="d-block d-md-none ms-1">
              {" "}
              {row.lastName.slice(0, 1)}
            </span>
          )}
          {row.lastName && (
            <span className="d-none d-md-block ms-1"> {row.lastName}</span>
          )}
        </span>
      ),
    },
    {
      name: "Prime Domain",
      center: true,
      grow: 2,
      cell: (row) => (
        <span>
          {row.primaryTechnicalDomains.length
            ? row.primaryTechnicalDomains[0]
            : "-"}
        </span>
      ),
    },
    // {
    //   name: "Applied Desig.",
    //   center: true,
    //   selector: "designationAppliedFor",
    //   width: "140px",
    // },
    {
      name: "Experience",
      center: true,
      grow: 2,
      cell: (row) => (
        <span>
          {(row.experienceInMonths / 12) | 0}.{row.experienceInMonths % 12} yrs
        </span>
      ),
    },
    // {
    //   name: "Qualification",
    //   center: true,
    //   selector: "qualification",
    //   width: "110px",
    // },
    {
      name: "Applied date",
      center: true,
      selector: "createdAt",
      grow: 2,
      cell: (row) => (
        <span>{moment(new Date(row.createdAt)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      selector: "status",
      grow: 2,
      cell: (row) => {
        if (row.status === JOB_APPLICAITON_STATUS_APPLIED) {
          return (
            <span className="badge badge-primary">
              {JOB_APPLICAITON_STATUS_APPLIED}
            </span>
          );
        }
        if (row.status === JOB_APPLICAITON_STATUS_IN_PROGRESS) {
          return (
            <span className="badge badge-warning">
              {JOB_APPLICAITON_STATUS_IN_PROGRESS}
            </span>
          );
        }
        if (row.status === INTERVIEW_OUTCOME_SELECTED) {
          return (
            <span className="badge badge-success">
              {INTERVIEW_OUTCOME_SELECTED}
            </span>
          );
        }
        if (row.status === INTERVIEW_OUTCOME_REJECTED) {
          return (
            <span className="badge badge-danger">
              {INTERVIEW_OUTCOME_REJECTED}
            </span>
          );
        }
        if (row.status === JOB_APPLICAITON_STATUS_ONHOLD) {
          return (
            <span className="badge badge-hold">
              {JOB_APPLICAITON_STATUS_ONHOLD}
            </span>
          );
        }
        if (row.status === JOB_APPLICAITON_STATUS_CANCELLED) {
          return (
            <span className="badge badge-danger">
              {JOB_APPLICAITON_STATUS_CANCELLED}
            </span>
          );
        }
        if (row.status === INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND) {
          return (
            <span className="badge badge-secondary">
              Schedule <br /> Next Round
            </span>
          );
        }
      },
    },
    {
      name: "Detail",
      center: true,
      minWidth: "70px",
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              setRowId(row._id);
              setProfileDetail(row);
              if (srollToSummary.current) {
                srollToSummary.current.scrollIntoView({
                  //  behavior: "smooth",
                });
              }
            }}
            title={"View"}
            size="small"
            styles={row._id === rowId ? "secondary" : "primal"}
          />
        </div>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row._id === rowId,
      style: {
        backgroundColor: "#e8edfa",
      },
    },
  ];
  return (
    <DataTable
      data={jobApplicationList}
      defaultSortAsc={false}
      columns={columns}
      // pagination={true}
      paginationPerPage={5}
      paginationServer={false}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default ActiveList;
