import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { Input, ButtonView, Select } from "../../../controls";
import { AiOutlineClose } from "react-icons/ai";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
const relationOptions = [
  {
    label: "Brother",
    value: "Brother",
  },
  {
    label: "Daughter",
    value: "Daughter",
  },
  {
    label: "Father",
    value: "Father",
  },
  {
    label: "Husband",
    value: "Husband",
  },
  {
    label: "Mother",
    value: "Mother",
  },

  {
    label: "Sister",
    value: "Sister",
  },
  {
    label: "Son",
    value: "Son",
  },
  {
    label: "Wife",
    value: "Wife",
  },
];

const AddFamilyMember = ({
  addFamilyInsurance,
  familyMemberInsuranceList,
  editFamilyMemberInsurance,
  deleteFamilyMemberInsurance,
  familyInsuranceDocumentList,
  downloadFamilyInsuranceCard,
}) => {
  const [familyModal, setFamilyModal] = useState(false);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    relation: "",
  });

  const [showEdit, setShowEdit] = useState(false);
  const [familyMemberInsuranceId, setfamilyMemberInsuranceId] = useState("");

  const [errors, setErrors] = useState({});

  const familyColumns = [
    {
      name: "FirstName",
      selector: "firstName",
      // sortable: true,
      grow: 3,
    },
    {
      name: "LastName",
      selector: "lastName",
      // sortable: true,
      // center: true,
      grow: 3,
    },
    {
      name: "Relation",
      selector: "relation",
      // sortable: true,
      grow: 3,
    },
    {
      name: "Insurance No.",
      selector: "insuranceNumber",
      grow: 4,
      wrap: true,
      cell: row => {
        if (row.insuranceNumber !== "") {
          return row.insuranceNumber;
        } else {
          return <p className="text-center">_</p>;
        }
      },
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: row => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setFamilyModal(true);
                setfamilyMemberInsuranceId(row._id);
                setState({
                  firstName: row.firstName,
                  lastName: row.lastName,
                  relation: { label: row.relation, value: row.relation },
                });
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            title={"Delete"}
            onClick={() => {
              if (window.confirm("Delete family member record?")) {
                deleteFamilyMemberInsurance(row._id);
              }
            }}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
    {
      name: "Insurance Card",
      center: true,
      minWidth: "100px",
      grow: 4,
      cell: row => {
        // console.log(row._id);
        const hasDoc = familyInsuranceDocumentList.find(item => {
          if (row._id === item.familyMemberId) {
            return true;
          } else {
            return false;
          }
        });
        return (
          <div className="d-flex">
            <div className="me-1">
              {hasDoc ? (
                <ButtonView
                  onClick={() => {
                    downloadFamilyInsuranceCard(row._id, "family_insurance_card");
                  }}
                  title={"Download"}
                  size="small"
                  styles="primal"
                />
              ) : (
                <>
                  <span className="badge bg-warning-transparent">Not Available</span>
                </>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    setState({ firstName: "", lastName: "", relation: "" });
    setErrors({});
    setFamilyModal(false);
    setShowEdit(false);
  };

  const handleChange = field => e => {
    let value;

    const isDate = e instanceof Date;
    const isTextField = e.target;
    if (isDate) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }
    setState({ ...state, [field]: value });
  };

  const handleSelectChange = field => e => {
    let value;

    value = e;

    setState({
      ...state,
      [field]: value,
    });
  };

  const handleAddFamilyInsurance = () => {
    var rules = { required: ["firstName", "lastName", "relation"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(state),
    });
    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const data = {
          firstName: state.firstName,
          lastName: state.lastName,
          relation: state.relation.value,
        };

        editFamilyMemberInsurance(data, familyMemberInsuranceId);
      } else {
        const data = {
          firstName: state.firstName,
          lastName: state.lastName,
          relation: state.relation,
        };

        addFamilyInsurance(data);
      }

      handleClose();
    }
  };

  return (
    <div className="familyMember_Container">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-none d-md-block">Family Member Information</div>
            </Card.Title>

            <ButtonView onClick={() => setFamilyModal(true)} title={"Add Member"} size="large" />
          </Card.Header>
          <div className="table_content px-2 family_table pb-3">
            <DataTable
              data={familyMemberInsuranceList}
              columns={familyColumns}
              pagination={true}
              // defaultSortField={"name"}
              paginationPerPage={5}
              paginationServer={false}
            />
          </div>
        </Card.Body>
      </Card>
      <Modal size="lg" show={familyModal} onHide={handleClose} aria-labelledby="add_member">
        <Modal.Header>
          <Modal.Title id="add_member">{showEdit ? "Edit Family Member " : "Add Family Member"}</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="firstName"
                label="First Name"
                value={state.firstName}
                type="text"
                placeholder="First Name"
                onChange={handleChange("firstName")}
                size="fluid"
                error={errors["firstName"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="lastname"
                label="Last Name"
                value={state.lastName}
                type="text"
                placeholder="Last Name"
                onChange={handleChange("lastName")}
                size="fluid"
                error={errors["lastName"]}
                isrequired={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} className="mb-3">
              <Select
                name="relation"
                label="Relation"
                isSearchable={false}
                defaultValue={relationOptions.find(option => option.value === state.relation.value)}
                placeholder="Select Relation"
                options={relationOptions}
                onChange={handleSelectChange("relation")}
                className="select"
                classNamePrefix="select"
                error={errors["relation"]}
                isrequired={true}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col xs={6} md={6} lg={3}>
              {/* <Col md={12} lg={{ span: 6, offset: 3 }} className="mb-3"> */}

              <ButtonView onClick={handleClose} title={"Cancel"} styles="danger-outline" />
            </Col>
            <Col xs={6} md={6} lg={3}>
              <ButtonView onClick={handleAddFamilyInsurance} title={showEdit ? "Save" : "Add"} styles="secondary" />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddFamilyMember;
