import React, { useState } from "react";
// import { MdCall } from "react-icons/md";
import { Modal, Form, Col, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { ButtonView, TextArea } from "../../../controls";
import { DataTable } from "../../../common";
import { LEAVE_STATUS_CANCELLED } from "../../../../constants/env";

const Unapproved = ({
  data,
  updateLeave,
  user,
  showNextYearLeave,
  currentYear,
}) => {
  // console.log("data", moment(new Date()).format("LT"));
  // console.log("user", user);
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [cancelVal, setCancelVal] = useState("");
  const [cancelId, setCancelId] = useState("");
  const [errors, setErrors] = useState({});

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="badge bg-warning-transparent">Pending Approval</span>
      ),
    },
    {
      name: "Type",
      selector: "masterLeaveType",
      center: true,
      grow: 4,
    },
    {
      name: "Approver",
      selector: "",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{`${
          row.reportingManagerDetailsUserId[0].firstName
        } ${row.reportingManagerDetailsUserId[0].lastName.charAt(0)}`}</span>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "160px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                // console.log("row", row);
                handleViewOpen(row);
                // handleViewOpen()
              }}
              title={"View"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => handleCancelOpen(row)}
            title={"Cancel"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleViewOpen = (row) => {
    setViewDetails(row);
    setViewDetailsShow(true);
    // console.log(row);
  };

  const handleCancelOpen = (row) => {
    setCancelId(row._id);
    setCancelOpen(true);
  };

  const handleCancelLeave = () => {
    var rules = {
      required: ["cancelVal"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ cancelVal }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      // console.log("1", LEAVE_STATUS_CANCELLED);
      updateLeave(
        cancelId,
        user,
        cancelVal,
        LEAVE_STATUS_CANCELLED,
        "Successfully cancelled leave.",
        currentYear
      );
      handleClose();
    }
  };

  const handleClose = () => {
    setViewDetailsShow(false);
    setCancelOpen(false);
    setCancelId("");
    setErrors({});
    setCancelVal("");
  };

  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <DataTable
          data={data}
          columns={columns}
          pagination={true}
          paginationPerPage={5}
          paginationServer={false}
        />
      </div>

      <div className="cancel_modal">
        <Modal
          show={cancelOpen}
          onHide={handleClose}
          aria-labelledby="reject_modal"
        >
          <Modal.Header>
            <Modal.Title id="reject_modal">Cancellation Reason</Modal.Title>
            <div onClick={handleClose} style={{ cursor: "pointer" }}>
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <TextArea
                name="comments"
                placeholder="Reason..."
                label=" Kindly provide the reason for cancelling the leave."
                value={cancelVal}
                type="textarea"
                setFocus={1}
                onChange={(e) => setCancelVal(e.target.value)}
                size="fluid"
                error={errors["cancelVal"]}
                isrequired={true}
              />
            </div>
            <Row>
              <Col md={12} lg={6} className="mb-2">
                <ButtonView
                  onClick={handleClose}
                  title={"Cancel"}
                  styles="danger-outline"
                />
              </Col>
              <Col md={12} lg={6}>
                <ButtonView
                  onClick={handleCancelLeave}
                  title={"Submit"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>

      {viewDetails && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Application Detail</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="comments ms-1">
                <h5>
                  Name:{" "}
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.userId[0].firstName}{" "}
                    {viewDetails.userId[0].lastName}
                  </span>
                </h5>
              </div>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  FD/1H/2H
                  <span>{viewDetails.leaveSubType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Leave Type:
                  <span>{viewDetails.masterLeaveType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sub Leave Type:
                  <span>{viewDetails.leaveType.description}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Start Date:
                  <span>
                    {moment(new Date(viewDetails.startDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  End Date:
                  <span>
                    {moment(new Date(viewDetails.endDate)).format("DD/MM/YYYY")}
                  </span>
                </li>
                {viewDetails.comments && (
                  <div className="mt-3">
                    <div className="comments">
                      <h6>Comments:</h6>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={viewDetails.comments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
              </ul>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {/* <div className="unapproved_modal">
        <Modal
          show={applyForLeaveShow}
          onHide={handleClose}
          aria-labelledby="filter_modal"
        >
          <Modal.Header>
            <Modal.Title id="filter_modal">Unapproved</Modal.Title>
            <div onClick={handleClose} style={{cursor: "pointer"}}>
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="comments">
              <h5>Comments</h5>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veniam error facilis, et fugiat deleniti praesentium. Quibusdam modi laborum, non, voluptatum ab quisquam officia dicta aut exercitationem expedita quam veritatis sed.</p>
            </div>
            <ButtonView
              onClick={() => {}}
              title={"Cancel Leave"}
              styles="danger-outline"
            />
            <Button variant="danger">Cancel Leave</Button>
          </Modal.Body>
        </Modal>
      </div> */}
    </>
  );
};

export default Unapproved;
