import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { globals } from "../globals";
import {
  LEAVE_STATUS_PENDING_APPROVAL,
  // LEAVE_STATUS_APPROVED,
  // LEAVE_STATUS_CANCELLED,
} from "../../../src/constants/env";
// import { RiEyeCloseFill } from "react-icons/ri";

export const actionTypes = {
  APPLY_WFH_PENDING: "APPLY_WFH_PENDING",
  APPLY_WFH_SUCCESS: "APPLY_WFH_SUCCESS",
  APPLY_WFH_ERROR: "APPLY_WFH_ERROR",

  USERWFHLIST_PENDING: "USERWFHLIST_PENDING",
  USERWFHLIST_SUCCESS: "USERWFHLIST_SUCCESS",
  USERWFHLIST_ERROR: "USERWFHLIST_ERROR",

  GETUSERWFH_PENDING: "GETUSERWFH_PENDING",
  GETUSERWFH_SUCCESS: "GETUSERWFH_SUCCESS",
  GETUSERWFH_ERROR: "GETUSERWFH_ERROR",

  USERWFHLISTCALENDER_PENDING: "USERWFHLISTCALENDER_PENDING",
  USERWFHLISTCALENDER_SUCCESS: "USERWFHLISTCALENDER_SUCCESS",
  USERWFHLISTCALENDER_ERROR: "USERWFHLISTCALENDER_ERROR",

  UPDATE_WFH_DETAIL_PENDING: "UPDATE_WFH_DETAIL_PENDING",
  UPDATE_WFH_DETAIL_SUCCESS: "UPDATE_WFH_DETAIL_SUCCESS",
  UPDATE_WFH_DETAIL_ERROR: "UPDATE_WFH_DETAIL_ERROR",

  GETUSERWFHANDLEAVE_PENDING: "GETUSERWFHANDLEAVE_PENDING",
  GETUSERWFHANDLEAVE_SUCCESS: "GETUSERWFHANDLEAVE_SUCCESS",
  GETUSERWFHANDLEAVE_ERROR: "GETUSERWFHANDLEAVE_ERROR",
  UPDATE_WFH_GETDATA_PENDING: "UPDATE_WFH_GETDATA_PENDING",

  CANCELGETUSERWFHBYID_PENDING: "CANCELGETUSERWFHBYID_PENDING",
  CANCELGETUSERWFHBYID_SUCCESS: "CANCELGETUSERWFHBYID_SUCCESS",
  CANCELGETUSERWFHBYID_ERROR: "CANCELGETUSERWFHBYID_ERROR",
};

export function applyForWfh(wfhData, file, user, approverName, changingYear) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPLY_WFH_PENDING,
      payload: {
        applyForWfhPending: true,
        applyForWfhSuccess: false,
        applyForWfhError: null,
      },
    });
    dispatch(showLoader());
    //   var date1 = new Date(leaveData.startDate);
    //   var date2 = new Date(leaveData.endDate);
    //   var Difference_In_Time = date2.getTime() - date1.getTime();
    //   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const formdata = new FormData();
    formdata.append("employeeId", user._id);
    formdata.append("leaveSubType", wfhData.leaveSubType);
    //   formdata.append("leaveSubType", leaveData.leaveSubType);
    formdata.append("reportingManagerId", user.reportingManagerEmployeeId);
    formdata.append("startDate", wfhData.startDate);
    formdata.append("endDate", wfhData.endDate);
    // formdata.append("totalDays", `${Difference_In_Days + 1}`);
    // formdata.append("status", "0");
    //   formdata.append("masterLeaveType", leaveData.masterLeaveType);
    // formdata.append("reportingManagerEmailId", approverName.email);
    formdata.append(
      "createdBy",
      `${user.userId[0].firstName} ${user.userId[0].lastName}`
    );
    formdata.append(
      "lastUpdatedBy",
      `${user.userId[0].firstName} ${user.userId[0].lastName}`
    );
    formdata.append("reportingManagerName", approverName.name);

    if (wfhData.comments) {
      formdata.append("comments", wfhData.comments);
    }
    if (file) {
      formdata.append("file", file);
    }
    // console.log("formdata",...formdata)

    Api.post("employeeWorkFromHomeManagement/applyForWFH", formdata)
      .then((response) => {
        // console.log(response);
        dispatch(hideLoader());
        if (response.data) {
          const employeeId = globals.store.getState().auth.loginUser.employeeId;
          const status = parseInt(LEAVE_STATUS_PENDING_APPROVAL);
          const page = parseInt(1);
          const year = changingYear;
          const data = { employeeId, status, year };
          // console.log("emploid", employeeId);
          // dispatch(myTeamEmployeeLeaveList(false, year));
          // const status = 0;
          dispatch(userWfhList(page, data, false));
          //   dispatch(getEmployeeLeavesSummary(false, currentYear));
          //   dispatch(getOptionalHolidayLeaveSummary(false, currentYear));
          //   dispatch(getOtherLeavesSummary(false, currentYear));
          dispatch({
            type: actionTypes.APPLY_WFH_SUCCESS,
            payload: {
              applyForWfhPending: false,
              applyForWfhSuccess: true,
              applyForWfhError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully Uploaded WFH.",
            })
          );
          dispatch(userWfhListCalender({ employeeId, year }, false));
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: response.message,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.APPLY_WFH_ERROR,
          payload: {
            applyForWfhPending: false,
            applyForWfhSuccess: true,
            applyForWfhError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to upload Wfh.",
          })
        );
      });
  };
}

// queryListHasNextPagee: false,
// queryListHasPrevPagee: false,
// queryListLimitt: null,
// queryListNextPagee: null,

// queryListPagingCounterr: null,
// queryListPrevPagee: null,
// queryListTotalDocsData: null,
// queryListTotalPagess: null,

export function userWfhList(page, data, showLoad = true) {
  // console.log("data", data);
  const employeeId = data.employeeId;
  const year = data.year;
  const status = data.status;
  // const setKey = data.setKey;
  const data1 = { employeeId, status, year };

  return (dispatch) => {
    if (showLoad) {
      dispatch({
        type: actionTypes.USERWFHLIST_PENDING,
        payload: {
          userWfhListPending: true,
          userWfhListSuccess: false,
          userWfhListError: null,
        },
      });
    }

    Api.post(
      `employeeWorkFromHomeManagement/getEmployeeWFH?limit=${5}&page=${page}`,

      data1
    )

      .then((response) => {
        // console.log("data3", response.data);

        if (response.data) {
          dispatch({
            type: actionTypes.USERWFHLIST_SUCCESS,
            payload: {
              userWfhListPending: false,
              userWfhListSuccess: true,
              userWfhListError: null,
              // userWfhData:response.data,
              userWfhList: response.data.docs,
              hasNextPage: response.data.hasNextPage,
              hasPrevPage: response.data.hasPrevPage,
              limit: response.data.limit,
              nextPage: response.data.nextPage,
              page: response.data.page,
              pagingCounter: response.data.pagingCounter,
              prevPage: response.data.prevPage,
              totalDocs: response.data.totalDocs,
              totalPages: response.data.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.USERWFHLIST_ERROR,
            payload: {
              userWfhListPending: false,
              userWfhListSuccess: true,
              userWfhListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user wfh list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USERWFHLIST_ERROR,
          payload: {
            userWfhListPending: false,
            userWfhListSuccess: true,
            userWfhListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user Wfh list.",
          })
        );
      });
  };
}

//To get single user

export function getUserWfh(Id, showLoad = true) {
  return (dispatch) => {
    // console.log("Id", Id);
    if (showLoad) {
      dispatch({
        type: actionTypes.GETUSERWFH_PENDING,
        payload: {
          getUserWfhPending: true,
          getUserWfhSuccess: false,
          getUserWfhError: null,
        },
      });
    }

    Api.get(`employeeWorkFromHomeManagement/getWFHByID/${Id}`)

      .then((response) => {
        // console.log("getwfh12", response.data);

        if (response.data) {
          dispatch({
            type: actionTypes.GETUSERWFH_SUCCESS,
            payload: {
              getUserWfhPending: false,
              getUserWfhSuccess: true,
              getUserWfhError: null,
              // userWfhData:response.data,
              getUserWfhData: response.data,
              // hasNextPage: response.data.hasNextPage,
              // hasPrevPage: response.data.hasPrevPage,
              // limit: response.data.limit,
              // nextPage: response.data.nextPage,
              // page: response.data.page,
              // pagingCounter: response.data.pagingCounter,
              // prevPage: response.data.prevPage,
              // totalDocs: response.data.totalDocs,
              // totalPages: response.data.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GETUSERWFH_ERROR,
            payload: {
              getUserWfhPending: false,
              getUserWfhSuccess: true,
              getUserWfhError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user wfh list.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: actionTypes.GETUSERWFH_ERROR,
          payload: {
            getUserWfhPending: false,
            getUserWfhSuccess: true,
            getUserWfhError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user Wfh list.",
          })
        );
      });
  };
}
// To show all applied wfh by an employee on calender
export function userWfhListCalender(data, showLoad = true) {
  return (dispatch) => {
    if (showLoad) {
      dispatch({
        type: actionTypes.USERWFHLISTCALENDER_PENDING,
        payload: {
          userWfhListCalenderPending: true,
          userWfhListCalenderSuccess: false,
          userWfhListCalenderError: null,
        },
      });
    }

    Api.post(
      `employeeWorkFromHomeManagement/getWFHByYear`,

      data
    )

      .then((response) => {
        if (response.data) {
          dispatch({
            type: actionTypes.USERWFHLISTCALENDER_SUCCESS,
            payload: {
              userWfhListCalenderPending: false,
              userWfhListCalenderSuccess: true,
              userWfhListCalenderError: null,
              // userWfhData:response.data,
              userCalender: response.data,
              // hasNextPage: response.data.hasNextPage,
              // hasPrevPage: response.data.hasPrevPage,
              // limit: response.data.limit,
              // nextPage: response.data.nextPage,
              // page: response.data.page,
              // pagingCounter: response.data.pagingCounter,
              // prevPage: response.data.prevPage,
              // totalDocs: response.data.totalDocs,
              // totalPages: response.data.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.USERWFHLISTCALENDER_ERROR,
            payload: {
              userWfhListCalenderPending: false,
              userWfhListCalenderSuccess: true,
              userWfhListCalenderError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user wfh list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USERWFHLISTCALENDER_ERROR,
          payload: {
            userWfhListCalenderPending: false,
            userWfhListCalenderSuccess: true,
            userWfhListCalenderError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user Wfh list.",
          })
        );
      });
  };
}

export function updateWfh(id, user, val, status, message, year, setKey) {
  // console.log("setKey", setKey);
  // console.log("user", user);
  // console.log("val", val);
  // console.log("status", status);
  // console.log("message", message);

  return (dispatch) => {
    var data = {};
    if (val) {
      // console.log("val", val);
      data = {
        lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        rejectionOrCancellationComments: val,
        status: status,
      };
    } else {
      data = {
        lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
        status: status,
      };
    }
    // console.log(message)

    Api.post(`employeeWorkFromHomeManagement/updateWFHStatus/${id}`, data)
      .then((response) => {
        // console.log("setKey", setKey);
        const employeeId = globals.store.getState().auth.loginUser.employeeId;
        // const status = parseInt(LEAVE_STATUS_PENDING_APPROVAL);
        // console.log("status", response.data.status == 1);
        // const status = response.data.status;
        const page = parseInt(1);
        // const data1 = { status, year };

        // console.log("emploid", employeeId);
        // dispatch(myTeamEmployeeLeaveList(false, year));
        // const status = 0;
        if (setKey === 3) {
          // console.log("3", year);
          const status = 0;
          const data1 = { status, year, employeeId };
          dispatch(userWfhList(page, data1, false));
          dispatch(userWfhListCalender({ year, employeeId }, false));
        } else if (setKey === 4) {
          // console.log("4", year);
          const status = 1;
          const data1 = { status, year, employeeId };
          dispatch(userWfhList(page, data1, false));
          dispatch(userWfhListCalender({ year, employeeId }, false));
        } else if (setKey === 0) {
          // console.log("0", year);
          const status = 0;
          const data1 = { status, year };
          dispatch(userWfhList(page, data1, false));
          dispatch(userWfhListCalender({ year }, false));
        } else if (setKey === 1) {
          // console.log("1", year);
          const status = 1;
          const data1 = { status, year };
          dispatch(userWfhList(page, data1, false));
          dispatch(userWfhListCalender({ year }, false));
        } else if (setKey === -1) {
          // console.log("-1", year);
          const status = -1;
          const data1 = { status, year };
          dispatch(userWfhList(page, data1, false));
          dispatch(userWfhListCalender({ year }, false));
        } else {
          // console.log("year", year);
          const data1 = { year };
          dispatch(userWfhList(page, data1, false));
          dispatch(userWfhListCalender(data1, false));
        }
        // dispatch(userWfhList(page, {}, false));
        // const userRole = globals.store.getState().auth.loginUser.userRole;
        // dispatch(userWfhList(page, data1, false));

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            // msg: "Successfully cancelled leave.",
            msg: message,
          })
        );
        // dispatch(userWfhListCalender({ employeeId, year }, false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update wfh.",
          })
        );
      });
  };
}

export function updateWFHDetail(data) {
  console.log("data", data);
  const comments = data.comments;
  const startDate = data.startDate;
  const endDate = data.endDate;
  const leaveSubType = data.leaveSubType;
  const totalDays = data.totalDays;
  const year = data.currentYear;
  const _id = data._id;
  const setKey = data.setKey;
  const data1 = { comments, startDate, endDate, leaveSubType, totalDays, _id };

  // console.log("data99", data.setKey);
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_WFH_DETAIL_PENDING,
      payload: {
        updateWfhDetailPending: true,
        updateWfhDetailSuccess: false,
        updateWfhDetailError: null,
      },
    });
    // console.log("data1", data);
    Api.patch("employeeWorkFromHomeManagement/editEmployeeWFH", {}, data1)
      .then((response) => {
        // console.log("dataresponse", response);
        if (response.data) {
          // const employeeId = globals.store.getState().auth.loginUser.employeeId;

          const status = parseInt(setKey);
          const page = parseInt(1);
          // const year = new Date().getFullYear();
          const data2 = { status, year };
          // console.log("emploid", employeeId);
          // dispatch(myTeamEmployeeLeaveList(false, year));
          // const status = 0;
          dispatch(userWfhList(page, data2, false));
          // dispatch(myTeamEmployeeLeaveList(false, new Date().getFullYear()));
          dispatch({
            type: actionTypes.UPDATE_WFH_DETAIL_SUCCESS,
            payload: {
              updateWfhDetailPending: false,
              updateWfhDetailSuccess: true,
              updateWfhDetailError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "WFH detail updated successfully.",
            })
          );
        } else {
          dispatch({
            type: actionTypes.UPDATE_WFH_DETAIL_ERROR,
            payload: {
              updateWfhDetailPending: false,
              updateWfhDetailSuccess: true,
              updateWfhDetailError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to update WFh details.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_WFH_DETAIL_ERROR,
          payload: {
            updateWfhDetailPending: false,
            updateWfhDetailSuccess: true,
            updateWfhDetailError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update WFH details.",
          })
        );
      });
  };
}

export function getUserWfhAndLeave(page, data, showLoad = true) {
  var searchData = null;
  return (dispatch) => {
    if (showLoad) {
      dispatch({
        type: actionTypes.GETUSERWFHANDLEAVE_PENDING,
        payload: {
          getUserWfhAndLeavePending: true,
          getUserWfhAndLeaveSuccess: false,
          getUserWfhAndLeaveError: null,
        },
      });
    }
    if (data) {
      dispatch(showLoader());
      searchData = {
        startDate: data,
        endDate: data,
      };
    }

    Api.post(
      `employeeWorkFromHomeManagement/viewAllEmployeesOnLeaveOrWFH?limit=${8}&page=${page}`,
      searchData
    )

      .then((response) => {
        if (response.data && response.data[0].docs.length > 0) {
          console.log("response4", response.data, response.data[0].docs);
          const limit = 8;
          dispatch({
            type: actionTypes.GETUSERWFHANDLEAVE_SUCCESS,
            payload: {
              getUserWfhAndLeavePending: false,
              getUserWfhAndLeaveSuccess: true,
              getUserWfhAndLeaveError: null,
              getUserWfhAndLeaveData: response.data[0].docs,
              limit: limit,
              page: response.data[0].pagination[0].currentPage,
              totalDocs: response.data[0].pagination[0].totalDocs,
              totalPages: response.data[0].pagination[0].totalPages,
            },
          });
          dispatch(hideLoader());
        } else {
          dispatch({
            type: actionTypes.GETUSERWFHANDLEAVE_ERROR,
            payload: {
              getUserWfhAndLeavePending: false,
              getUserWfhAndLeaveSuccess: true,
              getUserWfhAndLeaveError: null,
              getUserWfhAndLeaveData: [],
            },
          });
          dispatch(hideLoader());
          // dispatch(
          //   showAlert({
          //     isOpen: true,
          //     title: "Error",
          //     type: "danger",
          //     msg: "Failed to get user wfh list.",
          //   })
          // );
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GETUSERWFHANDLEAVE_ERROR,
          payload: {
            getUserWfhAndLeavePending: false,
            getUserWfhAndLeaveSuccess: true,
            getUserWfhAndLeaveError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user Wfh list.",
          })
        );
      });
  };
}

export function updateGetUserWfhData() {
  // console.log("updateGetUserWfhData", data);
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_WFH_GETDATA_PENDING,
      payload: {
        getUserWfhData: null,
      },
    });
  };
}

export function cancelGetUserWfhBYId(Id, showLoad = true) {
  return (dispatch) => {
    // console.log("Id", Id);
    if (showLoad) {
      dispatch({
        type: actionTypes.CANCELGETUSERWFHBYID_PENDING,
        payload: {
          cancelGetUserWfhByIdPending: true,
          cancelGetUserWfhByIdSuccess: false,
          cancelGetUserWfhByIdError: null,
        },
      });
    }

    Api.get(`employeeWorkFromHomeManagement/getWFHByID/${Id}`)

      .then((response) => {
        // console.log("getwfh12", response.data);

        if (response.data) {
          dispatch({
            type: actionTypes.CANCELGETUSERWFHBYID_SUCCESS,
            payload: {
              cancelGetUserWfhByIdPending: false,
              cancelGetUserWfhByIdSuccess: true,
              cancelGetUserWfhByIdError: null,
              // userWfhData:response.data,
              getUserWfhData: null,
              // hasNextPage: response.data.hasNextPage,
              // hasPrevPage: response.data.hasPrevPage,
              // limit: response.data.limit,
              // nextPage: response.data.nextPage,
              // page: response.data.page,
              // pagingCounter: response.data.pagingCounter,
              // prevPage: response.data.prevPage,
              // totalDocs: response.data.totalDocs,
              // totalPages: response.data.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.CANCELGETUSERWFHBYID_ERROR,
            payload: {
              cancelGetUserWfhByIdPending: false,
              cancelGetUserWfhByIdSuccess: true,
              cancelGetUserWfhByIdError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user wfh list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.CANCELGETUSERWFHBYID_ERROR,
          payload: {
            cancelGetUserWfhByIdPending: false,
            cancelGetUserWfhByIdSuccess: true,
            cancelGetUserWfhByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user Wfh list.",
          })
        );
      });
  };
}
