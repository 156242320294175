import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { ButtonView, Select, Input, DatePicker } from "../../../controls";
import { AiOutlineClose } from "react-icons/ai";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";

const CertifiedOptions = [
  { value: "YES", label: "YES" },
  { value: "NO", label: "NO" },
];

const EditMySkillModal = ({
  editMySkillShow,
  handleClose,
  editData,
  proficiencies,
  categories,
  technologies,
  getTechnologies,
  updateSkills,
  skillId,
  fetchSkillCertificate,
  mySkillCertificates,
  uploadSkillCertificates,
  deleteSkillCertificate,
}) => {
  const [dynamicDisplayUpload, setDynamicDisplayUpload] = useState(false);
  const [proficiencyID, setProficiencyID] = useState("");
  const [certificates, setCertificates] = useState(false);
  const [certificateName, setCertificateName] = useState("");
  const [validDate, setValidDate] = useState("");
  const [file, setFile] = useState(null);
  const [addAnotherCertificate, setAddAnotherCertificate] = useState(false);
  const [deleteSkillCertifiacateModal, setDeleteSkillCertifiacateModal] =
    useState(false);
  const [deleteSkillCertificateID, setDeleteSkillCertificateID] = useState("");

  function compareProficiencyName(a, b) {
    if (a.proficiencyName < b.proficiencyName) {
      return -1;
    }
    if (a.proficiencyName > b.proficiencyName) {
      return 1;
    }
    return 0;
  }

  proficiencies.sort(compareProficiencyName);
  const ProficiencyOptions = proficiencies.map((list) => {
    return {
      value: list.proficiencyName,
      label: list.proficiencyName,
    };
  });

  const checkValueProficiency = (selectedOption) => {
    proficiencies.forEach((item) => {
      if (item.proficiencyName === selectedOption.value) {
        setProficiencyID(item._id);
        console.log(item);
      }
    });
  };

  const [errors, setErrors] = useState({});

  const handleChange = (selectedOption) => {
    if (selectedOption.value === "YES") {
      setDynamicDisplayUpload(true);
    } else {
      setDynamicDisplayUpload(false);
    }

    if (dynamicDisplayUpload === false) {
      setCertificateName("");
      setValidDate("");
      setFile(null);
      setErrors({});
    }
  };

  const updateData = {
    proficiencyId: proficiencyID,
  };

  const fecthCertificateData = {
    skillsId: skillId,
  };

  const handleUploadFiles = () => {
    var rules = {
      required: ["certificateName", "file"],
    };

    const fields = {
      certificateName,
      file,
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(fields),
    });

    if (!certificateName) {
      errors = {
        ...errors,
        certificateName: "Please fill certificate name field",
      };
    }

    if (!file) {
      errors = {
        ...errors,
        file: "Please select a file",
      };
    } else {
      const allowedExtensions = ["pdf", "png", "jpg", "doc", "docx"];

      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        // File extension is valid

        console.log("File uploaded!");
      } else {
        // File extension is invalid

        errors = {
          ...errors,
          file: "Invalid file extension",
        };
      }
    }

    setErrors(errors);

    console.log(errors);

    if (!Object.keys(errors).length) {
      const uploadData = {
        skillsId: skillId,
        certificateName: certificateName,
        validCertificationDate: validDate,
        file: file,
      };
      uploadSkillCertificates(uploadData, fecthCertificateData);
      setAddAnotherCertificate(false);
      setDynamicDisplayUpload(false);
      // setTimeout(() => {
      //   fetchSkillCertificate(fecthCertificateData);
      // }, 800);
      if (certificateName !== "" && file !== null) {
        setCertificateName("");
        setValidDate("");
        setFile(null);
      }
      updateSkills(updateData, skillId);
      handleClose();
    }
  };

  useEffect(() => {
    if (mySkillCertificates.length !== 0) {
      setCertificates(true);
    } else {
      setCertificates(false);
    }
  }, [mySkillCertificates]);

  // console.log(mySkillCertificates);

  useEffect(() => {
    // setDynamicDisplayUpload(false);
    // setAddAnotherCertificate(false);
    setCertificateName("");
    setValidDate("");
    setFile(null);
  }, [updateSkills]);

  useEffect(() => {
    if (validDate === null) {
      setValidDate("");
    }
  }, [validDate]);

  return (
    <>
      {deleteSkillCertifiacateModal ? (
        <Modal show={deleteSkillCertifiacateModal} centered={true} size="sm">
          <Modal.Header className="d-flex justify-content-center align-items-center">
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>Are you sure you want to delete this Certificate?</p>
            <Row>
              <Col lg={6} md={6} sm={6} xs={6}>
                <ButtonView
                  size={"small"}
                  title="Confirm"
                  styles="danger-outline"
                  onClick={() => {
                    // deleteSkill(deleteSkillID);
                    deleteSkillCertificate(
                      deleteSkillCertificateID,
                      fecthCertificateData
                    );
                    setDeleteSkillCertifiacateModal(false);
                    // setTimeout(() => {
                    //   fetchSkillCertificate(fecthCertificateData);
                    // }, 600);
                    setErrors({});
                  }}
                ></ButtonView>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <ButtonView
                  size={"small"}
                  title="Cancel"
                  onClick={() => {
                    setDeleteSkillCertifiacateModal(false);
                    setDeleteSkillCertificateID("");
                    setErrors({});
                  }}
                ></ButtonView>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      ) : null}

      <Modal show={editMySkillShow} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>Edit MySkill</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} lg={12} className="mb-3">
              <Input
                label={"Category"}
                placeholder={editData.categoryName.substring(0, 50)}
                disabled={"disabled"}
              ></Input>
            </Col>

            <Col md={12} lg={12} className="mb-3">
              <Input
                label={"Technology"}
                placeholder={editData.technologyName.substring(0, 50)}
                disabled={"disabled"}
              ></Input>
            </Col>

            <Col md={12} lg={12} className="mb-3">
              <Select
                label="Proficiency"
                options={ProficiencyOptions}
                // placeholder={editData.proficiencyId.proficiencyName}
                defaultValue={ProficiencyOptions.find(
                  (option) =>
                    option.value === editData.proficiencyId.proficiencyName
                )}
                onChange={checkValueProficiency}
              />
            </Col>

            <Col>
              {certificates ? (
                <label className="mt-2 mb-2 d-flex justify-content-center align-item-center">
                  <b style={{ color: "#d87d2e" }}>
                    <u>Certificates</u>
                  </b>
                </label>
              ) : null}
              {certificates
                ? mySkillCertificates.map((item, index) => {
                    // console.log(item.skill.certificateName);
                    return (
                      <Row key={index}>
                        <Col>
                          <h6 style={{ color: "#d87d2e" }}>
                            {item.skill.certificateName
                              .substring(0, 50)
                              .toUpperCase()}
                          </h6>
                        </Col>
                        <Col lg={2} md={3} sm={3} xs={3}>
                          <ButtonView
                            size="small"
                            styles="danger-outline"
                            title="Delete"
                            onClick={() => {
                              // console.log(item.skill._id);
                              // deleteSkillCertificate(item.skill._id);
                              setDeleteSkillCertifiacateModal(true);
                              setDeleteSkillCertificateID(item.skill._id);
                              setAddAnotherCertificate(false);
                              setCertificateName("");
                              setValidDate("");
                              setFile(null);
                            }}
                          ></ButtonView>
                        </Col>
                      </Row>
                    );
                  })
                : null}
            </Col>

            {certificates ? (
              <>
                {addAnotherCertificate ? null : (
                  <>
                    <Col sm={12} xs={12} md={12} lg={12}>
                      <ButtonView
                        title="Upload Another Certificate"
                        onClick={() => {
                          setAddAnotherCertificate(true);
                        }}
                      />
                    </Col>{" "}
                  </>
                )}
              </>
            ) : (
              <Col md={12} lg={12} className="mb-3">
                <Select
                  label="Certified"
                  defaultValue={CertifiedOptions.find(
                    (option) => option.value === "NO"
                  )}
                  value={
                    dynamicDisplayUpload === false
                      ? CertifiedOptions.find((option) => option.value === "NO")
                      : CertifiedOptions.find(
                          (option) => option.value === "YES"
                        )
                  }
                  // placeholder={editData.certificate_count === 0 ? "NO" : "YES"}
                  // placeholder={certificates ? "YES" : "NO"}
                  options={CertifiedOptions}
                  onChange={handleChange}
                />
              </Col>
            )}

            {addAnotherCertificate || dynamicDisplayUpload ? (
              <>
                <Col md={12} lg={12} className="mb-3">
                  <Input
                    label="Certificate Name"
                    placeholder="Enter Certificate Name"
                    maxLength={50}
                    type="text"
                    onChange={(e) => {
                      setCertificateName(e.target.value);
                    }}
                    isrequired={true}
                    error={errors["certificateName"]}
                  ></Input>
                </Col>

                <Col md={12} lg={5} className="mb-3 pb-md-3 pb-sm-3 pb-xs-0">
                  <DatePicker
                    name="Certificate Expiry Date"
                    label="Certificate Expiry Date"
                    value={validDate}
                    onChange={(e) => {
                      setValidDate(e);
                    }}
                  />
                </Col>

                <Col md={12} lg={5} className="mb-3">
                  <Input
                    name="file"
                    label="Upload File (.pdf, .png, .jpg, .doc, .docx)"
                    type="file"
                    placeholder="Upload File"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        setFile(e.target.files[0]);
                      }
                    }}
                    accept=".pdf,.png,.jpg,.doc,.docx"
                    isrequired={true}
                    error={errors["file"]}
                  />
                </Col>

                {addAnotherCertificate || dynamicDisplayUpload ? (
                  <Col
                    lg={2}
                    className="mb-3 d-flex justify-content-center align-items-center pt-3"
                  >
                    <ButtonView
                      title="Discard"
                      onClick={() => {
                        setAddAnotherCertificate(false);
                        setDynamicDisplayUpload(false);
                        setCertificateName("");
                        setValidDate("");
                        setFile(null);
                        setErrors({});
                      }}
                    ></ButtonView>
                  </Col>
                ) : null}
              </>
            ) : null}
          </Row>

          <Row className="d-flex justify-content-end">
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                title={"Cancel"}
                styles="danger-outline"
                onClick={() => {
                  handleClose();
                }}
              />
            </Col>

            <Col xs={6} md={6} lg={3}>
              <ButtonView
                title={"Save"}
                styles="secondary"
                // onClick={() => {
                //   if (
                //     dynamicDisplayUpload === true ||
                //     addAnotherCertificate === true
                //   ) {
                //     if (
                //       certificateName === "" ||
                //       file === null ||
                //       (certificateName === "" && file === null)
                //     ) {
                //       // const updateData = "invalid Data";
                //       // updateSkills(updateData, skillId);
                //       setDynamicDisplayUpload(false);
                //       setAddAnotherCertificate(false);
                //       setCertificateName("");
                //       setValidDate("");
                //       setFile(null);
                //       setErrors({});
                //     }
                //   } else {
                //     updateSkills(updateData, skillId);
                //     handleClose();
                //   }
                // }}
                onClick={() => {
                  if (
                    dynamicDisplayUpload === true ||
                    addAnotherCertificate === true
                  ) {
                    handleUploadFiles();
                  } else {
                    if (proficiencyID !== "") {
                      updateSkills(updateData, skillId);
                      handleClose();
                    } else {
                      if (deleteSkillCertificateID !== "") {
                        updateSkills(updateData, skillId);
                      }
                      handleClose();
                    }
                  }
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditMySkillModal;
