import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "../auth/authReducer";
import alert from "../alert/alertReducer";
import user from "../user/userReducer";
import lunch from "../lunch/lunchReducer";
import lunchType from "../lunchType/lunchTypeReducer";
import location from "../location/locationReducer";
import role from "../role/roleReducer";
import loader from "../loader/loaderReducer";
import sidebar from "../sidebar/sidebarReducer";
import permission from "../permission/permissionReducer";
import level from "../level/levelReducer";
import holidays from "../holidays/holidaysReducer";
import leaveTypes from "../leaveTypes/leaveTypesReducer";
import leavesPerYear from "../leavesPerYear/leavesPerYearReducer";
import employeeLeave from "../employeeLeave/employeeLeaveReducer";
import techDomain from "../technicalDomain/technicalDomainReducer";
import designation from "../Designation/DesignationReducer";
import aplicationDomain from "../aplicationDomain/aplicationDomainReducer";
import jobApplication from "../jobApplication/jobApplicationReducer";
import joinUs from "../joinUs/joinUsReducer";
import leaveReporting from "../leaveReporting/leaveReportingReducer";
import lunchBlockingTimeReducer from "../lunchBlockingTime/lunchBlockingTimeReducer";
import userDocuments from "../documents/documentsReducer";
import familyInsuranceReducer from "../familyInsurance/familyInsuranceReducer";
import employeeWfhReducer from "../employeeWfh/employeeWfhReducer";
import employeeQueryReducer from "../queries/queryReducer";
import applicationManagerReducer from "../application/applicationReducer";
import mySkill from "../mySkills/mySkillsReducer";
import skillManagement from "../skillManagement/skillManagementReducer";
import employeeSkills from "../employeeSkills/employeeSkillsReducer";

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    alert,
    auth,
    user,
    lunch,
    lunchType,
    location,
    role,
    loader,
    sidebar,
    permission,
    level,
    holidays,
    leaveTypes,
    leavesPerYear,
    employeeLeave,
    techDomain,
    designation,
    aplicationDomain,
    jobApplication,
    joinUs,
    leaveReporting,
    lunchBlockingTimeReducer,
    userDocuments,
    familyInsuranceReducer,
    employeeWfhReducer,
    employeeQueryReducer,
    applicationManagerReducer,
    mySkill,
    skillManagement,
    employeeSkills,
  });
