import React from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { Nav, Navbar, Container } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
// import { BiHomeSmile } from "react-icons/bi";

import "./HeaderStyles.scss";
// import { Role } from '../../../helpers/role';
import logo from "../../../assets/images/download.png";
import { CircularButtonView, ButtonView } from "../../controls";
import { showSidebar } from "../../../store/sidebar/sidebarActions";
// import { globals } from "../../../store/globals";

const Header = ({
  label,
  Icon,
  btnData,
  showSidebar,
  isFilterActive = false,
  handleClearFilter,
}) => {
  // var permission = globals.store.getState().auth.loginUser.userPermissions;
  // var level = globals.store.getState().auth.loginUser.level;
  // var userRole = globals.store.getState().auth.loginUser.userRole;
  var showMenu = true;

  // const role = globals.store.getState().auth.loginUser.user_role;
  // console.log("icon", Icon);
  // console.log("btn", btnData);
  return (
    <div className="header fixed-top">
      <Container>
        <Nav className="header_nav" as="ul">
          <Navbar.Brand className="d-block d-md-none">
            <img src={logo} className="img" alt="React Bootstrap logo" />
          </Navbar.Brand>
          <Nav.Item className="header_title d-flex align-items-center" as="li">
            <span className="d-none d-md-block me-1">
              <Icon size={30} />
            </span>
            <h2>{label}</h2>
          </Nav.Item>
          {btnData.length > 0 && (
            <>
              <Nav.Item className="d-flex align-items-center" as="li">
                {btnData.map((btn, index) => {
                  var Icon = btn.icon;
                  return (
                    <CircularButtonView
                      Icon={Icon}
                      onClick={btn.onClick}
                      size={20}
                      tooltip={btn.tooltip}
                      key={`btn-${index}`}
                    />
                  );
                })}
                {isFilterActive && (
                  <div className="ms-2 d-none d-md-block">
                    <ButtonView
                      onClick={handleClearFilter}
                      title={"Clear Filter"}
                    />
                  </div>
                )}
              </Nav.Item>
            </>
          )}
          {showMenu && (
            <Nav.Item
              onClick={showSidebar}
              className="d-flex align-items-center sidenav_icon d-block d-md-none"
              as="li"
            >
              <GiHamburgerMenu size={20} />
            </Nav.Item>
          )}
        </Nav>
      </Container>
    </div>
  );
};

Header.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  btnData: PropTypes.array,
};

Header.defaultProps = {
  btnData: [],
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showSidebar: showSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
