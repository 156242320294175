import React from "react";
import { Row, Col } from "react-bootstrap";

import { ButtonView, Input, Select } from "../../../controls";

const EmployeeDetails = ({
  userData,
  setUserData,
  errors,
  roleOptions,
  locationOptions,
  handleSubmit,
  setShowNext,
  levelOptions,
  userManagerOptions,
}) => {
  return (
    <div>
      <h6>Employee Details:</h6>
      <Row>
        <Col md={12} lg={6} className="mb-3">
          <Input
            nmae="empId"
            label="Employee Id"
            type="tel"
            isNum={true}
            value={userData.empId}
            placeholder="Employee Id"
            onChange={(e) =>
              setUserData({ ...userData, empId: e.target.value })
            }
            size="fluid"
            error={errors["empId"]}
            isrequired={true}
            maxLength={5}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Select
            name="level"
            label="Level"
            isSearchable={false}
            defaultValue={levelOptions.find(
              (option) => option.value === userData.level
            )}
            placeholder="Select Level"
            options={levelOptions}
            onChange={(val) => setUserData({ ...userData, level: val.value })}
            className="select "
            classNamePrefix="select"
            error={errors["level"]}
            isrequired={true}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Select
            name="location"
            label="Location"
            isSearchable={false}
            defaultValue={locationOptions.find(
              (option) => option.value === userData.location
            )}
            placeholder="Select Location"
            options={locationOptions}
            onChange={(val) =>
              setUserData({ ...userData, location: val.value })
            }
            className="select"
            classNamePrefix="select"
            error={errors["location"]}
            isrequired={true}
          />
        </Col>
        {/* <Col md={12} lg={6} className="mb-3">
          <Input
            nmae="managerId"
            label="Manager Id"
            type="number"
            value={userData.managerId}
            placeholder="Manager Id"
            onChange={(e) => setUserData({ ...userData, managerId: e.target.value })}
            size="fluid"
            error={errors["managerId"]}
          />
        </Col> */}
        <Col md={12} lg={6} className="mb-3">
          <Select
            name="managerId"
            label="Manager"
            isSearchable={false}
            defaultValue={userManagerOptions.find(
              (option) => option.value === userData.managerId
            )}
            placeholder="Select Manager"
            options={userManagerOptions}
            onChange={(val) => {
              console.log(val);
              setUserData({ ...userData, managerId: val.value });
            }}
            className="select "
            classNamePrefix="select"
            error={errors["managerId"]}
            isrequired={true}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col xs={6} md={6} lg={3}>
          <ButtonView onClick={() => setShowNext(false)} title={"Previous"} />
        </Col>
        <Col xs={6} md={6} lg={3}>
          <ButtonView
            onClick={handleSubmit}
            title={"Add New User"}
            styles="secondary"
          />
        </Col>
        {/* <Col md={12} lg={{ span: 6, offset: 3 }} className="mb-3">
          <ButtonView
            onClick={() => {
              setUserData(data)
              setErrors({})
            }}
            title={"Reset"}
          />
        </Col> */}
      </Row>
    </div>
  );
};

export default EmployeeDetails;
