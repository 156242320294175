import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import "./EmployeeSkillsStyles.scss";
import EmployeeSkillView from "./EmployeeSKillsView";

import {
  getCategories,
  getTechnologies,
  getProficiencies,
  saveCategoryTechnolgy,
  downloadUserReport,
  downloadTechonlogyReport,
} from "../../../store/skillManagement/skillManagementAction";

import { downloadSkillCertificates } from "../../../store/mySkills/mySkillsActions";

import {
  getUserList,
  getUserSkillWithFilter,
} from "../../../store/employeeSkills/employeeSkillsActions";

import { BigLoader } from "../../common";

export class EmployeeSkillsContainer extends Component {
  constructor(props) {
    super(props);
    this.props.getCategories();
    this.props.getTechnologies();
    this.props.getProficiencies();
    this.props.getUserList();
    // this.props.getUserSkillWithFilter();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      categoryList,
      TechnologyList,
      ProficiencyList,
      getUserSkillWithFilter,
      UserSkillFilteredList,
      userList,
      downloadSkillCertificates,
      saveCategoryTechnolgy,
      downloadUserReport,
      downloadTechonlogyReport,
    } = this.props;

    return (
      <>
        <EmployeeSkillView
          categoryList={categoryList}
          TechnologyList={TechnologyList}
          ProficiencyList={ProficiencyList}
          getUserSkillWithFilter={getUserSkillWithFilter}
          userList={userList}
          UserSkillFilteredList={UserSkillFilteredList}
          downloadSkillCertificates={downloadSkillCertificates}
          saveCategoryTechnolgy={saveCategoryTechnolgy}
          downloadUserReport={downloadUserReport}
          downloadTechonlogyReport={downloadTechonlogyReport}
        />
        <BigLoader />
      </>
    );
  }
}

EmployeeSkillsContainer.propTypes = {};

const mapStateToProps = (state) => ({
  categoryList: state.skillManagement.categoryList,
  TechnologyList: state.skillManagement.TechnologyList,
  ProficiencyList: state.skillManagement.ProficiencyList,
  userList: state.employeeSkills.userList,
  UserSkillFilteredList: state.employeeSkills.UserSkillFilteredList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCategories: getCategories,
      getTechnologies: getTechnologies,
      getProficiencies: getProficiencies,
      getUserList: getUserList,
      getUserSkillWithFilter: getUserSkillWithFilter,
      downloadSkillCertificates: downloadSkillCertificates,
      saveCategoryTechnolgy: saveCategoryTechnolgy,
      downloadUserReport: downloadUserReport,
      downloadTechonlogyReport: downloadTechonlogyReport,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeSkillsContainer);
