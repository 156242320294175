import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";

const ModalView = ({
  getUserWfhData,
  cancelGetUserWfhBYId,
  updateGetUserWfhData,
}) => {
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [value, setValue] = useState(null);

  const handleClose = () => {
    setViewDetailsShow(false);
    setValue(null);
    updateGetUserWfhData();
  };

  useEffect(() => {
    if (getUserWfhData) {
      setValue(getUserWfhData);
      setViewDetailsShow(true);
    } else {
      return setViewDetailsShow(false);
    }
  }, [getUserWfhData]);

  return (
    <>
      {value && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Application Detail</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              {value.status === -1 && (
                <div>
                  <div className="comments ms-1 mb-2">
                    <h5>
                      Rejected by{" "}
                      <span style={{ color: "#d87d2e" }}>
                        {value.lastUpdatedBy}
                      </span>
                    </h5>
                  </div>
                  <div className="comments ms-1">
                    <h6>Reason:</h6>
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      // disabled
                      readOnly
                      value={value.rejectionOrCancellationComments}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </div>
              )}
              {value.status === -2 && (
                <div>
                  <div className="comments">
                    <h5>Cancellation Reason:</h5>
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      // disabled
                      readOnly
                      value={value.rejectionOrCancellationComments}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </div>
              )}
              <div className="comments ms-1">
                <h5>
                  Name:{" "}
                  <span style={{ color: "#d87d2e" }}>
                    {value.userId[0].firstName}
                    {value.userId[0].lastName}
                  </span>
                </h5>
              </div>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  FD/1H/2H
                  <span>{value.leaveSubType}</span>
                </li>

                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Start Date:
                  <span>
                    {moment(new Date(value.startDate)).format("DD/MM/YYYY")}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  End Date:
                  <span>
                    {moment(new Date(value.endDate)).format("DD/MM/YYYY")}
                  </span>
                </li>
                {value.comments && (
                  <div className="mt-3">
                    <div className="comments">
                      <h6>Comments:</h6>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={value.comments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
              </ul>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ModalView;
