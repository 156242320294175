import { actionTypes } from "./employeeSkillsActions";
const {
  GET_USERLIST_PENDING,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_ERROR,

  GET_USER_SKILL_WITH_FILTER_PENDING,
  GET_USER_SKILL_WITH_FILTER_SUCCESS,
  GET_USER_SKILL_WITH_FILTER_ERROR,
} = actionTypes;

const initialState = {
  getUserListPending: false,
  getUserListSuccess: false,
  getUserListError: null,

  getUserSkillWithFilterPending: false,
  getUserSkillWIthFilterSuccess: false,
  getUserSkillWithFilterError: null,

  userList: [],
  UserSkillFilteredList: [],
};

export default function employeeSkills(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USERLIST_PENDING:
    case GET_USERLIST_SUCCESS:
    case GET_USERLIST_ERROR:
      return Object.assign({}, state, payload);

    case GET_USER_SKILL_WITH_FILTER_PENDING:
    case GET_USER_SKILL_WITH_FILTER_SUCCESS:
    case GET_USER_SKILL_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
