import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import "./UserManagementStyles.scss";
import {
  userList as getUserList,
  updateUser,
  deleteUser,
  user as getUser,
  createUser,
  userListFilter,
  uploadInsuranceCard,
  downloadInsuranceCard,
  getDocument,
  removeDocument,
  initDoc,
  reportingMangerUsersList,
  getUserSkillList,
} from "../../../store/user/userActions";
import { list as getlocation } from "../../../store/location/locationActions";
import { list as getRole } from "../../../store/role/roleActions";
import UserManagementView from "./UserManagementView";
import { Loader, BigLoader } from "../../common";
import { list as getLevel } from "../../../store/level/levelActions";

export class UserManagementContainer extends Component {
  state = {
    addUserShow: false,
    filterShow: false,

    data: {
      isActive: "1",
    },
    setFilterPagination: false,
    isFilterActive: false,
  };

  constructor(props) {
    super(props);

    this.props.initDoc();
    this.props.getUserList(1, { isActive: "1" });
    this.props.getUser();
    this.props.getlocation();
    this.props.getRole();
    this.props.getLevel();
    this.props.reportingMangerUsersList();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  handleClose = () => {
    this.setState({
      addUserShow: false,
      filterShow: false,
    });
  };
  handleOpenAddUser = () => {
    this.setState({
      addUserShow: true,
    });
  };
  handleOpenFilter = () => {
    this.setState({
      filterShow: true,
    });
  };
  handleUploadFile = (file, id) => {
    this.props.uploadInsuranceCard(file, id);
  };

  onsetFilterData = (data) => {
    // console.log("data", data);
    this.setState({
      data: data,
      setFilterPagination: true,
    });
  };

  onresetFilterPagination = () => {
    this.setState({
      setFilterPagination: false,
      data: {
        isActive: "1",
      },
    });
  };

  handlePageChange = (page) => {
    // console.log("page", page);
    if (this.state.setFilterPagination) {
      // console.log("data", this.state.data);
      this.props.getUserList(page, this.state.data, false);
    } else {
      // console.log("onchange", page);
      this.props.getUserList(page, { isActive: "1" }, false);
    }
  };

  handleIsFilterActive = (val) => {
    // console.log("val", val);
    this.setState({
      isFilterActive: val,
    });
  };

  handleClearFilter = () => {
    this.props.getUserList(1, { isActive: "1" });
    this.handleIsFilterActive(false);
    this.onresetFilterPagination();
  };

  render() {
    const {
      userListSuccess,
      userListFilter,
      userList,
      createUser,
      locationList,
      roleList,
      updateUser,
      deleteUser,
      userSuccess,
      getDocument,
      userDocument,
      showInsuranceBox,
      removeDocument,
      initDoc,
      downloadInsuranceCard,
      userData,
      getUserList,
      levelList,

      userManager,

      getUserSkillList,
      userSkillList,
    } = this.props;

    if (userListSuccess === false || userSuccess === false) {
      return <Loader />;
    } else {
      return (
        <>
          <UserManagementView
            userList={userList}
            locationList={locationList}
            roleList={roleList}
            updateUser={updateUser}
            deleteUser={deleteUser}
            createUser={createUser}
            userListFilter={userListFilter}
            addUserShow={this.state.addUserShow}
            filterShow={this.state.filterShow}
            handleClose={this.handleClose}
            handleOpenAddUser={this.handleOpenAddUser}
            handleOpenFilter={this.handleOpenFilter}
            handleUploadFile={this.handleUploadFile}
            getDocument={getDocument}
            removeDocument={removeDocument}
            userDocument={userDocument}
            showInsuranceBox={showInsuranceBox}
            initDoc={initDoc}
            downloadInsuranceCard={downloadInsuranceCard}
            rowsPerPage={userData.limit}
            rowCount={userData.totalDocs}
            onChangePage={this.handlePageChange}
            currentPage={userData.page}
            getUserList={getUserList}
            levelList={levelList}
            userData={userData}
            getUserSkillList={getUserSkillList}
            userManager={userManager}
            onsetFilterData={this.onsetFilterData}
            onresetFilterPagination={this.onresetFilterPagination}
            filterData={this.state.data}
            handleIsFilterActive={this.handleIsFilterActive}
            isFilterActive={this.state.isFilterActive}
            handleClearFilter={this.handleClearFilter}
            userSkillList={userSkillList}
          />
          <BigLoader />
        </>
      );
    }
  }
}

UserManagementContainer.propTypes = {
  userList: PropTypes.array,
  locationList: PropTypes.array,
  roleList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  userList: state.user.userList,
  userData: state.user,
  userDocument: state.user.userDocument,
  showInsuranceBox: state.user.showInsuranceBox,
  userListSuccess: state.user.userListSuccess,
  locationList: state.location.locationList,
  roleList: state.role.roleList,
  userSuccess: state.user.userSuccess,
  userManager: state.user.userManager,
  levelList: state.level.levelList,
  userSkillList: state.user.userSkillList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserList: getUserList,
      getUser: getUser,
      updateUser: updateUser,
      deleteUser: deleteUser,
      getlocation: getlocation,
      getRole: getRole,
      createUser: createUser,
      userListFilter: userListFilter,
      uploadInsuranceCard: uploadInsuranceCard,
      getDocument: getDocument,
      removeDocument: removeDocument,
      initDoc: initDoc,
      downloadInsuranceCard: downloadInsuranceCard,
      getLevel: getLevel,
      reportingMangerUsersList: reportingMangerUsersList,

      getUserSkillList: getUserSkillList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementContainer);
