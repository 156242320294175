import React, { useState, useRef, useEffect } from "react";
import { Card } from "react-bootstrap";
import { DataTable } from "../../../common";
import LeaveListDownload from "./LeaveListDownload";
import { ButtonView } from "../../../controls";

import moment from "moment";
import LeaveListDatatable from "./leaveListDatatable";

const DateRangeLeaveList = ({ dateRangeLeaveList, startDate, endDate }) => {
  const [employeeLeaveDetailShow, setEmployeeLeaveDetailShow] = useState(false);
  const [employeeLeaveDetail, setEmployeeLeaveDetail] = useState();
  const newStartDate = moment(startDate).format("DD/MM/YYYY");
  const newEndDate = moment(endDate).format("DD/MM/YYYY");

  const myRef = useRef(null);

  const columns = [
    {
      name: "Employee Id",
      selector: "employeeNumber",
      center: true,
      grow: 3,
    },
    {
      name: "Employee Name",
      center: true,
      grow: 3,
      cell: (row) => (
        <span className="d-flex">
          {row.employeeFirstName}
          {row.employeeLastName && (
            <span className="d-block d-md-none ms-1">
              {row.employeeLastName.slice(0, 1)}
            </span>
          )}
          {row.employeeLastName && (
            <span className="d-none d-md-block ms-1">
              {row.employeeLastName}
            </span>
          )}
        </span>
      ),
    },
    {
      name: "No of Leave Taken",
      selector: "totalApproveLeaveDays",
      center: true,
      grow: 3,
    },
    {
      name: "Optional Holiday Leave",
      selector: "totalOptionalHolidayDays",
      center: true,
      grow: 3,
    },
    {
      name: "Approval Pending",
      selector: "pendingApproval",
      center: true,
      grow: 3,
    },
    {
      name: "Detail",
      center: true,
      // minWidth: "70px",
      grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              handleViewOpen(row);
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
  ];

  const approvedLeavedColumns = [
    {
      name: "Start Date",
      // selector: "startDate",
      center: true,
      grow: 3,

      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      // selector: "endDate",
      center: true,
      grow: 3,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Total Days",
      selector: "totalDays",
      center: true,
      grow: 3,
    },
  ];

  const handleViewOpen = (row) => {
    setEmployeeLeaveDetail(row);
    // setViewDetailsShow(true);
    setEmployeeLeaveDetailShow(true);
    // console.log("row", row);
  };

  useEffect(() => {
    // handleBackClick();
    // console.log("hi", myRef);
    window.scrollTo(0, myRef.current.offsetTop - 75);
    // myRef.current?.scrollIntoView(true);
  }, [employeeLeaveDetail]);

  return (
    <div className="daterange_leavelist">
      <Card className="leave_list mt-3">
        <Card.Header>
          <Card.Title className="mt-1 mt-lg-0">
            {/* {`Leave List  from -${startDate}  to -${endDate}`} */}
            Leave List
            <p className="mt-3 ms-4">
              (from {newStartDate} to {newEndDate})
            </p>
          </Card.Title>
          <LeaveListDownload dateRangeLeaveList={dateRangeLeaveList} />
        </Card.Header>

        <Card.Body>
          <div className="table_content px-2 attendance_table pb-3">
            <DataTable
              data={dateRangeLeaveList}
              columns={columns}
              pagination={true}
              paginationPerPage={5}
              paginationServer={false}
            />
          </div>
        </Card.Body>
      </Card>
      <div ref={myRef} />
      {employeeLeaveDetailShow ? (
        <Card className="leave_list mt-2">
          <Card.Header>
            <Card.Title className=" mt-lg-0 py-2">
              {employeeLeaveDetail.employeeFirstName +
                " " +
                employeeLeaveDetail.employeeLastName}
            </Card.Title>
            <Card.Title className="me-3">
              Employee No: {employeeLeaveDetail.employeeNumber}
            </Card.Title>
          </Card.Header>

          <Card.Body>
            <LeaveListDatatable
              label={"Approved Leave Details"}
              totalLeaveSubtitle={"Approved Leave: "}
              totalLeave={employeeLeaveDetail.totalApproveLeaveDays}
              data={employeeLeaveDetail.approveLeaveDetail}
              columns={approvedLeavedColumns}
              pagination={true}
              paginationPerPage={5}
              paginationServer={false}
            />
            <hr />
            <LeaveListDatatable
              label={"Approved optional Leave"}
              totalLeaveSubtitle={"Optional Leave: "}
              totalLeave={employeeLeaveDetail.totalOptionalHolidayDays}
              data={employeeLeaveDetail.optionalLeaveDetail}
              columns={approvedLeavedColumns}
              pagination={true}
              paginationPerPage={5}
              paginationServer={false}
            />
            <hr />
            <LeaveListDatatable
              label={"Pending Approval Details"}
              totalLeaveSubtitle={"Pending Leave: "}
              totalLeave={employeeLeaveDetail.totalPendingApprovalDays}
              data={employeeLeaveDetail.pendingApprovalDetail}
              columns={approvedLeavedColumns}
              pagination={true}
              paginationPerPage={5}
              paginationServer={false}
            />
          </Card.Body>
        </Card>
      ) : null}
    </div>
  );
};

export default DateRangeLeaveList;
