import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import "./ApplyForWfhStyles.scss";
import ApplyForWFHView from "./ApplyForWFHView";

import queryString from "query-string";
import { Loader, BigLoader } from "../../common";
import {
  user as getUser,
  reportingMangerUsersList,
} from "../../../store/user/userActions";
import { LEAVE_STATUS_PENDING_APPROVAL } from "../../../constants/env";
import { list as getHolidays } from "../../../store/holidays/holidaysActions";
import {
  userWfhList as getUserWfhList,
  getUserWfh,
  applyForWfh,
  userWfhListCalender,
  updateWfh,
  updateGetUserWfhData,
  cancelGetUserWfhBYId,
} from "../../../store/employeeWfh/employeeWfhAction";

import { globals } from "../../../store/globals";

export class ApplyForWFHContainer extends Component {
  wfhId = 0;
  employeeId = globals.store.getState().auth.loginUser.employeeId;

  state = {
    status: 0,
    date: new Date().toISOString().split("T")[0],
    year: new Date().getFullYear(),
    changingYear: new Date().getFullYear(),
    combineCalenderData: [],
    desData: "",
    defaultTabActiveKey: LEAVE_STATUS_PENDING_APPROVAL,
    currentYear: new Date().getFullYear(),
    setKey: "",
  };
  constructor(props) {
    super(props);

    if (window.location.search !== "") {
      const parsed = queryString.parse(window.location.search);
      // console.log("parrsed", parsed);
      this.wfhId = parsed.id;
    }

    this.props.getUser();

    this.props.getHolidays();
    if (this.wfhId !== 0) {
      this.props.getUserWfh(this.wfhId);
    }

    this.props.getUserWfhList(1, {
      setKey: 0,
      status: LEAVE_STATUS_PENDING_APPROVAL,
      year: this.state.changingYear,
      employeeId: this.employeeId,
    });

    this.props.userWfhListCalender({
      employeeId: this.employeeId,
      year: this.state.changingYear,
    });
    this.props.reportingMangerUsersList();
    this.srollToSummary = React.createRef();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  handleSelect = (eventKey) => {
    this.setState({
      setKey: 7,
    });

    if (parseInt(eventKey) === 4) {
      this.props.getUserWfhList(1, {
        setKey: parseInt(eventKey),
        year: this.state.changingYear,
        employeeId: this.employeeId,
      });
    } else {
      this.props.getUserWfhList(
        1,

        {
          setKey: parseInt(eventKey),
          status: parseInt(eventKey),
          year: this.state.changingYear,
          employeeId: this.employeeId,
        }
      );
    }
  };

  onsetCombineCalenderList = (data) => {
    this.setState({
      combineCalenderData: data,
    });
  };

  onsetYear = (data) => {
    console.log("onsetyera", data);
    this.setState({
      changingYear: data,
    });
  };
  onsetDate = (data) => {
    this.setState({
      date: data,
    });
  };
  onsetDescription = (data) => {
    // console.log(data)
    this.setState({
      desData: data,
    });
  };

  onsetDefaultTabActiveKey = (data) => {
    this.setState({
      defaultTabActiveKey: data,
    });
  };

  render() {
    const {
      applyForWfh,
      userWfhList,
      getUserWfhData,
      userManagerList,
      user,
      userSuccess,
      limit,
      totalDocs,
      page,
      getUserWfhList,
      // userManagerSuccess,
      getUserWfh,
      userWfhListSuccess,
      userCalender,
      holidaysList,
      getHolidays,
      // desData,
      userWfhListCalender,
      updateWfh,

      userWfhListCalenderSuccess,

      updateGetUserWfhData,

      cancelGetUserWfhBYId,
      // changingYear,
    } = this.props;
    // console.log("userWfhListCalenderSuccess", userWfhListCalenderSuccess);
    if (
      userSuccess === false ||
      // applyForWfhSuccess === false ||

      userWfhListCalenderSuccess === false
      // updateWfhDetailSuccess === false
    ) {
      return <Loader />;
    } else {
      return (
        <>
          <ApplyForWFHView
            applyForWfh={applyForWfh}
            userManagerList={userManagerList}
            user={user}
            userWfhList={userWfhList}
            rowsPerPage={limit}
            rowCount={totalDocs}
            getUserWfhList={getUserWfhList}
            onChangePage={this.handlePageChange}
            currentPage={page}
            handleSelect={this.handleSelect}
            employeeId={this.employeeId}
            getUserWfh={getUserWfh}
            getUserWfhData={getUserWfhData}
            userCalender={userCalender}
            userWfhListCalender={userWfhListCalender}
            onsetCombineCalenderList={this.onsetCombineCalenderList}
            onsetDate={this.onsetDate}
            onsetYear={this.onsetYear}
            onsetDescription={this.onsetDescription}
            onsetDefaultTabActiveKey={this.onsetDefaultTabActiveKey}
            srollToSummary={this.srollToSummary}
            combineCalenderData={this.state.combineCalenderData}
            holidaysList={holidaysList}
            getHolidays={getHolidays}
            date={this.state.date}
            desData={this.state.desData}
            updateWfh={updateWfh}
            currentYear={this.state.currentYear}
            userWfhListSuccess={userWfhListSuccess}
            updateGetUserWfhData={updateGetUserWfhData}
            // key={this.state.data.key}
            setKey={this.setKey}
            defaultTabActiveKey={this.state.defaultTabActiveKey}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
            wfhId={this.wfhId}
            changingYear={this.state.changingYear}
          />
          <BigLoader />
        </>
      );
    }
  }
}

ApplyForWFHContainer.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  // console.log("state", state.employeeWfhReducer.userWfhListSuccess);
  return {
    user: state.user.user,
    userManagerList: state.user.userManager,
    userWfhList: state.employeeWfhReducer.userWfhList,
    limit: state.employeeWfhReducer.limit,
    totalDocs: state.employeeWfhReducer.totalDocs,
    page: state.employeeWfhReducer.page,
    getUserWfhData: state.employeeWfhReducer.getUserWfhData,
    userCalender: state.employeeWfhReducer.userCalender,
    holidaysList: state.holidays.holidaysList,
    userWfhListSuccess: state.employeeWfhReducer.userWfhListSuccess,
    userSuccess: state.user.userSuccess,
    applyForWfhSuccess: state.employeeWfhReducer.applyForWfhSuccess,
    userManagerSuccess: state.user.userManagerSuccess,
    getUserWfhSuccess: state.employeeWfhReducer.getUserWfhSuccess,
    userWfhListCalenderSuccess:
      state.employeeWfhReducer.userWfhListCalenderSuccess,
    updateWfhDetailSuccess: state.employeeWfhReducer.updateWfhDetailSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: getUser,
      applyForWfh: applyForWfh,
      reportingMangerUsersList: reportingMangerUsersList,
      getUserWfhList: getUserWfhList,
      getUserWfh: getUserWfh,
      userWfhListCalender: userWfhListCalender,
      getHolidays: getHolidays,
      updateWfh: updateWfh,
      updateGetUserWfhData: updateGetUserWfhData,
      cancelGetUserWfhBYId: cancelGetUserWfhBYId,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyForWFHContainer);
