import React from "react";
// import { FaFilter } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import { Header } from "../../common";
import WFHAndLeaveManagementCard from "./components/WFHAndLeaveManagementCard";
import { Input, ButtonView } from "../../controls";
import UserFilter from "./components/UserFilter";
import { Row, Col, Container } from "react-bootstrap";
const WFHAndLeaveView = ({
  getUserWfhAndLeaveData,
  addUserShow,
  filterShow,
  handleClose,
  handleOpenAddUser,
  handleOpenFilter,
  handleUploadFile,
  onresetFilterPagination,
  filterData,
  isFilterActive,
  handleIsFilterActive,
  handleClearFilter,
  rowsPerPage,
  rowCount,
  page,
  getUserWfhAndLeave,
  onsetFilterData,
  onChangePage,
  currentPage,
  data,
  userList,
  searchDate,
  onChange,
  onSubmit,
}) => {
  // console.log("getUserWfhAndLeaveData", getUserWfhAndLeaveData);
  // const btnData = [
  //   {
  //     onClick: handleOpenFilter,
  //     icon: FaFilter,
  //     tooltip: "Filter",
  //   },
  // ];

  return (
    <>
      <Header
        label={"View WFH/Leaves"}
        Icon={IoPeople}
        // btnData={btnData}
        // isFilterActive={isFilterActive}
        // handleClearFilter={handleClearFilter}
      />
      <div className="user_management padding">
        <Container>
          <div className="mt-3">
            <Row className="searchDate">
              <Col md={3} className="searchDateStyle">
                <Input
                  name="startDate"
                  label="Select Date"
                  value={searchDate}
                  type="date"
                  onChange={(e) => onChange(e.target.value)}
                />
              </Col>
              <Col md={1} className="searchDateButton">
                <ButtonView
                  onClick={onSubmit}
                  title={"Search"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
          <WFHAndLeaveManagementCard
            getUserWfhAndLeaveData={getUserWfhAndLeaveData}
            rowCount={rowCount}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            getUserWfhAndLeave={getUserWfhAndLeave}
            onChangePage={onChangePage}
          />

          <UserFilter
            filterShow={filterShow}
            handleClose={handleClose}
            onresetFilterPagination={onresetFilterPagination}
            handleIsFilterActive={handleIsFilterActive}
            handleClearFilter={handleClearFilter}
            onsetFilterData={onsetFilterData}
            isFilterActive={isFilterActive}
            getUserWfhAndLeaveData={getUserWfhAndLeaveData}
            getUserWfhAndLeave={getUserWfhAndLeave}
            userList={userList}
          />
        </Container>
      </div>
    </>
  );
};

export default WFHAndLeaveView;
