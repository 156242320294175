import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal, Row, Col } from "react-bootstrap";
import { Select } from "../../../controls";

import { ButtonView } from "../../../controls";

const UserFilter = ({
  filterShow,
  handleClose,
  locationList,

  handleIsFilterActive,
  handleClearFilter,

  queryStatusOptions,
  fields,
  onchangeStatus,
  errors,
  filterSubmit,
}) => {
  // console.log("fields", fields.status);

  // console.log("statusFilterData", statusFilterData);
  const statusData = {
    status: fields.status,
  };

  // const [filterStatus, setFilterStatus] = useState(statusData.status?.value);

  // console.log("filterStatus", filterStatus);

  // queryStatusOptions.find((option) => {
  //   console.log("option", option.value === filterStatus);
  // });

  const handleReset = () => {
    handleClearFilter();
    handleClose();
  };

  return (
    <div className="filter_modal">
      <Modal
        show={filterShow}
        onHide={handleClose}
        aria-labelledby="filter_modal"
      >
        <Modal.Header>
          <Modal.Title id="filter_modal">Filter</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Select
              name="status"
              label="Status"
              isSearchable={false}
              placeholder="Select"
              options={queryStatusOptions}
              value={fields.status}
              onChange={(val) => {
                onchangeStatus(val);
              }}
              className="select"
              classNamePrefix="select"
              // error={errors["status"]}
              // isrequired={true}
            />
          </div>
          <Row className="d-flex justify-content-end">
            <Col xs={12} md={6} lg={4} className="mb-2">
              <ButtonView
                onClick={handleReset}
                title={"Reset"}
                styles="primal"
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <ButtonView
                onClick={() => filterSubmit(statusData)}
                title={"Apply Filter"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserFilter;
