import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { setLunchPreference } from "../lunchType/lunchTypeActions";
import { user } from "../user/userActions";

export const actionTypes = {
  LUNCH_PENDING: "LUNCH_PENDING",
  LUNCH_SUCCESS: "LUNCH_SUCCESS",
  LUNCH_ERROR: "LUNCH_ERROR",

  LUNCHPREFERENCE_PENDING: "LUNCHPREFERENCE_PENDING",
  LUNCHPREFERENCE_SUCCESS: "LUNCHPREFERENCE_SUCCESS",
  LUNCHPREFERENCE_ERROR: "LUNCHPREFERENCE_ERROR",

  LUNCHCOUNT_PENDING: "LUNCHCOUNT_PENDING",
  LUNCHCOUNT_SUCCESS: "LUNCHCOUNT_SUCCESS",
  LUNCHCOUNT_ERROR: "LUNCHCOUNT_ERROR",

  SET_LUNCHLIST: "SET_LUNCHLIST",
};

export function lunchListFilter(data) {
  return (dispatch) => {
    Api.post("lunch/search", data)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: actionTypes.LUNCH_SUCCESS,
            payload: {
              lunchPending: false,
              lunchSuccess: true,
              lunchError: null,

              lunchList: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.LUNCH_ERROR,
            payload: {
              lunchPending: false,
              lunchSuccess: true,
              lunchError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get lunch list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCH_ERROR,
          payload: {
            lunchPending: false,
            lunchSuccess: true,
            lunchError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get lunch list.",
          })
        );
      });
  };
}

export function lunchCount(userId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCHCOUNT_PENDING,
      payload: {
        lunchCountPending: true,
        lunchCountSuccess: false,
        lunchCountError: null,

        lunchCount: "",
      },
    });

    const data = {
      employeeId: userId,
    };

    Api.post("lunch/count", data)
      .then((response) => {
        var dataString = JSON.stringify(response.data[0]);
        var result = dataString.substring(1, dataString.length - 1);
        dispatch({
          type: actionTypes.LUNCHCOUNT_SUCCESS,
          payload: {
            lunchCountPending: false,
            lunchCountSuccess: true,
            lunchCountError: null,

            lunchCount: result.replace(/,/g, ", "),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCHCOUNT_ERROR,
          payload: {
            lunchCountPending: false,
            lunchCountSuccess: true,
            lunchCountError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "error",
            type: "danger",
            msg: "Failed to get lunch count.",
          })
        );
      });
  };
}

function showLunchError(id) {
  return (dispatch) => {
    Api.get(`lunchPreferenceSettings/list/${id}`).then((response) => {
      // console.log(response);
      dispatch(
        showAlert({
          isOpen: true,
          title: "Error",
          type: "danger",
          msg: `You can't edit lunch setting ${response.data[0].bookingCloseStartTime} - ${response.data[0].bookingCloseEndTime}.`,
        })
      );
    });
  };
}

export function lunchPreference(
  lunchType,
  userId,
  userData,
  setLunchList = false
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCHPREFERENCE_PENDING,
      payload: {
        lunchPreferencePending: true,
        lunchPreferenceSuccess: false,
        lunchPreferenceError: null,
      },
    });
    if (userData) {
      const data = {
        lunchPreference: lunchType._id,
        employeeId: userId,
      };

      var today = new Date();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      console.log(time);
      Api.post("lunchPreferenceSettings/canUpdateLunchPreference", {
        updateTime: time,
        location: userData[0]._id,
      })
        .then((response) => {
          console.log(response);
          if (response.data.canUpdateLunchPreference) {
            Api.post("lunch/create", data)
              .then((response) => {
                // console.log(response)
                dispatch({
                  type: actionTypes.LUNCHPREFERENCE_SUCCESS,
                  payload: {
                    lunchPreferencePending: false,
                    lunchPreferenceSuccess: true,
                    lunchPreferenceError: null,
                  },
                });

                if (setLunchList) {
                  dispatch({
                    type: actionTypes.SET_LUNCHLIST,
                    payload: {
                      lunchType: lunchType,
                      userId: response.data._id,
                    },
                  });
                  dispatch(
                    showAlert({
                      isOpen: true,
                      title: "Success",
                      type: "success",
                      msg: "Lunch data updated successfully.",
                    })
                  );
                } else {
                  dispatch(setLunchPreference(lunchType._id));
                }
              })
              .catch((err) => {
                console.log(err);
                dispatch({
                  type: actionTypes.LUNCHPREFERENCE_ERROR,
                  payload: {
                    lunchPreferencePending: false,
                    lunchPreferenceSuccess: true,
                    lunchPreferenceError: null,
                  },
                });
                dispatch(
                  showAlert({
                    isOpen: true,
                    title: "Error",
                    type: "danger",
                    msg: "Failed to set lunch preference.",
                  })
                );
                dispatch(user());
              });
          } else {
            dispatch(showLunchError(userData[0]._id));
          }
        })
        .catch((err) => {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to set lunch.",
            })
          );
        });
    } else {
      const data = {
        lunchPreference: lunchType._id,
        employeeId: userId,
      };
      Api.post("lunch/create", data)
        .then((response) => {
          // console.log(response)
          dispatch({
            type: actionTypes.LUNCHPREFERENCE_SUCCESS,
            payload: {
              lunchPreferencePending: false,
              lunchPreferenceSuccess: true,
              lunchPreferenceError: null,
            },
          });

          if (setLunchList) {
            dispatch({
              type: actionTypes.SET_LUNCHLIST,
              payload: {
                lunchType: lunchType,
                userId: response.data._id,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Success",
                type: "success",
                msg: "Lunch data updated successfully.",
              })
            );
          } else {
            dispatch(setLunchPreference(lunchType._id));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: actionTypes.LUNCHPREFERENCE_ERROR,
            payload: {
              lunchPreferencePending: false,
              lunchPreferenceSuccess: true,
              lunchPreferenceError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to set lunch preference.",
            })
          );
          dispatch(user());
        });
    }
  };
}

export function list(page) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LUNCH_PENDING,
      payload: {
        lunchPending: true,
        lunchSuccess: false,
        lunchError: null,
      },
    });

    Api.post(`lunch/list?limit=${8}&page=${page}`)
      .then((response) => {
        // console.log(response)
        if (response.data) {
          dispatch({
            type: actionTypes.LUNCH_SUCCESS,
            payload: {
              lunchPending: false,
              lunchSuccess: true,
              lunchError: null,

              lunchList: response.data.docs,
              hasNextPage: response.data.hasNextPage,
              hasPrevPage: response.data.hasPrevPage,
              limit: response.data.limit,
              nextPage: response.data.nextPage,
              page: response.data.page,
              pagingCounter: response.data.pagingCounter,
              prevPage: response.data.prevPage,
              totalDocs: response.data.totalDocs,
              totalPages: response.data.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.LUNCH_ERROR,
            payload: {
              lunchPending: false,
              lunchSuccess: true,
              lunchError: null,

              lunchList: [],
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get lunch list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.LUNCH_ERROR,
          payload: {
            lunchPending: false,
            lunchSuccess: true,
            lunchError: null,

            lunchList: [],
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get lunch list.",
          })
        );
      });
  };
}

export function deleteLunchPreference(empId) {
  return (dispatch) => {
    //  console.log(id)
    const data = {
      employeeId: empId,
    };
    Api.post("lunch/search", data).then((response) => {
      // console.log(response)
      if (response.data.length > 0) {
        Api.delete(`lunch/delete/${response.data[0]._id}`)
          .then((response) => {
            dispatch(
              showAlert({
                isOpen: true,
                title: "Success",
                type: "success",
                msg: "Lunch data updated successfully.",
              })
            );
            dispatch(list(1));
          })
          .catch((err) => console.log(err));
      }
    });
  };
}
