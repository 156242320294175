import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { ButtonView, Input, Select, TextArea } from "../../../controls";
import moment from "moment";
import { LEAVE_STATUS_PENDING_APPROVAL } from "../../../../constants/env";

const ApplyForWfhFormModal = ({
  currentYear,
  userManagerList,
  user,
  applyForWfh,
  userWfhListCalender,
  employeeId,
  getUserWfhList,
  onsetDate,
  onsetYear,
  empData,
  updateGetUserWfhData,
  changingYear,
}) => {
  const data = {
    leaveSubType: "",
    startDate: "",
    endDate: "",
    comments: "",
  };

  const wfhSubTypesListOptions = [
    { value: "Full Day", label: "Full Day" },
    { value: "Half Day - 1st Half", label: "Half Day - 1st Half" },
    { value: "Half Day - 2nd Half", label: "Half Day - 2nd Half" },
  ];

  const yearList = [
    {
      value: new Date().getFullYear() - 1,
      label: `${new Date().getFullYear() - 1}`,
    },
    {
      value: new Date().getFullYear(),
      label: `${new Date().getFullYear()}`,
    },
    {
      value: new Date().getFullYear() + 1,
      label: `${new Date().getFullYear() + 1}`,
    },
  ];

  const approverName = userManagerList
    .map((list) => {
      return {
        name: `${list.userId[0].firstName} ${list.userId[0].lastName}`,
        id: list._id,
        email: list.userId[0].email,
      };
    })
    .find((i) => i.id === user.reportingManagerEmployeeId);

  const [wfhData, setWfhData] = useState(data);
  const [applyForWfhShow, setApplyForWfhShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);

  const handleOpen = () => {
    setApplyForWfhShow(true);
  };

  const handleSubmit = () => {
    var rules = {
      required: ["leaveSubType", "startDate", "endDate", "comments"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(wfhData),
    });
    setErrors(errors);
    // console.log("errors", errors);
    var startDate = new Date(wfhData.startDate);

    if (!Object.keys(errors).length) {
      // get reject start date
      // const rejectStartDate = empData.filter((list) => list.status === -1).map(e => e.startDate.slice(0, 10))

      const getDaysArray = (start, end) => {
        for (
          var arr = [], dt = new Date(start);
          dt <= end;
          dt.setDate(dt.getDate() + 1)
        ) {
          arr.push(new Date(dt));
        }
        return arr;
      };

      // get approved and pending leaves list
      // const approvedAndPendingLeaveList = empData.filter(
      //   (list) => list.status !== -1
      // );

      var dateArr = [];
      empData.forEach((list) => {
        var daylist = getDaysArray(
          new Date(list.startDate),
          new Date(list.endDate)
        );
        // console.log("daylist", applyForWfh);
        dateArr = [
          ...dateArr,
          ...daylist.map((v) => v.toISOString().slice(0, 10)),
        ];
      });

      const newDateArr = [...dateArr];
      // const newDateArr = [...dateArr, ...rejectStartDate]

      if (
        wfhData.startDate &&
        newDateArr.indexOf(wfhData.startDate) === -1 &&
        wfhData.endDate &&
        newDateArr.indexOf(wfhData.endDate) === -1
      ) {
        if (
          new Date(wfhData.startDate).getFullYear() ===
          new Date(wfhData.endDate).getFullYear()
        ) {
          if (new Date(wfhData.endDate) >= startDate) {
            applyForWfh(wfhData, file, user, approverName, changingYear);
            handleClose();
          } else {
            alert("Invalid end date");
          }
        } else {
          alert(
            "Start and end year of the wfh should be same. To apply for next year wfh please choose proper month of the next year."
          );
          handleClose();
        }
      } else {
        alert(
          `Application already applied for any date between ${moment(
            new Date(wfhData.startDate)
          ).format("LL")} - ${moment(new Date(wfhData.endDate)).format("LL")}.`
        );
      }
    }
  };

  const handleClose = () => {
    updateGetUserWfhData();
    setApplyForWfhShow(false);
    setWfhData(data);
    setFile(null);
    setErrors({});
  };

  return (
    <>
      <div className="card-header d-flex border-0 mb-3">
        <h5 className="card-title">My WFH Application(s)</h5>
        <div className="d-flex flex-wrap">
          <div style={{ marginTop: "6px" }} className="me-2">
            <ButtonView
              onClick={handleOpen}
              title={"Apply for WFH"}
              styles="secondary"
            />
          </div>
          <div style={{ width: "150px" }}>
            <Select
              name="year"
              // label="Select Year"
              isSearchable={false}
              defaultValue={yearList.find(
                (list) => changingYear === list.value
              )}
              placeholder="Select"
              options={yearList}
              onChange={(val) => {
                // console.log("val1", val);
                const year = val.value;
                const status = parseInt(LEAVE_STATUS_PENDING_APPROVAL);
                // console.log("val", employeeId, year, status);
                getUserWfhList(1, { employeeId, status, year }, true);
                userWfhListCalender({ employeeId, year }, true);
                onsetYear(val.value);
              }}
              className="select"
              classNamePrefix="select"
            />
          </div>
        </div>
      </div>
      <Modal
        show={applyForWfhShow}
        onHide={handleClose}
        aria-labelledby="filter_modal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title id="filter_modal">WFH Application</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} lg={6} className="mb-3">
              <Select
                name="leaveSubType"
                label="FD/1H/2H"
                isSearchable={false}
                placeholder="Select"
                options={wfhSubTypesListOptions}
                onChange={(val) =>
                  setWfhData({
                    ...wfhData,
                    leaveSubType: val.value,
                    endDate: "",
                    startDate: "",
                  })
                }
                className="select"
                classNamePrefix="select"
                error={errors["leaveSubType"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="approver"
                label="Approver"
                value={approverName?.name ? approverName?.name : ""}
                type="text"
                placeholder="Approver"
                readOnly
                size="fluid"
                // error={errors["lastName"]}
              />
            </Col>

            {/* start and end date */}

            <Col md={12} lg={6} className="mb-3">
              <Input
                name="startDate"
                label="Start Date"
                value={wfhData.startDate}
                type="date"
                onChange={(e) =>
                  setWfhData({
                    ...wfhData,
                    startDate: e.target.value,
                  })
                }
                size="fluid"
                // min={moment(new Date()).format("YYYY-MM-DD")}
                min={new Date().toISOString().split("T")[0]}
                error={errors["startDate"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="endDate"
                label="End Date"
                value={wfhData.endDate}
                type="date"
                onChange={(e) =>
                  setWfhData({
                    ...wfhData,
                    endDate: e.target.value,
                  })
                }
                size="fluid"
                min={wfhData.startDate}
                // min={new Date().toISOString().split("T")[0]}
                error={errors["endDate"]}
                isrequired={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <TextArea
                name="comments"
                label="WFH Reason"
                value={wfhData.comments}
                type="textarea"
                placeholder="Type something..."
                onChange={(e) =>
                  setWfhData({ ...wfhData, comments: e.target.value })
                }
                size="fluid"
                error={errors["comments"]}
                isrequired={true}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                onClick={() => {
                  handleClose();
                }}
                title={"Cancel"}
                styles="danger-outline"
              />
            </Col>
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                onClick={handleSubmit}
                title={"Apply"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplyForWfhFormModal;
