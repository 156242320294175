import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  APPLICATION_DOMAIN_PENDING: "APPLICATION_DOMAIN_PENDING",
  APPLICATION_DOMAIN_SUCCESS: "APPLICATION_DOMAIN_SUCCESS",
  APPLICATION_DOMAIN_ERROR: "APPLICATION_DOMAIN_ERROR",

  APPLICATION_DOMAIN_ADD_PENDING: "APPLICATION_DOMAIN_ADD_PENDING",
  APPLICATION_DOMAIN_ADD_ERROR: "APPLICATION_DOMAIN_ADD_ERROR",
  APPLICATION_DOMAIN_ADD_SUCCESS: "APPLICATION_DOMAIN_ADD_SUCCESS",

  APPLICATION_DOMAIN_EDIT_PENDING: "APPLICATION_DOMAIN_EDIT_PENDING",
  APPLICATION_DOMAIN_EDIT_ERROR: "APPLICATION_DOMAIN_EDIT_ERROR",
  APPLICATION_DOMAIN_EDIT_SUCCESS: "APPLICATION_DOMAIN_EDIT_SUCCESS",

  APPLICATION_DOMAIN_DELETE_PENDING: "APPLICATION_DOMAIN_DELETE_PENDING",
  APPLICATION_DOMAIN_DELETE_ERROR: "APPLICATION_DOMAIN_DELETE_ERROR",
  APPLICATION_DOMAIN_DELETE_SUCCESS: "APPLICATION_DOMAIN_DELETE_SUCCESS",
};

export function addApplicationDomain(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPLICATION_DOMAIN_ADD_PENDING,
      payload: {
        aplicationDomainAddPending: true,
        aplicationDomainAddSuccess: false,
        aplicationDomainAddError: null,
      },
    });
    // console.log(data)

    Api.post("recruitmentManagement/hradmin/addApplicationDomain", data)
      .then((response) => {
        if (response.message === "Successfully added new technical domain") {
          dispatch({
            type: actionTypes.APPLICATION_DOMAIN_ADD_SUCCESS,
            payload: {
              aplicationDomainAddPending: false,
              aplicationDomainAddSuccess: true,
              aplicationDomainAddError: null,

              aplicationDomain: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new aplication domain.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.APPLICATION_DOMAIN_ADD_ERROR,
          payload: {
            aplicationDomainAddPending: false,
            aplicationDomainAddSuccess: true,
            aplicationDomainAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add holiday.",
          })
        );
      });
  };
}

export function editApplicationDomain(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPLICATION_DOMAIN_EDIT_PENDING,
      payload: {
        aplicationDomainEditPending: true,
        aplicationDomainEditSuccess: false,
        aplicationDomainEditError: null,
      },
    });

    Api.put(`recruitmentManagement/hradmin/updateApplicationDomain/${id}`, data)
      .then((response) => {
        if (response.message === "Successfully updated  technical domain") {
          dispatch({
            type: actionTypes.APPLICATION_DOMAIN_EDIT_SUCCESS,
            payload: {
              aplicationDomainEditPending: false,
              aplicationDomainEditSuccess: true,
              aplicationDomainEditError: null,

              aplicationDomain: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated aplication domain.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.APPLICATION_DOMAIN_EDIT_ERROR,
          payload: {
            aplicationDomainEditPending: false,
            aplicationDomainEditSuccess: true,
            aplicationDomainEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit holiday.",
          })
        );
      });
  };
}

export function deleteApplicationDomain(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPLICATION_DOMAIN_DELETE_PENDING,
      payload: {
        aplicationDomainDeletePending: true,
        aplicationDomainDeleteSuccess: false,
        aplicationDomainDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`recruitmentManagement/hradmin/deleteApplicationDomain/${id}`)
      .then((response) => {
        if (response.message === "Successfully deleted  application domain") {
          dispatch({
            type: actionTypes.APPLICATION_DOMAIN_DELETE_SUCCESS,
            payload: {
              aplicationDomainDeletePending: false,
              aplicationDomainDeleteSuccess: true,
              aplicationDomainDeleteError: null,

              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted application domain.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.APPLICATION_DOMAIN_DELETE_ERROR,
          payload: {
            aplicationDomainDeletePending: false,
            aplicationDomainDeleteSuccess: true,
            aplicationDomainDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add holiday.",
          })
        );
      });
  };
}

export function list() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPLICATION_DOMAIN_PENDING,
      payload: {
        aplicationDomainPending: true,
        aplicationDomainSuccess: false,
        aplicationDomainError: null,
      },
    });

    Api.post("recruitmentManagement/hradmin/getApplicationDomainsList")
      .then((response) => {
        if (response.message === "Successfully fetched technical domain list") {
          dispatch({
            type: actionTypes.APPLICATION_DOMAIN_SUCCESS,
            payload: {
              aplicationDomainPending: false,
              aplicationDomainSuccess: true,
              aplicationDomainError: null,

              aplicationDomainList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.APPLICATION_DOMAIN_SUCCESS,
          payload: {
            aplicationDomainPending: false,
            aplicationDomainSuccess: true,
            aplicationDomainError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.APPLICATION_DOMAIN_ERROR,
          payload: {
            aplicationDomainPending: false,
            aplicationDomainSuccess: true,
            aplicationDomainError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get aplicationDomain list.",
          })
        );
      });
  };
}
