import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
// import { globals } from "../globals";

export const actionTypes = {
  APPLY_QUERY_PENDING: "APPLY_QUERY_PENDING",
  APPLY_QUERY_SUCCESS: "APPLY_QUERY_SUCCESS",
  APPLY_QUERY_ERROR: "APPLY_QUERY_ERROR",

  USERQUERYLIST_PENDING: "USERQUERYLIST_PENDING",
  USERQUERYLIST_SUCCESS: "USERQUERYLIST_SUCCESS",
  USERQUERYLIST_ERROR: "USERQUERYLIST_ERROR",

  GETUSERQUERY_PENDING: "GETUSERQUERY_PENDING",
  GETUSERQUERY_SUCCESS: "GETUSERQUERY_SUCCESS",
  GETUSERQUERY_ERROR: "GETUSERQUERY_ERROR",

  MODAL_NULL_GET_QUERY_DATA_PENDING: "MODAL_NULL_GET_QUERY_DATA_PENDING",

  ADD_MESSAGE_PENDING: "ADD_MESSAGE_PENDING",
  ADD_MESSAGE_SUCCESS: "ADD_MESSAGE_SUCCESS",
  ADD_MESSAGE_ERROR: "ADD_MESSAGE_ERROR",
};

export function applyForQuery(queryData, user) {
  // console.log("query", queryData);
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPLY_QUERY_PENDING,
      payload: {
        applyForQueryPending: true,
        applyForQuerySuccess: false,
        applyForQueryError: null,
      },
    });
    dispatch(showLoader());

    Api.post("queryManagement/query/send", queryData)
      .then((response) => {
        // console.log("res", response);
        dispatch(hideLoader());
        if (response.data) {
          dispatch({
            type: actionTypes.APPLY_QUERY_SUCCESS,
            payload: {
              applyForQueryPending: false,
              applyForQuerySuccess: true,
              applyForQueryError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully uploaded Query.",
            })
          );
          dispatch(
            userQueryList({ pageNumber: 1, status: "pending", limit: 7 })
          );
        } else {
          // console.log("hi", response.message);
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: response.message,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.APPLY_QUERY_ERROR,
          payload: {
            applyForQueryPending: false,
            applyForQuerySuccess: true,
            applyForQueryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to upload Query.",
          })
        );
      });
  };
}

export function userQueryList(Info, showLoad = true) {
  // const employeeId = data.employeeId;
  // console.log("info", Info);
  const data = {
    pageNumber: Info.page,
    status: Info.status,
    limit: Info.limit,
    ...Info,
  };
  // const year = data.year;
  // const status = data.status;
  // const setKey = data.setKey;
  // const data1 = { employeeId, status, year };
  // console.log("info", data);
  return (dispatch) => {
    if (showLoad) {
      dispatch({
        type: actionTypes.USERQUERYLIST_PENDING,
        payload: {
          userQueryListPending: true,
          userQueryListSuccess: false,
          userQueryListError: null,
        },
      });
    }

    Api.post(
      `queryManagement/query/list`,

      data
    )

      .then((response) => {
        // console.log("respone", response);

        if (response.data) {
          dispatch({
            type: actionTypes.USERQUERYLIST_SUCCESS,
            payload: {
              userQueryListPending: false,
              userQueryListSuccess: true,
              userQueryListError: null,
              // userWfhData:response.data,
              userQueryList: response.data.docs,
              hasNextPagee: response.data.hasNextPage,
              hasPrevPagee: response.data.hasPrevPage,
              limitt: response.data.limit,
              nextPagee: response.data.nextPage,
              page: response.data.page,
              pagingCounterr: response.data.pagingCounter,
              prevPagee: response.data.prevPage,
              totalDocsData: response.data.totalDocs,

              totalPagess: response.data.totalPages,
            },
          });
          // dispatch(ModalNullGetQueryData());
        } else {
          dispatch({
            type: actionTypes.USERQUERYLIST_ERROR,
            payload: {
              userQueryListPending: false,
              userQueryListSuccess: true,
              userQueryListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user Query list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USERQUERYLIST_ERROR,
          payload: {
            userQueryListPending: false,
            userQueryListSuccess: true,
            userQueryListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user Query list.",
          })
        );
      });
  };
}

export function getUserQuery(Id, key, info, page = 1, showLoad = false) {
  return (dispatch) => {
    // console.log("key", key);
    const data = { pageNumber: page, limit: 50, ...info };
    // console.log("Id", Id);

    if (showLoad) {
      dispatch({
        type: actionTypes.GETUSERQUERY_PENDING,
        payload: {
          getUserQueryPending: true,
          getUserQuerySuccess: false,
          getUserQueryError: null,
        },
      });
    }

    Api.post(`queryManagement/query/message/list/${Id}`, data)

      .then((response) => {
        // console.log("getwfh12", response.data);

        if (response.data) {
          dispatch({
            type: actionTypes.GETUSERQUERY_SUCCESS,
            payload: {
              getUserQueryPending: false,
              getUserQuerySuccess: true,
              getUserQueryError: null,
              // userWfhData:response.data,
              getUserQueryData: response.data.docs,
              viewIdhasNextPage: response.data.hasNextPage,
              viewIdhasPrevPage: response.data.hasPrevPage,
              viewIdlimit: response.data.limit,
              viewIdnextPage: response.data.nextPage,
              viewIdpage: response.data.page,
              viewIdpagingCounter: response.data.pagingCounter,
              viewIdprevPage: response.data.prevPage,
              viewIdtotalDocs: response.data.totalDocs,
              viewIdtotalPages: response.data.totalPages,
            },
          });

          // if (key === 1) {
          //   dispatch(userQueryList({ pageNumber: 1, status: "pending" }));
          // }
        } else {
          dispatch({
            type: actionTypes.GETUSERQUERY_ERROR,
            payload: {
              getUserQueryPending: false,
              getUserQuerySuccess: true,
              getUserQueryError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user Query list.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: actionTypes.GETUSERQUERY_ERROR,
          payload: {
            getUserQueryPending: false,
            getUserQuerySuccess: true,
            getUserQueryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user Query list.",
          })
        );
      });
  };
}

export function ModalNullGetQueryData() {
  // console.log("updateGetUserWfhData", data);
  return (dispatch) => {
    dispatch({
      type: actionTypes.MODAL_NULL_GET_QUERY_DATA_PENDING,
      payload: {
        getUserQueryData: null,
      },
    });
  };
}

export function addMessageToQuery(Id, message, setKey, tabStatus) {
  // console.log("setKey", setKey);
  // console.log("tabStatus", tabStatus);
  return (dispatch) => {
    Api.post(`queryManagement/query/message/add/${Id}`, message)
      .then((response) => {
        // console.log("response", response);
        var arr = [];
        var q_id = response.data.queryId;
        var data = {
          messageIds: arr,
          status: "read",
        };
        if (response.data) {
          arr.push(response.data._id);
        }
        //Querylist
        if (setKey === 1) {
          dispatch(addStatusToMessages(data, q_id));
          // dispatch(userQueryList({ pageNumber: 1, status: "", limit: 12 }));
        } else if (setKey === "pending") {
          // console.log("1");
          if (
            tabStatus === "pending" ||
            tabStatus === "reopened" ||
            tabStatus === "new"
          ) {
            dispatch(
              userQueryList({ pageNumber: 1, status: "pending", limit: 7 })
            );

            dispatch(ModalNullGetQueryData());
          }
        } else if (setKey === "resolved") {
          // console.log("2");
          // if (tabStatus === "resolved") {
          dispatch(
            userQueryList({ pageNumber: 1, status: "resolved", limit: 7 })
          );

          dispatch(ModalNullGetQueryData());
          // }
        } else if (setKey === "") {
          dispatch(userQueryList({ pageNumber: 1, status: "", limit: 7 }));

          dispatch(ModalNullGetQueryData());
        }

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: "Successfully added message to the query.",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update query.",
          })
        );
      });
  };
}

export function addStatusToQuery(Id, status, oldStatus) {
  // console.log("status", status);
  // console.log("oldStatus", oldStatus);

  return (dispatch) => {
    Api.post(`queryManagement/query/status/${Id}`, status)
      .then((response) => {
        // console.log(response);

        if (oldStatus === "resolved") {
          dispatch(
            userQueryList({ pageNumber: 1, status: "resolved", limit: 7 }, true)
          );
          dispatch(ModalNullGetQueryData());
        } else {
          // dispatch(
          //   userQueryList({ pageNumber: 1, status: "", limit: 12 }, true)
          // );
          dispatch(ModalNullGetQueryData());
        }
        // dispatch(
        //   showAlert({
        //     isOpen: true,
        //     title: "Success",
        //     type: "success",
        //     msg: "Successfully updated status to the query.",
        //   })
        // );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update status to the query.",
          })
        );
      });
  };
}

export function addStatusToMessages(messageData, Id, readMessageSetKey) {
  // console.log("readMessageSetKey", readMessageSetKey);
  // console.log("setKey", setKey);

  return (dispatch) => {
    Api.post(`queryManagement/query/message/status`, messageData)
      .then((response) => {
        if (readMessageSetKey === 4) {
          dispatch(
            userQueryList({ pageNumber: 1, status: "", limit: 12 }, true)
          );

          dispatch(getUserQuery(Id));
          dispatch(ModalNullGetQueryData());
        } else {
          dispatch(
            userQueryList({ pageNumber: 1, status: "", limit: 12 }, true)
          );
          dispatch(ModalNullGetQueryData());
        }

        // dispatch(getUserQuery(Id));

        // dispatch(
        //   showAlert({
        //     isOpen: true,
        //     title: "Success",
        //     type: "success",
        //     msg: "Successfully updated status to the Messages.",
        //   })
        // );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update status to the query.",
          })
        );
      });
  };
}

// export function getALJById(id) {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.ALJ_BY_ID_PENDING,
//       payload: {
//         aljByIdPending: true,
//         aljByIdSuccess: false,
//         aljByIdError: null,
//       },
//     });

//     Api.get(`api/alj/${id}`)
//       .then((response) => {
//         // console.log("response ssa district:", response);
//         if (response.data) {
//           dispatch({
//             type: actionTypes.ALJ_BY_ID_SUCCESS,
//             payload: {
//               aljByIdPending: false,
//               aljByIdSuccess: true,
//               aljByIdError: null,

//               aljById: response.data,
//             },
//           });
//         } else {
//           dispatch(
//             showAlert({
//               isOpen: true,
//               title: "Error",
//               type: "danger",
//               msg: response.message,
//             })
//           );

//           globals.history.push(ALJ);

//           dispatch({
//             type: actionTypes.ALJ_BY_ID_SUCCESS,
//             payload: {
//               aljByIdPending: false,
//               aljByIdSuccess: true,
//               aljByIdError: null,
//               aljById: null,
//             },
//           });
//         }
//       })
//       .catch((err) => {
//         console.log(err);

//         dispatch({
//           type: actionTypes.ALJ_BY_ID_ERROR,
//           payload: {
//             aljByIdPending: false,
//             aljByIdSuccess: true,
//             aljByIdError: null,
//           },
//         });
//         globals.history.push(ALJ);
//         dispatch(
//           showAlert({
//             isOpen: true,
//             title: "Error",
//             type: "danger",
//             msg: "ALJ not found",
//           })
//         );
//       });
//   };
// }
