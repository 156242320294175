import React, { useState } from "react";
// import { MdCall } from "react-icons/md";
import { Modal, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";

import { ButtonView } from "../../../controls";
import { DataTable } from "../../../common";

const Cancelled = ({ data, updateLeave, user }) => {
  // console.log(data)
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => {
        return <span className="badge bg-cancel-transparent">Cancelled</span>;
      },
    },
    {
      name: "Type",
      selector: "masterLeaveType",
      center: true,
      grow: 4,
    },
    // {
    //   name: 'FD/1H/2H',
    //   selector: 'leaveSubType',
    //   center: true,
    //   grow: 4,
    // },
    // {
    //   name: 'Applier',
    //   selector: 'userId[0].firstName',
    //   center: true,
    //   grow: 4,
    // },
    {
      name: "Approver",
      selector: "",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{`${
          row.reportingManagerDetailsUserId[0].firstName
        } ${row.reportingManagerDetailsUserId[0].lastName.charAt(0)}`}</span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "70px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              handleViewOpen(row);
              // handleViewOpen()
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
  ];

  const handleViewOpen = (row) => {
    setViewDetails(row);
    setViewDetailsShow(true);
    // console.log(row)
  };

  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <DataTable
          data={data}
          columns={columns}
          pagination={true}
          paginationPerPage={5}
          paginationServer={false}
        />
      </div>
      {viewDetails && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={() => setViewDetailsShow(false)}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Application Detail</Modal.Title>
              <div
                onClick={() => setViewDetailsShow(false)}
                style={{ cursor: "pointer" }}
              >
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              {viewDetails.rejectionOrCancellationComments && (
                <div>
                  <div className="comments">
                    <h5>Cancellation Reason:</h5>
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      // disabled
                      readOnly
                      value={viewDetails.rejectionOrCancellationComments}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </div>
              )}
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  FD/1H/2H
                  <span>{viewDetails.leaveSubType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Leave Type:
                  <span>{viewDetails.masterLeaveType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sub Leave Type:
                  <span>{viewDetails.leaveType.description}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Start Date:
                  <span>
                    {moment(new Date(viewDetails.startDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  End Date:
                  <span>
                    {moment(new Date(viewDetails.endDate)).format("DD/MM/YYYY")}
                  </span>
                </li>
                {viewDetails.comments && (
                  <div className="mt-3">
                    <div className="comments">
                      <h6>Comments:</h6>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={viewDetails.comments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
              </ul>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {/* <div className="unapproved_modal">
        <Modal
          show={applyForLeaveShow}
          onHide={handleClose}
          aria-labelledby="filter_modal"
        >
          <Modal.Header>
            <Modal.Title id="filter_modal">Cancelled</Modal.Title>
            <div onClick={handleClose} style={{cursor: "pointer"}}>
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="comments">
              <h5>Comments</h5>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veniam error facilis, et fugiat deleniti praesentium. Quibusdam modi laborum, non, voluptatum ab quisquam officia dicta aut exercitationem expedita quam veritatis sed.</p>
            </div>
            <ButtonView
              onClick={() => {}}
              title={"Cancel Leave"}
              styles="danger-outline"
            />
            <Button variant="danger">Cancel Leave</Button>
          </Modal.Body>
        </Modal>
      </div> */}
    </>
  );
};

export default Cancelled;
