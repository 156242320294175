import React, { useEffect } from "react";
import { MdSchedule } from "react-icons/md";
import { Card, Col, Row, Container } from "react-bootstrap";
import moment from "moment";

import Calender from "./components/Calender";
import { Header } from "../../common";
import InterviewScheduleApplications from "./components/InterviewScheduleApplications";
import { MONTH_LIMIT } from "../../../service/apiConstants";
import {
  INTERVIEW_STATUS_SCHEDULED,
  INTERVIEW_STATUS_ACCEPTED,
  INTERVIEW_STATUS_COMPLETED,
  HOLIDAY_TYPE_REGULAR,
} from "../../../constants/env";

const InterviewScheduleView = ({
  interviewScheduleList,
  updateInterviewScheduleStatusByInterviewer,
  jobApplicationById,
  user,
  updateJobApplicationStatus,
  downloadJobApplicationDocument,
  holidaysList,

  date,
  desData,
  combineCalenderData,

  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,

  interviewId,
  onsetInterviewId,

  srollToSummary,
  filterInterviewSchedule,
  totalInterviewScheduleList,
  viewAllInterviewSchedule,
}) => {
  // console.log(interviewScheduleList);
  // useEffect for createing calender Data

  useEffect(() => {
    var combineDate = [];
    holidaysList
      .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
      .forEach((i) => {
        combineDate.push({
          des: `${i.description} (Holiday)`,
          interviewDate: i.date,
        });
      });
    onsetCombineCalenderList(combineDate);
    if (totalInterviewScheduleList.length) {
      // const interviewData = interviewScheduleList;
      const interviewData = totalInterviewScheduleList.filter(
        (list) =>
          list.status === INTERVIEW_STATUS_ACCEPTED ||
          list.status === INTERVIEW_STATUS_COMPLETED ||
          list.status === INTERVIEW_STATUS_SCHEDULED
      );
      // console.log(interviewData);

      interviewData.forEach((i) => {
        combineDate.push(i);
      });
      onsetCombineCalenderList(combineDate);
    }
    const data = combineDate.filter((list) => {
      return list.interviewDate.slice(0, 10) === date;
    });
    // console.log(data);
    if (data) {
      onsetDescription(data);
    } else {
      onsetDescription([]);
    }
  }, [
    totalInterviewScheduleList,
    holidaysList,
    date,
    onsetCombineCalenderList,
    onsetDescription,
  ]);

  const Unaccepted = totalInterviewScheduleList.filter(
    (list) => list.status === INTERVIEW_STATUS_SCHEDULED
  );
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  const Pending = totalInterviewScheduleList.filter(
    (list) =>
      list.status === INTERVIEW_STATUS_ACCEPTED &&
      new Date(list.interviewDate) < now
  );

  const Accepted = totalInterviewScheduleList.filter(
    (list) =>
      list.status === INTERVIEW_STATUS_ACCEPTED &&
      new Date(list.interviewDate) > now
  );
  return (
    <>
      <Header label={"Interview Schedule"} Icon={MdSchedule} />
      <div className="main__body padding">
        <Container>
          <Row>
            <Col lg={12} xl={4} className="col-xxl-4">
              <div className="mt-3">
                <Calender
                  holidaysList={holidaysList}
                  // accepted={interviewScheduleList}
                  accepted={totalInterviewScheduleList.filter(
                    (list) =>
                      list.status === INTERVIEW_STATUS_ACCEPTED ||
                      list.status === INTERVIEW_STATUS_COMPLETED ||
                      list.status === INTERVIEW_STATUS_SCHEDULED
                  )}
                  onsetDate={onsetDate}
                  onsetDescription={onsetDescription}
                  combineCalenderData={combineCalenderData}
                  srollToSummary={srollToSummary}
                  filterInterviewSchedule={filterInterviewSchedule}
                />
              </div>
            </Col>
            <Col lg={12} xl={8} className="col-xxl-8">
              <div className="column_divider mt-3">
                <div className="symbols">
                  <Card>
                    <Card.Body>
                      <Row className="calender_symbols">
                        <Col
                          md={6}
                          lg={3}
                          ref={srollToSummary}
                          className="d-block d-md-none"
                        ></Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center"
                        >
                          <span className="dot_label dot_label--maroon me-2 my-auto"></span>
                          <span style={{ fontSize: "14px" }}>Holiday</span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center"
                        >
                          <span className="dot_label dot_label--blue me-2 my-auto"></span>
                          <span style={{ fontSize: "14px" }}>Interview(s)</span>
                        </Col>

                        <Col md={6} lg={3}></Col>
                        <Col md={6} lg={3}></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <div className="leave_overview">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={12} lg={5}>
                          <h6>
                            <strong>
                              Showing last {MONTH_LIMIT} months data.
                            </strong>
                          </h6>
                          <div className="d-flex flex-column justify-content-center">
                            <p className="mb-1 d-flex justify-content-between">
                              <span>Total Unaccepted Invites</span>
                              <span>
                                :{" "}
                                {Unaccepted.length > 9
                                  ? Unaccepted.length
                                  : `0${Unaccepted.length}`}
                              </span>
                            </p>
                            <p className="mb-1 d-flex justify-content-between">
                              <span>Total Accepted Interviews</span>
                              <span>
                                :{" "}
                                {Accepted.length > 9
                                  ? `${Accepted.length}`
                                  : `0${Accepted.length}`}
                              </span>
                            </p>
                            <p className="mb-1 d-flex justify-content-between">
                              <span>Total Pending Reviews</span>
                              <span>
                                :{" "}
                                {Pending.length > 9
                                  ? `${Pending.length}`
                                  : `0${Pending.length}`}
                              </span>
                            </p>
                          </div>
                        </Col>
                        <Col className="border_divider" md={12} lg={7}>
                          <Card
                            className="today_date"
                            style={{ background: "#242034", color: "#fff" }}
                          >
                            <Card.Body className="text-center py-2">
                              {moment(date).format("MMMM Do YYYY")},{" "}
                              {moment(date).format("dddd")}
                            </Card.Body>
                          </Card>
                          <div className="interview_schedule_description">
                            {desData.length > 0 && desData ? (
                              <div
                                style={{ fontSize: "18px", color: "#242034" }}
                                className="mt-3 mb-3"
                              >
                                {desData.map((list, i) => {
                                  return (
                                    <div key={i}>
                                      {list.applicationId ? (
                                        <div
                                          key={list}
                                          className="description_detail d-flex ms-2 mt-2"
                                        >
                                          <div className="num me-2">
                                            <strong> {i + 1}.</strong>
                                          </div>
                                          <div className="description_detail_data">
                                            <div className="data_detail">
                                              <strong>Candidate:</strong>{" "}
                                              {list.applicationId.firstName}{" "}
                                              {list.applicationId.lastName} (
                                              <span className="text-primary">
                                                {list.status}
                                              </span>
                                              )
                                            </div>
                                            <div className="data_detail">
                                              <strong>Interviewer(s):</strong>{" "}
                                              {list.interviewers.map(
                                                (list, id, array) => {
                                                  if (id === array.length - 1) {
                                                    return (
                                                      <span key={list._id}>
                                                        {
                                                          list.userId[0]
                                                            .firstName
                                                        }{" "}
                                                        {
                                                          list.userId[0]
                                                            .lastName
                                                        }{" "}
                                                      </span>
                                                    );
                                                  } else {
                                                    return (
                                                      <span key={list._id}>
                                                        {
                                                          list.userId[0]
                                                            .firstName
                                                        }{" "}
                                                        {
                                                          list.userId[0]
                                                            .lastName
                                                        }
                                                        ,{" "}
                                                      </span>
                                                    );
                                                  }
                                                }
                                              )}
                                            </div>
                                            <div className="data_detail">
                                              <strong>Time:</strong>{" "}
                                              {((parseInt(list.startTime) +
                                                11) %
                                                12) +
                                                1}
                                              :{list.startTime.split(":")[1]}
                                              {parseInt(list.startTime) >= 12
                                                ? "PM"
                                                : "AM"}{" "}
                                              to{" "}
                                              {((parseInt(list.endTime) + 11) %
                                                12) +
                                                1}
                                              :{list.endTime.split(":")[1]}{" "}
                                              {parseInt(list.endTime) >= 12
                                                ? "PM"
                                                : "AM"}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: "18px",
                                            color: "#d87d2e",
                                          }}
                                          className="mt-3 mb-3 text-center"
                                        >
                                          {list.des}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="mt-3 mb-3 text-center text-muted">
                                No data available on this day
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <div className="my_leave_applications">
                  <InterviewScheduleApplications
                    interviewScheduleList={interviewScheduleList}
                    totalInterviewScheduleList={totalInterviewScheduleList}
                    updateInterviewScheduleStatusByInterviewer={
                      updateInterviewScheduleStatusByInterviewer
                    }
                    jobApplicationById={jobApplicationById}
                    user={user}
                    updateJobApplicationStatus={updateJobApplicationStatus}
                    downloadJobApplicationDocument={
                      downloadJobApplicationDocument
                    }
                    interviewId={interviewId}
                    onsetInterviewId={onsetInterviewId}
                    viewAllInterviewSchedule={viewAllInterviewSchedule}
                    onsetDate={onsetDate}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default InterviewScheduleView;
