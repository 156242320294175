import React, { Component } from "react";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import queryString from "query-string";

import {
  interviewScheduleList as getInterviewScheduleList,
  updateInterviewScheduleStatusByInterviewer,
  jobApplicationById,
  updateJobApplicationStatus,
  downloadJobApplicationDocument,
  filterInterviewSchedule,
  viewAllInterviewSchedule,
} from "../../../store/jobApplication/jobApplicationActions";
import { user as getUser } from "../../../store/user/userActions";
import { list as getHolidays } from "../../../store/holidays/holidaysActions";
import "./InterviewScheduleStyles.scss";
import InterviewScheduleView from "./InterviewScheduleView";
import { Loader } from "../../common";

export class InterviewScheduleContainer extends Component {
  interviewId = 0;
  state = {
    date: new Date().toISOString().split("T")[0],
    combineCalenderData: [],
    desData: [],
  };

  constructor(props) {
    super(props);
    if (window.location.search !== "") {
      const parsed = queryString.parse(window.location.search);
      this.interviewId = parsed.id;
    }

    this.props.getUser();
    this.props.getInterviewScheduleList();
    this.props.getHolidays();

    this.srollToSummary = React.createRef();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onsetCombineCalenderList = (data) => {
    this.setState({
      combineCalenderData: data,
    });
  };
  onsetDate = (data) => {
    this.setState({
      date: data,
    });
  };
  onsetDescription = (data) => {
    this.setState({
      desData: data,
    });
  };

  onsetInterviewId = (data) => {
    this.interviewId = data;
  };

  render() {
    const {
      user,
      userSuccess,

      interviewScheduleListSuccess,
      interviewScheduleList,
      updateInterviewScheduleStatusByInterviewer,
      jobApplicationById,
      updateJobApplicationStatus,
      downloadJobApplicationDocument,

      holidaysSuccess,
      holidaysList,

      filterInterviewSchedule,
      totalInterviewScheduleList,
      viewAllInterviewSchedule,
    } = this.props;
    // console.log(interviewScheduleList)
    if (
      userSuccess === false ||
      holidaysSuccess === false ||
      interviewScheduleListSuccess === false
    ) {
      return <Loader />;
    } else {
      return (
        <InterviewScheduleView
          user={user}
          interviewScheduleList={interviewScheduleList}
          updateInterviewScheduleStatusByInterviewer={
            updateInterviewScheduleStatusByInterviewer
          }
          jobApplicationById={jobApplicationById}
          updateJobApplicationStatus={updateJobApplicationStatus}
          downloadJobApplicationDocument={downloadJobApplicationDocument}
          holidaysList={holidaysList}
          onsetCombineCalenderList={this.onsetCombineCalenderList}
          onsetDate={this.onsetDate}
          onsetDescription={this.onsetDescription}
          date={this.state.date}
          desData={this.state.desData}
          combineCalenderData={this.state.combineCalenderData}
          interviewId={this.interviewId}
          onsetInterviewId={this.onsetInterviewId}
          srollToSummary={this.srollToSummary}
          filterInterviewSchedule={filterInterviewSchedule}
          totalInterviewScheduleList={totalInterviewScheduleList}
          viewAllInterviewSchedule={viewAllInterviewSchedule}
        />
      );
    }
  }
}

InterviewScheduleContainer.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userSuccess: state.user.userSuccess,
  interviewScheduleList: state.jobApplication.interviewScheduleList,
  totalInterviewScheduleList: state.jobApplication.totalInterviewScheduleList,
  interviewScheduleListSuccess:
    state.jobApplication.interviewScheduleListSuccess,

  holidaysSuccess: state.holidays.holidaysSuccess,
  holidaysList: state.holidays.holidaysList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInterviewScheduleList: getInterviewScheduleList,
      updateInterviewScheduleStatusByInterviewer:
        updateInterviewScheduleStatusByInterviewer,
      jobApplicationById: jobApplicationById,
      getUser: getUser,
      updateJobApplicationStatus: updateJobApplicationStatus,
      downloadJobApplicationDocument: downloadJobApplicationDocument,

      getHolidays: getHolidays,
      filterInterviewSchedule: filterInterviewSchedule,
      viewAllInterviewSchedule: viewAllInterviewSchedule,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewScheduleContainer);
