import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import SkillManagementView from "./SkillManagementView";
import "./SkillManagementStyles.scss";

import {
  getCategories,
  addSkillsCategory,
  updateSkillsCategories,
  deleteSkillCategory,
  getProficiencies,
  addProficiency,
  updateProficiency,
  deleteProficiency,
  getTechnologies,
  addSkillsTechnology,
  saveCategoryTechnolgy,
  updateSkillsTechnology,
  deleteSkillsTechnology,
  DeleteMappedCategoryTechnolgy,
} from "../../../store/skillManagement/skillManagementAction";

import { getTechnologiesForCategory } from "../../../store/mySkills/mySkillsActions";

import { BigLoader } from "../../common";

export class SkillManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.props.getCategories();
    this.props.getProficiencies();
    this.props.getTechnologies();
    this.props.getTechnologiesForCategory({ showAllMapping: true });
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      categoryList,
      addSkillsCategory,
      updateSkillsCategories,
      deleteSkillCategory,

      ProficiencyList,
      addProficiency,
      updateProficiency,
      deleteProficiency,

      TechnologyList,
      addSkillsTechnology,
      saveCategoryTechnolgy,
      updateSkillsTechnology,
      deleteSkillsTechnology,
      DeleteMappedCategoryTechnolgy,

      getTechnologiesForCategory,
      technologies,
    } = this.props;

    // console.log(TechnologyList);

    return (
      <div>
        <SkillManagementView
          categoryList={categoryList}
          addSkillsCategory={addSkillsCategory}
          updateSkillsCategories={updateSkillsCategories}
          deleteSkillCategory={deleteSkillCategory}
          ProficiencyList={ProficiencyList}
          addProficiency={addProficiency}
          updateProficiency={updateProficiency}
          deleteProficiency={deleteProficiency}
          TechnologyList={TechnologyList}
          addSkillsTechnology={addSkillsTechnology}
          saveCategoryTechnolgy={saveCategoryTechnolgy}
          updateSkillsTechnology={updateSkillsTechnology}
          deleteSkillsTechnology={deleteSkillsTechnology}
          getTechnologiesForCategory={getTechnologiesForCategory}
          technologies={technologies}
          DeleteMappedCategoryTechnolgy={DeleteMappedCategoryTechnolgy}
        />
        <BigLoader />
      </div>
    );
  }
}

SkillManagementContainer.propTypes = {};

const mapStateToProps = (state) => ({
  categoryList: state.skillManagement.categoryList,
  ProficiencyList: state.skillManagement.ProficiencyList,
  TechnologyList: state.skillManagement.TechnologyList,
  technologies: state.mySkill.technologies,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCategories: getCategories,
      addSkillsCategory: addSkillsCategory,
      updateSkillsCategories: updateSkillsCategories,
      deleteSkillCategory: deleteSkillCategory,
      getProficiencies: getProficiencies,
      addProficiency: addProficiency,
      updateProficiency: updateProficiency,
      deleteProficiency: deleteProficiency,
      getTechnologies: getTechnologies,
      addSkillsTechnology: addSkillsTechnology,
      saveCategoryTechnolgy: saveCategoryTechnolgy,
      updateSkillsTechnology: updateSkillsTechnology,
      deleteSkillsTechnology: deleteSkillsTechnology,
      getTechnologiesForCategory: getTechnologiesForCategory,
      DeleteMappedCategoryTechnolgy: DeleteMappedCategoryTechnolgy,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillManagementContainer);
