import React from "react";
import Recaptcha from "react-recaptcha";

import UploadImg from "../../../assets/images/upload_2.png";

const JoinUsView = ({
  fileVal,
  setFileVal,
  formData,
  setFormData,
  handleSubmit,
  errors,
  handleKeyPress,
  verifyCallback,
  notificationMessage,
  notificationType,
  callback,
}) => {
  return (
    <div className="join_us_embedded_form">
      {/* <h2 className="heading">Submit Resume</h2> */}
      <div className="form_main">
        <form>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="form_field">
                <input
                  value={formData.firstName}
                  onChange={(e) => setFormData(e)}
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  className={`${errors["firstName"] ? "show_error_line" : ""}`}
                />
                {errors["firstName"] && (
                  <span className="error_msg">{errors["firstName"]}</span>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="form_field">
                <input
                  value={formData.lastName}
                  onChange={(e) => setFormData(e)}
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  className={`${errors["lastName"] ? "show_error_line" : ""}`}
                />
                {errors["lastName"] && (
                  <span className="error_msg">{errors["lastName"]}</span>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="form_field">
                <input
                  value={formData.qualification}
                  onChange={(e) => setFormData(e)}
                  name="qualification"
                  type="text"
                  placeholder="Qualification"
                  className={`${
                    errors["qualification"] ? "show_error_line" : ""
                  }`}
                />
                {errors["qualification"] && (
                  <span className="error_msg">{errors["qualification"]}</span>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="form_field">
                <input
                  value={formData.phone}
                  onChange={(e) => setFormData(e)}
                  name="phone"
                  type="tel"
                  placeholder="Phone"
                  maxLength="18"
                  minLength="10"
                  onKeyPress={handleKeyPress}
                  className={`${errors["phone"] ? "show_error_line" : ""}`}
                />
                {errors["phone"] && (
                  <span className="error_msg">{errors["phone"]}</span>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form_field">
                <input
                  value={formData.email}
                  onChange={(e) => setFormData(e)}
                  name="email"
                  type="email"
                  placeholder="Email"
                  className={`${errors["email"] ? "show_error_line" : ""}`}
                />
                {errors["email"] && (
                  <span className="error_msg">{errors["email"]}</span>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="form_field">
                <select
                  value={formData.experience}
                  onChange={(e) => setFormData(e)}
                  name="experience"
                  // className={`${errors["firstName"] ? "show_error_line" : ""}`}
                >
                  <option value="0">Experience (0 year)</option>
                  <option value="12">Experience (1 years)</option>
                  <option value="24">Experience (2 years)</option>
                  <option value="36">Experience (3 years)</option>
                  <option value="48">Experience (4 year)</option>
                  <option value="60">Experience (5 years)</option>
                  <option value="72">Experience (6 years)</option>
                  <option value="84">Experience (7 years)</option>
                  <option value="96">Experience (8 years)</option>
                  <option value="108">Experience (9 years)</option>
                </select>
                {errors["experience"] && (
                  <span className="error_msg">{errors["experience"]}</span>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="form_field">
                <div className="file-input">
                  <input
                    type="file"
                    onChange={setFileVal}
                    accept=".docx,.pdf"
                    className={`${errors["fileVal"] ? "show_error_line" : ""}`}
                  />
                  <label className="label" data-js-label>
                    {fileVal ? fileVal.name : "Choose File"}
                  </label>
                  <span className="logo">
                    <img src={UploadImg} alt="" />
                  </span>
                </div>
                {errors["fileVal"] && (
                  <span className="error_msg">{errors["fileVal"]}</span>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 ms-2">
              <Recaptcha
                sitekey="6LdZCykdAAAAAMA3sIkaVDfPDO5n_ePQxCnX0kYU"
                render="explicit"
                onloadCallback={callback}
                verifyCallback={verifyCallback}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form_submit">
                <input onClick={handleSubmit} type="submit" value="Submit" />
              </div>
            </div>
            <div className="notification_msg">
              <div className={`text-center mt-1 ${notificationType}`}>
                {notificationMessage}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JoinUsView;

// import React from "react";
// import UploadImg from "../../assets/images/upload_2.png";

// const JoinUsView = () => {
//   return (
//     <div className="container-fluid">
//       <div className="row d-flex justify-content-center">
//         <div className="cl-sm-12 col-md-8 col-lg-6">
//           <div className="join_us">
//             <h2 className="heading">Submit Resume</h2>
//             <div className="form_main">
//               <form>
//                 <div className="row">
//                   <div className="col-xs-12 col-sm-6 col-md-6">
//                     <div className="form_field">
//                       <input
//                         type="text"
//                         name="first_name"
//                         placeholder="First Name"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xs-12 col-sm-6 col-md-6">
//                     <div className="form_field">
//                       <input
//                         type="text"
//                         name="last_name"
//                         placeholder="Last Name"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xs-12 col-sm-6 col-md-6">
//                     <div className="form_field">
//                       <input
//                         type="text"
//                         name="qualification"
//                         placeholder="Qualification"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-xs-12 col-sm-6 col-md-6">
//                     <div className="form_field">
//                       <input type="tel" name="phone" placeholder="Phone" />
//                     </div>
//                   </div>
//                   <div className="col-xs-12 col-sm-12 col-md-12">
//                     <div className="form_field">
//                       <input type="email" name="email" placeholder="Email" />
//                     </div>
//                   </div>

//                   <div className="col-xs-12 col-sm-6 col-md-6">
//                     <div className="form_field">
//                       <select name="Exp">
//                         <option value="Experience (0-1 year)">
//                           Experience (0 year)
//                         </option>
//                         <option value="Experience (1-3 years)">
//                           Experience (1 years)
//                         </option>
//                         <option value="Experience (3-5 years)">
//                           Experience (2 years)
//                         </option>
//                         <option value="Experience (5-9 years)">
//                           Experience (3 years)
//                         </option>
//                       </select>
//                     </div>
//                     {/* <!-- d-flex justify-content-between -->
//           <!-- <div className="form_field w-100">
//             <select name="Exp">
//               <option className="text-muted" value="">Exp in yrs</option>
//               <option value="" disabled selected hidden>Exp in yrs</option>
//               <option value="0">0</option>
//               <option value="1">1</option>
//               <option value="2">2</option>
//             </select>
//           </div>
//           <div className="form_field w-100">
//             <select name="Exp">
//               <option value="">Exp in months</option>
//               <option value="0">0</option>
//               <option value="1">1</option>
//               <option value="2">2</option>
//             </select>
//           </div> --> */}
//                   </div>
//                   <div className="col-xs-12 col-sm-6 col-md-6">
//                     <div className="form_field">
//                       <div className="file-input">
//                         <input type="file" />
//                         <label className="label" data-js-label>
//                           Choose File
//                         </label>
//                         <span className="logo">
//                           <img src={UploadImg} alt="" />
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-xs-12 col-sm-12 col-md-12">
//                     <div className="form_submit">
//                       <input type="submit" value="Submit" />
//                     </div>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JoinUsView;
