import React, { Component } from "react";
import { bindActionCreators } from "redux";
// import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import "./SystemManagementStyles.scss";
import SystemManagementView from "./SystemManagementView";
import {
  addRole,
  editRole,
  list as getRole,
  deleteRole,
  rolePermissionlist,
  removeAssignPermission,
} from "../../../store/role/roleActions";
import {
  assignPermission,
  list as getPermission,
  addPermission,
  editPermission,
  deletePermission,
} from "../../../store/permission/permissionActions";
import {
  list as getlocation,
  addLocation,
  editLocation,
  deleteLocation,
} from "../../../store/location/locationActions";
import {
  list as getLevel,
  addLevel,
  editLevel,
  deleteLevel,
} from "../../../store/level/levelActions";
import {
  list as getLunchType,
  addLunchType,
  editLunchType,
  deleteLunchType,
} from "../../../store/lunchType/lunchTypeActions";
import {
  //userSuccess,
  addApplicationManager,
  editApplicationManager,
  deleteApplicationManager,
  //applicationmanager,
  // getApplicationManagerList,
  getApplicationManagerList,
} from "../../../store/application/applicationAction";

import { Loader } from "../../common";

export class SystemManagement extends Component {
  constructor(props) {
    super(props);

    // this.props.getlocation();
    this.props.getRole();
    this.props.getPermission();
    this.props.getLevel();
    // this.props.getLunchType();
    this.props.rolePermissionlist();
    this.props.getApplicationManagerList();
    console.log("inside system managementcontainer");
    console.log(
      this.props.getApplicationManagerList,
      " this.props.getApplicationManagerListin system container"
    );
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}
  onApplicationManager = (applicationType, userId, userLocation) => {
    this.props.applicationmanager(applicationType, userId, userLocation, false);
  };
  render() {
    console.log("inside reander applicationmanager container");

    const {
      addRole,
      editRole,
      deleteRole,

      addPermission,
      editPermission,
      deletePermission,
      assignPermission,

      roleSuccess,
      // locationSuccess,
      permissionSuccess,
      levelSuccess,
      applicationManagerSuccess,

      roleList,
      locationList,
      permissionList,
      addLocation,
      editLocation,
      deleteLocation,

      addLevel,
      editLevel,
      deleteLevel,
      levelList,

      lunchTypeList,
      // lunchTypeSuccess,
      addLunchType,
      editLunchType,
      deleteLunchType,

      // rolepermissionSuccess,
      rolepermissionList,
      removeAssignPermission,

      applicationmanager,
      addApplicationManager,
      editApplicationManager,
      deleteApplicationManager,
      getApplicationManagerList,

      getUserApplicationManagerList,
    } = this.props;
    console.log("getUserApplicationManagerList", getUserApplicationManagerList);
    // console.log(permissionSuccess)
    // rolepermissionSuccess === false ||
    if (
      roleSuccess === false ||
      // locationSuccess === false ||
      permissionSuccess === false ||
      levelSuccess === false ||
      applicationManagerSuccess === false
      // lunchTypeSuccess === false
    ) {
      return <Loader />;
    } else {
      return (
        <SystemManagementView
          addRole={addRole}
          editRole={editRole}
          deleteRole={deleteRole}
          assignPermission={assignPermission}
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          roleList={roleList}
          locationList={locationList}
          permissionList={permissionList}
          addLocation={addLocation}
          editLocation={editLocation}
          deleteLocation={deleteLocation}
          levelList={levelList}
          addLevel={addLevel}
          editLevel={editLevel}
          deleteLevel={deleteLevel}
          lunchTypeList={lunchTypeList}
          addLunchType={addLunchType}
          editLunchType={editLunchType}
          deleteLunchType={deleteLunchType}
          rolepermissionList={rolepermissionList}
          removeAssignPermission={removeAssignPermission}
          applicationmanager={applicationmanager}
          getApplicationManagerList={getApplicationManagerList}
          addApplicationManager={addApplicationManager}
          editApplicationManager={editApplicationManager}
          deleteApplicationManager={deleteApplicationManager}
          getUserApplicationManagerList={getUserApplicationManagerList}
          onApplicationManager={this.onApplicationManager}
        />
      );
    }
  }
}

SystemManagement.propTypes = {};

const mapStateToProps = (state) => ({
  roleSuccess: state.role.roleSuccess,
  rolepermissionSuccess: state.role.rolepermissionSuccess,
  levelSuccess: state.level.levelSuccess,
  roleList: state.role.roleList,
  rolepermissionList: state.role.rolepermissionList,
  locationSuccess: state.location.locationSuccess,
  locationList: state.location.locationList,
  permissionSuccess: state.permission.permissionSuccess,
  permissionList: state.permission.permissionList,
  levelList: state.level.levelList,

  applicationmanager: state.applicationManagerReducer.applicationmanager,
  getApplicationManagerList:
    state.applicationManagerReducer.getApplicationManagerList,
  addApplicationManager: state.applicationManagerReducer.addApplicationManager,
  editApplicationManager:
    state.applicationManagerReducer.editApplicationManager,
  deleteApplicationManager:
    state.applicationManagerReducer.deleteApplicationManager,
  getUserApplicationManagerList:
    state.applicationManagerReducer.getUserApplicationManagerList,

  lunchTypeList: state.lunchType.allLunchTypeList,
  lunchTypeSuccess: state.lunchType.lunchTypeSuccess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addRole: addRole,
      editRole: editRole,
      assignPermission: assignPermission,
      removeAssignPermission: removeAssignPermission,
      getRole: getRole,
      getlocation: getlocation,
      getPermission: getPermission,
      getLevel: getLevel,
      deleteRole: deleteRole,
      addPermission: addPermission,
      editPermission: editPermission,
      deletePermission: deletePermission,
      addLocation: addLocation,
      editLocation: editLocation,
      deleteLocation: deleteLocation,

      addLevel: addLevel,
      editLevel: editLevel,
      deleteLevel: deleteLevel,

      getLunchType: getLunchType,
      addLunchType: addLunchType,
      editLunchType: editLunchType,
      deleteLunchType: deleteLunchType,

      rolePermissionlist: rolePermissionlist,

      addApplicationManager: addApplicationManager,
      editApplicationManager: editApplicationManager,
      deleteApplicationManager: deleteApplicationManager,
      getApplicationManagerList: getApplicationManagerList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SystemManagement);
