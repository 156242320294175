import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";

export const actionTypes = {
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
};

export const showNotification = (msg, type) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_NOTIFICATION,
    payload: {
      msg: msg,
      type: type,
    },
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: {
        msg: "",
        type: "",
      },
    });
  }, 1500);
};

export const addJobApplication = (data, file) => (dispatch) => {
  // console.log(data);
  // console.log(file);
  dispatch(showLoader());
  var formdata = new FormData();
  formdata.append("firstName", data.firstName);
  formdata.append("lastName", data.lastName);
  formdata.append("mobileNumber", data.phone);
  // formdata.append("whatsAppNumber", data.phone);
  formdata.append("experienceInMonths", parseInt(data.experience));
  formdata.append("emailAddress", data.email);
  formdata.append("qualification", data.qualification);
  formdata.append("file", file);
  formdata.append("referredByDetails", "Self");
  formdata.append("referredByName", "Self");
  // formdata.append("isActive", true);
  // console.log(...formdata);
  Api.post("recruitmentManagement/addNewJobApplication", formdata)
    .then((response) => {
      // console.log(response);
      dispatch(hideLoader());
      dispatch(
        showAlert({
          isOpen: true,
          title: "Success",
          type: "success",
          msg: "Successfully submitted resume.",
        })
      );
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    })
    .catch((err) => {
      console.log(err);
      dispatch(hideLoader());
      dispatch(
        showAlert({
          isOpen: true,
          title: "Error",
          type: "danger",
          msg: "Failed to submit resume.",
        })
      );
    });
  // dispatch({
  //   type: actionTypes.SHOW_NOTIFICATION,
  //   payload: {
  //     msg: "Resume submitted successfully.",
  //     type: "success",
  //   },
  // });
  // dispatch({
  //   type: actionTypes.SHOW_NOTIFICATION,
  //   payload: {
  //     msg: "",
  //     type: "",
  //   },
  // });
};
