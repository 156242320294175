import { actionTypes } from "./technicalDomainActions";

const {
  TECHDOMAIN_PENDING,
  TECHDOMAIN_ERROR,
  TECHDOMAIN_SUCCESS,

  TECHDOMAIN_ADD_PENDING,
  TECHDOMAIN_ADD_ERROR,
  TECHDOMAIN_ADD_SUCCESS,

  TECHDOMAIN_EDIT_PENDING,
  TECHDOMAIN_EDIT_ERROR,
  TECHDOMAIN_EDIT_SUCCESS,

  TECHDOMAIN_DELETE_PENDING,
  TECHDOMAIN_DELETE_ERROR,
  TECHDOMAIN_DELETE_SUCCESS,
} = actionTypes;

const initialState = {
  techDomainPending: false,
  techDomainSuccess: false,
  techDomainError: null,

  techDomainAddPending: false,
  techDomainAddSuccess: false,
  techDomainAddError: null,

  techDomainEditPending: false,
  techDomainEditSuccess: false,
  techDomainEditError: null,

  techDomainDeletePending: false,
  techDomainDeleteSuccess: false,
  techDomainDeleteError: null,

  techDomainList: [],
};

export default function techDomain(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TECHDOMAIN_PENDING:
    case TECHDOMAIN_ERROR:
    case TECHDOMAIN_SUCCESS:
      return Object.assign({}, state, payload);
    case TECHDOMAIN_ADD_PENDING:
    case TECHDOMAIN_ADD_ERROR:
      return Object.assign({}, state, payload);
    case TECHDOMAIN_ADD_SUCCESS: {
      const newHoliday = payload.techDomain;
      const newHolidaysList = [...state.techDomainList, newHoliday];

      const newState = {
        techDomainList: newHolidaysList,
        techDomainAddPending: payload.techDomainAddPending,
        techDomainAddSuccess: payload.techDomainAddSuccess,
        techDomainAddError: payload.techDomainAddError,
      };
      return Object.assign({}, state, newState);
    }
    case TECHDOMAIN_EDIT_PENDING:
    case TECHDOMAIN_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case TECHDOMAIN_EDIT_SUCCESS: {
      const newHolidaysList = state.techDomainList.map((list) => {
        if (list._id === payload.techDomain._id) {
          return payload.techDomain;
        } else {
          return list;
        }
      });
      return Object.assign(
        {},
        state,
        { techDomainList: newHolidaysList },
        payload
      );
    }
    case TECHDOMAIN_DELETE_PENDING:
    case TECHDOMAIN_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case TECHDOMAIN_DELETE_SUCCESS: {
      const newHolidaysList = state.techDomainList.filter((list) => {
        return list._id !== payload.id;
      });
      return Object.assign(
        {},
        state,
        { techDomainList: newHolidaysList },
        payload
      );
    }
    default:
      return state;
  }
}
