import { actionTypes } from "./userActions";

const {
  USER_PENDING,
  USER_ERROR,
  USER_SUCCESS,
  USERLIST_PENDING,
  USERLIST_SUCCESS,
  USERLIST_ERROR,
  UPDATEUSER_PENDING,
  UPDATEUSER_SUCCESS,
  UPDATEUSER_ERROR,
  DELETE_PENDING,
  DELETE_SUCCESS,
  DELETE_ERROR,
  CREATE_PENDING,
  CREATE_SUCCESS,
  CREATE_ERROR,
  USERDOC_PENDING,
  USERDOC_SUCCESS,
  USERDOC_ERROR,

  USER_MANAGER_PENDING,
  USER_MANAGER_SUCCESS,
  USER_MANAGER_ERROR,

  UPDATE_PROFILE_PENDING,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,

  FAMILY_MEMBER_LIST_PENDING,
  FAMILY_MEMBER_LIST_SUCCESS,
  FAMILY_MEMBER_LIST_ERROR,

  USER_SKILL_LIST_PENDING,
  USER_SKILL_LIST_SUCCESS,
  USER_SKILL_LIST_ERROR,
} = actionTypes;

const initialState = {
  userPending: false,
  userSuccess: false,
  userError: null,

  userManagerPending: false,
  userManagerSuccess: false,
  userManagerError: null,

  userListPending: false,
  userListSuccess: false,
  userListError: null,

  updateUserPending: false,
  updateUserSuccess: false,
  updateUserError: null,

  updateProfilePending: false,
  updateProfileSuccess: false,
  updateProfileError: null,

  createPending: false,
  createSuccess: false,
  createError: null,

  familyMemberListPending: false,
  familyMemberListSuccess: false,
  familyMemberListError: null,

  userSkillListPending: false,
  userSkillListSuccess: false,
  userSkillListError: null,

  familyMemberList: [],

  user: null,
  userList: [],
  hasNextPage: false,
  hasPrevPage: false,
  limit: null,
  nextPage: null,
  pagingCounter: null,
  prevPage: null,
  totalDocs: null,
  totalPages: null,

  userDocument: null,
  showInsuranceBox: false,

  userManager: [],

  userSkillList: [],
};

export default function user(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USERDOC_PENDING:
    case USERDOC_SUCCESS:
    case USERDOC_ERROR:
      return Object.assign({}, state, payload);
    case FAMILY_MEMBER_LIST_PENDING:
    case FAMILY_MEMBER_LIST_SUCCESS:
    case FAMILY_MEMBER_LIST_ERROR:
      return Object.assign({}, state, payload);
    case USER_PENDING:
    case USER_ERROR:
    case USER_SUCCESS:
      return Object.assign({}, state, payload);
    case USERLIST_PENDING:
    case USERLIST_ERROR:
    case USERLIST_SUCCESS:
      return Object.assign({}, state, payload);
    case USER_MANAGER_PENDING:
    case USER_MANAGER_ERROR:
    case USER_MANAGER_SUCCESS:
      return Object.assign({}, state, payload);
    case CREATE_PENDING:
    case CREATE_ERROR:
      return Object.assign({}, state, payload);
    case CREATE_SUCCESS:
      var setLocation =
        payload.newUser.location === "6066c634588c1863d3836541"
          ? "Thane"
          : "Udupi";
      var setRole =
        payload.newUser.role === "6065c792208e5947b316d4d1"
          ? "Admin"
          : "Employee";
      var newUser = {
        createdOn: payload.newUser.createdOn,
        email: payload.newUser.email,
        empNumber: payload.newUser.empNumber,
        firstName: payload.newUser.firstName,
        isActive: true,
        lastName: payload.newUser.lastName,
        location: { _id: payload.newUser.location, name: setLocation, __v: 0 },
        phoneNumber: payload.newUser.phoneNumber,
        role: { _id: payload.newUser.role, name: setRole, __v: 0 },
        updatedOn: payload.newUser.updatedOn,
        __v: 0,
        _id: payload.newUser._id,
      };
      var newUserListData = [...state.userList, newUser];
      return Object.assign({}, state, { userList: newUserListData });
    case UPDATEUSER_SUCCESS:
      var setNewLocation =
        payload.updatedUserData.location === "6066c634588c1863d3836541"
          ? "Thane"
          : "Udupi";
      var newData = state.userList.map((list) => {
        if (list._id === payload.updatedUserData._id) {
          list.email = payload.updatedUserData.email;
          list.empNumber = payload.updatedUserData.empNumber;
          list.firstName = payload.updatedUserData.firstName;
          list.lastName = payload.updatedUserData.lastName;
          list.location._id = payload.updatedUserData.location;
          list.location.name = setNewLocation;
          list.phoneNumber = payload.updatedUserData.phoneNumber;
          list.role._id = payload.updatedUserData.role;
        }
        return list;
      });
      return Object.assign({}, state, { userList: newData });
    case UPDATEUSER_PENDING:
    case UPDATEUSER_ERROR:
      return Object.assign({}, state, payload);
    case DELETE_PENDING:
    case DELETE_ERROR:
      return Object.assign({}, state, payload);
    case DELETE_SUCCESS:
      var updatedData = state.userList.filter((list) => {
        return list.userId[0] !== payload.userId;
      });
      return Object.assign({}, state, { userList: updatedData });
    case UPDATE_PROFILE_PENDING:
    case UPDATE_PROFILE_ERROR:
      return Object.assign({}, state, payload);
    case UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        user: { ...state.user, userId: [payload.data] },
      });

    case USER_SKILL_LIST_PENDING:
    case USER_SKILL_LIST_SUCCESS:
    case USER_SKILL_LIST_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
