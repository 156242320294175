import React, { Component } from "react";
import { bindActionCreators } from "redux";
// import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import "./ViewQueriesStyle.scss";

import * as validator from "../../../helpers/validator";

import { trimFieldsData } from "../../../utils";

import { Loader, BigLoader } from "../../common";
import { globals } from "../../../store/globals";
import ViewQueriesView from "./ViewQueriesView";
import {
  userQueryList as getUserQueryList,
  getUserQuery,
  ModalNullGetQueryData,
  addMessageToQuery,
  addStatusToQuery,
  addStatusToMessages,
} from "../../../store/queries/queryAction";

export class ViewQueriesContainer extends Component {
  Id = this.props.getUserQueryData?.[0].queryId._id;
  userId = globals.store.getState().auth.loginUser.userId;
  state = {
    addUserShow: false,
    filterShow: false,

    // currentYear: new Date().getFullYear(),
    setFilterPagination: false,
    isFilterActive: false,
    currentYear: new Date().getFullYear(),
    fields: {
      status: null,
      reply: "",
    },
    errors: {},
  };

  constructor(props) {
    super(props);

    this.props.getUserQueryList({
      pageNumber: 1,
      setKey: 2,
      limit: 12,
      status: "",
    });
    if (this.props.getUserQueryData) {
      this.props.ModalNullGetQueryData();
    }
  }

  handleCloseAfterFilter = () => {
    this.setState({
      addUserShow: false,
      filterShow: false,
      // filterStatus: false,
    });
  };

  handleClose = () => {
    this.setState({
      addUserShow: false,
      filterShow: false,
      // filterStatus: false,
    });
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        status: null,
        reply: "",
      },
      errors: {},
    });
  };

  handleOpenFilter = () => {
    this.setState({
      filterShow: true,
    });
  };

  onchangeStatus = (value) => {
    // console.log("val", value);
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        status: value,
        // reply:
      },
    });
    // console.log("field", fields.status);
  };

  onchangeReply = (value) => {
    // console.log("val", value);
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        reply: value,
        // reply:
      },
    });
    // console.log("field", fields.status);
  };
  // onsetFilterData = (data) => {
  //   // console.log("datafilter", data);
  //   this.setState({
  //     data: data,
  //     setFilterPagination: true,
  //   });
  // };

  // onresetFilterPagination = () => {
  //   this.setState({
  //     setFilterPagination: false,
  //     // data: {
  //     //   isActive: "1",
  //     // },
  //   });
  // };

  handleIsFilterActive = (val) => {
    // console.log("valcontainer", val);
    this.setState({
      isFilterActive: val,
    });
  };

  handleClearFilter = () => {
    this.props.getUserQueryList({
      pageNumber: 1,
      setKey: 2,
      limit: 12,
      status: "",
    });
    this.handleIsFilterActive(false);
    // this.onresetFilterPagination();
  };

  handleSubmit = (queryId, messageData, statusData) => {
    const setKey = 1;

    // e.preventDefault();
    var rules = {
      required: ["status", "reply"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(this.state.fields),
    });

    this.setState({
      errors: {
        ...errors,
      },
    });

    if (!Object.keys(errors).length) {
      this.props.addMessageToQuery(queryId, messageData, setKey);
      this.props.addStatusToQuery(queryId, statusData);
    }
  };

  onResetSubmit = () => {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        status: null,
        reply: "",
      },
      errors: {},
    });
    this.props.ModalNullGetQueryData();
  };
  filterSubmit = (statusData) => {
    const filterData = {
      pageNumber: 1,
      // setKey: 2,
      limit: 12,
      status: statusData.status?.value.toLowerCase(),
    };

    // const rules = {
    //   required: ["status"],
    // };
    // const { errors } = validator.validate(rules, {
    //   ...trimFieldsData(this.state.fields),
    // });

    // this.setState({
    //   errors: {
    //     ...errors,
    //   },
    // });

    if (this.state.fields.status) {
      this.props.getUserQueryList(filterData);
      // setShowNext(true);
      this.handleCloseAfterFilter();
    }
  };

  render() {
    // console.log("this.state", this.state);
    const {
      getUserWfhList,

      getUserWfhAndLeave,

      getUserQueryList,
      addStatusToQuery,
      addStatusToMessages,
      userQueryList,
      totalDocsData,

      userCalender,
      userWfhList,
      nextPagee,
      // totalDocs,
      getUserQuery,

      getUserQueryData,
      ModalNullGetQueryData,
      addMessageToQuery,
      userQueryListSuccess,
      totalPagess,
    } = this.props;
    const { fields, errors } = this.state;

    if (userQueryListSuccess === false) {
      return <Loader />;
    } else {
      return (
        <>
          <ViewQueriesView
            getUserQueryList={getUserQueryList}
            userQueryList={userQueryList}
            userId={this.userId}
            getUserQuery={getUserQuery}
            getUserQueryData={getUserQueryData}
            ModalNullGetQueryData={ModalNullGetQueryData}
            addMessageToQuery={addMessageToQuery}
            onchangeStatus={this.onchangeStatus}
            fields={fields}
            errors={errors}
            onchangeReply={this.onchangeReply}
            handleOpenFilter={this.handleOpenFilter}
            handleSubmit={this.handleSubmit}
            filterSubmit={this.filterSubmit}
            handleClose={this.handleClose}
            filterShow={this.state.filterShow}
            onResetSubmit={this.onResetSubmit}
            addStatusToQuery={addStatusToQuery}
            addStatusToMessages={addStatusToMessages}
            userQueryListSuccess={userQueryListSuccess}
            handleClearFilter={this.handleClearFilter}
            //old
            getUserWfhAndLeave={getUserWfhAndLeave}
            getUserWfhList={getUserWfhList}
            totalDocsData={totalDocsData}
            totalPagess={totalPagess}
            // rowsPerPage={limit}

            nextPagee={nextPagee}
            userCalender={userCalender}
            userWfhList={userWfhList}
            changingYear={this.state.changingYear}
          />

          <BigLoader />
        </>
      );
    }
  }
}

ViewQueriesContainer.propTypes = {};

const mapStateToProps = (state) => {
  // console.log("state", state.employeeWfhReducer);
  return {
    userQueryList: state.employeeQueryReducer.userQueryList,
    getUserQueryData: state.employeeQueryReducer.getUserQueryData,
    //old

    totalPagess: state.employeeQueryReducer.totalPagess,
    userList: state.leaveReporting.userList,

    userWfhList: state.employeeWfhReducer.userWfhList,
    totalDocsData: state.employeeQueryReducer.totalDocsData,
    nextPagee: state.employeeQueryReducer.nextPagee,
    userQueryListSuccess: state.employeeQueryReducer.userQueryListSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserQueryList: getUserQueryList,
      getUserQuery: getUserQuery,
      ModalNullGetQueryData: ModalNullGetQueryData,
      addMessageToQuery: addMessageToQuery,
      addStatusToQuery: addStatusToQuery,
      addStatusToMessages: addStatusToMessages,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewQueriesContainer);
