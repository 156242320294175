import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, TextArea, DatePicker, Select } from "../../../controls";
import {
  HOLIDAY_TYPE_OPTIONAL,
  HOLIDAY_TYPE_REGULAR,
} from "../../../../constants/env";

const HolidaysMngt = ({
  addHoliday,
  editHoliday,
  deleteHoliday,
  holidaysList,
  currentYear,
  getHolidays,
}) => {
  // console.log(holidaysList);
  const typeList = [
    {
      value: HOLIDAY_TYPE_OPTIONAL,
      label: HOLIDAY_TYPE_OPTIONAL,
    },
    {
      value: HOLIDAY_TYPE_REGULAR,
      label: HOLIDAY_TYPE_REGULAR,
    },
  ];
  const yearList = [
    {
      value: new Date().getFullYear() - 1,
      label: `${new Date().getFullYear() - 1}`,
    },
    {
      value: new Date().getFullYear(),
      label: `${new Date().getFullYear()}`,
    },
    {
      value: new Date().getFullYear() + 1,
      label: `${new Date().getFullYear() + 1}`,
    },
  ];
  const [showAddHoliday, setShowAddHoliday] = useState(false);
  const [holiday, setHoliday] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [holidayId, setHolidayId] = useState("");

  const columns = [
    {
      name: "Type",
      selector: "type",
      center: true,
      // sortable: true,
      grow: 4,
    },
    {
      name: "Date",
      // selector: "day",
      sortable: true,
      grow: 4,
      cell: (row) => <div>{`${row.day}/${row.month}/${row.year}`}</div>,
    },
    {
      name: "Description",
      selector: "description",
      // sortable: true,
      // center: true,
      grow: 4,
    },

    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 4,
      cell: (row) => {
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        if (new Date(row.date) < now) {
          return <div>-</div>;
        } else {
          return (
            <div className="d-flex">
              <div className="me-1">
                <ButtonView
                  onClick={() => {
                    setShowEdit(true);
                    setHoliday(new Date(row.date));
                    setDescription(row.description);
                    setType(row.type);
                    setShowAddHoliday(true);
                    setHolidayId(row._id);
                  }}
                  title={"Edit"}
                  size="small"
                  styles="secondary"
                />
              </div>
              <ButtonView
                onClick={() => {
                  if (window.confirm("Delete holiday?")) {
                    // console.log(row._id);
                    deleteHoliday(row._id);
                  }
                }}
                title={"Delete"}
                size="small"
                styles="danger-outline"
              />
            </div>
          );
        }
      },
    },
  ];

  const handleAddHoliday = () => {
    var rules = { required: ["holiday", "description", "type"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ holiday, description, type }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const dateData = new Date(holiday);
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const dayName = days[dateData.getDay()];

        const data = {
          day: dateData.getDate(),
          month: dateData.getMonth() + 1,
          year: dateData.getFullYear(),
          date: moment(holiday).format("YYYY-MM-DD"),
          weekDay: dayName,
          description: description,
          type: type,
        };
        // console.log({ data, holidayId });
        editHoliday(data, holidayId);
        // edit
      } else {
        const dateData = new Date(holiday);
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const dayName = days[dateData.getDay()];

        const data = {
          day: dateData.getDate(),
          month: dateData.getMonth() + 1,
          year: dateData.getFullYear(),
          weekDay: dayName,
          description: description,
          type: type,
          date: moment(holiday).format("YYYY-MM-DD"),
        };
        // console.log({ data, currentYear });
        addHoliday(data, currentYear);
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddHoliday(false);
    setHoliday("");
    setDescription("");
    setShowEdit(false);
    setType("");
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Holidays</div>
              <div className="d-none d-md-block">Holidays Management</div>
            </Card.Title>
            <div className="add_permission d-flex flex-wrap align-items-center">
              <div
                style={{ width: "120px", top: "-2px", position: "relative" }}
                className="me-2"
              >
                <Select
                  name="year"
                  // label="Select Year"
                  isSearchable={false}
                  defaultValue={yearList.find(
                    (list) => currentYear === list.value
                  )}
                  placeholder="Select"
                  options={yearList}
                  onChange={(val) => {
                    getHolidays(val.value, false);
                  }}
                  className="select"
                  classNamePrefix="select"
                />
              </div>
              <div className="d-none d-md-block">
                <ButtonView
                  onClick={() => setShowAddHoliday(true)}
                  title={"Add Holiday"}
                  // styles="secondary"
                />
              </div>
            </div>
          </Card.Header>
          <div className="d-block d-md-none">
            <ButtonView
              onClick={() => setShowAddHoliday(true)}
              title={"Add Holiday"}
              // styles="secondary"
            />
          </div>
          <DataTable
            data={holidaysList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddHoliday}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Holiday" : "Add Holiday"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12} className="mb-3">
                <Select
                  name="type"
                  label="Select Type"
                  isSearchable={false}
                  defaultValue={typeList.find((list) => type === list.value)}
                  placeholder="Select"
                  options={typeList}
                  onChange={(val) => {
                    setType(val.value);
                  }}
                  className="select"
                  classNamePrefix="select"
                  isrequired={true}
                  error={errors["type"]}
                />
              </Col>
              <Col xs={12} className="mb-3">
                <DatePicker
                  name="holiday"
                  label="Date"
                  value={holiday}
                  onChange={(e) => setHoliday(e)}
                  isrequired={true}
                  error={errors["holiday"]}
                />
              </Col>
              <Col xs={12} className="mt-3">
                <TextArea
                  name="description"
                  label="Description"
                  value={description}
                  type="number"
                  placeholder="Some text here..."
                  onChange={(e) => setDescription(e.target.value)}
                  size="fluid"
                  error={errors["description"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={4} className="mt-3">
                <ButtonView
                  onClick={handleAddHoliday}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HolidaysMngt;
