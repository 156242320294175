import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { globals } from "../globals";
import { MONTH_LIMIT } from "../../service/apiConstants";

export const actionTypes = {
  JOB_APPLICATION_LIST_PENDING: "JOB_APPLICATION_LIST_PENDING",
  JOB_APPLICATION_LIST_SUCCESS: "JOB_APPLICATION_LIST_SUCCESS",
  JOB_APPLICATION_LIST_ERROR: "JOB_APPLICATION_LIST_ERROR",

  JOB_APPLICATION_LIST_INACTIVE_PENDING:
    "JOB_APPLICATION_LIST_INACTIVE_PENDING",
  JOB_APPLICATION_LIST_INACTIVE_SUCCESS:
    "JOB_APPLICATION_LIST_INACTIVE_SUCCESS",
  JOB_APPLICATION_LIST_INACTIVE_ERROR: "JOB_APPLICATION_LIST_INACTIVE_ERROR",

  JOB_APPLICATION_BY_ID_PENDING: "JOB_APPLICATION_BY_ID_PENDING",
  JOB_APPLICATION_BY_ID_SUCCESS: "JOB_APPLICATION_BY_ID_SUCCESS",
  JOB_APPLICATION_BY_ID_ERROR: "JOB_APPLICATION_BY_ID_ERROR",

  QUALIFICATION_LIST_PENDING: "QUALIFICATION_LIST_PENDING",
  QUALIFICATION_LIST_SUCCESS: "QUALIFICATION_LIST_SUCCESS",
  QUALIFICATION_LIST_ERROR: "QUALIFICATION_LIST_ERROR",

  INTERVIEW_SCHEDULE_LIST_PENDING: "INTERVIEW_SCHEDULE_LIST_PENDING",
  INTERVIEW_SCHEDULE_LIST_SUCCESS: "INTERVIEW_SCHEDULE_LIST_SUCCESS",
  INTERVIEW_SCHEDULE_LIST_ERROR: "INTERVIEW_SCHEDULE_LIST_ERROR",

  USERLIST_PENDING: "USERLIST_PENDING",
  USERLIST_SUCCESS: "USERLIST_SUCCESS",
  USERLIST_ERROR: "USERLIST_ERROR",

  CREATE_JOB_PENDING: "CREATE_JOB_PENDING",
  CREATE_JOB_SUCCESS: "CREATE_JOB_SUCCESS",
  CREATE_JOB_ERROR: "CREATE_JOB_ERROR",

  UPDATE_JOB_PENDING: "UPDATE_JOB_PENDING",
  UPDATE_JOB_SUCCESS: "UPDATE_JOB_SUCCESS",
  UPDATE_JOB_ERROR: "UPDATE_JOB_ERROR",

  CLOSE_JOB_PENDING: "CLOSE_JOB_PENDING",
  CLOSE_JOB_SUCCESS: "CLOSE_JOB_SUCCESS",
  CLOSE_JOB_ERROR: "CLOSE_JOB_ERROR",

  CREATE_INTERVIEW_PENDING: "CREATE_INTERVIEW_PENDING",
  CREATE_INTERVIEW_SUCCESS: "CREATE_INTERVIEW_SUCCESS",
  CREATE_INTERVIEW_ERROR: "CREATE_INTERVIEW_ERROR",

  UPDATE_INTERVIEW_PENDING: "UPDATE_INTERVIEW_PENDING",
  UPDATE_INTERVIEW_SUCCESS: "UPDATE_INTERVIEW_SUCCESS",
  UPDATE_INTERVIEW_ERROR: "UPDATE_INTERVIEW_ERROR",

  FILTER_INTERVIEW_SCHEDULE: "FILTER_INTERVIEW_SCHEDULE",
  VIEW_ALL_INTERVIEW_SCHEDULE: "VIEW_ALL_INTERVIEW_SCHEDULE",
};

export function filterInterviewSchedule(date) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FILTER_INTERVIEW_SCHEDULE,
      payload: {
        date: date,
      },
    });
  };
}

export function viewAllInterviewSchedule() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.VIEW_ALL_INTERVIEW_SCHEDULE,
    });
  };
}

export function downloadJobApplicationDocument(applicationId) {
  return (dispatch) => {
    const data = {
      applicationId: applicationId,
      description: "job_application_resume",
    };
    // console.log(data)
    Api.post("recruitmentDocuments/getResumeDownloadURL", data)
      .then((response) => {
        // console.log(response)
        const url = response.data[0].downloadURL;
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function createJobApplication(data, file) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_JOB_PENDING,
      payload: {
        createJobPending: true,
        createJobSuccess: false,
        createJobError: null,
      },
    });

    dispatch(showLoader());
    // console.log(data);
    var formdata = new FormData();
    for (var i = 0; i < data.applicationDomains.length; i++) {
      formdata.append("applicationDomains", data.applicationDomains[i]);
    }
    for (var j = 0; j < data.secondaryTechnicalDomains.length; j++) {
      formdata.append(
        "secondaryTechnicalDomains",
        data.secondaryTechnicalDomains[j]
      );
    }
    for (var k = 0; k < data.primaryTechnicalDomains.length; k++) {
      formdata.append(
        "primaryTechnicalDomains",
        data.primaryTechnicalDomains[k]
      );
    }
    formdata.append("firstName", data.firstName);
    formdata.append("middleName", data.middleName);
    formdata.append("lastName", data.lastName);
    formdata.append("mobileNumber", data.mobileNumber);
    formdata.append("whatsAppNumber", data.whatsAppNumber);
    formdata.append("emailAddress", data.emailAddress);
    formdata.append("city", data.city);
    formdata.append("createdBy", data.createdBy);
    formdata.append("lastUpdatedBy", data.lastUpdatedBy);
    formdata.append("designationAppliedFor", data.designationAppliedFor);
    formdata.append("file", file);
    formdata.append("status", "Applied");
    formdata.append("experienceInMonths", data.experienceInMonths);
    formdata.append("qualification", data.qualification);
    formdata.append("passingYear", data.passingYear);
    formdata.append("skills", data.skills);
    formdata.append("currentEmployer", data.currentEmployer);
    formdata.append("previousCTC", data.previousCTC);
    formdata.append("expectedCTC", data.expectedCTC);
    formdata.append("noticePeriodEndDate", data.noticePeriodEndDate);
    formdata.append("referredByName", data.referredByName);
    formdata.append("referredByDetails", data.referredByDetails);
    // formdata.append("isActive", true);
    if (data.referredById) {
      formdata.append("referredById", data.referredById);
    }

    // console.log(...formdata);

    Api.post("recruitmentManagement/hradmin/createNewJobApplication", formdata)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        var data = response.data;
        var appliedJobdata = {
          ...data,
          resumeDocumentId: {
            applicationId: data._id,
            description: "job_application_resume",
            _id: data.resumeDocumentId,
          },
        };
        dispatch({
          type: actionTypes.CREATE_JOB_SUCCESS,
          payload: {
            createJobPending: false,
            createJobSuccess: true,
            createJobError: null,

            appliedJobdata: appliedJobdata,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: "Successfully added new job application.",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.CREATE_JOB_ERROR,
          payload: {
            createJobPending: false,
            createJobSuccess: true,
            createJobError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add new job application.",
          })
        );
      });
  };
}

export function createNewInterviewSchedule(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_INTERVIEW_PENDING,
      payload: {
        createInterviewPending: true,
        createInterviewSuccess: false,
        createInterviewError: null,
      },
    });

    var formdata = new FormData();
    for (var i = 0; i < data.interviewers.length; i++) {
      formdata.append("interviewers", data.interviewers[i]);
    }
    formdata.append("applicationId", data.applicationId);
    formdata.append("roundNumber", data.roundNumber);
    formdata.append("interviewDate", data.interviewDate);
    formdata.append("startTime", data.startTime);
    formdata.append("endTime", data.endTime);

    formdata.append("mode", data.mode);
    if (data.mode === "Online") {
      if (data.onlineMeetingLink) {
        formdata.append("onlineMeetingLink", data.onlineMeetingLink);
      }
      if (data.notesForCandidate) {
        formdata.append("notesForCandidate", data.notesForCandidate);
      }

      formdata.append(
        "location",
        `Online ${data.onlineMeetingLink && "- " + data.onlineMeetingLink}`
      );
    } else {
      formdata.append("location", data.location);
    }
    if (data.meetingInviteToCandidateStatus) {
      formdata.append(
        "meetingInviteToCandidateStatus",
        data.meetingInviteToCandidateStatus
      );
    }
    if (data.hrSignatureName) {
      formdata.append("hrSignatureName", data.hrSignatureName);
    }
    // formdata.append("outcome", "TBD");
    // formdata.append("isActive", true);

    console.log("form", formdata);

    Api.post("interviewManagement/hradmin/createNewInterviewSchedule", formdata)
      .then((response) => {
        console.log(response);
        dispatch({
          type: actionTypes.CREATE_INTERVIEW_SUCCESS,
          payload: {
            createInterviewPending: false,
            createInterviewSuccess: true,
            createInterviewError: null,

            appliedInterviewdata: response.data,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: "Successfully added job interview schedule.",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.CREATE_INTERVIEW_ERROR,
          payload: {
            createInterviewPending: false,
            createInterviewSuccess: true,
            createInterviewError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add job interview schedule.",
          })
        );
      });
  };
}

export function updateJobApplicationStatus(data, id, showNotification = true) {
  return (dispatch) => {
    // console.log(data);
    dispatch({
      type: actionTypes.UPDATE_JOB_PENDING,
      payload: {
        updateJobPending: true,
        updateJobSuccess: false,
        updateJobError: null,
      },
    });
    Api.post(`interviewManagement/updateJobApplicationStatus/${id}`, data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.UPDATE_JOB_SUCCESS,
            payload: {
              updateJobPending: false,
              updateJobSuccess: true,
              updateJobError: null,

              updateJob: response.data,
            },
          });
          if (showNotification) {
            dispatch(
              showAlert({
                isOpen: true,
                title: "Success",
                type: "success",
                msg: "Successfully updated job application.",
              })
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_JOB_ERROR,
          payload: {
            updateJobPending: false,
            updateJobSuccess: true,
            updateJobError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update job application.",
          })
        );
      });
  };
}

export function updateJobApplication(data, id, showNotification = true) {
  return (dispatch) => {
    // console.log(data);
    dispatch({
      type: actionTypes.UPDATE_JOB_PENDING,
      payload: {
        updateJobPending: true,
        updateJobSuccess: false,
        updateJobError: null,
      },
    });
    Api.post(`recruitmentManagement/hradmin/updateJobApplication/${id}`, data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.UPDATE_JOB_SUCCESS,
            payload: {
              updateJobPending: false,
              updateJobSuccess: true,
              updateJobError: null,

              updateJob: response.data,
            },
          });
          if (showNotification) {
            dispatch(
              showAlert({
                isOpen: true,
                title: "Success",
                type: "success",
                msg: "Successfully updated job application.",
              })
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_JOB_ERROR,
          payload: {
            updateJobPending: false,
            updateJobSuccess: true,
            updateJobError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update job application.",
          })
        );
      });
  };
}

export function closeJobApplication(id, data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLOSE_JOB_PENDING,
      payload: {
        closeJobPending: true,
        closeJobSuccess: false,
        closeJobError: null,
      },
    });
    // console.log(id);
    // console.log(data);
    Api.post(`recruitmentManagement/hradmin/updateJobApplication/${id}`, data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.CLOSE_JOB_SUCCESS,
            payload: {
              closeJobPending: false,
              closeJobSuccess: true,
              closeJobError: null,

              closeJob: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully closed job application.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.CLOSE_JOB_ERROR,
          payload: {
            closeJobPending: false,
            closeJobSuccess: true,
            closeJobError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to close job application.",
          })
        );
      });
  };
}

export function updateInterviewSchedule(data, id, newList) {
  return (dispatch) => {
    // console.log("data", data);
    dispatch({
      type: actionTypes.UPDATE_INTERVIEW_PENDING,
      payload: {
        updateInterviewPending: true,
        updateInterviewSuccess: false,
        updateInterviewError: null,
      },
    });
    // console.log(newList);
    Api.post(`interviewManagement/updateInterviewSchedule/${id}`, data)
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          dispatch({
            type: actionTypes.UPDATE_INTERVIEW_SUCCESS,
            payload: {
              updateInterviewPending: false,
              updateInterviewSuccess: true,
              updateInterviewError: null,

              updateInterview: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated inteview schedule.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_INTERVIEW_ERROR,
          payload: {
            updateInterviewPending: false,
            updateInterviewSuccess: true,
            updateInterviewError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update job inteview schedule.",
          })
        );
      });
  };
}
export function updateInterviewScheduleMeetingLink(data, id, newList) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_INTERVIEW_PENDING,
      payload: {
        updateInterviewPending: true,
        updateInterviewSuccess: false,
        updateInterviewError: null,
      },
    });
    const newData = {
      ...data,
      location: `Online - ${data?.onlineMeetingLink}`,
    };
    // console.log(data);
    Api.post(`interviewManagement/sendInterviewInvitation/${id}`, newData)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.UPDATE_INTERVIEW_SUCCESS,
            payload: {
              updateInterviewPending: false,
              updateInterviewSuccess: true,
              updateInterviewError: null,

              updateInterview: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully sent inteview invitation.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_INTERVIEW_ERROR,
          payload: {
            updateInterviewPending: false,
            updateInterviewSuccess: true,
            updateInterviewError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update job inteview schedule.",
          })
        );
      });
  };
}

export function updateInterviewScheduleStatusByInterviewer(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_INTERVIEW_PENDING,
      payload: {
        updateInterviewPending: true,
        updateInterviewSuccess: false,
        updateInterviewError: null,
      },
    });
    // console.log(data);
    // console.log(id);
    // Api.post(`interviewManagement/updateInterviewSchedule/${id}`, data)
    Api.post(`interviewManagement/updateInterviewScheduleStatus/${id}`, data)
      .then((response) => {
        console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.UPDATE_INTERVIEW_SUCCESS,
            payload: {
              updateInterviewPending: false,
              updateInterviewSuccess: true,
              updateInterviewError: null,

              updateInterview: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated inteview schedule.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_INTERVIEW_ERROR,
          payload: {
            updateInterviewPending: false,
            updateInterviewSuccess: true,
            updateInterviewError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update job inteview schedule.",
          })
        );
      });
  };
}

export function qualificationList() {
  return (dispatch) => {
    // interviewManagement/jobApplicationAndInterviewManagement/getDistinctQualifications
    // qualificationList
    dispatch({
      type: actionTypes.QUALIFICATION_LIST_PENDING,
      payload: {
        qualificationListPending: true,
        qualificationListSuccess: false,
        qualificationListError: null,
      },
    });
    Api.post(`interviewManagement/getDistinctQualifications`)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: actionTypes.QUALIFICATION_LIST_SUCCESS,
            payload: {
              qualificationListPending: false,
              qualificationListSuccess: true,
              qualificationListError: null,

              qualificationList: response.data,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.QUALIFICATION_LIST_ERROR,
          payload: {
            qualificationListPending: false,
            qualificationListSuccess: true,
            qualificationListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function jobApplicationById(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.JOB_APPLICATION_BY_ID_PENDING,
      payload: {
        jobApplicationByIdPending: true,
        jobApplicationByIdSuccess: false,
        jobApplicationByIdError: null,
      },
    });
    console.log(id);
    Api.get(`interviewManagement/getJobApplicationById/${id}`)
      .then((response) => {
        console.log(response);
        dispatch({
          type: actionTypes.JOB_APPLICATION_BY_ID_SUCCESS,
          payload: {
            jobApplicationByIdPending: false,
            jobApplicationByIdSuccess: true,
            jobApplicationByIdError: null,

            jobApplicationById: response.data.docs,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.JOB_APPLICATION_BY_ID_ERROR,
          payload: {
            jobApplicationByIdPending: false,
            jobApplicationByIdSuccess: true,
            jobApplicationByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function userList() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USERLIST_PENDING,
      payload: {
        userListPending: true,
        userListSuccess: false,
        userListError: null,
      },
    });

    Api.post(`user/list?limit=${1000}`)
      .then((response) => {
        // console.log(response)
        if (response.data) {
          dispatch({
            type: actionTypes.USERLIST_SUCCESS,
            payload: {
              userListPending: false,
              userListSuccess: true,
              userListError: null,

              userList: response.data.docs,
            },
          });
        } else {
          dispatch({
            type: actionTypes.USERLIST_ERROR,
            payload: {
              userListPending: false,
              userListSuccess: true,
              userListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USERLIST_ERROR,
          payload: {
            userListPending: false,
            userListSuccess: true,
            userListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user list.",
          })
        );
      });
  };
}

export function jobApplication(data, showLoader = true) {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.JOB_APPLICATION_LIST_PENDING,
        payload: {
          jobApplicationPending: true,
          jobApplicationSuccess: false,
          jobApplicationError: null,
        },
      });
    }
    data.active = true;
    data.historyLimitInMonth = MONTH_LIMIT;
    Api.post(`recruitmentManagement/hradmin/getJobApplicationsList`, data)
      .then((response) => {
        // console.log(response.data.docs);
        if (response.data) {
          dispatch({
            type: actionTypes.JOB_APPLICATION_LIST_SUCCESS,
            payload: {
              jobApplicationPending: false,
              jobApplicationSuccess: true,
              jobApplicationError: null,

              jobApplicationList: response.data.docs.reverse(),
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.JOB_APPLICATION_LIST_ERROR,
          payload: {
            jobApplicationPending: false,
            jobApplicationSuccess: true,
            jobApplicationError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function jobApplicationInactive(data, showLoader = true) {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.JOB_APPLICATION_LIST_INACTIVE_PENDING,
        payload: {
          jobApplicationInactivePending: true,
          jobApplicationInactiveSuccess: false,
          jobApplicationInactiveError: null,
        },
      });
    }
    data.active = false;
    data.historyLimitInMonth = MONTH_LIMIT;
    Api.post(`recruitmentManagement/hradmin/getJobApplicationsList`, data)
      .then((response) => {
        // console.log(response.data.docs);
        if (response.data) {
          dispatch({
            type: actionTypes.JOB_APPLICATION_LIST_INACTIVE_SUCCESS,
            payload: {
              jobApplicationInactivePending: false,
              jobApplicationInactiveSuccess: true,
              jobApplicationInactiveError: null,

              jobApplicationInactiveList: response.data.docs.reverse(),
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.JOB_APPLICATION_LIST_INACTIVE_ERROR,
          payload: {
            jobApplicationInactivePending: false,
            jobApplicationInactiveSuccess: true,
            jobApplicationInactiveError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function interviewScheduleList(showLoader = true) {
  return (dispatch) => {
    if (showLoader) {
      dispatch({
        type: actionTypes.INTERVIEW_SCHEDULE_LIST_PENDING,
        payload: {
          interviewScheduleListPending: true,
          interviewScheduleListSuccess: false,
          interviewScheduleListError: null,
        },
      });
    }
    // console.log(data);
    const userId = globals.store.getState().auth.loginUser.userId;
    const userRole = globals.store.getState().auth.loginUser.userRole;
    Api.get(`user/${userId}`).then((response) => {
      var data = {};
      if (userRole === "Admin" || userRole === "Super Admin" || userRole === "Recruitment Admin") { //if (userRole.contains("Admin")) {
        data.active = true;
        data.historyLimitInMonth = MONTH_LIMIT;
      } else {
        data.active = true;
        data.historyLimitInMonth = MONTH_LIMIT;
        data.interviewers = response.data[0]._id;
      }
      // console.log(data);
      Api.post(`interviewManagement/getInterviewScheduleList`, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data) {
            // const interviewScheduleList = response.data.map(list => {
            //   return {
            //     ...list,
            //     applicationId: {
            //       firstName: list.applicationId.firstName,
            //       lastName: list.applicationId.lastName,
            //       primaryTechnicalDomains: list.applicationId.primaryTechnicalDomains,
            //     }
            //   }
            // })
            // const interviewScheduleList = response.data.map(list => {
            //   const interviewers = list.interviewers.map(l => {
            //     return {
            //       userId: [
            //         {
            //           firstName: l.userId[0].firstName,
            //           lastName: l.userId[0].lastName,
            //           _id: l.userId[0]._id,
            //         }
            //       ]
            //     }
            //   })
            //   return {
            //     ...list,
            //     interviewers,
            //   }
            // })
            dispatch({
              type: actionTypes.INTERVIEW_SCHEDULE_LIST_SUCCESS,
              payload: {
                interviewScheduleListPending: false,
                interviewScheduleListSuccess: true,
                interviewScheduleListError: null,

                interviewScheduleList: response.data,
                totalInterviewScheduleList: response.data,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: actionTypes.INTERVIEW_SCHEDULE_LIST_ERROR,
            payload: {
              interviewScheduleListPending: false,
              interviewScheduleListSuccess: true,
              interviewScheduleListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get list.",
            })
          );
        });
    });
  };
}
