import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import moment from "moment";
import { ButtonView, TextArea, Select } from "../../../controls";

// import * as validator from "../../../../helpers/validator";

// import { trimFieldsData } from "../../../../utils";

const QueryDetail = ({
  getUserQueryData,
  queryStatusOptions,
  fields,
  ModalNullGetQueryData,
  onchangeStatus,
  addMessageToQuery,
  onchangeReply,
  addStatusToQuery,
  handleSubmit,
  onResetSubmit,
  errors,
}) => {
  const queryId = getUserQueryData?.[0].queryId._id;
  // const data = {
  //   queryStatus: null,
  //   message: "",
  // };
  // const [errors, setErrors] = useState({});

  const messageData = {
    message: fields.reply,
  };
  const statusData = {
    status: fields.status?.value,
  };

  // const handleSubmit = () => {
  //   var rules = {
  //     required: ["status", "reply"],
  //   };

  //   var { errors } = validator.validate(rules, {
  //     ...trimFieldsData(fields),
  //   });
  //   setErrors(errors);

  //   if (!Object.keys(errors).length) {
  //     addMessageToQuery(Id, messageData);
  //     addStatusToQuery(Id, statusData);
  //   }
  // };

  // const handleClose = () => {
  //   onResetSubmit();
  //   // setOpenCardShow(false);
  //   // setErrors({});
  // };
  // console.log("erroe", errors);
  // console.log("error1", errors["reply"]);
  return (
    <>
      {getUserQueryData && (
        <Col lg={12} xl={8} className="col-xxl-4 top_width mt-1">
          <div className="card_chat_container ">
            <div className="card_chat">
              <Card>
                <Card.Body>
                  <div>
                    <div className="calender_symbols g-0">
                      <Row>
                        <div className="chat_subject">
                          <p>
                            <span className="subject_reply">Subject:</span>
                            <span className="subject_reply_data">
                              {`${" "} ${getUserQueryData[0].queryId.subject}`}
                            </span>
                          </p>
                          <hr />
                        </div>
                      </Row>
                    </div>
                    <div className="card_scoll">
                      <Row>
                        <Col md={12} className="mb-3">
                          <div className="chat_info">
                            {getUserQueryData.map((item) => {
                              // console.log("item", item);
                              var time = moment(item.createdAt).toISOString(
                                true
                              );
                              const myArray = time.split("T");
                              var validTime = moment(myArray, ["h:mm:ss a"]);
                              var actualTime = moment(validTime._i?.[1], [
                                "HH:mm",
                              ]).format("hh:mm a");
                              return (
                                <div key={item._id}>
                                  <div className="chat_inner_info">
                                    <strong>{item.createdBy?.name}</strong>
                                    <span>{`${moment(
                                      new Date(item.createdAt.slice(0, 10))
                                    ).format(
                                      "DD/MM/YYYY"
                                    )}${","} ${actualTime} `}</span>
                                  </div>
                                  <div className="chat_messge">
                                    <p className="chat_inner_message ms-1">
                                      <span>{item.message}</span>
                                    </p>
                                  </div>
                                  <hr />
                                </div>
                              );
                            })}

                            <Col md={12} lg={6} className="mb-3">
                              <Select
                                name="status"
                                label="Status"
                                isSearchable={false}
                                placeholder="Select"
                                options={queryStatusOptions}
                                value={fields.status}
                                onChange={(val) => {
                                  onchangeStatus(val);
                                }}
                                className="select"
                                classNamePrefix="select"
                                error={errors["status"]}
                                isrequired={true}
                              />
                            </Col>
                          </div>
                          <TextArea
                            name="reply"
                            label="Reply"
                            type="textarea"
                            placeholder="Type something..."
                            onChange={(e) =>
                              //   setQueryData({
                              //     ...queryData,
                              //     message: e.target.value,
                              //   })
                              onchangeReply(e.target.value)
                            }
                            size="fluid"
                            error={errors["reply"]}
                            isrequired={true}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <Row className="d-flex justify-content-end">
                    <Col xs={6} md={6} lg={3}>
                      <ButtonView
                        onClick={() => {
                          onResetSubmit();
                        }}
                        title={"Cancel"}
                        styles="danger-outline"
                      />
                    </Col>
                    <Col xs={6} md={6} lg={3}>
                      <ButtonView
                        onClick={() =>
                          handleSubmit(queryId, messageData, statusData)
                        }
                        title={"Save"}
                        styles="secondary"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default QueryDetail;
