import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import "./ProfileStyles.scss";
import ProfileView from "./ProfileView";
import {
  user as getUser,
  downloadInsuranceCard,
  getDocument,
  initDoc,
  updateProfile,
} from "../../../store/user/userActions";
import {
  addFamilyInsurance,
  getFamilyMembersInsurance,
  editFamilyMemberInsurance,
  deleteFamilyMemberInsurance,
  getFamilyMemberInsuranceDoc,
  downloadFamilyInsuranceCard,
} from "../../../store/familyInsurance/familyInsuranceActions";
import { list as getlocation } from "../../../store/location/locationActions";
import { Loader } from "../../common";

export class ProfileContainer extends Component {
  constructor(props) {
    super(props);

    this.props.initDoc();
    this.props.getUser();
    this.props.getlocation();
    this.props.getFamilyMembersInsurance();
    this.props.getFamilyMemberInsuranceDoc("family_insurance_card");
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  handledownloadFile = (userDoc) => {
    this.props.downloadInsuranceCard(userDoc.employeeId);
  };

  render() {
    const {
      userSuccess,
      user,
      locationList,
      locationSuccess,
      getDocument,
      userDocument,
      showInsuranceBox,
      profileURL,
      updateProfile,
      addFamilyInsurance,
      familyMemberInsuranceList,
      editFamilyMemberInsurance,
      deleteFamilyMemberInsurance,
      familyInsuranceDocumentList,
      downloadFamilyInsuranceCard,
    } = this.props;

    if (userSuccess === false || locationSuccess === false) {
      return <Loader />;
    } else {
      return (
        <ProfileView
          user={user}
          locationList={locationList}
          handledownloadFile={this.handledownloadFile}
          getDocument={getDocument}
          userDocument={userDocument}
          showInsuranceBox={showInsuranceBox}
          profileURL={profileURL}
          updateProfile={updateProfile}
          addFamilyInsurance={addFamilyInsurance}
          familyMemberInsuranceList={familyMemberInsuranceList}
          editFamilyMemberInsurance={editFamilyMemberInsurance}
          deleteFamilyMemberInsurance={deleteFamilyMemberInsurance}
          familyInsuranceDocumentList={familyInsuranceDocumentList}
          downloadFamilyInsuranceCard={downloadFamilyInsuranceCard}
        />
      );
    }
  }
}

ProfileContainer.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userSuccess: state.user.userSuccess,
  locationSuccess: state.location.locationSuccess,
  locationList: state.location.locationList,
  userDocument: state.user.userDocument,
  showInsuranceBox: state.user.showInsuranceBox,
  profileURL: state.auth.profileurl,
  familyMemberInsuranceList:
    state.familyInsuranceReducer.familyMemberInsuranceList,

  familyInsuranceDocumentList:
    state.familyInsuranceReducer.familyInsuranceDocumentList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: getUser,
      getlocation: getlocation,
      downloadInsuranceCard: downloadInsuranceCard,
      getDocument: getDocument,
      initDoc: initDoc,
      updateProfile: updateProfile,
      addFamilyInsurance: addFamilyInsurance,
      getFamilyMembersInsurance: getFamilyMembersInsurance,
      editFamilyMemberInsurance: editFamilyMemberInsurance,
      deleteFamilyMemberInsurance: deleteFamilyMemberInsurance,
      getFamilyMemberInsuranceDoc: getFamilyMemberInsuranceDoc,
      downloadFamilyInsuranceCard: downloadFamilyInsuranceCard,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
