import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import moment from "moment";

import { Header } from "../../common";
import { BsNewspaper } from "react-icons/bs";
import Calender from "./components/Calender";
import MyWFHApplications from "./components/MyWFHApplication";
import {
  LEAVE_STATUS_PENDING_APPROVAL,
  LEAVE_STATUS_CANCELLED,
  HOLIDAY_TYPE_REGULAR,
} from "../../../constants/env";
import ModalView from "./components/ModalView";
const WfhApplicationView = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
  getUserWfhList,
  handleSelect,
  userWfhList,
  onsetWfhId,
  wfhId,
  onsetDefaultTabActiveKey,
  defaultTabActiveKey,
  userDocument,
  getUserWfh,
  getUserWfhData,
  downloadFile,
  getDocument,
  updateWfh,
  user,
  currentYear,
  updateWFHDetail,
  employeeId,
  userWfhListCalender,
  userSuccess,
  userWfhListSuccess,
  onsetYear,
  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,
  holidaysList,
  combineCalenderData,
  srollToSummary,
  userCalender,
  date,
  desData,
  changingYear,

  updateGetUserWfhData,
}) => {
  // console.log("changingYear", changingYear);
  useEffect(() => {
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    const WfhData = userCalender.filter(
      (list) => list.status === 0 || list.status === 1 || list.status === -1
    );

    var combineDate = [];
    holidaysList
      .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
      .forEach((i) => {
        combineDate.push({
          des: `${i.description} (Holiday)`,
          startDate: i.date,
        });
      });
    WfhData.forEach((i) => {
      if (i.status === 1 || i.status === 0) {
        combineDate.push(i);
      }
    });

    onsetCombineCalenderList(combineDate);
    const data = combineDate.filter((list) => {
      return list.startDate.slice(0, 10) === date;
    });
    if (data) {
      onsetDescription(data);
    } else {
      onsetDescription([]);
    }
  }, [
    userCalender,
    holidaysList,
    date,
    onsetCombineCalenderList,
    onsetDescription,
  ]);

  useEffect(() => {
    if (userWfhList) {
      const leaveDependOnId = userWfhList.forEach((list) => {
        return list.status === wfhId;
      });

      if (leaveDependOnId) {
        if (leaveDependOnId.status === LEAVE_STATUS_PENDING_APPROVAL) {
          onsetDefaultTabActiveKey("pendingapproval");
        } else if (leaveDependOnId.status === LEAVE_STATUS_CANCELLED) {
          onsetDefaultTabActiveKey("cancelled");
        } else {
          onsetDefaultTabActiveKey("pendingapproval");
        }
      } else {
        onsetDefaultTabActiveKey("pendingapproval");
      }
    } else {
      onsetDefaultTabActiveKey("pendingapproval");
    }
  }, [userWfhList, wfhId, onsetDefaultTabActiveKey]);
  return (
    <>
      <Header label={"WFH Application"} Icon={BsNewspaper} />
      <div className="main__body padding">
        <Container>
          <Row>
            <Col lg={12} xl={4} className="col-xxl-4">
              <div className="mt-3">
                <Calender
                  holidaysList={holidaysList}
                  userCalender={userCalender}
                  onsetDate={onsetDate}
                  onsetDescription={onsetDescription}
                  combineCalenderData={combineCalenderData}
                  srollToSummary={srollToSummary}
                  // showNextYearLeave={showNextYearLeave}
                />
              </div>
            </Col>
            <Col lg={12} xl={8} className="col-xxl-8">
              <div className="column_divider mt-3">
                <div className="symbols">
                  <Card>
                    <Card.Body>
                      <Row className="calender_symbols">
                        <Col
                          md={6}
                          lg={3}
                          ref={srollToSummary}
                          className="d-block d-md-none"
                        ></Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--maroon me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>Holiday</span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--blue me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Pending Approval
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center calender_symbols_content"
                        >
                          <span className="dot_label dot_label--green me-2 my-auto"></span>
                          <span style={{ fontSize: "15px" }}>
                            Approved Leave
                          </span>
                        </Col>
                        <Col
                          md={6}
                          lg={3}
                          className="d-flex my-auto mx-auto text-center justify-content-center"
                        ></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
                <div className="leave_overview">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          md={12}
                          lg={4}
                          className="d-flex justify-content-center align-items-center"
                        ></Col>
                        <Col className="border_dividerr" md={12} lg={12}>
                          <Card
                            className="today_date"
                            style={{ background: "#242034", color: "#fff" }}
                          >
                            <Card.Body className="text-center py-2">
                              {moment(date).format("MMMM Do YYYY")},
                              {moment(date).format("dddd")}
                              {/* 24th October 2021, Wednesday */}
                            </Card.Body>
                          </Card>
                          {desData.length > 0 && desData ? (
                            <div
                              style={{ fontSize: "18px", color: "#242034" }}
                              className="mt-3 mb-3"
                            >
                              <div
                                className={`${
                                  desData.length > 5
                                    ? "leave_overview_contents"
                                    : ""
                                }`}
                              >
                                {desData.map((list, i) => {
                                  return (
                                    <div key={i}>
                                      {list.userId ? (
                                        <span>
                                          {i + 1}.{" "}
                                          <span style={{ color: "#d87d2e" }}>
                                            {list.userId[0].firstName}
                                            {list.userId[0].lastName.slice(
                                              0,
                                              1
                                            )}
                                          </span>{" "}
                                          on {list.leaveSubType} WFH{" "}
                                          {list.startDate === list.endDate
                                            ? `(${moment(list.startDate).format(
                                                "ll"
                                              )})`
                                            : `(${moment(list.startDate).format(
                                                "ll"
                                              )} - ${moment(
                                                list.endDate
                                              ).format("ll")})`}
                                          {list.status === 0 &&
                                            " Pending Approval."}
                                          {list.status === 1 && " Approved."}
                                        </span>
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: "18px",
                                            color: "#d87d2e",
                                          }}
                                          className="mt-3 mb-3 text-center"
                                        >
                                          {list.des}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <div className="mt-3 mb-3 text-center text-muted">
                              No data available on this day
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>

                <div className="my_leave_applications">
                  {defaultTabActiveKey && (
                    <MyWFHApplications
                      getUserWfhList={getUserWfhList}
                      rowsPerPage={rowsPerPage}
                      rowCount={rowCount}
                      onChangePage={onChangePage}
                      currentPage={currentPage}
                      handleSelect={handleSelect}
                      defaultTabActiveKey={defaultTabActiveKey}
                      downloadFile={downloadFile}
                      getUserWfh={getUserWfh}
                      getDocument={getDocument}
                      updateWfh={updateWfh}
                      user={user}
                      currentYear={currentYear}
                      updateWFHDetail={updateWFHDetail}
                      employeeId={employeeId}
                      userWfhListCalender={userWfhListCalender}
                      userWfhListSuccess={userWfhListSuccess}
                      onsetYear={onsetYear}
                      changingYear={changingYear}
                      userWfhList={userWfhList}
                      userCalender={userCalender}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {getUserWfhData && (
          <ModalView
            downloadFile={downloadFile}
            getUserWfhData={getUserWfhData}
            userDocument={userDocument}
            getDocument={getDocument}
            updateWfh={updateWfh}
            user={user}
            currentYear={currentYear}
            changingYear={changingYear}
            updateGetUserWfhData={updateGetUserWfhData}
          />
        )}
      </div>
    </>
  );
};

export default WfhApplicationView;
