import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import "./LunchPreferenceStyles.scss";
import { user as getUser } from "../../../store/user/userActions";
import { lunchPreference } from "../../../store/lunch/lunchActions";
import { deleteLunchPreference } from "../../../store/lunchType/lunchTypeActions";
import { showAlert } from "../../../store/alert/alertActions";
import { Loader } from "../../common";
import LunchPreferenceView from "./LunchPreferenceView";

export class LunchPreferenceContainer extends Component {
  constructor(props) {
    super(props);

    this.props.getUser(true);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onlunchPreference = (lunchType, userId, userLocation) => {
    this.props.lunchPreference(lunchType, userId, userLocation, false);
  };

  render() {
    const {
      user,
      userSuccess,
      lunchTypeList,
      selectedLunchType,
      lunchTypeSuccess,
      showAlert,
      deleteLunchPreference,
    } = this.props;

    if (userSuccess === false || lunchTypeSuccess === false) {
      return <Loader />;
    } else {
      return (
        <LunchPreferenceView
          user={user}
          lunchTypeList={lunchTypeList}
          selectedLunchType={selectedLunchType}
          onlunchPreference={this.onlunchPreference}
          showAlert={showAlert}
          deleteLunchPreference={deleteLunchPreference}
        />
      );
    }
  }
}

LunchPreferenceContainer.propTypes = {
  user: PropTypes.object,
  lunchTypeList: PropTypes.array,
  selectedLunchType: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userSuccess: state.user.userSuccess,
  lunchTypeList: state.lunchType.lunchTypeList,
  selectedLunchType: state.lunchType.selectedLunchType,
  lunchTypeSuccess: state.lunchType.lunchTypeSuccess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: getUser,
      lunchPreference: lunchPreference,
      showAlert: showAlert,
      deleteLunchPreference: deleteLunchPreference,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LunchPreferenceContainer);
