import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, Select } from "../../../controls";

const LeavesPerYearMngt = ({
  addLeavesPerYear,
  editLeavesPerYear,
  deleteLeavesPerYear,
  leavesPerYearList,

  leaveMasterTypeList,
}) => {
  const [showAddLeavesPerYear, setShowAddLeavesPerYear] = useState(false);
  const [totalLeaveDays, setTotalLeaveDays] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [totalLeaveDaysId, setTotalLeaveDaysId] = useState("");

  //   useEffect(()=>{
  // if(totalLeaveDays > 180){
  //   alert("")
  // }
  // },[totalLeaveDays])
  // console.log(leavesPerYearList);
  const leaveTypesListOptions = leaveMasterTypeList.map((leaveType) => {
    return { value: leaveType.description, label: leaveType.description };
  });

  const columns = [
    {
      name: "Leave Type",
      selector: "leaveType",
      sortable: true,
      grow: 8,
    },
    {
      name: "Number of leaves",
      selector: "totalLeaveDays",
      sortable: true,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setLeaveType(row.leaveType);
                setTotalLeaveDays(row.totalLeaveDays);
                setShowAddLeavesPerYear(true);
                setTotalLeaveDaysId(row._id);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete leaveType?")) {
                console.log(row._id);
                deleteLeavesPerYear(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleAddHoliday = () => {
    var rules = { required: ["totalLeaveDays", "leaveType"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ totalLeaveDays, leaveType }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const data = {
          leaveType: leaveType,
          totalLeaveDays: parseInt(totalLeaveDays),
        };
        // console.log(data, totalLeaveDaysId);
        editLeavesPerYear(data, totalLeaveDaysId, leaveMasterTypeList);
        // edit
      } else {
        const data = {
          leaveType: leaveType,
          totalLeaveDays: parseInt(totalLeaveDays),
        };
        // console.log(data);
        addLeavesPerYear(data, leaveMasterTypeList);
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddLeavesPerYear(false);
    setTotalLeaveDays("");
    setLeaveType("");
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Leaves Per Year</div>
              <div className="d-none d-md-block">
                Leaves Per Year Management
              </div>
            </Card.Title>
            <div className="add_permission">
              <ButtonView
                onClick={() => setShowAddLeavesPerYear(true)}
                title={"Add Leaves"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={leavesPerYearList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddLeavesPerYear}
        onHide={handleClose}
        aria-labelledby="add_leaveType_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_leaveType_modal">
            {showEdit ? "Edit Leave Per Year" : "Add Leave Per Year"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_leaveType">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12} className="mb-3">
                <Select
                  name="leaveType"
                  label="Leave Type"
                  isSearchable={false}
                  defaultValue={leaveTypesListOptions.find(
                    (option) => option.value === leaveType
                  )}
                  placeholder="Select Leave Type"
                  options={leaveTypesListOptions}
                  onChange={(val) => setLeaveType(val.value)}
                  className="select"
                  classNamePrefix="select"
                  error={errors["leaveType"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12}>
                <Input
                  name="totalLeaveDays"
                  label="Total Leave Per Year"
                  value={totalLeaveDays}
                  type="text"
                  placeholder="Enter number"
                  onChange={(e) => setTotalLeaveDays(e.target.value)}
                  // size="fluid"
                  error={errors["totalLeaveDays"]}
                  isrequired={true}
                  maxLength="3"
                  maxValue="180"
                />
              </Col>
              <Col xs={4} className="mt-3">
                <ButtonView
                  onClick={handleAddHoliday}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeavesPerYearMngt;
