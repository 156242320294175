import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import auth from "./auth";
import connect from "react-redux/es/connect/connect";
import { Sidebar } from "../common";
import SideBarMobile from "../common/SideBarMobile/SideBarMobile";

const RestrictedRoute = ({ component: Component, path, ...rest }) => {
  var str = window.location;
  var url = new URL(str);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated()) {
          return (
            <div className="page_content_wrapper">
              <Sidebar />
              <SideBarMobile />
              <Component {...props} />
            </div>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                  original_path: url.pathname,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps)(RestrictedRoute));
