import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import MySkillsView from "./MySkillsView";
import "./MySkillsStyles.scss";
import {
  getMySkills,
  getProficiencies,
  getCategories,
  getTechnologiesForCategory,
  updateSkills,
  saveSkills,
  saveMappingData,
  fetchSkillCertificate,
  downloadSkillCertificates,
  uploadSkillCertificates,
  deleteSkillCertificate,
  deleteSkill,
} from "../../../store/mySkills/mySkillsActions";

export class MySkillsContainer extends Component {
  constructor(props) {
    super(props);
    this.props.getMySkills();
    this.props.getProficiencies();
    this.props.getCategories();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      mySkillList,
      proficiencies,
      categories,
      technologies,
      getTechnologies,
      updateSkills,
      saveSkills,
      saveMappingData,
      fetchSkillCertificate,
      mySkillCertificates,
      downloadSkillCertificates,
      uploadSkillCertificates,
      deleteSkillCertificate,
      deleteSkill,
      newSkillId,
      DuplicateSkillMessage,
      ErrorMessage,
    } = this.props;

    // console.log(mySkillCertificates);

    return (
      <div>
        <MySkillsView
          mySkillList={mySkillList}
          proficiencies={proficiencies}
          categories={categories}
          technologies={technologies}
          getTechnologies={getTechnologies}
          updateSkills={updateSkills}
          saveSkills={saveSkills}
          saveMappingData={saveMappingData}
          fetchSkillCertificate={fetchSkillCertificate}
          mySkillCertificates={mySkillCertificates}
          downloadSkillCertificates={downloadSkillCertificates}
          uploadSkillCertificates={uploadSkillCertificates}
          deleteSkillCertificate={deleteSkillCertificate}
          deleteSkill={deleteSkill}
          newSkillId={newSkillId}
          DuplicateSkillMessage={DuplicateSkillMessage}
          ErrorMessage={ErrorMessage}
        />
      </div>
    );
  }
}

MySkillsContainer.propTypes = {};

const mapStateToProps = (state) => ({
  mySkillList: state.mySkill.mySkillList,
  proficiencies: state.mySkill.proficiencies,
  categories: state.mySkill.categories,
  technologies: state.mySkill.technologies,
  mySkillCertificates: state.mySkill.mySkillCertificates,
  newSkillId: state.mySkill.newSkillId,
  DuplicateSkillMessage: state.mySkill.DuplicateSkillMessage,
  ErrorMessage: state.mySkill.ErrorMessage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMySkills: getMySkills,
      getProficiencies: getProficiencies,
      getCategories: getCategories,
      getTechnologies: getTechnologiesForCategory,
      updateSkills: updateSkills,
      saveSkills: saveSkills,
      saveMappingData: saveMappingData,
      fetchSkillCertificate: fetchSkillCertificate,
      downloadSkillCertificates: downloadSkillCertificates,
      uploadSkillCertificates: uploadSkillCertificates,
      deleteSkillCertificate: deleteSkillCertificate,
      deleteSkill: deleteSkill,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MySkillsContainer);
