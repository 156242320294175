import React, { useState, useEffect } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { ButtonView, Input } from "../../../controls";
import { DataTable } from "../../../common";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { AiOutlineClose } from "react-icons/ai";

const ProficiencyMgt = ({
  ProficiencyList,
  addProficiency,
  updateProficiency,
  deleteProficiency,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errors, setErrors] = useState({});
  const [proficiency, setProficiency] = useState("");
  const [proficiencyID, setProficiencyID] = useState("");
  const [data, setData] = useState([]);

  // const data = ProficiencyList;
  // console.log(data);

  useEffect(() => {
    const sortedProficiencyList = ProficiencyList.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    setData(sortedProficiencyList);
    // eslint-disable-next-line
  }, [ProficiencyList]);

  const columns = [
    {
      name: "Proficiency Name",
      selector: "proficiencyName",
      sortable: false,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowAddModal(true);
                setShowEditModal(true);
                setProficiencyID(row._id);
                setProficiency(row.proficiencyName);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              setShowDelete(true);
              setProficiencyID(row._id);
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const addUpdateData = {
    proficiencyName: proficiency,
  };

  const handleaddProficiency = () => {
    var rules = {
      required: ["proficiency"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ proficiency }),
    });

    const hasParentheses = /\(|\)/.test(proficiency);

    if (hasParentheses) {
      errors = {
        ...errors,
        proficiency: "Proficiency should not contain parentheses.",
      };
    }

    if (!proficiency) {
      errors = {
        ...errors,
        proficiency: "Please fill proficiency field",
      };
    }

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEditModal) {
        updateProficiency(proficiencyID, addUpdateData);
        handleClose();
        // edit
      } else {
        addProficiency(addUpdateData);
        handleClose();
        // add
      }
      //   handleClose();
    }
  };

  const handleClose = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowDelete(false);
    setProficiency("");
    setProficiencyID("");
    setErrors({});
  };

  return (
    <div className="proficiecny_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Proficiency</div>
              <div className="d-none d-md-block">Proficiency Management</div>
            </Card.Title>

            <ButtonView
              onClick={() => {
                setShowAddModal(true);
              }}
              title={"Add New"}
              size={"medium"}
            // styles="secondary"
            />
          </Card.Header>
          <DataTable
            columns={columns}
            data={data}
            pagination={true}
            paginationPerPage={5}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showDelete}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
        size="sm"
        centered={true}
      >
        <Modal.Header className="d-flex justify-content-center align-items-center">
          <Modal.Title id="add_role_modal"> Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Are you sure you want to delete this Proficiency?
          </p>
          <Row>
            <Col>
              <ButtonView
                size={"small"}
                title="Confirm"
                styles="danger-outline"
                onClick={() => {
                  deleteProficiency(proficiencyID);
                  handleClose();
                }}
              ></ButtonView>
            </Col>
            <Col>
              <ButtonView
                size={"small"}
                title="Cancel"
                onClick={() => {
                  handleClose();
                }}
              ></ButtonView>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showAddModal} onHide={handleClose} centered={true}>
        <Modal.Header>
          <Modal.Title>
            {showEditModal ? "Edit Proficiency" : "Add Proficiency"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12}>
                <Input
                  name="Proficiency"
                  label="Proficiency"
                  maxLength={50}
                  value={showEditModal ? proficiency : proficiency}
                  type="text"
                  placeholder={
                    showEditModal
                      ? "Enter Proficiency Name"
                      : "Enter Proficiency Name"
                  }
                  onChange={(e) => setProficiency(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["proficiency"]}
                  isrequired={true}
                />
              </Col>

              <Col xs={12} className="mt-3">
                <ButtonView
                  // disabled={
                  //   showEditModal &&
                  //   proficiency ===
                  //     data.find((item) => item._id === proficiencyID)
                  //       ?.proficiencyName
                  // }
                  onClick={handleaddProficiency}
                  title={showEditModal ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProficiencyMgt;
