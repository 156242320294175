import React, { useState, useEffect } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { ButtonView, Input } from "../../../controls";
import { DataTable } from "../../../common";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { AiOutlineClose } from "react-icons/ai";

const TechnologyMgt = ({
  TechnologyList,
  addSkillsTechnology,
  updateSkillsTechnology,
  deleteSkillsTechnology,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [errors, setErrors] = useState({});
  const [technology, setTechnology] = useState("");
  const [technologyID, setTechnologyID] = useState("");
  const [data, setData] = useState([]);

  // const data = TechnologyList;
  // console.log(data);

  useEffect(() => {
    const sortedTechnologyList = TechnologyList.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    setData(sortedTechnologyList);
    // eslint-disable-next-line
  }, [TechnologyList]);

  const columns = [
    {
      name: "Technology",
      selector: "technologyName",
      //   sortable: true,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                // setCategory(row.categoryName);
                setTechnologyID(row._id);
                setTechnology(row.technologyName);
                setShowAddModal(true);
                setShowEditModal(true);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              setTechnologyID(row._id);
              setShowDelete(true);
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const addData = {
    technologyName: capitalizeFirstLetter(technology),
  };

  const handleaddTechnology = () => {
    var rules = {
      required: ["technology"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ technology }),
    });

    const hasParentheses = /\(|\)/.test(technology);

    if (hasParentheses) {
      errors = {
        ...errors,
        technology: "Technology should not contain parentheses.",
      };
    }

    if (!technology) {
      errors = {
        ...errors,
        technology: "Please fill technology field",
      };
    }

    setErrors(errors);
    // console.log("errors:", errors);

    if (!Object.keys(errors).length) {
      if (showEditModal) {
        updateSkillsTechnology(technologyID, addData);
        handleClose();
        // edit
      } else {
        addSkillsTechnology(addData);
        handleClose();
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowDelete(false);
    setErrors({});
    setTechnology("");
    setTechnologyID("");
    // console.log(technology, technologyID);
  };

  return (
    <div className="technology_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Technology</div>
              <div className="d-none d-md-block">Technology Management</div>
            </Card.Title>

            <ButtonView
              onClick={() => {
                setShowAddModal(true);
              }}
              title={"Add New"}
              size={"medium"}
            // styles="secondary"
            />
          </Card.Header>
          <DataTable
            columns={columns}
            data={data}
            pagination={true}
            paginationPerPage={5}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showDelete}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
        size="sm"
        centered={true}
      >
        <Modal.Header className="d-flex justify-content-center align-items-center">
          <Modal.Title id="add_role_modal"> Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Are you sure you want to delete this Technology?
          </p>
          <Row>
            <Col>
              <ButtonView
                size={"small"}
                title="Confirm"
                styles="danger-outline"
                onClick={() => {
                  deleteSkillsTechnology(technologyID);
                  handleClose();
                }}
              ></ButtonView>
            </Col>
            <Col>
              <ButtonView
                size={"small"}
                title="Cancel"
                onClick={() => {
                  handleClose();
                }}
              ></ButtonView>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddModal}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
        centered={true}
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEditModal ? "Edit Technology" : "Add Technology"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12}>
                <Input
                  name="Technology"
                  label="Technology"
                  maxLength={50}
                  value={showEditModal ? technology : technology}
                  type="text"
                  placeholder={
                    showEditModal
                      ? "Enter Technology Name"
                      : "Enter Technology Name"
                  }
                  onChange={(e) => setTechnology(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["technology"]}
                  isrequired={true}
                />
              </Col>

              <Col xs={12} className="mt-3">
                <ButtonView
                  onClick={handleaddTechnology}
                  title={showEditModal ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TechnologyMgt;
