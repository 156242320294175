import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  LEVEL_PENDING: "LEVEL_PENDING",
  LEVEL_SUCCESS: "LEVEL_SUCCESS",
  LEVEL_ERROR: "LEVEL_ERROR",

  LEVEL_ADD_PENDING: "LEVEL_ADD_PENDING", 
  LEVEL_ADD_ERROR: "LEVEL_ADD_ERROR", 
  LEVEL_ADD_SUCCESS: "LEVEL_ADD_SUCCESS",

  LEVEL_EDIT_PENDING: "LEVEL_EDIT_PENDING", 
  LEVEL_EDIT_ERROR: "LEVEL_EDIT_ERROR", 
  LEVEL_EDIT_SUCCESS: "LEVEL_EDIT_SUCCESS",

  LEVEL_DELETE_PENDING: "LEVEL_DELETE_PENDING", 
  LEVEL_DELETE_ERROR: "LEVEL_DELETE_ERROR", 
  LEVEL_DELETE_SUCCESS: "LEVEL_DELETE_SUCCESS",
};

export function addLevel(value, number) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEVEL_ADD_PENDING,
      payload: {
        levelAddPending: true,
        levelAddSuccess: false,
        levelAddError: null,
      },
    });

    const data = {
      description: value,
      number: number,
    }
    // console.log(data)

    Api.post("level/create", data)
      .then(response => {
        // console.log(response)
        if(response.message === "SuccessFully added new Level") {
          dispatch({
            type: actionTypes.LEVEL_ADD_SUCCESS,
            payload: {
              levelAddPending: false,
              levelAddSuccess: true,
              levelAddError: null,
  
              level: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new level.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.LEVEL_ADD_ERROR,
          payload: {
            levelAddPending: false,
            levelAddSuccess: true,
            levelAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add level.",
          })
        );
      })
  }
}

export function editLevel(value, number, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEVEL_EDIT_PENDING,
      payload: {
        levelEditPending: true,
        levelEditSuccess: false,
        levelEditError: null,
      },
    });

    const data = {
      description: value,
      number: number,
    }
    // console.log(data, id)

    Api.put(`level/update/${id}`, data)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully updated level") {
          dispatch({
            type: actionTypes.LEVEL_EDIT_SUCCESS,
            payload: {
              levelEditPending: false,
              levelEditSuccess: true,
              levelEditError: null,
  
              level: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated level.",
            })
          );
        }
      })
      .catch(err => {
        // console.log(err)
        dispatch({
          type: actionTypes.LEVEL_EDIT_ERROR,
          payload: {
            levelEditPending: false,
            levelEditSuccess: true,
            levelEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit level.",
          })
        );
      })
  }
}

export function deleteLevel(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEVEL_DELETE_PENDING,
      payload: {
        levelDeletePending: true,
        levelDeleteSuccess: false,
        levelDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`level/delete/${id}`)
      .then(response => {
        // console.log(response)
        if(response.message === "Successfully deleted level") {
          dispatch({
            type: actionTypes.LEVEL_DELETE_SUCCESS,
            payload: {
              levelDeletePending: false,
              levelDeleteSuccess: true,
              levelDeleteError: null,
  
              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted level.",
            })
          );
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: actionTypes.LEVEL_DELETE_ERROR,
          payload: {
            levelDeletePending: false,
            levelDeleteSuccess: true,
            levelDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add level.",
          })
        );
      })
  }
}

export function list() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEVEL_PENDING,
      payload: {
        levelPending: true,
        levelSuccess: false,
        levelError: null,
      },
    });

    Api.get("level/list")
      .then(response => {
        if(response.message === "SuccessFully fetched levels list") {
          dispatch({
            type: actionTypes.LEVEL_SUCCESS,
            payload: {
              levelPending: false,
              levelSuccess: true,
              levelError: null,
  
              levelList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.LEVEL_SUCCESS,
          payload: {
            levelPending: false,
            levelSuccess: true,
            levelError: null,
          },
        });
      })
      .catch(err => {
        dispatch({
          type: actionTypes.LEVEL_ERROR,
          payload: {
            levelPending: false,
            levelSuccess: true,
            levelError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get level list.",
          })
        );
      })
  }
}