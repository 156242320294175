import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import "react-loading-skeleton/dist/skeleton.css";

import "./LeaveReportingStyles.scss";
import {
  userList as getUserList,
  employeeLeaveList as getEmployeeLeaveList,
  getEmployeeLeavesSummary,
  getOtherLeavesSummary,
  getOptionalHolidayLeaveSummary,
  reset,
  setFilter,
  resetFilter,
  hideLeaveSummary,
  dateRangeEmployeeLeaveList,
} from "../../../store/leaveReporting/leaveReporingActions";
import LeaveReportingView from "./LeaveReportingView";
import { Loader } from "../../common";

export class LeaveReportingContainer extends Component {
  constructor(props) {
    super(props);

    this.props.reset();
    this.props.getUserList({ isActive: "1" });
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      userListSuccess,
      userList,
      getEmployeeLeaveList,
      setShow,
      showLoader,
      employeeLeaveList,
      currentYear,

      employeeLeavesSummarySuccess,
      otherLeavesSummarySuccess,

      employeeLeavesSummary,
      otherLeavesSummary,

      getEmployeeLeavesSummary,
      getOtherLeavesSummary,
      employeeLeaveListSuccess,
      reset,
      setFilter,
      resetFilter,
      optionalHolidaySummarySuccess,
      getOptionalHolidayLeaveSummary,
      optionalHolidaySummary,
      hideLeaveSummary,
      dateRangeEmployeeLeaveList,
      dateRangeLeaveList,
      startDate,
      endDate,
    } = this.props;
    if (userListSuccess === false) {
      return <Loader />;
    } else {
      return (
        <LeaveReportingView
          userList={userList}
          getEmployeeLeaveList={getEmployeeLeaveList}
          setShow={setShow}
          showLoader={showLoader}
          employeeLeaveList={employeeLeaveList}
          currentYear={currentYear}
          employeeLeavesSummarySuccess={employeeLeavesSummarySuccess}
          otherLeavesSummarySuccess={otherLeavesSummarySuccess}
          employeeLeavesSummary={employeeLeavesSummary}
          otherLeavesSummary={otherLeavesSummary}
          getEmployeeLeavesSummary={getEmployeeLeavesSummary}
          getOtherLeavesSummary={getOtherLeavesSummary}
          employeeLeaveListSuccess={employeeLeaveListSuccess}
          reset={reset}
          setFilter={setFilter}
          resetFilter={resetFilter}
          getOptionalHolidayLeaveSummary={getOptionalHolidayLeaveSummary}
          optionalHolidaySummarySuccess={optionalHolidaySummarySuccess}
          optionalHolidaySummary={optionalHolidaySummary}
          hideLeaveSummary={hideLeaveSummary}
          dateRangeEmployeeLeaveList={dateRangeEmployeeLeaveList}
          dateRangeLeaveList={dateRangeLeaveList}
          startDate={startDate}
          endDate={endDate}
        />
      );
    }
  }
}

LeaveReportingContainer.propTypes = {};

const mapStateToProps = (state) => ({
  userListSuccess: state.leaveReporting.userListSuccess,
  userList: state.leaveReporting.userList,
  showLoader: state.leaveReporting.showLoader,
  setShow: state.leaveReporting.setShow,
  employeeLeaveList: state.leaveReporting.employeeLeaveList,
  currentYear: state.leaveReporting.currentYear,

  employeeLeavesSummarySuccess:
    state.leaveReporting.employeeLeavesSummarySuccess,
  otherLeavesSummarySuccess: state.leaveReporting.otherLeavesSummarySuccess,
  employeeLeaveListSuccess: state.leaveReporting.employeeLeaveListSuccess,

  employeeLeavesSummary: state.leaveReporting.employeeLeavesSummary,
  otherLeavesSummary: state.leaveReporting.otherLeavesSummary,

  optionalHolidaySummary: state.leaveReporting.optionalHolidaySummary,
  optionalHolidaySummarySuccess:
    state.leaveReporting.optionalHolidaySummarySuccess,
  dateRangeLeaveList: state.leaveReporting.dateRangeLeaveList,
  startDate: state.leaveReporting.startDate,
  endDate: state.leaveReporting.endDate,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserList: getUserList,
      getEmployeeLeaveList: getEmployeeLeaveList,
      getEmployeeLeavesSummary: getEmployeeLeavesSummary,
      getOtherLeavesSummary: getOtherLeavesSummary,
      getOptionalHolidayLeaveSummary: getOptionalHolidayLeaveSummary,
      reset: reset,
      setFilter: setFilter,
      resetFilter: resetFilter,
      hideLeaveSummary: hideLeaveSummary,
      dateRangeEmployeeLeaveList: dateRangeEmployeeLeaveList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveReportingContainer);
