import React from "react";
import { FaFilter } from "react-icons/fa";
import { IoPeople, IoPersonAdd } from "react-icons/io5";
import { Header } from "../../common";
import UserManagementCard from "./components/UserManagementCard";
import AddUser from "./components/AddUser";
import UserFilter from "./components/UserFilter";

const UserManagementView = ({
  userList,
  locationList,
  roleList,
  updateUser,
  deleteUser,
  createUser,
  userListFilter,

  addUserShow,
  filterShow,
  handleClose,
  handleOpenAddUser,
  handleOpenFilter,

  handleUploadFile,
  getDocument,
  removeDocument,
  userDocument,
  showInsuranceBox,
  initDoc,
  downloadInsuranceCard,

  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
  getUserList,
  levelList,
  userData,
  userManager,
  onsetFilterData,
  onresetFilterPagination,
  filterData,

  isFilterActive,
  handleIsFilterActive,
  handleClearFilter,

  getUserSkillList,
  userSkillList,
}) => {
  // console.log("userData", userData);
  const btnData = [
    {
      onClick: handleOpenAddUser,
      icon: IoPersonAdd,
      tooltip: "Add User",
    },
    {
      onClick: handleOpenFilter,
      icon: FaFilter,
      tooltip: "Filter",
    },
  ];

  return (
    <>
      <Header
        label={"User Management"}
        Icon={IoPeople}
        btnData={btnData}
        isFilterActive={isFilterActive}
        handleClearFilter={handleClearFilter}
      />

      <UserManagementCard
        userList={userList}
        locationList={locationList}
        roleList={roleList}
        updateUser={updateUser}
        deleteUser={deleteUser}
        handleUploadFile={handleUploadFile}
        getDocument={getDocument}
        removeDocument={removeDocument}
        userDocument={userDocument}
        showInsuranceBox={showInsuranceBox}
        initDoc={initDoc}
        downloadInsuranceCard={downloadInsuranceCard}
        rowsPerPage={rowsPerPage}
        rowCount={rowCount}
        onChangePage={onChangePage}
        currentPage={currentPage}
        levelList={levelList}
        userDataDetails={userData}
        userManager={userManager}
        onresetFilterPagination={onresetFilterPagination}
        filterData={filterData}
        getUserSkillList={getUserSkillList}
        userSkillList={userSkillList}
      />

      <AddUser
        createUser={createUser}
        addUserShow={addUserShow}
        handleClose={handleClose}
        locationList={locationList}
        roleList={roleList}
        levelList={levelList}
        userDataDetails={userData}
        currentPage={currentPage}
        userManager={userManager}
        onresetFilterPagination={onresetFilterPagination}
      />

      <UserFilter
        filterShow={filterShow}
        handleClose={handleClose}
        locationList={locationList}
        userListFilter={userListFilter}
        getUserList={getUserList}
        levelList={levelList}
        onsetFilterData={onsetFilterData}
        onresetFilterPagination={onresetFilterPagination}
        handleIsFilterActive={handleIsFilterActive}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};

export default UserManagementView;
