import React, { useState } from "react";
import { Row, Col, Modal, Table } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import parse from "html-react-parser";
import ReactHtmlParser from "react-html-parser";

import ReactTooltip from "react-tooltip";

import {
  ButtonView,
  Select,
  TimePicker,
  DatePicker,
  Radio,
  TextArea,
  Input,
} from "../../../controls";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import {
  INTERVIEW_STATUS_SCHEDULED,
  INTERVIEW_STATUS_CANCELLED,
  JOB_APPLICAITON_STATUS_IN_PROGRESS,
  INTERVIEW_STATUS_COMPLETED,
  INTERVIEW_STATUS_INCOMPLETED,
  INTERVIEW_STATUS_REJECTED,
  INTERVIEW_STATUS_ACCEPTED,
} from "../../../../constants/env";
import Calender from "./Calender";

const data = {
  interviewDate: "",
  startTime: "",
  endTime: "",
  interviewers: [],
  roundNumber: 0,
  applicationId: "",
  location: "",
  mode: "",
  onlineMeetingLink: "",
  notesForCandidate: "",
  hrSignatureName: "",
};
const dataOne = {
  onlineMeetingLink: "",
  notesForCandidate: "",
  hrSignatureName: "",
};
// meetingInviteToCandidateStatus === "Do not send invite" so show pending, send, Send

const ScheduleInterview = ({
  profileDetail,
  userManager,
  interviewScheduleDetail,
  createNewInterviewSchedule,
  updateJobApplicationStatus,
  user,
  interviewSchedule,
  locationList,
  updateInterviewScheduleMeetingLink,

  date,
  desData,
  combineCalenderData,

  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,
  totalInterviewScheduleList,
  holidaysList,
}) => {
  // console.log(interviewScheduleDetail);
  const userManagerOptions = userManager.map((role) => {
    return {
      value: role._id,
      label: `${role.userId[0].firstName}  ${role.userId[0].lastName}`,
    };
  });
  const locationOptions = locationList
    .map((list) => list.city)
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .map((list) => {
      return {
        value: list,
        label: list,
      };
    });

  const [scheduleData, setScheduleData] = useState(data);
  const [addScheduleApplication, setAddScheduleApplication] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [interviewId, setInterviewId] = useState("");
  // const [status, setStatus] = useState("");
  const [defaultDataOfManagers, setDefaultDataOfManagers] = useState([]);

  const [locationListOnFilter, setLocationListOnFilter] = useState([]);

  const [openMeetingLinkModal, setOpenMeetingLinkModal] = useState(false);
  const [updateMeetingLinkData, setUpdateMeetingLink] = useState(dataOne);

  const [onlineMeetingStatus, setOnlineMeetingStatus] = useState("");
  const [onlineMeetingType, setOnlineMeetingType] = useState("");

  const setDataOnEdit = (list) => {
    // console.log(list);
    setInterviewId(list._id);
    setDefaultDataOfManagers(
      list.interviewers.map((list) => {
        return {
          value: list._id,
          label: `${list.userId[0].firstName} ${list.userId[0].lastName}`,
        };
      })
    );
    setScheduleData({
      interviewDate: new Date(list.interviewDate),
      startTime: list.startTime,
      endTime: list.endTime,
      interviewers: list.interviewers.map((list) => {
        return {
          value: list._id,
          label: `${list.userId[0].firstName} ${list.userId[0].lastName}`,
        };
      }),
      roundNumber: list.roundNumber,
      applicationId: list.applicationId,
    });
    setEdit(true);
    setAddScheduleApplication(true);
  };

  const handleSubmit = () => {
    var rules = {};
    if (edit) {
      rules = {
        required: ["interviewDate", "startTime", "endTime"],
      };
    } else {
      rules = {
        required: ["interviewDate", "startTime", "endTime", "location", "mode"],
      };
    }
    var { errors } = validator.validate(rules, {
      ...trimFieldsData(scheduleData),
    });
    if (!scheduleData.interviewers.length) {
      errors = {
        ...errors,
        interviewers: "Please fill required field",
      };
    }
    if (!edit) {
      if (!locationListOnFilter.length) {
        errors = {
          ...errors,
          city: "Please fill required field",
        };
      }
    }
    if (scheduleData.mode === "Online" && onlineMeetingStatus) {
      if (!scheduleData.onlineMeetingLink) {
        errors = {
          ...errors,
          onlineMeetingLink: "Please fill required fielda",
        };
      }
      if (!scheduleData.hrSignatureName) {
        errors = {
          ...errors,
          hrSignatureName: "Please fill required fielda",
        };
      }
    }
    if (scheduleData.mode === "Offline") {
      if (!scheduleData.hrSignatureName) {
        errors = {
          ...errors,
          hrSignatureName: "Please fill required fielda",
        };
      }
    }
    if (!onlineMeetingType && scheduleData.mode === "Online") {
      errors = {
        ...errors,
        meeting_link: "Please fill required field",
      };
    }
    // onlineMeetingLink meeting_link
    setErrors({
      ...errors,
    });
    if (!Object.keys(errors).length) {
      const newInterviewData = {
        ...scheduleData,
        interviewers: scheduleData.interviewers.map((i) => i.value),
        interviewDate: moment(scheduleData.interviewDate).format("YYYY-MM-DD"),
        meetingInviteToCandidateStatus:
          onlineMeetingType === "Yes" ? "Send Invite" : "Do not send invite",
      };
      if (scheduleData.mode !== "Online") {
        delete newInterviewData.onlineMeetingLink;
        delete newInterviewData.meetingInviteToCandidateStatus;
      }
      if (edit) {
        const readableData = {
          ...newInterviewData,
          interviewers: scheduleData.interviewers.map((i) => {
            return {
              userId: [
                {
                  firstName: i.label,
                  lastName: "",
                },
              ],
              _id: i.value,
            };
          }),
          status: INTERVIEW_STATUS_SCHEDULED,
          _id: interviewId,
        };
        interviewSchedule(
          { ...newInterviewData, status: INTERVIEW_STATUS_SCHEDULED },
          interviewId,
          readableData
        );
      } else {
        createNewInterviewSchedule(newInterviewData);
        const data = {
          lastUpdatedBy: `${user.userId[0].firstName} ${user.userId[0].lastName}`,
          status: JOB_APPLICAITON_STATUS_IN_PROGRESS,
        };
        updateJobApplicationStatus(data, profileDetail._id, false);
      }
      // console.log(newInterviewData);
      handleClose();
    }
    // console.log(scheduleData);
  };

  const handleSubmitMeetingLink = () => {
    var rules = {};
    rules = {
      required: ["onlineMeetingLink", "hrSignatureName"],
    };
    var { errors } = validator.validate(rules, {
      ...trimFieldsData(updateMeetingLinkData),
    });
    setErrors({
      ...errors,
    });
    if (!Object.keys(errors).length) {
      if (!updateMeetingLinkData.notesForCandidate) {
        delete updateMeetingLinkData.notesForCandidate;
      }
      updateInterviewScheduleMeetingLink(updateMeetingLinkData, interviewId);
      handleMeetingLinkClose();
    }
  };

  const handleClose = () => {
    setAddScheduleApplication(false);
    setScheduleData(data);
    setErrors({});
    setDefaultDataOfManagers([]);
    setEdit(false);
    setInterviewId("");
    setLocationListOnFilter([]);
    setOnlineMeetingStatus("");
    setOnlineMeetingType("");

    onsetCombineCalenderList([]);
    onsetDate(new Date().toISOString().split("T")[0]);
    onsetDescription([]);
  };

  const handleMeetingLinkClose = () => {
    setOpenMeetingLinkModal(false);
    setUpdateMeetingLink(dataOne);
    setInterviewId("");
    setErrors({});
  };

  // var html = scheduleData.location;
  return (
    <>
      <div className="job_detail_actions mb-3 d-flex align-items-center justify-content-between">
        <h5 className="m-0">Interview Round(s)</h5>

        {profileDetail.isActive && (
          <ButtonView
            onClick={() => {
              if (interviewScheduleDetail.length) {
                const getRoundNumber = interviewScheduleDetail.filter(
                  (list) => {
                    return !isNaN(list.roundNumber);
                  }
                );

                setScheduleData({
                  ...scheduleData,
                  roundNumber: getRoundNumber.length + 1,
                  applicationId: profileDetail._id,
                });
              } else {
                setScheduleData({
                  ...scheduleData,
                  roundNumber: 1,
                  applicationId: profileDetail._id,
                });
              }
              setAddScheduleApplication(true);
            }}
            title={"Schedule Interview"}
          />
        )}
      </div>

      <div className="sheduled_meeting_list">
        {interviewScheduleDetail.map((list, i) => {
          return (
            <div
              key={list._id}
              style={{ background: "#F1F4FB" }}
              className="mb-3"
            >
              {(list.status === INTERVIEW_STATUS_SCHEDULED ||
                list.status === INTERVIEW_STATUS_CANCELLED ||
                list.status === INTERVIEW_STATUS_REJECTED) && (
                <div className="cancel_interview d-flex justify-content-end p-2">
                  <ButtonView
                    onClick={() => {
                      setDataOnEdit(list);
                    }}
                    title={"Edit"}
                    size="small"
                    styles="secondary"
                  />
                  {list.status === INTERVIEW_STATUS_SCHEDULED && (
                    <div className="ms-2">
                      <ButtonView
                        onClick={() => {
                          const data = {
                            status: INTERVIEW_STATUS_CANCELLED,
                          };
                          interviewSchedule(data, list._id, {
                            ...list,
                            status: INTERVIEW_STATUS_CANCELLED,
                          });
                        }}
                        title={"Cancel"}
                        size="small"
                      />
                    </div>
                  )}
                  {list.status === INTERVIEW_STATUS_CANCELLED && (
                    <div className="ms-2">
                      <ButtonView
                        onClick={() => {
                          const data = {
                            status: INTERVIEW_STATUS_SCHEDULED,
                          };
                          interviewSchedule(data, list._id, {
                            ...list,
                            status: INTERVIEW_STATUS_SCHEDULED,
                          });
                        }}
                        title={"Re-Schedule"}
                        size="small"
                      />
                    </div>
                  )}
                </div>
              )}
              {list.status === INTERVIEW_STATUS_ACCEPTED &&
                list.mode === "Online" && (
                  <div className="cancel_interview align-items-center d-flex justify-content-between p-2">
                    {list.onlineMeetingLink ? (
                      <div
                        style={{ fontSize: "13px" }}
                        className="badge badge-success"
                      >
                        Meeting Invite Sent
                      </div>
                    ) : (
                      <div
                        style={{ fontSize: "13px" }}
                        className="badge badge-danger"
                      >
                        Meeting Invite Pending
                      </div>
                    )}

                    <ButtonView
                      onClick={() => {
                        setOpenMeetingLinkModal(true);
                        setInterviewId(list._id);
                        // const data = {
                        //   status: INTERVIEW_STATUS_SCHEDULED,
                        // };
                        // interviewSchedule(data, list._id, {
                        //   ...list,
                        //   status: INTERVIEW_STATUS_SCHEDULED,
                        // });
                        if (list.onlineMeetingLink) {
                          setUpdateMeetingLink({
                            ...updateMeetingLinkData,
                            onlineMeetingLink: list.onlineMeetingLink,
                            hrSignatureName: list.hrSignatureName,
                            notesForCandidate: list.notesForCandidate
                              ? list.notesForCandidate
                              : "",
                          });
                        }
                      }}
                      title={
                        list.onlineMeetingLink
                          ? "Update Meeting Link"
                          : "Send Meeting Link"
                      }
                      size="small"
                      styles="secondary"
                    />
                  </div>
                )}
              <Table bordered className="profile_detail_table">
                <tbody>
                  <tr>
                    <td>
                      <strong>Round:</strong>
                    </td>
                    <td>{i + 1}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Date:</strong>
                    </td>
                    <td>
                      {moment(new Date(list.interviewDate)).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Interviewers:</strong>
                    </td>
                    <td>
                      {" "}
                      {list.interviewers.map((list, id, array) => {
                        if (id === array.length - 1) {
                          return (
                            <span key={list._id}>
                              {list.userId[0].firstName}{" "}
                              {list.userId[0].lastName}{" "}
                            </span>
                          );
                        } else {
                          return (
                            <span key={list._id}>
                              {list.userId[0].firstName}{" "}
                              {list.userId[0].lastName},{" "}
                            </span>
                          );
                        }
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Mode:</strong>
                    </td>
                    <td>{list.mode}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Location:</strong>
                    </td>
                    <td>{parse(list.location)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Status:</strong>
                    </td>
                    <td>{list.status}</td>
                  </tr>
                  {list.status === INTERVIEW_STATUS_COMPLETED && (
                    <>
                      <tr>
                        <td>
                          <strong>Reviewed:</strong>
                        </td>
                        <td>
                          <strong>{list.lastUpdatedBy}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Outcome:</strong>
                        </td>
                        <td>{list.outcome}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Remarks:</strong>
                        </td>
                        <td>{list.outcomeComments}</td>
                      </tr>
                    </>
                  )}
                  {list.status === INTERVIEW_STATUS_INCOMPLETED && (
                    <>
                      <tr>
                        <td>
                          <strong>Reviewed:</strong>
                        </td>
                        <td>
                          <strong>{list.lastUpdatedBy}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Remarks:</strong>
                        </td>
                        <td>{list.statusComments}</td>
                      </tr>
                    </>
                  )}
                  {list.status === INTERVIEW_STATUS_REJECTED && (
                    <>
                      <tr>
                        <td>
                          <strong>Rejected By:</strong>
                        </td>
                        <td>
                          <strong>{list.lastUpdatedBy}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Remarks:</strong>
                        </td>
                        <td>{list.statusComments}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          );
        })}
        <Modal
          show={openMeetingLinkModal}
          onHide={handleMeetingLinkClose}
          aria-labelledby="add_meeting_link_modal"
        >
          <Modal.Header>
            <Modal.Title id="add_meeting_link_modal">
              Send Meeting Link
            </Modal.Title>
            <div onClick={handleMeetingLinkClose} style={{ cursor: "pointer" }}>
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="add_role">
              <Row className="mb-2 d-flex justify-content-end">
                <Col xs={12}>
                  <div className="content">
                    <TextArea
                      name="onlineMeetingLink"
                      label="Meeting Link"
                      type="text"
                      placeholder="Add Meeting Link"
                      size="fluid"
                      value={updateMeetingLinkData.onlineMeetingLink}
                      onChange={(e) =>
                        setUpdateMeetingLink({
                          ...updateMeetingLinkData,
                          onlineMeetingLink: e.target.value,
                        })
                      }
                      isrequired={true}
                      error={errors["onlineMeetingLink"]}
                    />
                    <div className="text-muted hint  mt-3">
                      Hint: Email of meeting link will be sent to interviewer(s)
                      as well as to candidate.
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="mt-3">
                  <div className="content">
                    <TextArea
                      name="notesForCandidate"
                      label="Notes For Candidate"
                      type="text"
                      placeholder="Notes For Candidate"
                      size="fluid"
                      value={updateMeetingLinkData.notesForCandidate}
                      // row="3"
                      onChange={(e) =>
                        setUpdateMeetingLink({
                          ...updateMeetingLinkData,
                          notesForCandidate: e.target.value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col xs={12} className="mt-3">
                  <div className="content">
                    <Input
                      name="hrSignatureName"
                      label="HR Signature Name"
                      type="text"
                      placeholder="Name"
                      size="fluid"
                      value={updateMeetingLinkData.hrSignatureName}
                      onChange={(e) =>
                        setUpdateMeetingLink({
                          ...updateMeetingLinkData,
                          hrSignatureName: e.target.value,
                        })
                      }
                      isrequired={true}
                      error={errors["hrSignatureName"]}
                    />
                    <div className="text-muted hint mt-3">
                      Hint: Name will be shown on email of interviewer(s) as
                      well as to candidate.
                    </div>
                  </div>
                </Col>
                <Col xs={4} className="mt-3">
                  <ButtonView
                    onClick={handleSubmitMeetingLink}
                    title={"Send"}
                    styles="secondary"
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <Modal
        show={addScheduleApplication}
        onHide={handleClose}
        aria-labelledby="filter_modal"
        size="xl"
        animation={false}
      >
        <Modal.Header>
          <Modal.Title style={{ color: "#d87d2e" }} id="filter_modal">
            Schedule Interview
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} md={4}>
              <Calender
                holidaysList={holidaysList}
                accepted={totalInterviewScheduleList.filter(
                  (list) =>
                    list.status === INTERVIEW_STATUS_ACCEPTED ||
                    list.status === INTERVIEW_STATUS_SCHEDULED
                )}
                date={date}
                desData={desData}
                combineCalenderData={combineCalenderData}
                onsetCombineCalenderList={onsetCombineCalenderList}
                onsetDate={onsetDate}
                onsetDescription={onsetDescription}
                totalInterviewScheduleList={totalInterviewScheduleList}
              />
            </Col>
            <Col xs={12} md={8}>
              <div className="interview_schedule">
                <h6
                  style={{
                    color: "#d87d2e",
                  }}
                  className="ms-1"
                >
                  Application Detail :
                </h6>
                <div className="interview_schedule_table mt-2">
                  <ul className="list-group interview_schedule_detail_list">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Name:
                      <span>
                        {profileDetail.firstName}{" "}
                        {profileDetail.middleName && profileDetail.middleName}{" "}
                        {profileDetail.lastName && profileDetail.lastName}
                      </span>
                    </li>
                    {profileDetail.designationAppliedFor && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Applied Designation:
                        <span>{profileDetail.designationAppliedFor}</span>
                      </li>
                    )}
                    {profileDetail.primaryTechnicalDomains.length ? (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Prime Domain:
                        <span>{profileDetail.primaryTechnicalDomains[0]}</span>
                      </li>
                    ) : null}
                    {profileDetail.secondaryTechnicalDomains.length ? (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        Secondary Domain:
                        <span style={{ textAlign: "right" }}>
                          {profileDetail.secondaryTechnicalDomains.map(
                            (list, id, array) => {
                              if (
                                profileDetail.secondaryTechnicalDomains.length >
                                1
                              ) {
                                if (id === array.length - 1) {
                                  return list;
                                } else {
                                  return (
                                    <>
                                      {list},{" "}
                                      {(id + 1) % 3 === 0 ? <br /> : null}
                                    </>
                                  );
                                }
                              } else {
                                return list;
                              }
                            }
                          )}
                        </span>
                      </li>
                    ) : null}
                    {profileDetail.applicationDomains.length ? (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        Application Domain:
                        <span style={{ textAlign: "right" }}>
                          {profileDetail.applicationDomains.map(
                            (list, id, array) => {
                              if (
                                profileDetail.secondaryTechnicalDomains.length >
                                1
                              ) {
                                if (id === array.length - 1) {
                                  return list;
                                } else {
                                  return (
                                    <>
                                      {list},{" "}
                                      {(id + 1) % 3 === 0 ? <br /> : null}
                                    </>
                                  );
                                }
                              } else {
                                return list;
                              }
                            }
                          )}
                        </span>
                      </li>
                    ) : null}
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      Experience:
                      <span>
                        {" "}
                        {(profileDetail.experienceInMonths / 12) | 0}.
                        {profileDetail.experienceInMonths % 12} yrs
                      </span>
                    </li>
                    {profileDetail.qualification && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Qualification:
                        <span>{profileDetail.qualification}</span>
                      </li>
                    )}
                    {profileDetail.passingYear && (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Passing Year:
                        <span>{profileDetail.passingYear}</span>
                      </li>
                    )}
                  </ul>
                </div>
                <h6
                  style={{
                    color: "#d87d2e",
                  }}
                  className="ms-1 mt-3"
                >
                  Schedule Interview (Round{" "}
                  <span>{scheduleData.roundNumber}</span>)
                </h6>
                <Row className="mt-3">
                  <Col md={12} lg={4} className="mb-3">
                    {/* <Input
                  name="interviewDate"
                  label="Schedule Date"
                  type="date"
                  placeholder="Schedule Date"
                  size="fluid"
                  value={scheduleData.interviewDate}
                  onChange={(e) =>
                    setScheduleData({
                      ...scheduleData,
                      interviewDate: e.target.value,
                    })
                  }
                  error={errors["interviewDate"]}
                  isrequired={true}
                /> */}
                    <DatePicker
                      name="interviewDate"
                      label="Schedule Date"
                      value={scheduleData.interviewDate}
                      onChange={(e) =>
                        setScheduleData({
                          ...scheduleData,
                          interviewDate: e,
                        })
                      }
                      isrequired={true}
                      error={errors["interviewDate"]}
                    />
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <TimePicker
                      onChange={(e) => {
                        setScheduleData({
                          ...scheduleData,
                          startTime: e.format("HH:mm"),
                        });
                      }}
                      placeholder={
                        edit
                          ? `${scheduleData.startTime} ${
                              parseInt(scheduleData.startTime) >= 12
                                ? "pm"
                                : "am"
                            }`
                          : "Start time"
                      }
                      label={"Start Time"}
                      isrequired={true}
                      error={errors["startTime"]}
                    />
                  </Col>
                  <Col md={12} lg={4} className="mb-3">
                    <TimePicker
                      onChange={(e) => {
                        setScheduleData({
                          ...scheduleData,
                          endTime: e.format("HH:mm"),
                        });
                      }}
                      placeholder={
                        edit
                          ? `${scheduleData.endTime} ${
                              parseInt(scheduleData.endTime) >= 12 ? "pm" : "am"
                            }`
                          : "End time"
                      }
                      label={"End Time"}
                      isrequired={true}
                      error={errors["endTime"]}
                    />
                  </Col>
                  {!edit && (
                    <Col md={12} lg={8} className="mb-3">
                      <Select
                        name="interviewers"
                        label="Interviewers"
                        isSearchable={true}
                        defaultValue={defaultDataOfManagers}
                        isMulti={true}
                        placeholder="Start typing the name to search"
                        options={userManagerOptions}
                        onChange={(val) => {
                          setScheduleData({
                            ...scheduleData,
                            interviewers: val,
                          });
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isDisabled={edit}
                        error={errors["interviewers"]}
                        isrequired={true}
                      />
                    </Col>
                  )}
                </Row>
                {!edit && (
                  <>
                    <hr className="my-1" />

                    <Row>
                      <Col xs={12} md={6} lg={6} className="mt-3">
                        <Select
                          name="City"
                          label="City"
                          isSearchable={true}
                          // defaultValue={defaultDataOfManagers}
                          placeholder="Select City"
                          options={locationOptions}
                          onChange={(val) => {
                            const rawLocationList = locationList
                              .filter((list) => {
                                return list.city === val.value;
                              })
                              .map((list) => {
                                // console.log("listS", convertNodeToElement(list.addressLine1))
                                // console.log("listS", parse(list.addressLine1))
                                return {
                                  value: list.addressLine1,
                                  label: list.addressLine1.replace(
                                    /(<([^>]+)>)/gi,
                                    ""
                                  ),
                                };
                              });
                            setLocationListOnFilter(rawLocationList);
                          }}
                          classNamePrefix="select"
                          error={errors["city"]}
                          isrequired={true}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={6} className="mt-3 selectScroll">
                        <div
                          className="location-list-select"
                          data-tip
                          data-for="registerTip"
                        >
                          {/* <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          > */}
                          <Select
                            name="Location"
                            label="Location"
                            // defaultValue={defaultDataOfManagers}
                            // maxMenuHeight={100}
                            placeholder="Select Location"
                            options={locationListOnFilter}
                            onChange={(val) => {
                              setScheduleData({
                                ...scheduleData,
                                location: val.value,
                              });
                            }}
                            classNamePrefix="select"
                            error={errors["location"]}
                            isrequired={true}
                          />
                          {/* </OverlayTrigger> */}
                          {scheduleData.location && (
                            <ReactTooltip
                              id="registerTip"
                              place="top"
                              effect="solid"
                              className="tooltip_select"
                            >
                              {ReactHtmlParser(scheduleData.location)}
                            </ReactTooltip>
                          )}
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="mt-3">
                        <Select
                          name="mode"
                          label="Mode"
                          // defaultValue={defaultDataOfManagers}
                          placeholder="Select Mode"
                          options={[
                            { value: "Online", label: "Online" },
                            { value: "Offline", label: "Offline" },
                            {
                              value: "Telephonic (Over the phone)",
                              label: "Telephonic (Over the phone)",
                            },
                          ]}
                          onChange={(val) => {
                            setScheduleData({
                              ...scheduleData,
                              mode: val.value,
                              onlineMeetingLink: "",
                            });
                            setOnlineMeetingStatus(false);
                            setOnlineMeetingType("");
                          }}
                          classNamePrefix="select"
                          error={errors["mode"]}
                          isrequired={true}
                        />
                      </Col>
                      {scheduleData.mode === "Online" && (
                        <Col md={12} lg={6} className="mt-4">
                          <span className={`label text-muted`}>
                            Send meeting link to the interviewer(s) as well as
                            to candidate. <span className="text-danger">*</span>
                          </span>
                          <div className="currently_employment_status d-flex mt-2">
                            <Radio
                              name={"online_meeting_status"}
                              text={"Yes"}
                              onChange={() => {
                                setOnlineMeetingType("Yes");
                                setOnlineMeetingStatus(true);
                              }}
                            />
                            <Radio
                              name={"online_meeting_status"}
                              text={"Not now"}
                              onChange={() => {
                                setOnlineMeetingType("Not now");
                                setOnlineMeetingStatus(false);
                                setScheduleData({
                                  ...scheduleData,
                                  onlineMeetingLink: "",
                                  notesForCandidate: "",
                                });
                              }}
                            />
                            {true && (
                              <span className="currently_employment_status_error">
                                {errors["meeting_link"]}
                              </span>
                            )}
                          </div>
                        </Col>
                      )}
                      {scheduleData.mode === "Online" && onlineMeetingStatus && (
                        <>
                          <Col xs={12} md={12} lg={12} className="mt-3">
                            <TextArea
                              name="onlineMeetingLink"
                              label="Meeting Link"
                              type="text"
                              placeholder="Meeting Link"
                              size="fluid"
                              value={scheduleData.onlineMeetingLink}
                              onChange={(e) =>
                                setScheduleData({
                                  ...scheduleData,
                                  onlineMeetingLink: e.target.value,
                                })
                              }
                              isrequired={true}
                              error={errors["onlineMeetingLink"]}
                              // row="3"
                            />
                          </Col>
                          <Col xs={12} md={12} lg={12} className="mt-3">
                            <TextArea
                              name="notesForCandidate"
                              label="Notes For Candidate"
                              type="text"
                              placeholder="Notes For Candidate"
                              size="fluid"
                              value={scheduleData.notesForCandidate}
                              onChange={(e) =>
                                setScheduleData({
                                  ...scheduleData,
                                  notesForCandidate: e.target.value,
                                })
                              }
                              // isrequired={true}
                              // error={errors["notesForCandidate"]}
                              // row="3"
                            />
                          </Col>
                          <Col xs={12} md={12} lg={6} className="mt-3">
                            <Input
                              name="hrSignatureName"
                              label="HR Signature Name"
                              type="text"
                              placeholder="Name"
                              size="fluid"
                              value={scheduleData.hrSignatureName}
                              onChange={(e) =>
                                setScheduleData({
                                  ...scheduleData,
                                  hrSignatureName: e.target.value,
                                })
                              }
                              isrequired={true}
                              error={errors["hrSignatureName"]}
                            />
                            <div className="text-muted hint ms-1 mt-3">
                              Hint: Name will be shown on email of candidate.
                            </div>
                          </Col>
                        </>
                      )}
                      {scheduleData.mode === "Offline" && (
                        <Col xs={12} md={12} lg={6} className="mt-3">
                          <Input
                            name="hrSignatureName"
                            label="HR Signature Name"
                            type="text"
                            placeholder="Name"
                            size="fluid"
                            value={scheduleData.hrSignatureName}
                            onChange={(e) =>
                              setScheduleData({
                                ...scheduleData,
                                hrSignatureName: e.target.value,
                              })
                            }
                            isrequired={true}
                            error={errors["hrSignatureName"]}
                          />
                          <div className="text-muted hint ms-1 mt-3">
                            Hint: Name will be shown on email of candidatess.
                          </div>
                        </Col>
                      )}
                    </Row>
                  </>
                )}

                <Row className="d-flex justify-content-end">
                  <Col xs={6} md={6} lg={3} className="mt-3">
                    <ButtonView
                      onClick={() => {
                        handleClose();
                      }}
                      title={"Cancel"}
                      styles="danger-outline"
                    />
                  </Col>
                  <Col xs={6} md={6} lg={3} className="mt-3">
                    <ButtonView
                      onClick={handleSubmit}
                      title={edit ? "Update" : "Send Invite"}
                      styles="secondary"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScheduleInterview;
