import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";

import { ButtonView } from "../../../controls";

const Filter = ({
  filterShow,
  handleClose,
  locationList,
  allLunchTypeList,
  location,
  lunchType,
  handleSelectChange,
  onAddFilter,
  onResetFilter,
}) => {
  const locationOptions = locationList.map((location) => {
    return { value: location._id, label: location.name };
  });

  const lunchTypeOptions = allLunchTypeList.map((lunchType) => {
    return { value: lunchType._id, label: lunchType.lunchType };
  });

  return (
    <div className="filter_modal">
      <Modal
        show={filterShow}
        onHide={handleClose}
        aria-labelledby="filter_modal"
      >
        <Modal.Header>
          <Modal.Title id="filter_modal">Filter</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Select
              isSearchable={false}
              onChange={handleSelectChange("location")}
              defaultValue={
                location
                  ? locationOptions.find((option) => option.value === location)
                  : ""
              }
              placeholder="Select Location"
              options={locationOptions}
              className="select"
              classNamePrefix="select"
            />
          </div>
          <div className="mb-3">
            <Select
              isSearchable={false}
              onChange={handleSelectChange("lunchType")}
              defaultValue={
                lunchType
                  ? lunchTypeOptions.find(
                      (option) => option.value === lunchType
                    )
                  : ""
              }
              placeholder="Select Lunch Type"
              options={lunchTypeOptions}
              className="select"
              classNamePrefix="select"
            />
          </div>
          <Row className="d-flex justify-content-end">
            <Col xs={12} md={6} lg={4} className="mb-2">
              <ButtonView
                onClick={onResetFilter}
                title={"Reset"}
                styles="primal"
              />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <ButtonView
                onClick={onAddFilter}
                title={"Apply Filter"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Filter;
