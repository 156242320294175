import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import All from "./All";
import Approved from "./Approved";
import Rejected from "./Rejected";
import Unapproved from "./Unapproved";
import Cancelled from "./Cancelled";
import { Select } from "../../../controls";
import {
  LEAVE_STATUS_PENDING_APPROVAL,
  LEAVE_STATUS_CANCELLED,
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
} from "../../../../constants/env";
const MyWFHApplications = ({
  currentYear,
  rowsPerPage,
  rowCount,
  onChangePage,
  handleSelect,
  currentPage,
  getUserWfhList,
  defaultTabActiveKey,
  getUserWfh,
  getDocument,
  updateWfh,
  user,
  updateWFHDetail,
  employeeId,
  userWfhListCalender,
  userWfhListSuccess,
  onsetYear,
  changingYear,
  userWfhList,
  userCalender,
}) => {
  // console.log("userWfhList", userWfhList);
  const yearList = [
    {
      value: new Date().getFullYear() - 1,
      label: `${new Date().getFullYear() - 1}`,
    },
    {
      value: new Date().getFullYear(),
      label: `${new Date().getFullYear()}`,
    },
    {
      value: new Date().getFullYear() + 1,
      label: `${new Date().getFullYear() + 1}`,
    },
  ];
  var pendingList = userCalender.filter((list) => {
    return list.status === 0;
  });
  var dot = "";
  if (pendingList.length >= 1) {
    dot = "notification_dots";
  }
  // console.log("dot", dot);
  return (
    <div className="card">
      <div className="card-header border-0 mb-3">
        <h5 className="card-title">WFH Application(s)</h5>
        <div style={{ width: "150px" }}>
          <Select
            name="year"
            isSearchable={false}
            defaultValue={yearList.find((list) => changingYear === list.value)}
            placeholder="Select"
            options={yearList}
            onChange={(val) => {
              const year = val.value;
              const status = parseInt(LEAVE_STATUS_PENDING_APPROVAL);
              // console.log("val", employeeId, year, status);
              getUserWfhList(1, { status, year }, true);
              userWfhListCalender({ year }, true);
              onsetYear(val.value);
            }}
            className="select"
            classNamePrefix="select"
          />
        </div>
      </div>
      <Tabs
        defaultActiveKey={LEAVE_STATUS_PENDING_APPROVAL}
        id="leave_application"
        className="table_tabs"
        onSelect={handleSelect}
      >
        <Tab
          eventKey={LEAVE_STATUS_PENDING_APPROVAL}
          title={
            <span className="notification_container">
              PENDING APPROVAL
              <div className={`${dot}`}></div>
            </span>
          }
        >
          <Unapproved
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            // onChangePage={onChangePage}
            currentPage={currentPage}
            getUserWfhList={getUserWfhList}
            getUserWfh={getUserWfh}
            getDocument={getDocument}
            updateWfh={updateWfh}
            user={user}
            currentYear={currentYear}
            changingYear={changingYear}
            updateWFHDetail={updateWFHDetail}
            userWfhListSuccess={userWfhListSuccess}
            // userWfhList={userWfhList}
          />
        </Tab>
        <Tab eventKey={LEAVE_STATUS_APPROVED} title="APPROVED">
          <Approved
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            // onChangePage={onChangePage}
            currentPage={currentPage}
            getUserWfhList={getUserWfhList}
            getUserWfh={getUserWfh}
            getDocument={getDocument}
            updateWfh={updateWfh}
            user={user}
            currentYear={currentYear}
            changingYear={changingYear}
            updateWFHDetail={updateWFHDetail}
            userWfhListSuccess={userWfhListSuccess}
          />
        </Tab>
        <Tab eventKey={LEAVE_STATUS_REJECTED} title="REJECTED">
          <Rejected
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            // onChangePage={onChangePage}
            currentPage={currentPage}
            getUserWfhList={getUserWfhList}
            getUserWfh={getUserWfh}
            getDocument={getDocument}
            updateWfh={updateWfh}
            user={user}
            currentYear={currentYear}
            changingYear={changingYear}
            userWfhListSuccess={userWfhListSuccess}
          />
        </Tab>
        <Tab eventKey={LEAVE_STATUS_CANCELLED} title="CANCELLED">
          <Cancelled
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            // onChangePage={onChangePage}
            currentPage={currentPage}
            getUserWfhList={getUserWfhList}
            getUserWfh={getUserWfh}
            getDocument={getDocument}
            userWfhListSuccess={userWfhListSuccess}
            changingYear={changingYear}
          />
        </Tab>
        <Tab eventKey={5} title="ALL">
          <All
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            // onChangePage={onChangePage}
            currentPage={currentPage}
            getUserWfhList={getUserWfhList}
            getUserWfh={getUserWfh}
            getDocument={getDocument}
            userWfhListSuccess={userWfhListSuccess}
            changingYear={changingYear}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default MyWFHApplications;
