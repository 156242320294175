import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { Redirect } from "react-router-dom";

import "./LoginStyles.scss";
import queryString from "query-string";
import auth from "../../restricted/auth";
import { login } from "../../../store/auth/authActions";
import { LUNCH } from "../../../navigation/routes";
import LoginView from "./LoginView";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CLIENT_ID } from "../../../constants/env";

export class LoginContainer extends Component {
  url = "";
  type = "";
  mode = "";
  constructor(props) {
    super(props);

    if (window.location.search !== "") {
      const parsed = queryString.parse(window.location.search);
      console.log("parsed", parsed.type);
      this.url = parsed.url;
      this.type = parsed.type;
      this.mode = parsed.mode ? parsed.mode : "cookie";
    }

    this.aref = React.createRef();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onSuccessGoogle = response => {
    //console.log("response", response);
    this.props.login(response, this.props.history, this.url, this.type, this.mode);
  };

  onErrorGoogle = response => {
    console.log(response);
  };

  render() {
    if (auth.isAuthenticated()) return <Redirect to={LUNCH} />;
    return (
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <LoginView
          onSuccessGoogle={this.onSuccessGoogle}
          onErrorGoogle={this.onErrorGoogle}
          url={this.url}
          ref={this.aRef}
          // xyz={this.aref}
        />
      </GoogleOAuthProvider>
    );
  }
}

LoginContainer.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login: login,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
