import React from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { IoPeopleOutline } from "react-icons/io5";
import { AiOutlineSetting, AiOutlineFileText } from "react-icons/ai";
import {
  MdLocalDining,
  MdClose,
  MdOutlineQuestionAnswer,
  MdOutlinePersonSearch,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import { BsNewspaper, BsTable } from "react-icons/bs";
import { FiBriefcase } from "react-icons/fi";
import { MdSchedule } from "react-icons/md";
import { BiHomeSmile } from "react-icons/bi";
import { RiAdminLine } from "react-icons/ri";
import { IoPeople } from "react-icons/io5";
import { RiPencilRuler2Line, RiPencilRulerLine } from "react-icons/ri";
// BsClipboard

import "./SideBarMobileStyles.scss";
import {
  LUNCHPREFERENCE,
  USER,
  SYSTEMMANAGEMENT,
  LEAVEAPPLICATIONS,
  // LEAVEANAGEMENT,
  JOBAPPLICATIONS,
  // RECRUITMENTMANAGEMENT,
  INTERVIEWSCHEDULE,
  LEAVEREPORTING,
  HRADMINISTRATION,
  APPLYFORWFH,
  WFHAPPLICATION,
  VIEWWFHANDLEAVE,
  MYQUERIES,
  VIEWQUERIES,
  APPLICATIONMANAGER,
  MYSKILLS,
  SKILLMANAGEMENT,
  EMPLOYEESKILLS,
} from "../../../navigation/routes";
import { hideSidebar } from "../../../store/sidebar/sidebarActions";
import { globals } from "../../../store/globals";

const SideBarMobile = ({ isOpen, hideSidebar }) => {
  var permission = globals.store.getState().auth.loginUser.userPermissions;
  var permissionNameList = permission.map((x) => x.name);
  var level = globals.store.getState().auth.loginUser.level;
  // var userRole = globals.store.getState().auth.loginUser.userRole;
  return (
    <>
      <div
        className={`clip_wrapper_mobile d-block d-md-none ${
          isOpen ? "clip_wrapper_mobile_open" : "clip_wrapper_mobile_close"
        }`}
      ></div>
      <div
        className={`sidenav_wrapper_mobile d-bl <div onClick={hideSidebar} className="navbar_close">
        <MdClose size={25} />
      </div>ock d-md-none ${
        isOpen ? "sidenav_wrapper_mobile_open" : "sidenav_wrapper_mobile_close"
      }`}
      >
        <Container>
          <Navbar className="sidenav_wrapper_mobile_navbar">
            <div onClick={hideSidebar} className="navbar_close">
              <MdClose size={25} />
            </div>
            <Nav>
              {/* {
                (permission.map(x => x.name).indexOf("LunchPreference") !== -1) && (
                  <NavLink
                    className="nav-link mt-1"
                    exact
                    to={LUNCH}
                    onClick={hideSidebar}
                  >
                    <IoFastFoodOutline size={20} /> Lunch Preference
                  </NavLink>
                )
              } */}
              {permissionNameList.indexOf("LunchManagement") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={LUNCHPREFERENCE}
                  onClick={hideSidebar}
                >
                  <MdLocalDining size={20} />{" "}
                  <span className="side_nav_content_mobi">
                  {permission
                    .filter((list) => list.name === "LunchManagement")
                    .map((list) => {
                      return list.displayName;
                    })}
                  </span>
                </NavLink>
              )}
              {(level >= 4 ||
                permissionNameList.indexOf("LeaveApplications") !== -1) && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={LEAVEAPPLICATIONS}
                  onClick={hideSidebar}
                >
                  <BsNewspaper size={20} /> 
                  <span className="side_nav_content_mobi" >Leave Applications
                  </span>
                </NavLink>
              )}
              {permissionNameList.indexOf("LeaveReporting") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={LEAVEREPORTING}
                  onClick={hideSidebar}
                >
                  <AiOutlineFileText size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {permission
                    .filter((list) => list.name === "LeaveReporting")
                    .map((list) => {
                      return list.displayName;
                    })}
                  </span>
                </NavLink>
              )}
              {permissionNameList.indexOf("JobApplications") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={JOBAPPLICATIONS}
                  onClick={hideSidebar}
                >
                  <FiBriefcase size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {permission
                    .filter((list) => list.name === "JobApplications")
                    .map((list) => {
                      return list.displayName;
                    })}
                  </span>
                </NavLink>
              )}
              {(level >= 4 ||
                permissionNameList.indexOf("InterviewSchedule") !== -1) && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={INTERVIEWSCHEDULE}
                  onClick={hideSidebar}
                >
                  <MdSchedule size={20} /> 
                  <span className="side_nav_content_mobi" >
                  Interview Schedule</span>
                </NavLink>
              )}
              {permissionNameList.indexOf("UserManagement") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={USER}
                  onClick={hideSidebar}
                >
                  <IoPeopleOutline size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {permission
                    .filter((list) => list.name === "UserManagement")
                    .map((list) => {
                      return list.displayName;
                    })}</span>
                </NavLink>
              )}
              {permissionNameList.indexOf("MySkills") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={MYSKILLS}
                  onClick={hideSidebar}
                >
                  <RiPencilRuler2Line size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {/* {permission
                        .filter((list) => list.name === "MySkills")
                        .map((list) => {
                          return list.displayName;
                        })} */}
                  My Skills</span>
                </NavLink>
              )}

              {permissionNameList.indexOf("SkillsManagement") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={SKILLMANAGEMENT}
                  onClick={hideSidebar}
                >
                  <RiPencilRulerLine size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {/* {permission
                        .filter((list) => list.name === "MySkills")
                        .map((list) => {
                          return list.displayName;
                        })} */}
                  Skill Management</span>
                </NavLink>
              )}

              {permissionNameList.indexOf("EmployeeSkills") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={EMPLOYEESKILLS}
                  onClick={hideSidebar}
                >
                  <MdOutlinePersonSearch size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {/* {permission
                        .filter((list) => list.name === "MySkills")
                        .map((list) => {
                          return list.displayName;
                        })} */}
                  Employee Skills</span>
                </NavLink>
              )}

              {/* {permissionNameList.indexOf("LeaveManagement") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={LEAVEANAGEMENT}
                  onClick={hideSidebar}
                >
                  <BsClipboard size={20} />{" "}
                  {permission
                    .filter((list) => list.name === "LeaveManagement")
                    .map((list) => {
                      return list.displayName;
                    })}
                </NavLink>
              )}
              {permissionNameList.indexOf("JobManagement") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={RECRUITMENTMANAGEMENT}
                  onClick={hideSidebar}
                >
                  <FiBriefcase size={20} />{" "}
                  {permission
                    .filter((list) => list.name === "JobManagement")
                    .map((list) => {
                      return list.displayName;
                    })}
                </NavLink>
              )} */}

              {permissionNameList.indexOf("HRMSAdministration") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={HRADMINISTRATION}
                  onClick={hideSidebar}
                >
                  <RiAdminLine size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {permission
                    .filter((list) => list.name === "HRMSAdministration")
                    .map((list) => {
                      return list.displayName;
                    })}</span>
                </NavLink>
              )}
              {permissionNameList.indexOf("SystemManagement") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={SYSTEMMANAGEMENT}
                  onClick={hideSidebar}
                >
                  <AiOutlineSetting size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {permission
                    .filter((list) => list.name === "SystemManagement")
                    .map((list) => {
                      return list.displayName;
                    })}</span>
                </NavLink>
              )}

              {permissionNameList.indexOf("MyWFH") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={APPLYFORWFH}
                  onClick={hideSidebar}
                >
                  <BiHomeSmile size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {permission
                    .filter((list) => list.name === "MyWFH")
                    .map((list) => {
                      return list.displayName;
                    })}</span>
                </NavLink>
              )}

              {(level >= 4 ||
                permissionNameList.indexOf("WFHApplication") !== -1) && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={WFHAPPLICATION}
                  onClick={hideSidebar}
                >
                  <BsNewspaper size={20} /> 
                  <span className="side_nav_content_mobi" >
                  WFH Applications</span>
                </NavLink>
              )}

              {permissionNameList.indexOf("ViewWFH/Leaves") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={VIEWWFHANDLEAVE}
                  onClick={hideSidebar}
                >
                  <IoPeople size={20} />
                  <span className="side_nav_content_mobi" >
                  {permission
                    .filter((list) => list.name === "ViewWFH/Leaves")
                    .map((list) => {
                      return list.displayName;
                    })}</span>
                </NavLink>
              )}
              {permissionNameList.indexOf("MyQueries") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={MYQUERIES}
                  onClick={hideSidebar}
                >
                  <MdOutlineQuestionAnswer size={20} />
                  <span className="side_nav_content_mobi" >
                  {/* Myqueries */}
                  {permission
                    .filter((list) => list.name === "MyQueries")
                    .map((list) => {
                      return list.displayName;
                    })}</span>
                </NavLink>
              )}
              {permissionNameList.indexOf("ViewQueries") !== -1 && (
                <NavLink
                  className="nav-link mt-1"
                  exact
                  to={VIEWQUERIES}
                  onClick={hideSidebar}
                >
                  <BsTable size={20} />{" "}
                  <span className="side_nav_content_mobi" >
                  {permission
                    .filter((list) => list.name === "ViewQueries")
                    .map((list) => {
                      return list.displayName;
                    })}</span>
                </NavLink>
              )}

              <NavLink className="nav-link mt-1" to={APPLICATIONMANAGER}>
                <IoPeopleOutline size={20} />
                <span className="side_nav_content_mobi" >
                  {/* {permission
                      .filter((list) => list.name === "ApplicationManager")
                      .map((list) => {
                        return list.displayName;
                      })} */}
                  Application Manager
                </span>
              </NavLink>
              {/* )} */}
            </Nav>
          </Navbar>
        </Container>
      </div>
    </>
  );
};

SideBarMobile.propTypes = {
  isOpen: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isOpen: state.sidebar.isOpen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideSidebar: hideSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SideBarMobile);
