import { actionTypes } from "./leaveReporingActions";

const {
  USER_LIST_PENDING,
  USER_LIST_SUCCESS,
  USER_LIST_ERROR,

  EMPLOYEE_LEAVE_LIST_PENDING,
  EMPLOYEE_LEAVE_LIST_SUCCESS,
  EMPLOYEE_LEAVE_LIST_ERROR,

  OTHER_SUMMARY_PENDING,
  OTHER_SUMMARY_SUCCESS,
  OTHER_SUMMARY_ERROR,

  EMPLOYEE_SUMMARY_PENDING,
  EMPLOYEE_SUMMARY_SUCCESS,
  EMPLOYEE_SUMMARY_ERROR,

  RESET_DATA,

  SET_FILTER,
  RESET_FILTER,
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING,
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS,
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR,

  HIDE_LEAVESUMMARY_SUCCESS,

  DATERANGE_LEAVELIST_PENDING,
  DATERANGE_LEAVELIST_SUCCESS,
  DATERANGE_LEAVELIST_ERROR,
} = actionTypes;

const initialState = {
  userListPending: false,
  userListSuccess: false,
  userListError: null,

  employeeLeaveListPending: false,
  employeeLeaveListSuccess: false,
  employeeLeaveListError: null,

  otherLeavesSummaryPending: false,
  otherLeavesSummarySuccess: false,
  otherLeavesSummaryError: null,

  employeeLeavesSummaryPending: false,
  employeeLeavesSummarySuccess: false,
  employeeLeavesSummaryError: null,

  optionalHolidaySummaryPending: false,
  optionalHolidaySummarySuccess: false,
  optionalHolidaySummaryError: null,

  optionalHolidaySummary: null,

  showLoader: true,
  setShow: false,

  userList: [],

  employeeLeaveList: [],
  totalEmployeeLeaveList: [],
  currentYear: "",

  dateRangeLeaveListPending: false,
  dateRangeLeaveListSuccess: false,
  dateRangeLeaveListError: null,
  dateRangeLeaveList: [],
  startDate: "",
  endDate: "",
};

export default function leaveReportingReducer(state = initialState, action) {
  const { type, payload } = action;
  // console.log("payload", payload);

  switch (type) {
    case USER_LIST_PENDING:
    case USER_LIST_ERROR:
    case USER_LIST_SUCCESS:
      return Object.assign({}, state, payload);
    case EMPLOYEE_LEAVE_LIST_PENDING:
    case EMPLOYEE_LEAVE_LIST_SUCCESS:
    case EMPLOYEE_LEAVE_LIST_ERROR:
      return Object.assign({}, state, payload);
    case EMPLOYEE_SUMMARY_PENDING:
    case EMPLOYEE_SUMMARY_SUCCESS:
    case EMPLOYEE_SUMMARY_ERROR:
      return Object.assign({}, state, payload);
    case OTHER_SUMMARY_PENDING:
    case OTHER_SUMMARY_SUCCESS:
    case OTHER_SUMMARY_ERROR:
      return Object.assign({}, state, payload);
    case RESET_DATA:
      return Object.assign({}, state, payload);
    case OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING:
    case OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS:
    case OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR:
      return Object.assign({}, state, payload);
    case RESET_FILTER:
      return Object.assign({}, state, {
        employeeLeaveList: state.totalEmployeeLeaveList,
      });
    case SET_FILTER:
      const { status, month } = payload.filterData;
      // console.log(status);
      // console.log(month);
      var newEmployeeLeaveList = [];
      if ((status || status === 0) && month) {
        // console.log("hi");
        newEmployeeLeaveList = state.totalEmployeeLeaveList.filter(
          (list) =>
            list.status === status &&
            new Date(list.startDate).getMonth() + 1 === month
        );
      } else if (month) {
        newEmployeeLeaveList = state.totalEmployeeLeaveList.filter(
          (list) => new Date(list.startDate).getMonth() + 1 === month
        );
      } else if (status || status === 0) {
        newEmployeeLeaveList = state.totalEmployeeLeaveList.filter(
          (list) => list.status === status
        );
      }

      return Object.assign({}, state, {
        employeeLeaveList: newEmployeeLeaveList,
      });

    case HIDE_LEAVESUMMARY_SUCCESS:
      return Object.assign({}, state, payload);

    case DATERANGE_LEAVELIST_PENDING:
    case DATERANGE_LEAVELIST_SUCCESS:
    case DATERANGE_LEAVELIST_ERROR:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}
