import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { ConnectedRouter } from "connected-react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications-component/dist/theme.css";

import App from "./components/pages";
import "./App.scss";
import { Alert } from "./components/common";
import { globals } from "./store/globals";
import { store } from "./store/configure/configureStore";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <Provider store={store}>
    <ReactNotifications />
    <ConnectedRouter history={globals.history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
      <Alert />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
