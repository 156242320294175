import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { globals } from "../globals";
import { searchLunch } from "../lunchType/lunchTypeActions";
import { showLoader, hideLoader } from "../loader/loaderActions";

export const actionTypes = {
  USER_PENDING: "USER_PENDING",
  USER_SUCCESS: "USER_SUCCESS",
  USER_ERROR: "USER_ERROR",

  USER_MANAGER_PENDING: "USER_MANAGER_PENDING",
  USER_MANAGER_SUCCESS: "USER_MANAGER_SUCCESS",
  USER_MANAGER_ERROR: "USER_MANAGER_ERROR",

  USERLIST_PENDING: "USERLIST_PENDING",
  USERLIST_SUCCESS: "USERLIST_SUCCESS",
  USERLIST_ERROR: "USERLIST_ERROR",

  UPDATEUSER_PENDING: "UPDATEUSER_PENDING",
  UPDATEUSER_SUCCESS: "UPDATEUSER_SUCCESS",
  UPDATEUSER_ERROR: "UPDATEUSER_ERROR",

  DELETE_PENDING: "DELETE_PENDING",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_ERROR: "DELETE_ERROR",

  CREATE_PENDING: "CREATE_PENDING",
  CREATE_SUCCESS: "CREATE_SUCCESS",
  CREATE_ERROR: "CREATE_ERROR",

  USERDOC_PENDING: "USERDOC_PENDING",
  USERDOC_SUCCESS: "USERDOC_SUCCESS",
  USERDOC_ERROR: "USERDOC_ERROR",

  UPDATE_PROFILE_PENDING: "UPDATE_PROFILE_PENDING",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",

  FAMILY_MEMBER_LIST_PENDING: "FAMILY_MEMBER_LIST_PENDING",
  FAMILY_MEMBER_LIST_SUCCESS: "FAMILY_MEMBER_LIST_SUCCESS",
  FAMILY_MEMBER_LIST_ERROR: "FAMILY_MEMBER_LIST_ERROR",

  USER_SKILL_LIST_PENDING: "USER_SKILL_LIST_PENDING",
  USER_SKILL_LIST_SUCCESS: "USER_SKILL_LIST_SUCCESS",
  USER_SKILL_LIST_ERROR: "USER_SKILL_LIST_ERROR",
};

export function initDoc() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USERDOC_SUCCESS,
      payload: {
        userDocument: null,
        showInsuranceBox: false,
      },
    });
  };
}

export function getDocument(
  id,
  withLeaveRequest,
  withInsuranceRequest,
  attachementDocumentId
) {
  return (dispatch) => {
    if (withLeaveRequest) {
      const data = {
        employeeId: id,
        description: "attachment_leave_request",
        documentId: attachementDocumentId,
      };
      // console.log(JSON.stringify(data))
      Api.post("userDocuments/getUserDocumentWithDownloadURL", data)
        .then((response) => {
          // console.log(response)
          if (response.data.length > 0) {
            dispatch({
              type: actionTypes.USERDOC_SUCCESS,
              payload: {
                userDocument: response.data[0].document,
                showInsuranceBox: true,
              },
            });
          } else {
            dispatch({
              type: actionTypes.USERDOC_SUCCESS,
              payload: {
                showInsuranceBox: true,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: actionTypes.USERDOC_SUCCESS,
            payload: {
              showInsuranceBox: false,
            },
          });
        });
    }

    if (withInsuranceRequest) {
      const data = {
        employeeId: id,
        description: "insurance_card",
      };
      // console.log(data)
      Api.post("userDocuments/getUserDocuments", data)
        .then((response) => {
          // console.log(response);
          if (response.data.docs.length > 0) {
            dispatch({
              type: actionTypes.USERDOC_SUCCESS,
              payload: {
                userDocument: response.data.docs[0],
                showInsuranceBox: true,
              },
            });
          } else {
            dispatch({
              type: actionTypes.USERDOC_SUCCESS,
              payload: {
                showInsuranceBox: true,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: actionTypes.USERDOC_SUCCESS,
            payload: {
              showInsuranceBox: false,
            },
          });
        });
    }
  };
}

export function removeDocument(id) {
  return (dispatch) => {
    // console.log(id)
    const data = {
      documentId: id,
    };
    Api.post("userDocuments/deleteUserDocument", data)
      .then((response) => {
        // console.log(response)
        if (response.message === "Successfully deleted user document") {
          dispatch({
            type: actionTypes.USERDOC_SUCCESS,
            payload: {
              userDocument: null,
              showInsuranceBox: true,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "User document deleted successfully.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function uploadInsuranceCard(file, id) {
  return (dispatch) => {
    dispatch(showLoader());
    const formData = new FormData();
    formData.append("file", file);
    formData.append("employeeId", id);
    formData.append("description", "insurance_card");
    // console.log(id);
    Api.post("userDocuments/uploadUserDocument", formData)
      .then((response) => {
        // console.log(response)
        if (response.message === "Successfully uploaded user document") {
          dispatch({
            type: actionTypes.USERDOC_SUCCESS,
            payload: {
              userDocument: response.data,
              showInsuranceBox: true,
            },
          });
          dispatch(hideLoader());
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully uploaded user document.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to upload insurance card.",
          })
        );
      });
  };
}

export function downloadInsuranceCard(employeeId) {
  return (dispatch) => {
    const data = {
      employeeId: employeeId,
      description: "insurance_card",
    };
    // console.log(data)
    Api.post("userDocuments/getUserDocumentWithDownloadURL", data)
      .then((response) => {
        // console.log(response)
        const url = response.data[0].downloadURL;
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function downloadFile(employeeId, attachementDocumentId) {
  return (dispatch) => {
    const data = {
      employeeId: employeeId,
      description: "attachment_leave_request",
      documentId: attachementDocumentId,
    };
    // console.log(data)
    Api.post("userDocuments/getUserDocumentWithDownloadURL", data)
      .then((response) => {
        // console.log(response)
        const url = response.data[0].downloadURL;
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function userListFilter(data, page) {
  return (dispatch) => {
    console.log(data);
    console.log(page);
  };
}

export function createUser(data, page) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_PENDING,
      payload: {
        createPending: true,
        createSuccess: false,
        createError: null,
      },
    });

    Api.post("user/create", data)
      .then((response) => {
        // console.log(response)
        if (response.message === "User has been added successfully") {
          dispatch(userList(page, { isActive: "1" }, false));
          dispatch(reportingMangerUsersList());
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "User added successfully.",
            })
          );
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to create user.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USER_ERROR,
          payload: {
            createPending: false,
            createSuccess: true,
            createError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to create user.",
          })
        );
      });
  };
}

export function deleteUser(data, userId, empId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_PENDING,
      payload: {
        deleteUserPending: true,
        deleteUserSuccess: false,
        deleteUserError: null,
      },
    });
    dispatch(deleteUserLunch(empId));

    Api.put(`user/update/${userId}`, data)
      .then((response) => {
        // console.log(response)
        if (response.data) {
          dispatch(userList(1, { isActive: "1" }));
          dispatch(reportingMangerUsersList());
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "User deleted successfully.",
            })
          );
        } else {
          dispatch({
            type: actionTypes.UPDATEUSER_ERROR,
            payload: {
              updateUserPending: false,
              updateUserSuccess: true,
              updateUserError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to delete user.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USER_ERROR,
          payload: {
            updateUserPending: false,
            updateUserSuccess: true,
            updateUserError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to delete user.",
          })
        );
      });
  };
}

export function deleteUserLunch(userId) {
  return (dispatch) => {
    const data = {
      employeeId: userId,
    };
    Api.post("lunch/search", data).then((response) => {
      if (response.data.length > 0) {
        Api.delete(`lunch/delete/${response.data[0]._id}`).then((response) =>
          console.log(response)
        );
      }
    });
  };
}

export function updateUser(data, userId, page, filterData) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATEUSER_PENDING,
      payload: {
        updateUserPending: true,
        updateUserSuccess: false,
        updateUserError: null,
      },
    });

    // console.log(JSON.stringify(data))
    console.log(userId);

    Api.put(`user/update/${userId}`, data)
      .then((response) => {
        // console.log(response)
        if (response.data) {
          dispatch(userList(page, filterData, false));
          dispatch(reportingMangerUsersList());
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "User updated successfully.",
            })
          );
        } else {
          dispatch({
            type: actionTypes.UPDATEUSER_ERROR,
            payload: {
              updateUserPending: false,
              updateUserSuccess: true,
              updateUserError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to update user.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATEUSER_ERROR,
          payload: {
            updateUserPending: false,
            updateUserSuccess: true,
            updateUserError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update user.",
          })
        );
      });
  };
}

export function user(getsearchLunch = false) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_PENDING,
      payload: {
        userPending: true,
        userSuccess: false,
        userError: null,
      },
    });

    const userId = globals.store.getState().auth.loginUser.userId;
    // console.log(userId)
    if (userId) {
      Api.get(`user/${userId}`)
        .then((response) => {
          // console.log(response)
          if (response.data) {
            if (getsearchLunch) {
              dispatch(searchLunch(response.data[0]));
            }
            dispatch({
              type: actionTypes.USER_SUCCESS,
              payload: {
                userPending: false,
                userSuccess: true,
                userError: null,

                user: response.data[0],
              },
            });
          } else {
            dispatch({
              type: actionTypes.USER_ERROR,
              payload: {
                userPending: false,
                userSuccess: true,
                userError: null,

                user: null,
              },
            });
            dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Failed to get user.",
              })
            );
          }
        })
        .catch((err) => {
          // console.log(err);
          dispatch({
            type: actionTypes.USER_ERROR,
            payload: {
              userPending: false,
              userSuccess: true,
              userError: null,

              user: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user.",
            })
          );
        });
    }
  };
}

export function userList(page, data, showLoad = true) {
  // console.log("data1", data);
  return (dispatch) => {
    if (showLoad) {
      dispatch({
        type: actionTypes.USERLIST_PENDING,
        payload: {
          userListPending: true,
          userListSuccess: false,
          userListError: null,
        },
      });
    }
    // console.log("data2", data);
    Api.post(`user/list?limit=${8}&page=${page}`, data)
      .then((response) => {
        // console.log("response", response);

        if (response.data) {
          dispatch({
            type: actionTypes.USERLIST_SUCCESS,
            payload: {
              userListPending: false,
              userListSuccess: true,
              userListError: null,

              userList: response.data.docs,
              hasNextPage: response.data.hasNextPage,
              hasPrevPage: response.data.hasPrevPage,
              limit: response.data.limit,
              nextPage: response.data.nextPage,
              page: response.data.page,
              pagingCounter: response.data.pagingCounter,
              prevPage: response.data.prevPage,
              totalDocs: response.data.totalDocs,
              totalPages: response.data.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.USERLIST_ERROR,
            payload: {
              userListPending: false,
              userListSuccess: true,
              userListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USERLIST_ERROR,
          payload: {
            userListPending: false,
            userListSuccess: true,
            userListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user list.",
          })
        );
      });
  };
}

export function reportingMangerUsersList() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_MANAGER_PENDING,
      payload: {
        userManagerPending: true,
        userManagerSuccess: false,
        userManagerError: null,
      },
    });

    Api.post("user/getReportingMangerUsersList")
      .then((response) => {
        // console.log(response)
        if (response.data) {
          dispatch({
            type: actionTypes.USER_MANAGER_SUCCESS,
            payload: {
              userManagerPending: false,
              userManagerSuccess: true,
              userManagerError: null,

              userManager: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.USER_MANAGER_ERROR,
            payload: {
              userManagerPending: false,
              userManagerSuccess: true,
              userManagerError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get managers.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USER_MANAGER_ERROR,
          payload: {
            userManagerPending: false,
            userManagerSuccess: true,
            userManagerError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get managers.",
          })
        );
      });
  };
}

export function updateProfile(data, userId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PROFILE_PENDING,
      payload: {
        updateUserPending: true,
        updateUserSuccess: false,
        updateUserError: null,
      },
    });

    // console.log(data);

    Api.put(`user/update/${userId}`, data)
      .then((response) => {
        // console.log(response);
        if ((response.data.message = "Successfully updated user record")) {
          dispatch({
            type: actionTypes.UPDATE_PROFILE_SUCCESS,
            payload: {
              updateUserPending: false,
              updateUserSuccess: true,
              updateUserError: null,
              data: response.data.personalDetails,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Profile updated successfully.",
            })
          );
        } else {
          dispatch({
            type: actionTypes.UPDATE_PROFILE_ERROR,
            payload: {
              updateUserPending: false,
              updateUserSuccess: true,
              updateUserError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to update user.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATEUSER_ERROR,
          payload: {
            updateUserPending: false,
            updateUserSuccess: true,
            updateUserError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to update profile.",
          })
        );
      });
  };
}

export function getFamilyMemberList(empId) {
  return (dispatch) => {
    // console.log(empId);
    const data = {
      employeeId: empId,
    };

    // FAMILY_MEMBER_LIST_PENDING: "FAMILY_MEMBER_LIST_PENDING",
    // FAMILY_MEMBER_LIST_SUCCESS: "FAMILY_MEMBER_LIST_SUCCESS",
    // FAMILY_MEMBER_LIST_ERROR: "FAMILY_MEMBER_LIST_ERROR",

    dispatch({
      type: actionTypes.FAMILY_MEMBER_LIST_PENDING,
      payload: {
        familyMemberListPending: true,
        familyMemberListSuccess: false,
        familyMemberListError: null,
      },
    });
    Api.post(`employeeFamilyMembers/list`, data)
      .then((response) => {
        console.log("response", response);
        console.log("data", data);

        if (response.data.length > 0) {
          dispatch({
            type: actionTypes.FAMILY_MEMBER_LIST_PENDING,
            payload: {
              familyMemberListPending: false,
              familyMemberListSuccess: true,
              familyMemberListError: null,

              familyMemberList: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.FAMILY_MEMBER_LIST_SUCCESS,
            payload: {
              familyMemberListPending: false,
              familyMemberListSuccess: true,
              familyMemberListError: null,

              familyMemberList: [],
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.FAMILY_MEMBER_LIST_ERROR,
          payload: {
            familyMemberListPending: false,
            familyMemberListSuccess: true,
            familyMemberListError: null,

            familyMemberList: [],
          },
        });
      });
  };
}

export function getUserSkillList(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_SKILL_LIST_PENDING,
      payload: {
        userSkillListPending: true,
        userSkillListSuccess: false,
        userSkillListError: null,
      },
    });
    dispatch(showLoader());
    Api.post("userSkills/getUserSkillsWithFilter/list?page=1&limit=2", id)
      .then((response) => {
        console.log("myresponse", response);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.USER_SKILL_LIST_SUCCESS,
          payload: {
            userSkillListPending: false,
            userSkillListSuccess: true,
            userSkillListError: null,

            userSkillList: response.data.results,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.USER_SKILL_LIST_ERROR,
          payload: {
            userSkillListPending: false,
            userSkillListSuccess: true,
            userSkillListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}
