import React, { Component } from "react";
import { bindActionCreators } from "redux";
// import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import "./ImpDocumentsStyles.scss";
import ImpDocumentsView from "./ImpDocumentsView";
import { Loader } from "../../common";
import {
  getDocumentCategoryList,
  getCategoryDocumentList,
  downloadDocument,
} from "../../../store/documents/documentsActions";
import { user as getUser } from "../../../store/user/userActions";

class ImpDocumentsContainer extends Component {
  constructor(props) {
    super(props);

    this.props.getUser();
    this.props.getDocumentCategoryList();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      userDocumentsListSuccess,
      userDocumentsList,
      userSuccess,
      loginUserData,
      getCategoryDocumentList,

      categoryDocumentsListSuccess,
      categoryDocumentsList,
      downloadDocument,
    } = this.props;

    if (userDocumentsListSuccess === false || userSuccess === false) {
      return <Loader />;
    }
    return (
      <ImpDocumentsView
        userDocumentsList={userDocumentsList}
        loginUserData={loginUserData}
        getCategoryDocumentList={getCategoryDocumentList}
        categoryDocumentsListSuccess={categoryDocumentsListSuccess}
        categoryDocumentsList={categoryDocumentsList}
        downloadDocument={downloadDocument}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userDocumentsListSuccess: state.userDocuments.userDocumentsListSuccess,
  userDocumentsList: state.userDocuments.userDocumentsList,

  loginUserData: state.user.user,
  userSuccess: state.user.userSuccess,

  categoryDocumentsList: state.userDocuments.categoryDocumentsList,
  categoryDocumentsListSuccess:
    state.userDocuments.categoryDocumentsListSuccess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDocumentCategoryList,
      getUser,
      getCategoryDocumentList,
      downloadDocument,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpDocumentsContainer);
