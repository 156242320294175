import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
// import { setApplicationManager } from "../applicationmanagerType/applicationmanagerTypeActions";
//import { user } from "../user/userActions";

export const actionTypes = {
  APPLICATION_PENDING: "APPLICATION_PENDING",
  APPLICATION_SUCCESS: "APPLICATION_SUCCESS",
  APPLICATION_ERROR: "APPLICATION_ERROR",

  APPLICATIONMANAGER_PENDING: "APPLICATIONMANAGER_PENDING",
  APPLICATIONMANAGER_SUCCESS: "APPLICATIONMANAGER_SUCCESS",
  APPLICATIONMANAGER_ERROR: "APPLICATIONMANAGER_ERROR",

  GET_USER_APPLICATION_MANAGER_PENDING: "GET_USER_APPLICATION_MANAGER_PENDING",
  GET_USER_APPLICATION_MANAGER_SUCCESS: "GET_USER_APPLICATION_MANAGER_SUCCESS",
  GET_USER_APPLICATION_MANAGER_ERROR: "GET_USER_APPLICATION_MANAGER_ERROR",

  APPLICATION_ADD_PENDING: "APPLICATION_ADD_PENDING",
  APPLICATION_ADD_ERROR: "APPLICATION_ADD_ERROR",
  APPLICATION_ADD_SUCCESS: "APPLICATION_ADD_SUCCESS",

  APPLICATION_EDIT_PENDING: "APPLICATION_EDIT_PENDING",
  APPLICATION_EDIT_ERROR: "APPLICATION_EDIT_ERROR",
  APPLICATION_EDIT_SUCCESS: "APPLICATION_EDIT_SUCCESS",

  APPLICATION_DELETE_PENDING: "APPLICATION_DELETE_PENDING",
  APPLICATION_DELETE_ERROR: "APPLICATION_DELETE_ERROR",
  APPLICATION_DELETE_SUCCESS: "APPLICATION_DELETE_SUCCESS",

  // APPLICATIONCOUNT_PENDING: "APPLICATIONCOUNT_PENDING",
  // APPLICATIONCOUNT_SUCCESS: "APPLICATIONCOUNT_SUCCESS",
  // APPLICATIONCOUNT_ERROR: "APPLICATIONCOUNT_ERROR",

  // SET_APPLICATIONMANAGERLIST: "SET_APPLICATIONMANAGERLIST",
};

export function addApplicationManager(name, description, link) {
  return (dispatch) => {
    console.log(name, "name");
    console.log(description, "description");
    console.log(link, "link");
    dispatch({
      type: actionTypes.APPLICATION_ADD_PENDING,
      payload: {
        applicationAddPending: true,
        applicationAddSuccess: false,
        applicationAddError: null,
      },
    });

    const data = {
      name: name,
      description: description,
      link: link,
    };

    console.log("data", data);
    Api.post("application/create", data)

      .then((response) => {
        console.log("response", response);
        if (response.message === "Successfully added new application") {
          // console.log(response.message, "response.message");
          dispatch({
            type: actionTypes.APPLICATION_ADD_SUCCESS,
            payload: {
              applicationAddPending: false,
              applicationAddSuccess: true,
              applicationAddError: null,
              application: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new application.",
            })
          );
        }
        dispatch(getApplicationManagerList());
      })

      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.APPLICATION_ADD_ERROR,
          payload: {
            applicationAddPending: false,
            applicationAddSuccess: true,
            applicationAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add application.",
          })
        );
      });
  };
}

export function editApplicationManager(name, description, link, id) {
  return (dispatch) => {
    console.log(name, "name");
    console.log(description, "description");
    console.log(link, "link");
    dispatch({
      type: actionTypes.APPLICATION_EDIT_PENDING,
      payload: {
        applicationEditPending: true,
        applicationEditSuccess: false,
        applicationEditError: null,
      },
    });

    const data = {
      name: name,
      description: description,
      link: link,
      id: id,
    };
    console.log("data", data);
    Api.put(`application/update/${id}`, data)

      .then((response) => {
        console.log(response, "response");
        if (response.data) {
          console.log("edit");
          dispatch({
            type: actionTypes.APPLICATION_EDIT_SUCCESS,
            payload: {
              applicationEditPending: false,
              applicationEditSuccess: true,
              applicationEditError: null,

              application: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated application.",
            })
          );
        }
        dispatch(getApplicationManagerList());
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.APPLICATION_EDIT_ERROR,
          payload: {
            applicationEditPending: false,
            applicationEditSuccess: true,
            applicationEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit application.",
          })
        );
      });
  };
}

export function deleteApplicationManager(id) {
  return (dispatch) => {
    console.log(id, "id");
    dispatch({
      type: actionTypes.APPLICATION_DELETE_PENDING,
      payload: {
        applicationDeletePending: true,
        applicationDeleteSuccess: false,
        applicationDeleteError: null,
      },
    });
    // const data = {
    //   id: id,
    // };
    console.log("id", id);
    Api.delete(`application/delete/${id}`)
      .then((response) => {
        console.log(response);
        if (response.message === "Successfully deleted application") {
          dispatch({
            type: actionTypes.APPLICATION_DELETE_SUCCESS,
            payload: {
              applicationDeletePending: false,
              applicationDeleteSuccess: true,
              applicationDeleteError: null,

              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted application.",
            })
          );
        }
        dispatch(getApplicationManagerList());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.APPLICATION_DELETE_ERROR,
          payload: {
            applicationDeletePending: false,
            applicationDeleteSuccess: true,
            applicationDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add application.",
          })
        );
      });
  };
}

//

export function getApplicationManagerList(showLoad = true) {
  // console.log("data", data);

  // const data1 = { employeeId, status, year };

  return (dispatch) => {
    if (showLoad) {
      dispatch({
        type: actionTypes.GET_USER_APPLICATION_MANAGER_PENDING,
        payload: {
          getUserApplicationManagerListPending: true,
          getUserApplicationManagerListSuccess: false,
          getUserApplicationManagerListError: null,
        },
      });
    }

    Api.get(`application/list`)

      .then((response) => {
        // console.log("data3", response.data);

        if (response.data) {
          console.log(response.data, "dataresp");
          dispatch({
            type: actionTypes.GET_USER_APPLICATION_MANAGER_SUCCESS,
            payload: {
              getUserApplicationManagerListPending: false,
              getUserApplicationManagerListSuccess: true,
              getUserApplicationManagerListError: null,
              // userWfhData:response.data,
              getUserApplicationManagerList: response.data,
              // hasNextPage: response.data.hasNextPage,
              // hasPrevPage: response.data.hasPrevPage,
              // limit: response.data.limit,
              // nextPage: response.data.nextPage,
              // page: response.data.page,
              // pagingCounter: response.data.pagingCounter,
              // prevPage: response.data.prevPage,
              // totalDocs: response.data.totalDocs,
              // totalPages: response.data.totalPages,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_USER_APPLICATION_MANAGER_ERROR,
            payload: {
              getUserApplicationManagerListPending: false,
              getUserApplicationManagerListSuccess: true,
              getUserApplicationManagerListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user application manager list.",
            })
          );
        }
        console.log(response.data, "dataresp else");
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.GET_USER_APPLICATION_MANAGER_ERROR,
          payload: {
            getUserApplicationManagerListPending: false,
            getUserApplicationManagerListSuccess: true,
            getUserApplicationManagerListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user application manager list.",
          })
        );
      });
  };
}

// export function getApplicationManagerList(data, showLoad = true) {
//   // console.log("data", data);

//   // const data1 = { employeeId, status, year };

//   return (dispatch) => {
//     if (showLoad) {
//       dispatch({
//         type: actionTypes.GET_USER_APPLICATION_MANAGER_PENDING,
//         payload: {
//           getUserApplicationManagerListPending: true,
//           getUserApplicationManagerListSuccess: false,
//           getUserApplicationManagerListError: null,
//         },
//       });
//     }

//     Api.get(`application/list`)

//       .then((response) => {
//         // console.log("data3", response.data);

//         if (response.data) {
//           dispatch({
//             type: actionTypes.GET_USER_APPLICATION_MANAGER_SUCCESS,
//             payload: {
//               getUserApplicationManagerListPending: false,
//               getUserApplicationManagerListSuccess: true,
//               getUserApplicationManagerListError: null,
//               // userWfhData:response.data,
//               getUserApplicationManagerList: response.data,
//               // hasNextPage: response.data.hasNextPage,
//               // hasPrevPage: response.data.hasPrevPage,
//               // limit: response.data.limit,
//               // nextPage: response.data.nextPage,
//               // page: response.data.page,
//               // pagingCounter: response.data.pagingCounter,
//               // prevPage: response.data.prevPage,
//               // totalDocs: response.data.totalDocs,
//               // totalPages: response.data.totalPages,
//             },
//           });
//         } else {
//           dispatch({
//             type: actionTypes.GET_USER_APPLICATION_MANAGER_ERROR,
//             payload: {
//               getUserApplicationManagerListPending: false,
//               getUserApplicationManagerListSuccess: true,
//               getUserApplicationManagerListError: null,
//             },
//           });
//           dispatch(
//             showAlert({
//               isOpen: true,
//               title: "Error",
//               type: "danger",
//               msg: "Failed to get user application manager list.",
//             })
//           );
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({
//           type: actionTypes.GET_USER_APPLICATION_MANAGER_ERROR,
//           payload: {
//             getUserApplicationManagerListPending: false,
//             getUserApplicationManagerListSuccess: true,
//             getUserApplicationManagerListError: null,
//           },
//         });
//         dispatch(
//           showAlert({
//             isOpen: true,
//             title: "Error",
//             type: "danger",
//             msg: "Failed to get user application manager list.",
//           })
//         );
//       });
//   };
// }

// export function applicationmanager(
//   applicationType,
//   setApplicationManagerList = false,
//   userData = false,
//   userApplication = false
// ) {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.APPLICATIONMANAGERTYPE_PENDING,
//       payload: {
//         applicationmanagerTypePending: true,
//         applicationmanagerTypeSuccess: false,
//         applicationmanagerTypeError: null,
//       },
//     });

//     Api.get("application/list")
//       .then((response) => {
//         // console.log(response)

//         if (response.data) {
//           if (userData) {
//             var applicationmanagerTypeList = [];

//             response.data.forEach((applicationmanagerType) => {
//               if (
//                 applicationmanagerType.location.indexOf(
//                   userData.location[0]._id
//                 ) !== -1
//               ) {
//                 applicationmanagerTypeList.push(applicationmanagerType);
//               }
//             });

//             dispatch({
//               type: actionTypes.APPLICATIONMANAGERTYPE_SUCCESS,
//               payload: {
//                 applicationmanagerTypePending: false,
//                 applicationmanagerTypeSuccess: true,
//                 applicationmanagerTypeError: null,

//                 applicationmanagerTypeList: applicationmanagerTypeList,
//                 allApplicationManagerTypeList: response.data,
//                 selectedApplicationManagerType: userApplication,
//               },
//             });
//           } else {
//             dispatch({
//               type: actionTypes.APPLICATIONMANAGERTYPE_SUCCESS,
//               payload: {
//                 applicationmanagerTypePending: false,
//                 applicationmanagerTypeSuccess: true,
//                 applicationmanagerTypeError: null,
//                 allApplicationManagerTypeList: response.data,
//               },
//             });
//           }
//         } else {
//           dispatch({
//             type: actionTypes.APPLICATIONMANAGERTYPE_ERROR,
//             payload: {
//               applicationmanagerTypePending: false,
//               applicationmanagerTypeSuccess: true,
//               applicationmanagerTypeError: null,
//             },
//           });
//           dispatch(
//             showAlert({
//               isOpen: true,
//               title: "Error",
//               type: "danger",
//               msg: "Failed to get applicationmanager list.",
//             })
//           );
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({
//           type: actionTypes.APPLICATIONMANAGERTYPE_ERROR,
//           payload: {
//             applicationmanagerTypePending: false,
//             applicationmanagerTypeSuccess: true,
//             applicationmanagerTypeError: null,
//           },
//         });
//         dispatch(
//           showAlert({
//             isOpen: true,
//             title: "Error",
//             type: "danger",
//             msg: "Failed to get applicationmanager list.",
//           })
//         );
//       });
//   };
// }

// export function setApplicationManager(applicationmanagerTypeId) {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.APPLICATIONMANAGERTYPE_SUCCESS,
//       payload: {
//         selectedApplicationManagerType: applicationmanagerTypeId,
//       },
//     });
//     dispatch(
//       showAlert({
//         isOpen: true,
//         title: "Success",
//         type: "success",
//         msg: "ApplicationManager data updated successfully.",
//       })
//     );
//   };
// }

// export function lunchCount(userId) {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.LUNCHCOUNT_PENDING,
//       payload: {
//         lunchCountPending: true,
//         lunchCountSuccess: false,
//         lunchCountError: null,

//         lunchCount: "",
//       },
//     });

//     const data = {
//       employeeId: userId,
//     };

//     Api.post("lunch/count", data)
//       .then((response) => {
//         var dataString = JSON.stringify(response.data[0]);
//         var result = dataString.substring(1, dataString.length - 1);
//         dispatch({
//           type: actionTypes.LUNCHCOUNT_SUCCESS,
//           payload: {
//             lunchCountPending: false,
//             lunchCountSuccess: true,
//             lunchCountError: null,

//             lunchCount: result.replace(/,/g, ", "),
//           },
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({
//           type: actionTypes.LUNCHCOUNT_ERROR,
//           payload: {
//             lunchCountPending: false,
//             lunchCountSuccess: true,
//             lunchCountError: null,
//           },
//         });
//         dispatch(
//           showAlert({
//             isOpen: true,
//             title: "error",
//             type: "danger",
//             msg: "Failed to get lunch count.",
//           })
//         );
//       });
//   };
// }

// function showLunchError(id) {
//   return (dispatch) => {
//     Api.get(`lunchPreferenceSettings/list/${id}`).then((response) => {
//       // console.log(response);
//       dispatch(
//         showAlert({
//           isOpen: true,
//           title: "Error",
//           type: "danger",
//           msg: `You can't edit lunch setting ${response.data[0].bookingCloseStartTime} - ${response.data[0].bookingCloseEndTime}.`,
//         })
//       );
//     });
//   };
// }

// export function lunchPreference(
//   lunchType,
//   userId,
//   userData,
//   setLunchList = false
// ) {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.LUNCHPREFERENCE_PENDING,
//       payload: {
//         lunchPreferencePending: true,
//         lunchPreferenceSuccess: false,
//         lunchPreferenceError: null,
//       },
//     });
//     if (userData) {
//       const data = {
//         lunchPreference: lunchType._id,
//         employeeId: userId,
//       };

//       var today = new Date();
//       var time =
//         today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
//       console.log(time);
//       Api.post("lunchPreferenceSettings/canUpdateLunchPreference", {
//         updateTime: time,
//         location: userData[0]._id,
//       })
//         .then((response) => {
//           console.log(response);
//           if (response.data.canUpdateLunchPreference) {
//             Api.post("lunch/create", data)
//               .then((response) => {
//                 // console.log(response)
//                 dispatch({
//                   type: actionTypes.LUNCHPREFERENCE_SUCCESS,
//                   payload: {
//                     lunchPreferencePending: false,
//                     lunchPreferenceSuccess: true,
//                     lunchPreferenceError: null,
//                   },
//                 });

//                 if (setLunchList) {
//                   dispatch({
//                     type: actionTypes.SET_LUNCHLIST,
//                     payload: {
//                       lunchType: lunchType,
//                       userId: response.data._id,
//                     },
//                   });
//                   dispatch(
//                     showAlert({
//                       isOpen: true,
//                       title: "Success",
//                       type: "success",
//                       msg: "Lunch data updated successfully.",
//                     })
//                   );
//                 } else {
//                   dispatch(setLunchPreference(lunchType._id));
//                 }
//               })
//               .catch((err) => {
//                 console.log(err);
//                 dispatch({
//                   type: actionTypes.LUNCHPREFERENCE_ERROR,
//                   payload: {
//                     lunchPreferencePending: false,
//                     lunchPreferenceSuccess: true,
//                     lunchPreferenceError: null,
//                   },
//                 });
//                 dispatch(
//                   showAlert({
//                     isOpen: true,
//                     title: "Error",
//                     type: "danger",
//                     msg: "Failed to set lunch preference.",
//                   })
//                 );
//                 dispatch(user());
//               });
//           } else {
//             dispatch(showLunchError(userData[0]._id));
//           }
//         })
//         .catch((err) => {
//           dispatch(
//             showAlert({
//               isOpen: true,
//               title: "Error",
//               type: "danger",
//               msg: "Failed to set lunch.",
//             })
//           );
//         });
//     } else {
//       const data = {
//         lunchPreference: lunchType._id,
//         employeeId: userId,
//       };
//       Api.post("lunch/create", data)
//         .then((response) => {
//           // console.log(response)
//           dispatch({
//             type: actionTypes.LUNCHPREFERENCE_SUCCESS,
//             payload: {
//               lunchPreferencePending: false,
//               lunchPreferenceSuccess: true,
//               lunchPreferenceError: null,
//             },
//           });

//           if (setLunchList) {
//             dispatch({
//               type: actionTypes.SET_LUNCHLIST,
//               payload: {
//                 lunchType: lunchType,
//                 userId: response.data._id,
//               },
//             });
//             dispatch(
//               showAlert({
//                 isOpen: true,
//                 title: "Success",
//                 type: "success",
//                 msg: "Lunch data updated successfully.",
//               })
//             );
//           } else {
//             dispatch(setLunchPreference(lunchType._id));
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//           dispatch({
//             type: actionTypes.LUNCHPREFERENCE_ERROR,
//             payload: {
//               lunchPreferencePending: false,
//               lunchPreferenceSuccess: true,
//               lunchPreferenceError: null,
//             },
//           });
//           dispatch(
//             showAlert({
//               isOpen: true,
//               title: "Error",
//               type: "danger",
//               msg: "Failed to set lunch preference.",
//             })
//           );
//           dispatch(user());
//         });
//     }
//   };
// }

// export function list(page) {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.LUNCH_PENDING,
//       payload: {
//         lunchPending: true,
//         lunchSuccess: false,
//         lunchError: null,
//       },
//     });

//     Api.post(`lunch/list?limit=${8}&page=${page}`)
//       .then((response) => {
//         // console.log(response)
//         if (response.data) {
//           dispatch({
//             type: actionTypes.LUNCH_SUCCESS,
//             payload: {
//               lunchPending: false,
//               lunchSuccess: true,
//               lunchError: null,

//               lunchList: response.data.docs,
//               hasNextPage: response.data.hasNextPage,
//               hasPrevPage: response.data.hasPrevPage,
//               limit: response.data.limit,
//               nextPage: response.data.nextPage,
//               page: response.data.page,
//               pagingCounter: response.data.pagingCounter,
//               prevPage: response.data.prevPage,
//               totalDocs: response.data.totalDocs,
//               totalPages: response.data.totalPages,
//             },
//           });
//         } else {
//           dispatch({
//             type: actionTypes.LUNCH_ERROR,
//             payload: {
//               lunchPending: false,
//               lunchSuccess: true,
//               lunchError: null,

//               lunchList: [],
//             },
//           });
//           dispatch(
//             showAlert({
//               isOpen: true,
//               title: "Error",
//               type: "danger",
//               msg: "Failed to get lunch list.",
//             })
//           );
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch({
//           type: actionTypes.LUNCH_ERROR,
//           payload: {
//             lunchPending: false,
//             lunchSuccess: true,
//             lunchError: null,

//             lunchList: [],
//           },
//         });
//         dispatch(
//           showAlert({
//             isOpen: true,
//             title: "Error",
//             type: "danger",
//             msg: "Failed to get lunch list.",
//           })
//         );
//       });
//   };
// }

// export function deleteLunchPreference(empId) {
//   return (dispatch) => {
//     //  console.log(id)
//     const data = {
//       employeeId: empId,
//     };
//     Api.post("lunch/search", data).then((response) => {
//       // console.log(response)
//       if (response.data.length > 0) {
//         Api.delete(`lunch/delete/${response.data[0]._id}`)
//           .then((response) => {
//             dispatch(
//               showAlert({
//                 isOpen: true,
//                 title: "Success",
//                 type: "success",
//                 msg: "Lunch data updated successfully.",
//               })
//             );
//             dispatch(list(1));
//           })
//           .catch((err) => console.log(err));
//       }
//     });
//   };
// }
