import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  TECHDOMAIN_PENDING: "TECHDOMAIN_PENDING",
  TECHDOMAIN_SUCCESS: "TECHDOMAIN_SUCCESS",
  TECHDOMAIN_ERROR: "TECHDOMAIN_ERROR",

  TECHDOMAIN_ADD_PENDING: "TECHDOMAIN_ADD_PENDING",
  TECHDOMAIN_ADD_ERROR: "TECHDOMAIN_ADD_ERROR",
  TECHDOMAIN_ADD_SUCCESS: "TECHDOMAIN_ADD_SUCCESS",

  TECHDOMAIN_EDIT_PENDING: "TECHDOMAIN_EDIT_PENDING",
  TECHDOMAIN_EDIT_ERROR: "TECHDOMAIN_EDIT_ERROR",
  TECHDOMAIN_EDIT_SUCCESS: "TECHDOMAIN_EDIT_SUCCESS",

  TECHDOMAIN_DELETE_PENDING: "TECHDOMAIN_DELETE_PENDING",
  TECHDOMAIN_DELETE_ERROR: "TECHDOMAIN_DELETE_ERROR",
  TECHDOMAIN_DELETE_SUCCESS: "TECHDOMAIN_DELETE_SUCCESS",
};

export function addTechDomain(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TECHDOMAIN_ADD_PENDING,
      payload: {
        techDomainAddPending: true,
        techDomainAddSuccess: false,
        techDomainAddError: null,
      },
    });
    // console.log(data)

    Api.post("recruitmentManagement/hradmin/addTechnicalDomain", data)
      .then((response) => {
        // console.log(response)
        if (response.message === "Successfully added new technical domain") {
          dispatch({
            type: actionTypes.TECHDOMAIN_ADD_SUCCESS,
            payload: {
              techDomainAddPending: false,
              techDomainAddSuccess: true,
              techDomainAddError: null,

              techDomain: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added new technical domain.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.TECHDOMAIN_ADD_ERROR,
          payload: {
            techDomainAddPending: false,
            techDomainAddSuccess: true,
            techDomainAddError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add holiday.",
          })
        );
      });
  };
}

export function editTechDomain(data, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TECHDOMAIN_EDIT_PENDING,
      payload: {
        techDomainEditPending: true,
        techDomainEditSuccess: false,
        techDomainEditError: null,
      },
    });

    Api.put(`recruitmentManagement/hradmin/updateTechnicalDomain/${id}`, data)
      .then((response) => {
        if (response.message === "Successfully updated  technical domain") {
          dispatch({
            type: actionTypes.TECHDOMAIN_EDIT_SUCCESS,
            payload: {
              techDomainEditPending: false,
              techDomainEditSuccess: true,
              techDomainEditError: null,

              techDomain: response.data,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated technical domain.",
            })
          );
        }
      })
      .catch((err) => {
        // console.log(err)
        dispatch({
          type: actionTypes.TECHDOMAIN_EDIT_ERROR,
          payload: {
            techDomainEditPending: false,
            techDomainEditSuccess: true,
            techDomainEditError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to edit holiday.",
          })
        );
      });
  };
}

export function deleteTechDomain(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TECHDOMAIN_DELETE_PENDING,
      payload: {
        techDomainDeletePending: true,
        techDomainDeleteSuccess: false,
        techDomainDeleteError: null,
      },
    });

    // console.log(id)

    Api.delete(`recruitmentManagement/hradmin/deleteTechnicalDomain/${id}`)
      .then((response) => {
        if (response.message === "Successfully deleted  technical domain") {
          dispatch({
            type: actionTypes.TECHDOMAIN_DELETE_SUCCESS,
            payload: {
              techDomainDeletePending: false,
              techDomainDeleteSuccess: true,
              techDomainDeleteError: null,

              id: id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully deleted technical domain.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.TECHDOMAIN_DELETE_ERROR,
          payload: {
            techDomainDeletePending: false,
            techDomainDeleteSuccess: true,
            techDomainDeleteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to add holiday.",
          })
        );
      });
  };
}

export function list() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TECHDOMAIN_PENDING,
      payload: {
        techDomainPending: true,
        techDomainSuccess: false,
        techDomainError: null,
      },
    });

    Api.post("recruitmentManagement/hradmin/getTechnicalDomainsList")
      .then((response) => {
        if (response.message === "Successfully fetched technical domain list") {
          dispatch({
            type: actionTypes.TECHDOMAIN_SUCCESS,
            payload: {
              techDomainPending: false,
              techDomainSuccess: true,
              techDomainError: null,

              techDomainList: response.data,
            },
          });
        }
        dispatch({
          type: actionTypes.TECHDOMAIN_SUCCESS,
          payload: {
            techDomainPending: false,
            techDomainSuccess: true,
            techDomainError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.TECHDOMAIN_ERROR,
          payload: {
            techDomainPending: false,
            techDomainSuccess: true,
            techDomainError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get techDomain list.",
          })
        );
      });
  };
}
