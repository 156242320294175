import React, { useState } from "react";
import moment from "moment";
import { Modal, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import { DataTable } from "../../../common";
import { ButtonView } from "../../../controls";

const Completed = ({ data, downloadJobApplicationDocument }) => {
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);

  const columns = [
    {
      name: "Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.interviewDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Start",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {((parseInt(row.startTime) + 11) % 12) + 1}:
          {row.startTime.split(":")[1]}{" "}
          {parseInt(row.startTime) >= 12 ? "PM" : "AM"}
        </span>
      ),
    },
    {
      name: "End",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {((parseInt(row.endTime) + 11) % 12) + 1}:{row.endTime.split(":")[1]}{" "}
          {parseInt(row.endTime) >= 12 ? "PM" : "AM"}
        </span>
      ),
    },
    {
      name: "Candidate",
      center: true,
      grow: 4,
      cell: (row) => {
        return (
          <span>
            {row.applicationId.firstName && row.applicationId.firstName}{" "}
            {row.applicationId.lastName &&
              row.applicationId.lastName.slice(0, 1)}
          </span>
        );
      },
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => <span className="badge badge-warning">Completed</span>,
    },
    {
      name: "Detail",
      center: true,
      grow: 4,
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              handleViewOpen(row);
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
  ];

  const handleViewOpen = (row) => {
    setViewDetails(row);
    setViewDetailsShow(true);
  };

  const handleClose = () => {
    setViewDetailsShow(false);
  };

  return (
    <>
      <div className="table_content px-2 attendance_table pb-3">
        <DataTable
          data={data}
          columns={columns}
          pagination={true}
          paginationPerPage={5}
          paginationServer={false}
        />
      </div>

      {viewDetails && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Interview Detail</Modal.Title>

              <div className="d-flex align-items-center">
                <div className="me-2 d-none d-md-block">
                  <ButtonView
                    onClick={() =>
                      downloadJobApplicationDocument(
                        viewDetails.applicationId._id
                      )
                    }
                    title={"Download Resume"}
                    styles="secondary"
                  />
                </div>

                <div onClick={handleClose} style={{ cursor: "pointer" }}>
                  <AiOutlineClose size={20} />
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="interview_schedule_detail ms-1">
                <div className="mb-3 d-block d-md-none">
                  <ButtonView
                    onClick={() =>
                      downloadJobApplicationDocument(
                        viewDetails.applicationId._id
                      )
                    }
                    title={"Download Resume"}
                    styles="secondary"
                  />
                </div>
                <h5>Round: {viewDetails.roundNumber}</h5>
                <h5>
                  Interviewer(s):{" "}
                  {viewDetails.interviewers.map((list, id, array) => {
                    if (id === array.length - 1) {
                      return (
                        <span key={list._id}>
                          {list.userId[0].firstName} {list.userId[0].lastName}{" "}
                        </span>
                      );
                    } else {
                      return (
                        <span key={list._id}>
                          {list.userId[0].firstName} {list.userId[0].lastName},{" "}
                        </span>
                      );
                    }
                  })}
                </h5>
                <div className="d-flex flex-wrap">
                  <h5 className="me-3">
                    Date:{" "}
                    {moment(new Date(viewDetails.interviewDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </h5>
                  <h5>
                    Time: {((parseInt(viewDetails.startTime) + 11) % 12) + 1}:
                    {viewDetails.startTime.split(":")[1]}{" "}
                    {parseInt(viewDetails.startTime) >= 12 ? "PM" : "AM"} to{" "}
                    {((parseInt(viewDetails.endTime) + 11) % 12) + 1}:
                    {viewDetails.endTime.split(":")[1]}{" "}
                    {parseInt(viewDetails.endTime) >= 12 ? "PM" : "AM"}
                  </h5>
                </div>
              </div>
              <ul className="list-group interview_schedule_detail_list">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Application Status:
                  <span
                    style={{ fontSize: "15px" }}
                    className="badge badge-hold"
                  >
                    {viewDetails.applicationId.status}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Name:
                  <span>
                    {viewDetails.applicationId.firstName}{" "}
                    {viewDetails.applicationId.middleName &&
                      viewDetails.applicationId.middleName}{" "}
                    {viewDetails.applicationId.lastName &&
                      viewDetails.applicationId.lastName}
                  </span>
                </li>
                {viewDetails.applicationId.primaryTechnicalDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Prime Domain:
                    <span>
                      {viewDetails.applicationId.primaryTechnicalDomains[0]}
                    </span>
                  </li>
                ) : null}
                {viewDetails.applicationId.secondaryTechnicalDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    Secondary Domain:
                    <span style={{ textAlign: "right" }}>
                      {viewDetails.applicationId.secondaryTechnicalDomains.map(
                        (list, id, array) => {
                          if (
                            viewDetails.applicationId.secondaryTechnicalDomains
                              .length > 1
                          ) {
                            if (id === array.length - 1) {
                              return list;
                            } else {
                              return (
                                <>
                                  {list}, {(id + 1) % 3 === 0 ? <br /> : null}
                                </>
                              );
                            }
                          } else {
                            return list;
                          }
                        }
                      )}
                    </span>
                  </li>
                ) : null}
                {/* {viewDetails.applicationId.skills && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Skills:
                    <span>{viewDetails.applicationId.skills}</span>
                  </li>
                )} */}
                {viewDetails.applicationId.applicationDomains.length ? (
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    Application Domain:
                    <span style={{ textAlign: "right" }}>
                      {viewDetails.applicationId.applicationDomains.map(
                        (list, id, array) => {
                          if (
                            viewDetails.applicationId.secondaryTechnicalDomains
                              .length > 1
                          ) {
                            if (id === array.length - 1) {
                              return list;
                            } else {
                              return (
                                <>
                                  {list}, {(id + 1) % 3 === 0 ? <br /> : null}
                                </>
                              );
                            }
                          } else {
                            return list;
                          }
                        }
                      )}
                    </span>
                  </li>
                ) : null}
                {viewDetails.applicationId.designationAppliedFor && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Applied Designation:
                    <span>
                      {viewDetails.applicationId.designationAppliedFor}
                    </span>
                  </li>
                )}

                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Experience:
                  <span>
                    {" "}
                    {(viewDetails.applicationId.experienceInMonths / 12) | 0}.
                    {viewDetails.applicationId.experienceInMonths % 12} yrs
                  </span>
                </li>
                {viewDetails.applicationId.qualification && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Qualification:
                    <span>{viewDetails.applicationId.qualification}</span>
                  </li>
                )}
                {viewDetails.applicationId.passingYear && (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Passing Year:
                    <span>{viewDetails.applicationId.passingYear}</span>
                  </li>
                )}
              </ul>
              <div className="interview_schedule_detail">
                <h5 className="ms-2 mt-2">Outcome: {viewDetails.outcome}</h5>
              </div>
              <div>
                <div className="interview_schedule_detail">
                  {/* <h5>Remarks:</h5> */}
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    // disabled
                    readOnly
                    value={viewDetails.outcomeComments}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
              </div>
              <div className="interview_schedule_detail">
                <h5>
                  Reviewed By:{" "}
                  <span style={{ color: "#d87d2e" }}>
                    {viewDetails.lastUpdatedBy}
                  </span>
                </h5>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Completed;
