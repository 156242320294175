import React, { Component } from "react";
import { bindActionCreators } from "redux";
// import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import "./WFHAndLeaveStyles.scss";
import {
  userWfhList as getUserWfhList,
  getUserWfh,
  userWfhListCalender,
  getUserWfhAndLeave,
} from "../../../store/employeeWfh/employeeWfhAction";
import WFHAndLeaveView from "./WFHAndLeaveView";
import { Loader, BigLoader } from "../../common";
import { userList as getUserList } from "../../../store/leaveReporting/leaveReporingActions";

export class WFHAndLeaveContainer extends Component {
  state = {
    addUserShow: false,
    filterShow: false,

    // currentYear: new Date().getFullYear(),
    setFilterPagination: false,
    isFilterActive: false,
    searchDate: null,
  };

  constructor(props) {
    super(props);
    // this.props.getUserList({ isActive: "1" });
    this.props.getUserWfhAndLeave(1);
  }

  handleClose = () => {
    this.setState({
      addUserShow: false,
      filterShow: false,
    });
  };
  handleOpenFilter = () => {
    this.setState({
      filterShow: true,
    });
  };

  onsetFilterData = (data) => {
    // console.log("datafilter", data);
    this.setState({
      data: data,
      setFilterPagination: true,
    });
  };

  onresetFilterPagination = () => {
    this.setState({
      setFilterPagination: false,
      // data: {
      //   isActive: "1",
      // },
    });
  };

  handleIsFilterActive = (val) => {
    // console.log("valcontainer", val);
    this.setState({
      isFilterActive: val,
    });
  };

  handleClearFilter = () => {
    this.props.getUserWfhAndLeave(1);
    this.handleIsFilterActive(false);
    this.onresetFilterPagination();
  };

  handlePageChange = (page) => {
    if (this.state.setFilterPagination) {
      this.props.getUserWfhAndLeave(page, this.state.data, false);
    } else {
      this.props.getUserWfhAndLeave(page);
    }
  };

  handleChange = (value) => {
    console.log("value:", value);

    this.setState({
      ...this.state,
      searchDate: value,
    });
  };

  handleSubmit = () => {
    const { searchDate } = this.state;
    if (searchDate) this.props.getUserWfhAndLeave(1, searchDate);
  };

  render() {
    const {
      getUserWfhAndLeaveSuccess,
      getUserWfhList,
      getUserWfh,
      getUserWfhAndLeave,
      getUserWfhAndLeaveData,
      totalDocs,
      limit,
      page,

      userList,
    } = this.props;

    if (getUserWfhAndLeaveSuccess === false) {
      return <Loader />;
    } else {
      return (
        <>
          <WFHAndLeaveView
            filterShow={this.state.filterShow}
            handleClose={this.handleClose}
            handleOpenFilter={this.handleOpenFilter}
            onresetFilterPagination={this.onresetFilterPagination}
            handleIsFilterActive={this.handleIsFilterActive}
            isFilterActive={this.state.isFilterActive}
            handleClearFilter={this.handleClearFilter}
            getUserWfhAndLeave={getUserWfhAndLeave}
            getUserWfhList={getUserWfhList}
            getUserWfh={getUserWfh}
            getUserWfhAndLeaveData={getUserWfhAndLeaveData}
            rowCount={totalDocs}
            rowsPerPage={limit}
            currentPage={page}
            onsetFilterData={this.onsetFilterData}
            onChangePage={this.handlePageChange}
            data={this.state.data}
            searchDate={this.state.searchDate}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            userList={userList}
          />
          <BigLoader />
        </>
      );
    }
  }
}
// }

WFHAndLeaveContainer.propTypes = {};

const mapStateToProps = (state) => {
  // console.log("state", state.employeeWfhReducer);
  return {
    userListSuccess: state.leaveReporting.userListSuccess,
    getUserWfhAndLeaveSuccess:
      state.employeeWfhReducer.getUserWfhAndLeaveSuccess,
    getUserWfhAndLeaveData: state.employeeWfhReducer.getUserWfhAndLeaveData,
    limit: state.employeeWfhReducer.limit,
    totalDocs: state.employeeWfhReducer.totalDocs,
    page: state.employeeWfhReducer.page,
    userList: state.leaveReporting.userList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserWfhAndLeave: getUserWfhAndLeave,
      getUserWfhList: getUserWfhList,
      getUserWfh: getUserWfh,
      userWfhListCalender: userWfhListCalender,
      getUserList: getUserList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WFHAndLeaveContainer);
