import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Container, Row, Card, Col } from "react-bootstrap";
// import { IoPerson } from "react-icons/io5";
// import { AiOutlineArrowDown } from "react-icons/ai";
import { AiOutlineSetting } from "react-icons/ai";

import { Header } from "../../common";
import LevelMngt from "./components/LevelMngt";
import ApplicationMngt from "./components/ApplicationMngt";
// import LocationMngt from "./components/LocationMngt";
// import LunchTypeMngt from "./components/LunchTypeMngt";
import PermissionMngt from "./components/PermissionMngt";
import RoleMngt from "./components/RoleMngt";
// import { Input, CircularButtonView } from "../../controls";

const SystemManagementView = ({
  addRole,
  editRole,
  deleteRole,

  addPermission,
  editPermission,
  deletePermission,
  assignPermission,

  roleList,
  locationList,
  permissionList,
  addLocation,
  editLocation,
  deleteLocation,

  addLevel,
  editLevel,
  deleteLevel,
  levelList,

  lunchTypeList,
  addLunchType,
  editLunchType,
  deleteLunchType,

  rolepermissionList,
  removeAssignPermission,

  addApplicationManager,
  editApplicationManager,
  deleteApplicationManager,
  applicationmanagerList,
  applicationList,
  getApplicationManagerList,
  getUserApplicationManagerList,
}) => {
  console.log("inside application manager view");
  console.log("getUserApplicationManagerList", getUserApplicationManagerList);
  return (
    <>
      <Header label={"System Management"} Icon={AiOutlineSetting} />
      <div className="main__body padding">
        <Container>
          <Row>
            {/* <Col lg={6} xl={6}>
            <LocationMngt 
              locationList={locationList}
              addLocation={addLocation}
              editLocation={editLocation}
              deleteLocation={deleteLocation}
            />
          </Col> */}
            <Col lg={6} xl={6}>
              <LevelMngt
                levelList={levelList}
                addLevel={addLevel}
                editLevel={editLevel}
                deleteLevel={deleteLevel}
              />
            </Col>
            {/* <Col lg={6} xl={6}>
            <LunchTypeMngt 
              lunchTypeList={lunchTypeList}
              addLunchType={addLunchType}
              editLunchType={editLunchType}
              deleteLunchType={deleteLunchType}

              locationList={locationList}
            />
          </Col> */}
            <Col lg={6} xl={6}>
              <RoleMngt
                addRole={addRole}
                editRole={editRole}
                deleteRole={deleteRole}
                assignPermission={assignPermission}
                roleList={roleList}
                permissionList={permissionList}
                rolepermissionList={rolepermissionList}
                removeAssignPermission={removeAssignPermission}
              />
            </Col>
            <Col lg={6} xl={6}>
              <PermissionMngt
                addPermission={addPermission}
                editPermission={editPermission}
                deletePermission={deletePermission}
                permissionList={permissionList}
              />
            </Col>

            <Col lg={6} xl={6}>
              <ApplicationMngt
                addApplicationManager={addApplicationManager}
                editApplicationManager={editApplicationManager}
                deleteApplicationManager={deleteApplicationManager}
                applicationmanagerList={applicationmanagerList}
                applicationList={applicationList}
                getApplicationManagerList={getApplicationManagerList}
                getUserApplicationManagerList={getUserApplicationManagerList}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SystemManagementView;
