import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input } from "../../../controls";

const RoleMgnt = ({
  addRole,
  editRole,
  assignPermission,
  deleteRole,

  roleList,
  permissionList,
  rolepermissionList,
  removeAssignPermission,
}) => {
  // console.log(permissionList)
  // console.log(rolepermissionList)
  const [role, setRole] = useState("");
  const [permission, setPermission] = useState([]);
  const [errors, setErrors] = useState({});
  const [showAddRole, setShowAddRole] = useState(false);
  const [showEditRole, setShowEditRole] = useState(false);
  const [showAssignPermission, setShowAssignPermission] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [permissionIds, setExistingPermissionIds] = useState([]);
  const [saveExitPer, setSaveExitPer] = useState([]);

  const columns = [
    {
      name: "Role",
      selector: "name",
      sortable: true,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "250px",
      grow: 2,
      cell: (row) => {
        if (row.name === "Admin" || row.name === "Super Admin") {
          return (
            <div className="d-flex">
              <div className="me-1">
                <ButtonView
                  onClick={() => {
                    // console.log("hi")
                    handleAssignExistingPermission(row._id);
                    setShowAssignPermission(true);
                    // setRole(row.name)
                    setRoleId(row._id);
                  }}
                  title={"Assign"}
                  size="small"
                  styles="secondary"
                />
              </div>
              <div className="me-1 invisible">
                <ButtonView
                  onClick={() => {
                    setShowEditRole(true);
                    setRole(row.name);
                    setRoleId(row._id);
                  }}
                  title={"Edit"}
                  size="small"
                  styles="secondary"
                />
              </div>
              <div className="invisible">
                <ButtonView
                  onClick={() => {
                    if (window.confirm("Delete role?")) {
                      deleteRole(row._id);
                    }
                  }}
                  title={"Delete"}
                  size="small"
                  styles="danger-outline"
                />
              </div>
            </div>
          );
        } else {
          return (
            <div className="d-flex">
              <div className="me-1">
                <ButtonView
                  onClick={() => {
                    // console.log("hi")
                    handleAssignExistingPermission(row._id);
                    setShowAssignPermission(true);
                    // setRole(row.name)
                    setRoleId(row._id);
                  }}
                  title={"Assign"}
                  size="small"
                  styles="secondary"
                />
              </div>
              <div className="me-1">
                <ButtonView
                  onClick={() => {
                    setShowEditRole(true);
                    setRole(row.name);
                    setRoleId(row._id);
                  }}
                  title={"Edit"}
                  size="small"
                  styles="secondary"
                />
              </div>
              <ButtonView
                onClick={() => {
                  if (window.confirm("Delete role?")) {
                    deleteRole(row._id);
                  }
                }}
                title={"Delete"}
                size="small"
                styles="danger-outline"
              />
            </div>
          );
        }
      },
    },
  ];

  const handleAddRole = () => {
    var rules = { required: ["role"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ role }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      // addRole(role)
      if (permission.length > 0) {
        addRole(role, permission);
        // console.log(role)
        // console.log(permission)
      } else {
        alert("Select atleast one permission");
      }
      // handleClose();
    }
  };

  const handleEditRole = () => {
    var rules = { required: ["role"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ role }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      // console.log(role, roleId)
      editRole(role, roleId);
      handleClose();
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    var value = target.value;

    if (target.checked) {
      console.log("hi");
      setPermission((prevData) => {
        const newData = prevData.filter((v) => v !== value);
        return [...newData, value];
      });
    } else {
      console.log("hi2");
      setPermission((prevData) => {
        const newData = prevData.filter((v) => v !== value);
        return [...newData];
      });
    }
  };

  const assignNewPermission = () => {
    if (permissionIds.length > 0) {
      removeAssignPermission(roleId, permissionIds);
      handleClose();
    } else {
      alert("Select atleast one permission.");
    }
  };

  const handleAssignExistingPermission = (id) => {
    const existingPermission = rolepermissionList
      .filter((list) => {
        return list.roleId._id === id;
      })
      .map((list) => list.permissionId._id);
    setExistingPermissionIds(existingPermission);
    setSaveExitPer(existingPermission);
  };

  const handleInputPermissionChange = (event) => {
    const target = event.target;
    var value = target.value;

    if (target.checked) {
      setExistingPermissionIds((prevData) => {
        const newData = prevData.filter((v) => v !== value);
        return [...newData, value];
      });
    } else {
      setExistingPermissionIds((prevData) => {
        const newData = prevData.filter((v) => v !== value);
        return [...newData];
      });
    }
  };

  const handleClose = () => {
    setShowAddRole(false);
    setShowEditRole(false);
    setShowAssignPermission(false);
    setRole("");
    setErrors({});
    setPermission([]);
    setExistingPermissionIds([]);
  };

  return (
    <div className="role_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Role</div>
              <div className="d-none d-md-block">Role Management</div>
            </Card.Title>
            <div className="add_role">
              <ButtonView
                onClick={() => setShowAddRole(true)}
                title={"Add Role"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={roleList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={2}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showEditRole}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">Edit Role</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="edit_role">
            <div className="mb-3">
              <Input
                name="role"
                label="Edit Role"
                value={role}
                type="text"
                placeholder="Edit Role"
                onChange={(e) => setRole(e.target.value)}
                size="fluid"
                setFocus={1}
                error={errors["role"]}
                isrequired={true}
              />
            </div>

            <ButtonView
              onClick={handleEditRole}
              title={"Save"}
              styles="secondary"
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddRole}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">Add Role</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="d-flex mb-2 justify-content-end">
              <Col xs={12}>
                <Input
                  name="role"
                  value={role}
                  label={"Add Role"}
                  type="text"
                  placeholder="Add Role"
                  onChange={(e) => setRole(e.target.value)}
                  size="fluid"
                  setFocus={1}
                  error={errors["role"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12} md={12} className="assign_permission mt-3 mb-3">
                <h6>Assign Permission</h6>
                <Row>
                  {permissionList.map((list) => {
                    return (
                      <Col key={list._id} md={12}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={list._id}
                            onChange={handleInputChange}
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {list.displayName}
                          </label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={12} className="mt-1">
                <ButtonView
                  onClick={handleAddRole}
                  title={"Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAssignPermission}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">Assign Permission</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="d-flex mb-2 justify-content-end">
              <Col xs={12} md={12} className="assign_permission mb-3">
                {/* <h6>Assign Permission</h6> */}
                <Row>
                  {permissionList.map((list) => {
                    return (
                      <Col key={list._id} md={12}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={
                              permissionIds.indexOf(list._id) !== -1
                                ? true
                                : false
                            }
                            value={list._id}
                            onChange={handleInputPermissionChange}
                            id="flexCheckDefault"
                            disabled={
                              saveExitPer.indexOf(list._id) !== -1
                                ? true
                                : false
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {list.displayName}
                          </label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={12} className="mt-1">
                <ButtonView
                  onClick={assignNewPermission}
                  title={"Assign"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RoleMgnt;
