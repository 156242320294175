import { actionTypes } from "./holidaysActions";

const {
  HOLIDAYS_PENDING,
  HOLIDAYS_ERROR,
  HOLIDAYS_SUCCESS,

  HOLIDAYS_ADD_PENDING,
  HOLIDAYS_ADD_ERROR,
  HOLIDAYS_ADD_SUCCESS,

  HOLIDAYS_EDIT_PENDING,
  HOLIDAYS_EDIT_ERROR,
  HOLIDAYS_EDIT_SUCCESS,

  HOLIDAYS_DELETE_PENDING,
  HOLIDAYS_DELETE_ERROR,
  HOLIDAYS_DELETE_SUCCESS,
} = actionTypes;

const initialState = {
  holidaysPending: false,
  holidaysSuccess: false,
  holidaysError: null,

  holidaysAddPending: false,
  holidaysAddSuccess: false,
  holidaysAddError: null,

  holidaysEditPending: false,
  holidaysEditSuccess: false,
  holidaysEditError: null,

  holidaysDeletePending: false,
  holidaysDeleteSuccess: false,
  holidaysDeleteError: null,

  holidaysList: [],
  currentYear: new Date().getFullYear(),
};

export default function holidays(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HOLIDAYS_PENDING:
    case HOLIDAYS_ERROR:
    case HOLIDAYS_SUCCESS:
      return Object.assign({}, state, payload);
    case HOLIDAYS_ADD_PENDING:
    case HOLIDAYS_ADD_ERROR:
      return Object.assign({}, state, payload);
    case HOLIDAYS_ADD_SUCCESS: {
      const newHoliday = payload.holidays;
      const newHolidaysList = [...state.holidaysList, newHoliday];

      const newState = {
        holidaysList: newHolidaysList,
        holidaysAddPending: payload.holidaysAddPending,
        holidaysAddSuccess: payload.holidaysAddSuccess,
        holidaysAddError: payload.holidaysAddError,
      };
      return Object.assign({}, state, newState);
    }
    case HOLIDAYS_EDIT_PENDING:
    case HOLIDAYS_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case HOLIDAYS_EDIT_SUCCESS: {
      const newHolidaysList = state.holidaysList.map((list) => {
        if (list._id === payload.holidays._id) {
          return payload.holidays;
        } else {
          return list;
        }
      });
      return Object.assign(
        {},
        state,
        { holidaysList: newHolidaysList },
        payload
      );
    }
    case HOLIDAYS_DELETE_PENDING:
    case HOLIDAYS_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case HOLIDAYS_DELETE_SUCCESS: {
      const newHolidaysList = state.holidaysList.filter((list) => {
        return list._id !== payload.id;
      });
      return Object.assign(
        {},
        state,
        { holidaysList: newHolidaysList },
        payload
      );
    }
    default:
      return state;
  }
}
