import { actionTypes } from "./permissionActions";

const { 
  PERMISSION_PENDING, 
  PERMISSION_ERROR, 
  PERMISSION_SUCCESS,

  PERMISSION_ADD_PENDING,
  PERMISSION_ADD_ERROR,
  PERMISSION_ADD_SUCCESS,

  PERMISSION_EDIT_PENDING,
  PERMISSION_EDIT_ERROR,
  PERMISSION_EDIT_SUCCESS,

  PERMISSION_DELETE_PENDING,
  PERMISSION_DELETE_ERROR,
  PERMISSION_DELETE_SUCCESS,
      } = actionTypes;

const initialState = {
  permissionPending: false,
  permissionSuccess: false,
  permissionError: null,

  permissionAddPending: false,
  permissionAddSuccess: false,
  permissionAddError: null,

  permissionEditPending: false,
  permissionEditSuccess: false,
  permissionEditError: null,

  permissionDeletePending: false,
  permissionDeleteSuccess: false,
  permissionDeleteError: null,

  permissionList: [],
};

export default function permission(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PERMISSION_PENDING:
    case PERMISSION_ERROR:
    case PERMISSION_SUCCESS:
      return Object.assign({}, state, payload);
    case PERMISSION_ADD_PENDING:
    case PERMISSION_ADD_ERROR:
      return Object.assign({}, state, payload);
    case PERMISSION_ADD_SUCCESS: {
      const newRole = payload.permission;
      const newPermissionList = [...state.permissionList, newRole];

      const newState = {
        permissionList: newPermissionList,
        permissionAddPending: payload.permissionAddPending,
        permissionAddSuccess: payload.permissionAddSuccess,
        permissionAddError: payload.permissionAddError,
      }
      return Object.assign({}, state, newState);
    }
    case PERMISSION_EDIT_PENDING:
    case PERMISSION_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case PERMISSION_EDIT_SUCCESS: {
      const newPermissionList = state.permissionList.map(list => {
        if(list._id === payload.permission._id) {
          return payload.permission
        } else {
          return list
        }
      })
      return Object.assign({}, state, {permissionList: newPermissionList}, payload);
    }
    case PERMISSION_DELETE_PENDING:
    case PERMISSION_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case PERMISSION_DELETE_SUCCESS: {
      const newPermissionList = state.permissionList.filter(list => {
        return list._id !== payload.id
      })
      return Object.assign({}, state, {permissionList: newPermissionList}, payload);
    }
    default:
      return state;
  }
}
