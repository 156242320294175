import React, { useState } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { ButtonView, TextArea, Pagination } from "../../../controls";
import { DataTable } from "../../../common";
import {
  LEAVE_STATUS_CANCELLED,
  LEAVE_STATUS_APPROVED,
} from "../../../../constants/env";
import LoaderView from "./LoaderView";

const Approved = ({
  updateLeave,
  getUserWfhList,
  userWfhList,
  user,
  onsetLeaveId,
  leaveId,
  showNextYearLeave,
  getNextYearLeaveDependOnId,
  rowsPerPage,
  rowCount,

  currentPage,
  employeeId,
  userWfhListSuccess,
  getUserWfh,
  currentYear,
  updateWfh,
  changingYear,
}) => {
  // console.log("changingYear", changingYear);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [cancelVal, setCancelVal] = useState("");
  const [cancelId, setCancelId] = useState("");
  const [errors, setErrors] = useState({});

  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => {
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        if (new Date(row.startDate) < now) {
          return <span className="badge bg-success-transparent">Availed</span>;
        } else {
          return <span className="badge bg-success-transparent">Approved</span>;
        }
      },
    },
    // {
    //   name: "Type",
    //   selector: "masterLeaveType",
    //   center: true,
    //   grow: 4,
    // },
    {
      name: "Approver",
      selector: "",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{`${
          row.reportingManagerDetailsUserId[0].firstName
        } ${row.reportingManagerDetailsUserId[0].lastName.charAt(0)}`}</span>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "160px",
      grow: 2,
      cell: (row) => {
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        if (new Date(row.startDate) < now) {
          return (
            <div className="d-flex justify-content-between">
              <div className="me-1">
                <ButtonView
                  onClick={() => {
                    getUserWfh(row._id);
                  }}
                  title={"View"}
                  size="small"
                  styles="secondary"
                />
              </div>
              <div className="invisible">
                <ButtonView
                  onClick={() => handleCancelOpen(row)}
                  title={"Cancel"}
                  size="small"
                  styles="danger-outline"
                  disabled
                />
              </div>
            </div>
          );
        } else {
          return (
            <div className="d-flex">
              <div className="me-1">
                <ButtonView
                  onClick={() => {
                    getUserWfh(row._id);
                    // handleViewOpen()
                  }}
                  title={"View"}
                  size="small"
                  styles="secondary"
                />
              </div>
              <ButtonView
                onClick={() => handleCancelOpen(row)}
                title={"Cancel"}
                size="small"
                styles="danger-outline"
              />
            </div>
          );
        }
      },
    },
  ];

  const handleCancelOpen = (row) => {
    setCancelId(row._id);
    setCancelOpen(true);
  };

  const handleCancelLeave = () => {
    var rules = {
      required: ["cancelVal"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ cancelVal }),
    });

    setErrors(errors);
    var setKey = 4;
    if (!Object.keys(errors).length) {
      updateWfh(
        cancelId,
        user,
        cancelVal,
        LEAVE_STATUS_CANCELLED,
        "Successfully cancelled leave.",
        changingYear,
        setKey
      );
      handleClose();
    }
  };

  const handleClose = () => {
    // setViewDetailsShow(false);
    setCancelOpen(false);
    setCancelId("");
    setErrors({});
    setCancelVal("");
  };

  const onChangePage = (currentPage) => {
    getUserWfhList(
      currentPage,
      {
        status: LEAVE_STATUS_APPROVED,
        year: changingYear,
        employeeId: employeeId,
      },
      false
    );
  };

  if (userWfhListSuccess === false) {
    return <LoaderView />;
  } else {
    return (
      <>
        <div className="table_content px-2 attendance_table pb-3">
          <DataTable
            data={userWfhList}
            columns={columns}
            pagination={false}
            paginationPerPage={5}
            paginationServer={false}
          />
        </div>

        <div className="cancel_modal">
          <Modal
            show={cancelOpen}
            onHide={handleClose}
            aria-labelledby="reject_modal"
          >
            <Modal.Header>
              <Modal.Title id="reject_modal">Cancellation Reason</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <TextArea
                  name="comments"
                  placeholder="Reason..."
                  value={cancelVal}
                  label=" Kindly provide the reason for cancelling the WFH."
                  setFocus={1}
                  type="textarea"
                  onChange={(e) => setCancelVal(e.target.value)}
                  size="fluid"
                  error={errors["cancelVal"]}
                  isrequired={true}
                />
              </div>
              <Row>
                <Col md={12} lg={6} className="mb-2">
                  <ButtonView
                    onClick={handleClose}
                    title={"Cancel"}
                    styles="danger-outline"
                  />
                </Col>
                <Col md={12} lg={6}>
                  <ButtonView
                    onClick={handleCancelLeave}
                    title={"Submit"}
                    styles="secondary"
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>

        {userWfhList.length > 0 ? (
          <div className="margin_x mt-2 me-3 mb-3 d-flex justify-content-end">
            <Pagination
              rowsPerPage={rowsPerPage}
              rowCount={rowCount}
              onChangePage={onChangePage}
              currentPage={currentPage}
            />
          </div>
        ) : null}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  // console.log("unapproved", state.employeeWfhReducer.userWfhList);
  return {
    userWfhList: state.employeeWfhReducer.userWfhList,
    page: state.employeeWfhReducer.page,
    userWfhListSuccess: state.employeeWfhReducer.userWfhListSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getUser: getUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Approved);
