import { actionTypes } from "./lunchActions";

const { LUNCH_PENDING, 
        LUNCH_ERROR, 
        LUNCH_SUCCESS,
        LUNCHPREFERENCE_PENDING, 
        LUNCHPREFERENCE_SUCCESS, 
        LUNCHPREFERENCE_ERROR,
        LUNCHCOUNT_PENDING, 
        LUNCHCOUNT_SUCCESS, 
        LUNCHCOUNT_ERROR,
        SET_LUNCHLIST,
      } = actionTypes;

const initialState = {
  lunchPending: false,
  lunchSuccess: false,
  lunchError: null,

  lunchPreferencePending: false,
  lunchPreferenceSuccess: false,
  lunchPreferenceError: null,

  lunchCountPending: false,
  lunchCountSuccess: false,
  lunchCountError: null,

  lunchList: [],
  hasNextPage: false,
  hasPrevPage: false,
  limit: null,
  nextPage: null,
  pagingCounter: null,
  prevPage: null,
  totalDocs: null,
  totalPages: null,
  lunchCount: "",
};

export default function lunch(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LUNCH_PENDING:
    case LUNCH_ERROR:
    case LUNCH_SUCCESS:
      return Object.assign({}, state, payload);
    case LUNCHPREFERENCE_PENDING:
    case LUNCHPREFERENCE_SUCCESS:
    case LUNCHPREFERENCE_ERROR:
      return Object.assign({}, state, payload);
    case LUNCHCOUNT_PENDING:
    case LUNCHCOUNT_SUCCESS:
    case LUNCHCOUNT_ERROR:
      return Object.assign({}, state, payload);
    case SET_LUNCHLIST:
      const newData = state.lunchList.map(list => {
        if(list._id === payload.userId) {
          list.lunchPreference = payload.lunchType
        }
        return list
      })
      return Object.assign({}, state, {lunchList: newData});
    default:
      return state;
  }
}
