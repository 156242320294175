import React from "react";
// import { MdCall } from "react-icons/md";
import { Modal, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";

const ShowNextYearLeaveDetail = ({
  leaveDependOnId,
  showLeaveById,
  closeLeaveByIdData,
}) => {
  const handleClose = () => {
    closeLeaveByIdData(false);
  };

  return (
    <>
      {leaveDependOnId && (
        <div className="view_modal">
          <Modal
            show={showLeaveById}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Application Detail</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="comments ms-1 mb-3">
                {leaveDependOnId.status === 1 ? (
                  <h5>
                    Approved by{" "}
                    <span style={{ color: "#d87d2e" }}>
                      {leaveDependOnId.lastUpdatedBy}
                    </span>
                  </h5>
                ) : (
                  <h5>
                    Rejected by{" "}
                    <span style={{ color: "#d87d2e" }}>
                      {leaveDependOnId.lastUpdatedBy}
                    </span>
                  </h5>
                )}
              </div>
              {leaveDependOnId.status === -1 &&
                leaveDependOnId.rejectionOrCancellationComments && (
                  <div>
                    <div className="comments ms-1">
                      <h6>Rejection Reason:</h6>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={leaveDependOnId.rejectionOrCancellationComments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  FD/1H/2H
                  <span>{leaveDependOnId.leaveSubType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Leave Type:
                  <span>{leaveDependOnId.masterLeaveType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sub Leave Type:
                  <span>{leaveDependOnId.leaveType.description}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Start Date:
                  <span>
                    {moment(new Date(leaveDependOnId.startDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  End Date:
                  <span>
                    {moment(new Date(leaveDependOnId.endDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                {leaveDependOnId.comments && (
                  <div className="mt-3">
                    <div className="comments">
                      <h6>Comments:</h6>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={leaveDependOnId.comments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
              </ul>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ShowNextYearLeaveDetail;
