import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import "./MyLeavesStyles.scss";
import MyLeavesView from "./MyLeavesView";
import {
  user as getUser,
  reportingMangerUsersList,
} from "../../../store/user/userActions";
import { list as getLeaveTypes } from "../../../store/leaveTypes/leaveTypesActions";
import {
  employeeLeaveList as getemployeeLeaveList,
  applyForLeave,
  updateLeave,
  getLeaveMasterTypesList,
  getEmployeeLeavesSummary,
  getOtherLeavesSummary,
  getNextYearLeaveDependOnId,
  closeLeaveByIdData,
  canApplyOptionalHolidayLeave,
  getOptionalHolidayLeaveSummary,
} from "../../../store/employeeLeave/employeeLeaveActions";
import queryString from "query-string";
import { list as getHolidays } from "../../../store/holidays/holidaysActions";
import { Loader, BigLoader } from "../../common";
// import { globals } from "../../../store/globals";

export class MyLeavesContainer extends Component {
  leaveId = 0;

  state = {
    date: new Date().toISOString().split("T")[0],
    combineCalenderData: [],
    desData: "",
    defaultTabActiveKey: "",
  };
  constructor(props) {
    super(props);

    if (window.location.search !== "") {
      const parsed = queryString.parse(window.location.search);
      // console.log("parrsed", parsed);
      this.leaveId = parsed.id;
    }

    this.props.getemployeeLeaveList(true, new Date().getFullYear());
    this.props.getEmployeeLeavesSummary(true, new Date().getFullYear());
    this.props.getOtherLeavesSummary(true, new Date().getFullYear());
    this.props.getOptionalHolidayLeaveSummary(true, new Date().getFullYear());
    this.props.getUser();
    this.props.getLeaveTypes();
    this.props.reportingMangerUsersList();
    this.props.getHolidays();
    this.props.getLeaveMasterTypesList();

    this.srollToSummary = React.createRef();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onsetCombineCalenderList = (data) => {
    this.setState({
      combineCalenderData: data,
    });
  };
  onsetDate = (data) => {
    this.setState({
      date: data,
    });
  };
  onsetDescription = (data) => {
    // console.log(data)
    this.setState({
      desData: data,
    });
  };

  onsetDefaultTabActiveKey = (data) => {
    // console.log("default", data);
    this.setState({
      defaultTabActiveKey: data,
    });
  };

  onsetLeaveId = (data) => {
    this.leaveId = data;
  };

  render() {
    const {
      user,
      userSuccess,
      leaveTypesSuccess,
      leaveTypesList,
      userManagerSuccess,
      userManagerList,
      applyForLeave,
      employeeLeaveList,
      employeeLeaveListSuccess,
      updateLeave,

      holidaysSuccess,
      holidaysList,

      leaveMasterTypeListSuccess,
      leaveMasterTypeList,

      employeeLeavesSummarySuccess,
      employeeLeavesSummary,

      otherLeavesSummarySuccess,
      otherLeavesSummary,
      getemployeeLeaveList,
      showNextYearLeave,
      getEmployeeLeavesSummary,
      getOtherLeavesSummary,

      getNextYearLeaveDependOnId,

      showLeaveById,
      leaveById,
      closeLeaveByIdData,
      currentYear,
      totalEmployeeLeaveList,
      getHolidays,

      canApplyOptionalHolidayLeave,
      canApply,

      optionalHolidaySummary,
      optionalHolidaySummarySuccess,
      getOptionalHolidayLeaveSummary,
    } = this.props;

    if (
      userSuccess === false ||
      leaveTypesSuccess === false ||
      userManagerSuccess === false ||
      employeeLeaveListSuccess === false ||
      holidaysSuccess === false ||
      leaveMasterTypeListSuccess === false ||
      employeeLeavesSummarySuccess === false ||
      optionalHolidaySummarySuccess === false ||
      otherLeavesSummarySuccess === false
    ) {
      return <Loader />;
    } else {
      return (
        <>
          <MyLeavesView
            user={user}
            leaveTypesList={leaveTypesList}
            userManagerList={userManagerList}
            applyForLeave={applyForLeave}
            employeeLeaveList={employeeLeaveList}
            updateLeave={updateLeave}
            holidaysList={holidaysList}
            leaveMasterTypeList={leaveMasterTypeList}
            onsetCombineCalenderList={this.onsetCombineCalenderList}
            onsetDate={this.onsetDate}
            onsetDescription={this.onsetDescription}
            date={this.state.date}
            desData={this.state.desData}
            combineCalenderData={this.state.combineCalenderData}
            employeeLeavesSummary={employeeLeavesSummary}
            otherLeavesSummary={otherLeavesSummary}
            leaveId={this.leaveId}
            defaultTabActiveKey={this.state.defaultTabActiveKey}
            onsetDefaultTabActiveKey={this.onsetDefaultTabActiveKey}
            onsetLeaveId={this.onsetLeaveId}
            srollToSummary={this.srollToSummary}
            getemployeeLeaveList={getemployeeLeaveList}
            showNextYearLeave={showNextYearLeave}
            getOtherLeavesSummary={getOtherLeavesSummary}
            getEmployeeLeavesSummary={getEmployeeLeavesSummary}
            getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
            leaveById={leaveById}
            showLeaveById={showLeaveById}
            closeLeaveByIdData={closeLeaveByIdData}
            currentYear={currentYear}
            totalEmployeeLeaveList={totalEmployeeLeaveList}
            getHolidays={getHolidays}
            canApplyOptionalHolidayLeave={canApplyOptionalHolidayLeave}
            canApply={canApply}
            optionalHolidaySummary={optionalHolidaySummary}
            getOptionalHolidayLeaveSummary={getOptionalHolidayLeaveSummary}
          />
          <BigLoader />
        </>
      );
    }
  }
}

MyLeavesContainer.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  // console.log("stategetuserleave", state.holidays.holidaysList);
  return {
    user: state.user.user,
    userSuccess: state.user.userSuccess,
    userManagerSuccess: state.user.userManagerSuccess,
    userManagerList: state.user.userManager,

    leaveTypesSuccess: state.leaveTypes.leaveTypesSuccess,
    leaveTypesList: state.leaveTypes.leaveTypesList,

    employeeLeaveList: state.employeeLeave.employeeLeaveList,
    employeeLeaveListSuccess: state.employeeLeave.employeeLeaveListSuccess,

    totalEmployeeLeaveList: state.employeeLeave.totalEmployeeLeaveList,

    employeeLeavesSummary: state.employeeLeave.employeeLeavesSummary,
    showNextYearLeave: state.employeeLeave.showNextYearLeave,
    employeeLeavesSummarySuccess:
      state.employeeLeave.employeeLeavesSummarySuccess,

    otherLeavesSummary: state.employeeLeave.otherLeavesSummary,
    otherLeavesSummarySuccess: state.employeeLeave.otherLeavesSummarySuccess,

    holidaysSuccess: state.holidays.holidaysSuccess,
    holidaysList: state.holidays.holidaysList,

    leaveMasterTypeList: state.employeeLeave.leaveMasterTypeList,
    leaveMasterTypeListSuccess: state.employeeLeave.leaveMasterTypeListSuccess,

    leaveById: state.employeeLeave.leaveById,
    showLeaveById: state.employeeLeave.showLeaveById,
    currentYear: state.employeeLeave.currentYear,
    canApply: state.employeeLeave.canApply,

    optionalHolidaySummary: state.employeeLeave.optionalHolidaySummary,
    optionalHolidaySummarySuccess:
      state.employeeLeave.optionalHolidaySummarySuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: getUser,
      reportingMangerUsersList: reportingMangerUsersList,

      getLeaveTypes: getLeaveTypes,

      getemployeeLeaveList: getemployeeLeaveList,
      applyForLeave: applyForLeave,
      updateLeave: updateLeave,

      getHolidays: getHolidays,

      getLeaveMasterTypesList: getLeaveMasterTypesList,
      getEmployeeLeavesSummary: getEmployeeLeavesSummary,
      getOtherLeavesSummary: getOtherLeavesSummary,

      getNextYearLeaveDependOnId: getNextYearLeaveDependOnId,
      closeLeaveByIdData: closeLeaveByIdData,

      canApplyOptionalHolidayLeave: canApplyOptionalHolidayLeave,
      getOptionalHolidayLeaveSummary: getOptionalHolidayLeaveSummary,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyLeavesContainer);
