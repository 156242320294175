import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import "./LunchManagementStyles.scss";
import { user as getUser } from "../../../store/user/userActions";
import { list as getlocation } from "../../../store/location/locationActions";
import { list as getLunchList } from "../../../store/lunch/lunchActions";
import {
  lunchPreference,
  lunchCount,
  lunchListFilter,
  deleteLunchPreference,
} from "../../../store/lunch/lunchActions";
import LunchManagementView from "./LunchManagementView";
import { Loader } from "../../common";
// import { Loader } from "../../common";

export class LunchManagementContainer extends Component {
  state = {
    tiffinShow: false,
    filterShow: false,

    location: null,
    lunchType: null,
    showPagination: true,
    isFilterActive: false,
  };

  constructor(props) {
    super(props);

    this.props.getUser(true);
    this.props.getLunchList(1);
    this.props.getlocation();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onlunchPreference = (lunchType, userId) => {
    // console.log(userId)
    this.props.lunchPreference(lunchType, userId, false, true);
  };

  handleClose = () => {
    this.setState({
      tiffinShow: false,
      filterShow: false,
    });
  };
  handleOpenTiffin = () => {
    // console.log(this.props.user._id)
    this.props.lunchCount(this.props.user._id);
    this.setState({
      tiffinShow: true,
    });
  };
  handleOpenFilter = () => {
    this.setState({
      filterShow: true,
    });
  };

  handleSelectChange = (field) => (val) => {
    // console.log("val", val);
    this.setState({
      [field]: val.value,
    });
  };

  onAddFilter = () => {
    if (this.state.location || this.state.lunchType) {
      const data = {
        location: this.state.location,
        lunchPreference: this.state.lunchType,
      };
      this.props.lunchListFilter(data);
      this.handleClose();
      this.setState({
        showPagination: false,
      });
      this.handleIsFilterActive(true);
    }
  };

  onResetFilter = () => {
    this.props.getLunchList(1);
    this.handleClose();
    this.setState({
      location: null,
      lunchType: null,
      showPagination: true,
    });
    this.handleIsFilterActive(false);
  };

  handlePageChange = (page) => {
    this.props.getLunchList(page);
    // console.log(page)
  };

  handleIsFilterActive = (val) => {
    this.setState({
      isFilterActive: val,
    });
  };

  render() {
    const {
      deleteLunchPreference,
      lunchSuccess,
      lunchList,
      userSuccess,
      lunchTypeSuccess,
      allLunchTypeList,
      lunchCountData,
      locationList,
      lunchData,
    } = this.props;

    if (
      userSuccess === false ||
      lunchSuccess === false ||
      lunchTypeSuccess === false
    ) {
      return <Loader />;
    } else {
      return (
        <LunchManagementView
          lunchList={lunchList}
          onlunchPreference={this.onlunchPreference}
          allLunchTypeList={allLunchTypeList}
          lunchCountData={lunchCountData}
          tiffinShow={this.state.tiffinShow}
          filterShow={this.state.filterShow}
          handleClose={this.handleClose}
          handleOpenTiffin={this.handleOpenTiffin}
          handleOpenFilter={this.handleOpenFilter}
          locationList={locationList}
          location={this.state.location}
          lunchType={this.state.lunchType}
          handleSelectChange={this.handleSelectChange}
          onAddFilter={this.onAddFilter}
          onResetFilter={this.onResetFilter}
          rowsPerPage={lunchData.limit}
          rowCount={lunchData.totalDocs}
          onChangePage={this.handlePageChange}
          currentPage={lunchData.page}
          showPagination={this.state.showPagination}
          deleteLunchPreference={deleteLunchPreference}
          isFilterActive={this.state.isFilterActive}
        />
      );
    }
  }
}

LunchManagementContainer.propTypes = {
  lunchList: PropTypes.array,
  allLunchTypeList: PropTypes.array,
  user: PropTypes.object,
  lunchCountData: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  lunchData: state.lunch,
  lunchList: state.lunch.lunchList,
  lunchCountData: state.lunch.lunchCount,
  lunchSuccess: state.lunch.lunchSuccess,
  userSuccess: state.user.userSuccess,
  lunchTypeSuccess: state.lunchType.lunchTypeSuccess,
  allLunchTypeList: state.lunchType.allLunchTypeList,
  locationList: state.location.locationList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: getUser,
      lunchPreference: lunchPreference,
      lunchCount: lunchCount,
      getlocation: getlocation,
      lunchListFilter: lunchListFilter,
      getLunchList: getLunchList,
      deleteLunchPreference: deleteLunchPreference,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LunchManagementContainer);
