import React, { useState } from "react";
import { IoPerson } from "react-icons/io5";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { MdPlace, MdAssignmentInd, MdDelete } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { Col, Card, Row, Container } from "react-bootstrap";
import moment from "moment";

import { CircularButtonView, Pagination, ButtonView } from "../../../controls";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import EditUser from "./EditUser";
import ViewUser from "./ViewUser";

const UserManagementCard = ({
  getFamilyMemberInsurance,
  userList,
  locationList,
  roleList,
  updateUser,
  deleteUser,
  handleUploadFile,
  getDocument,
  removeDocument,
  userDocument,
  showInsuranceBox,
  initDoc,
  downloadInsuranceCard,

  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
  levelList,
  userDataDetails,
  userManager,
  onresetFilterPagination,
  filterData,

  getUserSkillList,
  userSkillList,
}) => {
  // console.log(userList);
  // console.log(userManager)
  const [userViewData, setUserViewData] = useState({});
  const [userViewShow, setUserViewShow] = useState(false);
  const [editUserShow, setEditUserShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState("");
  const [empId, setEmpId] = useState("");
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emergencyContactNumber: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pinCode: "",
    country: "",
    dob: "",
    role: "",
    isActive: "",
    createdBy: "",
    updatedBy: "",

    employeeNumber: "",
    level: "",
    location: "",
    reportingManagerEmployeeId: "",
    joiningDate: "",
    isConfirmed: "",
    confirmationDate: ""
  });
  const [file, setFile] = useState(null);

  const onLoadFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      // console.log(empId)
      handleUploadFile(file, empId);
      setFile(null);
    } else {
      alert("No file selected");
    }
  };

  const deleteCard = (id) => {
    if (
      window.confirm(
        "Delete Insurance User. Do you want to Delete selected Insurance User?"
      )
    ) {
      removeDocument(id);
    }
  };

  const handledownloadFile = (id) => {
    // console.log(id)
    downloadInsuranceCard(id);
  };

  const locationOptions = locationList.map((location) => {
    return { value: location._id, label: location.name };
  });

  const roleOptions = roleList.map((role) => {
    return { value: role._id, label: role.name };
  });
  const levelOptions = levelList.map((role) => {
    return { value: role._id, label: role.description };
  });
  console.log(userManager);
  const userManagerOptions = userManager.map((role) => {
    console.log(role);
    return {
      value: role._id,
      label: `${role.userId[0].firstName}  ${role.userId[0].lastName}`,
    };
  });

  const handleOpen = (user) => {
    // console.log("user", user);
    getDocument(user._id, false, true);
    const dob = user.dob;
    const userDetails = {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      emergencyContactNumber: user.emergencyContactNumber,
      email: user.email,
      addressLine1: user.addressLine1,
      addressLine2: user.addressLine2,
      city: user.city,
      state: user.state,
      pinCode: user.pinCode,
      country: user.country,
      dob: new Date(dob),
      // "dob" : new Date(user.dob).toISOString().moment.utc().format('MM/DD/YYYY').split("/").reverse().join("-"),
      role: user.role,
      isActive: "true",
      createdBy: user.createdBy,
      updatedBy: user.updatedBy,

      employeeNumber: user.employeeNumber,
      level: user.level,
      location: user.location[0],
      reportingManagerEmployeeId: user.reportingManagerEmployeeId,
      insuranceNumber: user.insuranceNumber,
      joiningDate: user?.joiningDate ? new Date(user.joiningDate) : '',
      isConfirmed: user?.isConfirmed ? user.isConfirmed : false,
      confirmationDate: user?.confirmationDate ? new Date(user.confirmationDate) : ''
    };
    setUserId(user.userId[0]);
    setEmpId(user._id);
    setUserData(userDetails);
    setEditUserShow(true);
    setUserViewData(user);
    // console.log("employeeNumber", user.insuranceNumber);
  };

  const handleView = (user) => {
    const userId = { userId: user.userId };
    getUserSkillList(userId);

    getDocument(user._id, false, true);
    console.log("check user id", user.userId);
    setUserViewData(user);
    setUserViewShow(true);
    setEmpId(user._id);
  };

  const handleSubmit = () => {
    var rules = {
      required: [
        "firstName",
        "lastName",
        // "phoneNumber",
        "email",
        "addressLine1",
        "addressLine2",
        // "city",
        // "state",
        // "pinCode",
        // "country",
        "dob",
        "joiningDate",
        "role",
        "employeeNumber",
        "level",
        "location",
        "reportingManagerEmployeeId",
      ],
      custom: [validator.email(["email"])],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(userData),
    });

    setErrors(errors);
    console.log("errors1", errors);
    if (!Object.keys(errors).length) {
      if (
        (userData.phoneNumber?.length >= 1 &&
          userData.phoneNumber?.length < 10) ||
        (userData.emergencyContactNumber?.length >= 1 &&
          userData.emergencyContactNumber?.length < 10)
      ) {
        alert("Number Should not be less than 10 digits");
        // handleClose();
      } else {
        var userDetails = {
          personalDetails: {
            firstName: userData.firstName,
            lastName: userData.lastName,
            phoneNumber: userData.phoneNumber,
            emergencyContactNumber: userData.emergencyContactNumber,
            email: userData.email,
            addressLine1: userData.addressLine1,
            addressLine2: userData.addressLine2,
            city: userData.city,
            state: userData.state,
            pinCode: userData.pinCode,
            country: userData.country,
            dob: moment(userData.dob).format("YYYY-MM-DD"),
            role: userData.role,
            isActive: "1",
            createdBy: userData.createdBy,
            updatedBy: `${userDataDetails.user.userId[0].firstName} ${userDataDetails.user.userId[0].lastName}`,
          },

          employeeDetails: {
            employeeNumber: userData.employeeNumber,
            level: userData.level,
            location: [userData.location],
            reportingManagerEmployeeId: userData.reportingManagerEmployeeId,
            insuranceNumber: userData.insuranceNumber,
            isActive: "1",
            joiningDate: userData?.joiningDate ? moment(userData.joiningDate).format("YYYY-MM-DD") : '',
            isConfirmed: userData?.isConfirmed ? userData.isConfirmed : false,
            confirmationDate: userData?.confirmationDate ? moment(userData.confirmationDate).format("YYYY-MM-DD") : ''
          },
        };
        updateUser(userDetails, userId, currentPage, filterData);
        // onresetFilterPagination()
        handleClose();
      }
    }
  };

  const handleDelete = (userData) => {
    if (
      window.confirm("Inactive user. Do you want to inactive selected user?")
    ) {
      const dob = userData.dob;
      const userDetails = {
        personalDetails: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          phoneNumber: userData.phoneNumber,
          emergencyContactNumber: userData.emergencyContactNumber,
          email: userData.email,
          addressLine1: userData.addressLine1,
          addressLine2: userData.addressLine2,
          city: userData.city,
          state: userData.state,
          pinCode: userData.pinCode,
          country: userData.country,
          dob: dob.slice(0, 10),
          role: userData.role,
          isActive: "0",
          createdBy: userData.createdBy,
          updatedBy: `${userDataDetails.user.userId[0].firstName} ${userDataDetails.user.userId[0].lastName}`,
        },

        employeeDetails: {
          employeeNumber: userData.employeeNumber,
          level: userData.level,
          location: [userData.location[0]],
          reportingManagerEmployeeId: userData.reportingManagerEmployeeId,
          insuranceNumber: userData.insuranceNumber,
          isActive: "0",
          joiningDate: userData?.joiningDate ? userData.joiningDate : '',
          isConfirmed: userData?.isConfirmed ? userData.isConfirmed : false,
          confirmationDate: userData?.confirmationDate ? userData.confirmationDate : ''
        },
      };
      deleteUser(userDetails, userData.userId[0], userData._id);
    }
    // updateUser(userDetasils, userData.userId[0], currentPage);
  };

  const handleClose = () => {
    initDoc();
    setEditUserShow(false);
    setErrors({});
    setFile(null);
    setUserId("");
    setEmpId("");
    // setUserViewData({});
    setUserViewShow(false);
  };

  return (
    <div className="user_management main__body padding">
      <Container>
        {userList.length > 0 ? (
          <>
            <Row>
              {userList.map((user) => {
                return (
                  <Col
                    md={6}
                    lg={6}
                    xl={3}
                    className="col-xxl-3"
                    key={user._id}
                  >
                    <Card className="mt-3">
                      <Card.Body>
                        <Card.Title className="text-center">
                          <span className="card_icon fw-bold">
                            <IoPerson /> {user.firstName} {user.lastName}
                          </span>
                        </Card.Title>
                        <p className="text-muted mb-1">
                          <AiOutlineMail /> {user.email}
                        </p>
                        <p className="text-muted mb-1">
                          <AiOutlinePhone />{" "}
                          {user.phoneNumber ? user.phoneNumber : "-"}
                        </p>

                        <p className="text-muted mb-1">
                          <MdPlace />{" "}
                          {user.employeeLocation.name
                            ? user.employeeLocation.name
                            : "-"}
                        </p>
                        <p className="text-muted mb-1">
                          <MdAssignmentInd />{" "}
                          {user.employeeNumber ? user.employeeNumber : " -"}
                        </p>
                        <hr />
                        <div className="user_edit d-flex justify-content-between">
                          <div className="view__btn">
                            <ButtonView
                              onClick={() => handleView(user)}
                              title={"View"}
                              // styles="secondary"
                            />
                          </div>
                          <div className="other__btn d-flex">
                            <CircularButtonView
                              Icon={FiEdit3}
                              onClick={() => handleOpen(user)}
                              size={20}
                              tooltip="Edit User"
                            />
                            <CircularButtonView
                              Icon={MdDelete}
                              onClick={() => handleDelete(user)}
                              size={20}
                              tooltip="Delete User"
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            <div className="margin_x mt-2 d-flex justify-content-end">
              <Pagination
                rowsPerPage={rowsPerPage}
                rowCount={rowCount}
                onChangePage={onChangePage}
                currentPage={currentPage}
              />
            </div>
          </>
        ) : (
          <div className="text-center mt-3">
            <h4>No data to display</h4>
          </div>
        )}

        <EditUser
          editUserShow={editUserShow}
          handleClose={handleClose}
          userData={userData}
          errors={errors}
          setUserData={setUserData}
          roleOptions={roleOptions}
          locationOptions={locationOptions}
          showInsuranceBox={showInsuranceBox}
          userDocument={userDocument}
          handleUpload={handleUpload}
          handleSubmit={handleSubmit}
          onLoadFile={onLoadFile}
          deleteCard={deleteCard}
          handledownloadFile={handledownloadFile}
          levelOptions={levelOptions}
          userManagerOptions={userManagerOptions}
          empId={empId}
        />
        <ViewUser
          handledownloadFile={handledownloadFile}
          userDocument={userDocument}
          userViewData={userViewData}
          handleClose={handleClose}
          userViewShow={userViewShow}
          userManagerOptions={userManagerOptions}
          roleOptions={roleOptions}
          levelOptions={levelOptions}
          locationOptions={locationOptions}
          handleOpen={handleOpen}
          setUserViewShow={setUserViewShow}
          empId={empId}
          userSkillList={userSkillList}
        />
      </Container>
    </div>
  );
};

export default UserManagementCard;
