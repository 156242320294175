import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import All from "./All";
import Approved from "./Approved";
import Rejected from "./Rejected";
import Unapproved from "./Unapproved";
import ApplyForWfhFormModal from "./ApplyForWfhFormModal";
import Cancelled from "./Cancelled";
import {
  LEAVE_STATUS_PENDING_APPROVAL,
  LEAVE_STATUS_CANCELLED,
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
} from "../../../../constants/env";

const ApplyForWfhApplication = ({
  applyForWfh,
  userManagerList,
  user,
  getUserWfhList,
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
  userWfhListSuccess,
  handleSelect,
  employeeId,
  getUserWfh,
  updateWfh,
  currentYear,
  userWfhListCalender,
  onsetDate,
  onsetYear,
  userCalender,
  updateGetUserWfhData,
  key,
  defaultTabActiveKey,
  cancelGetUserWfhBYId,
  changingYear,
}) => {
  // console.log("chaniging", changingYear);
  return (
    <div className="card">
      <ApplyForWfhFormModal
        applyForWfh={applyForWfh}
        userManagerList={userManagerList}
        user={user}
        currentYear={currentYear}
        getUserWfhList={getUserWfhList}
        employeeId={employeeId}
        userWfhListCalender={userWfhListCalender}
        onsetDate={onsetDate}
        onsetYear={onsetYear}
        empData={userCalender.filter(
          (list) => list.status === 0 || list.status === 1
        )}
        updateGetUserWfhData={updateGetUserWfhData}
        cancelGetUserWfhBYId={cancelGetUserWfhBYId}
        changingYear={changingYear}
      />
      <Tabs
        defaultActiveKey={defaultTabActiveKey}
        id="leave_application"
        className="table_tabs"
        // value={key}
        onSelect={handleSelect}
      >
        <Tab
          eventKey={LEAVE_STATUS_PENDING_APPROVAL}
          title="PENDING APPROVAL"
          // value={0}
        >
          <Unapproved
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            getUserWfh={getUserWfh}
            updateWfh={updateWfh}
            user={user}
            currentYear={currentYear}
            changingYear={changingYear}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
          />
        </Tab>
        <Tab eventKey={LEAVE_STATUS_APPROVED} title="APPROVED">
          <Approved
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            // userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            getUserWfh={getUserWfh}
            updateWfh={updateWfh}
            user={user}
            currentYear={currentYear}
            changingYear={changingYear}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
          />
        </Tab>

        <Tab eventKey={LEAVE_STATUS_REJECTED} title="REJECTED">
          <Rejected
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
            getUserWfh={getUserWfh}
            changingYear={changingYear}
          />
        </Tab>
        <Tab eventKey={LEAVE_STATUS_CANCELLED} title="CANCELLED">
          <Cancelled
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            getUserWfh={getUserWfh}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
            changingYear={changingYear}
          />
        </Tab>
        <Tab eventKey={4} title="ALL">
          <All
            //  data={employeeLeaveList} updateLeave={updateLeave} user={user}
            getUserWfhList={getUserWfhList}
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            onChangePage={onChangePage}
            currentPage={currentPage}
            userWfhListSuccess={userWfhListSuccess}
            employeeId={employeeId}
            getUserWfh={getUserWfh}
            cancelGetUserWfhBYId={cancelGetUserWfhBYId}
            changingYear={changingYear}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ApplyForWfhApplication;
