import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input, Select } from "../../../controls";

const LeaveTypesMngt = ({
  addLeaveType,
  editLeaveType,
  deleteLeaveType,
  leaveTypesList,
  leaveMasterTypeList,
}) => {
  const [showAddLeaveType, setShowAddLeaveType] = useState(false);
  const [description, setDescription] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [leaveTypeId, setLeaveTypeId] = useState("");
  const [leaveMasterType, setLeaveMasterType] = useState("");
  // console.log(leaveTypesList)
  const leaveMasterTypeListOptions = leaveMasterTypeList.map((leaveType) => {
    return { value: leaveType._id, label: leaveType.description };
  });

  const columns = [
    {
      name: "Leave Type",
      selector: "description",
      sortable: true,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setLeaveMasterType(row.masterLeaveType._id);
                setDescription(row.description);
                setShowAddLeaveType(true);
                setLeaveTypeId(row._id);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete leaveType?")) {
                console.log(row._id);
                deleteLeaveType(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleAddHoliday = () => {
    var rules = { required: ["description", "leaveMasterType"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ description, leaveMasterType }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        const data = {
          description: description,
          masterLeaveType: leaveMasterType,
        };
        editLeaveType(data, leaveTypeId);
        // edit
      } else {
        const data = {
          description: description,
          masterLeaveType: leaveMasterType,
        };
        addLeaveType(data);
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddLeaveType(false);
    setDescription("");
    setLeaveMasterType("");
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Leave Types</div>
              <div className="d-none d-md-block">Leave Types Management</div>
            </Card.Title>
            <div className="add_permission">
              <ButtonView
                onClick={() => setShowAddLeaveType(true)}
                title={"Add Leave Type"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={leaveTypesList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={3}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddLeaveType}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Leave Type" : "Add Leave Type"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12} className="mb-3">
                <Select
                  name="leaveMasterType"
                  label="Master Leave Type"
                  isSearchable={false}
                  defaultValue={leaveMasterTypeListOptions.find(
                    (option) => option.value === leaveMasterType
                  )}
                  placeholder="Select Leave Type"
                  options={leaveMasterTypeListOptions}
                  onChange={(val) => setLeaveMasterType(val.value)}
                  className="select"
                  classNamePrefix="select"
                  error={errors["leaveMasterType"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12}>
                <Input
                  name="description"
                  label="Leave type"
                  value={description}
                  type="text"
                  placeholder="Leave type"
                  onChange={(e) => setDescription(e.target.value)}
                  // setFocus={1}
                  size="fluid"
                  error={errors["description"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={4} className="mt-3">
                <ButtonView
                  onClick={handleAddHoliday}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeaveTypesMngt;
