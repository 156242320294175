import { actionTypes } from "./applicationAction";

const {
  // APPLICATION_PENDING,
  // APPLICATION_ERROR,
  // APPLICATION_SUCCESS,

  APPLICATIONMANAGER_PENDING,
  APPLICATIONMANAGER_ERROR,
  APPLICATIONMANAGER_SUCCESS,

  GET_USER_APPLICATION_MANAGER_PENDING,
  GET_USER_APPLICATION_MANAGER_SUCCESS,
  GET_USER_APPLICATION_MANAGER_ERROR,

  APPLICATION_ADD_PENDING,
  APPLICATION_ADD_ERROR,
  APPLICATION_ADD_SUCCESS,

  APPLICATION_DELETE_PENDING,
  APPLICATION_DELETE_ERROR,
  APPLICATION_DELETE_SUCCESS,

  APPLICATION_EDIT_PENDING,
  APPLICATION_EDIT_ERROR,
  APPLICATION_EDIT_SUCCESS,
  //   LUNCHPREFERENCE_PENDING,
  //   LUNCHPREFERENCE_SUCCESS,
  //   LUNCHPREFERENCE_ERROR,
  //   LUNCHCOUNT_PENDING,
  //   LUNCHCOUNT_SUCCESS,
  //   LUNCHCOUNT_ERROR,
  SET_APPLICATIONMANAGERLIST,
} = actionTypes;

const initialState = {
  //   lunchPending: false,
  //   lunchSuccess: false,
  //   lunchError: null,

  applicationPending: false,
  applicationSuccess: false,
  applicationError: null,

  applicationAddPending: false,
  applicationAddSuccess: false,
  applicationAddError: null,

  applicationEditPending: false,
  applicationeEditSuccess: false,
  applicationEditError: null,

  applicationDeletePending: false,
  applicationDeleteSuccess: false,
  applicationDeleteError: null,

  getUserApplicationManagerListPending: false,
  getUserApplicationManagerListSuccess: false,
  getUserApplicationManagerListError: null,
  //   lunchCountPending: false,
  //   lunchCountSuccess: false,
  //   lunchCountError: null,
  getUserApplicationManagerList: [],
  applicationmanagerList: [],
  hasNextPage: false,
  hasPrevPage: false,
  limit: null,
  nextPage: null,
  pagingCounter: null,
  prevPage: null,
  totalDocs: null,
  totalPages: null,
  applicationmanager: [],
  allApplicationManagerTypeList: [],
  selectedApplicationManagerType: "",
  getApplicationManagerList: [],
  // lunchCount: "",
};

export default function applicationManagerReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case APPLICATIONMANAGER_PENDING:
    case APPLICATIONMANAGER_ERROR:
    case APPLICATIONMANAGER_SUCCESS:
      return Object.assign({}, state, payload);
    case GET_USER_APPLICATION_MANAGER_PENDING:
    case GET_USER_APPLICATION_MANAGER_SUCCESS:
    case GET_USER_APPLICATION_MANAGER_ERROR:
      return Object.assign({}, state, payload);
    case APPLICATION_ADD_PENDING:
    case APPLICATION_ADD_ERROR:
      return Object.assign({}, state, payload);
    case APPLICATION_ADD_SUCCESS: {
      const newApplication = payload.applicationmanager;
      const newapplicationmanagerList = [
        ...state.applicationmanagerList,
        newApplication,
      ];

      const newState = {
        applicationmanagerList: newapplicationmanagerList,
        applicationAddPending: payload.applicationAddPending,
        applicationAddSuccess: payload.applicationAddSuccess,
        applicationAddError: payload.applicationAddError,
      };
      return Object.assign({}, state, newState);
    }
    case APPLICATION_EDIT_PENDING:
    case APPLICATION_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case APPLICATION_EDIT_SUCCESS: {
      const newpplicationmanagerList = state.applicationmanagerList.map(
        (list) => {
          if (list._id === payload.applicationmanager._id) {
            return payload.applicationmanager;
          } else {
            return list;
          }
        }
      );
      return Object.assign(
        {},
        state,
        { applicationmanagerList: newpplicationmanagerList },
        payload
      );
    }
    case APPLICATION_DELETE_PENDING:
    case APPLICATION_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case APPLICATION_DELETE_SUCCESS: {
      const newapplicationmanagerList = state.applicationmanagerList.filter(
        (list) => {
          return list._id !== payload.id;
        }
      );
      return Object.assign(
        {},
        state,
        { applicationmanagerList: newapplicationmanagerList },
        payload
      );
    }
    // case LUNCHCOUNT_PENDING:
    // case LUNCHCOUNT_SUCCESS:
    // case LUNCHCOUNT_ERROR:
    //   return Object.assign({}, state, payload);
    case SET_APPLICATIONMANAGERLIST:
      const newData = state.applicationmanagerList.map((list) => {
        if (list._id === payload.userId) {
          list.applicationManager = payload.applicationmanagerType;
        }
        return list;
      });
      return Object.assign({}, state, { applicationmanagerList: newData });
    default:
      return state;
  }
}
