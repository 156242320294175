import React, { useState, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { IoIosCopy } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { TiTick } from "react-icons/ti";

import { CircularButtonView } from "../../../controls";

const TiffinCount = ({ handleClose, tiffinShow, lunchCountData }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const textAreaRef = useRef(null);
  const copyToClipboard = (e) => {
    // navigator.clipboard.writeText(lunchCountData)
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 500);
  };
  return (
    <div className="tiffin_count_modal">
      <Modal
        show={tiffinShow}
        onHide={handleClose}
        aria-labelledby="tiffin_count_modal"
      >
        <Modal.Header>
          <Modal.Title id="tiffin_count_modal">Tiffin Count</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              // disabled
              readOnly
              ref={textAreaRef}
              value={lunchCountData}
              as="textarea"
              rows={3}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <CircularButtonView
              Icon={showSuccess ? TiTick : IoIosCopy}
              onClick={copyToClipboard}
              size={20}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TiffinCount;
