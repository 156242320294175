import { actionTypes } from "./lunchBlockingTimeActions";

const {
  LUNCH_BLOCKING_TIME_PENDING,
  LUNCH_BLOCKING_TIME_ERROR,
  LUNCH_BLOCKING_TIME_SUCCESS,

  LUNCH_BLOCKING_TIME_ADD_PENDING,
  LUNCH_BLOCKING_TIME_ADD_ERROR,
  LUNCH_BLOCKING_TIME_ADD_SUCCESS,

  LUNCH_BLOCKING_TIME_EDIT_PENDING,
  LUNCH_BLOCKING_TIME_EDIT_ERROR,
  LUNCH_BLOCKING_TIME_EDIT_SUCCESS,
} = actionTypes;

const initialState = {
  lunchBlockingTimeListPending: false,
  lunchBlockingTimeListSuccess: false,
  lunchBlockingTimeListError: null,

  lunchBlockingTimeAddPending: false,
  lunchBlockingTimeAddSuccess: false,
  lunchBlockingTimeAddError: null,

  lunchBlockingTimeEditPending: false,
  lunchBlockingTimeEditSuccess: false,
  lunchBlockingTimeEditError: null,

  lunchBlockingTimeList: [],
};

export default function lunchBlockingTimeReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LUNCH_BLOCKING_TIME_PENDING:
    case LUNCH_BLOCKING_TIME_ERROR:
    case LUNCH_BLOCKING_TIME_SUCCESS:
      return Object.assign({}, state, payload);
    case LUNCH_BLOCKING_TIME_ADD_PENDING:
    case LUNCH_BLOCKING_TIME_ADD_ERROR:
      return Object.assign({}, state, payload);
    case LUNCH_BLOCKING_TIME_ADD_SUCCESS: {
      const lunchBlockingTime = payload.lunchBlockingTime;
      const newLunchBlockingTimeList = [
        ...state.lunchBlockingTimeList,
        lunchBlockingTime,
      ];

      const newState = {
        lunchBlockingTimeList: newLunchBlockingTimeList,
        lunchBlockingTimeAddPending: payload.lunchBlockingTimeAddPending,
        lunchBlockingTimeAddSuccess: payload.lunchBlockingTimeAddSuccess,
        lunchBlockingTimeAddError: payload.lunchBlockingTimeAddError,
      };
      return Object.assign({}, state, newState);
    }
    case LUNCH_BLOCKING_TIME_EDIT_PENDING:
    case LUNCH_BLOCKING_TIME_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case LUNCH_BLOCKING_TIME_EDIT_SUCCESS: {
      const newLunchBlockingTimeList = state.lunchBlockingTimeList.map(
        (list) => {
          if (list._id === payload.lunchBlockingTime._id) {
            return payload.lunchBlockingTime;
          } else {
            return list;
          }
        }
      );
      return Object.assign(
        {},
        state,
        { lunchBlockingTimeList: newLunchBlockingTimeList },
        payload
      );
    }
    default:
      return state;
  }
}
