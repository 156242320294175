import React from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { ButtonView, Pagination } from "../../../controls";
import { DataTable } from "../../../common";
import {
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
} from "../../../../constants/env";
import { AiFillEye } from "react-icons/ai";
import LoaderView from "./LoaderView";
const Rejected = ({
  user,
  getUserWfh,
  getDocument,
  userDocument,
  updateLeave,
  downloadFile,
  showNextYearLeave,
  currentYear,
  userWfhList,
  rowsPerPage,
  rowCount,
  currentPage,
  getUserWfhList,
  updateWfh,
  userWfhListSuccess,
  changingYear,
}) => {
  // console.log("changingyr", changingYear);
  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="badge bg-danger-transparent">Rejected</span>
      ),
    },
    // {
    //   name: "Type",
    //   selector: "masterLeaveType",
    //   center: true,
    //   grow: 4,
    // },
    {
      name: "FD/1H/2H",
      selector: "leaveSubType",
      center: true,
      grow: 4,
    },
    {
      name: "Employee",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {row.userId[0].firstName} {row.userId[0].lastName.slice(0, 1)}
        </span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "70px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <AiFillEye
            className="view"
            size={18}
            onClick={() => {
              getUserWfh(row._id);
              if (row.attachementDocumentId) {
                getDocument(
                  row.employeeId[0],
                  true,
                  false,
                  row.attachementDocumentId
                );
              }
            }}
          />
        </div>
      ),
    },
    {
      name: "Option",
      center: true,
      minWidth: "120px",
      // grow: 4,
      cell: (row) => {
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        if (new Date(row.startDate) < now) {
          return "";
        } else {
          return (
            <div className="d-flex">
              <ButtonView
                onClick={() => handleApproveLeave(row)}
                title={"Approve"}
                size="small"
              />
            </div>
          );
        }
      },
    },
  ];

  const handleApproveLeave = (row) => {
    // console.log(
    //   "row",
    //   row._id,
    //   user,
    //   LEAVE_STATUS_APPROVED,
    //   "Successfully approved WFH",
    //   currentYear
    // );
    var setKey = -1;
    if (window.confirm("Approve leave?")) {
      updateWfh(
        row._id,
        user,
        "",
        LEAVE_STATUS_APPROVED,
        "Successfully approved leave.",
        changingYear,
        setKey
      );
    }
  };

  const onChangePage = (currentPage) => {
    getUserWfhList(
      currentPage,
      {
        status: LEAVE_STATUS_REJECTED,
        year: changingYear,
      },
      false
    );
  };

  if (userWfhListSuccess === false) {
    return <LoaderView />;
  } else {
    return (
      <>
        <div className="table_content px-2 attendance_table pb-3">
          <DataTable
            data={userWfhList}
            columns={columns}
            pagination={false}
            paginationPerPage={5}
            paginationServer={false}
          />
        </div>

        {userWfhList.length > 0 ? (
          <div className="margin_x mt-2 me-3 mb-3 d-flex justify-content-end">
            <Pagination
              rowsPerPage={rowsPerPage}
              rowCount={rowCount}
              onChangePage={onChangePage}
              currentPage={currentPage}
            />
          </div>
        ) : null}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    userWfhList: state.employeeWfhReducer.userWfhList,
    page: state.employeeWfhReducer.page,
    userWfhListSuccess: state.employeeWfhReducer.userWfhListSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getUser: getUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Rejected);
