import { actionTypes } from "./authActions";
import Auth from "../../service/auth";

const { AUTH_PENDING, AUTH_ERROR, AUTH_SUCCESS } = actionTypes;

const initialState = {
  authPending: false,
  authSuccess: false,
  authError: null,

  token: parseInt(Auth.getTokenExpiryFromLocalStorage()) > Math.floor(Date.now() / 1000) ? Auth.getTokenFromLocalStorage() : null,
  tokenExpiresOn: Auth.getTokenExpiryFromLocalStorage(),
  profileurl: parseInt(Auth.getTokenExpiryFromLocalStorage()) > Math.floor(Date.now() / 1000) ? Auth.getProfileUrlFromLocalStorage() : null,
  loginUser: parseInt(Auth.getTokenExpiryFromLocalStorage()) > Math.floor(Date.now() / 1000) ? Auth.getLoginUserFromLocalStorage() : Auth.getDefaultLoginUserFromLocalStorage(),
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_PENDING:
    case AUTH_ERROR:
    case AUTH_SUCCESS:
      return Object.assign({}, state, {
        authPending: payload.authPending,
        authSuccess: payload.authSuccess,
        authError: payload.authError,
        token: payload.token,
        profileurl: payload.profileurl,
        loginUser: payload.loginUser,
      });
    default:
      return state;
  }
}
