import React, { useState, useEffect } from "react";
import moment from "moment";
import { AiOutlineArrowDown } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { ButtonView, TextArea, CircularButtonView } from "../../../controls";
import {
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
} from "../../../../constants/env";

const ShowNextYearLeaveDetail = ({
  user,
  initDoc,
  getDocument,
  userDocument,
  updateLeave,
  downloadFile,
  showNextYearLeave,
  leaveDependOnId,
  showLeaveById,
  closeLeaveByIdData,
  currentYear,
}) => {
  //  console.log(data)

  const [rejectOpen, setRejectOpen] = useState(false);
  const [rejectionVal, setRejectionVal] = useState("");
  const [rejectionId, setRejectionId] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (showLeaveById) {
      if (leaveDependOnId.attachementDocumentId) {
        getDocument(
          leaveDependOnId.employeeId[0],
          true,
          false,
          leaveDependOnId.attachementDocumentId
        );
      }
    }
  }, [getDocument, leaveDependOnId, showLeaveById]);

  const handleRejectOpen = (row) => {
    setRejectionId(row._id);
    setRejectOpen(true);
  };

  const handleApproveLeave = (row) => {
    if (window.confirm("Approve leave?")) {
      updateLeave(
        row._id,
        user,
        "",
        LEAVE_STATUS_APPROVED,
        "Successfully approved leave.",
        currentYear
      );
      handleClose();
    }
  };

  const handleRejectLeave = () => {
    var rules = {
      required: ["rejectionVal"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ rejectionVal }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      updateLeave(
        rejectionId,
        user,
        rejectionVal,
        LEAVE_STATUS_REJECTED,
        "Successfully rejected leave."
      );
      handleClose();
    }
  };

  const handleClose = () => {
    closeLeaveByIdData(false);
    setRejectOpen(false);
    initDoc();
    setRejectionId("");
    setErrors({});
    setRejectionVal("");
  };
  return (
    <>
      <div className="reject_modal">
        <Modal
          show={rejectOpen}
          onHide={handleClose}
          aria-labelledby="reject_modal"
        >
          <Modal.Header>
            <Modal.Title id="reject_modal">Rejection Reason</Modal.Title>
            <div onClick={handleClose} style={{ cursor: "pointer" }}>
              <AiOutlineClose size={20} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <TextArea
                name="comments"
                placeholder="Reason..."
                label="Kindly provide the reason for rejecting the leave."
                value={rejectionVal}
                type="textarea"
                setFocus={1}
                onChange={(e) => setRejectionVal(e.target.value)}
                size="fluid"
                error={errors["rejectionVal"]}
                isrequired={true}
              />
            </div>
            <Row>
              <Col md={12} lg={6} className="mb-2">
                <ButtonView
                  onClick={handleRejectLeave}
                  title={"Submit Rejection"}
                  styles="secondary"
                />
              </Col>
              <Col md={12} lg={6}>
                <ButtonView
                  onClick={handleClose}
                  title={"Cancel"}
                  styles="danger-outline"
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>

      {leaveDependOnId && (
        <div className="view_modal">
          <Modal
            show={showLeaveById}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Application Detail</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="comments ms-1">
                {leaveDependOnId.status === 0 && (
                  <h5>
                    Applier:{" "}
                    <span style={{ color: "#d87d2e" }}>
                      {leaveDependOnId.userId[0].firstName}{" "}
                      {leaveDependOnId.userId[0].lastName}
                    </span>
                  </h5>
                )}
              </div>
              {leaveDependOnId.status === -2 &&
                leaveDependOnId.rejectionOrCancellationComments && (
                  <div>
                    <div className="comments">
                      <h5>Cancellation Reason:</h5>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={leaveDependOnId.rejectionOrCancellationComments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  FD/1H/2H
                  <span>{leaveDependOnId.leaveSubType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Leave Type:
                  <span>{leaveDependOnId.masterLeaveType}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Sub Leave Type:
                  <span>{leaveDependOnId.leaveType.description}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Start Date:
                  <span>
                    {moment(new Date(leaveDependOnId.startDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  End Date:
                  <span>
                    {moment(new Date(leaveDependOnId.endDate)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </li>
                {leaveDependOnId.comments && (
                  <div className="mt-3">
                    <div className="comments">
                      <h6>Comments:</h6>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        // disabled
                        readOnly
                        value={leaveDependOnId.comments}
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                  </div>
                )}
                {userDocument ? (
                  <div className="d-flex justify-content-end mt-3">
                    <p className="mt-2">Download file</p>
                    <CircularButtonView
                      Icon={AiOutlineArrowDown}
                      onClick={() => {
                        downloadFile(userDocument.employeeId, userDocument._id);
                      }}
                      size={20}
                    />
                  </div>
                ) : (
                  ""
                )}
                {leaveDependOnId.status === 0 && (
                  <Row className="mt-3">
                    <Col md={12} lg={6} className="mb-2">
                      <ButtonView
                        onClick={() => handleApproveLeave(leaveDependOnId)}
                        title={"Approve"}
                        styles="secondary"
                      />
                    </Col>
                    <Col md={12} lg={6}>
                      <ButtonView
                        onClick={() => {
                          closeLeaveByIdData(false);
                          handleRejectOpen(leaveDependOnId);
                        }}
                        title={"Reject"}
                        styles="danger-outline"
                      />
                    </Col>
                  </Row>
                )}
              </ul>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ShowNextYearLeaveDetail;
