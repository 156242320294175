
export const DOMAIN = "https://connect.smartsight.in:312";

export const CLIENT_ID = "905234473983-u9h7iabqbg0ael2uqfn88gji4s1s9107.apps.googleusercontent.com";

export const COOKIE_DOMAIN = ".smartsight.in";

export const  LEAVE_STATUS_PENDING_APPROVAL = 0;
export const  LEAVE_STATUS_APPROVED = 1;
export const  LEAVE_STATUS_REJECTED = -1;
export const  LEAVE_STATUS_CANCELLED = -2;

export const JOB_APPLICAITON_STATUS_APPLIED = "Applied";
export const JOB_APPLICAITON_STATUS_IN_PROGRESS = "In-Progress";
export const JOB_APPLICAITON_STATUS_ONHOLD = "On-Hold";
export const JOB_APPLICAITON_STATUS_CANCELLED = "Cancelled";

export const INTERVIEW_STATUS_SCHEDULED = "Scheduled";
export const INTERVIEW_STATUS_ACCEPTED = "Accepted";
export const INTERVIEW_STATUS_COMPLETED = "Completed";
export const INTERVIEW_STATUS_INCOMPLETED = "Incomplete";
export const INTERVIEW_STATUS_REJECTED = "Rejected";
export const INTERVIEW_STATUS_CANCELLED = "Cancelled";

export const INTERVIEW_OUTCOME_SELECTED = "Selected";
export const INTERVIEW_OUTCOME_REJECTED = "Rejected";
export const INTERVIEW_OUTCOME_SCHEDULE_NEXT_ROUND = "Schedule Next Round";
export const HOLIDAY_TYPE_OPTIONAL = "Optional";
export const HOLIDAY_TYPE_REGULAR = "Regular";


