import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Card } from "react-bootstrap";
import moment from "moment";

import {
  INTERVIEW_STATUS_SCHEDULED,
  INTERVIEW_STATUS_ACCEPTED,
  HOLIDAY_TYPE_REGULAR,
} from "../../../../constants/env";

const Calender = ({
  holidaysList,
  accepted,

  date,
  desData,
  combineCalenderData,

  onsetCombineCalenderList,
  onsetDate,
  onsetDescription,
  totalInterviewScheduleList,
}) => {
  useEffect(() => {
    var combineDate = [];
    holidaysList
      .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
      .forEach((i) => {
        combineDate.push({
          des: `${i.description} (Holiday)`,
          interviewDate: i.date,
        });
      });
    onsetCombineCalenderList(combineDate);
    if (totalInterviewScheduleList.length) {
      // const interviewData = interviewScheduleList;
      const interviewData = totalInterviewScheduleList.filter(
        (list) =>
          list.status === INTERVIEW_STATUS_ACCEPTED ||
          list.status === INTERVIEW_STATUS_SCHEDULED
      );

      interviewData.forEach((i) => {
        combineDate.push(i);
      });
      onsetCombineCalenderList(combineDate);
    }
    const data = combineDate.filter((list) => {
      return list.interviewDate.slice(0, 10) === date;
    });
    // console.log(data);
    if (data) {
      onsetDescription(data);
    } else {
      onsetDescription([]);
    }
  }, [
    totalInterviewScheduleList,
    holidaysList,
    date,
    onsetCombineCalenderList,
    onsetDescription,
  ]);

  // console.log(accepted);
  var calenderData = [];
  holidaysList
    .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
    .forEach((i) => {
      calenderData.push({
        title: i.description,
        date: i.date,
        backgroundColor: "#CC0066",
        borderColor: "#CC0066",
      });
    });
  accepted
    .filter(
      (list, index, self) =>
        index === self.findIndex((i) => i.interviewDate === list.interviewDate)
    )
    .forEach((i) => {
      calenderData.push({
        title: "",
        date: i.interviewDate.slice(0, 10),
        backgroundColor: "#004C99",
        borderColor: "#004C99",
      });
    });

  const onDayClick = (e) => {
    onsetDate(e.dateStr);
  };

  return (
    <div className="column_divider">
      {/* <div className="symbols">
        <Card>
          <Card.Body>
            <Row className="calender_symbols">
              <Col
                md={6}
                className="d-flex my-auto mx-auto text-center justify-content-center"
              >
                <span className="dot_label dot_label--maroon me-2 my-auto"></span>
                <span style={{ fontSize: "14px" }}>Holiday</span>
              </Col>
              <Col
                md={6}
                className="d-flex my-auto mx-auto text-center justify-content-center"
              >
                <span className="dot_label dot_label--blue me-2 my-auto"></span>
                <span style={{ fontSize: "14px" }}>Interview(s)</span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div> */}
      <Card className="event_calender">
        <Card.Body>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            headerToolbar={{
              start: "prev",
              center: "title",
              end: "next",
            }}
            dateClick={onDayClick}
            initialView="dayGridMonth"
            events={calenderData}
            // editable={true}
            selectable={true}
            contentHeight={"auto"}
            eventClick={() => {}}
            // aspectRatio={0.7}
          />
        </Card.Body>
      </Card>
      <div className="border_divider">
        <Card
          className="today_date mt-3"
          style={{ background: "#242034", color: "#fff" }}
        >
          <Card.Body className="text-center py-2">
            {moment(date).format("MMMM Do YYYY")}, {moment(date).format("dddd")}
          </Card.Body>
        </Card>
      </div>
      <div className="interview_schedule_depend_on_date_des">
        {desData.length > 0 && desData ? (
          <div
            style={{ fontSize: "18px", color: "#242034" }}
            className="mt-3 mb-3"
          >
            {desData.map((list, i) => {
              return (
                <div key={i}>
                  {list.applicationId ? (
                    <div
                      key={list}
                      className="description_detail d-flex ms-2 mt-2"
                    >
                      <div className="num me-2">
                        <strong> {i + 1}.</strong>
                      </div>
                      <div className="description_detail_data">
                        <div className="data_detail">
                          <strong>Candidate:</strong>{" "}
                          {list.applicationId.firstName}{" "}
                          {list.applicationId.lastName} (
                          <span className="text-primary">{list.status}</span>)
                        </div>
                        <div className="data_detail">
                          <strong>Interviewer(s):</strong>{" "}
                          {list.interviewers.map((list, id, array) => {
                            if (id === array.length - 1) {
                              return (
                                <span key={list._id}>
                                  {list.userId[0].firstName}{" "}
                                  {list.userId[0].lastName}{" "}
                                </span>
                              );
                            } else {
                              return (
                                <span key={list._id}>
                                  {list.userId[0].firstName}{" "}
                                  {list.userId[0].lastName},{" "}
                                </span>
                              );
                            }
                          })}
                        </div>
                        <div className="data_detail">
                          <strong>Time:</strong>{" "}
                          {((parseInt(list.startTime) + 11) % 12) + 1}:
                          {list.startTime.split(":")[1]}
                          {parseInt(list.startTime) >= 12 ? "PM" : "AM"} to{" "}
                          {((parseInt(list.endTime) + 11) % 12) + 1}:
                          {list.endTime.split(":")[1]}{" "}
                          {parseInt(list.endTime) >= 12 ? "PM" : "AM"}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "18px",
                        color: "#d87d2e",
                      }}
                      className="mt-3 mb-3 text-center"
                    >
                      {list.des}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mt-3 mb-3 text-center text-muted">
            No data available on this day
          </div>
        )}
      </div>
    </div>
  );
};

export default Calender;
