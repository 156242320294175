import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import { IoPerson } from "react-icons/io5";
import { IoIosCopy } from "react-icons/io";
import { TiTick } from "react-icons/ti";

import { Header } from "../../common";
import { Input, TextArea, ButtonView } from "../../controls";
import EditProfile from "./components/EditProfile";
import AddFamilyMember from "./components/AddFamilyMember";

const ProfileView = ({
  user,
  locationList,
  handledownloadFile,
  getDocument,
  userDocument,
  showInsuranceBox,
  profileURL,
  updateProfile,
  addFamilyInsurance,
  familyMemberInsuranceList,
  editFamilyMemberInsurance,
  deleteFamilyMemberInsurance,
  familyInsuranceDocumentList,
  downloadFamilyInsuranceCard,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const textAreaRef = useRef(null);
  const copyToClipboard = (e) => {
    // navigator.clipboard.writeText(lunchCountData)
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 500);
  };

  useEffect(() => {
    if (user) {
      getDocument(user._id, false, true);
    }
  }, [getDocument, user]);

  return (
    <>
      <Header label={"Employee Profile"} Icon={IoPerson} />
      <div className="user_profile main__body padding">
        <Container>
          <Row>
            <Col>
              <Card className="mt-3 shadow-sm">
                <EditProfile
                  user={user}
                  profileURL={profileURL}
                  updateProfile={updateProfile}
                />

                <Card.Body className="d-flex justify-content-center align-items-center flex-column">
                  <div className="mt-3">
                    <h5>Profile Detail:</h5>
                    <Row>
                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="First Name"
                          value={user?.userId[0]?.firstName}
                          type="text"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                      </Col>
                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="Last Name"
                          value={user?.userId[0]?.lastName}
                          type="text"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                      </Col>
                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="Email"
                          value={user?.userId[0]?.email}
                          type="text"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                      </Col>
                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="Date of birth"
                          value={user?.userId[0]?.dob.slice(0, 10)}
                          type="date"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                        {/* <DatePicker
                          label="Date of birth"
                          value={new Date(user.userId[0].dob)}
                          onChange={() => {}}
                          readOnly
                          clearIcon={false}
                        /> */}
                      </Col>
                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="Phone Number"
                          value={
                            user?.userId[0].phoneNumber
                              ? user.userId[0].phoneNumber
                              : "-"
                          }
                          type="text"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                      </Col>
                      <Col md={12} lg={4} className="mb-3">
                        <TextArea
                          label="Address 1"
                          value={user?.userId[0]?.addressLine1}
                          type="text"
                          onChange={() => {}}
                          row={1}
                          size="fluid"
                          readOnly
                        />
                      </Col>
                      <Col md={12} lg={4} className="mb-3">
                        <TextArea
                          label="Address 2"
                          value={user?.userId[0]?.addressLine2}
                          type="text"
                          onChange={() => {}}
                          row={1}
                          size="fluid"
                          readOnly
                        />
                      </Col>
                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="City"
                          value={
                            user?.userId[0].city ? user.userId[0].city : "-"
                          }
                          type="text"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                      </Col>

                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="State"
                          value={
                            user?.userId[0].state ? user.userId[0].state : "-"
                          }
                          type="text"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                      </Col>
                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="Pincode"
                          value={
                            user?.userId[0].pinCode
                              ? user.userId[0].pinCode
                              : "-"
                          }
                          type="text"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                      </Col>
                      {/* <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="Location"
                          value={location.name}
                          type="text"
                          onChange={() => {}}
                         size="fluid"
                          readOnly
                        />
                      </Col> */}
                      <Col md={12} lg={4} className="mb-3">
                        <Input
                          label="Employee Number"
                          value={user?.employeeNumber}
                          type="text"
                          onChange={() => {}}
                          size="fluid"
                          readOnly
                        />
                      </Col>

                      {user?.insuranceNumber && (
                        <Col md={12} lg={4} className="mb-3">
                          <Row className="g-0">
                            <Col xs={10} md={10}>
                              <span className={`label text-muted`}>
                                Insurance Number
                              </span>
                              <input
                                value={user.insuranceNumber}
                                type="text"
                                className="input"
                                onChange={() => {}}
                                ref={textAreaRef}
                                readOnly
                              />
                            </Col>
                            <Col style={{ marginTop: "18px" }} xs={2} md={2}>
                              <ButtonView
                                onClick={copyToClipboard}
                                title={showSuccess ? <TiTick /> : <IoIosCopy />}
                                styles="secondary"
                                // tooltip="Copy to clipboard"
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col md={12} lg={6} className="mb-3 download_btn d-flex">
                        {showInsuranceBox &&
                          (userDocument ? (
                            <div>
                              <h5 className="mb-0 d-block">
                                Download Insurance Card:
                              </h5>
                              <div className="mt-2">
                                <ButtonView
                                  title="Download"
                                  onClick={() =>
                                    handledownloadFile(userDocument)
                                  }
                                  styles="secondary"
                                  size="medium"
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          ))}
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <AddFamilyMember
            addFamilyInsurance={addFamilyInsurance}
            familyMemberInsuranceList={familyMemberInsuranceList}
            editFamilyMemberInsurance={editFamilyMemberInsurance}
            deleteFamilyMemberInsurance={deleteFamilyMemberInsurance}
            familyInsuranceDocumentList={familyInsuranceDocumentList}
            downloadFamilyInsuranceCard={downloadFamilyInsuranceCard}
          />
        </Container>
      </div>
    </>
  );
};

export default ProfileView;
