import { actionTypes } from "./DesignationActions";

const {
  DESIGNATION_PENDING,
  DESIGNATION_ERROR,
  DESIGNATION_SUCCESS,

  DESIGNATION_ADD_PENDING,
  DESIGNATION_ADD_ERROR,
  DESIGNATION_ADD_SUCCESS,

  DESIGNATION_EDIT_PENDING,
  DESIGNATION_EDIT_ERROR,
  DESIGNATION_EDIT_SUCCESS,

  DESIGNATION_DELETE_PENDING,
  DESIGNATION_DELETE_ERROR,
  DESIGNATION_DELETE_SUCCESS,
} = actionTypes;

const initialState = {
  designationPending: false,
  designationSuccess: false,
  designationError: null,

  designationAddPending: false,
  designationAddSuccess: false,
  designationAddError: null,

  designationEditPending: false,
  designationEditSuccess: false,
  designationEditError: null,

  designationDeletePending: false,
  designationDeleteSuccess: false,
  designationDeleteError: null,

  designationList: [],
};

export default function designation(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DESIGNATION_PENDING:
    case DESIGNATION_ERROR:
    case DESIGNATION_SUCCESS:
      return Object.assign({}, state, payload);
    case DESIGNATION_ADD_PENDING:
    case DESIGNATION_ADD_ERROR:
      return Object.assign({}, state, payload);
    case DESIGNATION_ADD_SUCCESS: {
      const newHoliday = payload.designation;
      const newHolidaysList = [...state.designationList, newHoliday];

      const newState = {
        designationList: newHolidaysList,
        designationAddPending: payload.designationAddPending,
        designationAddSuccess: payload.designationAddSuccess,
        designationAddError: payload.designationAddError,
      };
      return Object.assign({}, state, newState);
    }
    case DESIGNATION_EDIT_PENDING:
    case DESIGNATION_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case DESIGNATION_EDIT_SUCCESS: {
      const newHolidaysList = state.designationList.map((list) => {
        if (list._id === payload.designation._id) {
          return payload.designation;
        } else {
          return list;
        }
      });
      return Object.assign(
        {},
        state,
        { designationList: newHolidaysList },
        payload
      );
    }
    case DESIGNATION_DELETE_PENDING:
    case DESIGNATION_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case DESIGNATION_DELETE_SUCCESS: {
      const newHolidaysList = state.designationList.filter((list) => {
        return list._id !== payload.id;
      });
      return Object.assign(
        {},
        state,
        { designationList: newHolidaysList },
        payload
      );
    }
    default:
      return state;
  }
}
