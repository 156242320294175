import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import All from "./All";
import Approved from "./Approved";
import Rejected from "./Rejected";
import Unapproved from "./Unapproved";
import LeaveFormModal from "./LeaveFormModal";
import Cancelled from "./Cancelled";
import ShowNextYearLeaveDetail from "./ShowNextYearLeaveDetail";

const MyLeaveApplications = ({
  user,
  leaveTypesList,
  userManagerList,
  applyForLeave,
  employeeLeaveList,
  updateLeave,
  leaveMasterTypeList,

  defaultTabActiveKey,
  onsetLeaveId,
  leaveId,
  getemployeeLeaveList,
  showNextYearLeave,
  getOtherLeavesSummary,
  getEmployeeLeavesSummary,

  showLeaveById,
  leaveById,
  closeLeaveByIdData,
  getNextYearLeaveDependOnId,
  currentYear,
  totalEmployeeLeaveList,
  onsetDate,
  getHolidays,
  holidaysList,

  canApplyOptionalHolidayLeave,
  canApply,
  getOptionalHolidayLeaveSummary,
}) => {
  // console.log("totalEmployeeLeaveList", totalEmployeeLeaveList);
  return (
    <div className="card">
      <LeaveFormModal
        user={user}
        leaveTypesList={leaveTypesList}
        userManagerList={userManagerList}
        applyForLeave={applyForLeave}
        leaveMasterTypeList={leaveMasterTypeList}
        empData={totalEmployeeLeaveList.filter(
          (list) => list.status === 0 || list.status === 1 || list.status === -1
        )}
        getemployeeLeaveList={getemployeeLeaveList}
        showNextYearLeave={showNextYearLeave}
        getOtherLeavesSummary={getOtherLeavesSummary}
        getEmployeeLeavesSummary={getEmployeeLeavesSummary}
        currentYear={currentYear}
        onsetDate={onsetDate}
        getHolidays={getHolidays}
        holidaysList={holidaysList}
        canApplyOptionalHolidayLeave={canApplyOptionalHolidayLeave}
        canApply={canApply}
        getOptionalHolidayLeaveSummary={getOptionalHolidayLeaveSummary}
      />
      <Tabs
        defaultActiveKey={defaultTabActiveKey}
        id="leave_application"
        className="table_tabs"
      >
        <Tab eventKey="pendingapproval" title="PENDING APPROVAL">
          <Unapproved
            data={employeeLeaveList.filter((list) => list.status === 0)}
            updateLeave={updateLeave}
            showNextYearLeave={showNextYearLeave}
            user={user}
            currentYear={currentYear}
          />
        </Tab>
        <Tab eventKey="approved" title="APPROVED">
          <Approved
            data={employeeLeaveList.filter((list) => list.status === 1)}
            updateLeave={updateLeave}
            showNextYearLeave={showNextYearLeave}
            user={user}
            onsetLeaveId={onsetLeaveId}
            leaveId={leaveId}
            getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
            currentYear={currentYear}
          />
        </Tab>
        <Tab eventKey="rejected" title="REJECTED">
          <Rejected
            data={employeeLeaveList.filter((list) => list.status === -1)}
            updateLeave={updateLeave}
            user={user}
            onsetLeaveId={onsetLeaveId}
            leaveId={leaveId}
            getNextYearLeaveDependOnId={getNextYearLeaveDependOnId}
          />
        </Tab>
        <Tab eventKey="cancelled" title="CANCELLED">
          <Cancelled
            data={employeeLeaveList.filter((list) => list.status === -2)}
            updateLeave={updateLeave}
            user={user}
          />
        </Tab>
        <Tab eventKey="all" title="ALL">
          <All data={employeeLeaveList} updateLeave={updateLeave} user={user} />
        </Tab>
      </Tabs>
      <ShowNextYearLeaveDetail
        leaveDependOnId={leaveById}
        showLeaveById={showLeaveById}
        closeLeaveByIdData={closeLeaveByIdData}
      />
    </div>
  );
};

export default MyLeaveApplications;

// <Col md={12} lg={6} className="mb-3 d-flex">
// <Input
//   name="startDate"
//   label="Start Date"
//   value={value}
//   type="date"
//   placeholder="First Name"
//   onChange={(e) => setValue(e.target.value)}
//   size="fluid"
// />
// <Input
//   name="startTime"
//   label="Start Time"
//   value={time}
//   type="time"
//   placeholder="First Name"
//   onChange={(e) => setTime(e.target.value)}
//   size="fluid"
// />
// </Col>
