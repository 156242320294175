import { actionTypes } from "./locationActions";

const { 
  LOCATION_PENDING, 
  LOCATION_ERROR, 
  LOCATION_SUCCESS,

  LOCATION_ADD_PENDING,
  LOCATION_ADD_ERROR,
  LOCATION_ADD_SUCCESS,

  LOCATION_EDIT_PENDING,
  LOCATION_EDIT_ERROR,
  LOCATION_EDIT_SUCCESS,

  LOCATION_DELETE_PENDING,
  LOCATION_DELETE_ERROR,
  LOCATION_DELETE_SUCCESS,
      } = actionTypes;

const initialState = {
  locationPending: false,
  locationSuccess: false,
  locationError: null,

  locationAddPending: false,
  locationAddSuccess: false,
  locationAddError: null,

  locationEditPending: false,
  locationEditSuccess: false,
  locationEditError: null,

  locationDeletePending: false,
  locationDeleteSuccess: false,
  locationDeleteError: null,

  locationList: [],
};

export default function lunch(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOCATION_PENDING:
    case LOCATION_ERROR:
    case LOCATION_SUCCESS:
      return Object.assign({}, state, payload);
    case LOCATION_ADD_PENDING:
    case LOCATION_ADD_ERROR:
      return Object.assign({}, state, payload);
    case LOCATION_ADD_SUCCESS: {
      const newRole = payload.location;
      const newLocationList = [...state.locationList, newRole];

      const newState = {
        locationList: newLocationList,
        locationAddPending: payload.locationAddPending,
        locationAddSuccess: payload.locationAddSuccess,
        locationAddError: payload.locationAddError,
      }
      return Object.assign({}, state, newState);
    }
    case LOCATION_EDIT_PENDING:
    case LOCATION_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case LOCATION_EDIT_SUCCESS: {
      const newLocationList = state.locationList.map(list => {
        if(list._id === payload.location._id) {
          return payload.location
        } else {
          return list
        }
      })
      return Object.assign({}, state, {locationList: newLocationList}, payload);
    }
    case LOCATION_DELETE_PENDING:
    case LOCATION_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case LOCATION_DELETE_SUCCESS: {
      const newLocationList = state.locationList.filter(list => {
        return list._id !== payload.id
      })
      return Object.assign({}, state, {locationList: newLocationList}, payload);
    }
    default:
      return state;
  }
}
