import React, { useState } from "react";
import { Card, Modal, Row, Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { DataTable } from "../../../common";
import { ButtonView, Input } from "../../../controls";

const LevelMngt = ({ addLevel, editLevel, deleteLevel, levelList }) => {
  // console.log(levelList)
  const [showAddLevel, setShowAddLocation] = useState(false);
  const [level, setLevel] = useState("");
  const [levelNum, setLevelNum] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [levelId, setLevelId] = useState("");

  const columns = [
    {
      name: "Level Name",
      selector: "description",
      sortable: true,
      grow: 8,
    },
    {
      name: "Option",
      center: true,
      minWidth: "150px",
      grow: 2,
      cell: (row) => (
        <div className="d-flex">
          <div className="me-1">
            <ButtonView
              onClick={() => {
                setShowEdit(true);
                setLevel(row.description);
                setLevelNum(row.number);
                setShowAddLocation(true);
                setLevelId(row._id);
              }}
              title={"Edit"}
              size="small"
              styles="secondary"
            />
          </div>
          <ButtonView
            onClick={() => {
              if (window.confirm("Delete level?")) {
                // console.log(row._id)
                deleteLevel(row._id);
              }
            }}
            title={"Delete"}
            size="small"
            styles="danger-outline"
          />
        </div>
      ),
    },
  ];

  const handleAddRole = () => {
    var rules = { required: ["level", "levelNum"] };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData({ level, levelNum }),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      if (showEdit) {
        editLevel(level, parseInt(levelNum), levelId);
        // edit
      } else {
        addLevel(level, parseInt(levelNum));
        // add
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setShowAddLocation(false);
    setLevel("");
    setLevelNum("");
    setShowEdit(false);
    setErrors({});
  };

  return (
    <div className="permission_management mt-3">
      <Card>
        <Card.Body>
          <Card.Header>
            <Card.Title>
              <div className="d-block d-md-none">Level</div>
              <div className="d-none d-md-block">Level Management</div>
            </Card.Title>
            <div className="add_permission">
              <ButtonView
                onClick={() => setShowAddLocation(true)}
                title={"Add Level"}
                // styles="secondary"
              />
            </div>
          </Card.Header>
          <DataTable
            data={levelList}
            columns={columns}
            pagination={true}
            // defaultSortField={"name"}
            paginationPerPage={2}
            paginationServer={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showAddLevel}
        onHide={handleClose}
        aria-labelledby="add_role_modal"
      >
        <Modal.Header>
          <Modal.Title id="add_role_modal">
            {showEdit ? "Edit Level" : "Add Level"}
          </Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="add_role">
            <Row className="mb-2 d-flex justify-content-end">
              <Col xs={12}>
                <Input
                  name="level"
                  label="Add Level"
                  value={level}
                  type="text"
                  placeholder="Add Level"
                  onChange={(e) => setLevel(e.target.value)}
                  size="fluid"
                  // setFocus={1}
                  error={errors["level"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={12} className="mt-3">
                <Input
                  name="levelNum"
                  label="Add Number"
                  value={levelNum}
                  type="number"
                  placeholder="Add Level Number"
                  onChange={(e) => setLevelNum(e.target.value)}
                  size="fluid"
                  error={errors["levelNum"]}
                  isrequired={true}
                />
              </Col>
              <Col xs={4} className="mt-3">
                <ButtonView
                  onClick={handleAddRole}
                  title={showEdit ? "Save" : "Add"}
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LevelMngt;
