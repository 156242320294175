import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import connect from "react-redux/es/connect/connect";
import { getFamilyMembersInsurance } from "../../../../store/familyInsurance/familyInsuranceActions";
import {
  ButtonView,
  Input,
  Select,
  TextArea,
  DatePicker,
} from "../../../controls";
import FamilyInfo from "./FamilyInfo";

const confirmationOption = [
  { label: 'No', value: false },
  { label: 'Yes', value: true }
];

const EditUser = ({
  editUserShow,
  handleClose,
  userData,
  errors,
  setUserData,
  roleOptions,
  locationOptions,
  showInsuranceBox,
  userDocument,
  handleUpload,
  handleSubmit,
  onLoadFile,
  deleteCard,
  handledownloadFile,
  levelOptions,

  userManagerOptions,
  employeeId,
  getFamilyMembersInsurance,
  empId,
  state,
  familyMemberInsuranceList,
  familyInsuranceSuccess,
}) => {
  console.log("userData", userData.phoneNumber);
  useEffect(() => {
    if (editUserShow) {
      getFamilyMembersInsurance(empId);
    }
  }, [getFamilyMembersInsurance, editUserShow, empId]);
  // console.log("userData", userData);

  return (
    <Modal
      size="lg"
      show={editUserShow}
      onHide={handleClose}
      aria-labelledby="edit_user_modal"
    >
      <Modal.Header>
        <Modal.Title id="edit_user_modal">Update User</Modal.Title>
        <div onClick={handleClose} style={{ cursor: "pointer" }}>
          <AiOutlineClose size={20} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="firstName"
              label="First Name"
              value={userData.firstName}
              type="text"
              placeholder="First Name"
              onChange={(e) =>
                setUserData({ ...userData, firstName: e.target.value })
              }
              size="fluid"
              error={errors["firstName"]}
              isrequired={true}
              maxLength={25}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="lastname"
              label="Last Name"
              value={userData.lastName}
              type="text"
              placeholder="Last Name"
              onChange={(e) =>
                setUserData({ ...userData, lastName: e.target.value })
              }
              size="fluid"
              error={errors["lastName"]}
              isrequired={true}
              maxLength={25}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="email"
              label="Email"
              value={userData.email}
              type="email"
              placeholder="Email"
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
              size="fluid"
              error={errors["email"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <DatePicker
              name="dob"
              label="Date of birth"
              value={userData.dob}
              onChange={(e) => setUserData({ ...userData, dob: e })}
              error={errors["dob"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="phoneNumber"
              label="Phone Number"
              value={userData.phoneNumber}
              type="tel"
              isNum={true}
              placeholder="Phone Number"
              onChange={(e) =>
                setUserData({ ...userData, phoneNumber: e.target.value })
              }
              size="fluid"
              error={errors["phoneNumber"]}
              // isrequired={true}
              maxLength={10}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="emergencyContactNumber"
              label="Emergency Contact"
              value={userData.emergencyContactNumber}
              type="tel"
              isNum={true}
              placeholder="Emergency Contact"
              onChange={(e) =>
                setUserData({
                  ...userData,
                  emergencyContactNumber: e.target.value,
                })
              }
              size="fluid"
              error={errors["emergencyContactNumber"]}
              // isrequired={true}
              maxLength={10}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="employeeNumber"
              label="Employee Id"
              type="tel"
              isNum={true}
              value={userData.employeeNumber}
              placeholder="Employee Id"
              onChange={(e) =>
                setUserData({ ...userData, employeeNumber: e.target.value })
              }
              size="fluid"
              error={errors["employeeNumber"]}
              isrequired={true}
              maxLength={5}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <DatePicker
              name="joiningDate"
              label="Joining Date"
              value={userData.joiningDate}
              onChange={(e) => setUserData({ ...userData, joiningDate: e })}
              error={errors["joiningDate"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Select
              name="isConfirmed"
              label="Is Confirmed"
              isSearchable={false}
              defaultValue={confirmationOption.find(
                (option) => option.value === userData.isConfirmed
              )}
              placeholder="Select Confirmation"
              options={confirmationOption}
              onChange={(val) => {
                setUserData({
                  ...userData,
                  isConfirmed: val.value,
                });
              }}
              className="select "
              classNamePrefix="select"
              error={errors["isConfirmed"]}
              isrequired={false}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <DatePicker
              name="confirmationDate"
              label="Confirmation Date"
              value={userData.confirmationDate}
              onChange={(e) => setUserData({ ...userData, confirmationDate: e })}
              error={errors["confirmationDate"]}
              isrequired={false}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <TextArea
              name="addressLine1"
              label="Address 1"
              value={userData.addressLine1}
              type="text"
              placeholder="Address"
              onChange={(e) =>
                setUserData({ ...userData, addressLine1: e.target.value })
              }
              size="fluid"
              error={errors["addressLine1"]}
              isrequired={true}
              maxLength={300}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <TextArea
              name="addressLine2"
              label="Address 2"
              value={userData.addressLine2}
              type="text"
              placeholder="Address"
              onChange={(e) =>
                setUserData({ ...userData, addressLine2: e.target.value })
              }
              size="fluid"
              error={errors["addressLine2"]}
              isrequired={true}
              maxLength={300}
            />
          </Col>

          <Col md={12} lg={6} className="mb-3">
            <Input
              name="city"
              label="City"
              type="text"
              value={userData.city}
              placeholder="City"
              onChange={(e) =>
                setUserData({ ...userData, city: e.target.value })
              }
              size="fluid"
              error={errors["city"]}
              maxLength={30}
              // isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="state"
              label="State"
              type="text"
              value={userData.state}
              placeholder="State"
              onChange={(e) =>
                setUserData({ ...userData, state: e.target.value })
              }
              size="fluid"
              error={errors["state"]}
              // isrequired={true}
              maxLength={30}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="pinCode"
              label="Zip Code"
              type="tel"
              isNum={true}
              value={userData.pinCode}
              placeholder="Zip Code"
              onChange={(e) =>
                setUserData({ ...userData, pinCode: e.target.value })
              }
              size="fluid"
              error={errors["pinCode"]}
              // isrequired={true}
              maxLength={10}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Select
              name="reportingManagerEmployeeId"
              label="Manager"
              isSearchable={false}
              defaultValue={userManagerOptions.find(
                (option) => option.value === userData.reportingManagerEmployeeId
              )}
              placeholder="Select Manager"
              options={userManagerOptions}
              onChange={(val) => {
                // console.log(val);
                setUserData({
                  ...userData,
                  reportingManagerEmployeeId: val.value,
                });
              }}
              className="select "
              classNamePrefix="select"
              error={errors["reportingManagerEmployeeId"]}
              isrequired={true}
            />
          </Col>

          <Col md={12} lg={6} className="mb-3">
            <Select
              name="role"
              label="Role"
              isSearchable={false}
              defaultValue={roleOptions.find(
                (option) => option.value === userData.role
              )}
              placeholder="Select Role"
              options={roleOptions}
              onChange={(val) => setUserData({ ...userData, role: val.value })}
              className="select"
              classNamePrefix="select"
              error={errors["role"]}
              isrequired={true}
            />
          </Col>

          <Col md={12} lg={6} className="mb-3">
            <Select
              name="level"
              label="Level"
              isSearchable={false}
              defaultValue={levelOptions.find(
                (option) => option.value === userData.level
              )}
              placeholder="Select Level"
              options={levelOptions}
              onChange={(val) => setUserData({ ...userData, level: val.value })}
              className="select "
              classNamePrefix="select"
              error={errors["level"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Select
              name="location"
              label="Location"
              isSearchable={false}
              defaultValue={locationOptions.find(
                (option) => option.value === userData.location
              )}
              placeholder="Select Location"
              options={locationOptions}
              onChange={(val) =>
                setUserData({ ...userData, location: val.value })
              }
              className="select"
              classNamePrefix="select"
              error={errors["location"]}
              isrequired={true}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3">
            <Input
              name="insuranceNumber"
              label="Insurance Number"
              value={userData.insuranceNumber}
              type="tel"
              isNum={true}
              placeholder="Insurance Number"
              onChange={(e) =>
                setUserData({ ...userData, insuranceNumber: e.target.value })
              }
              size="fluid"
              error={errors["insuranceNumber"]}
              maxLength={30}
            />
          </Col>
          <Col md={12} lg={6} className="mb-3 d-flex">
            {showInsuranceBox &&
              (userDocument ? (
                <div className="d-flex align-items-start flex-column upload_card">
                  <div className="label text-muted me-3">
                    Insurance Card Uploaded
                  </div>
                  <div className="d-flex align-items-center">
                    <ButtonView
                      onClick={() =>
                        handledownloadFile(userDocument.employeeId)
                      }
                      title={"Download Card"}
                      styles="secondary"
                    />
                    <div className="ms-1">
                      <ButtonView
                        onClick={() => deleteCard(userDocument._id)}
                        title={"Delete Card"}
                        styles="danger-outline"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Input
                    name="insuranceCard"
                    label="Upload Insurance Card"
                    type="file"
                    placeholder="Upload File"
                    onChange={onLoadFile}
                    size="fluid"
                    accept=".pdf,.png,.jpg,.doc,.docx"
                  />
                  <div className="mt-3 ms-3">
                    <ButtonView
                      onClick={handleUpload}
                      title={"Upload"}
                      styles="secondary"
                    />
                  </div>
                </>
              ))}
          </Col>
        </Row>

        <Row className="d-flex justify-content-end">
          <Col xs={6} md={6} lg={3}>
            {/* <Col md={12} lg={{ span: 6, offset: 3 }} className="mb-3"> */}

            <ButtonView
              onClick={handleClose}
              title={"Cancel"}
              styles="danger-outline"
            />
          </Col>
          <Col xs={6} md={6} lg={3}>
            <ButtonView
              onClick={() => handleSubmit()}
              title={"Update"}
              styles="secondary"
            />
          </Col>
        </Row>
        {familyInsuranceSuccess === true && (
          <>
            <hr />
            <div className="family__info">
              <Row>
                <h4 className="family__info--title">
                  Family Member Information
                </h4>
                {familyMemberInsuranceList.length > 0 ? (
                  <div className="family__info--container mt-3">
                    {familyMemberInsuranceList.map((item, num) => {
                      return (
                        <FamilyInfo
                          key={item._id}
                          data={item}
                          num={num}
                          empId={empId}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <p>No data available</p>
                )}
              </Row>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  // familyMemberList: state.user.familyMemberList,
  // familyMemberListSuccess: state.user.familyMemberListSuccess,
  familyMemberInsuranceList:
    state.familyInsuranceReducer.familyMemberInsuranceList,
  familyInsuranceSuccess: state.familyInsuranceReducer.familyInsuranceSuccess,
});

export default connect(mapStateToProps, {
  // getFamilyMemberList,
  getFamilyMembersInsurance,
})(EditUser);
