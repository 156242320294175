import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ButtonView, Input } from "../../../controls";
import connect from "react-redux/es/connect/connect";
import {
  editFamilyMemberInsurance,
  getFamilyMemberInsuranceDoc,
  downloadFamilyInsuranceCard,
  removefamilyMemberInsuranceCard,
  uploadMemberInsuranceCard,
} from "../../../../store/familyInsurance/familyInsuranceActions";
const FamilyInfo = ({
  data,
  num,
  editFamilyMemberInsurance,
  getFamilyMemberInsuranceDoc,
  familyInsuranceDocumentList,
  downloadFamilyInsuranceCard,
  removefamilyMemberInsuranceCard,
  uploadMemberInsuranceCard,
  empId,
}) => {
  useEffect(() => {
    getFamilyMemberInsuranceDoc("family_insurance_card", empId);
  }, [getFamilyMemberInsuranceDoc, empId]);
  // console.log("hi", data.employeeId._id);
  // console.log("emp", empId);
  const [file, setFile] = useState(null);

  const [state, setState] = useState({
    fields: {
      firstName: data.firstName,
      lastName: data.lastName,
      insuranceNumber: data.insuranceNumber,
      employeeId: data.employeeId._id,
      relation: data.relation,
    },
  });

  const onLoadFile = (e) => {
    //console.log(e.target.files);
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      uploadMemberInsuranceCard(file, data.employeeId._id, data._id);
      setFile(null);
    } else {
      alert("No file selected");
    }
  };

  const handleChange = (field) => (e) => {
    let value;

    const isDate = e instanceof Date;
    const isTextField = e.target;
    if (isDate) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }
    setState({
      ...state,
      fields: {
        ...state.fields,
        [field]: value,
      },
    });
  };
  const hasDoc = familyInsuranceDocumentList.find((item) => {
    // console.log("data?._id", item);

    if (data?._id === item.familyMemberId) {
      return true;
    } else {
      return false;
    }
  });

  const updateInsuranceNo = () => {
    // console.log(state.fields);
    editFamilyMemberInsurance(state.fields, data?._id);
  };

  const deleteCard = (data) => {
    if (
      window.confirm(
        "Do you want to delete selected family member insurance card?"
      )
    ) {
      removefamilyMemberInsuranceCard(data._id);
    }
  };

  return (
    <div className="family__info--content mb-3">
      <div className="family__content--header mb-1">
        <h5 className="family__content--title">
          {`${num + 1})`} {data?.firstName} {data?.lastName}{" "}
          {`(${data?.relation})`}
        </h5>
      </div>
      <Row>
        <Col md={12} lg={6} className="mb-3">
          <Input
            name="insuranceNumber"
            label="Insurance Number"
            value={state.fields.insuranceNumber}
            type="tel"
            isNum={true}
            placeholder="Insurance Number"
            onChange={handleChange("insuranceNumber")}
            size="fluid"
          />
          <div className="mt-2">
            <ButtonView
              onClick={updateInsuranceNo}
              title={"Save"}
              size={"medium"}
              styles={"secondary"}
              // 4.0.3
            />
          </div>
        </Col>
        <Col md={12} lg={6} className="mb-3">
          {hasDoc ? (
            <div className="d-flex mt-1 align-items-start flex-column upload_card">
              <div className="label text-muted me-3">
                Insurance Card Uploaded
              </div>
              <div className="d-flex align-items-center">
                <ButtonView
                  onClick={() => {
                    downloadFamilyInsuranceCard(
                      data?._id,
                      "family_insurance_card",
                      empId
                    );
                  }}
                  title={"Download Card"}
                  styles="secondary"
                />
                <div className="ms-1">
                  <ButtonView
                    onClick={() => deleteCard(hasDoc)}
                    title={"Delete Card"}
                    styles="danger-outline"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <Input
                name="insuranceCard"
                label="Upload Insurance Card"
                type="file"
                placeholder="Upload File"
                onChange={onLoadFile}
                size="fluid"
                accept=".pdf,.png,.jpg,.doc,.docx"
              />
              <div className="mt-2">
                <ButtonView
                  onClick={handleUpload}
                  title={"Upload"}
                  size={"medium"}
                  styles="secondary"
                />
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  familyInsuranceDocumentList:
    state.familyInsuranceReducer.familyInsuranceDocumentList,
  showInsuranceBox: state.familyInsuranceReducer.showInsuranceBox,
});

export default connect(mapStateToProps, {
  editFamilyMemberInsurance,
  getFamilyMemberInsuranceDoc,
  downloadFamilyInsuranceCard,
  removefamilyMemberInsuranceCard,
  uploadMemberInsuranceCard,
})(FamilyInfo);
