import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import UserDetails from "./UserDetails";
import EmployeeDetails from "./EmployeeDetails";

const data = {
  firstName: "",
  lastName: "",
  dateOfBirth: new Date(),
  email: "",
  phoneNumber: "",
  emergencyContactNumber: "",
  joiningDate: new Date(),
  addressLineOne: "address 1",
  addressLineTwo: "address 2",
  addressLineThree: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
  role: "",

  location: "",
  empId: "1",
  level: "",
  managerId: "",
};

const AddUser = ({
  addUserShow,
  handleClose,
  locationList,
  roleList,
  levelList,
  createUser,
  userDataDetails,
  currentPage,
  userManager,
  onresetFilterPagination,
}) => {
  // console.log(userManager)
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState(data);
  const [showNext, setShowNext] = useState(false);
  // console.log(userDataDetails.user.userId.firstName)
  const locationOptions = locationList.map((location) => {
    return { value: location._id, label: location.name };
  });
  const roleOptions = roleList.map((role) => {
    return { value: role._id, label: role.name };
  });
  const levelOptions = levelList.map((role) => {
    return { value: role._id, label: role.description };
  });
  const userManagerOptions = userManager.map((role) => {
    return {
      value: role._id,
      label: `${role.userId[0].firstName}  ${role.userId[0].lastName}`,
    };
  });

  const handleSubmitNext = () => {
    var rules = {
      required: [
        "firstName",
        "lastName",
        "dateOfBirth",
        "email",
        "role",
        // "phoneNumber",
        "joiningDate",
        "addressLineOne",
        "addressLineTwo",
        // "city",
        // "state",
        // "zipCode",
        // "country",
      ],
      custom: [validator.email(["email"])],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(userData),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      setShowNext(true);
    }
  };

  const handleSubmit = () => {
    var rules = {
      required: ["location", "level", "managerId", "empId"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(userData),
    });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      const userDtails = {
        personalDetails: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          phoneNumber: userData.phoneNumber,
          emergencyContactNumber: userData.emergencyContactNumber,
          email: userData.email,
          addressLine1: userData.addressLineOne,
          addressLine2: userData.addressLineTwo,
          city: userData.city,
          state: userData.state,
          pinCode: userData.zipCode,
          country: userData.country,
          dob: moment(userData.dateOfBirth).format("YYYY-MM-DD"),
          role: userData.role,
          isActive: "1",
          createdBy: `${userDataDetails.user.userId[0].firstName} ${userDataDetails.user.userId[0].lastName}`,
          updatedBy: `${userDataDetails.user.userId[0].firstName} ${userDataDetails.user.userId[0].lastName}`,
        },

        employeeDetails: {
          employeeNumber: userData.empId,
          level: userData.level,
          location: [userData.location],
          reportingManagerEmployeeId: userData.managerId,
          isActive: "1",
          insuranceNumber: "",
          joiningDate: moment(userData.joiningDate).format("YYYY-MM-DD")
        },
      };
      // console.log(userDtails)
      createUser(userDtails, currentPage);
      onresetFilterPagination();
      handleClose();
      setErrors({});
      setUserData(data);
      setShowNext(false);

      // console.log(userData)
      // console.log(userDtails, currentPage)
    }
  };

  return (
    <div className="add_user_modal">
      <Modal
        size="lg"
        show={addUserShow}
        onHide={() => {
          handleClose();
          setErrors({});
          setUserData(data);
          setShowNext(false);
        }}
        aria-labelledby="add_user_modal"
        animation={false}
      >
        <Modal.Header>
          <Modal.Title id="add_user_modal">New User</Modal.Title>
          <div
            onClick={() => {
              handleClose();
              setErrors({});
              setUserData(data);
              setShowNext(false);
            }}
            style={{ cursor: "pointer" }}
          >
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          {!showNext ? (
            <UserDetails
              userData={userData}
              setUserData={setUserData}
              errors={errors}
              roleOptions={roleOptions}
              locationOptions={locationOptions}
              handleSubmitNext={handleSubmitNext}
            />
          ) : (
            <EmployeeDetails
              userData={userData}
              setUserData={setUserData}
              errors={errors}
              roleOptions={roleOptions}
              locationOptions={locationOptions}
              handleSubmit={handleSubmit}
              setShowNext={setShowNext}
              levelOptions={levelOptions}
              userManagerOptions={userManagerOptions}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddUser;
