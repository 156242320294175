import React from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

import { ButtonView } from "../../../controls";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Download extends React.Component {
  render() {
    const dataSet1 = this.props.employeeLeaveList;
    console.log("dataSet1", dataSet1);
    return (
      <ExcelFile
        element={
          <div style={{ width: "150px" }} className="me-3">
            <ButtonView onClick={() => {}} title={"Export To Excel"} />
          </div>
        }
        filename={`${this.props.currentYear} leave list`}
        bold={true}
      >
        <ExcelSheet data={dataSet1} name="Employee">
          <ExcelColumn
            label="Start Date"
            value={(col) =>
              moment(new Date(col.startDate)).format("DD/MM/YYYY")
            }
          />
          <ExcelColumn
            label="End Date"
            value={(col) => moment(new Date(col.endDate)).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Status"
            value={(col) => {
              if (col.status === 0) {
                return "Pending Approval";
              }
              if (col.status === 1) {
                return "Approved";
              }
              if (col.status === -1) {
                return "Rejected";
              }
              if (col.status === -2) {
                return "Cancelled";
              }
            }}
          />
          <ExcelColumn label="Type" value="masterLeaveType" />
          <ExcelColumn
            label="Sub Leave Type"
            value={(col) => col.leaveType.description}
          />
          <ExcelColumn label="FD/1H/2H" value="leaveSubType" />
          <ExcelColumn label="Total Days" value="totalDays" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

/* <ExcelSheet dataSet={styledMultiDataSet} name="Employee" /> */
// const styledMultiDataSet = [
//   {
//     columns: [
//       {
//         value: "Start Date",
//         widthPx: 160,
//         style: { font: { sz: "24", bold: true } },
//       },
//       {
//         value: "End Date",
//         widthPx: 180,
//         style: { font: { sz: "24", bold: true } },
//       },
//       {
//         value: "Type",
//         style: { font: { sz: "24", bold: true } },
//       },
//       {
//         value: "Sub Leave Type",
//         style: { font: { sz: "24", bold: true } },
//       },
//       {
//         value: "FD/1H/2H",
//         style: { font: { sz: "24", bold: true } },
//       },
//       {
//         value: "Total Days",
//         style: { font: { sz: "24", bold: true } },
//       },
//     ],
//     data: dataSet1.map((list) => {
//       return [
//         moment(new Date(list.startDate)).format("DD/MM/YYYY"),
//         moment(new Date(list.endDate)).format("DD/MM/YYYY"),
//         list.masterLeaveType,
//         list.leaveType.description,
//         list.leaveSubType,
//         list.totalDays,
//       ];
//     }),
//   },
// ];
