import React from "react";
import moment from "moment";

import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";

import { ButtonView, Pagination } from "../../../controls";
import { DataTable } from "../../../common";
import { LEAVE_STATUS_CANCELLED } from "../../../../constants/env";
import LoaderView from "./LoaderView";

const Cancelled = ({
  //   data,
  onsetLeaveId,
  leaveId,
  getNextYearLeaveDependOnId,
  userWfhList,
  rowsPerPage,
  rowCount,
  currentPage,
  getUserWfhList,
  getUserWfh,
  getDocument,
  userWfhListSuccess,
  changingYear,
}) => {
  // console.log("changingyr", changingYear);
  const columns = [
    {
      name: "Start Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.startDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "End Date",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>{moment(new Date(row.endDate)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      name: "Status",
      center: true,
      grow: 4,
      cell: (row) => (
        <span className="badge bg-cancel-transparent">Cancelled</span>
      ),
    },
    // {
    //   name: "Type",
    //   selector: "masterLeaveType",
    //   center: true,
    //   grow: 4,
    // },
    {
      name: "FD/1H/2H",
      selector: "leaveSubType",
      center: true,
      grow: 4,
    },
    {
      name: "Employee",
      center: true,
      grow: 4,
      cell: (row) => (
        <span>
          {row.userId[0].firstName} {row.userId[0].lastName.slice(0, 1)}
        </span>
      ),
    },
    {
      name: "Detail",
      center: true,
      minWidth: "130px",
      // grow: 4,
      cell: (row) => (
        <div className="d-flex">
          <ButtonView
            onClick={() => {
              getUserWfh(row._id);

              // handleViewOpen()
            }}
            title={"View"}
            size="small"
            styles="secondary"
          />
        </div>
      ),
    },
  ];

  const onChangePage = (currentPage) => {
    getUserWfhList(
      currentPage,
      {
        status: LEAVE_STATUS_CANCELLED,
        year: changingYear,
      },
      false
    );
  };

  if (userWfhListSuccess === false) {
    return <LoaderView />;
  } else {
    return (
      <>
        <div className="table_content px-2 attendance_table pb-3">
          <DataTable
            data={userWfhList}
            columns={columns}
            pagination={false}
            paginationPerPage={5}
            paginationServer={false}
          />
        </div>

        {userWfhList.length > 0 ? (
          <div className="margin_x mt-2 me-3 mb-3 d-flex justify-content-end">
            <Pagination
              rowsPerPage={rowsPerPage}
              rowCount={rowCount}
              onChangePage={onChangePage}
              currentPage={currentPage}
            />
          </div>
        ) : null}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  // console.log("unapproved", state.employeeWfhReducer);
  return {
    userWfhList: state.employeeWfhReducer.userWfhList,
    page: state.employeeWfhReducer.page,
    userWfhListSuccess: state.employeeWfhReducer.userWfhListSuccess,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getUser: getUser,
      // applyForWfh: applyForWfh,
      // reportingMangerUsersList: reportingMangerUsersList,
      // getUserWfhList: getUserWfhList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Cancelled);
