import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Card } from "react-bootstrap";

import {
  HOLIDAY_TYPE_REGULAR,
  HOLIDAY_TYPE_OPTIONAL,
} from "../../../../constants/env";

const Calender = ({
  holidaysList,
  unapproved,
  approved,
  rejected,
  //optionalholiday,

  onsetDate,
  onsetDescription,
  combineCalenderData,

  srollToSummary,
}) => {
  var calenderData = [];
  // console.log("option", holidaysList);
  // console.log("combineCalenderData", combineCalenderData);
  holidaysList
    .filter((list) => list.type === HOLIDAY_TYPE_REGULAR)
    .forEach((i) => {
      // console.log("holiday", i);
      // console.log("holiday22", i.date);
      calenderData.push({
        title: i.description,
        date: i.date,
        backgroundColor: "#CC0066",
        borderColor: "#CC0066",
      });
    });
  holidaysList
    .filter((list) => list.type === HOLIDAY_TYPE_OPTIONAL)
    .forEach((i) => {
      // console.log("holiday optrion", i);
      calenderData.push({
        title: i.description,
        date: i.date,
        backgroundColor: "#408E91",
        borderColor: "#408E91",
      });
    });
  approved.forEach((i) => {
    // console.log("approved", i);
    if (i.startDate === i.endDate) {
      calenderData.push({
        title: "",

        date: i.startDate.slice(0, 10),

        backgroundColor: "#009900",
        borderColor: "#009900",
      });
    } else {
      calenderData.push({
        title: "",
        start: i.startDate,
        end: i.endDate,

        backgroundColor: "#009900",
        borderColor: "#009900",
      });
    }

    // calenderData.push({ title: "", date: i.startDate.slice(0, 10), backgroundColor: '#009900', borderColor: "#009900" })
  });
  rejected.forEach((i) => {
    if (i.startDate === i.endDate) {
      calenderData.push({
        title: "",
        date: i.startDate.slice(0, 10),
        backgroundColor: "#FF3333",
        borderColor: "#FF3333",
      });
    } else {
      calenderData.push({
        title: "",
        start: i.startDate,
        end: i.endDate,
        backgroundColor: "#FF3333",
        borderColor: "#FF3333",
      });
    }
  });

  // calenderData.push({ title: "", date: i.startDate.slice(0, 10), backgroundColor: '#FF3333', borderColor: "#FF3333" })

  unapproved.forEach((i) => {
    if (i.startDate === i.endDate) {
      calenderData.push({
        title: "",
        date: i.startDate.slice(0, 10),
        backgroundColor: "#004C99",
        borderColor: "#004C99",
      });
    } else {
      calenderData.push({
        title: "",
        start: i.startDate,
        end: i.endDate,
        backgroundColor: "#004C99",
        borderColor: "#004C99",
      });
    }

    // calenderData.push({ title: "", date: i.startDate.slice(0, 10), backgroundColor: '#004C99', borderColor: "#004C99" })
  });

  const onDayClick = (e) => {
    // console.log("e", e);
    const data = combineCalenderData.find((list) => {
      // console.log("list", list);

      return list.date === e.dateStr;
    });
    // console.log("liststr", data.des);
    onsetDate(e.dateStr);
    if (data && data.des) {
      onsetDescription(data.des);
    } else {
      onsetDescription("");
    }
    if (srollToSummary.current) {
      srollToSummary.current.scrollIntoView({
        //  behavior: "smooth",
      });
    }
  };
  // console.log("calender", calenderData);

  return (
    <Card className="event_calender">
      <Card.Body>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            start: "prev",
            center: "title",
            end: "next",
          }}
          dateClick={onDayClick}
          initialView="dayGridMonth"
          events={
            calenderData
            // [
            // { title: '', date: '2021-09-16', backgroundColor: '#CC0066', borderColor: "#CC0066" },
            // { title: '', date: '2021-09-20', backgroundColor: '#004C99', borderColor: "#004C99" },
            // { title: '', date: '2021-09-05', backgroundColor: '#009900', borderColor: "#009900" },
            // { title: '', date: '2021-09-29', backgroundColor: '#FF3333', borderColor: "#FF3333" },
            // ]
          }
          // editable={true}
          selectable={true}
          displayEventTime={false}
          contentHeight={"auto"}
          // aspectRatio={0.7}
        />
      </Card.Body>
    </Card>
  );
};

export default Calender;
