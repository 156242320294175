import React from "react";
import { Container, Row } from "react-bootstrap";

import { Header } from "../../common";
import { BsTable } from "react-icons/bs";

import QueryList from "./components/QueryList";
import QueryDetail from "./components/QueryDetail";
import UserFilter from "./components/UserFilter";
// import MyLeaveApplications from "./components/MyLeaveApplications";

const ViewQueriesView = ({
  getUserQueryList,
  addStatusToQuery,

  totalDocsData,
  nextPagee,
  userQueryList,
  getUserQuery,
  getUserQueryData,
  ModalNullGetQueryData,
  addMessageToQuery,
  onchangeStatus,
  onchangeReply,
  handleSubmit,
  errors,
  fields,
  userQueryListSuccess,
  onResetSubmit,
  addStatusToMessages,
  userId,
  handleOpenFilter,
  filterShow,
  handleClose,
  handleClearFilter,
  filterSubmit,
  totalPagess,
}) => {
  // console.log("userId", userId);
  // const data = {
  //   queryStatus: "",
  //   message: "",
  // };

  const queryStatusOptions = [
    { value: "NEW", label: "NEW" },
    { value: "PENDING", label: "PENDING" },
    { value: "REOPENED", label: "REOPENED" },
    { value: "RESOLVED", label: "RESOLVED" },
  ];
  // const fields = {
  //   value: "",
  //   label: "",
  // };

  return (
    <>
      <Header label={"View Queries"} Icon={BsTable} />
      <div className="main__body padding ">
        <Container>
          <Row className="top mt-2">
            <QueryList
              userQueryList={userQueryList}
              getUserQueryList={getUserQueryList}
              totalDocsData={totalDocsData}
              totalPagess={totalPagess}
              nextPagee={nextPagee}
              getUserQuery={getUserQuery}
              onchangeStatus={onchangeStatus}
              queryStatusOptions={queryStatusOptions}
              userQueryListSuccess={userQueryListSuccess}
              getUserQueryData={getUserQueryData}
              ModalNullGetQueryData={ModalNullGetQueryData}
              addStatusToMessages={addStatusToMessages}
              userId={userId}
              handleOpenFilter={handleOpenFilter}
              fields={fields}
            />

            <QueryDetail
              getUserQueryData={getUserQueryData}
              queryStatusOptions={queryStatusOptions}
              fields={fields}
              ModalNullGetQueryData={ModalNullGetQueryData}
              onchangeStatus={onchangeStatus}
              addMessageToQuery={addMessageToQuery}
              onchangeReply={onchangeReply}
              addStatusToQuery={addStatusToQuery}
              userQueryListSuccess={userQueryListSuccess}
              handleSubmit={handleSubmit}
              errors={errors}
              onResetSubmit={onResetSubmit}
            />
          </Row>
          <UserFilter
            queryStatusOptions={queryStatusOptions}
            fields={fields}
            onchangeStatus={onchangeStatus}
            filterShow={filterShow}
            handleClose={handleClose}
            handleClearFilter={handleClearFilter}
            filterSubmit={filterSubmit}
            errors={errors}
            onResetSubmit={onResetSubmit}
          />
        </Container>
      </div>
    </>
  );
};
export default ViewQueriesView;
