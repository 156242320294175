import React from "react";
import { Row, Col } from "react-bootstrap";

import {
  ButtonView,
  Input,
  TextArea,
  Select,
  DatePicker,
} from "../../../controls";

const UserDetails = ({
  userData,
  setUserData,
  errors,
  roleOptions,
  locationOptions,
  handleSubmitNext,
}) => {
  return (
    <div>
      <h6>User Details:</h6>
      <Row>
        <Col md={12} lg={6} className="mb-3" autoFocus={0}>
          <Input
            name="firstName"
            label="First Name"
            value={userData.firstName}
            type="text"
            placeholder="First Name"
            onChange={(e) =>
              setUserData({ ...userData, firstName: e.target.value })
            }
            size="fluid"
            error={errors["firstName"]}
            isrequired={true}
            // autofocus
            autoFocus={1}
            // autoFocus = {1}
            maxLength={25}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Input
            name="lastname"
            label="Last Name"
            value={userData.lastName}
            type="text"
            placeholder="Last Name"
            onChange={(e) =>
              setUserData({ ...userData, lastName: e.target.value })
            }
            size="fluid"
            error={errors["lastName"]}
            isrequired={true}
            maxLength={25}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <DatePicker
            name="dateOfBirth"
            label="Date of birth"
            value={userData.dateOfBirth}
            onChange={(e) => setUserData({ ...userData, dateOfBirth: e })}
            error={errors["dateOfBirth"]}
            isrequired={true}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Input
            name="email"
            label="Email"
            value={userData.email}
            type="email"
            placeholder="Email"
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            size="fluid"
            error={errors["email"]}
            isrequired={true}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Input
            name="phoneNumber"
            label="Phone Number"
            value={userData.phoneNumber}
            type="tel"
            isNum={true}
            placeholder="Phone Number"
            onChange={(e) =>
              setUserData({ ...userData, phoneNumber: e.target.value })
            }
            size="fluid"
            error={errors["phoneNumber"]}
            // isrequired={true}
            maxLength={10}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Input
            name="emergencyContactNumber"
            label="Emergency Contact"
            value={userData.emergencyContactNumber}
            type="tel"
            isNum={true}
            placeholder="Emergency Contact"
            onChange={(e) =>
              setUserData({
                ...userData,
                emergencyContactNumber: e.target.value,
              })
            }
            size="fluid"
            error={errors["emergencyContactNumber"]}
            // isrequired={true}
            maxLength={10}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Select
            name="role"
            label="Role"
            isSearchable={false}
            defaultValue={roleOptions.find(
              (option) => option.value === userData.role
            )}
            placeholder="Select Role"
            options={roleOptions}
            onChange={(val) => setUserData({ ...userData, role: val.value })}
            className="select"
            classNamePrefix="select"
            error={errors["role"]}
            isrequired={true}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <DatePicker
            name="joiningDate"
            label="Joining Date"
            value={userData.joiningDate}
            onChange={(e) => setUserData({ ...userData, joiningDate: e })}
            error={errors["joiningDate"]}
            isrequired={true}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <TextArea
            name="addressLineOne"
            label="Address 1"
            value={userData.addressLineOne}
            type="text"
            placeholder="Address"
            onChange={(e) =>
              setUserData({ ...userData, addressLineOne: e.target.value })
            }
            size="fluid"
            error={errors["addressLineOne"]}
            isrequired={true}
            maxLength={300}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <TextArea
            name="addressLineTwo"
            label="Address 2"
            value={userData.addressLineTwo}
            type="text"
            placeholder="Address"
            onChange={(e) =>
              setUserData({ ...userData, addressLineTwo: e.target.value })
            }
            size="fluid"
            error={errors["addressLineTwo"]}
            isrequired={true}
            maxLength={300}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Input
            nmae="city"
            label="City"
            type="text"
            value={userData.city}
            placeholder="City"
            onChange={(e) => setUserData({ ...userData, city: e.target.value })}
            size="fluid"
            error={errors["city"]}
            maxLength={30}
            // isrequired={true}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Input
            nmae="state"
            label="State"
            type="text"
            value={userData.state}
            placeholder="State"
            onChange={(e) =>
              setUserData({ ...userData, state: e.target.value })
            }
            size="fluid"
            error={errors["state"]}
            maxLength={30}
            // isrequired={true}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3">
          <Input
            nmae="zipCode"
            label="Zip Code"
            type="tel"
            isNum={true}
            value={userData.zipCode}
            placeholder="Zip Code"
            onChange={(e) =>
              setUserData({ ...userData, zipCode: e.target.value })
            }
            size="fluid"
            error={errors["zipCode"]}
            maxLength={10}
            // isrequired={true}
          />
        </Col>
        <Col md={12} lg={6} className="mb-3 d-none">
          <Input
            nmae="country"
            label="Country"
            type="text"
            value={userData.country}
            placeholder="Country"
            onChange={(e) =>
              setUserData({ ...userData, country: e.target.value })
            }
            size="fluid"
            error={errors["country"]}
            // isrequired={true}
            maxLength={25}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col xs={6} md={6} lg={3}>
          <ButtonView
            onClick={handleSubmitNext}
            title={"Next"}
            styles="secondary"
          />
        </Col>
      </Row>
    </div>
  );
};

export default UserDetails;
