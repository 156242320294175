import React from "react";
import { Container, Row } from "react-bootstrap";
import { ButtonView } from "../../../controls";
import { Col, Card } from "react-bootstrap";

const ApplicationManagerCard = ({ applications, onOpenApplicationClicked }) => {
  return (
    <div className="application manager main__body padding">
      <Container>
        <Row>
          {applications.map(application => {
            return (
              <Col key={application._id} md={6} lg={6} xl={3} className="col-xxl-3">
                <div className="application_manager_card">
                  <Card className="mt-3">
                    <Card.Body>
                      <Card.Title className="text-center">
                        <span className="card_icon fw-bold">
                          {/* <IoPerson /> */}
                          {application.name}
                        </span>
                      </Card.Title>
                      <Card.Text className="text-muted text-center">{application.description}</Card.Text>
                      {/* <Card.Text className="text-muted text-center">{application.link}</Card.Text> */}
                      <hr />
                      <div>
                        <ButtonView title={"Open"} onClick={() => onOpenApplicationClicked(application)} />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default ApplicationManagerCard;
