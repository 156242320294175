import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { ButtonView, Select, Input, DatePicker } from "../../../controls";
import { AiOutlineClose } from "react-icons/ai";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";

const CertifiedOptions = [
  { value: "YES", label: "YES" },
  { value: "NO", label: "NO" },
];

const AddMySkill = ({
  AddMySkillShow,
  handleClose,
  categories,
  getTechnologies,
  technologies,
  proficiencies,
  saveSkills,
  // saveMappingData,
  uploadSkillCertificates,
  newSkillId,
  DuplicateSkillMessage,
  mySkillList,
  ErrorMessage,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [certificateList, setCertificateList] = useState([]);
  console.log(DuplicateSkillMessage);
  const [fields, setFields] = useState({
    category: "",
    technology: "",
    proficiency: "",
    certified: { value: "NO", label: "NO" },
    newCategoryName: "",
    technologyName: "",
  });

  const [errors, setErrors] = useState({});

  // function compareProficiencyName(a, b) {
  //   if (a.proficiencyName < b.proficiencyName) {
  //     return -1;
  //   }
  //   if (a.proficiencyName > b.proficiencyName) {
  //     return 1;
  //   }
  //   return 0;
  // }

  // proficiencies.sort(compareProficiencyName);
  const ProficiencyOptions = proficiencies.map((list) => {
    return {
      value: list._id,
      label: list.proficiencyName,
    };
  });

  const CategoryListOptions = categories.map((list) => {
    console.log("list:", list);
    return {
      value: list._id,
      label: list.categoryName,
    };
  });

  function compareTechnologyName(a, b) {
    if (a.technologyName < b.technologyName) {
      return -1;
    }
    if (a.technologyName > b.technologyName) {
      return 1;
    }
    return 0;
  }

  technologies.sort(compareTechnologyName);
  const TechnologyListOptions = technologies.map((list) => {
    return {
      value: list.technologyId,
      label: list.technologyName,
    };
  });

  const noOption = [];

  const handleAddRow = () => {
    setCertificateList((prev) => {
      return [
        ...prev,
        {
          certificateName: "",
          expiryDate: "",
          file: "",
          error: {},
          id: Math.random(),
        },
      ];
    });
  };

  const handleRemoveRow = (id) => {
    console.log("length", certificateList.length);
    setCertificateList((prevRows) => prevRows.filter((row) => row.id !== id));

    if (certificateList.length === 1) {
      setFields((prevFields) => ({
        ...prevFields,
        certified: { value: "NO", label: "NO" },
      }));
    }
  };

  const handleSaveSkill = () => {
    console.log({ certificateList });
    const newList = [];
    // var required = [];
    var errorList = {};
    var skillExist = {};
    certificateList.forEach((certificate) => {
      const error = {};
      if (!certificate.certificateName) {
        error.certificateName = "Please fill certificate name field";
      }

      const hasParenthesesInCertificateName = /\(|\)/.test(certificate.certificateName);
      if (hasParenthesesInCertificateName) {
        error.certificateName = "Parentheses are not allowed.";
      }
      // if (!certificate.file) {
      //   error.file = "Please fill required field";
      // }
      if (!certificate.file) {
        error.file = "Please select a file";
      } else {
        const allowedExtensions = ["pdf", "png", "jpg", "doc", "docx"];

        const fileExtension = certificate.file.name
          .split(".")
          .pop()
          .toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          // File extension is valid

          console.log("File uploaded!");
        } else {
          // File extension is invalid

          error.file = "Invalid file extension";
        }
      }

      newList.push({
        ...certificate,
        error,
      });
      console.log(newList);
      errorList = { ...errorList, ...error };
    });

    setCertificateList(newList);

    var rules = {
      required: ["category", "technology", "proficiency"],
    };

    if (fields.category.label === "Other") {
      rules = {
        required: [
          "category",

          "proficiency",
          "newCategoryName",
          "technologyName",
        ],
      };
    }

    if (fields.technology.label === "Other") {
      rules = {
        required: ["category", "technology", "proficiency", "technologyName"],
      };
    }

    let { errors } = validator.validate(rules, {
      ...trimFieldsData(fields),
    });

    if (!fields.category) {
      errors = {
        ...errors,
        category: "Please fill category field",
        // technology: "Please fill technology field",
      };
    }

    if (
      !fields.technology &&
      !fields.newCategoryName &&
      !fields.technologyName
    ) {
      errors = {
        ...errors,
        technology: "Please fill technology field",
      };
    }

    if (!fields.proficiency) {
      errors = {
        ...errors,
        proficiency: "Please fill proficiency field",
      };
    }

    if (fields.category.label === "Other") {
      if (!fields.category) {
        errors = {
          ...errors,
          category: "Please fill category field",
          // technology: "Please fill technology field",
        };
      }

      if (!fields.proficiency) {
        errors = {
          ...errors,
          proficiency: "Please fill proficiency field",
        };
      }

      if (!fields.newCategoryName) {
        errors = {
          ...errors,
          newCategoryName: "Please fill category name field",
        };
      }

      if (!fields.technologyName) {
        errors = {
          ...errors,
          technologyName: "Please fill technology name field",
        };
      }
    }

    if (fields.technology.label === "Other") {
      if (!fields.category) {
        errors = {
          ...errors,
          category: "Please fill category field",
          // technology: "Please fill technology field",
        };
      }

      if (!fields.technology) {
        errors = {
          ...errors,
          technology: "Please fill technology field",
        };
      }

      if (!fields.proficiency) {
        errors = {
          ...errors,
          proficiency: "Please fill proficiency field",
        };
      }

      if (!fields.technologyName) {
        errors = {
          ...errors,
          technologyName: "Please fill technology name field",
        };
      }
    }

    const hasParentheses = (text) => /\(|\)/.test(text);

    if (
      hasParentheses(fields.newCategoryName)
    ) {
      errors = {
        ...errors,
        newCategoryName: "Parentheses are not allowed.",
      };
    }

    if (
      hasParentheses(fields.technologyName)
    ) {
      errors = {
        ...errors,
        technologyName: "Parentheses are not allowed.",
      };
    }

    mySkillList.forEach((skill) => {
      console.log("skill list", skill);
      if (
        (fields.category !== "" &&
          fields.technology !== "" &&
          fields.proficiency) ||
        (fields.newCategoryName !== "" && fields.technologyName !== "") ||
        (fields.category !== "" && fields.technologyName !== "")
      ) {
        if (
          skill.categoryName.toLowerCase().split(" ").join("") ===
          fields.category.label.toLowerCase().split(" ").join("") &&
          skill.technologyName.toLowerCase().split(" ").join("") ===
          fields.technology.label.toLowerCase().split(" ").join("")
        ) {
          console.log("its matching");
          // errors = {
          //   ...errors,
          //   category: "Skill already exist",
          //   technology: "Skill already exist",
          // };

          const otherData = {
            existingSkill: "Skill Exist",
          };
          saveSkills(otherData);
          skillExist = { exist: true };
        }

        if (
          skill.categoryName.toLowerCase().split(" ").join("") ===
          fields.newCategoryName.toLowerCase().split(" ").join("") &&
          skill.technologyName.toLowerCase().split(" ").join("") ===
          fields.technologyName.toLowerCase().split(" ").join("")
        ) {
          errors = {
            ...errors,
            newCategoryName: "Category name already exist",
            technologyName: "Technology name already exist",
          };
        }

        if (
          skill.categoryName.toLowerCase().split(" ").join("") ===
          fields.category.label.toLowerCase().split(" ").join("") &&
          skill.technologyName.toLowerCase().split(" ").join("") ===
          fields.technologyName.toLowerCase().split(" ").join("")
        ) {
          errors = {
            ...errors,
            // category: "Skill already exist",
            technologyName: "Technology already exist",
          };
        }
      }
    });

    console.log("errors:", errors);
    console.log("errorList:", errorList);
    setErrors(errors);
    console.log("myskilllist", mySkillList);

    if (
      !Object.keys(errors).length &&
      !Object.keys(errorList).length &&
      !Object.keys(skillExist).length
    ) {
      console.log("test 111");
      if (fields.category.label === "Other") {
        console.log("t1");
        const otherData = {
          proficiencyId: fields.proficiency.value,
          categoryId: 9999,
          categoryName: fields.newCategoryName,
          technologyId: 10001,
          technologyName: fields.technologyName,
        };
        setDisabled(true);
        saveSkills(otherData);
        handleClose();
      } else if (fields.technology.label === "Other") {
        const otherData = {
          proficiencyId: fields.proficiency.value,
          categoryId: fields.category.value,
          categoryName: fields.category.label,
          technologyId: 10001,
          technologyName: fields.technologyName,
        };
        setDisabled(true);
        saveSkills(otherData);
        handleClose();
      } else {
        console.log("test 3:");
        const otherData = {
          proficiencyId: fields.proficiency.value,
          categoryId: fields.category.value,
          categoryName: fields.category.label,
          technologyId: fields.technology.value,
          technologyName: fields.technology.label,
        };
        setDisabled(true);
        saveSkills(otherData);
      }
    }
  };

  const uploadData = certificateList.map((obj) => {
    if (obj.expiryDate === null) {
      obj.expiryDate = "";
    }
    return {
      skillsId: newSkillId,
      certificateName: obj.certificateName,
      validCertificationDate: obj.expiryDate,
      file: obj.file,
    };
  });

  useEffect(() => {
    uploadData.forEach((uploadData) => {
      uploadSkillCertificates(uploadData);
    });

    setFields({
      category: "",
      technology: "",
      proficiency: "",
      certified: { value: "NO", label: "NO" },
      newCategoryName: "",
      technologyName: "",
    });
    setCertificateList([]);
    handleClose();
    setDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSkillId]);

  useEffect(() => {
    setErrors({});
  }, [AddMySkillShow]);

  useEffect(() => {
    if (fields.certified.value === "YES") {
      setCertificateList([
        {
          certificateName: "",
          expiryDate: "",
          file: "",
          error: {},
          id: Math.random(),
        },
      ]);
    } else {
      setCertificateList([]);
    }
  }, [fields.certified]);

  useEffect(() => {
    setErrors({ ...errors, certificateName: null, file: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.certified]);

  useEffect(() => {
    fields.technology = "";
    fields.newCategoryName = "";
    fields.technologyName = "";

    errors.newCategoryName = "";
    errors.technologyName = "";
    setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.category]);

  useEffect(() => {
    fields.technologyName = "";
    errors.technologyName = "";
    setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.technology]);

  console.log("erros", errors);

  useEffect(() => {
    var catNameExist = "";
    categories.forEach((item) => {
      if (catNameExist !== "Other") {
        catNameExist = item.categoryName;
      }
    });

    if (catNameExist !== "Other" && catNameExist !== "") {
      const otherOption = {
        categoryName: "Other",
      };
      categories.push(otherOption);
    } else {
      return null;
    }
  }, [categories]);

  const handleCloseSubmit = () => {
    handleClose();

    setFields({
      category: "",
      technology: "",
      proficiency: "",
      certified: { value: "NO", label: "NO" },
      newCategoryName: "",
      technologyName: "",
    });

    setCertificateList([]);
    setDisabled(false);
    setErrors({});
  };

  useEffect(() => {
    handleCloseSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ErrorMessage]);

  console.log("fields:", fields);
  console.log(certificateList);
  console.log(technologies);
  return (
    <>
      <Modal show={AddMySkillShow} onHide={handleCloseSubmit} size="lg">
        <Modal.Header>
          <Modal.Title>Add Skill</Modal.Title>
          <div onClick={handleCloseSubmit} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} lg={6} className="mb-3">
              <Select
                label="Category"
                placeholder="Select Category"
                isSearchable={
                  Object.keys(CategoryListOptions).length > 0 ? true : false
                }
                options={CategoryListOptions}
                isrequired={true}
                value={fields.category}
                onChange={(val) => {
                  console.log(val);
                  setFields({ ...fields, category: val });
                  const data = {
                    categoryId: val.value,
                  };
                  getTechnologies(data);
                }}
                error={errors["category"]}
              />
            </Col>

            {fields.category.label === "Other" ? (
              <>
                <Col md={12} lg={6} className="mb-3">
                  <Input
                    type="text"
                    label={"Category Name"}
                    placeholder="Enter Category Name"
                    maxLength={50}
                    value={fields.newCategoryName}
                    onChange={(e) => {
                      setFields({ ...fields, newCategoryName: e.target.value });
                    }}
                    isrequired={true}
                    error={errors["newCategoryName"]}
                  />
                </Col>

                <Col md={12} lg={6} className="mb-3">
                  <Input
                    type="text"
                    label={"Technology Name"}
                    placeholder="Enter Technology Name"
                    maxLength={50}
                    value={fields.technologyName}
                    onChange={(e) =>
                      setFields({ ...fields, technologyName: e.target.value })
                    }
                    error={errors["technologyName"]}
                    isrequired={true}
                  />
                </Col>
              </>
            ) : (
              <Col md={12} lg={6} className="mb-3">
                <Select
                  label="Technology"
                  placeholder="Select Technology"
                  isSearchable={
                    Object.keys(TechnologyListOptions).length > 0 ? true : false
                  }
                  options={
                    fields.category
                      ? [
                        ...TechnologyListOptions,
                        { label: "Other", value: "9999" },
                      ]
                      : noOption
                  }
                  value={fields.technology}
                  onChange={(val) => {
                    console.log(val);
                    setFields({ ...fields, technology: val });
                  }}
                  isrequired={true}
                  error={errors["technology"]}
                  className="select"
                  classNamePrefix="select"
                />
              </Col>
            )}

            {fields.technology.label === "Other" ? (
              <Col md={12} lg={6} className="mb-3">
                <Input
                  type="text"
                  label={"Technology Name"}
                  placeholder="Enter Technology Name"
                  maxLength={50}
                  value={fields.technologyName}
                  onChange={(e) =>
                    setFields({ ...fields, technologyName: e.target.value })
                  }
                  error={errors["technologyName"]}
                  isrequired={true}
                />
              </Col>
            ) : null}

            <Col md={12} lg={6} className="mb-3">
              <Select
                label="Proficiency"
                placeholder="Select Proficiency"
                isSearchable={
                  Object.keys(ProficiencyOptions).length > 0 ? true : false
                }
                options={ProficiencyOptions}
                value={fields.proficiency}
                onChange={(val) => {
                  setFields({ ...fields, proficiency: val });
                }}
                isrequired={true}
                error={errors["proficiency"]}
              />
            </Col>

            <Col md={12} lg={6} className="mb-3">
              <Select
                label="Certified"
                options={CertifiedOptions}
                value={fields.certified}
                // value={
                //   certificateList.length === 0
                //     ? CertifiedOptions.find((option) => option.value === "NO")
                //     : CertifiedOptions.find((option) => option.value === "YES")
                // }
                onChange={(val) => {
                  console.log("val:", val);
                  setFields({ ...fields, certified: val });
                }}
              />
            </Col>

            {fields.certified.value === "YES"
              ? certificateList.map((row) => (
                <div>
                  <Row key={row.id}>
                    <Col md={12} lg={12} className="mb-3">
                      <Input
                        key={row.id}
                        value={row.certificateName}
                        label="Certificate Name"
                        placeholder="Enter Certificate Name"
                        maxLength={50}
                        onChange={(e) =>
                          setCertificateList((prev) => {
                            const { id } = row;
                            const newList = prev.map((certificate) => {
                              if (id === certificate.id) {
                                return {
                                  ...certificate,
                                  certificateName: e.target.value,
                                };
                              } else {
                                return certificate;
                              }
                            });
                            return newList;
                          })
                        }
                        isrequired={true}
                        error={row.error["certificateName"]}
                      ></Input>
                    </Col>

                    <Col
                      md={12}
                      lg={5}
                      className="mb-3 pb-md-3 pb-sm-3 pb-xs-0"
                    >
                      <DatePicker
                        key={row.id}
                        value={row.expiryDate}
                        name="Certificate Expiry Date"
                        label="Certificate Expiry Date"
                        onChange={(e) => {
                          setCertificateList((prev) => {
                            const { id } = row;
                            const newList = prev.map((certificate) => {
                              if (id === certificate.id) {
                                return {
                                  ...certificate,
                                  expiryDate: e,
                                };
                              } else {
                                return certificate;
                              }
                            });
                            return newList;
                          });
                        }}
                      />
                    </Col>

                    <Col md={12} lg={5} className="mb-3">
                      <Input
                        key={row.id}
                        // value={row.file}
                        name="file"
                        label="Upload File (.pdf, .png, .jpg, .doc, .docx)"
                        type="file"
                        placeholder="Upload File"
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            setCertificateList((prev) => {
                              const { id } = row;
                              const newList = prev.map((certificate) => {
                                if (id === certificate.id) {
                                  return {
                                    ...certificate,
                                    file: e.target.files[0],
                                  };
                                } else {
                                  return certificate;
                                }
                              });
                              return newList;
                            });
                          }
                        }}
                        accept=".pdf,.png,.jpg,.doc,.docx"
                        isrequired={true}
                        error={row.error["file"]}
                      />
                    </Col>

                    <Col
                      lg={2}
                      className="mb-3 d-flex justify-content-center align-items-center pt-3"
                    >
                      <ButtonView
                        title="Remove"
                        onClick={() => handleRemoveRow(row.id)}
                      ></ButtonView>
                    </Col>
                  </Row>
                </div>
              ))
              : null}
            { }
            {fields.certified.value === "YES"
              ? certificateList.length > 0 && (
                <Col md={12} lg={12}>
                  <ButtonView
                    title="Add Another Certificate"
                    onClick={handleAddRow}
                  ></ButtonView>
                </Col>
              )
              : null}
          </Row>

          <Row className="d-flex justify-content-end">
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                title={"Cancel"}
                styles="danger-outline"
                onClick={handleCloseSubmit}
              />
            </Col>
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                disabled={disabled}
                onClick={() => {
                  handleSaveSkill();
                }}
                title={"Save"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddMySkill;
