import React, { useEffect, useState } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { ButtonView, TextArea } from "../../../controls";
import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";
const data = {
  message: "",
};
const ModalView = ({
  getUserQueryData,

  ModalNullGetQueryData,
  addMessageToQuery,
  defaultTabActiveKey,
  addStatusToQuery,
}) => {
  // console.log("modal", getUserQueryData?.[0].queryId.status);

  var tabStatus = getUserQueryData?.[0].queryId.status;

  const Id = getUserQueryData?.[0].queryId._id;

  const [queryData, setQueryData] = useState(data);
  const [viewDetailsShow, setViewDetailsShow] = useState(false);
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    var rules = {
      required: ["message"],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(queryData),
    });
    setErrors(errors);
    // console.log("user", user);
    // var startDate = new Date(wfhData.startDate);
    // applyForQuery(queryData);

    if (!Object.keys(errors).length) {
      // console.log("queryData", queryData);
      if (defaultTabActiveKey === "pending") {
        addMessageToQuery(Id, queryData, defaultTabActiveKey, tabStatus);
      }
      if (defaultTabActiveKey === "resolved") {
        const oldStatus = "resolved";
        const newStatus = {
          status: "reopened",
        };
        // console.log("hi");
        if (window.confirm("Are You Sure To Reopened This Query?")) {
          addMessageToQuery(Id, queryData, defaultTabActiveKey, tabStatus);
          addStatusToQuery(Id, newStatus, oldStatus);
        }
      }
      setViewDetailsShow(false);
    }
  };

  const handleClose = () => {
    setViewDetailsShow(false);
    setValue(null);
    ModalNullGetQueryData();
    setErrors({});
    setQueryData(data);
  };

  useEffect(() => {
    if (getUserQueryData) {
      setValue(getUserQueryData);
      setViewDetailsShow(true);
    } else {
      return setViewDetailsShow(false);
    }
  }, [getUserQueryData]);
  // console.log("tabStatus", defaultTabActiveKey);

  return (
    <>
      {value && (
        <div className="view_modal">
          <Modal
            show={viewDetailsShow}
            onHide={handleClose}
            aria-labelledby="view_modal"
          >
            <Modal.Header>
              <Modal.Title id="view_modal">Query Detail</Modal.Title>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <AiOutlineClose size={20} />
              </div>
            </Modal.Header>
            <Modal.Body>
              {/* <div> */}
              <div className="comments  mb-2 ">
                <div className="viewScroll">
                  <div className="My_chat_subject">
                    Subject: {value[0].queryId.subject}
                  </div>
                  <div className="My_chat_container mt-2">
                    <div>
                      {value.map((item, index) => {
                        // console.log("item", item);
                        var time = moment(item.createdAt).toISOString(true);
                        const myArray = time.split("T");
                        var validTime = moment(myArray, ["h:mm:ss a"]);
                        var actualTime = moment(validTime._i?.[1], [
                          "HH:mm",
                        ]).format("hh:mm a");
                        // console.log("actualTime", actualTime);
                        return (
                          <div key={item._id}>
                            <div className="chat-inner">
                              <strong>{item.createdBy?.name}</strong>
                              <span>
                                {`${moment(
                                  new Date(myArray[0].slice(0, 10))
                                ).format("DD/MM/YYYY")}${","} ${actualTime}
                          
                            `}
                              </span>
                            </div>
                            <div className="My_containerr ">
                              {/* {value.map((item) => {
                        return ( */}
                              <div className="chatboxx mt-1">
                                {/* <strong>Message1:</strong> */}
                                <span>{item.message}</span>
                                <hr />
                              </div>
                              {/* );
                       })} */}

                              {/* <p className="chatboxx mt-1">
                      <strong>Message1:</strong>
                      <span>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </span>
                    </p> */}
                            </div>
                          </div>
                        );
                      })}

                      <Row>
                        <Col md={12} className="mb-3">
                          <TextArea
                            name="message"
                            label="Reply"
                            // value={wfhData.comments}
                            type="textarea"
                            placeholder="Type something..."
                            onChange={(e) =>
                              setQueryData({
                                message: e.target.value,
                              })
                            }
                            size="fluid"
                            error={errors["message"]}
                            isrequired={true}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                {(defaultTabActiveKey === "pending" ||
                  defaultTabActiveKey === "") && (
                  <div>
                    <Row className="d-flex justify-content-end">
                      <Col xs={6} md={6} lg={3}>
                        <ButtonView
                          onClick={() => {
                            handleClose();
                          }}
                          title={"Cancel"}
                          styles="danger-outline"
                        />
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <ButtonView
                          onClick={handleSubmit}
                          title={"Submit"}
                          styles="secondary"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
                {defaultTabActiveKey === "resolved" && (
                  <div>
                    <Row className="d-flex justify-content-end">
                      <Col xs={6} md={6} lg={3}>
                        <ButtonView
                          onClick={() => {
                            handleClose();
                          }}
                          title={"Cancel"}
                          styles="danger-outline"
                        />
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <ButtonView
                          onClick={handleSubmit}
                          title={"Reopen"}
                          styles="dangerr"
                        />
                      </Col>
                    </Row>
                  </div>
                )}

                {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      // disabled
                      readOnly
                      value={value.rejectionOrCancellationComments}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group> */}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ModalView;
