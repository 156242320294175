import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MdPersonSearch } from "react-icons/md";

import { Header } from "../../common";
import SkillFilter from "./components/SkillFilter";
import EmployeeSkills from "./components/EmployeeSkills";
import EmployeeReports from "./components/EmployeeReports";

const EmployeeSKillsView = ({
  categoryList,
  TechnologyList,
  ProficiencyList,
  getUserSkillWithFilter,
  userList,
  UserSkillFilteredList,
  downloadSkillCertificates,
  saveCategoryTechnolgy,
  downloadUserReport,
  downloadTechonlogyReport,
}) => {
  return (
    <>
      <Header label={"Employee Skills"} Icon={MdPersonSearch} />
      <div className="main__body padding">
        <Container>
          <Row>
            <Col>
              <div className="skill_filter_overview mt-lg-3">
                <SkillFilter
                  userList={userList}
                  categoryList={categoryList}
                  TechnologyList={TechnologyList}
                  ProficiencyList={ProficiencyList}
                  getUserSkillWithFilter={getUserSkillWithFilter}
                />
              </div>
              <div className="mt-3">
                <EmployeeReports
                  downloadUserReport={downloadUserReport}
                  downloadTechonlogyReport={downloadTechonlogyReport}
                />
              </div>
              <div className="mt-3">
                <EmployeeSkills
                  UserSkillFilteredList={UserSkillFilteredList}
                  downloadSkillCertificates={downloadSkillCertificates}
                  saveCategoryTechnolgy={saveCategoryTechnolgy}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EmployeeSKillsView;
