import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { getTechnologiesForCategory } from "../mySkills/mySkillsActions";

export const actionTypes = {
  GET_CATEGORY_PENDING: "GET_CATEGORY_PENDING",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_ERROR: "GET_CATEGORY_ERROR",

  ADD_CATEGORY_PENDING: "ADD_CATEGORY_PENDING",
  ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
  ADD_CATEGORY_ERROR: "ADD_CATEGORY_ERROR",

  UPDATE_CATEGORY_PENDING: "UPDATE_CATEGORY_PENDING",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_ERROR: "UPDATE_CATEGORY_ERROR",

  DELETE_CATEGORY_PENDING: "DELETE_CATEGORY_PENDING",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_ERROR: "DELETE_CATEGORY_ERROR",

  GET_PROFICIENCY_PENDING: "GET_PROFICIENCY_PENDING",
  GET_PROFICIENCY_SUCCESS: "GET_PROFICIENCY_SUCCESS",
  GET_PROFICIENCY_ERROR: "GET_PROFICIENCY_ERROR",

  ADD_PROFICIENCY_PENDING: "ADD_PROFICIENCY_PENDING",
  ADD_PROFICIENCY_SUCCESS: "ADD_PROFICIENCY_SUCCESS",
  ADD_PROFICIENCY_ERROR: "ADD_PROFICIENCY_ERROR",

  UPDATE_PROFICIENCY_PENDING: "UPDATE_PROFICIENCY_PENDING",
  UPDATE_PROFICIENCY_SUCCESS: "UPDATE_PROFICIENCY_SUCCESS",
  UPDATE_PROFICIENCY_ERROR: "UPDATE_PROFICIENCY_ERROR",

  DELETE_PROFICIENCY_PENDING: "DELETE_PROFICIENCY_PENDING",
  DELETE_PROFICIENCY_SUCCESS: "DELETE_PROFICIENCY_SUCCESS",
  DELETE_PROFICIENCY_ERROR: "DELETE_PROFICIENCY_ERROR",

  GET_TECHNOLOGY_PENDING: "GET_TECHNOLOGY_PENDING",
  GET_TECHNOLOGY_SUCCESS: "GET_TECHNOLOGY_SUCCESS",
  GET_TECHNOLOGY_ERROR: "GET_TECHNOLOGY_ERROR",

  ADD_TECHNOLOGY_PENDING: "ADD_TECHNOLOGY_PENDING",
  ADD_TECHNOLOGY_SUCCESS: "ADD_TECHNOLOGY_SUCCESS",
  ADD_TECHNOLOGY_ERROR: "ADD_TECHNOLOGY_ERROR",

  SAVE_TECHNOLOGY_CATEGORY_PENDING: "SAVE_TECHNOLOGY_CATEGORY_PENDING",
  SAVE_TECHNOLOGY_CATEGORY_SUCCESS: "SAVE_TECHNOLOGY_CATEGORY_SUCCESS",
  SAVE_TECHNOLOGY_CATEGORY_ERROR: "SAVE_TECHNOLOGY_CATEGORY_ERROR",

  DELETE_MAPPED_TECHNOLOGY_CATEGORY_PENDING:
    "DELETE_MAPPED_TECHNOLOGY_CATEGORY_PENDING",
  DELETE_MAPPED_TECHNOLOGY_CATEGORY_SUCCESS:
    " DELETE_MAPPED_TECHNOLOGY_CATEGORY_SUCCESS",
  DELETE_MAPPED_TECHNOLOGY_CATEGORY_ERROR:
    "DELETE_MAPPED_TECHNOLOGY_CATEGORY_ERROR",

  UPDATE_TECHNOLOGY_PENDING: "UPDATE_TECHNOLOGY_PENDING",
  UPDATE_TECHNOLOGY_SUCCESS: "UPDATE_TECHNOLOGY_SUCCESS",
  UPDATE_TECHNOLOGY_ERROR: "UPDATE_TECHNOLOGY_ERROR",

  UPDATE_TECHNOLOGY_CATEGORY_PENDING: "UPDATE_TECHNOLOGY_CATEGORY_PENDING",
  UPDATE_TECHNOLOGY_CATEGORY_SUCCESS: "UPDATE_TECHNOLOGY_CATEGORY_SUCCESS",
  UPDATE_TECHNOLOGY_CATEGORY_ERROR: "UPDATE_TECHNOLOGY_CATEGORY_ERROR",

  DELETE_TECHNOLOGY_PENDING: "DELETE_TECHNOLOGY_PENDING",
  DELETE_TECHNOLOGY_SUCCESS: "DELETE_TECHNOLOGY_SUCCESS",
  DELETE_TECHNOLOGY_ERROR: "DELETE_TECHNOLOGY_ERROR",

  DOWNLOAD_TECHNOLOGY_REPORT_PENDING: "DOWNLOAD_TECHNOLOGY_REPORT_PENDING",
  DOWNLOAD_TECHNOLOGY_REPORT_SUCCESS: "DOWNLOAD_TECHNOLOGY_REPORT_SUCCESS",
  DOWNLOAD_TECHNOLOGY_REPORT_ERROR: "DOWNLOAD_TECHNOLOGY_REPORT_ERROR",

  DOWNLOAD_USER_REPORT_PENDING: "DOWNLOAD_USER_REPORT_PENDING",
  DOWNLOAD_USER_REPORT_SUCCESS: "DOWNLOAD_USER_REPORT_SUCCESS",
  DOWNLOAD_USER_REPORT_ERROR: "DOWNLOAD_USER_REPORT_ERROR",
};

export function getCategories() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CATEGORY_PENDING,
      payload: {
        getCategoryPending: true,
        getCategorySuccess: false,
        getCategoryError: null,
      },
    });
    Api.get("skills/getCategories")
      .then((response) => {
        dispatch({
          type: actionTypes.GET_CATEGORY_SUCCESS,
          payload: {
            getCategoryPending: false,
            getCategorySuccess: true,
            getCategoryError: null,

            categoryList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.GET_CATEGORY_ERROR,
          payload: {
            getCategoryPending: false,
            getCategorySuccess: true,
            getCategoryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}

export function addSkillsCategory(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_CATEGORY_PENDING,
      payload: {
        addCategoryPending: true,
        addCategorySuccess: false,
        addCategoryError: null,
      },
    });
    dispatch(showLoader());
    Api.post("skills/addSkillsCategories", data)
      .then((response) => {
        console.log(response);
        if (response.message === "Duplicate record found ") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "Duplicate record found",
            })
          );
        }
        if (response.message === "Successfully added skills categories") {
          dispatch({
            type: actionTypes.ADD_CATEGORY_SUCCESS,
            payload: {
              addCategoryPending: false,
              addCategorySuccess: true,
              addCategoryError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added category",
            })
          );
        }
        dispatch(hideLoader());
        dispatch(getCategories());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.ADD_CATEGORY_ERROR,
          payload: {
            addCategoryPending: false,
            addCategorySuccess: true,
            addCategoryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Add Data.",
          })
        );
      });
  };
}

export function updateSkillsCategories(id, data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CATEGORY_PENDING,
      payload: {
        updateCategoryPending: true,
        updateCategorySuccess: false,
        updateCategoryError: null,
      },
    });
    dispatch(showLoader());
    // if (data.duplicateFound === true) {
    //   dispatch(hideLoader());
    //   dispatch(
    //     showAlert({
    //       isOpen: true,
    //       title: "Warning",
    //       type: "warning",
    //       msg: "Duplicate Category Found",
    //     })
    //   );
    // } else {
    Api.put(`skills/updateSkillsCategories/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (response.message === "Successfully updated skills categories") {
          dispatch({
            type: actionTypes.UPDATE_CATEGORY_SUCCESS,
            payload: {
              updateCategoryPending: false,
              updateCategorySuccess: true,
              updateCategoryError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated category",
            })
          );
          dispatch(getTechnologiesForCategory({ showAllMapping: true }));
        }
        if (response.message === "Failed to update Categories") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "Duplicate Category Found",
            })
          );
        }
        if (response.message === "Duplicate record found ") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "Duplicate Category Found",
            })
          );
        }
        dispatch(getCategories());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_CATEGORY_ERROR,
          payload: {
            updateCategoryPending: false,
            updateCategorySuccess: true,
            updateCategoryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Update Data.",
          })
        );
      });
    // }
  };
}

export function deleteSkillCategory(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_CATEGORY_PENDING,
      payload: {
        deleteCategoryPending: true,
        deleteCategorySuccess: false,
        deleteCategoryError: null,
      },
    });
    dispatch(showLoader());
    console.log("delte");
    Api.delete(`skills/deleteSkillsCategories/${id}`)
      .then((response) => {
        console.log(response);
        dispatch({
          type: actionTypes.DELETE_CATEGORY_SUCCESS,
          payload: {
            deleteCategoryPending: false,
            deleteCategorySuccess: true,
            deleteCategoryError: null,
          },
        });
        dispatch(hideLoader());
        if (
          response.message ===
          "Technologies are mapped to this category. Please delete the mappings before"
        ) {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: response.message,
            })
          );
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "The skill category was successfully deleted",
            })
          );
        }
        dispatch(getCategories());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DELETE_CATEGORY_ERROR,
          payload: {
            deleteCategoryPending: false,
            deleteCategorySuccess: true,
            deleteCategoryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Delete Data.",
          })
        );
      });
  };
}

export function getProficiencies() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_PROFICIENCY_PENDING,
      payload: {
        getProficiencyPending: true,
        getProficiencySuccess: false,
        getProficiencyError: null,
      },
    });
    Api.get("skills/getProficiencies")
      .then((response) => {
        dispatch({
          type: actionTypes.GET_PROFICIENCY_SUCCESS,
          payload: {
            getProficiencyPending: false,
            getProficiencySuccess: true,
            getProficiencyError: null,

            ProficiencyList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.GET_PROFICIENCY_ERROR,
          payload: {
            getProficiencyPending: false,
            getProficiencySuccess: true,
            getProficiencyError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}

export function addProficiency(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PROFICIENCY_PENDING,
      payload: {
        addProficiencyPending: true,
        addProficiencySuccess: false,
        addProficiencyError: null,
      },
    });
    dispatch(showLoader());
    console.log("addworkfin");
    Api.post("skills/addSkillsProficiency", data)
      .then((response) => {
        console.log(response);
        if (response.message === "Duplicate record found ") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "Duplicate record found",
            })
          );
        }

        if (response.message === "Successfully added Proficiency") {
          dispatch({
            type: actionTypes.ADD_PROFICIENCY_SUCCESS,
            payload: {
              addProficiencyPending: false,
              addProficiencySuccess: true,
              addProficiencyError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added Proficiency",
            })
          );
        }
        dispatch(hideLoader());
        dispatch(getProficiencies());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.ADD_CATEGORY_ERROR,
          payload: {
            addProficiencyPending: false,
            addProficiencySuccess: true,
            addProficiencyError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Add Data.",
          })
        );
      });
  };
}

export function updateProficiency(id, data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PROFICIENCY_PENDING,
      payload: {
        updateProficiencyPending: true,
        updateProficiencySuccess: false,
        updateProficiencyError: null,
      },
    });
    dispatch(showLoader());
    Api.put(`skills/updateSkillsProficiency/${id}`, data)
      .then((response) => {
        if (response.message === "Successfully updated Proficiency") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated proficiency",
            })
          );
        }
        if (response.message === "Duplicate record found ") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "Duplicate Proficiency Found",
            })
          );
        }
        console.log(response);
        dispatch({
          type: actionTypes.UPDATE_PROFICIENCY_SUCCESS,
          payload: {
            updateProficiencyPending: false,
            updateProficiencySuccess: true,
            updateProficiencyError: null,
          },
        });
        dispatch(hideLoader());
        dispatch(getProficiencies());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_PROFICIENCY_ERROR,
          payload: {
            updateProficiencyPending: false,
            updateProficiencySuccess: true,
            updateProficiencyError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Update Data.",
          })
        );
      });
  };
}

export function deleteProficiency(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_PROFICIENCY_PENDING,
      payload: {
        deleteProficiencyPending: true,
        deleteProficiencySuccess: false,
        deleteProficiencyError: null,
      },
    });
    dispatch(showLoader());
    console.log("delte");
    Api.delete(`skills/deleteSkillsProficiency/${id}`)
      .then((response) => {
        console.log(response);
        dispatch({
          type: actionTypes.DELETE_PROFICIENCY_SUCCESS,
          payload: {
            deleteProficiencyPending: false,
            deleteProficiencySuccess: true,
            deleteProficiencyError: null,
          },
        });
        dispatch(hideLoader());
        if (response.message === "Successfully deleted Proficiency") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "The skill proficiency was successfully deleted",
            })
          );
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "Proficiency is in use",
            })
          );
        }
        dispatch(getProficiencies());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DELETE_PROFICIENCY_ERROR,
          payload: {
            deleteProficiencyPending: false,
            deleteProficiencySuccess: true,
            deleteProficiencyError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Delete Data.",
          })
        );
      });
  };
}

export function getTechnologies() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TECHNOLOGY_PENDING,
      payload: {
        getTechnologyPending: true,
        getTechnologySuccess: false,
        getTechnologyError: null,
      },
    });
    Api.get("skills/getTechnologies")
      .then((response) => {
        // console.log(response);
        dispatch({
          type: actionTypes.GET_TECHNOLOGY_SUCCESS,
          payload: {
            getTechnologyPending: false,
            getTechnologySuccess: true,
            getTechnologyError: null,

            TechnologyList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.GET_TECHNOLOGY_ERROR,
          payload: {
            getTechnologyPending: false,
            getTechnologySuccess: true,
            getTechnologyError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to fetch Data.",
          })
        );
      });
  };
}

export function addSkillsTechnology(data, mappingData) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_TECHNOLOGY_PENDING,
      payload: {
        addTechnologyPending: true,
        addTechnologySuccess: false,
        addTechnologyError: null,
      },
    });
    dispatch(showLoader());
    Api.post("skills/addSkillsTechnology", data)
      .then((response) => {
        dispatch(hideLoader());
        console.log(response);
        if (response.message === "Duplicate record found ") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "Duplicate record found",
            })
          );
        }

        if (response.message === "Successfully added Technology") {
          dispatch({
            type: actionTypes.ADD_TECHNOLOGY_SUCCESS,
            payload: {
              addTechnologyPending: false,
              addTechnologySuccess: true,
              addTechnologyError: null,

              newTechnologyID: response.data._id,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully added technology",
            })
          );
          dispatch(hideLoader());
          dispatch(getTechnologies());
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.ADD_TECHNOLOGY_ERROR,
          payload: {
            addTechnologyPending: false,
            addTechnologySuccess: true,
            addTechnologyError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Add Data.",
          })
        );
      });
  };
}

export function saveCategoryTechnolgy(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SAVE_TECHNOLOGY_CATEGORY_PENDING,

      payload: {
        saveTechnologyCategoryPending: true,
        saveTechnologyCategorySuccess: false,
        saveTechnologyCategoryError: null,
      },
    });
    Api.post("skills/saveCategoryTechnology", data)
      .then((response) => {
        console.log(response);
        if (
          response.message === "already have this CategoryTechnology mapping"
        ) {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "This Technology already mapped",
            })
          );
        }
        if (
          response.message ===
          "Successfully save CategoryTechnology mappingData"
        ) {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully Mapped Technology",
            })
          );
        }
        dispatch({
          type: actionTypes.SAVE_TECHNOLOGY_CATEGORY_SUCCESS,

          payload: {
            saveTechnologyCategoryPending: false,
            saveTechnologyCategorySuccess: true,
            saveTechnologyCategoryError: null,
          },
        });
        dispatch(getCategories());
        dispatch(getTechnologies());
        dispatch(getTechnologiesForCategory({ showAllMapping: true }));
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.SAVE_TECHNOLOGY_CATEGORY_ERROR,
          payload: {
            saveTechnologyCategoryPending: false,
            saveTechnologyCategorySuccess: true,
            saveTechnologyCategoryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Map Data.",
          })
        );
      });
  };
}

export function DeleteMappedCategoryTechnolgy(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_MAPPED_TECHNOLOGY_CATEGORY_PENDING,

      payload: {
        deleteMappedTechnologyCategoryPending: false,
        deleteMappedTechnologyCategorySuccess: false,
        deleteMappedTechnologyCategoryError: null,
      },
    });
    Api.delete("skills/deleteMappingData", data)
      .then((response) => {
        console.log(response);
        if (
          response.message === "Successfully updated CategoryTechnologyData"
        ) {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully Removed From Mapping",
            })
          );
        }
        dispatch({
          type: actionTypes.DELETE_MAPPED_TECHNOLOGY_CATEGORY_SUCCESS,

          payload: {
            deleteMappedTechnologyCategoryPending: false,
            deleteMappedTechnologyCategorySuccess: true,
            deleteMappedTechnologyCategoryError: null,
          },
        });
        dispatch(getTechnologiesForCategory({ showAllMapping: true }));
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DELETE_MAPPED_TECHNOLOGY_CATEGORY_ERROR,
          payload: {
            deleteMappedTechnologyCategoryPending: false,
            deleteMappedTechnologyCategorySuccess: true,
            deleteMappedTechnologyCategoryError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Remove From Mapping.",
          })
        );
      });
  };
}

export function updateSkillsTechnology(id, data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TECHNOLOGY_PENDING,

      payload: {
        updateTechnologyPending: true,
        updateTechnologySuccess: false,
        updateTechnologyError: null,
      },
    });
    dispatch(showLoader());
    // if (data.duplicateFound === true) {
    //   dispatch(hideLoader());
    //   dispatch(
    //     showAlert({
    //       isOpen: true,
    //       title: "Warning",
    //       type: "warning",
    //       msg: "Duplicate technology Found",
    //     })
    //   );
    // } else {
    Api.put(`skills/updateSkillsTechnology/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.UPDATE_TECHNOLOGY_SUCCESS,

          payload: {
            updateTechnologyPending: false,
            updateTechnologySuccess: true,
            updateTechnologyError: null,
          },
        });
        if (response.message === "Successfully updated Technology") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "Successfully updated technology",
            })
          );
        }
        if (response.message === "Duplicate record found ") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: "Duplicate Technology Found",
            })
          );
        }
        dispatch(getTechnologies());
        // dispatch(getTechnologiesForCategory({ showAllMapping: true }));
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.UPDATE_TECHNOLOGY_ERROR,
          payload: {
            updateTechnologyPending: false,
            updateTechnologySuccess: true,
            updateTechnologyError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Update Data.",
          })
        );
      });
    // }
  };
}

export function deleteSkillsTechnology(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_TECHNOLOGY_PENDING,
      payload: {
        deleteTechnologyPending: true,
        deleteTechnologySuccess: false,
        deleteTechnologyError: null,
      },
    });
    dispatch(showLoader());
    Api.delete(`skills/deleteSkillsTechnology/${id}`)
      .then((response) => {
        console.log(response);
        dispatch({
          type: actionTypes.DELETE_TECHNOLOGY_SUCCESS,
          payload: {
            deleteTechnologyPending: false,
            deleteTechnologySuccess: true,
            deleteTechnologyError: null,
          },
        });
        dispatch(hideLoader());
        if (response.message === "Successfully deleted Technology") {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: "The skill technology was successfully deleted",
            })
          );
        } else {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Warning",
              type: "warning",
              msg: response.message,
            })
          );
        }
        dispatch(getTechnologiesForCategory({ showAllMapping: true }));
        dispatch(getTechnologies());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DELETE_TECHNOLOGY_ERROR,
          payload: {
            deleteTechnologyPending: false,
            deleteTechnologySuccess: true,
            deleteTechnologyError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Delete Data.",
          })
        );
      });
  };
}

export function downloadTechonlogyReport(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOWNLOAD_TECHNOLOGY_REPORT_PENDING,
      payload: {
        downloadTechonlogyReportPending: true,
        downloadTechonlogyReportSuccess: false,
        downloadTechonlogyReportError: null,
      },
    });
    dispatch(showLoader());
    Api.post("userSkills/technologyWiseSkills/csv", data)
      .then((response) => {
        console.log(response);

        // dispatch(
        //   showAlert({
        //     isOpen: true,
        //     title: "Success",
        //     type: "success",
        //     msg: "Successfully added category",
        //   })
        // );

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "downloadTechnologyReport.csv");
        document.body.appendChild(link);
        link.click();

        dispatch(hideLoader());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DOWNLOAD_TECHNOLOGY_REPORT_ERROR,
          payload: {
            downloadTechonlogyReportPending: false,
            downloadTechonlogyReportSuccess: true,
            downloadTechonlogyReportError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to Add Data.",
          })
        );
      });
  };
}

export function downloadUserReport(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOWNLOAD_USER_REPORT_PENDING,
      payload: {
        downloadUserReportPending: true,
        downloadUserReportSuccess: false,
        downloadUserReportError: null,
      },
    });
    dispatch(showLoader());
    Api.get("userSkills/employeeWiseSkills/csv")
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "downloadEmployeeReport.csv");
        document.body.appendChild(link);
        link.click();

        console.log(response);

        dispatch({
          type: actionTypes.DOWNLOAD_USER_REPORT_SUCCESS,
          payload: {
            downloadUserReportPending: false,
            downloadUserReportSuccess: true,
            downloadUserReportError: null,
          },
        });
        // dispatch(
        //   showAlert({
        //     isOpen: true,
        //     title: "Success",
        //     type: "success",
        //     msg: "",
        //   })
        // );
        // }
        dispatch(hideLoader());
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DOWNLOAD_USER_REPORT_ERROR,
          payload: {
            downloadUserReportPending: false,
            downloadUserReportSuccess: true,
            downloadUserReportError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
