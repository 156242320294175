import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// import { Role } from "../../helpers/role";
import RestrictedRoute from "../restricted/index";
import LunchPreferenceContainer from "./LunchPreference/LunchPreferenceContainer";
import LunchManagementContainer from "./LunchManagement/LunchManagementContainer";
import UserManagementContainer from "./UserManagement/UserManagementContainer";
import ProfileContainer from "./Profile/ProfileContainer";
import LoginContainer from "./Login/LoginContainer";
import SystemManagementContainer from "./SystemManagement/SystemManagementContainer";
import MyLeavesContainer from "./MyLeaves/MyLeavesContainer";
import LeaveApplicationsContainer from "./LeaveApplications/LeaveApplicationsContainer";
// import LeaveManagementContainer from "./LeaveManagement/LeaveManagementContainer";
import JobApplicationsContainer from "./JobApplications/JobApplicationsContainer";
// import RecruitmentManagementContainer from "./RecruitmentManagement/RecruitmentManagementContainer";
import InterviewScheduleContainer from "./InterviewSchedule/InterviewScheduleContainer";
import JoinUsContainer from "./JoinUs/JoinUsContainer";
import LeaveReportingContainer from "./LeaveReporting/LeaveReportingContainer";
import HRAdministrationContainer from "./HRAdministration/HRAdministrationContainer";
import ImpDocumentsContainer from "./ImpDocuments/ImpDocumentsContainer";
import { globals } from "../../store/globals";
import Cookies from "universal-cookie";
import { COOKIE_DOMAIN } from "../../constants/env";
// import Calender from "./ApplyforWFH/components/calender";
import ApplyForWFHContainer from "./ApplyforWFH/ApplyForWFHContainer";
import WfhApplicationContainer from "./WFHApplications/WfhApplicationContainer";
import WFHAndLeaveContainer from "./View WFHAndLeaves/WFHAndLeaveContainer";
import MyQueriesContainer from "./MyQueries/MyQueriesContainer";
import ViewQueriesContainer from "./ViewQueries/ViewQueriesContainer";
import ApplicationManagerContainer from "./ApplicationManager/ApplicationManagerContainer";
import queryString from "query-string";
import MySkillsContainer from "./MySkills/MySkillsContainer";
import SkillManagementContainer from "./SkillManagement/SkillManagementContainer";
import EmployeeSkillsContainer from "./EmployeeSkills/EmployeeSkillsContainer";

function App({ match }) {
  const userId = globals.store.getState().auth.loginUser.userId;

  var defaultRedirection = (
    <Redirect exact from={`${match.url}/`} to={`${match.url}lunchpreference`} />
  );

  var permission = globals.store.getState().auth.loginUser.userPermissions;
  var permissionNameList = permission.map((x) => x.name);
  // console.log(permissionNameList);
  var level = globals.store.getState().auth.loginUser.level;
  var loginUser = globals.store.getState().auth.loginUser;
  var cookies = new Cookies();
  // console.log("loginUser", loginUser);

  if (window.location.search !== "" && userId) {
    const parsed = queryString.parse(window.location.search);
    // console.log("parsed", parsed);
    var url = parsed.url;
    var type = parsed.type;
    var mode = parsed.mode ? parsed.mode : "cookie";
    // console.log("userId ", userId);
  }
  if (type === "e" && url !== null) {
    console.log("type  && userId", type === "e" && url !== null);
    // globals.history.push("/lunchpreference");

    var actual_data = JSON.parse(JSON.stringify(loginUser));
    delete actual_data.userPermissions;

    if (mode === "queryString") {
      const query = new URLSearchParams(actual_data).toString();
      window.open(url + "?" + query, "_self");
    } else {
      cookies.set("usercredentials", JSON.stringify(actual_data), {
        maxAge: 60 * 1,
        domain: COOKIE_DOMAIN,
      });
      window.open(url, "_self");
    }

    return;
  }

  return (
    <Switch>
      {defaultRedirection}
      {/* <Redirect exact to={`/login?url=${urll}`} /> */}
      <Route exact path="/login" component={LoginContainer} />

      {/* <Route
        path="/lunchpreference"
        component={() => {
          window.location.href = "https://youtube.com";
          // window.open("https://youtube.com", "_self");
          return null;
        }}
      /> */}

      {permissionNameList.indexOf("LeaveReporting") !== -1 && (
        <RestrictedRoute
          path={`${match.url}leavereporting`}
          component={LeaveReportingContainer}
        />
      )}
      {permissionNameList.indexOf("JobApplications") !== -1 && (
        <RestrictedRoute
          path={`${match.url}jobapplications`}
          component={JobApplicationsContainer}
        />
      )}
      {(level >= 4 ||
        permissionNameList.indexOf("InterviewSchedule") !== -1) && (
        <RestrictedRoute
          path={`${match.url}interviewschedule`}
          component={InterviewScheduleContainer}
        />
      )}
      {/* {permissionNameList.indexOf("JobManagement") !== -1 && (
        <RestrictedRoute
          path={`${match.url}recruitmentmanagement`}
          component={RecruitmentManagementContainer}
        />
      )} */}
      {permissionNameList.indexOf("Profile") !== -1 && (
        <RestrictedRoute
          path={`${match.url}profile`}
          component={ProfileContainer}
        />
      )}
      {permissionNameList.indexOf("LunchPreference") !== -1 && (
        <RestrictedRoute
          path={`${match.url}lunchpreference`}
          component={LunchPreferenceContainer}
        />
      )}
      {permissionNameList.indexOf("LunchManagement") !== -1 && (
        <RestrictedRoute
          path={`${match.url}lunchmanagement`}
          component={LunchManagementContainer}
        />
      )}
      {permissionNameList.indexOf("UserManagement") !== -1 && (
        <RestrictedRoute
          path={`${match.url}user`}
          component={UserManagementContainer}
        />
      )}
      {/* {permissionNameList.indexOf("SystemManagement") !== -1 && ( */}
      <RestrictedRoute
        path={`${match.url}systemmanagement`}
        component={SystemManagementContainer}
      />
      {/* )} */}
      {permissionNameList.indexOf("MyLeaves") !== -1 && (
        <RestrictedRoute
          path={`${match.url}myleaves`}
          component={MyLeavesContainer}
        />
      )}
      {/* {permissionNameList.indexOf("LeaveManagement") !== -1 && (
        <RestrictedRoute
          path={`${match.url}leavemanagement`}
          component={LeaveManagementContainer}
        />
      )} */}
      {permissionNameList.indexOf("HRMSAdministration") !== -1 && (
        <RestrictedRoute
          path={`${match.url}hradministration`}
          component={HRAdministrationContainer}
        />
      )}
      {(level >= 4 ||
        permissionNameList.indexOf("LeaveApplications") !== -1) && (
        <RestrictedRoute
          path={`${match.url}leaveapplications`}
          component={LeaveApplicationsContainer}
        />
      )}
      {permissionNameList.indexOf("ImportantDocuments") !== -1 && (
        <RestrictedRoute
          path={`${match.url}important-documents`}
          component={ImpDocumentsContainer}
        />
      )}
      {permissionNameList.indexOf("MyWFH") !== -1 && (
        <RestrictedRoute
          path={`${match.url}apply-for-wfh`}
          component={ApplyForWFHContainer}
        />
      )}

      {(level >= 4 || permissionNameList.indexOf("WFHApplication") !== -1) && (
        <RestrictedRoute
          path={`${match.url}WFHApplication`}
          component={WfhApplicationContainer}
        />
      )}
      {permissionNameList.indexOf("ViewWFH/Leaves") !== -1 && (
        // {(level >= 4 || permissionNameList.indexOf("ViewWFH/Leaves") !== -1) && (
        <RestrictedRoute
          path={`${match.url}View-WFH-Leaves`}
          component={WFHAndLeaveContainer}
        />
      )}

      {permissionNameList.indexOf("MyQueries") !== -1 && (
        <RestrictedRoute
          path={`${match.url}my-queries`}
          component={MyQueriesContainer}
        />
      )}

      {/* {permissionNameList.indexOf("ViewQueries") !== -1 && ( */}
      {(level >= 4 || permissionNameList.indexOf("ViewQueries") !== -1) && (
        <RestrictedRoute
          path={`${match.url}view-queries`}
          component={ViewQueriesContainer}
        />
      )}
      {permissionNameList.indexOf("AppDashboard") !== -1 && (
        <RestrictedRoute
          path={`${match.url}application-manager`}
          component={ApplicationManagerContainer}
        />
      )}
      {permissionNameList.indexOf("MySkills") !== -1 && (
        <RestrictedRoute
          path={`${match.url}my-skills`}
          component={MySkillsContainer}
        />
      )}
      {permissionNameList.indexOf("SkillsManagement") !== -1 && (
        <RestrictedRoute
          path={`${match.url}skill-management`}
          component={SkillManagementContainer}
        />
      )}
      {permissionNameList.indexOf("EmployeeSkills") !== -1 && (
        <RestrictedRoute
          path={`${match.url}employee-skills`}
          component={EmployeeSkillsContainer}
        />
      )}
      {/* <Route exact path="/apply-for-wfh" component={Calenders} /> */}
      <Route exact path="/joinus" component={JoinUsContainer} />
      <RestrictedRoute path={`*`} component={LoginContainer} />
    </Switch>
  );
}

export default App;
