import { actionTypes } from "./queryAction";
const {
  APPLY_QUERY_PENDING,
  APPLY_QUERY_SUCCESS,
  APPLY_QUERY_ERROR,

  USERQUERYLIST_PENDING,
  USERQUERYLIST_SUCCESS,
  USERQUERYLIST_ERROR,

  GETUSERQUERY_PENDING,
  GETUSERQUERY_SUCCESS,
  GETUSERQUERY_ERROR,

  MODAL_NULL_GET_QUERY_DATA_PENDING,

  ADD_MESSAGE_PENDING,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_ERROR,
} = actionTypes;

const initialState = {
  applyForQueryPending: false,
  applyForQuerySuccess: false,
  applyForQueryError: null,

  userQueryListPending: false,
  userQueryListSuccess: false,
  userQueryListError: null,

  getUserQueryPending: false,
  getUserQuerySuccess: false,
  getUserQueryError: null,

  addMessageQueryPending: false,
  addMessageQuerySuccess: false,
  addMessageQueryError: null,

  // getUserQueryData: [],
  getUserQueryData: null,
  userQueryList: [],

  hasNextPagee: false,
  hasPrevPagee: false,
  limitt: null,
  nextPagee: null,

  pagingCounterr: null,
  prevPagee: null,
  totalDocsData: null,
  totalPagess: null,

  viewIdhasNextPage: false,
  viewIdhasPrevPage: false,
  viewIdlimit: null,
  viewIdnextPage: null,
  viewIdpagingCounter: null,
  viewIdprevPage: null,
  viewIdtotalDocs: null,
  viewIdtotalPages: null,
};

export default function employeeQueryReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case APPLY_QUERY_PENDING:
    case APPLY_QUERY_SUCCESS:
    case APPLY_QUERY_ERROR:
      return Object.assign({}, state, payload);

    case USERQUERYLIST_PENDING:
    case USERQUERYLIST_SUCCESS:
    case USERQUERYLIST_ERROR:
      return Object.assign({}, state, payload);

    case GETUSERQUERY_PENDING:
    case GETUSERQUERY_SUCCESS:
    case GETUSERQUERY_ERROR:
      return Object.assign({}, state, payload);

    case MODAL_NULL_GET_QUERY_DATA_PENDING:
      return Object.assign({}, state, payload);

    case ADD_MESSAGE_PENDING:
    case ADD_MESSAGE_SUCCESS:
    case ADD_MESSAGE_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
