import { actionTypes } from "./aplicationDomainActions";

const {
  APPLICATION_DOMAIN_PENDING,
  APPLICATION_DOMAIN_ERROR,
  APPLICATION_DOMAIN_SUCCESS,

  APPLICATION_DOMAIN_ADD_PENDING,
  APPLICATION_DOMAIN_ADD_ERROR,
  APPLICATION_DOMAIN_ADD_SUCCESS,

  APPLICATION_DOMAIN_EDIT_PENDING,
  APPLICATION_DOMAIN_EDIT_ERROR,
  APPLICATION_DOMAIN_EDIT_SUCCESS,

  APPLICATION_DOMAIN_DELETE_PENDING,
  APPLICATION_DOMAIN_DELETE_ERROR,
  APPLICATION_DOMAIN_DELETE_SUCCESS,
} = actionTypes;

const initialState = {
  aplicationDomainPending: false,
  aplicationDomainSuccess: false,
  aplicationDomainError: null,

  aplicationDomainAddPending: false,
  aplicationDomainAddSuccess: false,
  aplicationDomainAddError: null,

  aplicationDomainEditPending: false,
  aplicationDomainEditSuccess: false,
  aplicationDomainEditError: null,

  aplicationDomainDeletePending: false,
  aplicationDomainDeleteSuccess: false,
  aplicationDomainDeleteError: null,

  aplicationDomainList: [],
};

export default function aplicationDomain(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case APPLICATION_DOMAIN_PENDING:
    case APPLICATION_DOMAIN_ERROR:
    case APPLICATION_DOMAIN_SUCCESS:
      return Object.assign({}, state, payload);
    case APPLICATION_DOMAIN_ADD_PENDING:
    case APPLICATION_DOMAIN_ADD_ERROR:
      return Object.assign({}, state, payload);
    case APPLICATION_DOMAIN_ADD_SUCCESS: {
      const newHoliday = payload.aplicationDomain;
      const newHolidaysList = [...state.aplicationDomainList, newHoliday];

      const newState = {
        aplicationDomainList: newHolidaysList,
        aplicationDomainAddPending: payload.aplicationDomainAddPending,
        aplicationDomainAddSuccess: payload.aplicationDomainAddSuccess,
        aplicationDomainAddError: payload.aplicationDomainAddError,
      };
      return Object.assign({}, state, newState);
    }
    case APPLICATION_DOMAIN_EDIT_PENDING:
    case APPLICATION_DOMAIN_EDIT_ERROR:
      return Object.assign({}, state, payload);
    case APPLICATION_DOMAIN_EDIT_SUCCESS: {
      const newHolidaysList = state.aplicationDomainList.map((list) => {
        if (list._id === payload.aplicationDomain._id) {
          return payload.aplicationDomain;
        } else {
          return list;
        }
      });
      return Object.assign(
        {},
        state,
        { aplicationDomainList: newHolidaysList },
        payload
      );
    }
    case APPLICATION_DOMAIN_DELETE_PENDING:
    case APPLICATION_DOMAIN_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case APPLICATION_DOMAIN_DELETE_SUCCESS: {
      const newHolidaysList = state.aplicationDomainList.filter((list) => {
        return list._id !== payload.id;
      });
      return Object.assign(
        {},
        state,
        { aplicationDomainList: newHolidaysList },
        payload
      );
    }
    default:
      return state;
  }
}
