export default class Auth {
  static getTokenFromLocalStorage() {
    try {
      return window.localStorage.getItem("token") ? JSON.parse(window.localStorage.getItem("token")) : null;
    } catch (e) {
      localStorage.clear();
    }
  }
  static getTokenExpiryFromLocalStorage() {
    try {
      return window.localStorage.getItem("tokenExpiresOn") ? JSON.parse(window.localStorage.getItem("tokenExpiresOn")) : null;
    } catch (e) {
      localStorage.clear();
    }
  }
  static getProfileUrlFromLocalStorage() {
    try {
      return window.localStorage.getItem("profileurl") ? JSON.parse(window.localStorage.getItem("profileurl")) : null;
    } catch (e) {
      localStorage.clear();
    }
  }
  static getLoginUserFromLocalStorage() {
    try {
      return window.localStorage.getItem("loginUser")
        ? JSON.parse(window.localStorage.getItem("loginUser"))
        : {
            userRole: "",
            userId: "",
            userPermissions: [],
            level: 0,
          };
    } catch (e) {
      localStorage.clear();
    }
  }

  static getDefaultLoginUserFromLocalStorage() {
    return {
      userRole: "",
      userId: "",
      userPermissions: [],
      level: 0,
    };
  }
}
