import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { Input, TextArea, ButtonView, DatePicker } from "../../../controls";
import moment from "moment";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";

const EditProfile = ({ user, profileURL, updateProfile }) => {
  const [editProfileShow, setEditProfileShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pinCode: "",
    country: "",
    dob: "",
    role: "",
    isActive: "",
    createdBy: "",
    updatedBy: "",

    employeeNumber: "",
    level: "",
    location: "",
    reportingManagerEmployeeId: "",
  });

  const handleOpen = () => {
    // console.log(user._id)
    const dob = user.userId[0].dob;
    // console.log(user);
    const userDetails = {
      firstName: user.userId[0].firstName,
      lastName: user.userId[0].lastName,
      phoneNumber: user.userId[0].phoneNumber,
      // email: user.userId[0].email,
      addressLine1: user.userId[0].addressLine1,
      addressLine2: user.userId[0].addressLine2,
      city: user.userId[0].city,
      state: user.userId[0].state,
      pinCode: user.userId[0].pinCode,
      // country: user.userId[0].country,
      dob: new Date(dob),
      // "dob" : new Date(user.userId[0].dob).toISOString().moment.utc().format('MM/DD/YYYY').split("/").reverse().join("-"),
      // role: user.userId[0].role,
      isActive: "true",
      createdBy: user.userId[0].createdBy,
      updatedBy: user.userId[0].updatedBy,

      // employeeNumber: user.userId[0].employeeNumber,
      // level: user.userId[0].level,
      // location: user.userId[0].location[0],
      // reportingManagerEmployeeId: user.userId[0].reportingManagerEmployeeId,
      // insuranceNumber: user.userId[0].insuranceNumber,
    };
    setUserId(user.userId[0]._id);
    setUserData(userDetails);
    setEditProfileShow(true);
  };

  const handleSubmit = () => {
    var rules = {
      required: [
        "firstName",
        "lastName",
        // "phoneNumber",
        "addressLine1",
        "addressLine2",
        // "city",
        // "state",
        // "pinCode",
        // "country",
        "dob",
      ],
    };

    var { errors } = validator.validate(rules, {
      ...trimFieldsData(userData),
    });

    setErrors(errors);
    // console.log(errors)
    if (!Object.keys(errors).length) {
      const userDetails = {
        personalDetails: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          phoneNumber: userData.phoneNumber,
          // email: userData.email,
          addressLine1: userData.addressLine1,
          addressLine2: userData.addressLine2,
          city: userData.city,
          state: userData.state,
          pinCode: userData.pinCode,
          // country: userData.country,
          dob: moment(userData.dob).format("YYYY-MM-DD"),
          // role: userData.role,
          isActive: "1",
          createdBy: userData.createdBy,
          updatedBy: `${userData.firstName} ${userData.lastName}`,
        },

        // employeeDetails: {
        //   employeeNumber: userData.employeeNumber,
        //   level: userData.level,
        //   location: [userData.location],
        //   reportingManagerEmployeeId: userData.reportingManagerEmployeeId,
        //   insuranceNumber: userData.insuranceNumber,
        //   isActive: "1",
        // },
      };

      updateProfile(userDetails, userId);
      // onresetFilterPagination()
      handleClose();
    }
  };

  const handleClose = () => {
    setEditProfileShow(false);
    setUserId("");
    setErrors({});
  };
  return (
    <div className="edit_profile_container ps-3 pt-3">
      {/* <Image src={profileURL} roundedCircle /> */}
      <div className="user_detail">
        <div className="user_name">
          {user?.userId[0]?.firstName} {user?.userId[0]?.lastName}
        </div>
        <Row>
          <Col>
            <ButtonView
              onClick={handleOpen}
              title={"Edit Profile"}
              size="medium"
              // tooltip="Copy to clipboard"
            />
          </Col>
        </Row>
      </div>

      <Modal
        size="lg"
        show={editProfileShow}
        onHide={handleClose}
        aria-labelledby="edit_profile"
      >
        <Modal.Header>
          <Modal.Title id="edit_profile">Edit Profile</Modal.Title>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <AiOutlineClose size={20} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="firstName"
                label="First Name"
                value={userData.firstName}
                type="text"
                placeholder="First Name"
                onChange={(e) =>
                  setUserData({ ...userData, firstName: e.target.value })
                }
                size="fluid"
                error={errors["firstName"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="lastname"
                label="Last Name"
                value={userData.lastName}
                type="text"
                placeholder="Last Name"
                onChange={(e) =>
                  setUserData({ ...userData, lastName: e.target.value })
                }
                size="fluid"
                error={errors["lastName"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <DatePicker
                name="dob"
                label="Date of birth"
                value={userData.dob}
                onChange={(e) => setUserData({ ...userData, dob: e })}
                error={errors["dob"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Input
                name="phoneNumber"
                label="Phone Number"
                value={userData.phoneNumber}
                type="tel"
                isNum={true}
                placeholder="Phone Number"
                onChange={(e) =>
                  setUserData({ ...userData, phoneNumber: e.target.value })
                }
                size="fluid"
                error={errors["phoneNumber"]}
                // isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <TextArea
                name="addressLine1"
                label="Address 1"
                value={userData.addressLine1}
                type="text"
                placeholder="Address"
                onChange={(e) =>
                  setUserData({ ...userData, addressLine1: e.target.value })
                }
                size="fluid"
                error={errors["addressLine1"]}
                isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <TextArea
                name="addressLine2"
                label="Address 2"
                value={userData.addressLine2}
                type="text"
                placeholder="Address"
                onChange={(e) =>
                  setUserData({ ...userData, addressLine2: e.target.value })
                }
                size="fluid"
                error={errors["addressLine2"]}
                isrequired={true}
              />
            </Col>

            <Col md={12} lg={6} className="mb-3">
              <Input
                nmae="city"
                label="City"
                type="text"
                value={userData.city}
                placeholder="City"
                onChange={(e) =>
                  setUserData({ ...userData, city: e.target.value })
                }
                size="fluid"
                error={errors["city"]}
                // isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Input
                nmae="state"
                label="State"
                type="text"
                value={userData.state}
                placeholder="State"
                onChange={(e) =>
                  setUserData({ ...userData, state: e.target.value })
                }
                size="fluid"
                error={errors["state"]}
                // isrequired={true}
              />
            </Col>
            <Col md={12} lg={6} className="mb-3">
              <Input
                nmae="pinCode"
                label="Pin Code"
                type="tel"
                isNum={true}
                value={userData.pinCode}
                placeholder="Pin Code"
                onChange={(e) =>
                  setUserData({ ...userData, pinCode: e.target.value })
                }
                size="fluid"
                error={errors["pinCode"]}
                // isrequired={true}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col xs={6} md={6} lg={3}>
              {/* <Col md={12} lg={{ span: 6, offset: 3 }} className="mb-3"> */}

              <ButtonView
                onClick={handleClose}
                title={"Cancel"}
                styles="danger-outline"
              />
            </Col>
            <Col xs={6} md={6} lg={3}>
              <ButtonView
                onClick={() => handleSubmit()}
                title={"Save"}
                styles="secondary"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditProfile;
