import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import { ButtonView } from "../../../controls";

import { HiDownload } from "react-icons/hi";

const EmployeeReports = ({ downloadUserReport, downloadTechonlogyReport }) => {
  return (
    <div className="Employee_Skills_List mb-3">
      <Card className="skills_list">
        <Card.Header>
          <Card.Title className="mt-1 mt-lg-0 fs-sm-1">
            Employee Report(s)
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div>
            <Row>
              <Col md={3} className="mb-2">
                <ButtonView
                  onClick={() => {
                    downloadTechonlogyReport({ technologyIds: [] });
                  }}
                  title={
                    <div>
                      <span>
                        <HiDownload size={15} />
                      </span>
                      Technology Report
                    </div>
                  }
                  styles="primary"
                />
              </Col>
              <Col md={3}>
                <ButtonView
                  onClick={() => {
                    downloadUserReport();
                  }}
                  title={
                    <div>
                      <span>
                        <HiDownload size={15} />
                      </span>
                      Employee Report
                    </div>
                  }
                  styles="secondary"
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EmployeeReports;
