import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiOutlineFileText } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";

import { Header } from "../../common";
import SearchUsers from "./components/SearchUsers";
import LeaveSummary from "./components/LeaveSummary";
import LeaveOverview from "./components/LeaveOverview";
import { AiOutlineArrowLeft } from "react-icons/ai";
import DateRangeLeaveList from "./components/DateRangeLeaveList";
const LeaveReportingView = ({
  userList,
  getEmployeeLeaveList,
  setShow,
  showLoader,
  employeeLeaveList,
  currentYear,

  employeeLeavesSummarySuccess,
  otherLeavesSummarySuccess,

  employeeLeavesSummary,
  otherLeavesSummary,

  getEmployeeLeavesSummary,
  getOtherLeavesSummary,
  employeeLeaveListSuccess,
  reset,
  setFilter,
  resetFilter,

  optionalHolidaySummarySuccess,
  getOptionalHolidayLeaveSummary,
  optionalHolidaySummary,
  hideLeaveSummary,
  dateRangeEmployeeLeaveList,
  dateRangeLeaveList,
  startDate,
  endDate,
}) => {
  // console.log("dateRangeLeaveList", dateRangeLeaveList);

  const [viewLeaveSummary, setViewLeaveSummary] = useState(false);
  const [type, setType] = useState("");
  const showLeaveSummary = (data) => {
    setViewLeaveSummary(data);
  };

  const typeSelection = (data) => {
    //console.log(data);
    setType(data);
  };

  return (
    <>
      <Header label={"Leave Reporting"} Icon={AiOutlineFileText} />
      <div className="leave__reporting main__body padding">
        <Container>
          <Row>
            <Col sm={12} lg={12}>
              {viewLeaveSummary ? (
                <div className="backButton">
                  <AiOutlineArrowLeft
                    size={18}
                    className="arrowLeft"
                    color="#d87d2e"
                    onClick={() => {
                      setViewLeaveSummary(false);
                      hideLeaveSummary();
                    }}
                  />
                  <p>Go To Filter</p>
                </div>
              ) : (
                <SearchUsers
                  userList={userList}
                  getEmployeeLeaveList={getEmployeeLeaveList}
                  getEmployeeLeavesSummary={getEmployeeLeavesSummary}
                  getOtherLeavesSummary={getOtherLeavesSummary}
                  getOptionalHolidayLeaveSummary={
                    getOptionalHolidayLeaveSummary
                  }
                  reset={reset}
                  showLeaveSummary={showLeaveSummary}
                  typeSelection={typeSelection}
                  dateRangeEmployeeLeaveList={dateRangeEmployeeLeaveList}
                />
              )}
            </Col>
            {setShow &&
              (type.value === "By Employee" ? (
                <>
                  <Col sm={12} lg={12}>
                    {employeeLeavesSummarySuccess === false ||
                    otherLeavesSummarySuccess === false ||
                    optionalHolidaySummarySuccess === false ? (
                      <div>
                        <Card className="mt-3">
                          <Card.Body>
                            <Row>
                              <Col sm={12} lg={6}>
                                <Skeleton width={150} />
                                <Skeleton count={9} />
                              </Col>
                              <Col sm={12} lg={6}>
                                <Skeleton count={10} />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    ) : (
                      <LeaveSummary
                        currentYear={currentYear}
                        employeeLeavesSummary={employeeLeavesSummary}
                        otherLeavesSummary={otherLeavesSummary}
                        optionalHolidaySummary={optionalHolidaySummary}
                      />
                    )}
                  </Col>
                  <Col sm={12} lg={12}>
                    {employeeLeaveListSuccess === false ? (
                      <div>
                        <Card className="mt-3">
                          <Card.Body>
                            <Row>
                              <Col>
                                <Skeleton height={"50px"} />
                              </Col>
                              {/* <Col sm={6}>
                              <Skeleton count={2} />
                            </Col> */}
                            </Row>
                            <Skeleton count={10} />
                          </Card.Body>
                        </Card>
                      </div>
                    ) : (
                      <LeaveOverview
                        employeeLeaveList={employeeLeaveList}
                        currentYear={currentYear}
                        setFilter={setFilter}
                        resetFilter={resetFilter}
                      />
                    )}
                  </Col>
                </>
              ) : type.value === "By Date-Range" ? (
                <DateRangeLeaveList
                  dateRangeLeaveList={dateRangeLeaveList}
                  startDate={startDate}
                  endDate={endDate}
                />
              ) : null)}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LeaveReportingView;
