import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";

export const actionTypes = {
  USER_LIST_PENDING: "USER_LIST_PENDING",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_ERROR: "USER_LIST_ERROR",

  EMPLOYEE_LEAVE_LIST_PENDING: "EMPLOYEE_LEAVE_LIST_PENDING",
  EMPLOYEE_LEAVE_LIST_SUCCESS: "EMPLOYEE_LEAVE_LIST_SUCCESS",
  EMPLOYEE_LEAVE_LIST_ERROR: "EMPLOYEE_LEAVE_LIST_ERROR",

  OTHER_SUMMARY_PENDING: "OTHER_SUMMARY_PENDING",
  OTHER_SUMMARY_SUCCESS: "OTHER_SUMMARY_SUCCESS",
  OTHER_SUMMARY_ERROR: "OTHER_SUMMARY_ERROR",

  EMPLOYEE_SUMMARY_PENDING: "EMPLOYEE_SUMMARY_PENDING",
  EMPLOYEE_SUMMARY_SUCCESS: "EMPLOYEE_SUMMARY_SUCCESS",
  EMPLOYEE_SUMMARY_ERROR: "EMPLOYEE_SUMMARY_ERROR",

  RESET_DATA: "RESET_DATA",

  SET_FILTER: "SET_FILTER",
  RESET_FILTER: "RESET_FILTER",

  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING:
    "OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING",
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS:
    "OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS",
  OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR: "OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR",

  HIDE_LEAVESUMMARY_SUCCESS: "HIDE_LEAVESUMMARY_SUCCESS",

  DATERANGE_LEAVELIST_PENDING: "DATERANGE_LEAVELIST_PENDING",
  DATERANGE_LEAVELIST_SUCCESS: "DATERANGE_LEAVELIST_SUCCESS",
  DATERANGE_LEAVELIST_ERROR: "DATERANGE_LEAVELIST_ERROR",
};

export function setFilter(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_FILTER,
      payload: {
        filterData: data,
      },
    });
  };
}
export function resetFilter() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_FILTER,
    });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_DATA,
      payload: {
        showLoader: true,
        setShow: false,

        employeeLeaveList: [],
        currentYear: "",
      },
    });
  };
}

export function userList(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LIST_PENDING,
      payload: {
        userListPending: true,
        userListSuccess: false,
        userListError: null,
      },
    });

    Api.post(`user/list?limit=${200}`, data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.USER_LIST_SUCCESS,
            payload: {
              userListPending: false,
              userListSuccess: true,
              userListError: null,

              userList: response.data.docs,
            },
          });
        } else {
          dispatch({
            type: actionTypes.USER_LIST_ERROR,
            payload: {
              userListPending: false,
              userListSuccess: true,
              userListError: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Failed to get user list.",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.USER_LIST_ERROR,
          payload: {
            userListPending: false,
            userListSuccess: true,
            userListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get user list.",
          })
        );
      });
  };
}

export function employeeLeaveList(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMPLOYEE_LEAVE_LIST_PENDING,
      payload: {
        employeeLeaveListPending: true,
        employeeLeaveListSuccess: false,
        employeeLeaveListError: null,
        showLoader: true,
        setShow: true,
      },
    });
    // console.log(data);
    Api.post(`employeeLeaveManagement/getEmployeeLeaves?limit=${1000}`, data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          const sortedArray = response.data.docs
            .sort((a, b) => {
              return new Date(b.startDate) - new Date(a.startDate);
            })
            .reverse();
          dispatch({
            type: actionTypes.EMPLOYEE_LEAVE_LIST_SUCCESS,
            payload: {
              employeeLeaveListPending: false,
              employeeLeaveListSuccess: true,
              employeeLeaveListError: null,

              totalEmployeeLeaveList: sortedArray,
              employeeLeaveList: sortedArray,
              showLoader: false,
              currentYear: data.year,
              setShow: true,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.EMPLOYEE_LEAVE_LIST_ERROR,
          payload: {
            employeeLeaveListPending: false,
            employeeLeaveListSuccess: true,
            employeeLeaveListError: null,
            showLoader: true,
            setShow: false,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function getEmployeeLeavesSummary(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EMPLOYEE_SUMMARY_PENDING,
      payload: {
        employeeLeavesSummaryPending: true,
        employeeLeavesSummarySuccess: false,
        employeeLeavesSummaryError: null,
        setShow: true,
      },
    });

    Api.post("employeeLeaveManagement/getEmployeeLeavesSummary", data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.EMPLOYEE_SUMMARY_SUCCESS,
            payload: {
              employeeLeavesSummaryPending: false,
              employeeLeavesSummarySuccess: true,
              employeeLeavesSummaryError: null,

              employeeLeavesSummary: response.data,
              currentYear: data.year,
            },
          });
        } else {
          dispatch({
            type: actionTypes.EMPLOYEE_SUMMARY_SUCCESS,
            payload: {
              employeeLeavesSummaryPending: false,
              employeeLeavesSummarySuccess: true,
              employeeLeavesSummaryError: null,

              employeeLeavesSummary: null,
              setShow: false,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.EMPLOYEE_SUMMARY_ERROR,
          payload: {
            employeeLeavesSummaryPending: false,
            employeeLeavesSummarySuccess: true,
            employeeLeavesSummaryError: null,
            setShow: false,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function getOtherLeavesSummary(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.OTHER_SUMMARY_PENDING,
      payload: {
        otherLeavesSummaryPending: true,
        otherLeavesSummarySuccess: false,
        otherLeavesSummaryError: null,
        setShow: true,
      },
    });

    Api.post("employeeLeaveManagement/getOtherLeaveSummary", data)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: actionTypes.OTHER_SUMMARY_SUCCESS,
            payload: {
              otherLeavesSummaryPending: false,
              otherLeavesSummarySuccess: true,
              otherLeavesSummaryError: null,

              otherLeavesSummary: response.data,
              currentYear: data.year,
            },
          });
        } else {
          dispatch({
            type: actionTypes.OTHER_SUMMARY_SUCCESS,
            payload: {
              otherLeavesSummaryPending: false,
              otherLeavesSummarySuccess: true,
              otherLeavesSummaryError: null,

              otherLeavesSummary: null,
              setShow: false,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.OTHER_SUMMARY_ERROR,
          payload: {
            otherLeavesSummaryPending: false,
            otherLeavesSummarySuccess: true,
            otherLeavesSummaryError: null,
            setShow: false,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}

export function getOptionalHolidayLeaveSummary(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.OPTIONAL_HOLIDAY_LEAVE_SUMMARY_PENDING,
      payload: {
        optionalHolidaySummaryPending: true,
        optionalHolidaySummarySuccess: false,
        optionalHolidaySummaryError: null,
      },
    });
    Api.post("employeeLeaveManagement/getOptionalHolidayLeavesSummary", data)
      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch({
            type: actionTypes.OPTIONAL_HOLIDAY_LEAVE_SUMMARY_SUCCESS,
            payload: {
              optionalHolidaySummaryPending: false,
              optionalHolidaySummarySuccess: true,
              optionalHolidaySummaryError: null,
              optionalHolidaySummary: response.data,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.OPTIONAL_HOLIDAY_LEAVE_SUMMARY_ERROR,
          payload: {
            optionalHolidaySummaryPending: false,
            optionalHolidaySummarySuccess: true,
            optionalHolidaySummaryError: null,
            optionalHolidaySummary: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Something went wrong.",
          })
        );
      });
  };
}

export function hideLeaveSummary() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.HIDE_LEAVESUMMARY_SUCCESS,
      payload: {
        setShow: false,
      },
    });
  };
}

export function dateRangeEmployeeLeaveList(data) {
  return (dispatch) => {
    // console.log("data", data);
    dispatch({
      type: actionTypes.DATERANGE_LEAVELIST_PENDING,
      payload: {
        dateRangeLeaveListPending: true,
        dateRangeLeaveListSuccess: false,
        dateRangeLeaveListError: null,
        setShow: true,
      },
    });
    // console.log("dateRangeEmployeeLeaveList", data);
    Api.post(`employeeLeaveManagement/getEmployeeLeavesBetweenDates`, data)
      .then((response) => {
        // console.log("response", response);
        if (response.data) {
          dispatch({
            type: actionTypes.DATERANGE_LEAVELIST_SUCCESS,
            payload: {
              dateRangeLeaveListPending: false,
              dateRangeLeaveListSuccess: true,
              dateRangeLeaveListError: null,
              setShow: true,
              dateRangeLeaveList: response.data,
              startDate: data.startDate,
              endDate: data.endDate,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: actionTypes.DATERANGE_LEAVELIST_ERROR,
          payload: {
            dateRangeLeaveListPending: false,
            dateRangeLeaveListSuccess: true,
            dateRangeLeaveListError: null,
            setShow: false,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: "Failed to get list.",
          })
        );
      });
  };
}
