import React from "react";
import { bindActionCreators } from "redux";
import ReactLoading from 'react-loading';
import connect from "react-redux/es/connect/connect";

import "./BigLoaderStyles.scss";

const BigLoader = ({ isOpen }) => {
  return (
    <>
      {
        isOpen ? (
          <div className="big_loader">
            <ReactLoading type="spinningBubbles" color="#d87d2e" height={100} width={100} />
            <p>Uploading please wait...</p>
          </div>
        ) : ("")
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  isOpen: state.loader.isOpen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BigLoader);
