import React, { Component } from "react";
import { bindActionCreators } from "redux";
// import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import "./HRAdministrationStyles.scss";
import HRAdministrationView from "./HRAdministrationView";
import {
  list as getHolidays,
  addHoliday,
  editHoliday,
  deleteHoliday,
} from "../../../store/holidays/holidaysActions";
import { getLeaveMasterTypesList } from "../../../store/employeeLeave/employeeLeaveActions";
import {
  list as getLeaveTypes,
  addLeaveType,
  editLeaveType,
  deleteLeaveType,
} from "../../../store/leaveTypes/leaveTypesActions";
import {
  list as getLeavesPerYearList,
  addLeavesPerYear,
  editLeavesPerYear,
  deleteLeavesPerYear,
} from "../../../store/leavesPerYear/leavesPerYearActions";

import {
  list as getTechDomains,
  addTechDomain,
  editTechDomain,
  deleteTechDomain,
} from "../../../store/technicalDomain/technicalDomainActions";
import {
  list as getDesignations,
  addDesignation,
  editDesignation,
  deleteDesignation,
} from "../../../store/Designation/DesignationActions";
import {
  list as getApplicationDomains,
  addApplicationDomain,
  editApplicationDomain,
  deleteApplicationDomain,
} from "../../../store/aplicationDomain/aplicationDomainActions";

import {
  list as getLunchType,
  addLunchType,
  editLunchType,
  deleteLunchType,
} from "../../../store/lunchType/lunchTypeActions";
import {
  list as getlocation,
  addLocation,
  editLocation,
  deleteLocation,
} from "../../../store/location/locationActions";

import {
  list as getLunchBlockingTimeList,
  addLunchBlockingTime,
  editLunchBlockingTime,
} from "../../../store/lunchBlockingTime/lunchBlockingTimeActions";

import {
  getDocumentCategoryList,
  addDocumentCategory,
  editDocumentCategory,
  getCategoryDocumentList,
  downloadDocument,
  deleteDocument,
  uploadDocumentForCategory,
} from "../../../store/documents/documentsActions";
import { BigLoader, Loader } from "../../common";

export class HRAdministrationContainer extends Component {
  constructor(props) {
    super(props);

    this.props.getHolidays(new Date().getFullYear());
    this.props.getLeaveTypes();
    this.props.getLeavesPerYearList();
    this.props.getLeaveMasterTypesList();

    this.props.getTechDomains();
    this.props.getDesignations();
    this.props.getApplicationDomains();

    this.props.getlocation();
    this.props.getLunchType();

    this.props.getLunchBlockingTimeList();
    this.props.getDocumentCategoryList();
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      holidaysSuccess,
      addHoliday,
      editHoliday,
      deleteHoliday,
      holidaysList,

      leaveTypesSuccess,
      addLeaveType,
      editLeaveType,
      deleteLeaveType,
      leaveTypesList,

      leavesPerYearSuccess,
      addLeavesPerYear,
      editLeavesPerYear,
      deleteLeavesPerYear,
      leavesPerYearList,

      leaveMasterTypeListSuccess,
      leaveMasterTypeList,
      currentYear,
      getHolidays,

      techDomainSuccess,
      techDomainList,
      addTechDomain,
      editTechDomain,
      deleteTechDomain,

      designationSuccess,
      designationList,
      addDesignation,
      editDesignation,
      deleteDesignation,

      aplicationDomainSuccess,
      aplicationDomainList,
      addApplicationDomain,
      editApplicationDomain,
      deleteApplicationDomain,

      lunchTypeList,
      lunchTypeSuccess,
      addLunchType,
      editLunchType,
      deleteLunchType,

      locationSuccess,
      locationList,
      addLocation,
      editLocation,
      deleteLocation,

      lunchBlockingTimeList,
      addLunchBlockingTime,
      lunchBlockingTimeListSuccess,
      editLunchBlockingTime,

      userDocumentsListSuccess,
      userDocumentsList,
      addDocumentCategory,
      editDocumentCategory,
      getCategoryDocumentList,
      categoryDocumentsList,
      categoryDocumentsListSuccess,
      downloadDocument,
      uploadDocumentForCategory,
      deleteDocument,
    } = this.props;
    // console.log(lunchBlockingTimeList);

    if (
      holidaysSuccess === false ||
      leaveTypesSuccess === false ||
      leavesPerYearSuccess === false ||
      leaveMasterTypeListSuccess === false ||
      techDomainSuccess === false ||
      designationSuccess === false ||
      aplicationDomainSuccess === false ||
      locationSuccess === false ||
      lunchTypeSuccess === false ||
      lunchBlockingTimeListSuccess === false ||
      userDocumentsListSuccess === false
    ) {
      return <Loader />;
    } else {
      return (
        <>
          <HRAdministrationView
            addHoliday={addHoliday}
            editHoliday={editHoliday}
            deleteHoliday={deleteHoliday}
            holidaysList={holidaysList}
            addLeaveType={addLeaveType}
            editLeaveType={editLeaveType}
            deleteLeaveType={deleteLeaveType}
            leaveTypesList={leaveTypesList}
            addLeavesPerYear={addLeavesPerYear}
            editLeavesPerYear={editLeavesPerYear}
            deleteLeavesPerYear={deleteLeavesPerYear}
            leavesPerYearList={leavesPerYearList}
            leaveMasterTypeList={leaveMasterTypeList}
            currentYear={currentYear}
            getHolidays={getHolidays}
            techDomainList={techDomainList}
            addTechDomain={addTechDomain}
            editTechDomain={editTechDomain}
            deleteTechDomain={deleteTechDomain}
            designationList={designationList}
            addDesignation={addDesignation}
            editDesignation={editDesignation}
            deleteDesignation={deleteDesignation}
            aplicationDomainList={aplicationDomainList}
            addApplicationDomain={addApplicationDomain}
            editApplicationDomain={editApplicationDomain}
            deleteApplicationDomain={deleteApplicationDomain}
            lunchTypeList={lunchTypeList}
            addLunchType={addLunchType}
            editLunchType={editLunchType}
            deleteLunchType={deleteLunchType}
            locationList={locationList}
            addLocation={addLocation}
            editLocation={editLocation}
            deleteLocation={deleteLocation}
            addLunchBlockingTime={addLunchBlockingTime}
            lunchBlockingTimeList={lunchBlockingTimeList}
            editLunchBlockingTime={editLunchBlockingTime}
            userDocumentsList={userDocumentsList}
            addDocumentCategory={addDocumentCategory}
            editDocumentCategory={editDocumentCategory}
            getCategoryDocumentList={getCategoryDocumentList}
            categoryDocumentsList={categoryDocumentsList}
            categoryDocumentsListSuccess={categoryDocumentsListSuccess}
            downloadDocument={downloadDocument}
            uploadDocumentForCategory={uploadDocumentForCategory}
            deleteDocument={deleteDocument}
          />
          <BigLoader />
        </>
      );
    }
  }
}

HRAdministrationContainer.propTypes = {};

const mapStateToProps = (state) => ({
  holidaysSuccess: state.holidays.holidaysSuccess,
  holidaysList: state.holidays.holidaysList,
  currentYear: state.holidays.currentYear,

  leaveTypesSuccess: state.leaveTypes.leaveTypesSuccess,
  leaveTypesList: state.leaveTypes.leaveTypesList,

  leavesPerYearSuccess: state.leavesPerYear.leavesPerYearSuccess,
  leavesPerYearList: state.leavesPerYear.leavesPerYearList,

  leaveMasterTypeList: state.employeeLeave.leaveMasterTypeList,
  leaveMasterTypeListSuccess: state.employeeLeave.leaveMasterTypeListSuccess,

  techDomainSuccess: state.techDomain.techDomainSuccess,
  techDomainList: state.techDomain.techDomainList,

  designationSuccess: state.designation.designationSuccess,
  designationList: state.designation.designationList,

  aplicationDomainSuccess: state.aplicationDomain.aplicationDomainSuccess,
  aplicationDomainList: state.aplicationDomain.aplicationDomainList,

  lunchTypeList: state.lunchType.allLunchTypeList,
  lunchTypeSuccess: state.lunchType.lunchTypeSuccess,

  locationSuccess: state.location.locationSuccess,
  locationList: state.location.locationList,

  lunchBlockingTimeList: state.lunchBlockingTimeReducer.lunchBlockingTimeList,
  lunchBlockingTimeListSuccess:
    state.lunchBlockingTimeReducer.lunchBlockingTimeListSuccess,

  userDocumentsListSuccess: state.userDocuments.userDocumentsListSuccess,
  userDocumentsList: state.userDocuments.userDocumentsList,
  categoryDocumentsList: state.userDocuments.categoryDocumentsList,
  categoryDocumentsListSuccess:
    state.userDocuments.categoryDocumentsListSuccess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHolidays: getHolidays,
      addHoliday: addHoliday,
      editHoliday: editHoliday,
      deleteHoliday: deleteHoliday,

      getLeaveTypes: getLeaveTypes,
      addLeaveType: addLeaveType,
      editLeaveType: editLeaveType,
      deleteLeaveType: deleteLeaveType,

      getLeavesPerYearList: getLeavesPerYearList,
      addLeavesPerYear: addLeavesPerYear,
      editLeavesPerYear: editLeavesPerYear,
      deleteLeavesPerYear: deleteLeavesPerYear,

      getLeaveMasterTypesList: getLeaveMasterTypesList,

      getTechDomains: getTechDomains,
      addTechDomain,
      editTechDomain,
      deleteTechDomain,

      getDesignations: getDesignations,
      addDesignation,
      editDesignation,
      deleteDesignation,

      getApplicationDomains: getApplicationDomains,
      addApplicationDomain,
      editApplicationDomain,
      deleteApplicationDomain,

      getlocation: getlocation,
      addLocation: addLocation,
      editLocation: editLocation,
      deleteLocation: deleteLocation,

      getLunchType: getLunchType,
      addLunchType: addLunchType,
      editLunchType: editLunchType,
      deleteLunchType: deleteLunchType,

      getLunchBlockingTimeList: getLunchBlockingTimeList,
      addLunchBlockingTime: addLunchBlockingTime,
      editLunchBlockingTime: editLunchBlockingTime,

      getDocumentCategoryList,
      addDocumentCategory,
      editDocumentCategory,
      getCategoryDocumentList,
      downloadDocument,
      uploadDocumentForCategory,
      deleteDocument,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HRAdministrationContainer);
